import { Theme } from "@mui/material";
export const styles = {
  boxContainer: {
    display: "flex",
    flexDirection: "column",
    border: "1px solid #F0F0F0",
    backgroundColor: "#fff",
    borderRadius: "4px",
    padding: "24px 22px",
  },
  actionButtons: {
    display: "flex",
    justifyContent: "space-between",
    columnGap: "10px",
    marginTop: "20px",
  },
  actionButton: {
    height: 46,
    textTransform: "none",
    color: (theme: Theme) => `${theme.palette.text.primary}`,
    borderColor: (theme: Theme) => `${theme.palette.background.default}`,
    "&:hover": {
      background: (theme: Theme) => `${theme.palette.background.paper}`,
      color: (theme: Theme) => `${theme.palette.primary.main}`,
    },
    svg: {
      marginRight: "14px",
    },
  },
  actionButtonSave: {
    height: 46,
    textTransform: "none",
    boxShadow: "none",
  },
};
