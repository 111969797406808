import { Box } from "@mui/material";
import React from "react";

interface Props {
  children: React.ReactElement | React.ReactElement[];
}

const TabProfileSection: React.FC<Props> = ({ children }) => {
  return <Box>{children}</Box>;
};

export default TabProfileSection;
