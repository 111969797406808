import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  Alert,
  Theme,
  Typography,
  Container,
  Checkbox,
  FormControl,
  InputAdornment,
  Card,
  Box,
  Link,
} from "@mui/material";
import { Input, PasswordStrength, SafeInput, Button } from "src/components";
import { AppRoutes, PASSWORD_REGEX, PHONE_NUMBER_REGEX } from "src/constants";
import { cleanError, signUpRequest } from "src/store/auth/actions";
import { getErrorSelector } from "src/store/auth/selectors";
import { getMappedErrorMessage, isFieldHasError } from "src/utils";
import TermsModal from "src/components/ActionsModal/TermsModal";
import PrivacyPolicyModal from "src/components/ActionsModal/PrivacyPolicyModal";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    paddingTop: "24px",
    paddingBottom: "24px",
  },
  cardWrapper: {
    width: "100%",
    mt: "35px",
    boxSizing: "border-box",
    borderColor: (theme: Theme) => `${theme.palette.background.default}`,
    px: {
      xs: "24px",
      md: "30px",
    },
    py: "50px",
    maxWidth: {
      xs: "350px",
      md: "460px",
    },
  },
  headerWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  checkBoxWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  checkBox: {
    p: 0,
  },
  startAdornment: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
  },
  divider: {
    backgroundColor: (theme: Theme) => `${theme.palette.divider}`,
    height: "100%",
    width: "1px",
    marginLeft: "10px",
  },
  alertErrorWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  alertError: {
    mt: "20px",
    background: "transparent",
    fontSize: "14px",
    color: (theme: Theme) => theme.palette.error[700],
  },
};

const validationSchema = yup.object().shape({
  companyName: yup
    .string()
    .required("Company Name is required")
    .min(1, "Size must be between 1 and 150")
    .max(150, "Size must be between 1 and 150"),
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  email: yup
    .string()
    .email("Email must be valid")
    .required("Email is required"),
  phoneNumber: yup
    .string()
    .required("Phone number is required")
    .matches(PHONE_NUMBER_REGEX, "Phone number is not valid"),
  loginPassword: yup
    .string()
    .required("Password is required")
    .matches(PASSWORD_REGEX, "Password is not valid."),
  passwordConfirmation: yup
    .string()
    .required("Passwords must match")
    .oneOf([yup.ref("loginPassword"), null], "Passwords must match"),
});

const SignUp = () => {
  const [agreeWithTerms, setAgreeWithTerms] = useState(false);
  const authenticationError = useSelector(getErrorSelector);
  const [termsOfServiceModal, setTermsOfServiceModal] = useState(false);
  const [privacyPolicyModal, setPrivacyPolicyModal] = useState(false);
  const modalActionType = {
    action: "Terms of",
    page: "Service",
  };
  const ppActionType = {
    action: "Privacy ",
    page: "Policy",
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      phoneNumber: "",
      companyName: "",
      firstName: "",
      lastName: "",
      email: "",
      loginPassword: "",
      passwordConfirmation: "",
    },
    validationSchema: validationSchema,
    onSubmit: ({
      phoneNumber,
      companyName,
      firstName,
      lastName,
      email,
      loginPassword,
    }) => {
      const phoneNumberWithoutMask = phoneNumber.replace(/\D/g, "");
      dispatch(
        signUpRequest(
          {
            phoneNumber: phoneNumberWithoutMask,
            companyName,
            firstName,
            lastName,
            email,
            loginPassword,
          },
          {
            onSuccess: onSingUpSuccess,
          }
        )
      );
    },
  });

  useEffect(() => {
    dispatch(cleanError());
    return () => {
      dispatch(cleanError());
    };
  }, []);

  const redirectToSignIn = useCallback(() => {
    navigate(AppRoutes.SignIn);
  }, [navigate]);

  const onSingUpSuccess = useCallback(() => {
    navigate(AppRoutes.Verification);
  }, [navigate]);

  const redirectToPrivacyPolicy = useCallback(() => {
    navigate(AppRoutes.PrivacyPolicy);
  }, [navigate]);

  const redirectToTermsConditions = useCallback(() => {
    navigate(AppRoutes.TermsConditions);
  }, [navigate]);

  return (
    <Container sx={styles.container} maxWidth="sm">
      <Typography
        data-sqa-id="page-title"
        color="primary"
        fontWeight="800"
        fontSize={{ xs: "24px", md: "36px" }}
      >
        BusyBucket
      </Typography>
      <Card variant="outlined" sx={styles.cardWrapper}>
        <Box sx={styles.headerWrapper}>
          <Typography
            data-sqa-id="form-title"
            fontWeight="bold"
            fontSize={{ xs: "20px", md: "24px" }}
          >
            Sign Up
          </Typography>
          <Link
            data-sqa-id="already-have-an-account-link"
            fontSize={{ xs: "14px", md: "16px" }}
            color="primary"
            variant="body1"
            component="button"
            type="button"
            onClick={redirectToSignIn}
          >
            Already have an account?
          </Link>
        </Box>
        <form onSubmit={formik.handleSubmit}>
          <Box mt="20px">
            <Input
              label="Company Name"
              required
              fullWidth
              size="small"
              inputProps={{ maxLength: 150 }}
              id="companyName"
              value={formik.values.companyName}
              onChange={formik.handleChange}
              error={isFieldHasError({
                formik,
                fieldName: "companyName",
                isGlobalError: Boolean(authenticationError),
              })}
              helperText={
                formik.touched.companyName && formik.errors.companyName
              }
            />
          </Box>
          <Box mt="20px">
            <Input
              label="First Name"
              required
              fullWidth
              size="small"
              inputProps={{ maxLength: 150 }}
              id="firstName"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              error={isFieldHasError({
                formik,
                fieldName: "firstName",
                isGlobalError: Boolean(authenticationError),
              })}
              helperText={formik.touched.firstName && formik.errors.firstName}
            />
          </Box>
          <Box mt="20px">
            <Input
              label="Last Name"
              required
              fullWidth
              size="small"
              inputProps={{ maxLength: 150 }}
              id="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={isFieldHasError({
                formik,
                fieldName: "lastName",
                isGlobalError: Boolean(authenticationError),
              })}
              helperText={formik.touched.lastName && formik.errors.lastName}
            />
          </Box>
          <Box mt="20px">
            <Input
              label="Email"
              required
              fullWidth
              size="small"
              inputProps={{ maxLength: 150 }}
              id="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={isFieldHasError({
                formik,
                fieldName: "email",
                isGlobalError: Boolean(authenticationError),
              })}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Box>
          <Box mt="20px">
            <Input
              required
              mask="(999) 999-9999"
              label="Phone Number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={styles.startAdornment}>
                    <Typography>+1</Typography>
                    <Box sx={styles.divider} />
                  </InputAdornment>
                ),
              }}
              fullWidth
              size="small"
              inputProps={{ maxLength: 30 }}
              id="phoneNumber"
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              error={isFieldHasError({
                formik,
                fieldName: "phoneNumber",
                isGlobalError: Boolean(authenticationError),
              })}
              helperText={
                formik.touched.phoneNumber && formik.errors.phoneNumber
              }
            />
          </Box>
          <Box mt="20px">
            <SafeInput
              label="Password"
              required
              fullWidth
              size="small"
              type="password"
              inputProps={{ maxLength: 50 }}
              id="loginPassword"
              value={formik.values.loginPassword}
              onChange={formik.handleChange}
              error={isFieldHasError({
                formik,
                fieldName: "loginPassword",
                isGlobalError: Boolean(authenticationError),
              })}
              helperText={
                formik.touched.loginPassword && formik.errors.loginPassword
              }
            />
            <PasswordStrength isValid={!formik.errors.loginPassword} />
          </Box>
          <Box mt="20px">
            <SafeInput
              label="Confirm Password"
              required
              fullWidth
              size="small"
              type="password"
              inputProps={{ maxLength: 50 }}
              id="passwordConfirmation"
              value={formik.values.passwordConfirmation}
              onChange={formik.handleChange}
              error={isFieldHasError({
                formik,
                fieldName: "passwordConfirmation",
                isGlobalError: Boolean(authenticationError),
              })}
              helperText={
                formik.touched.passwordConfirmation &&
                formik.errors.passwordConfirmation
              }
            />
          </Box>
          <Box mt="20px">
            <FormControl>
              <Box
                data-sqa-id="agree-terms-conditions"
                sx={styles.checkBoxWrapper}
              >
                <Checkbox
                  data-sqa-id="agree-checkbox"
                  sx={styles.checkBox}
                  checked={agreeWithTerms}
                  onChange={(e) => setAgreeWithTerms(e.target.checked)}
                />
                <Typography
                  fontSize={{ xs: "12px", md: "16px" }}
                  sx={{ margin: "0 0 0 5px" }}
                >
                  Agree with&nbsp;
                </Typography>
                <Link
                  data-sqa-id="agree-terms-conditions-link"
                  fontSize={{ xs: "12px", md: "16px" }}
                  color="primary"
                  sx={{ cursor: "pointer" }}
                  variant="body1"
                  onClick={() => setTermsOfServiceModal(true)}
                >
                  Terms of Service
                </Link>
                <Typography fontSize={{ xs: "12px", md: "16px" }}>
                  &nbsp;and&nbsp;
                </Typography>
                <Link
                  data-sqa-id="agree-privacy-policy-link"
                  fontSize={{ xs: "12px", md: "16px" }}
                  color="primary"
                  sx={{ cursor: "pointer" }}
                  variant="body1"
                  onClick={() => setPrivacyPolicyModal(true)}
                >
                  Privacy Policy
                </Link>
              </Box>
            </FormControl>
          </Box>
          <Box mt="20px">
            <Button
              data-sqa-id="sign-up-button"
              disabled={!agreeWithTerms}
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
              size="small"
            >
              <Typography fontSize={{ xs: "14px", md: "16px" }}>
                Sign Up
              </Typography>
            </Button>
          </Box>
          <Box mt="20px" textAlign="center">
            <Link
              data-sqa-id="back-to-sign-in"
              fontSize={{ xs: "14px", md: "16px" }}
              color="primary"
              variant="body1"
              component="button"
              type="button"
              onClick={redirectToSignIn}
            >
              Back to Sign In
            </Link>
          </Box>
          {Boolean(authenticationError) && (
            <Box sx={styles.alertErrorWrapper}>
              <Alert severity="error" sx={styles.alertError}>
                {getMappedErrorMessage(authenticationError)}
              </Alert>
            </Box>
          )}
        </form>
      </Card>
      <Link
        data-sqa-id="terms-conditions-footer-link"
        mt="30px"
        variant="body1"
        component="button"
        fontSize={{ xs: "12px", md: "14px" }}
        color="secondary"
        sx={{ marginTop: "30px" }}
        onClick={redirectToTermsConditions}
      >
        Terms of Service
      </Link>
      <Link
        data-sqa-id="privacy-policy-footer-link"
        variant="body1"
        color="secondary"
        component="button"
        fontSize={{ xs: "12px", md: "14px" }}
        sx={{ marginTop: "20px" }}
        onClick={redirectToPrivacyPolicy}
      >
        Privacy Policy
      </Link>
      {termsOfServiceModal && (
        <TermsModal
          modalActionType={modalActionType}
          isOpen={termsOfServiceModal}
          closeModal={() => setTermsOfServiceModal(false)}
        />
      )}
      {privacyPolicyModal && (
        <PrivacyPolicyModal
          modalActionType={ppActionType}
          isOpen={privacyPolicyModal}
          closeModal={() => setPrivacyPolicyModal(false)}
        />
      )}
    </Container>
  );
};

export default SignUp;
