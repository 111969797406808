import React from "react";
import { ButtonProps, Button as MuiButton } from "@mui/material";

interface Props extends ButtonProps {
  fontSize?: string | { [key: string]: string };
  mt?: string | { [key: string]: string };
}

const useStyles = ({ fontSize = "16px", mt }: Props) => ({
  button: {
    textTransform: "none",
    mt,
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
    },
    "&.MuiButton-sizeSmall": {
      height: "46px",
    },
    "&.MuiButton-sizeMedium": {
      height: "56px",
    },
    "&.MuiButton-text": {
      textTransform: "none",
      textDecoration: "underline",
      fontSize,
    },
    "&.Mui-disabled": {
      background: (theme) => `${theme.palette.background.paper}`,
      boxShadow: `inset 0 0 0 1px #d9d9d9`,
      ".MuiTypography-root": {
        color: "#d9d9d9",
      },
    },
  },
});

const Button: React.FC<Props> = ({ fontSize, mt, ...buttonProps }) => {
  const styles = useStyles({ fontSize, mt });
  return <MuiButton {...buttonProps} sx={styles.button} />;
};

export default Button;
