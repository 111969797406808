import * as React from "react";
import { SVGProps } from "react";

interface Props {
  width?: number;
  height?: number;
  color?: string;
}

const ClearIcon: React.FC<Props> = (
  { width = 20, height = 20 },
  props: SVGProps<SVGSVGElement>
) => (
  <svg
    data-sqa-id="clear-icon"
    height={height}
    viewBox="0 0 48 48"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M38 12.83l-2.83-2.83-11.17 11.17-11.17-11.17-2.83 2.83 11.17 11.17-11.17 11.17 2.83 2.83 11.17-11.17 11.17 11.17 2.83-2.83-11.17-11.17z" />
    <path d="M0 0h48v48h-48z" fill="none" />
  </svg>
);

export default ClearIcon;
