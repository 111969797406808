import React, { SVGProps } from "react";

const SuccessIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" {...props}>
    <circle cx={10} cy={10} r={9.6} fill="#67BB66" />
    <path
      d="m14.415 6.928-6.071 6.144-2.76-2.792"
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SuccessIcon;
