export const styles = {
  historyDay: {
    fontWeight: 500,
    fontSize: "32px",
    color: "#000",
  },
  weekContainer: {
    display: "flex",
    flexShrink: 0,
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #F0F0F0",
    backgroundColor: "#FFF",
    color: "#000",
    borderRadius: "4px",
    width: "46px",
    height: "46px",
    cursor: "pointer",
    zIndex: 5,
  },
};
