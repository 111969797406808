import { Box, Theme } from "@mui/material";
import React from "react";

const styles = {
  tabsDataContainer: {
    backgroundColor: (theme: Theme) => `${theme.palette.background.paper}`,
    // py: "16px",
    height: "fit-content",
    borderRadius: "4px",
    border: (theme: Theme) => `1px solid ${theme.palette.background.default}`,
  },
};

interface Props {
  children: React.ReactElement | React.ReactElement[];
  px?: string;
  py?: string;
  mt?: string;
  width?: string;
  border?: string;
}

const TabDataSection: React.FC<Props> = ({
  children,
  px,
  py,
  mt,
  width,
  border,
}) => {
  return (
    <Box sx={[styles.tabsDataContainer, { py, px, mt, width, border }]}>
      {children}
    </Box>
  );
};

export default TabDataSection;
