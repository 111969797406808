import { Box, TextField, Typography } from "@mui/material";
import React from "react";
import Button from "../Button";
import ModalWrapper from "./ModalWrapper";

interface ModalProps {
  closeModal: () => void;
  setCaptionText: any;
  handleCaption?: (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => void;
  caption: string;
  index: number;
  isOpen: boolean;
  modalActionType: {
    action: string;
    page: string;
  };
}

const AddCaptionModal: React.FC<ModalProps> = ({
  isOpen,
  modalActionType,
  caption,
  index,
  closeModal,
  handleCaption,
  setCaptionText,
}) => {
  const handleSave = () => {
    closeModal();
    setCaptionText(caption);
  };

  return (
    <>
      <ModalWrapper
        closeModal={closeModal}
        isOpen={isOpen}
        modalActionType={modalActionType}
        maxWidth="350px"
      >
        <>
          <Box>
            <TextField
              multiline
              fullWidth
              rows={5}
              value={caption}
              placeholder="Enter photo caption"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleCaption(e, index)
              }
              type="text"
              inputProps={{ maxLength: 500 }}
            />
          </Box>
          <Typography
            sx={{ width: "100%", textAlign: "right", mb: "20px" }}
            color="#D9D9D9"
          >{`${caption.length} / 500`}</Typography>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleSave}
          >
            <Typography sx={{ textTransform: "none" }}>Save</Typography>
          </Button>
        </>
      </ModalWrapper>
    </>
  );
};

export default AddCaptionModal;
