import axios from "../../utils/axios";
import {
  AddUserRequestPayload,
  UpdateUserRequestPayload,
  DeleteUserRequestPayload,
  FetchUsersRequestPayload,
  SearchUserRequestPayload,
  GetUserRequestPayload,
} from "./types";

export const fetchUsers = (payload: FetchUsersRequestPayload) => {
  return axios.get(
    `/user/all?currentPage=${payload.currentPage}&pageSize=${payload.pageSize}&sortBy=${payload.sortBy}&sortOrder=${payload.sortOrder}`
  );
};

export const searchUser = (payload: SearchUserRequestPayload) => {
  return axios.get(
    `/user/search?currentPage=${payload.currentPage}&pageSize=${payload.pageSize}&sortBy=${payload.sortBy}&sortOrder=${payload.sortOrder}&searchQuery=${payload.searchQuery}`
  );
};

export const getUser = (payload: GetUserRequestPayload) => {
  return axios.get(`/user/${payload.id}`);
};

export const addUser = (payload: AddUserRequestPayload) => {
  return axios.post("/user", payload);
};

export const updateUser = (payload: UpdateUserRequestPayload) => {
  return axios.put("/user", payload.user);
};

export const deleteUser = (payload: DeleteUserRequestPayload) => {
  return axios.delete(`/user/${payload.id}`);
};
