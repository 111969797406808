import {
  Box,
  FormControl,
  MenuItem,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "../../Button";
import ModalWrapper from "../ModalWrapper";
import { DeleteForWeb, Link } from "src/assets/icons";
import { useDispatch, useSelector } from "react-redux";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  deleteClockByUserAndDayRequest,
  linkTimeRecordByUserAndDayRequest,
} from "src/store/clocks/actions";
import theme from "src/theme";
import Select from "src/components/Select";
import { ProjectAll } from "src/types";
import { fetchProjectAllRequest } from "src/store/projects/actions";
import { getProjectSelector } from "src/store/projects/selectors";

const styles = {
  actionButtons: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    columnGap: "10px",
    marginTop: "20px",
    ">div": {
      width: "35%",
    },
  },
};

interface ModalProps {
  closeModal: () => void;
  fetchSuccess: () => void;
  setModalActionType?: any;
  elem: any;
  isOpen: boolean;
  modalActionType: {
    action: string;
    page: string;
  };
}

const ActionReportModal: React.FC<ModalProps> = ({
  isOpen,
  modalActionType,
  setModalActionType,
  elem,
  closeModal,
  fetchSuccess,
}) => {
  const dispatch = useDispatch();
  const projectState = useSelector(getProjectSelector);
  const [projectValue, setProjectValue] = useState("");
  const handleChangeSelectProject = (event: SelectChangeEvent) => {
    setProjectValue(event.target.value as string);
  };

  const handleLinkProjects = () => {
    dispatch(
      linkTimeRecordByUserAndDayRequest(
        {
          data: {
            projectId: projectValue,
            userDayPairs: [{ userId: elem.userId, day: elem.day }],
          },
        },
        {
          onSuccess,
        }
      )
    );
  };

  const onSuccess = () => {
    fetchSuccess();
    closeModal();
  };

  useEffect(() => {
    dispatch(fetchProjectAllRequest());
  }, []);

  const deleteElement = () => {
    dispatch(
      deleteClockByUserAndDayRequest(
        { userDayPairs: [{ userId: elem.userId, day: elem.day }] },
        { onSuccess: fetchSuccess }
      )
    );
    closeModal();
  };

  return (
    <>
      <ModalWrapper
        closeModal={closeModal}
        isOpen={isOpen}
        maxWidth="350px"
        modalActionType={modalActionType}
      >
        <>
          {modalActionType.action === "Actions" && (
            <>
              <Box mt="10px">
                <Button
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  onClick={() =>
                    setModalActionType({
                      action: "Link Time Records",
                      page: "to Project",
                    })
                  }
                >
                  <Link color="#0D6FF0" />
                  <Typography
                    ml="12px"
                    fontSize="14px"
                    fontWeight={500}
                    color="#262626"
                  >
                    Link to Project
                  </Typography>
                </Button>
              </Box>
              <Box mt="10px">
                <Button
                  variant="outlined"
                  color="error"
                  fullWidth
                  onClick={() =>
                    setModalActionType({
                      action: "Delete",
                      page: "Report",
                    })
                  }
                >
                  <DeleteForWeb />
                  <Typography
                    ml="12px"
                    fontSize="14px"
                    fontWeight={500}
                    color="#FF0000"
                  >
                    Delete
                  </Typography>
                </Button>
              </Box>
            </>
          )}
          {modalActionType.action === "Delete" && (
            <>
              <Typography fontSize={14}>
                Are you sure you want to delete the entry?
              </Typography>
              <Box sx={styles.actionButtons}>
                <Box>
                  <Button
                    variant="outlined"
                    fullWidth
                    size="small"
                    color="secondary"
                    onClick={closeModal}
                  >
                    <Typography color="#262626" fontSize="14px">
                      Cancel
                    </Typography>
                  </Button>
                </Box>
                <Box>
                  <Button
                    variant="outlined"
                    color="error"
                    fullWidth
                    size="small"
                    onClick={deleteElement}
                  >
                    Delete
                  </Button>
                </Box>
              </Box>
            </>
          )}
          {modalActionType.action === "Link Time Records" && (
            <>
              <Box mb={"20px"}>
                <Typography fontSize={14} mb={"8px"}>
                  Select Project
                </Typography>
                <FormControl fullWidth>
                  <Select
                    value={projectValue}
                    onChange={handleChangeSelectProject}
                    height={"40px"}
                    IconComponent={KeyboardArrowDownIcon}
                  >
                    {projectState.projectAll?.map((project: ProjectAll) => {
                      if (project?.subProjects) {
                        let arrProjectGroup = [...project.subProjects];
                        arrProjectGroup.unshift(project);
                        return arrProjectGroup.map(
                          (subproject: ProjectAll, i: number) => {
                            return (
                              <MenuItem
                                sx={{ pl: i ? "30px" : "16px" }}
                                key={subproject.id}
                                value={subproject.id}
                              >
                                {`${subproject.projectName} (${subproject.numericId})`}
                              </MenuItem>
                            );
                          }
                        );
                      } else
                        return (
                          <MenuItem key={project.id} value={project.id}>
                            {`${project.projectName} (${project.numericId})`}
                          </MenuItem>
                        );
                    })}
                  </Select>
                </FormControl>
              </Box>
              <Box mt="20px" sx={{ textAlign: "right" }}>
                <Button
                  variant="outlined"
                  size="small"
                  color="secondary"
                  style={{
                    marginRight: "20px",
                  }}
                  onClick={closeModal}
                >
                  <Typography
                    color={theme.palette.text.primary}
                    fontSize="14px"
                  >
                    Cancel
                  </Typography>
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  style={{
                    padding: "12px",
                    pointerEvents: !projectValue ? "none" : "auto",
                  }}
                  onClick={handleLinkProjects}
                >
                  <Typography fontSize="14px">Link to Project</Typography>
                </Button>
              </Box>
            </>
          )}
        </>
      </ModalWrapper>
    </>
  );
};

export default ActionReportModal;
