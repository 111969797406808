import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  fetchTodaysClock as fetchTodaysClockApi,
  fetchWeeklyClock as fetchWeeklyClockApi,
  fetchDailyClock as fetchDailyClockApi,
  fetchDailyClockByUser as fetchDailyClockByUserApi,
  fetchWorkedMonth as fetchWorkedMonthApi,
  linkTimeRecord as linkTimeRecordApi,
  linkTimeRecordByUserAndDay as linkTimeRecordByUserAndDayApi,
  deleteTodaysClock as deleteTodaysClockApi,
  deleteClockByUserAndDay as deleteClockByUserAndDayApi,
  manualEntryRecord as manualEntryRecordApi,
  manualEntryRecordByUser as manualEntryRecordByUserApi,
  updateEntryTimeRecord as updateEntryTimeRecordApi,
} from "./api";
import {
  fetchTodaysClockSuccess,
  fetchTodaysClockFailure,
  deleteTodaysClockSuccess,
  deleteTodaysClockFailure,
  manualEntrySuccess,
  manualEntryFailure,
  updateEntryTimeRecordFailure,
  updateEntryTimeRecordSuccess,
  fetchWeeklyClockFailure,
  fetchWeeklyClockSuccess,
  fetchDailyClockFailure,
  fetchDailyClockSuccess,
  linkTimeRecordSuccess,
  linkTimeRecordFailure,
  fetchWorkedMonthFailure,
  fetchWorkedMonthSuccess,
  manualEntryByUserFailure,
  manualEntryByUserSuccess,
  fetchDailyClockByUserFailure,
  fetchDailyClockByUserSuccess,
  linkTimeRecordByUserAndDayFailure,
  linkTimeRecordByUserAndDaySuccess,
  deleteClockByUserAndDayFailure,
  deleteClockByUserAndDaySuccess,
} from "./actions";
import {
  DELETE_CLOCK_BY_USER_AND_DAY_REQUEST,
  DELETE_TODAYS_CLOCK_REQUEST,
  FETCH_DAILY_CLOCK_BY_USER_REQUEST,
  FETCH_DAILY_CLOCK_REQUEST,
  FETCH_TODAYS_CLOCK_REQUEST,
  FETCH_WEEKLY_CLOCK_REQUEST,
  FETCH_WORKED_MONTH_REQUEST,
  LINK_TIME_RECORD_BY_USER_AND_DAY_REQUEST,
  LINK_TIME_RECORD_REQUEST,
  MANUAL_ENTRY_BY_USER_REQUEST,
  MANUAL_ENTRY_REQUEST,
  UPDATE_ENTRY_TIME_RECORD_REQUEST,
} from "./actionTypes";

import { AxiosResponse } from "axios";
import {
  ClockRecord,
  ClockRecords,
  DeleteTimeClock,
  LinkTimeRecords,
  LinkTimeRecordsByUserAndDay,
  UserAndDayPairs,
  WorkedMonth,
} from "src/types";
import {
  DeleteTodaysClockRequest,
  LinkTimeRecordRequest,
  UpdateEntryTimeRecordRequest,
  ManualEntryRequest,
  FetchDailyClockRequest,
  FetchWorkedMonthRequest,
  ManualEntryByUserRequest,
  FetchDailyClockByUserRequest,
  LinkTimeRecordByUserAndDayRequest,
  DeleteClockByUserAndDayRequest,
  FetchTodaysClockRequest,
  FetchWeeklyClockRequest,
} from "./types";

function* fetchTodaysClockSaga({ payload }: FetchTodaysClockRequest) {
  try {
    const response: AxiosResponse<ClockRecords> = yield call(
      fetchTodaysClockApi,
      payload
    );

    yield put(
      fetchTodaysClockSuccess({
        todaysClocks: response.data,
      })
    );
  } catch (e) {
    yield put(
      fetchTodaysClockFailure({
        // @ts-ignore
        error: e?.response?.data?.message,
      })
    );
  }
}

function* fetchWorkedMonthSaga({ payload }: FetchWorkedMonthRequest) {
  try {
    const response: AxiosResponse<WorkedMonth> = yield call(
      fetchWorkedMonthApi,
      payload
    );

    yield put(
      fetchWorkedMonthSuccess({
        workedMonth: response.data,
      })
    );
  } catch (e) {
    yield put(
      fetchWorkedMonthFailure({
        // @ts-ignore
        error: e?.response?.data?.message,
      })
    );
  }
}

function* fetchWeeklyClockSaga({ payload }: FetchWeeklyClockRequest) {
  try {
    const response: AxiosResponse<ClockRecords> = yield call(
      fetchWeeklyClockApi,
      payload
    );

    yield put(
      fetchWeeklyClockSuccess({
        todaysClocks: response.data,
      })
    );
  } catch (e) {
    yield put(
      fetchWeeklyClockFailure({
        // @ts-ignore
        error: e?.response?.data?.message,
      })
    );
  }
}

function* fetchDailyClockSaga({ payload }: FetchDailyClockRequest) {
  try {
    const response: AxiosResponse<ClockRecords> = yield call(
      fetchDailyClockApi,
      payload
    );

    yield put(
      fetchDailyClockSuccess({
        todaysClocks: response.data,
      })
    );
  } catch (e) {
    yield put(
      fetchDailyClockFailure({
        // @ts-ignore
        error: e?.response?.data?.message,
      })
    );
  }
}

function* fetchDailyClockByUserSaga({ payload }: FetchDailyClockByUserRequest) {
  try {
    const response: AxiosResponse<ClockRecords> = yield call(
      fetchDailyClockByUserApi,
      payload
    );

    yield put(
      fetchDailyClockByUserSuccess({
        todaysClocks: response.data,
      })
    );
  } catch (e) {
    yield put(
      fetchDailyClockByUserFailure({
        // @ts-ignore
        error: e?.response?.data?.message,
      })
    );
  }
}

function* linkTimeRecordSaga({ payload, callbacks }: LinkTimeRecordRequest) {
  try {
    const response: AxiosResponse<LinkTimeRecords> = yield call(
      linkTimeRecordApi,
      payload
    );

    yield put(
      linkTimeRecordSuccess({
        linkTimeClocks: response.data,
      })
    );

    if (callbacks?.onSuccess) {
      yield call(callbacks.onSuccess);
    }
  } catch (e) {
    yield put(
      linkTimeRecordFailure({
        // @ts-ignore
        error: e?.response?.data?.message,
      })
    );
  }
}

function* linkTimeRecordByUserAndDaySaga({
  payload,
  callbacks,
}: LinkTimeRecordByUserAndDayRequest) {
  try {
    const response: AxiosResponse<LinkTimeRecordsByUserAndDay> = yield call(
      linkTimeRecordByUserAndDayApi,
      payload
    );

    yield put(
      linkTimeRecordByUserAndDaySuccess({
        data: response.data,
      })
    );

    if (callbacks?.onSuccess) {
      yield call(callbacks.onSuccess);
    }
  } catch (e) {
    yield put(
      linkTimeRecordByUserAndDayFailure({
        // @ts-ignore
        error: e?.response?.data?.message,
      })
    );
  }
}

function* deleteClockByUserAndDaySaga({
  payload,
  callbacks,
}: DeleteClockByUserAndDayRequest) {
  try {
    const response: AxiosResponse<UserAndDayPairs> = yield call(
      deleteClockByUserAndDayApi,
      payload
    );

    yield put(
      deleteClockByUserAndDaySuccess({
        data: response.data,
      })
    );

    if (callbacks?.onSuccess) {
      yield call(callbacks.onSuccess);
    }
  } catch (e) {
    yield put(
      deleteClockByUserAndDayFailure({
        // @ts-ignore
        error: e?.response?.data?.message,
      })
    );
  }
}

function* deleteTodaysClockSaga({
  payload,
  callbacks,
}: DeleteTodaysClockRequest) {
  try {
    const response: AxiosResponse<DeleteTimeClock> = yield call(
      deleteTodaysClockApi,
      payload
    );

    yield put(
      deleteTodaysClockSuccess({
        timeClockId: response.data,
      })
    );

    if (callbacks?.onSuccess) {
      yield call(callbacks.onSuccess);
    }
  } catch (e) {
    yield put(
      deleteTodaysClockFailure({
        // @ts-ignore
        error: e?.response?.data?.message,
      })
    );
  }
}

function* manualEntryRecordSaga({ payload, callbacks }: ManualEntryRequest) {
  try {
    const response: AxiosResponse<ClockRecord> = yield call(
      manualEntryRecordApi,
      payload
    );

    yield put(
      manualEntrySuccess({
        clockRecord: response.data,
      })
    );

    if (callbacks?.onSuccess) {
      yield call(callbacks.onSuccess);
    }
  } catch (e) {
    yield put(
      manualEntryFailure({
        // @ts-ignore
        error: e?.response?.data?.message,
      })
    );
  }
}

function* manualEntryRecordByUserSaga({
  payload,
  callbacks,
}: ManualEntryByUserRequest) {
  try {
    const response: AxiosResponse<ClockRecord> = yield call(
      manualEntryRecordByUserApi,
      payload
    );

    yield put(
      manualEntryByUserSuccess({
        clockRecord: response.data,
      })
    );

    if (callbacks?.onSuccess) {
      yield call(callbacks.onSuccess);
    }
  } catch (e) {
    yield put(
      manualEntryByUserFailure({
        // @ts-ignore
        error: e?.response?.data?.message,
      })
    );
  }
}

function* updateEntryTimeRecordSaga({
  payload,
  callbacks,
}: UpdateEntryTimeRecordRequest) {
  try {
    const response: AxiosResponse<ClockRecord> = yield call(
      updateEntryTimeRecordApi,
      payload
    );

    yield put(
      updateEntryTimeRecordSuccess({
        clockRecord: response.data,
      })
    );

    if (callbacks?.onSuccess) {
      yield call(callbacks.onSuccess);
    }
  } catch (e) {
    yield put(
      updateEntryTimeRecordFailure({
        // @ts-ignore
        error: e?.response?.data?.message,
      })
    );
  }
}

function* clocksSaga() {
  yield all([
    takeLatest(FETCH_TODAYS_CLOCK_REQUEST, fetchTodaysClockSaga),
    takeLatest(FETCH_WEEKLY_CLOCK_REQUEST, fetchWeeklyClockSaga),
    takeLatest(FETCH_DAILY_CLOCK_REQUEST, fetchDailyClockSaga),
    takeLatest(FETCH_DAILY_CLOCK_BY_USER_REQUEST, fetchDailyClockByUserSaga),
    takeLatest(FETCH_WORKED_MONTH_REQUEST, fetchWorkedMonthSaga),
    takeLatest(DELETE_TODAYS_CLOCK_REQUEST, deleteTodaysClockSaga),
    takeLatest(
      DELETE_CLOCK_BY_USER_AND_DAY_REQUEST,
      deleteClockByUserAndDaySaga
    ),
    takeLatest(LINK_TIME_RECORD_REQUEST, linkTimeRecordSaga),
    takeLatest(
      LINK_TIME_RECORD_BY_USER_AND_DAY_REQUEST,
      linkTimeRecordByUserAndDaySaga
    ),
    takeLatest(MANUAL_ENTRY_REQUEST, manualEntryRecordSaga),
    takeLatest(MANUAL_ENTRY_BY_USER_REQUEST, manualEntryRecordByUserSaga),
    takeLatest(UPDATE_ENTRY_TIME_RECORD_REQUEST, updateEntryTimeRecordSaga),
  ]);
}

export default clocksSaga;
