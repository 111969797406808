import { createSelector } from "reselect";

import { AppState } from "../rootReducer";

const getAttachments = (state: AppState) => state.attachments;

export const getAttachmentsSelector = createSelector(
  getAttachments,
  (attachment) => attachment
);
