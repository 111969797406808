import * as React from "react";
import { SVGProps } from "react";

interface Props {
  color?: string;
}

const ImageIcon: React.FC<Props> = (
  { color },
  props: SVGProps<SVGSVGElement>
) => (
  <svg width={18} height={18} fill="none" {...props}>
    <path
      d="M14.444 2H3.556C2.696 2 2 2.696 2 3.556v10.888C2 15.304 2.696 16 3.556 16h10.888c.86 0 1.556-.696 1.556-1.556V3.556C16 2.696 15.304 2 14.444 2Z"
      stroke={color ? color : "#0D6FF0"}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.278 7.444a1.167 1.167 0 1 0 0-2.333 1.167 1.167 0 0 0 0 2.333ZM16 11.333l-3.889-3.889L3.556 16"
      stroke={color ? color : "#0D6FF0"}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ImageIcon;
