import { isMobile } from "mobile-device-detect";
export const styles = {
  boxContainer: {
    display: "flex",
    flexDirection: "column",
    border: "1px solid #F0F0F0",
    backgroundColor: "#fff",
    borderRadius: "4px",
    padding: "24px 22px",
  },
  topContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  topContainerProject: {
    width: "fit-content",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  pageTitle: {
    fontSize: isMobile ? "20px" : "32px",
    fontWeight: isMobile ? 600 : 500,
    color: "#000",
  },
  moreContainer: {
    backgroundColor: "#fff",
    borderRadius: "4px",
    border: "1px solid #F0F0F0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 30px",
  },
  moreText: {
    fontSize: "16px",
    fontWeight: 400,
    color: "#8C8C8C",
  },
  moreButton: {
    fontSize: "16px",
    fontWeight: 400,
    color: "#0D6FF0",
  },
  teamContainer: {
    marginTop: "30px",
    padding: "30px 20px",
    backgroundColor: "#fff",
    borderRadius: "4px",
    border: "1px solid #F0F0F0",
  },
  input: {
    marginTop: 0,
    width: isMobile ? "100%" : "300px",
  },
  addButtonContainer: {
    display: "flex",
    alignItems: "center",
  },
  addButtonText: {
    fontSize: isMobile ? "14px" : "16px",
    fontWeight: 500,
    ml: "10px",
    textTransform: "initial",
  },
  pagination: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  tabsWrapper: {
    width: "100%",
    borderBottom: "1px solid #F0F0F0",
    overflow: isMobile ? "scroll" : "visible",
  },
  tabWrapper: {
    textTransform: "capitalize",
    color: "#262626",
    fontWeight: isMobile ? 600 : 500,
    minHeight: "auto",
  },
  activeLink: {
    maxWidth: "200px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    cursor: "pointer",
    textDecoration: "none",
  },
  countContainer: {
    width: "fit-content",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px 10px",
    border: "1px solid transparent",
    backgroundColor: "#ECF3FE",
    borderRadius: "4px",
  },
  emptyContainer: {
    width: "fit-content",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px 10px",
    border: "1px solid #F0F0F0",
    backgroundColor: "transparent",
    borderRadius: "4px",
  },
  threeDots: {
    marginTop: "10px",
    backgroundColor: "#FFF",
    border: "1px solid #F0F0F0",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "40px",
    minHeight: "40px",
  },
  noData: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: isMobile ? "140px" : "340px",
    backgroundColor: "#F0F0F0",
    borderRadius: "4px",
  },
  noDataText: {
    fontWeight: 400,
    textAlign: "center",
    fontSize: isMobile ? "14px" : "18px",
    color: "#8C8C8C",
  },
};
