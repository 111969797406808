export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const SIGN_UP_REQUEST = "SIGN_UP_REQUEST";
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";
export const SIGN_UP_FAILURE = "SIGN_UP_FAILURE";

export const EMAIL_CONFIRMATION_REQUEST = "EMAIL_CONFIRMATION_REQUEST";
export const EMAIL_CONFIRMATION_SUCCESS = "EMAIL_CONFIRMATION_SUCCESS";
export const EMAIL_CONFIRMATION_FAILURE = "EMAIL_CONFIRMATION_FAILURE";

export const CURRENT_USER_DATA_REQUEST = "CURRENT_USER_DATA_REQUEST";
export const CURRENT_USER_DATA_SUCCESS = "CURRENT_USER_DATA_SUCCESS";
export const CURRENT_USER_DATA_FAILURE = "CURRENT_USER_DATA_FAILURE";

export const UPDATE_USER_DATA_REQUEST = "UPDATE_USER_DATA_REQUEST";
export const UPDATE_USER_DATA_SUCCESS = "UPDATE_USER_DATA_SUCCESS";
export const UPDATE_USER_DATA_FAILURE = "UPDATE_USER_DATA_FAILURE";

export const DELETE_USER_PROFILE_PICTURE_REQUEST =
  "DELETE_USER_PROFILE_PICTURE_REQUEST";
export const DELETE_USER_PROFILE_PICTURE_SUCCESS =
  "DELETE_USER_PROFILE_PICTURE_SUCCESS";
export const DELETE_USER_PROFILE_PICTURE_FAILURE =
  "DELETE_USER_PROFILE_PICTURE_FAILURE";

export const DELETE_CURRENT_USER_REQUEST = "DELETE_CURRENT_USER_REQUEST";
export const DELETE_CURRENT_USER_SUCCESS = "DELETE_CURRENT_USER_SUCCESS";
export const DELETE_CURRENT_USER_FAILURE = "DELETE_CURRENT_USER_FAILURE";

export const PASSWORD_RESET_REQUEST = "PASSWORD_RESET_REQUEST";
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS";
export const PASSWORD_RESET_FAILURE = "PASSWORD_RESET_FAILURE";

export const CHECK_PASSWORD_RESET_REQUEST = "CHECK_PASSWORD_RESET_REQUEST";
export const CHECK_PASSWORD_RESET_SUCCESS = "CHECK_PASSWORD_RESET_SUCCESS";
export const CHECK_PASSWORD_RESET_FAILURE = "CHECK_PASSWORD_RESET_FAILURE";

export const NEW_PASSWORD_REQUEST = "NEW_PASSWORD_REQUEST";
export const NEW_PASSWORD_SUCCESS = "NEW_PASSWORD_SUCCESS";
export const NEW_PASSWORD_FAILURE = "NEW_PASSWORD_FAILURE";

export const SEND_VERIFICATION_SMS_REQUEST = "SEND_VERIFICATION_SMS_REQUEST";
export const SEND_VERIFICATION_SMS_SUCCESS = "SEND_VERIFICATION_SMS_SUCCESS";
export const SEND_VERIFICATION_SMS_FAILURE = "SEND_VERIFICATION_SMS_FAILURE";

export const SEND_VERIFICATION_CODE_REQUEST = "SEND_VERIFICATION_CODE_REQUEST";
export const SEND_VERIFICATION_CODE_SUCCESS = "SEND_VERIFICATION_CODE_SUCCESS";
export const SEND_VERIFICATION_CODE_FAILURE = "SEND_VERIFICATION_CODE_FAILURE";

export const GET_ORGANIZATION_REQUEST = "GET_ORGANIZATION_REQUEST";
export const GET_ORGANIZATION_SUCCESS = "GET_ORGANIZATION_SUCCESS";
export const GET_ORGANIZATION_FAILURE = "GET_ORGANIZATION_FAILURE";

export const UPDATE_ORGANIZATION_REQUEST = "UPDATE_ORGANIZATION_REQUEST";
export const UPDATE_ORGANIZATION_SUCCESS = "UPDATE_ORGANIZATION_SUCCESS";
export const UPDATE_ORGANIZATION_FAILURE = "UPDATE_ORGANIZATION_FAILURE";

export const DELETE_ORGANIZATION_REQUEST = "DELETE_ORGANIZATION_REQUEST";
export const DELETE_ORGANIZATION_SUCCESS = "DELETE_ORGANIZATION_SUCCESS";
export const DELETE_ORGANIZATION_FAILURE = "DELETE_ORGANIZATION_FAILURE";

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

export const CLEAN_ERROR = "CLEAN_ERROR";

export const RESET_STATE = "RESET_STATE";

export const LOG_OUT = "LOG_OUT";
