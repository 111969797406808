import React, { useEffect } from "react";
import { Project, User } from "src/types";
import ModalWrapper from "./ModalWrapper";
import CurrentReportBreakdown from "../CurrentReportDreakdown";
import { reportBreakdownRequest } from "src/store/report/actions";
import { useDispatch } from "react-redux";

interface ModalProps {
  closeModal: () => void;
  isOpen: boolean;
  startDate: string;
  endDate: string;
  userIds: User[];
  projectIds: Project[];
  modalActionType: {
    action: string;
    page: string;
  };
}

const CurrentReportBreakdownModal: React.FC<ModalProps> = ({
  isOpen,
  modalActionType,
  startDate,
  endDate,
  userIds,
  projectIds,
  closeModal,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      reportBreakdownRequest({
        startDate,
        endDate,
        userIds: userIds.length ? userIds.map((el: User) => el.id) : null,
        projectIds: projectIds.length
          ? projectIds.map((el: Project) => el.id)
          : null,
      })
    );
  }, []);

  return (
    <>
      <ModalWrapper
        closeModal={closeModal}
        isOpen={isOpen}
        maxWidth="70%"
        modalActionType={modalActionType}
      >
        <>
          <CurrentReportBreakdown closeModal={closeModal} />
        </>
      </ModalWrapper>
    </>
  );
};

export default CurrentReportBreakdownModal;
