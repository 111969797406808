import {
  Box,
  Typography,
  FormControl,
  MenuItem,
  TextField,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../Input";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ModalWrapper from "../ModalWrapper";
import Select from "../../Select";
import { toast } from "react-toastify";
import { isFieldHasError } from "src/utils";
import { addProjectRequest } from "src/store/projects/actions";
import americaStates from "src/AmericaStates.json";
import { getProjectSelector } from "src/store/projects/selectors";
import { styles } from "./addProject.module";
import { AppRoutes } from "src/constants";
import { useNavigate } from "react-router-dom";
import Button from "src/components/Button";
import theme from "src/theme";
import { ZIP_NUMBER_REGEX } from "src/constants";
import { isMobile } from "mobile-device-detect";

const validationSchema = yup.object().shape({
  projectName: yup.string().required("Project name is required"),
  zip: yup.string().matches(ZIP_NUMBER_REGEX, "Zip code is not valid"),
});

interface ModalProps {
  closeModal: () => void;
  fetchSuccess: () => void;
  isOpen: boolean;
  modalActionType: {
    action: string;
    page: string;
  };
  subprojectId: string;
  showToast: boolean;
}

const AddProject: React.FC<ModalProps> = ({
  isOpen,
  modalActionType,
  subprojectId,
  showToast,
  closeModal,
  fetchSuccess,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { error, project, isLoadingAddProject } =
    useSelector(getProjectSelector);
  const ref = useRef(null);
  const formik = useFormik({
    initialValues: {
      projectName: "",
      projectDescription: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
    },
    validationSchema: validationSchema,
    onSubmit: ({
      projectName,
      projectDescription,
      address1,
      address2,
      city,
      state,
      zip,
    }) => {
      const projectNameTrim = projectName.trim();
      if (!projectNameTrim) return;
      else {
        dispatch(
          addProjectRequest(
            {
              projectName: projectNameTrim.trim(),
              projectDescription: projectDescription
                ? projectDescription.trim()
                : null,
              parentId: subprojectId,
              address: {
                address: address1 ? address1.trim() : null,
                addressTwo: address2 ? address2.trim() : null,
                city: city ? city.trim() : null,
                state: state ? state : null,
                zip: zip ? zip.toString() : null,
              },
            },
            { onSuccess: addProjectSuccess }
          )
        );
      }
    },
  });

  const addProjectSuccess = () => {
    fetchSuccess();
    closeModal();
    toast.success(
      <>
        <Typography fontSize="16px" color="#262626">
          Project Added
        </Typography>
        <Typography position="absolute" fontSize="14px" color="#262626">
          Project added successfully.
        </Typography>
        {showToast && (
          <Typography
            position="absolute"
            top="60px"
            fontSize="14px"
            color="#0D6FF0"
            onClick={() => navigate(`${AppRoutes.ProjectItem}/${ref.current}`)}
          >
            View the Project
          </Typography>
        )}
      </>,
      {
        style: styles.toastStyle,
      }
    );
  };

  useEffect(() => {
    ref.current = project?.id;
  }, [project?.id]);

  return (
    <>
      <ModalWrapper
        closeModal={closeModal}
        isOpen={isOpen}
        modalActionType={modalActionType}
        maxWidth={isMobile ? "350px" : "580px"}
      >
        <>
          <form onSubmit={formik.handleSubmit}>
            <Box>
              <Input
                type="text"
                fullWidth
                required
                size="small"
                label="Project Name"
                id="projectName"
                inputProps={{ maxLength: 200 }}
                value={formik.values.projectName}
                onChange={formik.handleChange}
                error={isFieldHasError({
                  formik,
                  fieldName: "projectName",
                  isGlobalError: Boolean(error),
                })}
                helperText={
                  formik.touched.projectName && formik.errors.projectName
                }
              />
            </Box>
            <Box mt="20px">
              <Typography mb={"8px"} color="#8C8C8C">
                Project Description
              </Typography>
              <TextField
                multiline
                fullWidth
                inputProps={{ maxLength: 5000 }}
                rows={4}
                id="projectDescription"
                value={formik.values.projectDescription}
                onChange={formik.handleChange}
              />
            </Box>
            <Box mt="20px">
              <Input
                type="text"
                fullWidth
                size="small"
                label="Address 1"
                inputProps={{ maxLength: 200 }}
                id="address1"
                value={formik.values.address1}
                onChange={formik.handleChange}
              />
            </Box>
            <Box mt="20px">
              <Input
                type="text"
                fullWidth
                size="small"
                label="Address 2 (option)"
                inputProps={{ maxLength: 100 }}
                id="address2"
                value={formik.values.address2}
                onChange={formik.handleChange}
              />
            </Box>
            <Box sx={styles.boxContainer}>
              <Box sx={{ width: "48%" }}>
                <Input
                  type="text"
                  fullWidth
                  size="small"
                  label="City"
                  inputProps={{ maxLength: 100 }}
                  id="city"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                />
              </Box>
              <Box sx={{ width: "24%" }}>
                <Typography mb={"8px"} color="#8C8C8C">
                  State
                </Typography>
                <FormControl fullWidth>
                  <Select
                    value={formik.values.state}
                    height="46px"
                    fontSize="16px"
                    onChange={(opt) =>
                      formik.setFieldValue("state", opt.target.value)
                    }
                    IconComponent={ArrowDropDownIcon}
                  >
                    {americaStates.map((elem: string) => (
                      <MenuItem key={elem} value={elem}>
                        {elem}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ width: "24%", position: "relative" }}>
                <Input
                  type="text"
                  fullWidth
                  size="small"
                  label="Zip"
                  id="zip"
                  value={formik.values.zip}
                  onChange={formik.handleChange}
                  error={isFieldHasError({
                    formik,
                    fieldName: "zip",
                    isGlobalError: Boolean(error),
                  })}
                  helperText={formik.touched.zip && formik.errors.zip}
                />
              </Box>
            </Box>
            <Box sx={styles.actionButtons}>
              <Box sx={styles.buttonContainer}>
                <Box sx={{ width: "45%" }}>
                  <Button
                    variant="outlined"
                    fullWidth
                    size="small"
                    color="secondary"
                    onClick={closeModal}
                  >
                    <Typography
                      fontSize="14px"
                      color={theme.palette.text.primary}
                    >
                      Cancel
                    </Typography>
                  </Button>
                </Box>
                <Box sx={{ width: "45%" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    fullWidth
                    type="submit"
                    disabled={isLoadingAddProject}
                  >
                    <Typography fontSize="14px">Save</Typography>
                  </Button>
                </Box>
              </Box>
            </Box>
          </form>
        </>
      </ModalWrapper>
    </>
  );
};

export default AddProject;
