import { Box, Typography } from "@mui/material";
import React from "react";
import Button from "../Button";
import ModalWrapper from "./ModalWrapper";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "src/constants";
import { toast } from "react-toastify";

const styles = {
  addButtonContainer: {
    display: "flex",
    alignItems: "center",
  },
  addButtonText: {
    fontWeight: 500,
    fontSize: { xs: "14px", md: "16px" },
    ml: "10px",
    textTransform: "initial",
  },
  toastStyle: {
    backgroundColor: "#FEE6B9",
    border: "1px solid #B87A00",
    width: "350px",
    margin: "16px auto 0",
  },
};

interface ModalProps {
  closeModal: () => void;
  onFetchSuccess: () => void;
  elementDate: string;
  isOpen: boolean;
  modalActionType: {
    action: string;
    page: string;
  };
}

const AddAttachmentModal: React.FC<ModalProps> = ({
  elementDate,
  isOpen,
  modalActionType,
  closeModal,
}) => {
  const navigate = useNavigate();

  // https://capacitorjs.com/docs/apis/camera
  const takePicture = async () => {
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: false,
      resultType: CameraResultType.Uri,
      saveToGallery: true,
      source: CameraSource.Camera,
    });
    const imageUrl = {
      webPath: image.webPath,
      path: image.path,
      exif: image.exif,
      format: image.format,
    };
    navigate(AppRoutes.AddAttachments, {
      state: { photos: [imageUrl], date: elementDate },
    });
  };

  const pickImages = async () => {
    const image = await Camera.pickImages({
      quality: 90,
    });
    if (image.photos.length > 20) {
      toast.warning(
        <>
          <Typography fontSize="16px" color="#262626">
            You can only upload at most 20 files!
          </Typography>
        </>,
        {
          style: styles.toastStyle,
        }
      );
      return closeModal();
    } else {
      navigate(AppRoutes.AddAttachments, {
        state: { photos: image.photos, date: elementDate },
      });
    }
  };

  return (
    <>
      <ModalWrapper
        closeModal={closeModal}
        isOpen={isOpen}
        modalActionType={modalActionType}
        maxWidth="350px"
      >
        <>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={takePicture}
          >
            <Box sx={styles.addButtonContainer}>
              <Typography sx={styles.addButtonText}>Take Photo</Typography>
            </Box>
          </Button>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={pickImages}
            mt="10px"
          >
            <Box sx={styles.addButtonContainer}>
              <Typography sx={styles.addButtonText}>
                Choose From Gallery
              </Typography>
            </Box>
          </Button>
          <Button
            fullWidth
            variant="outlined"
            color="secondary"
            onClick={closeModal}
            mt="10px"
          >
            <Box sx={styles.addButtonContainer}>
              <Typography color="#262626" sx={styles.addButtonText}>
                Cancel
              </Typography>
            </Box>
          </Button>
        </>
      </ModalWrapper>
    </>
  );
};

export default AddAttachmentModal;
