import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  fetchReport as fetchReportApi,
  reportBreakdown as reportBreakdownApi,
  reportTimesheet as reportTimesheetApi,
} from "./api";
import {
  fetchReportSuccess,
  fetchReportFailure,
  reportBreakdownFailure,
  reportBreakdownSuccess,
  reportTimesheetFailure,
  reportTimesheetSuccess,
} from "./actions";
import {
  FETCH_REPORT_REQUEST,
  REPORT_BREAKDOWN_REQUEST,
  REPORT_TIMESHEET_REQUEST,
} from "./actionTypes";
import { AxiosResponse } from "axios";
import { Breakdown, Report, Timesheet } from "src/types";
import {
  FetchReportRequest,
  ReportBreakdownRequest,
  ReportTimesheetRequest,
} from "./types";

function* fetchReportSaga({ payload, callbacks }: FetchReportRequest) {
  try {
    const response: AxiosResponse<Report> = yield call(fetchReportApi, payload);
    yield put(
      fetchReportSuccess({
        report: response.data,
      })
    );
    if (callbacks?.onSuccess) {
      yield call(callbacks.onSuccess);
    }
  } catch (e) {
    yield put(
      fetchReportFailure({
        // @ts-ignore
        error: e?.response?.data?.message,
      })
    );
  }
}

function* reportBreakdownSaga({ payload }: ReportBreakdownRequest) {
  try {
    const response: AxiosResponse<Breakdown> = yield call(
      reportBreakdownApi,
      payload
    );
    yield put(
      reportBreakdownSuccess({
        usersBreakdown: response.data.usersBreakdown,
        projectsBreakdown: response.data.projectsBreakdown,
      })
    );
  } catch (e) {
    yield put(
      reportBreakdownFailure({
        // @ts-ignore
        error: e?.response?.data?.message,
      })
    );
  }
}

function* reportTimesheetSaga({ payload, callbacks }: ReportTimesheetRequest) {
  try {
    const response: AxiosResponse<Timesheet> = yield call(
      reportTimesheetApi,
      payload
    );
    yield put(
      reportTimesheetSuccess({
        timesheet: response.data,
      })
    );
    if (callbacks?.onSuccess) {
      yield call(callbacks.onSuccess);
    }
  } catch (e) {
    yield put(
      reportTimesheetFailure({
        // @ts-ignore
        error: e?.message,
      })
    );
  }
}

function* attachmentsSaga() {
  yield all([
    takeLatest(FETCH_REPORT_REQUEST, fetchReportSaga),
    takeLatest(REPORT_BREAKDOWN_REQUEST, reportBreakdownSaga),
    takeLatest(REPORT_TIMESHEET_REQUEST, reportTimesheetSaga),
  ]);
}

export default attachmentsSaga;
