import * as React from "react";
import { SVGProps } from "react";

interface Props {
  color?: string;
}

const Download: React.FC<Props> = (
  { color },
  props: SVGProps<SVGSVGElement>
) => (
  <svg width={20} height={20} fill="none" {...props}>
    <path
      d="M17.5 12.5v3.333a1.666 1.666 0 0 1-1.667 1.667H4.167A1.667 1.667 0 0 1 2.5 15.833V12.5M5.833 8.333 10 12.5l4.167-4.167M10 12.5v-10"
      stroke={color ? color : "#8C8C8C"}
      strokeWidth={1.7}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Download;
