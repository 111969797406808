import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  requestCheckStartWatch,
  requestStartWatch,
  requestStopWatch,
} from "./api";
import {
  CHECK_START_WATCH_REQUEST,
  START_WATCH_REQUEST,
  STOP_WATCH_REQUEST,
} from "./actionTypes";
import { AxiosResponse } from "axios";
import {
  startWatchSuccess,
  startWatchFailure,
  stopWatchSuccess,
  stopWatchFailure,
  checkStartWatchFailure,
  checkStartWatchSuccess,
} from "./actions";
import { StartWatchRequest, StopWatchRequest } from "./types";
import { TimeClock, TimeWatch } from "src/types";

function* startWatchSaga({ payload, callbacks }: StartWatchRequest) {
  try {
    const response: AxiosResponse<TimeWatch> = yield call(
      requestStartWatch,
      payload
    );

    yield put(
      startWatchSuccess({
        timeWatch: response.data,
      })
    );

    if (callbacks?.onSuccess) {
      yield call(callbacks.onSuccess);
    }
  } catch (e) {
    yield put(
      startWatchFailure({
        // @ts-ignore
        error: e?.message,
      })
    );
  }
}

function* checkStartWatchSaga() {
  try {
    const response: AxiosResponse<TimeClock> = yield call(
      requestCheckStartWatch
    );

    yield put(
      checkStartWatchSuccess({
        timeClock: response.data,
      })
    );
  } catch (e) {
    yield put(
      checkStartWatchFailure({
        // @ts-ignore
        error: e?.message,
      })
    );
  }
}

function* stopWatchSaga({ payload, callbacks }: StopWatchRequest) {
  try {
    const response: AxiosResponse<TimeWatch> = yield call(
      requestStopWatch,
      payload
    );

    yield put(
      stopWatchSuccess({
        timeWatch: response.data,
      })
    );
    if (callbacks?.onSuccess) {
      yield call(callbacks.onSuccess);
    }
  } catch (e) {
    yield put(
      stopWatchFailure({
        // @ts-ignore
        error: e?.message,
      })
    );
  }
}

function* watchSaga() {
  yield all([
    takeLatest(START_WATCH_REQUEST, startWatchSaga),
    takeLatest(CHECK_START_WATCH_REQUEST, checkStartWatchSaga),
    takeLatest(STOP_WATCH_REQUEST, stopWatchSaga),
  ]);
}

export default watchSaga;
