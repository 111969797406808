import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  fetchProject as fetchProjectApi,
  fetchProjectAll as fetchProjectAllApi,
  fetchSubProject as fetchSubProjectApi,
  fetchProjectID as fetchProjectIDApi,
  fetchCommentProject as fetchCommentProjectApi,
  searchProject as searchProjectApi,
  addProject as addProjectApi,
  updateProject as updateProjectApi,
  deleteProject as deleteProjectApi,
  addCommentProject as addCommentProjectApi,
  updateCommentProject as updateCommentProjectApi,
  deleteCommentProject as deleteCommentProjectApi,
} from "./api";
import {
  ADD_COMMENT_PROJECT_REQUEST,
  ADD_PROJECT_REQUEST,
  DELETE_COMMENT_PROJECT_REQUEST,
  DELETE_PROJECT_REQUEST,
  FETCH_COMMENT_PROJECT_REQUEST,
  FETCH_PROJECT_ALL_REQUEST,
  FETCH_PROJECT_ID_REQUEST,
  FETCH_PROJECT_REQUEST,
  FETCH_SUBPROJECT_REQUEST,
  SEARCH_PROJECT_REQUEST,
  UPDATE_COMMENT_PROJECT_REQUEST,
  UPDATE_PROJECT_REQUEST,
} from "./actionTypes";
import { AxiosResponse } from "axios";
import {
  fetchProjectSuccess,
  fetchProjectFailure,
  fetchSubProjectFailure,
  fetchSubProjectSuccess,
  fetchProjectIDFailure,
  fetchProjectIDSuccess,
  addProjectFailure,
  addProjectSuccess,
  deleteProjectFailure,
  deleteProjectSuccess,
  updateProjectFailure,
  updateProjectSuccess,
  fetchCommentProjectFailure,
  fetchCommentProjectSuccess,
  addCommentProjectFailure,
  addCommentProjectSuccess,
  deleteCommentProjectFailure,
  deleteCommentProjectSuccess,
  updateCommentProjectFailure,
  updateCommentProjectSuccess,
  fetchProjectAllFailure,
  fetchProjectAllSuccess,
} from "./actions";
import {
  ProjectID,
  Project,
  ProjectComment,
  ProjectCommentID,
  ProjectAll,
} from "src/types";
import {
  AddCommentProjectRequest,
  AddProjectRequest,
  DeleteCommentProjectRequest,
  DeleteProjectRequest,
  FetchCommentProjectRequest,
  FetchProjectIDRequest,
  FetchProjectRequest,
  FetchSubProjectRequest,
  SearchProjectRequest,
  UpdateCommentProjectRequest,
  UpdateProjectRequest,
} from "./types";

function* fetchProjectSaga({ payload, callbacks }: FetchProjectRequest) {
  try {
    const response: AxiosResponse<Project[]> = yield call(
      fetchProjectApi,
      payload
    );

    yield put(
      fetchProjectSuccess({
        projects: response.data,
      })
    );
    if (callbacks?.onSuccess) {
      yield call(callbacks.onSuccess);
    }
  } catch (e) {
    yield put(
      fetchProjectFailure({
        // @ts-ignore
        error: e?.response?.data?.message,
      })
    );
  }
}

function* fetchProjectAllSaga() {
  try {
    const response: AxiosResponse<ProjectAll[]> = yield call(
      fetchProjectAllApi
    );

    yield put(
      fetchProjectAllSuccess({
        projectAll: response.data,
      })
    );
  } catch (e) {
    yield put(
      fetchProjectAllFailure({
        // @ts-ignore
        error: e?.response?.data?.message,
      })
    );
  }
}

function* fetchSubProjectSaga({ payload, callbacks }: FetchSubProjectRequest) {
  try {
    const response: AxiosResponse<Project[]> = yield call(
      fetchSubProjectApi,
      payload
    );

    yield put(
      fetchSubProjectSuccess({
        projects: response.data,
      })
    );
    if (callbacks?.onSuccess) {
      yield call(callbacks.onSuccess);
    }
  } catch (e) {
    yield put(
      fetchSubProjectFailure({
        // @ts-ignore
        error: e?.response?.data?.message,
      })
    );
  }
}

function* fetchProjectIDSaga({ payload }: FetchProjectIDRequest) {
  try {
    const response: AxiosResponse<Project> = yield call(
      fetchProjectIDApi,
      payload
    );

    yield put(
      fetchProjectIDSuccess({
        project: response.data,
      })
    );
  } catch (e) {
    yield put(
      fetchProjectIDFailure({
        // @ts-ignore
        error: e?.response?.data?.message,
      })
    );
  }
}

function* fetchCommentProjectSaga({ payload }: FetchCommentProjectRequest) {
  try {
    const response: AxiosResponse<ProjectComment[]> = yield call(
      fetchCommentProjectApi,
      payload
    );

    yield put(
      fetchCommentProjectSuccess({
        commentProjects: response.data,
      })
    );
  } catch (e) {
    yield put(
      fetchCommentProjectFailure({
        // @ts-ignore
        error: e?.response?.data?.message,
      })
    );
  }
}

function* searchProjectSaga({ payload, callbacks }: SearchProjectRequest) {
  try {
    const response: AxiosResponse<Project[]> = yield call(
      searchProjectApi,
      payload
    );

    yield put(
      fetchProjectSuccess({
        projects: response.data,
      })
    );
    if (callbacks?.onSuccess) {
      yield call(callbacks.onSuccess);
    }
  } catch (e) {
    yield put(
      fetchProjectFailure({
        // @ts-ignore
        error: e?.response?.data?.message,
      })
    );
  }
}

function* addProjectSaga({ payload, callbacks }: AddProjectRequest) {
  try {
    const response: AxiosResponse<Project> = yield call(addProjectApi, payload);
    yield put(
      addProjectSuccess({
        project: response.data,
      })
    );

    if (callbacks?.onSuccess) {
      yield call(callbacks.onSuccess);
    }
  } catch (e) {
    yield put(
      addProjectFailure({
        // @ts-ignore
        error: e?.response?.data?.message,
      })
    );
  }
}

function* updateProjectSaga({ payload, callbacks }: UpdateProjectRequest) {
  try {
    const response: AxiosResponse<Project> = yield call(
      updateProjectApi,
      payload
    );
    yield put(
      updateProjectSuccess({
        project: response.data,
      })
    );

    if (callbacks?.onSuccess) {
      yield call(callbacks.onSuccess);
    }
  } catch (e) {
    yield put(
      updateProjectFailure({
        // @ts-ignore
        error: e?.response?.data?.message,
      })
    );
  }
}

function* deleteProjectSaga({ payload, callbacks }: DeleteProjectRequest) {
  try {
    const response: AxiosResponse<ProjectID> = yield call(
      deleteProjectApi,
      payload
    );
    yield put(
      deleteProjectSuccess({
        data: response.data,
      })
    );

    if (callbacks?.onSuccess) {
      yield call(callbacks.onSuccess);
    }
  } catch (e) {
    yield put(
      deleteProjectFailure({
        // @ts-ignore
        error: e?.response?.data?.message,
      })
    );
  }
}

function* addCommentProjectSaga({
  payload,
  callbacks,
}: AddCommentProjectRequest) {
  try {
    const response: AxiosResponse<ProjectComment> = yield call(
      addCommentProjectApi,
      payload
    );
    yield put(
      addCommentProjectSuccess({
        commentProject: response.data,
      })
    );

    if (callbacks?.onSuccess) {
      yield call(callbacks.onSuccess);
    }
  } catch (e) {
    yield put(
      addCommentProjectFailure({
        // @ts-ignore
        error: e?.response?.data?.message,
      })
    );
  }
}

function* updateCommentProjectSaga({
  payload,
  callbacks,
}: UpdateCommentProjectRequest) {
  try {
    const response: AxiosResponse<ProjectComment> = yield call(
      updateCommentProjectApi,
      payload
    );
    yield put(
      updateCommentProjectSuccess({
        commentProject: response.data,
      })
    );

    if (callbacks?.onSuccess) {
      yield call(callbacks.onSuccess);
    }
  } catch (e) {
    yield put(
      updateCommentProjectFailure({
        // @ts-ignore
        error: e?.response?.data?.message,
      })
    );
  }
}

function* deleteCommentProjectSaga({
  payload,
  callbacks,
}: DeleteCommentProjectRequest) {
  try {
    const response: AxiosResponse<ProjectCommentID> = yield call(
      deleteCommentProjectApi,
      payload
    );
    yield put(
      deleteCommentProjectSuccess({
        data: response.data,
      })
    );

    if (callbacks?.onSuccess) {
      yield call(callbacks.onSuccess);
    }
  } catch (e) {
    yield put(
      deleteCommentProjectFailure({
        // @ts-ignore
        error: e?.response?.data?.message,
      })
    );
  }
}

function* watchSaga() {
  yield all([
    takeLatest(FETCH_PROJECT_REQUEST, fetchProjectSaga),
    takeLatest(FETCH_PROJECT_ALL_REQUEST, fetchProjectAllSaga),
    takeLatest(FETCH_SUBPROJECT_REQUEST, fetchSubProjectSaga),
    takeLatest(FETCH_PROJECT_ID_REQUEST, fetchProjectIDSaga),
    takeLatest(FETCH_COMMENT_PROJECT_REQUEST, fetchCommentProjectSaga),
    takeLatest(SEARCH_PROJECT_REQUEST, searchProjectSaga),
    takeLatest(ADD_PROJECT_REQUEST, addProjectSaga),
    takeLatest(UPDATE_PROJECT_REQUEST, updateProjectSaga),
    takeLatest(DELETE_PROJECT_REQUEST, deleteProjectSaga),
    takeLatest(ADD_COMMENT_PROJECT_REQUEST, addCommentProjectSaga),
    takeLatest(UPDATE_COMMENT_PROJECT_REQUEST, updateCommentProjectSaga),
    takeLatest(DELETE_COMMENT_PROJECT_REQUEST, deleteCommentProjectSaga),
  ]);
}

export default watchSaga;
