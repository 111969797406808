import { combineReducers } from "redux";

import authReducer from "./auth/reducer";
import clocksReducer from "./clocks/reducer";
import watchReducer from "./timeWatch/reducer";
import attachmentsReducer from "./attachments/reducer";
import commentsReducer from "./comments/reducer";
import projectReducer from "./projects/reducer";
import usersReducer from "./users/reducer";
import reportReducer from "./report/reducer";
import subscriptionReducer from "./subscription/reducer";

const rootReducer = combineReducers({
  auth: authReducer,
  clocks: clocksReducer,
  watch: watchReducer,
  attachments: attachmentsReducer,
  comments: commentsReducer,
  project: projectReducer,
  users: usersReducer,
  report: reportReducer,
  subscription: subscriptionReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
