import { Box, Breadcrumbs, Tab, Tabs, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { styles } from "./projects.module";
import { getProjectSelector } from "src/store/projects/selectors";
import { useNavigate, Link } from "react-router-dom";
import { Back, ProjectIcon } from "src/assets/icons";
import TabDataSection from "src/components/TabDataSection";
import TabValue from "src/components/TabValue";
import ProjectDetails from "src/components/ProjectTabs/ProjectDetails";
import SubprojectsWrapper from "src/components/ProjectTabs/SubProjectsWrapper";
import ProjectComments from "src/components/ProjectTabs/ProjectComments";
import { AppRoutes } from "src/constants";

interface Props {
  currentTab: number;
  handleTabChange: (event: React.SyntheticEvent, newValue: number) => void;
  fetchProject: () => void;
}

const ProjectItemWeb: React.FC<Props> = ({
  currentTab,
  handleTabChange,
  fetchProject,
}) => {
  const navigate = useNavigate();
  const project = useSelector(getProjectSelector);

  return (
    <>
      {project.project?.parentId && (
        <Box display="flex" mb="20px" alignItems="center" columnGap="8px">
          <ProjectIcon color="#8C8C8C" />
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              to="/projects"
              style={{
                textDecoration: "none",
                color: "#8C8C8C",
              }}
            >
              Projects
            </Link>
            {project.project?.parentId && (
              <Link
                to={`${AppRoutes.ProjectItem}/${project.project?.parentId}`}
                style={{ textDecoration: "none", color: "#8C8C8C" }}
              >
                <Typography sx={styles.activeLink}>
                  {project.project?.parentProjectName}
                </Typography>
              </Link>
            )}
            <Typography color="text.primary" sx={styles.activeLink}>
              {project.project?.projectName}
            </Typography>
          </Breadcrumbs>
        </Box>
      )}
      <Box
        sx={styles.topContainerProject}
        onClick={() => navigate(AppRoutes.Projects)}
      >
        <Back width={19} height={18} color="#000" />
        <Typography sx={[styles.pageTitle, { marginLeft: "15px" }]}>
          {project.project?.projectName}
        </Typography>
      </Box>
      <Box mt="10px" ml="41px">
        <Typography fontSize="16px" fontWeight={500} color="#8C8C8C">
          Project ID {project.project?.numericId}
        </Typography>
      </Box>
      <Box sx={styles.teamContainer}>
        <Box sx={{ marginBottom: "20px" }}>
          <Tabs
            sx={styles.tabsWrapper}
            value={currentTab}
            onChange={handleTabChange}
          >
            <Tab sx={styles.tabWrapper} label="Project Details" />
            <Tab
              sx={styles.tabWrapper}
              label="Subprojects"
              icon={
                <TabValue
                  isSelected={currentTab === 1}
                  count={project.project?.projectCountV?.subProjectCount}
                />
              }
              iconPosition="end"
            />
            <Tab
              sx={styles.tabWrapper}
              label="Projects Comments"
              icon={
                <TabValue
                  isSelected={currentTab === 2}
                  count={project.project?.projectCountV?.commentsCount}
                />
              }
              iconPosition="end"
            />
          </Tabs>
          <TabDataSection mt="20px" border="none">
            {currentTab === 0 && <ProjectDetails fetchSuccess={fetchProject} />}
            {currentTab === 1 && (
              <SubprojectsWrapper fetchProjectID={fetchProject} />
            )}
            {currentTab === 2 && (
              <ProjectComments fetchProjectID={fetchProject} />
            )}
          </TabDataSection>
        </Box>
      </Box>
    </>
  );
};

export default ProjectItemWeb;
