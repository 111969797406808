export const styles = {
  actionButtons: {
    display: "flex",
    justifyContent: "space-between",
    columnGap: "10px",
    marginTop: "20px",
  },
  actionButton: {
    textTransform: "none",
  },
  actionButtonSave: {
    textTransform: "none",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
    },
  },
  toastStyle: {
    backgroundColor: "#F0F9F0",
    border: "1px solid #67BB66",
    width: "320px",
    paddingBottom: "50px",
  },
  boxContainer: {
    mt: "20px",
    display: "flex",
    justifyContent: "space-between",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    width: "30%",
  },
};
