import React, { useEffect, useMemo, useState } from "react";
import { Box, CircularProgress, Theme, Typography } from "@mui/material";
import { Location as LocationIcon } from "src/assets/icons";
import { Geolocation } from "@capacitor/geolocation";
import { isMobile } from "mobile-device-detect";
import GoogleMaps from "./GoogleMaps";
import { Capacitor } from "@capacitor/core";
import {
  AndroidSettings,
  IOSSettings,
  NativeSettings,
} from "capacitor-native-settings";
import { App } from "@capacitor/app";

const styles = {
  container: {
    border: (theme: Theme) => `1px solid ${theme.palette.background.default}`,
    borderRadius: "4px",
    height: "100%",
    backgroundColor: (theme: Theme) => `${theme.palette.background.paper}`,
  },
  descriptionWrapper: {
    py: "11px",
    px: "12px",
    display: "flex",
    alignItems: "center",
  },
  isLoading: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "15px",
    paddingBottom: "15px",
  },
};

interface Props {
  coords?: any[];
  height: string;
}

const Map: React.FC<Props> = ({ coords, height }) => {
  const [addressData, setAddressData] = useState(null);
  const [userPosition, setUserPosition] = useState(null);
  const [loading, setLoading] = useState(true);
  const [globalPermission, setGlobalPermission] = useState(false);
  const [errMessage, setErrMessage] = useState("");

  const showLocation = async () => {
    try {
      const checkPermissions = await Geolocation.checkPermissions();
      if (
        checkPermissions.location === "granted" ||
        checkPermissions.coarseLocation === "granted"
      ) {
        const geolocation = sessionStorage.getItem("geolocation");
        if (geolocation) {
          const geo = JSON.parse(geolocation);
          setUserPosition({
            lat: geo?.lat,
            lng: geo?.lng,
          });
        } else {
          const coordinates = await Geolocation.getCurrentPosition({
            maximumAge: 60000,
          });
          setUserPosition({
            lat: coordinates?.coords?.latitude,
            lng: coordinates?.coords?.longitude,
          });
          sessionStorage.setItem(
            "geolocation",
            JSON.stringify({
              lat: coordinates?.coords?.latitude,
              lng: coordinates?.coords?.longitude,
            })
          );
        }
        setGlobalPermission(false);
        setLoading(false);
      } else if (
        checkPermissions.location === "prompt" ||
        (!isMobile && checkPermissions.location === "prompt-with-rationale") ||
        checkPermissions.coarseLocation === "prompt" ||
        (!isMobile &&
          checkPermissions.coarseLocation === "prompt-with-rationale")
      ) {
        const geolocation = sessionStorage.getItem("geolocation");
        if (geolocation) {
          const geo = JSON.parse(geolocation);
          setUserPosition({
            lat: geo?.lat,
            lng: geo?.lng,
          });
          setGlobalPermission(false);
          setLoading(false);
        } else {
          Geolocation.watchPosition(
            {
              enableHighAccuracy: true,
            },
            function (position) {
              try {
                if (position) {
                  setUserPosition({
                    lat: position?.coords?.latitude,
                    lng: position?.coords?.longitude,
                  });
                  sessionStorage.setItem(
                    "geolocation",
                    JSON.stringify({
                      lat: position?.coords?.latitude,
                      lng: position?.coords?.longitude,
                    })
                  );
                } else {
                  sessionStorage.removeItem("geolocation");
                  setErrMessage(
                    "Location access denied. Please enable the location access."
                  );
                }
                setGlobalPermission(false);
                setLoading(false);
              } catch (error) {
                if (error.code == error.PERMISSION_DENIED) {
                  sessionStorage.removeItem("geolocation");
                  setErrMessage(
                    "Location access denied. Please enable the location access."
                  );
                  setGlobalPermission(false);
                  setLoading(false);
                }
              }
            }
          );
        }
      } else if (
        checkPermissions.location === "denied" ||
        checkPermissions.coarseLocation === "denied" ||
        (isMobile && checkPermissions.location === "prompt-with-rationale") ||
        (isMobile &&
          checkPermissions.coarseLocation === "prompt-with-rationale")
      ) {
        sessionStorage.removeItem("geolocation");
        setErrMessage(
          "Location access denied. Please enable the location access."
        );
        setGlobalPermission(false);
        setLoading(false);
      }
    } catch (error) {
      sessionStorage.removeItem("geolocation");
      setErrMessage(
        "Location access denied. Please enable the location access."
      );
      setGlobalPermission(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    showLocation();
  }, []);

  const getLocationAddress = async () => {
    const address = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?key=${
        // eslint-disable-next-line no-undef
        process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ""
      }&latlng=${userPosition?.lat},${userPosition?.lng}
      &location_type=ROOFTOP`
    ).then((res) => res.json());
    setAddressData(address);
  };

  const handleRequestPermission = () => {
    if (globalPermission) {
      NativeSettings.open({
        optionAndroid: AndroidSettings.Location,
        optionIOS: IOSSettings.LocationServices,
      });
    } else {
      NativeSettings.open({
        optionAndroid: AndroidSettings.ApplicationDetails,
        optionIOS: IOSSettings.App,
      });
    }
    App.addListener("appStateChange", async ({ isActive }) => {
      if (isActive === true) {
        setLoading(true);
        setErrMessage("");
        showLocation();
      }
    });
  };

  useMemo(() => {
    if (height === "100px" && userPosition) {
      getLocationAddress();
    }
  }, [userPosition]);

  return (
    <>
      <Box sx={styles.container}>
        {loading ? (
          <Box data-sqa-id="circular-progress-loader" sx={styles.isLoading}>
            <CircularProgress />
          </Box>
        ) : errMessage ? (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            width="100%"
            height="100%"
            minHeight={
              height === "100px" ? "100px" : isMobile ? "350px" : "400px"
            }
          >
            <Typography
              mx="10px"
              fontSize={{ xs: "14px", md: "16px" }}
              color="#8C8C8C"
            >
              {errMessage}
            </Typography>
            {Capacitor.getPlatform() !== "web" && (
              <Typography
                mx="10px"
                sx={{
                  cursor: "pointer",
                }}
                fontSize={{ xs: "14px", md: "16px" }}
                color="primary"
                onClick={handleRequestPermission}
              >
                Request permission
              </Typography>
            )}
          </Box>
        ) : (
          <>
            <GoogleMaps
              coords={height === "100px" ? [userPosition] : coords}
              height={height}
              userPosition={
                height === "100px"
                  ? userPosition
                  : coords?.[0]?.lat ||
                    coords?.[0]?.lng ||
                    coords?.[1]?.lat ||
                    coords?.[1]?.lng
                  ? {
                      lat: (coords?.[0]?.lat + coords?.[1]?.lat) / 2,
                      lng: (coords?.[0]?.lng + coords?.[1]?.lng) / 2,
                    }
                  : userPosition
              }
            />
            {height === "100px" && (
              <Box data-sqa-id="location-box" sx={styles.descriptionWrapper}>
                <LocationIcon />
                <Typography
                  data-sqa-id="location-address"
                  ml="8px"
                  fontSize="14px"
                  color="text.secondary"
                >
                  {addressData?.results[0]
                    ? `Near ${addressData?.results[0]?.address_components[1].short_name}, ${addressData?.results[0]?.address_components[2].short_name}, ${addressData?.results[0]?.address_components[4].short_name} ${addressData?.results[0]?.address_components[6].short_name}`
                    : ""}
                </Typography>
              </Box>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default Map;
