import { Box, CardMedia, Typography } from "@mui/material";
import React, { useState } from "react";
import { Comment, DeleteForWeb } from "src/assets/icons";
import AddCaptionModal from "./ActionsModal/AddCaptionModal";
import Toggle from "./Toggle";

const styles = {
  attachment: {
    borderRadius: "4px",
    objectFit: "contain",
    width: "100%",
    maxHeight: "300px",
    minHeight: "300px",
    backgroundColor: "#F0F0F0",
  },
  deleteIcon: {
    position: "absolute",
    right: "15px",
    top: "15px",
    backgroundColor: "#FF4D4F",
    borderRadius: "22px",
    display: "flex",
    columnGap: "10px",
    justifyContent: "space-between",
    alignItems: "center",
    p: "13px 24px",
    cursor: "pointer",
  },
  bottomActions: {
    position: "absolute",
    bottom: "16px",
    px: "16px",
    width: "100%",
    display: "flex",
    columnGap: "10px",
    justifyContent: "space-between",
    alignItems: "center",
  },
  togleContainer: {
    backgroundColor: "#FFF",
    borderRadius: "22px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    columnGap: "22px",
    padding: "12px 16px",
  },
  captionContainer: {
    backgroundColor: "#FFF",
    borderRadius: "22px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    columnGap: "10px",
    padding: "12px 16px",
  },
};

interface Props {
  attachment: any;
  index: number;
  toggleReceipt: (index: number, elem: boolean) => void;
  handleCaption: (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => void;
  handleDelete: (id: string) => void;
}

const AddAttachmentItem: React.FC<Props> = ({
  attachment,
  index,
  toggleReceipt,
  handleCaption,
  handleDelete,
}) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [captionText, setCaptionText] = useState<string>("");
  const modalActionType = {
    action: captionText.length > 0 ? "Edit" : "Add",
    page: "Caption",
  };
  return (
    <>
      <Box my="20px" width="100%">
        <Box position="relative">
          <CardMedia
            sx={styles.attachment}
            component="img"
            image={attachment?.file?.webPath}
            alt="attachment"
          />
          <Box
            sx={styles.deleteIcon}
            onClick={() => handleDelete(attachment.file.webPath)}
          >
            <DeleteForWeb color="#FFF" />
            <Typography fontSize="14px" fontWeight={500} color="#FFF">
              Delete
            </Typography>
          </Box>
          <Box sx={styles.bottomActions}>
            <Box
              sx={styles.captionContainer}
              onClick={() => setOpenModal(true)}
            >
              <Comment size={16.5} />
              <Typography fontSize="14px" color="#262626">
                {captionText.length > 0 ? "Edit" : "Add"} Caption
              </Typography>
            </Box>
            <Box
              sx={styles.togleContainer}
              onClick={() => toggleReceipt(index, attachment.isReceipt)}
            >
              <Typography fontSize="14px" color="#262626">
                Receipt
              </Typography>
              <Toggle
                name="isReceipt"
                checked={attachment.isReceipt}
                onChange={() => toggleReceipt(index, attachment.isReceipt)}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      {openModal && (
        <AddCaptionModal
          handleCaption={handleCaption}
          setCaptionText={setCaptionText}
          index={index}
          caption={attachment.caption}
          modalActionType={modalActionType}
          isOpen={openModal}
          closeModal={() => setOpenModal(false)}
        />
      )}
    </>
  );
};

export default AddAttachmentItem;
