import React from "react";

interface Props {
  color?: string;
}

const Plus: React.FC<Props> = ({ color }) => {
  return (
    <svg
      data-sqa-id="plus-icon"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_480_4124)">
        <circle
          cx="12"
          cy="12"
          r="11.25"
          stroke={color ? color : "#FFF"}
          strokeWidth="1.5"
        />
        <path
          d="M8 12H12M12 12H16M12 12V8M12 12V16"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_480_4124">
          <rect width="24" height="24" fill={color ? color : "#FFF"} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Plus;
