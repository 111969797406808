import React from "react";
import { AppBar, WebContainer } from "src/components";
import { isMobile } from "mobile-device-detect";

interface Props {
  children: React.ReactElement;
}

const DeviceWrapper: React.FC<Props> = ({ children }) => {
  return (
    <>
      {isMobile ? (
        <AppBar>{children}</AppBar>
      ) : (
        <WebContainer>{children}</WebContainer>
      )}
    </>
  );
};

export default DeviceWrapper;
