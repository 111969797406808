import { Box, Typography, IconButton, Button, Theme } from "@mui/material";
import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";
import theme from "src/theme";
import {
  Link as LinkIcon,
  Edit as EditIcon,
  Eye as EyeIcon,
  DeleteForWeb,
  Location,
} from "src/assets/icons";
import { EntryModalBaseProps } from "./types";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "src/constants";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { getUserSelector } from "src/store/auth/selectors";
import { isMobile } from "mobile-device-detect";
import { parseTime } from "src/utils";

const styles = {
  modal: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: 350,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "4px",
  },
  modalTitile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "16px 24px",
    borderBottom: (theme: Theme) =>
      `1px solid ${theme.palette.background.default}`,
  },
  modalClose: {
    color: (theme: Theme) => `${theme.palette.text.primary}`,
    p: 0,
  },
  modalContent: {
    padding: "24px",
  },
  actionButton: {
    height: 56,
    color: (theme: Theme) => `${theme.palette.text.primary}`,
    borderColor: (theme: Theme) => `${theme.palette.background.default}`,
    textTransform: "none",
    marginBottom: "10px",
    "&:hover": {
      background: (theme: Theme) => `${theme.palette.background.paper}`,
      color: (theme: Theme) => `${theme.palette.primary.main}`,
    },
    svg: {
      marginRight: "14px",
    },
  },
  actionButtonDelete: {
    height: 56,
    textTransform: "none",
    svg: {
      marginRight: "12px",
    },
  },
};

interface GeneralEntryModalProps extends EntryModalBaseProps {
  setModalState: (elem: string) => void;
  currentTab: number;
}

const GeneralEntryModal: React.FC<GeneralEntryModalProps> = ({
  setModalState,
  closeModal,
  currentTab,
  modalData,
}) => {
  const user = useSelector(getUserSelector);
  const navigate = useNavigate();
  const handleClickOpenDialog = (type: string) => {
    setModalState(type);
  };
  return (
    <>
      <Box sx={styles.modalTitile}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          fontSize={16}
          fontWeight={700}
        >
          Actions
        </Typography>
        <IconButton onClick={closeModal} sx={styles.modalClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box sx={styles.modalContent}>
        <Box>
          <Button
            variant="outlined"
            fullWidth
            sx={styles.actionButton}
            onClick={() => handleClickOpenDialog("LINK_ENTRY_MODAL")}
          >
            <LinkIcon color={theme.palette.primary.main} />
            Link to Project
          </Button>
        </Box>
        {currentTab === 0 && (
          <Box>
            <Button
              variant="outlined"
              fullWidth
              sx={styles.actionButton}
              disabled={!modalData.endTime}
              onClick={() => handleClickOpenDialog("EDIT_ENTRY_MODAL")}
            >
              <EditIcon
                color={
                  modalData.endTime ? theme.palette.primary.main : "#D9D9D9"
                }
              />
              Edit Entry
            </Button>
          </Box>
        )}
        {currentTab === 1 && (
          <Box>
            <Button
              variant="outlined"
              fullWidth
              sx={styles.actionButton}
              onClick={() =>
                navigate(
                  AppRoutes.HistoryDay +
                    `/${format(parseTime(modalData.day), "y-MM-dd")}`,
                  {
                    state: { id: AppRoutes.Dashboard },
                  }
                )
              }
            >
              <EyeIcon color={theme.palette.primary.main} />
              View Entry
            </Button>
          </Box>
        )}
        {user.isAdmin &&
          localStorage.getItem("roleMode") === "admin" &&
          isMobile && (
            <Box>
              <Button
                variant="outlined"
                color="error"
                fullWidth
                onClick={() => handleClickOpenDialog("VIEW_LOCATION_MODAL")}
                sx={styles.actionButton}
              >
                <Location color="#0D6FF0" />
                View Location
              </Button>
            </Box>
          )}
        <Box>
          <Button
            variant="outlined"
            color="error"
            fullWidth
            onClick={() => handleClickOpenDialog("DELETE_ENTRY_MODAL")}
            sx={styles.actionButtonDelete}
          >
            {user.isAdmin && isMobile && <DeleteForWeb color="#FF00000" />}
            Delete {!user.isAdmin && isMobile && "Entry"}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default GeneralEntryModal;
