import React from "react";

interface Props {
  color?: string;
}

const ThreeDots: React.FC<Props> = ({ color }) => {
  return (
    <svg
      width="20"
      height="4"
      viewBox="0 0 20 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="2" cy="2" r="2" fill={color ? color : "#0D6FF0"} />
      <circle cx="10" cy="2" r="2" fill={color ? color : "#0D6FF0"} />
      <circle cx="18" cy="2" r="2" fill={color ? color : "#0D6FF0"} />
    </svg>
  );
};

export default ThreeDots;
