import { Avatar, Box, TextField, Typography } from "@mui/material";
import { format } from "date-fns";
import { isMobile } from "mobile-device-detect";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DeleteForWeb, EditForWeb } from "src/assets/icons";
import { getUserSelector } from "src/store/auth/selectors";
import { updateCommentRequest } from "src/store/comments/actions";
import { Comment } from "src/types";
import { parseTime } from "src/utils";
import ActionsModal from "../ActionsModal/ActionsModal";
import Button from "../Button";

const styles = {
  userPhoto: {
    width: isMobile ? "35px" : "44px",
    height: isMobile ? "35px" : "44px",
    fontSize: isMobile ? "14px" : "16px",
    borderRadius: "50%",
    marginRight: "10px",
  },
  actionButtons: {
    display: "flex",
    justifyContent: "flex-end",
    columnGap: "10px",
    marginTop: "20px",
  },
  actionButtonSave: {
    textTransform: "none",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "15%",
  },
  buttonAction: {
    cursor: "pointer",
    minWidth: "44px",
    background: "transparent",
    "&:hover": {
      background: "transparent",
    },
  },
};

interface Props {
  item: Comment;
  handleFetch?: () => void;
}

const DailyCommentItem: React.FC<Props> = ({ item, handleFetch }) => {
  const dispatch = useDispatch();
  const user = useSelector(getUserSelector);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [hover, setHover] = useState("");
  const [commentText, setCommentText] = useState(item.commentText);
  const [commentEdit, setCommentEdit] = useState(false);
  const modalActionType = {
    action: "Delete",
    page: "Comment",
  };
  function stringAvatar(name) {
    return {
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }

  const handleUpdateComment = () => {
    const updateCommentText = commentText.trim();
    if (updateCommentText.length === 0) return;
    else {
      dispatch(
        updateCommentRequest(
          {
            id: item.id,
            commentText: updateCommentText.trim(),
            commentDay: item.commentDay,
            projectId: item.id,
          },
          { onSuccess: handleFetch }
        )
      );
    }
  };

  const cancelEditComment = () => {
    setCommentEdit(false);
    setCommentText(item.commentText);
  };

  return (
    <>
      <Box
        data-sqa-id="comment-item"
        mt="20px"
        p={isMobile ? "22px" : "30px"}
        sx={{ border: "1px solid #F0F0F0" }}
      >
        {!commentEdit ? (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: isMobile ? "20px" : "16px",
              }}
            >
              <Box data-sqa-id="comment-author-box" sx={{ display: "flex" }}>
                <Avatar
                  {...stringAvatar(
                    `${item.user.firstName} ${item.user.lastName}`
                  )}
                  sx={styles.userPhoto}
                  data-sqa-id="author-avatar"
                  src={item.user?.profilePictureUrl}
                />
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    data-sqa-id="author-fist-name-name-text"
                    fontWeight="400"
                    color="#000"
                    fontSize={{ xs: "14px", md: "14px" }}
                  >
                    {`${item.user.firstName} ${item.user.lastName}`}
                  </Typography>
                  <Typography
                    data-sqa-id="comment-date-time"
                    fontWeight="400"
                    color="#8C8C8C"
                    fontSize={{ xs: "12px", md: "14px" }}
                  >
                    {format(parseTime(item.createdAt), "MMMM d, h:mm a")}
                  </Typography>
                </Box>
              </Box>
              {(!user.isAdmin ||
                (user.isAdmin &&
                  localStorage.getItem("roleMode") === "user")) && (
                <Box sx={{ display: "flex" }}>
                  <Button
                    data-sqa-id="comment-edit-btn"
                    style={styles.buttonAction}
                    onClick={() => setCommentEdit(true)}
                    onMouseEnter={() => setHover("edit" + item.id)}
                    onMouseLeave={() => setHover("")}
                  >
                    <EditForWeb
                      color={hover === "edit" + item.id ? "#0D6FF0" : "#8C8C8C"}
                    />
                  </Button>
                  <Button
                    data-sqa-id="comment-delete-btn"
                    style={styles.buttonAction}
                    onClick={() => setOpenModal(true)}
                    onMouseEnter={() => setHover("delete" + item.id)}
                    onMouseLeave={() => setHover("")}
                  >
                    <DeleteForWeb
                      color={
                        hover === "delete" + item.id ? "#FF0003" : "#FF4D4F"
                      }
                    />
                  </Button>
                </Box>
              )}
            </Box>
            <Typography
              data-sqa-id="comment-text"
              sx={{
                wordBreak: "break-word",
                fontSize: "14px",
                fontWeight: 400,
                color: "#000",
              }}
            >
              {item.commentText}
            </Typography>
          </>
        ) : (
          <Box>
            <TextField
              data-sqa-id="edit-comment-textarea-box"
              multiline
              fullWidth
              rows={4}
              inputProps={{ maxLength: 500 }}
              value={commentText}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (commentText.length + e.target.value.length > 500)
                  return setCommentText(e.target.value.substring(0, 500));
                else setCommentText(e.target.value);
              }}
            />
            <Typography
              data-sqa-id="char-counter"
              sx={{ width: "100%", textAlign: "right", mb: "20px" }}
              color="#D9D9D9"
            >{`${commentText.length} / 500`}</Typography>
            <Box sx={styles.actionButtons}>
              <Box sx={styles.buttonContainer}>
                <Box sx={{ width: "45%" }}>
                  <Button
                    data-sqa-id="edit-cancel-btn"
                    size="small"
                    variant="outlined"
                    fullWidth
                    color="secondary"
                    onClick={() => cancelEditComment()}
                  >
                    <Typography
                      data-sqa-id="edit-cancel-btn-text"
                      color="#262626"
                      fontSize="14px"
                    >
                      Cancel
                    </Typography>
                  </Button>
                </Box>
                <Box sx={{ width: "45%" }}>
                  <Button
                    data-sqa-id="edit-save-btn"
                    size="small"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={styles.actionButtonSave}
                    onClick={handleUpdateComment}
                  >
                    <Typography
                      data-sqa-id="edit-save-btn-text"
                      fontSize="14px"
                    >
                      Save
                    </Typography>
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      {openModal && (
        <ActionsModal
          onFetchSuccess={handleFetch}
          elementDate={item.commentText}
          modalActionType={modalActionType}
          isOpen={openModal}
          elementId={item.id}
          closeModal={() => setOpenModal(false)}
        />
      )}
    </>
  );
};

export default DailyCommentItem;
