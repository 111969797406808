import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  IconButton,
  Toolbar,
  CardMedia,
  TextField,
  CircularProgress,
  AppBar as MuiAppBar,
  Theme,
} from "@mui/material";
import { Back, DeleteIcon, EditIcon } from "src/assets/icons";
import { styles } from "./attachments.module";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button } from "src/components";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { getAttachmentsSelector } from "src/store/attachments/selectors";
import ActionsModal from "src/components/ActionsModal/ActionsModal";
import Toggle from "src/components/Toggle";
import {
  fetchAttachmentIdRequest,
  updateAttachmentRequest,
} from "src/store/attachments/actions";
import { AppRoutes } from "src/constants";
import {
  getOrganizationSelector,
  getUserSelector,
} from "src/store/auth/selectors";
import { parseTime } from "src/utils";
import Div100vh from "react-div-100vh";
import TrialModal from "src/components/ActionsModal/TrialModal";
import { Capacitor } from "@capacitor/core";

const AttachmentItem = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const organization = useSelector(getOrganizationSelector);
  const attachmentState = useSelector(getAttachmentsSelector);
  const user = useSelector(getUserSelector);
  const { id } = useParams();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [receiptToggle, setReceiptToggle] = useState<boolean>(false);
  const [pageMode, setPageMode] = useState<string>("View");
  const [attachmentText, setAttachmentText] = useState<string>("");
  const [createdAt, setCreatedAt] = useState<string>(null);
  const attachmentId = attachmentState?.attachment?.attachments[0]?.id;
  const attachmentDate =
    attachmentState?.attachment?.attachments[0]?.attachmentDay;
  const [modalActionType, setModalActionType] = useState({
    action: "",
    page: "Attachment",
  });
  let res: any[] = [];
  const tabData = useLocation().state as {
    name: {
      id: string;
      userName: string;
    };
    date: string;
    atachmentsCount: number;
    commentsCount: number;
  };

  if (
    !user.isAdmin ||
    (user.isAdmin && localStorage.getItem("roleMode") === "user")
  ) {
    if (Array.isArray(attachmentState.attachments)) {
      res.push({ attachments: attachmentState.attachments });
    } else {
      res = attachmentState.attachments?.days?.filter(
        (date) =>
          date.day ===
          attachmentState?.attachment?.attachments[0]?.attachmentDay
      );
    }
  }

  const handleNavigate = (direction: string) => {
    let startId: number;
    let nextIndex = 0;
    if (
      Array.isArray(attachmentState.attachments) &&
      attachmentState.attachments.length === 1
    )
      return;
    if (user.isAdmin && localStorage.getItem("roleMode") === "admin") {
      startId =
        Array.isArray(attachmentState.attachments) &&
        attachmentState.attachments.findIndex((elem) => elem.id === id);
      if (
        startId + 1 <
          (Array.isArray(attachmentState.attachments) &&
            attachmentState.attachments?.length) &&
        startId !== 0
      ) {
        if (direction === "prev") {
          nextIndex = startId - 1;
        } else {
          nextIndex = startId + 1;
        }
      } else if (startId === 0) {
        if (direction === "prev") {
          nextIndex = nextIndex =
            Array.isArray(attachmentState.attachments) &&
            attachmentState.attachments?.length - 1;
        } else {
          nextIndex = startId + 1;
        }
      } else {
        if (direction === "prev") {
          nextIndex = startId - 1;
        } else {
          startId =
            Array.isArray(attachmentState.attachments) &&
            attachmentState.attachments?.length - 1;
        }
      }
      if (user.isAdmin && localStorage.getItem("roleMode") === "admin") {
        navigate(
          AppRoutes.Attachment +
            "/" +
            attachmentState?.attachments?.[nextIndex]?.id,
          {
            state: {
              name: {
                id: tabData.name.id,
                userName: tabData.name.userName,
              },
              date: tabData.date,
              atachmentsCount: tabData.atachmentsCount,
              commentsCount: tabData.commentsCount,
            },
          }
        );
      } else {
        navigate(
          AppRoutes.Attachment +
            "/" +
            attachmentState?.attachments?.[nextIndex]?.id
        );
      }
    } else {
      startId = res[0]?.attachments?.findIndex((elem) => elem.id === id);
      if (startId + 1 < res[0]?.attachments?.length && startId !== 0) {
        if (direction === "prev") {
          nextIndex = startId - 1;
        } else {
          nextIndex = startId + 1;
        }
      } else if (startId === 0) {
        if (direction === "prev") {
          nextIndex = res[0]?.attachments?.length - 1;
        } else {
          nextIndex = startId + 1;
        }
      } else {
        if (direction === "prev") {
          nextIndex = startId - 1;
        } else {
          startId = res[0]?.attachments?.length - 1;
        }
      }
      if (user.isAdmin && localStorage.getItem("roleMode") === "admin") {
        navigate(
          AppRoutes.Attachment + "/" + res[0]?.attachments[nextIndex]?.id,
          {
            state: {
              name: {
                id: tabData.name.id,
                userName: tabData.name.userName,
              },
              date: tabData.date,
              atachmentsCount: tabData.atachmentsCount,
              commentsCount: tabData.commentsCount,
            },
          }
        );
      } else {
        if (res[0]?.attachments.length === 1) return;
        else {
          navigate(
            AppRoutes.Attachment + "/" + res[0]?.attachments[nextIndex]?.id
          );
        }
      }
    }
  };

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (attachmentText.length + event.target.value.length > 500)
      return setAttachmentText(event.target.value.substring(0, 500));
    else setAttachmentText(event.target.value);
  };

  const handleOpenModal = (type: string) => {
    setOpenModal(true);
    setModalActionType((prevState) => ({
      ...prevState,
      action: type,
    }));
  };

  const handleUpdateAttachment = () => {
    dispatch(
      updateAttachmentRequest(
        {
          id: attachmentId,
          attachmentDay: attachmentDate,
          isReceipt: receiptToggle,
          caption: attachmentText.trim(),
          s3FilePath: attachmentState?.attachment?.attachments[0]?.s3FilePath,
          thumbnailS3FilePath:
            attachmentState?.attachment?.attachments[0]?.thumbnailS3FilePath,
        },
        {
          onSuccess: handleFetchAttachmentId,
        }
      )
    );
  };

  const handleFetchAttachmentId = () => {
    if (modalActionType.action === "Delete") {
      navigate(-1);
    } else {
      dispatch(fetchAttachmentIdRequest({ id }));
      if (pageMode === "Edit") setPageMode("View");
    }
  };

  const handleOpenTrialModal = () => {
    setOpen(true);
    setModalActionType((prevState) => ({
      ...prevState,
      action:
        new Date().getTime() < parseTime(organization?.trialExpires).getTime()
          ? "Trial"
          : "Trial expired",
    }));
  };

  useEffect(() => {
    handleFetchAttachmentId();
  }, [id]);

  useEffect(() => {
    setAttachmentText(attachmentState?.attachment?.attachments[0]?.caption);
    setReceiptToggle(attachmentState?.attachment?.attachments[0]?.isReceipt);
    setCreatedAt(attachmentState?.attachment?.attachments[0]?.createdAt);
  }, [attachmentState?.attachment]);

  const navigationBack = () => {
    if (
      !user.isAdmin ||
      (user.isAdmin && localStorage.getItem("roleMode") === "user")
    ) {
      navigate(AppRoutes.Attachments);
    } else {
      navigate(`${AppRoutes.Report}/${sessionStorage.getItem("reportID")}`, {
        state: {
          name: {
            id: tabData.name.id,
            userName: tabData.name.userName,
          },
          date: tabData.date,
          atachmentsCount: tabData.atachmentsCount,
          commentsCount: tabData.commentsCount,
        },
      });
      sessionStorage.setItem("activeTab", "1");
    }
  };

  return (
    <Div100vh>
      <Box display="flex" flexDirection="column" height="100%">
        <MuiAppBar position="static" sx={styles.appBarWrapper}>
          <Toolbar sx={styles.toolbar}>
            <IconButton
              sx={styles.menuIcon}
              size="large"
              color="inherit"
              aria-label="menu"
              onClick={navigationBack}
            >
              <Back width={26} height={18} color="#000" />
            </IconButton>
            <Typography sx={styles.pageTitle}>{pageMode} Attachment</Typography>
            {organization?.trial && Capacitor.getPlatform() != "ios" && (
              <Box
                sx={[
                  styles.licenseStatus,
                  {
                    backgroundColor:
                      new Date().getTime() <
                      parseTime(organization?.trialExpires).getTime()
                        ? (theme: Theme) => theme.palette.info.main
                        : "#FF4D4F",
                    color:
                      new Date().getTime() <
                      parseTime(organization?.trialExpires).getTime()
                        ? (theme: Theme) => theme.palette.info.contrastText
                        : "#FFF",
                  },
                ]}
                onClick={handleOpenTrialModal}
              >
                Trial
              </Box>
            )}
          </Toolbar>
        </MuiAppBar>
        <Box overflow="auto">
          {attachmentState.isLoading ? (
            <Box data-sqa-id="circular-progress-loader" sx={styles.isLoading}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Box sx={{ p: "20px", flexGrow: 1, overflow: "auto" }}>
                <Box sx={{ width: "100%", position: "relative" }}>
                  {attachmentState.attachment?.attachments[0]?.isReceipt &&
                    pageMode === "View" && (
                      <Box
                        sx={[styles.whiteBgReceipt, { padding: "6px 15px" }]}
                      >
                        <Typography sx={[styles.caption, { fontSize: "14px" }]}>
                          Receipt
                        </Typography>
                      </Box>
                    )}
                  {attachmentState.attachment?.attachments?.[0]?.s3FilePath ? (
                    <CardMedia
                      sx={[
                        styles.attachment,
                        {
                          minHeight: "250px",
                          maxHeight: "460px",
                          height: "auto",
                        },
                      ]}
                      component="img"
                      image={`${attachmentState?.attachment?.bucketUrlPrefix}${attachmentState.attachment?.attachments?.[0]?.s3FilePath}`}
                      alt="attachment"
                    />
                  ) : (
                    <Box
                      sx={[
                        styles.attachment,
                        { minHeight: "250px", maxHeight: "460px" },
                      ]}
                    />
                  )}
                </Box>
                {pageMode === "Edit" && (
                  <Box sx={styles.receiptToggle}>
                    <Typography
                      fontSize="14px"
                      color="#262626"
                      fontWeight={400}
                    >
                      Receipt
                    </Typography>
                    <Toggle
                      checked={receiptToggle}
                      onChange={() => setReceiptToggle(!receiptToggle)}
                    />
                  </Box>
                )}
                {pageMode === "View" && res[0]?.attachments?.length > 1 && (
                  <Box sx={styles.boxContainer}>
                    <Button
                      variant="contained"
                      style={styles.buttonStyle}
                      onClick={() => handleNavigate("prev")}
                    >
                      <Box sx={styles.addButtonContainer}>
                        <Back width={22} height={10} color="#0D6FF0" />
                        <Typography sx={styles.arrowStyle}>Previous</Typography>
                      </Box>
                    </Button>
                    <Button
                      variant="contained"
                      style={styles.buttonStyle}
                      onClick={() => handleNavigate("next")}
                    >
                      <Box sx={styles.addButtonContainer}>
                        <Typography sx={styles.arrowStyle}>Next</Typography>
                        <Box sx={{ transform: "rotate(180deg)" }}>
                          <Back width={22} height={10} color="#0D6FF0" />
                        </Box>
                      </Box>
                    </Button>
                  </Box>
                )}
                {(attachmentState?.attachment?.attachments[0]?.caption?.length >
                  0 ||
                  pageMode === "Edit") && (
                  <Typography
                    sx={[styles.attachmentData, { marginTop: "20px" }]}
                  >
                    Caption
                  </Typography>
                )}
                {pageMode === "View" ? (
                  attachmentState?.attachment?.attachments[0]?.caption?.length >
                    0 && (
                    <Box sx={styles.attachmentBox}>
                      <Typography sx={styles.attachmentText}>
                        {attachmentState?.attachment?.attachments[0]?.caption}
                      </Typography>
                      <Typography sx={styles.attachmentData}>
                        {createdAt &&
                          format(parseTime(createdAt), "MMMM d, hh:mm a")}
                      </Typography>
                    </Box>
                  )
                ) : (
                  <>
                    <Box marginTop="10px">
                      <TextField
                        multiline
                        fullWidth
                        rows={5}
                        sx={{ backgroundColor: "#fff" }}
                        value={attachmentText}
                        onChange={handleInput}
                        type="text"
                        inputProps={{ maxLength: 500 }}
                      />
                    </Box>
                    <Typography
                      sx={{ width: "100%", textAlign: "right", mb: "20px" }}
                      color="#D9D9D9"
                    >{`${attachmentText?.length} / 500`}</Typography>
                  </>
                )}
              </Box>
              {(!user.isAdmin ||
                (user.isAdmin &&
                  localStorage.getItem("roleMode") === "user")) && (
                <Box sx={styles.boxAction}>
                  {pageMode === "Edit" && (
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "10px",
                      }}
                    >
                      <Button
                        fullWidth={true}
                        variant="contained"
                        color="primary"
                        onClick={handleUpdateAttachment}
                      >
                        <Typography sx={styles.addButtonText}>Save</Typography>
                      </Button>
                      <Button
                        variant="outlined"
                        fullWidth={true}
                        color="secondary"
                        onClick={() => {
                          setPageMode("View");
                          setAttachmentText(
                            attachmentState?.attachment?.attachments[0]?.caption
                          );
                        }}
                      >
                        <Typography color="#262626" fontSize="14px">
                          Cancel
                        </Typography>
                      </Button>
                    </Box>
                  )}
                  {pageMode === "View" && (
                    <>
                      <Button
                        variant="contained"
                        style={styles.btnEdit}
                        onClick={() => setPageMode("Edit")}
                      >
                        <Box sx={styles.addButtonContainer}>
                          <EditIcon />
                          <Typography sx={styles.addButtonText}>
                            Edit
                          </Typography>
                        </Box>
                      </Button>
                      <Button
                        variant="contained"
                        style={styles.btnDelete}
                        onClick={() => handleOpenModal("Delete")}
                      >
                        <Box sx={styles.addButtonContainer}>
                          <DeleteIcon />
                          <Typography sx={styles.addButtonText}>
                            Delete
                          </Typography>
                        </Box>
                      </Button>
                    </>
                  )}
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>
      {openModal && (
        <ActionsModal
          onFetchSuccess={handleFetchAttachmentId}
          elementDate={attachmentDate}
          modalActionType={modalActionType}
          isOpen={openModal}
          elementId={attachmentId}
          closeModal={() => setOpenModal(false)}
        />
      )}
      {open && (
        <TrialModal
          modalActionType={modalActionType}
          isOpen={open}
          closeModal={() => setOpen(false)}
        />
      )}
    </Div100vh>
  );
};

export default AttachmentItem;
