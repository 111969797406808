import { createSelector } from "reselect";

import { AppState } from "../rootReducer";

const getProject = (state: AppState) => state.project;

export const getProjectSelector = createSelector(
  getProject,
  (project) => project
);
