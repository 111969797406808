import * as React from "react";
import { SVGProps } from "react";

const UploadImage = (props: SVGProps<SVGSVGElement>) => (
  <svg width={136} height={115} fill="none" {...props}>
    <path
      d="M104.026 111.859h-78.13c-11.495 0-20.81-9.315-20.81-20.81V70.894c0-6.906 5.597-12.503 12.504-12.503h94.747c6.907 0 12.504 5.597 12.504 12.503V91.05c-.005 11.49-9.32 20.81-20.815 20.81Z"
      fill="#91D5FF"
    />
    <path
      d="m45.144 35.722-6.726 3.064a.501.501 0 0 1-.662-.247.501.501 0 0 1 .247-.662l6.727-3.064a.501.501 0 0 1 .661.247.501.501 0 0 1-.247.662ZM50.193 46.804l-6.726 3.064a.501.501 0 0 1-.663-.247.501.501 0 0 1 .248-.662l6.726-3.064a.501.501 0 0 1 .662.247.501.501 0 0 1-.247.662Z"
      fill="#fff"
    />
    <path
      d="M60.154 55.782 19.046 74.51a1.355 1.355 0 0 1-1.794-.671L1.579 39.434a1.355 1.355 0 0 1 .671-1.794l41.108-18.727a1.355 1.355 0 0 1 1.794.671l15.673 34.404c.31.682.006 1.486-.671 1.794ZM2.664 38.55a.356.356 0 0 0-.175.47L18.16 73.424c.081.177.293.256.47.175L59.74 54.873a.356.356 0 0 0 .176-.47L44.242 19.998a.356.356 0 0 0-.47-.175L2.664 38.549Z"
      fill="#E6F7FF"
    />
    <g filter="url(#a)">
      <path
        d="M60.154 55.782 19.046 74.51a1.355 1.355 0 0 1-1.794-.671L1.579 39.434a1.355 1.355 0 0 1 .671-1.794l41.108-18.727a1.355 1.355 0 0 1 1.794.671l15.673 34.404c.31.682.006 1.486-.671 1.794ZM2.664 38.55a.356.356 0 0 0-.175.47L18.16 73.424c.081.177.293.256.47.175L59.74 54.873a.356.356 0 0 0 .176-.47L44.242 19.998a.356.356 0 0 0-.47-.175L2.664 38.549Z"
        fill="#E6F7FF"
      />
    </g>
    <path
      d="M42.694 30.34 10.64 44.944a.501.501 0 0 1-.662-.248.501.501 0 0 1 .248-.662l32.053-14.602a.501.501 0 0 1 .662.248.501.501 0 0 1-.248.662ZM47.594 41.097 15.54 55.699a.501.501 0 0 1-.662-.248.501.501 0 0 1 .247-.662L47.18 40.187a.501.501 0 0 1 .662.248.498.498 0 0 1-.247.662ZM30.373 42.45l-17.282 7.873a.501.501 0 0 1-.661-.247.501.501 0 0 1 .247-.662L29.96 41.54a.501.501 0 0 1 .662.248.501.501 0 0 1-.248.662ZM35.422 53.535 18.14 61.407a.501.501 0 0 1-.662-.247.501.501 0 0 1 .248-.662l17.282-7.873a.501.501 0 0 1 .662.248.501.501 0 0 1-.248.662ZM32.136 61.23 20.48 66.54a.501.501 0 0 1-.662-.247.501.501 0 0 1 .248-.662l11.656-5.31a.501.501 0 0 1 .662.248.501.501 0 0 1-.248.661Z"
      fill="#91D5FF"
    />
    <g opacity={0.81}>
      <path
        d="m83.542 43.835 7.338.884c.273.033.47.283.437.556a.501.501 0 0 1-.556.437l-7.339-.884a.501.501 0 0 1-.436-.556.501.501 0 0 1 .556-.437ZM84.999 31.744l7.338.884c.273.032.47.283.437.556a.501.501 0 0 1-.556.436l-7.339-.884a.501.501 0 0 1-.436-.556.501.501 0 0 1 .556-.436Z"
        fill="#fff"
      />
      <g filter="url(#b)" fill="#E6F7FF">
        <path d="m91.496 13.704 40.776 19.438c.677.322.96 1.133.64 1.805l-16.268 34.127a1.355 1.355 0 0 1-1.805.64L74.063 50.277a1.355 1.355 0 0 1-.64-1.806L89.69 14.344a1.355 1.355 0 0 1 1.806-.64Zm23.773 55.108a.356.356 0 0 0 .473-.168l16.268-34.127a.357.357 0 0 0-.168-.473L91.065 14.607a.356.356 0 0 0-.472.167L74.325 48.902a.356.356 0 0 0 .168.473l40.776 19.437Z" />
        <path d="m91.496 13.704 40.776 19.438c.677.322.96 1.133.64 1.805l-16.268 34.127a1.355 1.355 0 0 1-1.805.64L74.063 50.277a1.355 1.355 0 0 1-.64-1.806L89.69 14.344a1.355 1.355 0 0 1 1.806-.64Zm23.773 55.108a.356.356 0 0 0 .473-.168l16.268-34.127a.357.357 0 0 0-.168-.473L91.065 14.607a.356.356 0 0 0-.472.167L74.325 48.902a.356.356 0 0 0 .168.473l40.776 19.437Z" />
      </g>
      <path
        d="m83.242 43.436 31.795 15.156a.502.502 0 0 1 .236.666.502.502 0 0 1-.667.236L82.812 44.338a.501.501 0 0 1-.236-.666.501.501 0 0 1 .666-.236ZM88.328 32.768l31.795 15.156a.502.502 0 0 1 .236.666.502.502 0 0 1-.667.236L87.898 33.67a.501.501 0 0 1-.236-.666.498.498 0 0 1 .666-.236ZM100.439 45.084l17.143 8.172a.501.501 0 0 1 .236.666.502.502 0 0 1-.667.236l-17.142-8.172a.501.501 0 0 1-.236-.666.501.501 0 0 1 .666-.236ZM105.679 34.09l17.143 8.171a.502.502 0 0 1 .236.667.501.501 0 0 1-.666.236l-17.143-8.172a.501.501 0 0 1-.236-.666.501.501 0 0 1 .666-.236ZM113.687 31.656l11.562 5.512a.501.501 0 0 1 .237.666.502.502 0 0 1-.667.236l-11.562-5.511a.5.5 0 0 1 .43-.902Z"
        fill="#91D5FF"
      />
    </g>
    <path
      opacity={0.36}
      d="M52.255 62.714 97.81 75.891a2.098 2.098 0 0 0 2.599-1.432l13.176-45.554a2.097 2.097 0 0 0-1.432-2.6L66.599 13.13a2.098 2.098 0 0 0-2.6 1.431L50.824 60.115a2.096 2.096 0 0 0 1.432 2.6Z"
      fill="#9CD1FA"
    />
    <path
      d="m56.535 61.221 43.484 12.581a3.174 3.174 0 0 0 3.934-2.167l12.581-43.484a3.173 3.173 0 0 0-2.167-3.934L70.883 11.635a3.174 3.174 0 0 0-3.935 2.168L54.367 57.287a3.174 3.174 0 0 0 2.168 3.934Z"
      fill="#fff"
    />
    <path
      d="m58.959 57.353 41.141 11.9 11.902-41.14L70.86 16.21 58.96 57.353Z"
      fill="#91D5FF"
    />
    <path
      opacity={0.47}
      d="m58.84 57.54-.477-.137 4.721-16.312.063-.213 7.425-3.53c2.553-1.214 5.646-.347 7.193 2.02l7.28 11.148a1.1 1.1 0 0 0 1.29.439l8.134-2.865a3.076 3.076 0 0 1 3.837 1.627l4.848 10.55-2.695 9.315-41.62-12.041Zm38.562-7.409a2.09 2.09 0 0 0-2.608-1.104l-8.133 2.865a2.084 2.084 0 0 1-2.448-.83l-7.281-11.148a4.707 4.707 0 0 0-5.937-1.67l-7.022 3.335-4.383 15.148 40.193 11.627 2.316-8.01-4.697-10.213ZM84.04 38.195a5.276 5.276 0 0 1-3.598-6.529 5.276 5.276 0 0 1 6.529-3.598 5.276 5.276 0 0 1 3.598 6.529 5.274 5.274 0 0 1-4.872 3.8 5.056 5.056 0 0 1-1.657-.202Zm-2.646-6.253a4.285 4.285 0 0 0 2.922 5.301 4.285 4.285 0 0 0 5.301-2.922 4.285 4.285 0 0 0-4.269-5.467 4.303 4.303 0 0 0-3.954 3.088Z"
      fill="#fff"
    />
    <path
      opacity={0.36}
      d="m76.613 60.796-32.66 4.723a3.18 3.18 0 0 1-3.6-2.691l-4.722-32.659a3.18 3.18 0 0 1 2.69-3.6l32.66-4.723a3.18 3.18 0 0 1 3.6 2.69l4.722 32.66a3.18 3.18 0 0 1-2.69 3.6Z"
      fill="#4C65BA"
    />
    <path
      d="m74.347 59.105-32.659 4.722a3.18 3.18 0 0 1-3.6-2.69l-4.723-32.66a3.18 3.18 0 0 1 2.69-3.6l32.66-4.722a3.18 3.18 0 0 1 3.6 2.69l4.723 32.66a3.177 3.177 0 0 1-2.69 3.6Z"
      fill="#fff"
    />
    <path
      opacity={0.39}
      d="m68.998 23.505-32.313 4.673 4.673 32.312 32.313-4.672-4.673-32.313Z"
      fill="#FF7875"
    />
    <path
      opacity={0.47}
      d="M74.251 56.022 40.96 60.837l-1.081-7.474 4.789-7.494a2.463 2.463 0 0 1 3.223-.865l5.839 3.019a.714.714 0 0 0 .875-.167l6.664-7.655a4.465 4.465 0 0 1 5.798-.793l5.28 3.474 1.9 13.141h.004Zm-32.46 3.69 31.334-4.532-1.696-11.727-4.91-3.228a3.476 3.476 0 0 0-4.506.616l-6.664 7.654c-.52.596-1.375.76-2.08.397l-5.843-3.018a1.472 1.472 0 0 0-1.93.521l-4.591 7.183.887 6.133ZM56.76 38.845a4.143 4.143 0 0 1-4.688-3.503 4.14 4.14 0 0 1 3.503-4.688 4.143 4.143 0 0 1 4.688 3.503 4.143 4.143 0 0 1-3.503 4.688Zm-1.042-7.207a3.146 3.146 0 0 0-2.661 3.561 3.146 3.146 0 0 0 3.56 2.661 3.146 3.146 0 0 0 2.662-3.56 3.146 3.146 0 0 0-3.561-2.662Z"
      fill="#fff"
    />
    <path
      opacity={0.36}
      d="m52.879 69.51-21.313 7.986a3.788 3.788 0 0 1-4.88-2.219L18.7 53.964a3.788 3.788 0 0 1 2.219-4.88l21.313-7.987a3.788 3.788 0 0 1 4.88 2.22l7.987 21.313a3.793 3.793 0 0 1-2.22 4.88Z"
      fill="#4C65BA"
    />
    <path
      d="m50.893 68.609-21.314 7.987a3.788 3.788 0 0 1-4.88-2.219l-7.986-21.313a3.788 3.788 0 0 1 2.219-4.88l21.313-7.987a3.788 3.788 0 0 1 4.88 2.22l7.987 21.312a3.783 3.783 0 0 1-2.22 4.88Z"
      fill="#fff"
    />
    <path
      d="M42.273 42.196 18.7 51.03l8.833 23.572 23.572-8.833-8.833-23.572Z"
      fill="#FFBB96"
    />
    <path
      opacity={0.47}
      d="m27.157 75.2-2.141-5.72 2.404-6.508a2.11 2.11 0 0 1 2.535-1.312l4.897 1.312a.35.35 0 0 0 .394-.167l3.764-6.86a3.655 3.655 0 0 1 4.497-1.646l4.635 1.783 3.699 9.878-24.684 9.24Zm-.883-5.726 1.575 4.205 22.464-8.416-3.101-8.274-4.14-1.593a2.47 2.47 0 0 0-3.036 1.11l-3.764 6.86c-.34.62-1.056.924-1.736.745L29.638 62.8a.938.938 0 0 0-1.12.579l-2.244 6.096ZM34.454 56.178a3.398 3.398 0 1 1-.006-6.794 3.398 3.398 0 0 1 .006 6.794Zm-.006-5.613a2.214 2.214 0 0 0-2.07 2.995 2.222 2.222 0 0 0 2.852 1.3 2.214 2.214 0 0 0 1.294-2.851 2.206 2.206 0 0 0-2.076-1.444Z"
      fill="#fff"
    />
    <path
      opacity={0.36}
      d="m114.17 73.032-21.223 8.222a3.788 3.788 0 0 1-4.904-2.165l-8.222-21.224a3.788 3.788 0 0 1 2.165-4.904l21.224-8.221a3.789 3.789 0 0 1 4.904 2.165l8.221 21.224a3.792 3.792 0 0 1-2.165 4.903Z"
      fill="#4C65BA"
    />
    <path
      d="m112.174 72.153-21.223 8.222a3.788 3.788 0 0 1-4.904-2.165l-8.222-21.224a3.788 3.788 0 0 1 2.165-4.903l21.224-8.222a3.789 3.789 0 0 1 4.904 2.165l8.221 21.224a3.783 3.783 0 0 1-2.165 4.903Z"
      fill="#fff"
    />
    <path
      d="m103.264 45.835-23.473 9.093L88.883 78.4l23.473-9.093-9.092-23.473Z"
      fill="#D9F7BE"
    />
    <path
      opacity={0.47}
      d="m88.515 79.006-2.204-5.697 2.332-6.534a2.11 2.11 0 0 1 2.52-1.34l4.912 1.258a.349.349 0 0 0 .391-.171l3.689-6.901a3.655 3.655 0 0 1 4.479-1.696l4.654 1.733 3.807 9.836-24.58 9.512Zm-.946-5.717 1.621 4.188 22.371-8.664-3.193-8.239-4.157-1.547a2.47 2.47 0 0 0-3.024 1.143l-3.688 6.901a1.533 1.533 0 0 1-1.728.765l-4.912-1.258a.937.937 0 0 0-1.114.59l-2.176 6.121ZM95.6 59.904a3.398 3.398 0 1 1-.08-6.793 3.398 3.398 0 0 1 .08 6.793Zm-.067-5.613a2.214 2.214 0 0 0-2.037 3.017 2.222 2.222 0 0 0 2.866 1.27 2.214 2.214 0 0 0 1.263-2.866 2.206 2.206 0 0 0-2.092-1.42Z"
      fill="#fff"
    />
    <path
      d="M64.96 108.446c17.466 0 31.624-14.159 31.624-31.624 0-17.466-14.158-31.625-31.624-31.625-17.465 0-31.624 14.159-31.624 31.624 0 17.466 14.159 31.625 31.624 31.625Z"
      fill="#1890FF"
    />
    <path
      d="M105.525 111.728H24.39c-10.664 0-19.31-8.645-19.31-19.31v-6.352c0-10.665 8.646-19.31 19.31-19.31h81.135c10.665 0 19.31 8.645 19.31 19.31v6.352c0 10.665-8.645 19.31-19.31 19.31Z"
      fill="#1890FF"
    />
    <path
      d="M87.32 92.555c12.35-12.35 12.35-32.373 0-44.723-12.35-12.35-32.374-12.35-44.724 0-12.35 12.35-12.35 32.373 0 44.723 12.35 12.35 32.373 12.35 44.723 0Z"
      fill="#1890FF"
    />
    <path
      d="M57.564 74.901a1.454 1.454 0 0 1-1.02-2.494l8.486-8.34 8.351 8.35c.57.57.57 1.49 0 2.06-.57.569-1.489.569-2.059 0l-6.312-6.313-6.431 6.322c-.28.275-.645.415-1.015.415Z"
      fill="#fff"
    />
    <path
      d="M64.96 86.47c-.804 0-1.454-.65-1.454-1.454V66.11c0-.804.65-1.454 1.454-1.454.805 0 1.454.65 1.454 1.454v18.906c0 .805-.65 1.454-1.454 1.454Z"
      fill="#fff"
    />
    <path
      d="M78.71 95.452H50.987a3.608 3.608 0 0 1-3.603-3.603v-10.07c0-.805.65-1.455 1.454-1.455.805 0 1.454.65 1.454 1.454v10.07c0 .385.31.695.695.695h27.721a.91.91 0 0 0 .91-.91v-9.855c0-.804.65-1.454 1.454-1.454.805 0 1.454.65 1.454 1.454v9.856a3.818 3.818 0 0 1-3.818 3.818Z"
      fill="#fff"
    />
    <defs>
      <filter
        id="a"
        x={1.457}
        y={18.791}
        width={59.49}
        height={56.84}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 0.0941176 0 0 0 0 0.564706 0 0 0 0 1 0 0 0 0.2 0" />
        <feBlend in2="shape" result="effect1_innerShadow_5833_30506" />
      </filter>
      <filter
        id="b"
        x={73.291}
        y={13.573}
        width={60.752}
        height={57.273}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={1} dy={1} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 0.0941176 0 0 0 0 0.564706 0 0 0 0 1 0 0 0 0.2 0" />
        <feBlend in2="shape" result="effect1_innerShadow_5833_30506" />
      </filter>
    </defs>
  </svg>
);

export default UploadImage;
