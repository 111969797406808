import {
  Avatar,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  IconButton,
  Snackbar,
} from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { DeleteForWeb, EditForWeb } from "src/assets/icons";
import ActionWithUser from "src/components/ActionsModal/ActionWithUser";
import { User } from "src/types";
import { formatPhoneNumber } from "src/utils";
import teamTable from "./teamTable.json";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { styles } from "./teamTable.module";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Copy } from "src/assets/icons";

interface Props {
  allUsers: User[];
  fetchUsers: () => void;
  requestSort: (elem: string) => void;
  setSortOrder: (order: string) => void;
  sortBy: string;
  sortOrder: any;
}

const TeamTable: React.FC<Props> = ({
  allUsers,
  fetchUsers,
  requestSort,
  setSortOrder,
  sortBy,
  sortOrder,
}) => {
  const [openActionModal, setOpenActionModal] = useState(false);
  const [hover, setHover] = useState("");
  const [elem, setElem] = useState();
  const [isOpenSnackbar, setIsOpenSnackbar] = useState(false);
  const [modalActionType, setModalActionType] = useState({
    action: "",
    page: "",
  });

  const handleClipboard = () => {
    setIsOpenSnackbar(true);
  };

  const handleCloseSnackbar = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setIsOpenSnackbar(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnackbar}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const handleOpenModal = (type: string, item?: any) => {
    setOpenActionModal(true);
    setElem(item);
    setModalActionType((prevState) => ({
      ...prevState,
      action: type,
      page: "User",
    }));
  };

  function stringAvatar(name) {
    return {
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }

  const chooseType = (elem, item) => {
    switch (elem.type) {
      case "name":
        return (
          <Box sx={styles.boxContainer}>
            <Avatar
              {...stringAvatar(`${item[elem.array[0]]} ${item[elem.array[1]]}`)}
              sx={styles.avatar}
              src={item[elem.array[2]]}
            />
            {item[elem.array[0]]} {item[elem.array[1]]}
          </Box>
        );
      case "phoneNumber":
        return (
          <>
            {formatPhoneNumber(item[elem.array[0]])}
            <CopyToClipboard
              text={item[elem.array[0]]}
              onCopy={handleClipboard}
            >
              <IconButton
                aria-label="delete"
                size="small"
                sx={{ marginLeft: "5px" }}
              >
                <Copy width={20} height={20} />
              </IconButton>
            </CopyToClipboard>
          </>
        );
      case "email":
        return (
          <>
            {item[elem.array[0]]}
            <CopyToClipboard
              text={item[elem.array[0]]}
              onCopy={handleClipboard}
            >
              <IconButton
                aria-label="delete"
                size="small"
                sx={{ marginLeft: "5px" }}
              >
                <Copy width={20} height={20} />
              </IconButton>
            </CopyToClipboard>
          </>
        );
      case "hourlyRate":
        return item[elem.array[0]] === null ? (
          ""
        ) : (
          <Typography>${item[elem.array[0]]}/hr</Typography>
        );
      case "userType":
        return item[elem.array[0]] === true ? "Administrator" : "Employee";
      case "actions":
        return (
          <Box sx={styles.boxContainer}>
            <Box
              mr="20px"
              onClick={() => handleOpenModal("Edit", item)}
              onMouseEnter={() => setHover("edit" + item.id)}
              onMouseLeave={() => setHover("")}
            >
              <EditForWeb
                color={hover === "edit" + item.id ? "#0D6FF0" : "#8C8C8C"}
              />
            </Box>
            <Box
              onClick={() => handleOpenModal("Delete", item)}
              onMouseEnter={() => setHover("delete" + item.id)}
              onMouseLeave={() => setHover("")}
            >
              <DeleteForWeb
                color={hover === "delete" + item.id ? "#FF0003" : "#FF4D4F"}
              />
            </Box>
          </Box>
        );
    }
  };
  return (
    <>
      <TableContainer>
        <Table>
          <TableHead
            sx={{
              backgroundColor: "#FAFAFA",
            }}
          >
            <TableRow sx={styles.tableHeader}>
              {teamTable[0]?.label?.map((elem: string) => (
                <TableCell key={elem} sx={styles.lineTable}>
                  {elem === "Name" ||
                  elem === "Email" ||
                  elem === "Rate" ||
                  elem === "User Type" ? (
                    <TableSortLabel
                      active={sortBy === elem}
                      direction={sortOrder}
                      onClick={() => {
                        requestSort(elem);
                        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                      }}
                      sx={{
                        "& .MuiTableSortLabel-icon": {
                          display: "none",
                        },
                      }}
                    >
                      {elem}
                      <Box sx={styles.boxWrapper}>
                        <Box
                          sx={styles.boxSort}
                          onClick={() => setSortOrder("asc")}
                        >
                          <ArrowDropUpIcon
                            sx={{ height: "24px" }}
                            color={
                              sortOrder === "asc" && sortBy === elem
                                ? "primary"
                                : "disabled"
                            }
                          />
                        </Box>
                        <Box
                          sx={styles.boxSort}
                          onClick={() => setSortOrder("desc")}
                        >
                          <ArrowDropDownIcon
                            sx={{ height: "24px" }}
                            color={
                              sortOrder === "desc" && sortBy === elem
                                ? "primary"
                                : "disabled"
                            }
                          />
                        </Box>
                      </Box>
                    </TableSortLabel>
                  ) : (
                    elem
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody sx={{ backgroundColor: "#FFF" }}>
            {allUsers?.map((userItem: User) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={userItem.id}
                  sx={styles.tableHeader}
                >
                  {teamTable[1]?.data?.map((elem, i) => {
                    return (
                      <TableCell key={i} sx={styles.tableCell}>
                        {chooseType(elem, userItem)}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {openActionModal && (
        <ActionWithUser
          fetchSuccess={fetchUsers}
          setModalActionType={setModalActionType}
          modalActionType={modalActionType}
          elem={elem}
          isOpen={openActionModal}
          closeModal={() => setOpenActionModal(false)}
        />
      )}
      <Snackbar
        open={isOpenSnackbar}
        autoHideDuration={3000}
        message="Copied!"
        action={action}
        onClose={handleCloseSnackbar}
      />
    </>
  );
};

export default TeamTable;
