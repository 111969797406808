import { Theme } from "@mui/material";
export const styles = {
  appBarWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    height: "100vh",
    backgroundColor: (theme: Theme) => theme.palette.background.main,
    boxShadow: "none",
  },
  sidebar: {
    width: { xs: "250px", xl: "300px" },
    height: "100vh",
    backgroundColor: (theme: Theme) => theme.palette.background.paper,
    borderRight: (theme: Theme) =>
      `1px solid ${theme.palette.background.default}`,
    display: "flex",
    flexShrink: 0,
    flexDirection: "column",
    paddingTop: "20px",
  },
  toolbar: {
    display: "flex",
    flexDirection: "column",
    width: { xs: "calc(100% - 250px)", xl: "calc(100% - 300px)" },
  },
  navbar: {
    position: "relative",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    height: "60px",
    paddingRight: "36px",
    backgroundColor: (theme: Theme) => theme.palette.background.paper,
    borderBottom: (theme: Theme) =>
      `1px solid ${theme.palette.background.default}`,
  },
  licenseStatus: {
    padding: "8px 21px",
    borderRadius: "4px",
    fontWeight: "500",
    fontSize: "16px",
    cursor: "pointer",
  },
  settingsBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px",
    backgroundColor: "#F5F5F5",
    borderRadius: "4px",
    margin: "10px",
    cursor: "pointer",
  },
  user: {
    display: "flex",
    alignItems: "center",
    marginLeft: "12px",
    cursor: "pointer",
  },
  userPhoto: {
    width: "32px",
    height: "32px",
    fontSize: "14px",
    borderRadius: "50%",
    marginRight: "10px",
  },
  dropdown: {
    width: "140px",
    position: "absolute",
    top: "55px",
    right: "5px",
    padding: "20px",
    borderRadius: "4px",
    backgroundColor: (theme: Theme) => theme.palette.background.paper,
    border: (theme: Theme) => `1px solid ${theme.palette.background.default}`,
    zIndex: 10,
  },
  pageContainer: {
    flexGrow: 1,
    px: "20px",
    mt: "50px",
    pb: "50px",
    height: "calc(100vh - 160px)",
    overflow: "auto",
  },
  boxContainer: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    my: "22px",
  },
  boxWrapper: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
};
