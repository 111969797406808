import {
  Box,
  Theme,
  Typography,
  IconButton,
  InputAdornment,
  FormControl,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addUserRequest,
  cleanError,
  updateUserRequest,
} from "src/store/users/actions";
import { User } from "src/types";
import { formatPhoneNumber } from "src/utils";
import Input from "../../Input";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Select from "../../Select";
import { toast } from "react-toastify";
import { PHONE_NUMBER_REGEX } from "src/constants";
import { isFieldHasError } from "src/utils";
import { getUserSelector } from "src/store/users/selectors";
import { styles } from "./actionWithUser.module";
import { Button } from "src/components";
import theme from "src/theme";
import { getAuthSelector } from "src/store/auth/selectors";
import ContactSupportTooltipText from "src/components/TooltipText/ContactSupportTooltipText";
const validationSchema = yup.object().shape({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  email: yup
    .string()
    .email("Email must be valid")
    .required("Email is required"),
  phoneNumber: yup
    .string()
    .required("Phone number is required")
    .matches(PHONE_NUMBER_REGEX, "Phone number is not valid"),
  userType: yup.string().required("User type is required"),
  rate: yup.number().positive().min(0),
});

interface ModalProps {
  closeModal: () => void;
  fetchSuccess: () => void;
  setModalActionType?: any;
  elem?: User;
  modalActionType: {
    action: string;
    page: string;
  };
}

const ActionWithUser: React.FC<ModalProps> = ({
  elem,
  modalActionType,
  setModalActionType,
  closeModal,
  fetchSuccess,
}) => {
  const dispatch = useDispatch();
  const userState = useSelector(getUserSelector);
  const auth = useSelector(getAuthSelector);

  const formik = useFormik({
    initialValues: {
      phoneNumber:
        modalActionType.action === "Add"
          ? ""
          : formatPhoneNumber(elem.phoneNumber),
      firstName: modalActionType.action === "Add" ? "" : elem.firstName,
      lastName: modalActionType.action === "Add" ? "" : elem.lastName,
      email: modalActionType.action === "Add" ? "" : elem.email,
      userType:
        modalActionType.action === "Add"
          ? ""
          : elem.isAdmin
          ? "Administrator"
          : "Employee",
      rate:
        modalActionType.action === "Add"
          ? ""
          : elem?.hourlyRate?.toFixed(2) || "",
    },
    validationSchema: validationSchema,
    onSubmit: ({ phoneNumber, firstName, lastName, email, userType, rate }) => {
      const phoneNumberWithoutMask = phoneNumber.replace(/\D/g, "");
      const firstNameTrim = firstName.trim();
      const lastNameTrim = lastName.trim();
      if (!firstNameTrim || !lastNameTrim) return;
      else {
        if (modalActionType.action === "Add") {
          dispatch(
            addUserRequest(
              {
                firstName: firstNameTrim.trim(),
                lastName: lastNameTrim.trim(),
                phoneNumber: phoneNumberWithoutMask,
                email,
                isAdmin: userType === "Administrator" ? true : false,
                hourlyRate: rate === "0" ? 0 : parseFloat(rate),
              },
              { onSuccess: addUserSuccess }
            )
          );
        } else {
          dispatch(
            updateUserRequest(
              {
                user: {
                  id: elem.id,
                  firstName: firstName.trim(),
                  lastName: lastName.trim(),
                  isAdmin: userType === "Administrator" ? true : false,
                  hourlyRate: rate === "0" ? 0 : parseFloat(rate),
                },
              },
              { onSuccess: fetchSuccess }
            )
          );
        }
      }
    },
  });

  const addUserSuccess = () => {
    fetchSuccess();
    closeModal();
    toast.success(
      <>
        <Typography fontSize="16px" color="#262626">
          User Added
        </Typography>
        <Typography position="absolute" fontSize="14px" color="#262626">
          User was added and login instructions were texted and emailed to the
          user.
        </Typography>
      </>,
      {
        style: styles.toastStyle,
      }
    );
  };

  useEffect(() => {
    dispatch(cleanError());
    return () => {
      dispatch(cleanError());
    };
  }, []);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Box sx={styles.boxContainer}>
          <Box sx={{ width: "48%" }}>
            <Input
              type="text"
              fullWidth
              required
              size="small"
              label="First Name"
              id="firstName"
              inputProps={{ maxLength: 150 }}
              value={formik.values.firstName}
              onChange={formik.handleChange}
              error={isFieldHasError({
                formik,
                fieldName: "firstName",
                isGlobalError: Boolean(userState.error),
              })}
              helperText={formik.touched.firstName && formik.errors.firstName}
            />
          </Box>
          <Box sx={{ width: "48%" }}>
            <Input
              type="text"
              fullWidth
              required
              size="small"
              label="Last Name"
              inputProps={{ maxLength: 150 }}
              id="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={isFieldHasError({
                formik,
                fieldName: "lastName",
                isGlobalError: Boolean(userState.error),
              })}
              helperText={formik.touched.lastName && formik.errors.lastName}
            />
          </Box>
        </Box>
        <Box sx={styles.boxContainer}>
          <Box sx={{ width: "48%" }}>
            <Input
              required
              mask="(999) 999-9999"
              label="Phone Number"
              fullWidth
              size="small"
              inputProps={{ maxLength: 30 }}
              id="phoneNumber"
              value={formik.values.phoneNumber}
              isDisabled={modalActionType.action === "Edit"}
              onChange={formik.handleChange}
              error={isFieldHasError({
                formik,
                fieldName: "phoneNumber",
                isGlobalError: Boolean(userState.error),
              })}
              helperText={
                formik.touched.phoneNumber && formik.errors.phoneNumber
              }
              withLockIcon={modalActionType.action === "Edit"}
              tooltipText={<ContactSupportTooltipText />}
            />
          </Box>
          <Box sx={{ width: "48%" }}>
            <Input
              type="text"
              fullWidth
              required
              size="small"
              label="Email"
              inputProps={{ maxLength: 150 }}
              id="email"
              value={formik.values.email}
              disabled={modalActionType.action === "Edit"}
              onChange={formik.handleChange}
              error={isFieldHasError({
                formik,
                fieldName: "email",
                isGlobalError: Boolean(userState.error),
              })}
              helperText={formik.touched.email && formik.errors.email}
              withLockIcon={modalActionType.action === "Edit"}
              tooltipText={<ContactSupportTooltipText />}
            />
          </Box>
        </Box>
        <Box sx={styles.boxContainer}>
          <Box sx={{ width: "48%" }}>
            <Typography mb={"8px"} color="#8C8C8C">
              User Type{" "}
              <Typography component="span" color="#FF0000">
                *
              </Typography>
            </Typography>
            <FormControl fullWidth>
              <Select
                value={formik.values.userType}
                height="46px"
                data-sqa-id="user-type-select"
                fontSize="16px"
                disabled={elem?.id === auth.user.id}
                onChange={(opt) =>
                  formik.setFieldValue("userType", opt.target.value)
                }
                IconComponent={ArrowDropDownIcon}
                error={isFieldHasError({
                  formik,
                  fieldName: "userType",
                  isGlobalError: Boolean(userState.error),
                })}
              >
                <MenuItem key="Employee" value="Employee">
                  Employee
                </MenuItem>
                <MenuItem key="Administrator" value="Administrator">
                  Administrator
                </MenuItem>
              </Select>
              {formik.touched.userType && formik.errors.userType && (
                <FormHelperText
                  sx={{
                    color: (theme: Theme) => theme.palette.error.main,
                    m: "4px 14px 0",
                  }}
                >
                  {formik.touched.userType && formik.errors.userType}
                </FormHelperText>
              )}
            </FormControl>
          </Box>
          <Box sx={{ width: "48%", position: "relative" }}>
            <Input
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="start">
                      <Typography color="#262626">$/hr</Typography>
                    </IconButton>
                  </InputAdornment>
                ),
                inputProps: { min: 0, step: "0.01", max: 9999 },
              }}
              type="number"
              fullWidth
              size="small"
              label="Rate"
              id="rate"
              value={formik.values.rate}
              onChange={formik.handleChange}
            />
          </Box>
        </Box>
        {Boolean(userState.error) && (
          <Box>
            <FormHelperText
              sx={{
                color: (theme: Theme) => theme.palette.error.main,
                m: "4px 14px 0",
                textAlign: "center",
                padding: "5px",
                marginTop: "10px",
                fontSize: "14px",
              }}
            >
              {userState.error}
            </FormHelperText>
          </Box>
        )}
        <Box sx={styles.actionButtons}>
          {modalActionType.action === "Add" ? (
            <Typography
              fontSize="14px"
              fontWeight={400}
              color="#8C8C8C"
              sx={{ width: "55%" }}
            >
              Once the user is saved, the login instructions and temporary
              password will be emailed and texted to the user.
            </Typography>
          ) : (
            <Box>
              <Button
                data-sqa-id="delete-user-button"
                variant="outlined"
                color="error"
                size="small"
                style={{ padding: "10px 40px" }}
                fullWidth
                sx={styles.actionButtonSave}
                onClick={() =>
                  setModalActionType((prevState) => ({
                    ...prevState,
                    action: "Delete",
                  }))
                }
              >
                <Typography fontSize="14px">Delete User</Typography>
              </Button>
            </Box>
          )}
          <Box sx={styles.buttonContainer}>
            <Box sx={{ width: "45%" }}>
              <Button
                data-sqa-id="cancel-button"
                variant="outlined"
                color="secondary"
                size="small"
                fullWidth
                sx={styles.actionButton}
                onClick={closeModal}
              >
                <Typography fontSize="14px" color={theme.palette.text.primary}>
                  Cancel
                </Typography>
              </Button>
            </Box>
            <Box sx={{ width: "45%" }}>
              <Button
                data-sqa-id="save-button"
                variant="contained"
                color="primary"
                size="small"
                fullWidth
                sx={styles.actionButtonSave}
                disabled={userState.isLoading ? true : false}
                type="submit"
              >
                <Typography fontSize="14px">Save</Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default ActionWithUser;
