import React, { useCallback, useEffect } from "react";
import * as yup from "yup";
import {
  Alert,
  Theme,
  Container,
  Typography,
  InputAdornment,
  Card,
  Box,
  Link,
} from "@mui/material";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Input, SafeInput, Button } from "src/components";
import { AppRoutes, PHONE_NUMBER_REGEX } from "src/constants";
import {
  cleanError,
  getOrganizationRequest,
  loginRequest,
  resetState,
} from "src/store/auth/actions";
import { getErrorSelector } from "src/store/auth/selectors";
import { getMappedErrorMessage, isFieldHasError } from "src/utils";
import { Capacitor } from "@capacitor/core";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    paddingTop: "24px",
    paddingBottom: "24px",
  },
  cardWrapper: {
    width: "100%",
    mt: "33px",
    boxSizing: "border-box",
    borderColor: (theme: Theme) => `${theme.palette.background.default}`,
    px: {
      xs: "24px",
      md: "30px",
    },
    py: "50px",
    maxWidth: {
      xs: "350px",
      md: "460px",
    },
  },
  headerWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  startAdornment: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
  },
  divider: {
    backgroundColor: (theme: Theme) => `${theme.palette.divider}`,
    height: "100%",
    width: "1px",
    marginLeft: "10px",
  },
  alertErrorWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  alertError: {
    mt: "20px",
    background: "transparent",
    fontSize: "14px",
    color: (theme: Theme) => theme.palette.error[700],
  },
};

const validationSchema = yup.object().shape({
  phoneNumber: yup
    .string()
    .required("Phone number is a required field")
    .matches(PHONE_NUMBER_REGEX, "Phone number is not valid"),
  loginPassword: yup.string().required("Password is a required field"),
});

const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authenticationError = useSelector(getErrorSelector);

  const formik = useFormik({
    initialValues: {
      phoneNumber: "",
      loginPassword: "",
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: validationSchema,
    onSubmit: ({ phoneNumber, loginPassword }) => {
      if (!formik.values.phoneNumber || !formik.values.loginPassword) {
        return;
      }
      const phoneNumberWithoutMask = phoneNumber.replace(/\D/g, "");
      dispatch(
        loginRequest(
          { phoneNumber: phoneNumberWithoutMask, loginPassword },
          {
            onSuccess: onLoginSuccess,
          }
        )
      );
    },
  });

  useEffect(() => {
    dispatch(cleanError());
    dispatch(resetState());
    return () => {
      dispatch(cleanError());
    };
  }, []);

  const redirectToSignUp = useCallback(() => {
    navigate(AppRoutes.SignUp);
  }, [navigate]);

  const redirectToForgotPassword = useCallback(() => {
    navigate(AppRoutes.ForgotPassword);
  }, [navigate]);

  const redirectToPrivacyPolicy = useCallback(() => {
    navigate(AppRoutes.PrivacyPolicy);
  }, [navigate]);

  const redirectToTermsConditions = useCallback(() => {
    navigate(AppRoutes.TermsConditions);
  }, [navigate]);

  const onLoginSuccess = useCallback(() => {
    navigate(AppRoutes.Dashboard);
    dispatch(getOrganizationRequest());
  }, [navigate]);

  return (
    <Container sx={styles.container} maxWidth="sm">
      <Typography
        color="primary"
        fontWeight="800"
        fontSize={{ xs: "24px", md: "36px" }}
        data-sqa-id="page-title"
      >
        BusyBucket
      </Typography>
      <Card variant="outlined" sx={styles.cardWrapper}>
        <form onSubmit={formik.handleSubmit}>
          <Box sx={styles.headerWrapper}>
            <Typography
              fontWeight="bold"
              fontSize={{ xs: "20px", md: "24px" }}
              lineHeight={"28px"}
              data-sqa-id="form-label"
            >
              Sign In
            </Typography>
            {Capacitor.getPlatform() === "ios" ? null : (
              <Link
                data-sqa-id="i-dont-have-an-account-link"
                fontSize={{ xs: "14px", md: "16px" }}
                lineHeight={"22px"}
                color="primary"
                variant="body1"
                component="button"
                type="button"
                onClick={redirectToSignUp}
              >
                I don’t have an account
              </Link>
            )}
          </Box>
          <Box mt="30px" data-sqa-id="phone-number-box">
            <Input
              required
              mask="(999) 999-9999"
              label="Phone Number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={styles.startAdornment}>
                    <Typography>+1</Typography>
                    <Box sx={styles.divider} />
                  </InputAdornment>
                ),
              }}
              fullWidth
              size="small"
              id="phoneNumber"
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              error={isFieldHasError({
                formik,
                fieldName: "phoneNumber",
                isGlobalError: Boolean(authenticationError),
              })}
              helperText={
                formik.touched.phoneNumber && formik.errors.phoneNumber
              }
            />
          </Box>
          <Box mt="20px" data-sqa-id="password-box">
            <SafeInput
              label="Password"
              required
              fullWidth
              size="small"
              type="password"
              id="loginPassword"
              value={formik.values.loginPassword}
              onChange={formik.handleChange}
              error={isFieldHasError({
                formik,
                fieldName: "loginPassword",
                isGlobalError: Boolean(authenticationError),
              })}
              helperText={
                formik.touched.loginPassword && formik.errors.loginPassword
              }
            />
          </Box>
          <Box mt="20px">
            <Button
              id="signIn"
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
              size="small"
            >
              <Typography fontSize={{ xs: "14px", md: "16px" }}>
                Sign In
              </Typography>
            </Button>
          </Box>
          {Boolean(authenticationError) && (
            <Box sx={styles.alertErrorWrapper} data-sqa-id="error-wrapper-box">
              <Alert
                severity="error"
                sx={styles.alertError}
                data-sqa-id="errorAlert"
              >
                {getMappedErrorMessage(authenticationError)}
              </Alert>
            </Box>
          )}
          <Box
            sx={{
              marginTop: "22px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Link
              data-sqa-id="forgot-password-link"
              variant="body1"
              color="primary"
              component="button"
              fontSize={{ xs: "14px", md: "16px" }}
              onClick={redirectToForgotPassword}
            >
              Forgot Password?
            </Link>
          </Box>
        </form>
      </Card>
      <Link
        data-sqa-id="terms-conditions-footer-link"
        mt="30px"
        variant="body1"
        component="button"
        fontSize={{ xs: "12px", md: "14px" }}
        color="secondary"
        sx={{ marginTop: "30px" }}
        onClick={redirectToTermsConditions}
      >
        Terms of Service
      </Link>
      <Link
        data-sqa-id="privacy-policy-footer-link"
        variant="body1"
        color="secondary"
        component="button"
        fontSize={{ xs: "12px", md: "14px" }}
        sx={{ marginTop: "20px" }}
        onClick={redirectToPrivacyPolicy}
      >
        Privacy Policy
      </Link>
    </Container>
  );
};

export default SignIn;
