import * as React from "react";
import { SVGProps } from "react";

interface Props {
  color?: string;
}

const DeleteForWeb: React.FC<Props> = (
  { color },
  props: SVGProps<SVGSVGElement>
) => (
  <svg
    data-sqa-id="delete-icon"
    width={20}
    height={20}
    fill="none"
    {...props}
    color={color}
  >
    <path
      d="M6.179 2.607H6a.18.18 0 0 0 .179-.178v.178h6.785V2.43c0 .098.08.178.179.178h-.179v1.607h1.607V2.43A1.43 1.43 0 0 0 13.143 1H6a1.43 1.43 0 0 0-1.429 1.429v1.785H6.18V2.607Zm11.25 1.607H1.714A.714.714 0 0 0 1 4.93v.714c0 .098.08.178.179.178h1.348l.551 11.675a1.43 1.43 0 0 0 1.426 1.361h10.134c.764 0 1.391-.598 1.427-1.361l.551-11.675h1.348a.18.18 0 0 0 .179-.178v-.714a.714.714 0 0 0-.714-.715ZM14.467 17.25h-9.79l-.54-11.429h10.87l-.54 11.429Z"
      fill="currentColor"
    />
  </svg>
);

export default DeleteForWeb;
