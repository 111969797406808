import { Box, Typography, IconButton, Theme } from "@mui/material";
import React from "react";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import Button from "../Button";
import theme from "src/theme";

const styles = {
  modal: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "95%",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "4px",
    overflow: "auto",
    maxHeight: "95%",
  },
  modalTitile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "16px 24px",
    borderBottom: (theme: Theme) =>
      `1px solid ${theme.palette.background.default}`,
  },
  modalContent: {
    padding: "24px",
    px: {
      xs: "24px",
      md: "20px",
    },
  },
  modalClose: {
    color: (theme: Theme) => `${theme.palette.text.primary}`,
    p: 0,
  },
  modalCloseBtn: {
    textAlign: "center",
  },
};

interface ModalProps {
  closeModal: () => void;
  children: React.ReactElement;
  isOpen: boolean;
  maxWidth?: string | number;
  maxHeight?: string | number;
  modalActionType: {
    action?: string;
    page?: string;
  };
  withCloseBtn?: boolean;
}

const ModalWrapper: React.FC<ModalProps> = ({
  isOpen,
  modalActionType,
  maxWidth = "350px",
  maxHeight,
  closeModal,
  children,
  withCloseBtn,
}) => {
  return (
    <>
      <Modal
        disableAutoFocus={true}
        open={isOpen}
        onClose={closeModal}
        sx={{ padding: "20px" }}
      >
        <Box id="modal-box" sx={[styles.modal, { maxWidth, maxHeight }]}>
          <Box id="modal-title-box" sx={styles.modalTitile}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              fontSize={{ xs: "16px", md: "18px" }}
              fontWeight={{ xs: 700, md: 500 }}
            >
              {`${modalActionType.action} ${modalActionType.page}`}
            </Typography>
            <IconButton
              id="modal-close-btn"
              onClick={closeModal}
              sx={styles.modalClose}
            >
              <CloseIcon
                id="modal-close-icon"
                fontSize="small"
                color="disabled"
              />
            </IconButton>
          </Box>
          <Box id="modal-content" sx={styles.modalContent}>
            {children}
          </Box>
          {withCloseBtn && (
            <Box mb="20px" sx={styles.modalCloseBtn}>
              <Button
                variant="outlined"
                size="small"
                color="secondary"
                style={{ padding: "4px 63px" }}
                onClick={closeModal}
              >
                <Typography color={theme.palette.common.black}>
                  Close
                </Typography>
              </Button>
            </Box>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default ModalWrapper;
