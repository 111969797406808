import { isMobile } from "mobile-device-detect";
import React from "react";
import { Support } from "src/pages/common";
import ModalWrapper from "./ModalWrapper";

interface ModalProps {
  closeModal: () => void;
  isOpen: boolean;
  modalActionType;
}

const SupportModal: React.FC<ModalProps> = ({
  isOpen,
  closeModal,
  modalActionType,
}) => {
  return (
    <>
      <ModalWrapper
        closeModal={closeModal}
        isOpen={isOpen}
        modalActionType={modalActionType}
        maxWidth={isMobile ? "350px" : "580px"}
      >
        <>
          <Support closeModal={closeModal} />
        </>
      </ModalWrapper>
    </>
  );
};

export default SupportModal;
