import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cleanError, deleteUserRequest } from "src/store/users/actions";
import { User } from "src/types";
import { Button } from "src/components";
import theme from "src/theme";
import { getUserSelector } from "src/store/users/selectors";

const styles = {
  actionButtons: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    columnGap: "10px",
    marginTop: "20px",
  },
  actionButton: {
    textTransform: "none",
    marginBottom: "10px",
  },
  actionButtonDelete: {
    textTransform: "none",
  },
};

interface ModalProps {
  closeModal: () => void;
  fetchSuccess: () => void;
  elem: User;
  modalActionType: {
    action: string;
    page: string;
  };
}

const DeleteUser: React.FC<ModalProps> = ({
  closeModal,
  fetchSuccess,
  elem,
}) => {
  const dispatch = useDispatch();
  const userState = useSelector(getUserSelector);
  const deleteElement = () => {
    dispatch(
      deleteUserRequest(
        { id: elem.id },
        {
          onSuccess: () => {
            fetchSuccess();
            closeModal();
          },
        }
      )
    );
  };

  useEffect(() => {
    return () => {
      dispatch(cleanError());
    };
  }, []);

  return (
    <>
      <Typography fontSize={14}>
        Are you sure you want to delete {elem?.firstName + " " + elem?.lastName}
        ?
      </Typography>
      {userState.error && (
        <Typography mt="10px" color={"#ff0000"} fontSize={14}>
          {userState.error}
        </Typography>
      )}
      <Box sx={styles.actionButtons}>
        <Box>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            style={{ padding: "10px 20px" }}
            fullWidth
            sx={styles.actionButton}
            onClick={closeModal}
          >
            <Typography fontSize="14px" color={theme.palette.text.primary}>
              Cancel
            </Typography>
          </Button>
        </Box>
        <Box>
          <Button
            variant="outlined"
            color="error"
            size="small"
            style={{ padding: "10px 20px" }}
            fullWidth
            sx={styles.actionButtonDelete}
            onClick={deleteElement}
          >
            <Typography fontSize="14px">Delete User</Typography>
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default DeleteUser;
