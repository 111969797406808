import axios from "../../utils/axios";
import {
  ChangePasswordRequestPayload,
  CheckPasswordResetRequestPayload,
  EmailConfirmationRequestPayload,
  LoginRequestPayload,
  NewPasswordRequestPayload,
  PasswordResetRequestPayload,
  SendVerificationCodeRequestPayload,
  SignUpRequestPayload,
  UpdateOrganizationRequestPayload,
  UpdateUserDataRequestPayload,
} from "./types";

export const login = (payload: LoginRequestPayload) => {
  return axios.post("/authenticate", payload);
};

export const signUp = (payload: SignUpRequestPayload) => {
  return axios.post("/signUp", payload);
};

export const emailConfirmation = (payload: EmailConfirmationRequestPayload) => {
  return axios.post("/emailConfirmation", null, {
    headers: {
      "X-Email-Confirmation-Token": payload.token,
    },
  });
};

export const currentUser = () => {
  return axios.get("/currentUser");
};

export const updateUser = (payload: UpdateUserDataRequestPayload) => {
  return axios.put("/user", payload.user);
};

export const deleteUserProfilePicture = () => {
  return axios.delete("/currentUser/profilePicture");
};

export const deleteCurrentUser = () => {
  return axios.delete("/currentUser");
};

export const passwordReset = (payload: PasswordResetRequestPayload) => {
  return axios.post("/requestPasswordReset", payload);
};

export const checkPasswordReset = (
  payload: CheckPasswordResetRequestPayload
) => {
  return axios.get("/passwordReset", {
    headers: {
      "X-Reset-Token": payload.token,
    },
  });
};

export const newPassword = (payload: NewPasswordRequestPayload) => {
  return axios.post("/passwordReset", null, {
    headers: {
      "X-Reset-Token": payload.token,
      "X-New-Password": payload.newPassword,
    },
  });
};

export const sendVerificationSms = () => {
  return axios.post("/sendVerificationSms");
};

export const phoneVerification = (
  payload: SendVerificationCodeRequestPayload
) => {
  return axios.put(`/phoneVerification/${payload.phoneVerificationCode}`);
};

export const getOrganization = () => {
  return axios.get("/organization");
};

export const updateOrganization = (
  payload: UpdateOrganizationRequestPayload
) => {
  return axios.put("/organization", payload.organization);
};

export const deleteOrganization = () => {
  return axios.delete("/organization/closeAccount");
};

export const changePassword = (payload: ChangePasswordRequestPayload) => {
  return axios.post("/user/changePassword", payload);
};
