import React from "react";

type IconProps = {
  width?: number;
  height?: number;
  color?: string;
};

const Arrow: React.FC<IconProps> = (
  { color, width = 8, height = 5 },
  props: any
) => {
  return (
    <svg
      data-sqa-id="arrow-icon"
      {...props}
      width={width}
      height={height}
      viewBox={`0 0 8 5`}
      fill="none"
    >
      <path d="M1 1L4 4L7 1" stroke={color ? color : "currentColor"} />
    </svg>
  );
};

export default Arrow;
