import {
  Box,
  CircularProgress,
  FormControl,
  Slider,
  Theme,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "src/components";
import theme from "src/theme";
import ModalWrapper from "./ModalWrapper";
import AvatarEditor from "react-avatar-editor";
import { isMobile } from "mobile-device-detect";
import { getPresignedUrlRequest } from "src/store/attachments/actions";
import axios from "axios";
import { updateUserDataRequest } from "src/store/auth/actions";
import { getAttachmentsSelector } from "src/store/attachments/selectors";
import { getUserSelector } from "src/store/auth/selectors";

const styles = {
  filledInput: {
    display: "none",
  },
  profilePhoto: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:after": {
      position: "absolute",
      width: "200px",
      height: "200px",
      border: (theme: Theme) => `3px dashed ${theme.palette.background.main}`,
      borderRadius: "50%",
      content: `""`,
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      boxSizing: "border-box",
      pointerEvents: "none",
    },
  },
  avatarEditor: {
    borderRadius: "10px",
  },
  scaleSlider: {
    my: "30px",
    height: "10px",
    p: 0,
    ".MuiSlider-rail": {
      border: (theme: Theme) => `1px solid ${theme.palette.background.default}`,
      background: (theme: Theme) => `${theme.palette.background.main}`,
      opacity: 1,
    },
    ".MuiSlider-thumb": {
      width: "30px",
      height: "30px",
      border: (theme: Theme) => `1px solid ${theme.palette.background.default}`,
    },
    ".MuiSlider-track": {
      background: "none",
      border: "none",
    },
  },
};

interface ModalProps {
  isOpen: boolean;
  closeModal: () => void;
  modalActionType: {
    action: string;
    page: string;
  };
}

const AvatarModal: React.FC<ModalProps> = ({
  closeModal,
  isOpen,
  modalActionType,
}) => {
  const dispatch = useDispatch();
  let editor = useRef(null);
  const userImageUrl = useSelector(getAttachmentsSelector);
  const user = useSelector(getUserSelector);
  const uploadNewPhoto = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [photo, setPhoto] = useState<File | null>(null);
  const [picture, setPicture] = useState({
    cropperOpen: false,
    img: user?.profilePictureUrl,
    zoom: 1,
    croppedImg: user?.profilePictureUrl,
  });

  const handleSlider = (event, value) => {
    setPicture({
      ...picture,
      zoom: value,
    });
  };

  const handleCancel = () => {
    setPicture({
      ...picture,
      cropperOpen: false,
    });
    closeModal();
  };

  const handleFileChange = (event: React.ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    let url = URL.createObjectURL(target.files[0]);
    let file: File = (target.files as FileList)[0];
    setPhoto(file);
    setPicture({
      ...picture,
      img: url,
      cropperOpen: true,
    });
  };

  const handleUploadNewPhoto = () => {
    uploadNewPhoto.current.click();
  };

  const handleSave = () => {
    if (editor) {
      setIsLoading(true);
      const canvasScaled = editor.current.getImageScaledToCanvas();
      canvasScaled.toBlob((r) => {
        const f = new File([r], photo.name, { type: photo.type });
        setPhoto(f);
      });

      setPicture({
        ...picture,
        cropperOpen: false,
        // croppedImg: croppedImg,
      });
      dispatch(
        getPresignedUrlRequest({
          s3ContentType: photo.type,
          numberOfUrlsToGenerate: 1,
        })
      );
    }
  };

  const uploadImageToS3 = async () => {
    if (photo !== null) {
      let options = {
        headers: {
          "Content-Type": photo.type,
        },
      };
      const result = await axios.put(
        userImageUrl.f3images[0]?.presignedUrl,
        photo,
        options
      );
      if (result) {
        dispatch(
          updateUserDataRequest(
            {
              user: {
                id: user.id,
                profilePictureS3FilePath: userImageUrl.f3images[0].s3FilePath,
                thumbnailProfilePictureS3FilePath:
                  userImageUrl.f3images[0].s3FilePath.replace(
                    "/",
                    "/thumbnail-"
                  ),
              },
            },
            {
              onSuccess: () => {
                closeModal();
                setIsLoading(false);
              },
            }
          )
        );
      }
    }
  };

  useEffect(() => {
    uploadImageToS3();
  }, [userImageUrl]);

  return (
    <>
      <ModalWrapper
        closeModal={closeModal}
        isOpen={isOpen}
        modalActionType={modalActionType}
        maxWidth={isMobile ? "350px" : "440px"}
      >
        {isLoading ? (
          <Box
            data-sqa-id="circular-progress-loader"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <FormControl fullWidth sx={{ marginBottom: "20px" }}>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                type="submit"
                size="small"
                onClick={handleUploadNewPhoto}
              >
                <Typography>Upload New Photo</Typography>
              </Button>
              <input
                type="file"
                ref={uploadNewPhoto}
                onChange={handleFileChange}
                name="avatar"
                id="avatar"
                accept=".png, .jpg, .jpeg"
                style={styles.filledInput}
              />
            </FormControl>

            <Box sx={styles.profilePhoto}>
              <AvatarEditor
                ref={editor}
                image={picture.img}
                border={isMobile ? 50 : 100}
                width={200}
                height={200}
                borderRadius={100}
                color={[0, 0, 0, 0.3]}
                scale={picture.zoom}
                rotate={0}
                style={styles.avatarEditor}
              />
            </Box>
            <Slider
              aria-label="raceSlider"
              value={picture.zoom}
              min={1}
              max={10}
              step={0.1}
              onChange={handleSlider}
              sx={styles.scaleSlider}
              disabled={!photo}
            ></Slider>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                variant="outlined"
                color="secondary"
                type="submit"
                size="small"
                style={{ padding: "10px 40px" }}
                onClick={handleCancel}
              >
                <Typography color={theme.palette.text.primary}>
                  Cancel
                </Typography>
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                size="small"
                disabled={!photo}
                style={{ marginLeft: "20px", padding: "10px 40px" }}
                onClick={handleSave}
              >
                <Typography>Save</Typography>
              </Button>
            </Box>
          </>
        )}
      </ModalWrapper>
    </>
  );
};

export default AvatarModal;
