import { Box, Modal } from "@mui/material";
import React, { useState } from "react";
import { Back } from "src/assets/icons";
import { styles } from "./attachmetSlider.module";
import { Attachment } from "src/types";
import AttachmentSlide from "./AttachmentSlide";

interface ModalProps {
  closeModal: () => void;
  fetchSuccess: () => void;
  elem: any;
  activeElem: string;
  isOpen: boolean;
  imageUrlPrefix: string;
}

const AttachmetSlider: React.FC<ModalProps> = ({
  isOpen,
  closeModal,
  fetchSuccess,
  elem,
  activeElem,
  imageUrlPrefix,
}) => {
  const startId: number = elem.findIndex((el) => activeElem === el.id);
  const [activeStep, setActiveStep] = useState<number>(startId);

  const handleNext = () => {
    if (activeStep === elem.length - 1) setActiveStep(0);
    else setActiveStep((prevState) => prevState + 1);
  };

  const handleBack = () => {
    if (activeStep === 0) setActiveStep(elem.length - 1);
    else setActiveStep((prevState) => prevState - 1);
  };

  return (
    <>
      <Modal
        disableAutoFocus={true}
        open={isOpen}
        onClose={closeModal}
        sx={{ padding: "20px" }}
      >
        <Box sx={[styles.modal, { maxWidth: "70%" }]}>
          <Box sx={styles.prevArrow} onClick={handleBack}>
            <Back width={19} height={18} color="#0D6FF0" />
          </Box>
          <Box onClick={handleNext} sx={styles.nextArrow}>
            <Back width={19} height={18} color="#0D6FF0" />
          </Box>
          <Box sx={styles.modalContent}>
            {elem.map((item: Attachment, i: number) => (
              <Box
                display={activeStep === i ? "flex" : "none"}
                key={item.id}
                sx={styles.contentWrapper}
              >
                <AttachmentSlide
                  item={item}
                  imageUrlPrefix={imageUrlPrefix}
                  closeModal={closeModal}
                  fetchSuccess={fetchSuccess}
                />
              </Box>
            ))}
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default AttachmetSlider;
