import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cleanError } from "src/store/auth/actions";
import { getErrorSelector } from "src/store/auth/selectors";
import theme from "src/theme";
import Button from "../Button";
import ModalWrapper from "./ModalWrapper";

const styles = {
  actionButtons: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "20px",
  },
  actionButton: {
    textTransform: "none",
  },
  actionButtonDelete: {
    textTransform: "none",
  },
};

interface ModalProps {
  closeModal: () => void;
  fetchRequest?: () => void;
  isOpen: boolean;
  elem: string;
  modalActionType: {
    action: string;
    page: string;
  };
}

const DeactivateUserModal: React.FC<ModalProps> = ({
  closeModal,
  fetchRequest,
  isOpen,
  elem,
  modalActionType,
}) => {
  const errorState = useSelector(getErrorSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(cleanError());
    };
  }, []);

  return (
    <>
      <ModalWrapper
        closeModal={closeModal}
        isOpen={isOpen}
        maxWidth={elem === "admin" ? "450px" : "350px"}
        modalActionType={modalActionType}
      >
        <>
          <Typography fontSize={{ md: 16, xs: 14 }}>
            {elem === "user"
              ? "Are you sure you want to delete the account? This can't be undone!"
              : "By closing your account, you will not be able to access your data. Your team will lose access as well. Are you sure you want to close the account?"}
          </Typography>
          {errorState && (
            <Box mt="10px">
              <Typography fontSize={{ md: 16, xs: 14 }} color="error">
                {errorState}
              </Typography>
            </Box>
          )}
          <Box
            sx={[
              styles.actionButtons,
              { columnGap: elem === "user" ? "10px" : "20px" },
            ]}
          >
            <Box width={elem === "admin" ? "25%" : "50%"}>
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                style={{ padding: "10px 20px" }}
                fullWidth
                sx={styles.actionButton}
                onClick={closeModal}
              >
                <Typography fontSize="14px" color={theme.palette.text.primary}>
                  Cancel
                </Typography>
              </Button>
            </Box>
            <Box width={elem === "admin" ? "45%" : "50%"}>
              <Button
                variant="outlined"
                color="error"
                size="small"
                style={{ padding: "10px 20px" }}
                fullWidth
                sx={styles.actionButtonDelete}
                onClick={fetchRequest}
              >
                {elem === "user" ? "Delete" : "Close Account"}
              </Button>
            </Box>
          </Box>
        </>
      </ModalWrapper>
    </>
  );
};

export default DeactivateUserModal;
