import React from "react";
import AppBar from "src/components/AppBar";
import { Support } from "../common";

const SupportMobile = () => {
  return (
    <>
      <AppBar>
        <Support />
      </AppBar>
    </>
  );
};

export default SupportMobile;
