import { Pagination, PaginationItem, Stack } from "@mui/material";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import React from "react";

interface Props {
  pages: number;
  page: number;
  handleChangePage: (elem: unknown, pageNum: number) => void;
}

const styles = {
  pagination: {
    ".Mui-selected": {
      backgroundColor: "transparent !important",
    },
    ".MuiPaginationItem-root:hover": {
      borderColor: "#0D6FF0",
      color: "#0D6FF0",
      backgroundColor: "#FFF",
    },
    ".MuiPaginationItem-previousNext:hover, .MuiPaginationItem-firstLast:hover":
      {
        backgroundColor: "#FFF",
        borderColor: "#0D6FF0",
      },
    ".MuiPaginationItem-previousNext:hover .MuiPaginationItem-icon": {
      color: "#0D6FF0",
    },
    ".MuiPaginationItem-firstLast:hover .MuiPaginationItem-icon": {
      color: "#0D6FF0",
    },
  },
};

const PaginationContainer: React.FC<Props> = ({
  pages,
  page,
  handleChangePage,
}) => {
  return (
    <>
      <Stack spacing={2}>
        <Pagination
          sx={styles.pagination}
          count={pages}
          showFirstButton
          showLastButton
          color="primary"
          variant="outlined"
          shape="rounded"
          page={page}
          onChange={handleChangePage}
          renderItem={(item) => (
            <PaginationItem
              components={{
                first: KeyboardDoubleArrowLeftIcon,
                last: KeyboardDoubleArrowRightIcon,
              }}
              {...item}
            />
          )}
        />
      </Stack>
    </>
  );
};

export default PaginationContainer;
