import { Box, Typography } from "@mui/material";
import { isMobile } from "mobile-device-detect";
import React, { useEffect } from "react";
import theme from "src/theme";
import Button from "../Button";
import CreditCardForm from "../CreditCardForm";
import ModalWrapper from "./ModalWrapper";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  cleanError,
  getSubscriptionRequest,
  updatePaymentRequest,
} from "src/store/subscription/actions";
import { useDispatch } from "react-redux";
import { ZIP_NUMBER_REGEX } from "src/constants";
import { useSelector } from "react-redux";
import { getSubscriptionSelector } from "src/store/subscription/selectors";

const validationSchema = yup.object().shape({
  cardHolderName: yup.string().required("Cardholder name is required"),
  cardNumber: yup.number().required("Card number is required"),
  expDate: yup.string().required("Expiration date is required"),
  cvc: yup.number().required("CVC is required"),
  zip: yup
    .string()
    .required("Zip code is required")
    .matches(ZIP_NUMBER_REGEX, "Zip code is not valid"),
});

interface ModalProps {
  closeModal: () => void;
  isOpen: boolean;
  modalActionType: {
    action: string;
    page: string;
  };
}
const CreditCardUpdate: React.FC<ModalProps> = ({
  isOpen,
  modalActionType,
  closeModal,
}) => {
  const dispatch = useDispatch();
  const { isLoadingPaymentUpdate } = useSelector(getSubscriptionSelector);
  const formik = useFormik({
    initialValues: {
      cardHolderName: "",
      cardNumber: "",
      expDate: "",
      cvc: "",
      zip: "",
    },
    validationSchema: validationSchema,
    onSubmit: ({ cardHolderName, cardNumber, expDate, cvc, zip }) => {
      console.log(zip);
      const cardHolderNameTrim = cardHolderName.trim();
      if (!cardHolderNameTrim) return;
      else {
        dispatch(
          updatePaymentRequest(
            {
              cardHolderName: cardHolderNameTrim.trim(),
              cardNumber,
              expMonth: parseInt(expDate.slice(0, 2)),
              expYear: parseInt(expDate.slice(5)),
              cvc,
            },
            {
              onSuccess: () => {
                dispatch(getSubscriptionRequest());
                closeModal();
              },
            }
          )
        );
      }
    },
  });

  useEffect(() => {
    dispatch(cleanError());
    return () => {
      dispatch(cleanError());
    };
  }, []);

  return (
    <>
      <ModalWrapper
        closeModal={closeModal}
        isOpen={isOpen}
        modalActionType={modalActionType}
        maxWidth={isMobile ? "350px" : "580px"}
      >
        <>
          <form onSubmit={formik.handleSubmit}>
            <CreditCardForm formik={formik} />
            <Box mt="25px" sx={{ textAlign: "right" }}>
              {!isMobile && (
                <Button
                  variant="outlined"
                  size="small"
                  color="secondary"
                  style={{
                    marginRight: "20px",
                    padding: "4px 40px",
                  }}
                  onClick={closeModal}
                >
                  <Typography color={theme.palette.text.primary}>
                    Cancel
                  </Typography>
                </Button>
              )}
              <Button
                variant="contained"
                size="small"
                color="primary"
                style={{ padding: "4px 40px" }}
                fullWidth={isMobile}
                type="submit"
                disabled={isLoadingPaymentUpdate}
              >
                <Typography>Save</Typography>
              </Button>
            </Box>
          </form>
        </>
      </ModalWrapper>
    </>
  );
};

export default CreditCardUpdate;
