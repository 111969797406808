import { Box, Typography } from "@mui/material";
import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Button, PasswordStrength, SafeInput } from "src/components";
import { useDispatch, useSelector } from "react-redux";
import { PASSWORD_REGEX } from "src/constants";
import { changePasswordRequest, cleanError } from "src/store/auth/actions";
import { getErrorSelector } from "src/store/auth/selectors";
import { isFieldHasError } from "src/utils";
import { isMobile } from "mobile-device-detect";
import { toast } from "react-toastify";

const validationSchema = yup.object().shape({
  currentPassword: yup.string().required("Current Password is required"),
  newPassword: yup
    .string()
    .required("New Password is required")
    .matches(PASSWORD_REGEX, "Password is not valid.")
    .notOneOf([yup.ref("currentPassword"), null], "Passwords must not match"),
  newPasswordConfirmation: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
});

const SecurityForm = forwardRef((_props, ref) => {
  const authenticationError = useSelector(getErrorSelector);
  const dispatch = useDispatch();
  useImperativeHandle(ref, () => formik.handleSubmit);
  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      newPasswordConfirmation: "",
    },
    validationSchema: validationSchema,
    onSubmit: ({ currentPassword, newPassword, newPasswordConfirmation }) => {
      if (
        !formik.values.currentPassword &&
        !formik.values.newPassword &&
        !formik.values.newPasswordConfirmation
      ) {
        return;
      }
      dispatch(
        changePasswordRequest(
          {
            currentPassword,
            newPassword,
            newPasswordConfirmation,
          },
          {
            onSuccess,
          }
        )
      );
    },
  });

  useEffect(() => {
    return () => {
      dispatch(cleanError());
    };
  }, []);

  const onSuccess = () => {
    formik.resetForm();
    toast.success(
      <>
        <Typography fontSize="16px" color="#262626">
          Password updated successfully
        </Typography>
      </>
    );
  };

  return (
    <>
      <Box width="100%">
        <Box>
          <SafeInput
            label="Current Password"
            required={isMobile ? false : true}
            fullWidth
            size="small"
            type="password"
            inputProps={{ maxLength: 50 }}
            id="currentPassword"
            value={formik.values.currentPassword}
            onChange={formik.handleChange}
            error={isFieldHasError({
              formik,
              fieldName: "currentPassword",
              isGlobalError: Boolean(authenticationError),
            })}
            helperText={
              formik.touched.currentPassword && formik.errors.currentPassword
            }
          />
        </Box>
        <Box ml="14px" mt="5px">
          {authenticationError && (
            <Typography color="#ff0000" fontSize={12}>
              {authenticationError}
            </Typography>
          )}
        </Box>
        <Box mt="20px">
          <SafeInput
            label="New Password"
            required={isMobile ? false : true}
            fullWidth
            size="small"
            type="password"
            inputProps={{ maxLength: 50 }}
            id="newPassword"
            value={formik.values.newPassword}
            onChange={formik.handleChange}
            error={isFieldHasError({
              formik,
              fieldName: "newPassword",
              // isGlobalError: Boolean(authenticationError),
            })}
            helperText={formik.touched.newPassword && formik.errors.newPassword}
          />
          <PasswordStrength isValid={!formik.errors.newPassword} />
        </Box>
        <Box mt="20px">
          <SafeInput
            label="Confirm Password"
            required={isMobile ? false : true}
            fullWidth
            size="small"
            type="password"
            inputProps={{ maxLength: 50 }}
            id="newPasswordConfirmation"
            value={formik.values.newPasswordConfirmation}
            onChange={formik.handleChange}
            error={isFieldHasError({
              formik,
              fieldName: "newPasswordConfirmation",
              // isGlobalError: Boolean(authenticationError),
            })}
            helperText={
              formik.touched.newPasswordConfirmation &&
              formik.errors.newPasswordConfirmation
            }
          />
        </Box>
        {isMobile && (
          <Box mt="20px">
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => formik.handleSubmit()}
            >
              <Typography fontSize={{ xs: "14px", md: "16px" }}>
                Save
              </Typography>
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
});

SecurityForm.displayName = "SecurityForm";

export default SecurityForm;
