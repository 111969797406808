import React from "react";

interface Props {
  color?: string;
}

const Play: React.FC<Props> = ({ color }) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12.5" cy="12.5" r="12.5" fill="white" />
      <path
        d="M9.84375 8.28125L16.4062 12.5L9.84375 16.7188V8.28125Z"
        fill={color}
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Play;
