import React, { useCallback, useEffect } from "react";
import {
  Box,
  CircularProgress,
  Container,
  Link,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { AppRoutes } from "src/constants";
import { Button } from "src/components";
import theme from "src/theme";
import { useDispatch, useSelector } from "react-redux";
import { FailedIcon, SuccessIcon } from "src/assets/icons";
import { getAuthSelector, getErrorSelector } from "src/store/auth/selectors";
import { cleanError, emailConfirmationRequest } from "src/store/auth/actions";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    paddingTop: "24px",
    paddingBottom: "24px",
  },
  messageSuccess: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    backgroundColor: "#F0F9F0",
    border: "1px solid #67BB66",
    padding: "30px 50px",
  },
  messageFailed: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    backgroundColor: "#F9F0F0",
    border: "1px solid #E97C7C",
    padding: "30px 50px",
  },
};

const defaultContactInfo = {
  email: "support@busybucket.io",
};

const ConfirmEmail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const authenticationError = useSelector(getErrorSelector);
  const authenticationState = useSelector(getAuthSelector);
  // eslint-disable-next-line no-undef
  const email = process.env.REACT_APP_SUPPORT_EMAIL || defaultContactInfo.email;

  const redirectToSignIn = useCallback(() => {
    navigate(AppRoutes.SignIn);
  }, [navigate]);

  useEffect(() => {
    dispatch(cleanError());
    dispatch(emailConfirmationRequest({ token: id }));
    return () => {
      dispatch(cleanError());
    };
  }, []);

  if (authenticationState.isLoading)
    return (
      <Box
        data-sqa-id="circular-progress-loader"
        height="100vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress />
      </Box>
    );

  return (
    <Container sx={styles.container} maxWidth="sm">
      {authenticationError ? (
        <>
          <Box sx={styles.messageFailed}>
            <Box>
              <FailedIcon />
              <Typography
                component="span"
                color={theme.palette.common.black}
                fontSize={{ xs: "16px", md: "24px" }}
                fontWeight={500}
                ml="15px"
              >
                Email could not be confirmed
              </Typography>
            </Box>
            <Box mt="10px">
              <Typography
                color={theme.palette.secondary.main}
                fontSize={{ xs: "14px", md: "16px" }}
                component="span"
              >
                Please contact support at{" "}
              </Typography>
              <Link
                color="primary"
                fontSize={{ xs: "14px", md: "16px" }}
                href={`mailto:${email}`}
              >
                support@busybucket.io
              </Link>
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box sx={styles.messageSuccess}>
            <SuccessIcon />
            <Typography
              color={theme.palette.common.black}
              fontSize={{ xs: "16px", md: "24px" }}
              fontWeight={500}
              ml="15px"
            >
              Your email is confirmed!
            </Typography>
          </Box>
          <Box width="100%" mt="20px">
            <Button
              variant="contained"
              color="primary"
              fullWidth
              size="small"
              onClick={redirectToSignIn}
            >
              <Typography fontSize={{ xs: "14px", md: "16px" }}>
                Login
              </Typography>
            </Button>
          </Box>
        </>
      )}
    </Container>
  );
};

export default ConfirmEmail;
