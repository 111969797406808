import React from "react";
import { Box, CardMedia, Typography } from "@mui/material";
import { styles } from "src/pages/UserView/Attachments/attachments.module";
import { Comment } from "src/assets/icons";
import { Attachment } from "src/types";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "src/constants";

interface Props {
  attachmentsState: string;
  attachmentDay: Attachment[];
}

const TabAttachmentsData: React.FC<Props> = ({
  attachmentsState,
  attachmentDay,
}) => {
  const navigate = useNavigate();
  const handleOpenAttachment = (id: string) =>
    navigate(`${AppRoutes.Attachment}/${id}`);

  return (
    <>
      <Box sx={styles.attachmentList}>
        {attachmentDay.length !== 0 ? (
          attachmentDay?.map((elem: Attachment) => (
            <Box
              sx={styles.attachmentContainer}
              key={elem.id}
              onClick={() => handleOpenAttachment(elem.id)}
            >
              {elem.isReceipt && (
                <Box sx={styles.whiteBgReceipt}>
                  <Typography sx={styles.caption}>Receipt</Typography>
                </Box>
              )}
              {elem.caption && (
                <Box sx={styles.whiteBgComment}>
                  <Comment />
                </Box>
              )}
              {`${attachmentsState}${elem.s3FilePath}` ? (
                <CardMedia
                  sx={styles.attachment}
                  component="img"
                  image={`${attachmentsState}${elem.s3FilePath}`}
                  alt="attachment"
                />
              ) : (
                <Box sx={styles.attachment} />
              )}
            </Box>
          ))
        ) : (
          <Box sx={styles.noData}>
            <Typography sx={styles.noDataText}>
              No attachments uploaded yet
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
};

export default TabAttachmentsData;
