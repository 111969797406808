import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PlusForWeb } from "src/assets/icons";
import { Button } from "src/components";
import { styles } from "../../pages/AdminView/Projects/projects.module";
import PaginationContainer from "../Pagination";
import { fetchSubProjectRequest } from "src/store/projects/actions";
import { getProjectSelector } from "src/store/projects/selectors";
import AddProject from "src/components/ActionsModal/AddProject";
import { useNavigate, useParams } from "react-router-dom";
import Subprojects from "./Subprojects";
import { isMobile } from "mobile-device-detect";
import SubProjectsMobile from "./Subprojects/SubprojectsMobile";
import { AppRoutes } from "src/constants";

interface Props {
  fetchProjectID: () => void;
}

const SubprojectsWrapper: React.FC<Props> = ({ fetchProjectID }) => {
  const [page, setPage] = useState<number>(1);
  const navigate = useNavigate();
  const pageSize = 10;
  const { id } = useParams();
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortBy, setSortBy] = useState("projectName");
  const [sortByTableName, setSortByTableName] = useState("Subproject Name");
  const [openActionModal, setOpenActionModal] = useState(false);
  const modalActionType = {
    action: "Add",
    page: "Project",
  };
  const dispatch = useDispatch();
  const allProjects = useSelector(getProjectSelector);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const fetchSuccess = () => {
    fetchSubprojects();
    fetchProjectID();
  };

  const fetchSubprojects = () => {
    dispatch(
      fetchSubProjectRequest({
        parentProjectId: id,
        currentPage: isMobile ? 1 : page,
        pageSize: isMobile ? page * pageSize : pageSize,
        sortBy: sortBy,
        sortOrder: sortOrder,
      })
    );
  };

  useEffect(() => {
    fetchSubprojects();
  }, [page, sortBy, sortOrder]);

  const requestSort = (elem: string) => {
    if (elem === "ID") {
      setSortBy("numericId");
      setSortByTableName("ID");
    }
    if (elem === "Subproject Name") {
      setSortBy("projectName");
      setSortByTableName("Subproject Name");
    }
    if (elem === "Project Address") {
      setSortBy("address");
      setSortByTableName("Project Address");
    }
    if (elem === "Subprojects") {
      setSortBy("projectCountV.subProjectCount");
      setSortByTableName("Subprojects");
    }
    if (elem === "Project Comments") {
      setSortBy("projectCountV.commentsCount");
      setSortByTableName("Project Comments");
    }
  };

  return (
    <>
      <Box
        sx={{
          marginBottom: "20px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          style={{
            width: isMobile ? "100%" : "175px",
            height: isMobile ? "40px" : "46px",
          }}
          variant="contained"
          color="primary"
          disabled={allProjects.project?.parentId ? true : false}
          onClick={() => {
            if (isMobile)
              navigate(AppRoutes.AddProject, {
                state: { id },
              });
            else setOpenActionModal(true);
          }}
        >
          <Box sx={styles.addButtonContainer}>
            <PlusForWeb
              color={allProjects.project?.parentId ? "#d9d9d9" : "#fff"}
            />
            <Typography sx={styles.addButtonText}>Add Subproject</Typography>
          </Box>
        </Button>
      </Box>
      {!isMobile ? (
        allProjects.isLoadingSubproject ? (
          <Box
            data-sqa-id="circular-progress-loader"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress />
          </Box>
        ) : allProjects.projects.length ? (
          <>
            <Subprojects
              allProject={allProjects.projects}
              fetchSuccess={fetchSuccess}
              requestSort={requestSort}
              setSortOrder={setSortOrder}
              sortOrder={sortOrder}
              sortBy={sortByTableName}
            />
            {
              // Show pagination only when there is more than 1 page.
            }
            {allProjects.totalPages > 1 ? (
              <Box mt="10px" sx={styles.pagination}>
                <PaginationContainer
                  page={page}
                  handleChangePage={handleChangePage}
                  pages={allProjects.totalPages}
                />
              </Box>
            ) : null}
          </>
        ) : (
          <Box sx={styles.noData}>
            <Typography
              data-sqa-id="no-comments-yet-text"
              sx={styles.noDataText}
            >
              No projects have been created yet.
            </Typography>
          </Box>
        )
      ) : null}

      {isMobile &&
        (allProjects.isLoadingSubproject ? (
          <Box
            data-sqa-id="circular-progress-loader"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress />
          </Box>
        ) : allProjects.projects.length ? (
          <SubProjectsMobile
            pageSize={pageSize}
            setPage={setPage}
            fetchSuccess={fetchSuccess}
          />
        ) : (
          <Box sx={styles.noData}>
            <Typography
              data-sqa-id="no-comments-yet-text"
              sx={styles.noDataText}
            >
              No projects have been created yet.
            </Typography>
          </Box>
        ))}

      {openActionModal && (
        <AddProject
          modalActionType={modalActionType}
          subprojectId={id}
          showToast={false}
          fetchSuccess={fetchSuccess}
          isOpen={openActionModal}
          closeModal={() => setOpenActionModal(false)}
        />
      )}
    </>
  );
};

export default SubprojectsWrapper;
