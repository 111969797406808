import {
  Box,
  Button,
  FormControl,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { addProjectRequest } from "src/store/projects/actions";
import americaStates from "src/AmericaStates.json";
import { getProjectSelector } from "src/store/projects/selectors";
import AppBar from "../../../../components/AppBar";
import { styles } from "./projectActions.module";
import { useLocation, useNavigate } from "react-router-dom";
import { AppRoutes, ZIP_NUMBER_REGEX } from "src/constants";
import { Input, Select } from "src/components";
import { isFieldHasError } from "src/utils";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const validationSchema = yup.object().shape({
  projectName: yup.string().required("Project name is required"),
  zip: yup.string().matches(ZIP_NUMBER_REGEX, "Zip code is not valid"),
});

const AddUser: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const parentId = useLocation().state as { id: number };
  const { error, isLoadingAddProject } = useSelector(getProjectSelector);
  const formik = useFormik({
    initialValues: {
      projectName: "",
      projectDescription: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
    },
    validationSchema: validationSchema,
    onSubmit: ({
      projectName,
      projectDescription,
      address1,
      address2,
      city,
      state,
      zip,
    }) => {
      const projectNameTrim = projectName.trim();
      if (!projectNameTrim) return;
      else {
        dispatch(
          addProjectRequest(
            {
              projectName: projectName.trim(),
              projectDescription: projectDescription
                ? projectDescription.trim()
                : null,
              parentId: parentId.id ? parentId.id.toString() : null,
              address: {
                address: address1 ? address1.trim() : null,
                addressTwo: address2 ? address2.trim() : null,
                city: city ? city.trim() : null,
                state: state ? state : null,
                zip: zip ? zip.toString() : null,
              },
            },
            { onSuccess: () => navigate(AppRoutes.Projects) }
          )
        );
      }
    },
  });

  return (
    <AppBar>
      <>
        <Box sx={styles.boxContainer}>
          <form onSubmit={formik.handleSubmit}>
            <Box>
              <Input
                type="text"
                fullWidth
                required
                size="small"
                label="Project Name"
                id="projectName"
                inputProps={{ maxLength: 200 }}
                value={formik.values.projectName}
                onChange={formik.handleChange}
                error={isFieldHasError({
                  formik,
                  fieldName: "projectName",
                  isGlobalError: Boolean(error),
                })}
                helperText={
                  formik.touched.projectName && formik.errors.projectName
                }
              />
            </Box>
            <Box mt="20px">
              <Typography mb={"8px"} color="#8C8C8C">
                Project Description
              </Typography>
              <TextField
                multiline
                fullWidth
                inputProps={{ maxLength: 5000 }}
                rows={4}
                id="projectDescription"
                value={formik.values.projectDescription}
                onChange={formik.handleChange}
              />
            </Box>
            <Box mt="20px">
              <Input
                type="text"
                fullWidth
                size="small"
                label="Address 1"
                inputProps={{ maxLength: 200 }}
                id="address1"
                value={formik.values.address1}
                onChange={formik.handleChange}
              />
            </Box>
            <Box mt="20px">
              <Input
                type="text"
                fullWidth
                size="small"
                label="Address 2 (option)"
                inputProps={{ maxLength: 100 }}
                id="address2"
                value={formik.values.address2}
                onChange={formik.handleChange}
              />
            </Box>
            <Box mt="20px">
              <Input
                type="text"
                fullWidth
                size="small"
                label="City"
                inputProps={{ maxLength: 100 }}
                id="city"
                value={formik.values.city}
                onChange={formik.handleChange}
              />
            </Box>
            <Box
              mt="20px"
              display="flex"
              justifyContent="space-between"
              columnGap="10px"
            >
              <Box width="50%">
                <Typography mb={"8px"} color="#8C8C8C">
                  State
                </Typography>
                <FormControl fullWidth>
                  <Select
                    value={formik.values.state}
                    height="40px"
                    fontSize="16px"
                    onChange={(opt) =>
                      formik.setFieldValue("state", opt.target.value)
                    }
                    IconComponent={ArrowDropDownIcon}
                  >
                    {americaStates.map((elem: string) => (
                      <MenuItem key={elem} value={elem}>
                        {elem}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box width="50%">
                <Input
                  type="text"
                  fullWidth
                  size="small"
                  label="Zip"
                  id="zip"
                  value={formik.values.zip}
                  onChange={formik.handleChange}
                  error={isFieldHasError({
                    formik,
                    fieldName: "zip",
                    isGlobalError: Boolean(error),
                  })}
                  helperText={formik.touched.zip && formik.errors.zip}
                />
              </Box>
            </Box>
            <Box sx={styles.actionButtons}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                sx={styles.actionButtonSave}
                type="submit"
                disabled={isLoadingAddProject}
              >
                Save
              </Button>
            </Box>
          </form>
        </Box>
      </>
    </AppBar>
  );
};

export default AddUser;
