import React, { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useCallback } from "react";
import { AppRoutes } from "src/constants";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import theme from "src/theme";
import {
  User as UserIcon,
  HelpCircle as HelpCircleIcon,
  Shuffle as ShuffleIcon,
  Logout as LogoutIcon,
} from "src/assets/icons";
import { logout } from "src/store/auth/actions";
import { useDispatch, useSelector } from "react-redux";
import { getUserSelector } from "src/store/auth/selectors";
import { styles } from "./SideBar.module";

const Sidebar = (props: { state; action }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(getUserSelector);
  const errorColor = `${theme.palette.error[500]}`;
  const [roleMode, setRoleMode] = useState<string>(
    localStorage.getItem("roleMode")
  );

  const toggleDrawer = () => {
    props.action(!props.state);
  };

  const redirectTo = useCallback(
    (route: string) => {
      navigate(route);
    },
    [navigate]
  );

  const changeRoleMode = () => {
    if (localStorage.getItem("roleMode") === "admin") {
      localStorage.setItem("roleMode", "user");
      setRoleMode("user");
      window.location.href = "/";
      sessionStorage.setItem("activeTab", "0");
    } else {
      localStorage.setItem("roleMode", "admin");
      setRoleMode("admin");
      window.location.href = "/";
      sessionStorage.setItem("activeTab", "0");
    }
  };

  const list = () => (
    <Box role="presentation" onClick={toggleDrawer} onKeyDown={toggleDrawer}>
      <List>
        <ListItem disablePadding>
          <ListItemButton
            sx={styles.listItem}
            onClick={() => redirectTo(AppRoutes.Profile)}
          >
            <ListItemIcon sx={styles.icon}>
              <UserIcon color={theme.palette.secondary.main} />
            </ListItemIcon>
            <ListItemText
              primary={"My Profile"}
              primaryTypographyProps={{
                fontSize: 14,
              }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            sx={styles.listItem}
            onClick={() => redirectTo(AppRoutes.Support)}
          >
            <ListItemIcon sx={styles.icon}>
              <HelpCircleIcon color={theme.palette.secondary.main} />
            </ListItemIcon>
            <ListItemText
              primary={"Support"}
              primaryTypographyProps={{
                fontSize: 14,
              }}
            />
          </ListItemButton>
        </ListItem>
        {user.isAdmin && (
          <ListItem disablePadding>
            <ListItemButton sx={styles.listItem} onClick={changeRoleMode}>
              <ListItemIcon sx={styles.icon}>
                <ShuffleIcon size={24} color={theme.palette.secondary.main} />
              </ListItemIcon>
              <ListItemText
                primary={`Switch to ${
                  roleMode === "admin" ? "User" : "Admin"
                } Mode`}
                primaryTypographyProps={{
                  fontSize: 14,
                }}
              />
            </ListItemButton>
          </ListItem>
        )}
        <ListItem disablePadding>
          <ListItemButton
            sx={styles.listItem}
            onClick={() => {
              dispatch(logout());
              navigate(AppRoutes.SignIn);
            }}
          >
            <ListItemIcon sx={styles.icon}>
              <LogoutIcon color={errorColor} />
            </ListItemIcon>
            <ListItemText
              primary={"Log out"}
              primaryTypographyProps={{
                fontSize: 14,
                color: errorColor,
              }}
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <React.Fragment>
      <Drawer
        anchor="left"
        open={props.state}
        onClose={toggleDrawer}
        sx={styles.sidebar}
      >
        <Typography fontSize={22} fontWeight={500} margin={"34px 0 6px 0"}>
          Hello, {user?.firstName}!
        </Typography>
        {list()}
      </Drawer>
    </React.Fragment>
  );
};

export default Sidebar;
