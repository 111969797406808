import * as React from "react";
import { SVGProps } from "react";

const Search = (props: SVGProps<SVGSVGElement>) => (
  <svg data-sqa-id="search-icon" width={14} height={14} fill="none" {...props}>
    <path
      d="M13.463 12.602 9.405 8.544c.63-.814.97-1.81.97-2.857a4.66 4.66 0 0 0-1.373-3.314A4.653 4.653 0 0 0 5.687 1a4.66 4.66 0 0 0-3.314 1.373A4.65 4.65 0 0 0 1 5.687c0 1.252.49 2.43 1.373 3.315a4.65 4.65 0 0 0 3.314 1.373 4.65 4.65 0 0 0 2.855-.969l4.058 4.056a.129.129 0 0 0 .181 0l.681-.68a.129.129 0 0 0 0-.18Zm-5.3-4.44a3.481 3.481 0 0 1-2.476 1.025 3.481 3.481 0 0 1-2.475-1.024 3.481 3.481 0 0 1-1.025-2.476c0-.934.365-1.814 1.026-2.475a3.481 3.481 0 0 1 2.474-1.025c.935 0 1.815.363 2.475 1.026a3.481 3.481 0 0 1 1.025 2.474c0 .935-.364 1.815-1.024 2.475Z"
      fill="#8C8C8C"
    />
  </svg>
);

export default Search;
