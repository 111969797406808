import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { AppRoutes } from "src/constants";
import { PrivateRoute } from "src/components";
import {
  Dashboard,
  Profile,
  Attachments,
  AddAttachment,
  Comments,
  History,
  HistoryDayPage,
  AttachmentItem,
  SupportMobile,
} from "src/pages/UserView";
import {
  SignIn,
  SignUp,
  ForgotPassword,
  CheckEmail,
  PrivacyPolicy,
  TermsConditions,
  Welcome,
  ResetPassword,
  Verification,
  ConfirmEmail,
} from "src/pages/common";
import {
  ProjectActions,
  ProjectItem,
  Projects,
  Report,
  ReportItem,
  ReportFilters,
  Settings,
  ChoosePlanMobile,
  Team,
  UserActions,
  PaidPlanPurchase,
} from "./pages/AdminView";
import { useSelector } from "react-redux";
import { getUserSelector } from "./store/auth/selectors";
import { ToastContainer } from "react-toastify";

function RoutingMobile() {
  const user = useSelector(getUserSelector);
  const [roleMode, setRoleMode] = useState<string>(
    localStorage.getItem("roleMode")
  );

  useEffect(() => {
    if (user && user.isAdmin && localStorage.getItem("roleMode") !== null) {
      localStorage.getItem("roleMode");
      setRoleMode(localStorage.getItem("roleMode"));
    } else {
      localStorage.setItem("roleMode", "admin");
      setRoleMode("admin");
    }
  }, [roleMode]);

  return (
    <BrowserRouter>
      <Routes>
        {!user && <Route path={AppRoutes.SignIn} element={<SignIn />} />}
        <Route path={AppRoutes.SignUp} element={<SignUp />} />
        <Route
          path={`${AppRoutes.EmailConfirm}/:id`}
          element={<ConfirmEmail />}
        />
        <Route path={AppRoutes.ForgotPassword} element={<ForgotPassword />} />
        <Route path={AppRoutes.CheckEmail} element={<CheckEmail />} />
        <Route
          path={`${AppRoutes.ResetPassword}/:id`}
          element={<ResetPassword />}
        />
        <Route path={AppRoutes.Verification} element={<Verification />} />
        <Route path={AppRoutes.Welcome} element={<Welcome />} />
        <Route path={AppRoutes.Profile} element={<Profile />} />
        <Route path={AppRoutes.Support} element={<SupportMobile />} />
        <Route path={AppRoutes.PrivacyPolicy} element={<PrivacyPolicy />} />
        <Route path={AppRoutes.TermsConditions} element={<TermsConditions />} />
        {user && user?.isAdmin && roleMode === "admin" ? (
          <Route
            path={AppRoutes.Report}
            element={
              <PrivateRoute>
                <Report />
              </PrivateRoute>
            }
          />
        ) : (
          <Route
            path={AppRoutes.Dashboard}
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
        )}
        {((user && user?.isAdmin && roleMode === "user") ||
          (user && !user?.isAdmin)) && (
          <Route
            path={AppRoutes.Attachments}
            element={
              <PrivateRoute>
                <Attachments />
              </PrivateRoute>
            }
          />
        )}
        <Route
          path={`${AppRoutes.Attachment}/:id`}
          element={
            <PrivateRoute>
              <AttachmentItem />
            </PrivateRoute>
          }
        />
        {((user && user?.isAdmin && roleMode === "user") ||
          (user && !user?.isAdmin)) && (
          <Route
            path={AppRoutes.AddAttachments}
            element={
              <PrivateRoute>
                <AddAttachment />
              </PrivateRoute>
            }
          />
        )}
        {((user && user?.isAdmin && roleMode === "user") ||
          (user && !user?.isAdmin)) && (
          <Route
            path={AppRoutes.Comments}
            element={
              <PrivateRoute>
                <Comments />
              </PrivateRoute>
            }
          />
        )}

        {((user && user?.isAdmin && roleMode === "user") ||
          (user && !user?.isAdmin)) && (
          <Route
            path={AppRoutes.History}
            element={
              <PrivateRoute>
                <History />
              </PrivateRoute>
            }
          />
        )}

        {((user && user?.isAdmin && roleMode === "user") ||
          (user && !user?.isAdmin)) && (
          <Route
            path={`${AppRoutes.HistoryDay}/:id`}
            element={
              <PrivateRoute>
                <HistoryDayPage />
              </PrivateRoute>
            }
          />
        )}

        {user && user?.isAdmin && roleMode === "admin" && (
          <Route
            path={AppRoutes.AddUser}
            element={
              <PrivateRoute>
                <UserActions />
              </PrivateRoute>
            }
          />
        )}

        {user && user?.isAdmin && roleMode === "admin" && (
          <Route
            path={AppRoutes.EditUser}
            element={
              <PrivateRoute>
                <UserActions />
              </PrivateRoute>
            }
          />
        )}

        {user && user?.isAdmin && roleMode === "admin" && (
          <Route
            path={AppRoutes.Projects}
            element={
              <PrivateRoute>
                <Projects />
              </PrivateRoute>
            }
          />
        )}

        {user && user?.isAdmin && roleMode === "admin" && (
          <Route
            path={AppRoutes.AddProject}
            element={
              <PrivateRoute>
                <ProjectActions />
              </PrivateRoute>
            }
          />
        )}

        {user && user?.isAdmin && roleMode === "admin" && (
          <Route
            path={`${AppRoutes.ProjectItemMobile}/:id`}
            element={
              <PrivateRoute>
                <ProjectItem />
              </PrivateRoute>
            }
          />
        )}
        {user && user?.isAdmin && roleMode === "admin" && (
          <Route
            path={AppRoutes.Settings}
            element={
              <PrivateRoute>
                <Settings />
              </PrivateRoute>
            }
          />
        )}
        {user && user?.isAdmin && roleMode === "admin" && (
          <Route
            path={AppRoutes.Team}
            element={
              <PrivateRoute>
                <Team />
              </PrivateRoute>
            }
          />
        )}
        {user && user?.isAdmin && roleMode === "admin" && (
          <Route
            path={AppRoutes.ReportFilters}
            element={
              <PrivateRoute>
                <ReportFilters />
              </PrivateRoute>
            }
          />
        )}
        {user && user?.isAdmin && roleMode === "admin" && (
          <Route
            path={`${AppRoutes.Report}/:id`}
            element={
              <PrivateRoute>
                <ReportItem />
              </PrivateRoute>
            }
          />
        )}
        {user && user?.isAdmin && (
          <Route
            path={AppRoutes.SettingsPlan}
            element={
              <PrivateRoute>
                <ChoosePlanMobile />
              </PrivateRoute>
            }
          />
        )}
        {user && user?.isAdmin && (
          <Route
            path={AppRoutes.PaidPlanPurchase}
            element={
              <PrivateRoute>
                <PaidPlanPurchase />
              </PrivateRoute>
            }
          />
        )}
        <Route
          path="*"
          element={
            <Navigate
              to={user?.isAdmin && roleMode === "admin" ? "/report" : "/"}
            />
          }
        />
      </Routes>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        theme="light"
        toastStyle={{
          marginTop: "15px",
        }}
      />
    </BrowserRouter>
  );
}

export default RoutingMobile;
