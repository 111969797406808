import {
  Box,
  CircularProgress,
  Typography,
  Button as MuiButton,
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PullToRefresh from "react-simple-pull-to-refresh";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { Comments, PlusForWeb, ProjectIcon, ThreeDots } from "src/assets/icons";
import { Button } from "src/components";
import { styles } from "./projects.module";
import { getProjectSelector } from "src/store/projects/selectors";
import { Project } from "src/types";
import { AppRoutes } from "src/constants";
import Actions from "src/components/ActionsModal/Actions";
import SortByModal from "src/components/ActionsModal/SortByModal";
import sortByProjects from "./sortByProjects.json";
import SearchField from "src/components/SearchField";

interface Props {
  isLoading: boolean;
  searchProjectValue: string;
  pageSize: number;
  sortOrder: string;
  sortBy: string;
  sortBySearch: string;
  setIsLoading: any;
  setPage: (page: any) => void;
  setSearchProjectValue: (word: string) => void;
  setSortOrder: (order: string) => void;
  setSortBy: (elem: string) => void;
  fetchProjects: () => void;
  setSortBySearch: (text: string) => void;
}

const ProjectsMobile: React.FC<Props> = ({
  isLoading,
  searchProjectValue,
  pageSize,
  sortOrder,
  sortBy,
  sortBySearch,
  setIsLoading,
  setPage,
  setSearchProjectValue,
  fetchProjects,
  setSortOrder,
  setSortBy,
  setSortBySearch,
}) => {
  const allProjects = useSelector(getProjectSelector);
  const navigate = useNavigate();
  const [openActionModal, setOpenActionModal] = useState<boolean>(false);
  const [openSortModal, setOpenSortModal] = useState<boolean>(false);
  const [elem, setElem] = useState<Project>(null);
  const [modalActionType, setModalActionType] = useState({
    action: "",
    page: "",
  });

  const handleSortBy = (action: string, page: string) => {
    setOpenSortModal(true);
    setModalActionType((prevState) => ({
      ...prevState,
      action,
      page,
    }));
  };

  const openModal = (elem: Project, action: string) => {
    setOpenActionModal(true);
    setElem(elem);
    setModalActionType((prevState) => ({
      ...prevState,
      action,
      page: "",
    }));
  };

  const handleRefresh = async () => {
    fetchProjects();
  };

  return (
    <>
      <PullToRefresh onRefresh={handleRefresh}>
        <Box sx={styles.boxContainer}>
          <Button
            style={{ height: "40px", marginTop: "14px" }}
            fullWidth
            variant="contained"
            color="primary"
            onClick={() =>
              navigate(AppRoutes.AddProject, {
                state: { id: null },
              })
            }
          >
            <Box sx={styles.addButtonContainer}>
              <PlusForWeb />
              <Typography sx={styles.addButtonText}>Add Project</Typography>
            </Box>
          </Button>
          <Box sx={[styles.topContainer, { my: "20px", columnGap: "15px" }]}>
            <Box width="100%">
              <SearchField
                id="searchProjectValue"
                searchValue={searchProjectValue}
                setSearchValue={setSearchProjectValue}
                setPage={setPage}
              />
            </Box>
            <Box
              sx={[styles.boxContainer, { p: "7px" }]}
              onClick={() => handleSortBy("Sort", "By")}
            >
              <SwapVertIcon />
            </Box>
          </Box>
          {isLoading ? (
            <Box
              data-sqa-id="circular-progress-loader"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              {allProjects.projects.length ? (
                <>
                  {allProjects.projects.map((project: Project) => {
                    if (project.parentId) return;
                    else
                      return (
                        <Box
                          mb="20px"
                          sx={styles.boxContainer}
                          key={project.id}
                          onClick={() => {
                            navigate(
                              AppRoutes.ProjectItemMobile + `/${project.id}`
                            );
                            sessionStorage.setItem("activeTab", "0");
                          }}
                        >
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            columnGap="10px"
                          >
                            <Box>
                              <Typography
                                mb="5px"
                                fontSize="12px"
                                color="#8C8C8C"
                              >
                                Project ID {project.numericId}
                              </Typography>
                              <Typography
                                sx={{ breakWord: "break-word" }}
                                color="#262626"
                              >
                                {project.projectName}
                              </Typography>
                              <Box
                                mt="10px"
                                display="flex"
                                alignItems="center"
                                columnGap="10px"
                              >
                                <Box
                                  sx={
                                    project.projectCountV.subProjectCount === 0
                                      ? styles.emptyContainer
                                      : styles.countContainer
                                  }
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    navigate(
                                      AppRoutes.ProjectItemMobile +
                                        `/${project.id}`
                                    );
                                    sessionStorage.setItem("activeTab", "1");
                                  }}
                                >
                                  <ProjectIcon
                                    size="16px"
                                    color={
                                      project.projectCountV.subProjectCount ===
                                      0
                                        ? "#8C8C8C"
                                        : "#0D6FF0"
                                    }
                                  />
                                  <Typography
                                    ml="6px"
                                    fontSize="12px"
                                    color={
                                      project.projectCountV.subProjectCount ===
                                      0
                                        ? "#8C8C8C"
                                        : "#0D6FF0"
                                    }
                                  >
                                    {project.projectCountV.subProjectCount}
                                  </Typography>
                                </Box>
                                <Box
                                  sx={
                                    project.projectCountV.commentsCount === 0
                                      ? styles.emptyContainer
                                      : styles.countContainer
                                  }
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    navigate(
                                      AppRoutes.ProjectItemMobile +
                                        `/${project.id}`
                                    );
                                    sessionStorage.setItem("activeTab", "2");
                                  }}
                                >
                                  <Comments
                                    size={16}
                                    color={
                                      project.projectCountV.commentsCount === 0
                                        ? "#8C8C8C"
                                        : "#0D6FF0"
                                    }
                                  />
                                  <Typography
                                    ml="6px"
                                    fontSize="12px"
                                    color={
                                      project.projectCountV.commentsCount === 0
                                        ? "#8C8C8C"
                                        : "#0D6FF0"
                                    }
                                  >
                                    {project.projectCountV.commentsCount}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                            <MuiButton
                              sx={styles.threeDots}
                              onClick={(e) => {
                                e.stopPropagation();
                                openModal(project, "Actions");
                              }}
                            >
                              <ThreeDots color="#000" />
                            </MuiButton>
                          </Box>
                          {project.address && (
                            <Box display="flex" alignItems="center" mt="20px">
                              <Box mr="10px">
                                <LocationOnOutlinedIcon color="primary" />
                              </Box>
                              <Typography fontSize="14px" color="#262626">
                                {`${
                                  project.address.address
                                    ? project.address.address
                                    : ""
                                }${
                                  project.address.address &&
                                  (project.address.state || project.address.zip)
                                    ? ","
                                    : ""
                                } ${
                                  project.address.state
                                    ? project.address.state
                                    : ""
                                } ${
                                  project.address.zip ? project.address.zip : ""
                                }`}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      );
                  })}
                  {allProjects.isLoading ? (
                    <Box
                      data-sqa-id="circular-progress-loader"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      mb="10px"
                    >
                      <CircularProgress />
                    </Box>
                  ) : (
                    pageSize < allProjects.totalPages * pageSize && (
                      <Button
                        data-sqa-id="load-more-btn"
                        variant="contained"
                        fullWidth
                        style={{
                          border: "1px solid #0D6FF0",
                          backgroundColor: "transparent",
                          boxShadow: "none",
                        }}
                        onClick={() => setPage((prevState) => prevState + 1)}
                      >
                        <Box sx={styles.addButtonContainer}>
                          <Typography
                            data-sqa-id="load-more-btn-text"
                            color="#0D6FF0"
                          >
                            Load More
                          </Typography>
                        </Box>
                      </Button>
                    )
                  )}
                </>
              ) : (
                <Box sx={styles.noData}>
                  <Typography
                    data-sqa-id="no-comments-yet-text"
                    sx={styles.noDataText}
                  >
                    {!searchProjectValue.length ? (
                      "No projects created yet"
                    ) : (
                      <>
                        No results found for your search.
                        <br />
                        Please try again with different keywords.
                      </>
                    )}
                  </Typography>
                </Box>
              )}
            </>
          )}
        </Box>
      </PullToRefresh>

      {openSortModal && (
        <SortByModal
          setIsLoading={setIsLoading}
          sortTable={sortByProjects}
          setSortBy={setSortBy}
          sortBy={sortBy}
          setSortOrder={setSortOrder}
          sortOrder={sortOrder}
          setSortBySearch={setSortBySearch}
          sortBySearch={sortBySearch}
          modalActionType={modalActionType}
          isOpen={openSortModal}
          closeModal={() => setOpenSortModal(false)}
        />
      )}

      {openActionModal && (
        <Actions
          modalActionType={modalActionType}
          elem={elem}
          type="View"
          isOpen={openActionModal}
          closeModal={() => setOpenActionModal(false)}
          fetchSuccess={fetchProjects}
          setModalActionType={setModalActionType}
        />
      )}
    </>
  );
};

export default ProjectsMobile;
