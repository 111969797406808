import { Avatar, Box, IconButton, Theme, Typography } from "@mui/material";
// import axios from "axios";
import { isMobile } from "mobile-device-detect";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Edit as EditIcon } from "src/assets/icons";
// import { getAttachmentsSelector } from "src/store/attachments/selectors";
import {
  currentUserDataRequest,
  deleteUserProfilePictureRequest,
  getOrganizationRequest,
  // updateUserDataRequest,
} from "src/store/auth/actions";
import {
  getOrganizationSelector,
  getUserSelector,
} from "src/store/auth/selectors";
import theme from "src/theme";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import AvatarModal from "./ActionsModal/AvatarModal";
import { cleanF3images } from "src/store/attachments/actions";

const styles = {
  cardUserContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: isMobile ? "24px" : "20px",
  },
  userPhoto: {
    width: isMobile ? "70px" : "80px",
    height: isMobile ? "70px" : "80px",
    fontSize: "24px",
    objectFit: "contain",
  },
  edit: {
    position: "absolute",
    bottom: "0",
    right: "0",
    width: "24px",
    height: "24px",
    border: (theme: Theme) => `1px solid ${theme.palette.background.default}`,
    padding: "5px",
    background: (theme: Theme) => `${theme.palette.background.paper}`,
    "&:hover": {
      background: (theme: Theme) => `${theme.palette.background.paper}`,
    },
  },
  icon: {
    width: "12px",
    height: "12px",
  },
  form: {
    width: "100%",
  },
  "#lastName": {
    margin: "5px",
  },
  card: {
    p: "20px",
    borderRadius: "4px",
    border: (theme: Theme) => `1px solid ${theme.palette.background.default}`,
  },
  cardUser: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    width: "80px",
    height: "80px",
  },
  delete: {
    position: "absolute",
    top: "0",
    right: "0",
    border: (theme: Theme) => `1px solid ${theme.palette.background.default}`,
    p: "4px",
    background: (theme: Theme) => `${theme.palette.background.paper}`,
    "&:hover": {
      background: (theme: Theme) => `${theme.palette.background.paper}`,
    },
  },
  upload: {
    marginLeft: "30px",
    padding: "3px 40px",
    color: "#000",
  },
  btn: {
    display: "flex",
    marginLeft: "auto",
    padding: "3px 40px",
  },
};

const CardUserProfile = () => {
  const user = useSelector(getUserSelector);
  const organization = useSelector(getOrganizationSelector);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [picture, setPicture] = useState({
    cropperOpen: false,
    img: user?.profilePictureUrl,
    zoom: 1,
    croppedImg: user?.profilePictureUrl,
  });

  const modalActionType = {
    action: "Upload",
    page: "Profile Photo",
  };

  function stringAvatar(name) {
    return {
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }

  useEffect(() => {
    dispatch(getOrganizationRequest());
  }, []);

  const handlerAvatarEditor = () => {
    setIsOpen(true);
  };

  const deleteUserAvatar = () => {
    dispatch(
      deleteUserProfilePictureRequest({
        onSuccess,
      })
    );
  };

  const onSuccess = () => {
    dispatch(currentUserDataRequest());
    setPicture({
      ...picture,
      img: "",
      croppedImg: "",
    });
    dispatch(cleanF3images());
  };

  useEffect(() => {
    dispatch(currentUserDataRequest());
  }, []);

  return (
    <Box sx={styles.cardUserContainer}>
      <Box sx={{ position: "relative" }}>
        <Avatar
          {...stringAvatar(`${user.firstName} ${user.lastName}`)}
          sx={styles.userPhoto}
          src={user?.profilePictureUrl}
        />
        {user?.profilePictureUrl && (
          <IconButton sx={styles.delete} onClick={deleteUserAvatar}>
            <CloseRoundedIcon sx={{ fontSize: "12px" }} />
          </IconButton>
        )}
        <IconButton sx={styles.edit} onClick={handlerAvatarEditor}>
          <EditIcon style={styles.icon} />
        </IconButton>
      </Box>
      <Box ml={"20px"}>
        <Typography
          variant="h5"
          fontSize={isMobile ? "22px" : "18px"}
          fontWeight={isMobile ? 500 : 400}
          color="#262626"
        >
          {`${user?.firstName} ${user.lastName}`}
        </Typography>
        <Typography
          variant="body2"
          marginTop="5px"
          fontSize={isMobile ? "14px" : "16px"}
          color={theme.palette.text.secondary}
        >
          {organization?.companyName}
        </Typography>
      </Box>
      {isOpen && (
        <AvatarModal
          isOpen={isOpen}
          closeModal={() => setIsOpen(false)}
          modalActionType={modalActionType}
          // picture={picture}
          // photo={photo}
          // setPicture={setPicture}
          // setPhoto={setPhoto}
        />
      )}
    </Box>
  );
};

export default CardUserProfile;
