import {
  CHECK_START_WATCH_FAILURE,
  CHECK_START_WATCH_SUCCESS,
  START_WATCH_FAILURE,
  START_WATCH_SUCCESS,
  STOP_WATCH_FAILURE,
  STOP_WATCH_SUCCESS,
} from "./actionTypes";
import { WatchActions, WatchState } from "./types";

const initialState: WatchState = {
  isLoading: false,
  timeWatch: { id: null, startTime: null, endTime: null },
  error: null,
};

export default (state = initialState, action: WatchActions) => {
  switch (action.type) {
    case START_WATCH_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case STOP_WATCH_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case CHECK_START_WATCH_SUCCESS: {
      return {
        ...state,
        timeWatch: action.payload.timeClock.timeClock,
      };
    }
    case CHECK_START_WATCH_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
      };
    }
    case STOP_WATCH_FAILURE:
    case START_WATCH_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
      };
    }
    default:
      return state;
  }
};
