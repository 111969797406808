import React from "react";

interface Props {
  width?: number | string;
  height?: number | string;
}

const CheckBox: React.FC<Props> = ({ width = 24, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="4" fill="#0D6FF0" />
      <path
        d="M6 11.8519L10.0976 16L18 8"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CheckBox;
