import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PlusForWeb } from "src/assets/icons";
import { Button } from "src/components";
import { getUserSelector } from "src/store/users/selectors";
import { styles } from "./team.module";
import TeamTable from "./TeamTable";
import PaginationContainer from "../../../components/Pagination";
import { getOrganizationRequest } from "src/store/auth/actions";
import { getOrganizationSelector } from "src/store/auth/selectors";
import { AppRoutes } from "src/constants";
import { useNavigate } from "react-router-dom";
import { parseTime } from "src/utils";
import SearchField from "src/components/SearchField";

interface Props {
  searchTeamValue: string;
  page: number;
  sortOrder: any;
  sortByTableName: string;
  isLoading: boolean;
  setPage: any;
  setSearchTeamValue: (word: string) => void;
  setOpenActionModal: (bol: boolean) => void;
  handleChangePage: (elem: unknown, pageNum: number) => void;
  fetchUsers: () => void;
  requestSort: (elem: string) => void;
  setSortOrder: (order: string) => void;
}

const TeamWeb: React.FC<Props> = ({
  searchTeamValue,
  page,
  sortOrder,
  sortByTableName,
  isLoading,
  setPage,
  setSearchTeamValue,
  setOpenActionModal,
  handleChangePage,
  fetchUsers,
  requestSort,
  setSortOrder,
}) => {
  const allUsers = useSelector(getUserSelector);
  const organization = useSelector(getOrganizationSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getOrganizationRequest());
  }, []);

  return (
    <>
      <Box sx={styles.topContainer}>
        <Typography data-sqa-id="page-title" sx={styles.pageTitle}>
          Team
        </Typography>
        <Box sx={styles.moreContainer}>
          <Typography
            data-sqa-id="user-licenses-used-text"
            sx={styles.moreText}
          >
            {allUsers.totalUsers} of{" "}
            {organization?.subscriptionUsersNumber ||
              (organization?.trial && 10)}{" "}
            user licenses used.
          </Typography>
          {((organization?.trial && allUsers.totalUsers >= 8) ||
            (!organization?.trial &&
              allUsers.totalUsers >
                organization?.subscriptionUsersNumber - 3)) && (
            <Button
              data-sqa-id="add-more-link"
              size="small"
              style={styles.moreButton}
              onClick={() => {
                navigate(AppRoutes.Settings, {
                  state: { id: "2" },
                });
              }}
            >
              Add More
            </Button>
          )}
        </Box>
      </Box>

      <Box sx={styles.teamContainer}>
        <Box sx={[styles.topContainer, { marginBottom: "20px" }]}>
          <Box>
            <SearchField
              id="searchTeamValue"
              searchValue={searchTeamValue}
              setSearchValue={setSearchTeamValue}
              setPage={setPage}
            />
          </Box>
          <Button
            data-sqa-id="add-user-btn"
            style={{ width: "175px", height: "46px", pointerEvents: "auto" }}
            variant="contained"
            color="primary"
            title={
              (organization?.trial && allUsers.totalUsers === 10) ||
              (!organization?.trial &&
                organization?.subscriptionUsersNumber <= allUsers.totalUsers)
                ? "You have used all of the user licenses. Please update your subscription to get more user licenses if needed."
                : ""
            }
            disabled={
              (organization?.trial &&
                new Date().getTime() >
                  parseTime(organization?.trialExpires).getTime()) ||
              (organization?.trial && allUsers.totalUsers === 10) ||
              (!organization?.trial &&
                organization?.subscriptionUsersNumber <= allUsers.totalUsers)
            }
            onClick={() => setOpenActionModal(true)}
          >
            <Box sx={styles.addButtonContainer}>
              <PlusForWeb
                color={
                  (organization?.trial &&
                    new Date().getTime() >
                      parseTime(organization?.trialExpires).getTime()) ||
                  (organization?.trial && allUsers.totalUsers === 10) ||
                  (!organization?.trial &&
                    organization?.subscriptionUsersNumber <=
                      allUsers.totalUsers)
                    ? "#d9d9d9"
                    : "#fff"
                }
              />
              <Typography data-sqa-id="add-user-btn" sx={styles.addButtonText}>
                Add User
              </Typography>
            </Box>
          </Button>
        </Box>
        {allUsers.isLoading || isLoading ? (
          <Box
            data-sqa-id="circular-progress-loader"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress />
          </Box>
        ) : allUsers.users.length ? (
          <>
            <TeamTable
              allUsers={allUsers.users}
              fetchUsers={fetchUsers}
              requestSort={requestSort}
              setSortOrder={setSortOrder}
              sortOrder={sortOrder}
              sortBy={sortByTableName}
            />
            {
              // Show pagination only when there is more than 1 page.
            }
            {allUsers.totalPages > 1 ? (
              <Box mt="20px" sx={styles.pagination}>
                <PaginationContainer
                  page={page}
                  handleChangePage={handleChangePage}
                  pages={allUsers.totalPages}
                />
              </Box>
            ) : null}
          </>
        ) : (
          <Box sx={styles.noData}>
            <Typography
              data-sqa-id="no-comments-yet-text"
              sx={styles.noDataText}
            >
              {!searchTeamValue.length
                ? "No users have been created yet."
                : "No results found for your search. Please try again with different keywords."}
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
};

export default TeamWeb;
