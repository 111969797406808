import React, { useCallback } from "react";
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import {
  Home as HomeIcon,
  Attachments as AttachmentsIcon,
  Comments as CommentsIcon,
  Calendar as CalendarIcon,
  ProjectIcon,
  ReportIcon,
  SettingsIcon,
  TeamIcon,
} from "../assets/icons";
import BottomTabBarIcon from "./BottomTabBarIcon";
import { AppRoutes } from "src/constants";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getUserSelector } from "src/store/auth/selectors";
import theme from "src/theme";

interface Props {
  page: string;
}

const styles = {
  tabItem: {
    fontSize: "12px",
    justifyContent: "flex-start",
    padding: "10px",
    fontWeight: 600,
    "& .MuiBottomNavigationAction-label": {
      "&.Mui-selected": {
        fontSize: "12px",
      },
    },
  },
  bottomNavigation: {
    height: "92px",
    padding: "8px 20px",
    alignItems: "flex-start",
    boxSizing: "border-box",
  },
  bottomTabBar: {
    boxShadow: "none",
    borderTop: `1px solid ${theme.palette.background.default}`,
  },
};

const BottomTabBar: React.FC<Props> = ({ page }) => {
  const navigate = useNavigate();
  const [value, setValue] = React.useState<string>(page);
  const user = useSelector(getUserSelector);

  const onChangeNavigation = (e: React.SyntheticEvent, newValue: string) =>
    setValue(newValue);

  const redirectTo = useCallback(
    (route: string) => {
      navigate(route);
    },
    [navigate]
  );

  return (
    <Paper sx={styles.bottomTabBar}>
      {(!user.isAdmin ||
        (user.isAdmin && localStorage.getItem("roleMode") === "user")) && (
        <BottomNavigation
          showLabels
          value={value}
          onChange={onChangeNavigation}
          sx={styles.bottomNavigation}
        >
          <BottomNavigationAction
            value=""
            sx={styles.tabItem}
            label="Today"
            onClick={() => redirectTo(AppRoutes.Dashboard)}
            icon={<BottomTabBarIcon icon={<HomeIcon />} />}
          />
          <BottomNavigationAction
            value="attachments"
            sx={styles.tabItem}
            label="Attachments"
            onClick={() => redirectTo(AppRoutes.Attachments)}
            icon={<BottomTabBarIcon icon={<AttachmentsIcon />} />}
          />
          <BottomNavigationAction
            value="comments"
            sx={styles.tabItem}
            label="Comments"
            onClick={() => redirectTo(AppRoutes.Comments)}
            icon={<BottomTabBarIcon icon={<CommentsIcon size={24} />} />}
          />
          <BottomNavigationAction
            value="history"
            sx={styles.tabItem}
            label="History"
            onClick={() => redirectTo(AppRoutes.History)}
            icon={<BottomTabBarIcon icon={<CalendarIcon />} />}
          />
        </BottomNavigation>
      )}
      {user.isAdmin && localStorage.getItem("roleMode") === "admin" && (
        <BottomNavigation
          showLabels
          value={value}
          onChange={onChangeNavigation}
          sx={styles.bottomNavigation}
        >
          <BottomNavigationAction
            value="report"
            sx={styles.tabItem}
            label="Report"
            onClick={() => redirectTo(AppRoutes.Report)}
            icon={<BottomTabBarIcon icon={<ReportIcon size="22px" />} />}
          />
          <BottomNavigationAction
            value="team"
            sx={styles.tabItem}
            label="Team"
            onClick={() => redirectTo(AppRoutes.Team)}
            icon={<BottomTabBarIcon icon={<TeamIcon size="22px" />} />}
          />
          <BottomNavigationAction
            value="projects"
            sx={styles.tabItem}
            label="Projects"
            onClick={() => redirectTo(AppRoutes.Projects)}
            icon={<BottomTabBarIcon icon={<ProjectIcon size="22px" />} />}
          />
          <BottomNavigationAction
            value="settings"
            sx={styles.tabItem}
            label="Settings"
            onClick={() => {
              sessionStorage.setItem("activeTab", "0");
              redirectTo(AppRoutes.Settings);
            }}
            icon={<BottomTabBarIcon icon={<SettingsIcon size="22px" />} />}
          />
        </BottomNavigation>
      )}
    </Paper>
  );
};

export default BottomTabBar;
