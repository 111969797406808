import {
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
  CREATE_USER_FAILURE,
  DELETE_USER_FAILURE,
  SEARCH_USER_REQUEST,
  SEARCH_USER_SUCCESS,
  SEARCH_USER_FAILURE,
  UPDATE_USER_FAILURE,
  GET_USER_REQUEST,
  GET_USER_FAILURE,
  GET_USER_SUCCESS,
  CREATE_USER_SUCCESS,
  UPDATE_USER_SUCCESS,
  DELETE_USER_SUCCESS,
  CLEAN_ERROR,
  CREATE_USER_REQUEST,
  UPDATE_USER_REQUEST,
} from "./actionTypes";
import { UsersAction, UsersState } from "./types";

const initialState: UsersState = {
  users: [],
  user: null,
  totalPages: 0,
  totalUsers: 0,
  isLoading: false,
  error: null,
};

export default (state = initialState, action: UsersAction) => {
  switch (action.type) {
    case FETCH_USERS_REQUEST:
    case SEARCH_USER_REQUEST:
    case CREATE_USER_REQUEST:
    case UPDATE_USER_REQUEST:
    case GET_USER_REQUEST: {
      return {
        ...state,
        isLoading: true,
        ...action.payload,
      };
    }
    case FETCH_USERS_SUCCESS:
    case SEARCH_USER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        ...action.payload.users,
        error: null,
      };
    }
    case GET_USER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        user: action.payload.user,
        error: null,
      };
    }
    case CREATE_USER_SUCCESS:
    case UPDATE_USER_SUCCESS:
    case DELETE_USER_SUCCESS: {
      return {
        ...state,
        error: null,
      };
    }
    case FETCH_USERS_FAILURE:
    case SEARCH_USER_FAILURE:
    case GET_USER_FAILURE:
    case CREATE_USER_FAILURE:
    case UPDATE_USER_FAILURE:
    case DELETE_USER_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    case CLEAN_ERROR: {
      return {
        ...state,
        error: null,
      };
    }
    default:
      return state;
  }
};
