import React, { useState } from "react";
import {
  Box,
  Card,
  IconButton,
  Link,
  Snackbar,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Copy } from "src/assets/icons";
import theme from "src/theme";
import CloseIcon from "@mui/icons-material/Close";
import { isMobile } from "mobile-device-detect";
import { Button } from "src/components";
import { useDispatch, useSelector } from "react-redux";
import { getAuthSelector } from "src/store/auth/selectors";
import { contactUsRequest } from "src/store/subscription/actions";
import { toast } from "react-toastify";

const styles = {
  card: {
    backgroundColor: (theme: Theme) => `${theme.palette.background.paper}`,
    border: (theme: Theme) => `1px solid ${theme.palette.background.default}`,
    borderRadius: "4px",
    boxShadow: "none",
  },
  title: {
    borderBottom: (theme: Theme) =>
      `1px solid ${theme.palette.background.default}`,
  },
  button: {
    fontSize: "20px",
  },
  textfield: {
    ".MuiInputBase-root": {
      height: { xs: "106px", md: "160px" },
      padding: { xs: "9px 12px", md: "20px" },
    },
    "& textarea": {
      height: "100%!important",
      fontSize: { xs: "14px", md: "16px" },
      lineHeight: 1.5,
      "&::placeholder": {
        color: (theme: Theme) => `${theme.palette.text.secondary}`,
        opacity: 1,
      },
    },
    "& fieldset": {
      borderColor: "#D9D9D9",
    },
  },
};

const defaultContactInfo = {
  phone: "(916) 572-6755",
  email: "support@busybucket.io",
};

interface Props {
  closeModal?: () => void;
}

const Support: React.FC<Props> = ({ closeModal }) => {
  // eslint-disable-next-line no-undef
  const phone = process.env.REACT_APP_SUPPORT_PHONE || defaultContactInfo.phone;
  // eslint-disable-next-line no-undef
  const email = process.env.REACT_APP_SUPPORT_EMAIL || defaultContactInfo.email;
  const [isOpenSnackbar, setIsOpenSnackbar] = useState(false);
  const [text, setText] = useState("");
  const dispatch = useDispatch();
  const userState = useSelector(getAuthSelector);

  const heandleClipboard = () => {
    setIsOpenSnackbar(true);
  };

  const handleSave = (modal: boolean) => {
    const messageTextTrim = text.trim();
    if (!messageTextTrim) return;
    else {
      dispatch(
        contactUsRequest(
          {
            user: {
              firstName: userState.user.firstName,
              lastName: userState.user.lastName,
              email: userState.user.email,
              phoneNumber: userState.user.phoneNumber,
            },
            messageText: messageTextTrim.trim(),
          },
          {
            onSuccess,
          }
        )
      );
      if (modal) closeModal();
    }
  };

  const onSuccess = () => {
    setText("");
    toast.success(
      <>
        <Typography fontSize="16px" color="#262626">
          Message sent successfully
        </Typography>
      </>
    );
  };

  const handleCloseSnackbar = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setIsOpenSnackbar(false);
  };
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnackbar}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <>
      <Card sx={styles.card}>
        <Box p={{ xs: "20px 22px 16px 22px", md: "20px" }} sx={styles.title}>
          <Typography fontSize={16} fontWeight={500}>
            Support Contacts
          </Typography>
        </Box>
        <Box
          p={"20px 22px 25px 22px"}
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            justifyContent: "space-between",
            alignItems: isMobile ? "flex-start" : "center",
          }}
        >
          <Box mb={isMobile ? "30px" : "0"}>
            <Typography
              fontSize={{ xs: "14px", md: "16px" }}
              fontWeight={400}
              color={theme.palette.text.secondary}
            >
              Phone
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              mt={{ xs: "9px", md: "5px" }}
            >
              <Link
                href={`tel:${phone}`}
                fontSize={{ xs: "16px", md: "18px" }}
                fontWeight={500}
                color="primary"
              >
                {phone}
              </Link>
              <CopyToClipboard text={phone} onCopy={heandleClipboard}>
                <IconButton
                  aria-label="delete"
                  size="small"
                  sx={{ marginLeft: "5px" }}
                >
                  <Copy
                    width={isMobile ? 16 : 20}
                    height={isMobile ? 16 : 20}
                  />
                </IconButton>
              </CopyToClipboard>
            </Box>
          </Box>
          <Box>
            <Typography
              fontSize={{ xs: "14px", md: "16px" }}
              fontWeight={400}
              color={theme.palette.text.secondary}
            >
              Email
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              mt={{ xs: "9px", md: "5px" }}
            >
              <Link
                href={`mailto:${email}`}
                fontSize={{ xs: "16px", md: "18px" }}
                fontWeight={500}
                color="primary"
              >
                {email}
              </Link>

              <CopyToClipboard text={email} onCopy={heandleClipboard}>
                <IconButton
                  aria-label="delete"
                  size="small"
                  sx={{ marginLeft: "5px" }}
                >
                  <Copy
                    width={isMobile ? 16 : 20}
                    height={isMobile ? 16 : 20}
                  />
                </IconButton>
              </CopyToClipboard>
            </Box>
          </Box>
        </Box>
      </Card>
      <Card sx={[styles.card, { marginTop: "20px" }]}>
        <Box p={"20px 22px 16px 22px"} sx={styles.title}>
          <Typography fontSize={16} fontWeight={500}>
            Support Ticket
          </Typography>
        </Box>
        <Box p={"20px 22px"}>
          <Box>
            <Typography
              fontSize={{ xs: "14px", md: "16px" }}
              fontWeight={400}
              mb={{ xs: "10px", md: "16px" }}
            >
              Tell us about your problem using below form.
            </Typography>
            <TextField
              sx={styles.textfield}
              multiline
              fullWidth
              placeholder="Describe your problem here"
              rows={5}
              value={text}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (text.length + e.target.value.length > 500)
                  return setText(e.target.value.substring(0, 500));
                else setText(e.target.value);
              }}
              type="text"
              inputProps={{ maxLength: 500 }}
            />
          </Box>
          <Typography
            sx={{ width: "100%", textAlign: "right" }}
            fontSize={{ xs: "14px", md: "16px" }}
            color="#D9D9D9"
          >{`${text.length} / 500`}</Typography>
          {isMobile && (
            <Box mt="10px">
              <Button
                variant="contained"
                color="primary"
                fullWidth
                sx={{ height: "56px" }}
                onClick={() => handleSave(false)}
              >
                <Typography fontSize={{ xs: "14px", md: "16px" }}>
                  Submit
                </Typography>
              </Button>
            </Box>
          )}
        </Box>
      </Card>
      {!isMobile && (
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: "20px" }}>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            style={{ padding: "10px 40px" }}
            onClick={closeModal}
          >
            <Typography color={theme.palette.text.primary}>Cancel</Typography>
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            size="small"
            disabled={!text}
            style={{ marginLeft: "20px", padding: "10px 40px" }}
            onClick={() => handleSave(true)}
          >
            <Typography>Submit</Typography>
          </Button>
        </Box>
      )}
      <Snackbar
        open={isOpenSnackbar}
        autoHideDuration={3000}
        message="Copied!"
        action={action}
        onClose={handleCloseSnackbar}
      />
    </>
  );
};

export default Support;
