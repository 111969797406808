import React from "react";
import { IconComponentProps } from "src/types";

const Link: React.FC<IconComponentProps> = ({ color }) => {
  return (
    <svg
      data-sqa-id="link-icon"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      color={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 13C10.9295 13.5741 11.4774 14.0492 12.1066 14.3929C12.7357 14.7367 13.4315 14.9411 14.1467 14.9923C14.8618 15.0435 15.5796 14.9404 16.2513 14.6898C16.9231 14.4392 17.5331 14.0471 18.04 13.54L21.04 10.54C21.9508 9.59699 22.4548 8.33397 22.4434 7.02299C22.432 5.71201 21.9061 4.45794 20.9791 3.5309C20.0521 2.60386 18.798 2.07802 17.487 2.06663C16.176 2.05523 14.913 2.55921 13.97 3.47L12.25 5.18"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5 11C14.0705 10.4259 13.5226 9.95081 12.8934 9.60706C12.2642 9.2633 11.5684 9.05888 10.8533 9.00766C10.1382 8.95645 9.42037 9.05963 8.74861 9.31021C8.07685 9.5608 7.46684 9.95293 6.95996 10.46L3.95996 13.46C3.04917 14.403 2.54519 15.666 2.55659 16.977C2.56798 18.288 3.09382 19.542 4.02086 20.4691C4.9479 21.3961 6.20197 21.922 7.51295 21.9334C8.82393 21.9448 10.0869 21.4408 11.03 20.53L12.74 18.82"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Link;
