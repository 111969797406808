import React, { useMemo } from "react";
import { Box } from "@mui/system";
import { styles } from "./dashboard.module";
import { Typography, Tabs, Tab, CircularProgress } from "@mui/material";
import PullToRefresh from "react-simple-pull-to-refresh";
import { DateTime, Button, Map } from "src/components";
import { Pause, Play, Plus } from "src/assets/icons";
import TabDataSection from "src/components/TabDataSection";
import TabDataItem from "src/components/TabDataItem";
import { useSelector } from "react-redux";
import { getTodaysClocksSelector } from "src/store/clocks/selectors";
import { getTimeWatchSelector } from "src/store/timeWatch/selectors";
import theme from "src/theme";
import { secondsToTime, parseTime } from "src/utils";
import { ClockRecord, WeeklyRecord } from "src/types";
import { getOrganizationSelector } from "src/store/auth/selectors";

interface Props {
  handleStartWatch: () => void;
  handleStopWatch: () => void;
  handleOpenModal: (name: string, data?: any, tab?: number) => void;
  handleTabChange: (event: React.SyntheticEvent, newValue: number) => void;
  handleFetch?: () => void;
  checkStartWatchFetch?: () => void;
  currentWatch: null;
  currentTab: number;
  disbledClock: boolean;
}

const DashboardMobile: React.FC<Props> = ({
  handleStartWatch,
  handleStopWatch,
  handleOpenModal,
  handleTabChange,
  handleFetch,
  checkStartWatchFetch,
  currentWatch,
  disbledClock,
  currentTab,
}) => {
  const clocks = useSelector(getTodaysClocksSelector);
  const timeWatch = useSelector(getTimeWatchSelector);
  const organization = useSelector(getOrganizationSelector);
  const dayTimeWorked = useMemo(
    () => secondsToTime(clocks.todaysClocks.dayTotal),
    [clocks.todaysClocks]
  );

  const weeklyTimeWorked = useMemo(
    () => secondsToTime(clocks.todaysClocks.weeklyTotal),
    [clocks.todaysClocks]
  );

  const handleRefresh = async () => {
    handleFetch();
    checkStartWatchFetch();
  };

  return (
    <PullToRefresh onRefresh={handleRefresh}>
      <>
        <Box>
          <DateTime />
        </Box>
        {!timeWatch || !timeWatch?.id || timeWatch.endTime ? (
          <Button
            mt="10px"
            fullWidth
            variant="contained"
            color="primary"
            disabled={
              disbledClock ||
              (organization?.trial &&
                new Date().getTime() >
                  parseTime(organization?.trialExpires).getTime())
            }
            onClick={handleStartWatch}
          >
            <Box sx={styles.clockInButtonContainer}>
              <Play
                color={
                  organization?.trial &&
                  new Date().getTime() >
                    parseTime(organization?.trialExpires).getTime()
                    ? "#d9d9d9"
                    : "#0D6FF0"
                }
              />
              <Typography
                sx={styles.clockInButtonText.mobile}
                fontSize={{ xs: "14px", md: "16px" }}
              >
                Clock In
              </Typography>
            </Box>
          </Button>
        ) : (
          <Button
            mt="10px"
            fullWidth
            variant="contained"
            color="success"
            disabled={disbledClock}
            onClick={handleStopWatch}
            style={styles.stopWatch}
          >
            <Box sx={styles.clockInButtonContainer}>
              <Pause />
              <Typography
                sx={styles.clockInButtonText.mobile}
                fontSize={{ xs: "14px", md: "16px" }}
              >
                Clock Out
              </Typography>
            </Box>
            <Box>
              <span>{currentWatch}</span>
            </Box>
          </Button>
        )}
        <Box sx={styles.mapContainer}>
          <Map height="100px" />
        </Box>
        <Box>
          <Tabs
            sx={styles.tabsWrapper}
            value={currentTab}
            onChange={handleTabChange}
          >
            <Tab sx={styles.tabWrapper} label="Today" />
            <Tab sx={styles.tabWrapper} label="Week" />
          </Tabs>

          <TabDataSection px="16px" mt="16px" py="16px">
            {!clocks.isLoading ? (
              <>
                {currentTab === 0 ? (
                  clocks.todaysClocks?.timeClockRecords?.length ? (
                    clocks.todaysClocks.timeClockRecords.map(
                      (clock: ClockRecord) => (
                        <TabDataItem
                          key={clock.id}
                          currentTab={currentTab}
                          isHistoryView={false}
                          clock={clock}
                          onClick={() => {
                            handleOpenModal("GENERAL_ENTRY_MODAL", clock);
                          }}
                        />
                      )
                    )
                  ) : (
                    <Box my={"20px"}>
                      <Typography
                        fontSize={14}
                        fontWeight={600}
                        color={theme.palette.text.secondary}
                        textAlign="center"
                      >
                        No time entries yet
                      </Typography>
                    </Box>
                  )
                ) : clocks.todaysClocks?.weeklyRecords?.length ? (
                  clocks.todaysClocks.weeklyRecords.map(
                    (clock: WeeklyRecord) => (
                      <TabDataItem
                        key={clock.day}
                        currentTab={currentTab}
                        isHistoryView={false}
                        weekclock={clock}
                        onClick={() => {
                          handleOpenModal("GENERAL_ENTRY_MODAL", clock);
                        }}
                      />
                    )
                  )
                ) : (
                  <Box my={"20px"}>
                    <Typography
                      fontSize={14}
                      fontWeight={600}
                      color={theme.palette.text.secondary}
                      textAlign="center"
                    >
                      No weekly summary yet
                    </Typography>
                  </Box>
                )}
                {((currentTab === 0 && clocks.todaysClocks.dayTotal > 0) ||
                  (currentTab === 1 &&
                    clocks.todaysClocks.weeklyTotal > 0)) && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    mt={"20px"}
                  >
                    <Typography
                      fontSize={14}
                      fontWeight={500}
                      color={theme.palette.text.secondary}
                    >
                      {currentTab === 0 ? "Today’s Total" : "Weekly Total"}
                    </Typography>
                    <Typography fontSize={14} fontWeight={600} color="primary">
                      {currentTab === 0 ? dayTimeWorked : weeklyTimeWorked}
                    </Typography>
                  </Box>
                )}
                <Button
                  color="primary"
                  fullWidth
                  variant="contained"
                  mt="20px"
                  disabled={
                    organization?.trial &&
                    new Date().getTime() >
                      parseTime(organization?.trialExpires).getTime()
                  }
                  onClick={() =>
                    handleOpenModal("MANUAL_ENTRY_MODAL", new Date())
                  }
                >
                  <Plus
                    color={
                      organization?.trial &&
                      new Date().getTime() >
                        parseTime(organization?.trialExpires).getTime()
                        ? "#d9d9d9"
                        : "#FFF"
                    }
                  />
                  <Typography fontSize={{ xs: "14px", md: "16px" }} ml={"10px"}>
                    Manual Entry
                  </Typography>
                </Button>
              </>
            ) : (
              <Box
                data-sqa-id="circular-progress-loader"
                display="flex"
                alignItems="center"
                justifyContent="center"
                p={"15px"}
              >
                <CircularProgress />
              </Box>
            )}
          </TabDataSection>
        </Box>
      </>
    </PullToRefresh>
  );
};

export default DashboardMobile;
