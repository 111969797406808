import {
  CLEAN_ERROR,
  DELETE_CLOCK_BY_USER_AND_DAY_FAILURE,
  DELETE_CLOCK_BY_USER_AND_DAY_SUCCESS,
  DELETE_TODAYS_CLOCK_SUCCESS,
  FETCH_DAILY_CLOCK_BY_USER_FAILURE,
  FETCH_DAILY_CLOCK_BY_USER_REQUEST,
  FETCH_DAILY_CLOCK_BY_USER_SUCCESS,
  FETCH_DAILY_CLOCK_FAILURE,
  FETCH_DAILY_CLOCK_REQUEST,
  FETCH_DAILY_CLOCK_SUCCESS,
  FETCH_TODAYS_CLOCK_FAILURE,
  FETCH_TODAYS_CLOCK_REQUEST,
  FETCH_TODAYS_CLOCK_SUCCESS,
  FETCH_WEEKLY_CLOCK_FAILURE,
  FETCH_WEEKLY_CLOCK_REQUEST,
  FETCH_WEEKLY_CLOCK_SUCCESS,
  FETCH_WORKED_MONTH_FAILURE,
  FETCH_WORKED_MONTH_REQUEST,
  FETCH_WORKED_MONTH_SUCCESS,
  LINK_TIME_RECORD_BY_USER_AND_DAY_FAILURE,
  LINK_TIME_RECORD_BY_USER_AND_DAY_SUCCESS,
  LINK_TIME_RECORD_FAILURE,
  LINK_TIME_RECORD_SUCCESS,
  MANUAL_ENTRY_BY_USER_FAILURE,
  MANUAL_ENTRY_BY_USER_SUCCESS,
  MANUAL_ENTRY_FAILURE,
  MANUAL_ENTRY_SUCCESS,
  UPDATE_ENTRY_TIME_RECORD_FAILURE,
  UPDATE_ENTRY_TIME_RECORD_SUCCESS,
} from "./actionTypes";
import { AuthActions, ClocksState } from "./types";

const initialState: ClocksState = {
  isLoading: false,
  todaysClocks: {
    dayTotal: 0,
    timeClockRecords: [],
    weeklyTotal: 0,
    weeklyRecords: [],
  },
  clockRecord: null,
  workedMonth: {
    nextMonth: "",
    previousMonth: "",
    currentMonth: "",
    datesWorked: [],
  },
  error: null,
};

export default (state = initialState, action: AuthActions) => {
  switch (action.type) {
    case FETCH_WEEKLY_CLOCK_REQUEST:
    case FETCH_TODAYS_CLOCK_REQUEST:
    case FETCH_DAILY_CLOCK_REQUEST:
    case FETCH_WORKED_MONTH_REQUEST:
    case FETCH_DAILY_CLOCK_BY_USER_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case FETCH_TODAYS_CLOCK_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        todaysClocks: {
          ...state.todaysClocks,
          ...action.payload.todaysClocks,
        },
      };
    }
    case FETCH_TODAYS_CLOCK_FAILURE: {
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };
    }
    case FETCH_WORKED_MONTH_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        ...action.payload,
      };
    }
    case FETCH_WORKED_MONTH_FAILURE: {
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };
    }
    case FETCH_WEEKLY_CLOCK_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        todaysClocks: {
          ...state.todaysClocks,
          ...action.payload.todaysClocks,
        },
      };
    }
    case FETCH_WEEKLY_CLOCK_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: null,
        ...action.payload,
      };
    }
    case FETCH_DAILY_CLOCK_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        ...action.payload,
      };
    }
    case FETCH_DAILY_CLOCK_FAILURE: {
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };
    }
    case FETCH_DAILY_CLOCK_BY_USER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        ...action.payload,
      };
    }
    case FETCH_DAILY_CLOCK_BY_USER_FAILURE: {
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };
    }
    case LINK_TIME_RECORD_SUCCESS: {
      return {
        ...state,
        error: null,
      };
    }
    case LINK_TIME_RECORD_FAILURE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case LINK_TIME_RECORD_BY_USER_AND_DAY_SUCCESS: {
      return {
        ...state,
        error: null,
      };
    }
    case LINK_TIME_RECORD_BY_USER_AND_DAY_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
      };
    }
    case MANUAL_ENTRY_SUCCESS: {
      return {
        ...state,
        error: null,
        ...action.payload,
      };
    }
    case MANUAL_ENTRY_FAILURE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case MANUAL_ENTRY_BY_USER_SUCCESS: {
      return {
        ...state,
        error: null,
        ...action.payload,
      };
    }
    case MANUAL_ENTRY_BY_USER_FAILURE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case UPDATE_ENTRY_TIME_RECORD_SUCCESS: {
      return {
        ...state,
        error: null,
        ...action.payload,
      };
    }
    case UPDATE_ENTRY_TIME_RECORD_FAILURE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case DELETE_TODAYS_CLOCK_SUCCESS: {
      return {
        ...state,
        error: null,
      };
    }
    case DELETE_CLOCK_BY_USER_AND_DAY_SUCCESS: {
      return {
        ...state,
        error: null,
      };
    }
    case DELETE_CLOCK_BY_USER_AND_DAY_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
      };
    }
    case CLEAN_ERROR: {
      return {
        ...state,
        error: null,
      };
    }
    default:
      return state;
  }
};
