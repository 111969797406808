import {
  Box,
  CircularProgress,
  Divider,
  Theme,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { isMobile } from "mobile-device-detect";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import theme from "src/theme";
import Button from "../Button";
import CreditCardForm from "../CreditCardForm";
import * as yup from "yup";
import {
  cleanError,
  getInvoiceRequest,
  getSubscriptionRequest,
  startSubscriptionRequest,
} from "src/store/subscription/actions";
import { getOrganizationRequest } from "src/store/auth/actions";
import { AppRoutes, ZIP_NUMBER_REGEX } from "src/constants";
import { useNavigate } from "react-router-dom";
import { getSubscriptionSelector } from "src/store/subscription/selectors";

const validationSchema = yup.object().shape({
  cardHolderName: yup.string().required("Cardholder name is required"),
  cardNumber: yup.string().required("Card number is required"),
  expDate: yup.string().required("Expiration date is required"),
  cvc: yup.string().required("CVC is required"),
  zip: yup
    .string()
    .required("Zip code is required")
    .matches(ZIP_NUMBER_REGEX, "Zip code is not valid"),
});

const styles = {
  paydPlanCounter: {
    mt: "20px",
    p: "15px 20px",
    border: (theme: Theme) => `1px solid ${theme.palette.background.default}`,
    borderRadius: "4px",
  },
  counterText: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: { xs: "column", md: "row" },
  },
  submitLoader: {
    position: "absolute",
  },
};

interface ModalProps {
  closeModal?: () => void;
  user?: number;
}
const CreditCardUpdate: React.FC<ModalProps> = ({ user, closeModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const subscriptionState = useSelector(getSubscriptionSelector);
  const formik = useFormik({
    initialValues: {
      cardHolderName: "",
      cardNumber: "",
      expDate: "",
      cvc: "",
      zip: "",
    },
    validationSchema: validationSchema,
    onSubmit: ({ cardHolderName, cardNumber, expDate, cvc, zip }) => {
      console.log(zip);
      dispatch(
        startSubscriptionRequest(
          {
            subscriptionUsersNumber: Number(user),
            billingPeriod: "monthly",
            cardHolderName: cardHolderName.trim(),
            cardNumber,
            expMonth: Number(expDate.slice(0, 2)),
            expYear: Number(expDate.slice(5)),
            cvc,
          },
          {
            onSuccess,
          }
        )
      );
    },
  });

  const onSuccess = () => {
    dispatch(getSubscriptionRequest());
    dispatch(getOrganizationRequest());
    dispatch(getInvoiceRequest());
    if (!isMobile) closeModal();
    else {
      navigate(AppRoutes.Settings);
      sessionStorage.setItem("activeTab", "2");
    }
  };

  useEffect(() => {
    dispatch(cleanError());
    return () => {
      dispatch(cleanError());
    };
  }, []);

  const pricePerUser = 10;

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <CreditCardForm formik={formik} />

        <Box sx={styles.paydPlanCounter}>
          <Box sx={styles.counterText}>
            <Typography fontSize={14}>Monthly Paid Plan</Typography>
            <Typography
              fontSize={14}
              display="flex"
              justifyContent="space-between"
            >
              <Typography component="span" fontSize="14px">
                (${pricePerUser} x{" "}
                {user === 1 ? `${user} user` : `${user} users`} x 1 month)
              </Typography>
              <Typography component="span" fontSize="14px" ml="5px">
                $
                {Number(pricePerUser * user * 1).toLocaleString("en", {
                  minimumFractionDigits: 2,
                })}
              </Typography>
            </Typography>
          </Box>
          <Divider sx={{ margin: "15px 0" }} />
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography fontSize={16} fontWeight={600}>
              Total
            </Typography>
            <Typography fontSize={16} fontWeight={600}>
              $
              {Number(pricePerUser * user * 1).toLocaleString("en", {
                minimumFractionDigits: 2,
              })}
            </Typography>
          </Box>
        </Box>
        <Typography
          textAlign={"center"}
          fontSize={12}
          color={theme.palette.text.secondary}
          margin={"17px auto 0"}
          maxWidth={"450px"}
        >
          Your subscription will renew automatically by charging your payment
          method on file until you cancel. You may cancel at any time prior to
          the next billing cycle.
        </Typography>
        <Box mt="25px" sx={{ textAlign: "right" }}>
          {!isMobile && (
            <Button
              variant="outlined"
              size="small"
              color="secondary"
              style={{
                marginRight: "20px",
                padding: "4px 40px",
              }}
              onClick={closeModal}
            >
              <Typography color={theme.palette.text.primary}>Cancel</Typography>
            </Button>
          )}
          <Button
            variant="contained"
            size="small"
            color="primary"
            style={{ padding: "4px 40px" }}
            fullWidth={isMobile}
            disabled={subscriptionState.isLoadingSubscription ? true : false}
            type="submit"
          >
            {subscriptionState.isLoadingSubscription && (
              <CircularProgress
                size={20}
                sx={styles.submitLoader}
                color="primary"
              />
            )}
            <Typography>Submit Purchase</Typography>
          </Button>
        </Box>
      </form>
    </>
  );
};

export default CreditCardUpdate;
