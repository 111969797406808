import React, { useEffect, useState } from "react";
import { styles } from "./comments.module";
import { Box } from "@mui/system";
import { CircularProgress, Typography } from "@mui/material";
import PullToRefresh from "react-simple-pull-to-refresh";
import { Button } from "src/components";
import { Add } from "src/assets/icons";
import { useDispatch, useSelector } from "react-redux";
import { getCommentsSelector } from "src/store/comments/selectors";
import { fetchCommentsRequest } from "src/store/comments/actions";
import { CommentDay } from "src/types";
import ActionsModal from "src/components/ActionsModal/ActionsModal";
import { format } from "date-fns";
import TabCommentsData from "src/components/TabCommentsData";
import DeviceWrapper from "../../DeviceWrapper";
import { parseTime } from "src/utils";

const CommentsPage = () => {
  const [size, setSize] = useState<number>(0);
  const pageSize: number = 1000;
  const [isToday, setIsToday] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [commentId, setCommentId] = useState<string>("");
  const [commentDate, setCommentDate] = useState<string>("");
  const [modalActionType, setModalActionType] = useState({
    action: "",
    page: "Comment",
  });

  const dispatch = useDispatch();
  const commentState = useSelector(getCommentsSelector);

  const handleOpenModal = (type: string, elem?: string) => {
    setOpenModal(true);
    setCommentId(elem);
    setCommentDate(elem);
    setModalActionType((prevState) => ({
      ...prevState,
      action: type,
    }));
  };

  const handleFetchComments = () => {
    dispatch(
      fetchCommentsRequest({
        currentPage: 1,
        pageSize: pageSize,
        sortOrder: "asc",
        fromDate: format(
          new Date(
            new Date().setDate(
              new Date().getDate() - new Date().getDay() - 7 * size
            )
          ),
          "y-MM-dd"
        ),
        toDate: format(
          new Date(
            new Date().setDate(new Date().getDate() - new Date().getDay() + 6)
          ),
          "y-MM-dd"
        ),
        sortBy: "commentDay",
      })
    );
  };

  const findIsTodayComments = () => {
    const isDayExist: boolean = commentState?.comments?.days?.some(
      (elem) => elem?.day === format(new Date(), "y-MM-dd")
    );
    if (isDayExist) setIsToday(false);
    else setIsToday(true);
  };

  useEffect(() => {
    handleFetchComments();
  }, [size]);

  useEffect(() => {
    findIsTodayComments();
  }, [commentState?.comments]);

  const handleRefresh = async () => {
    handleFetchComments();
  };

  return (
    <>
      <DeviceWrapper>
        <PullToRefresh onRefresh={handleRefresh}>
          <Box>
            {!commentState.isLoading && isToday && (
              <Box sx={{ marginBottom: "30px" }}>
                <Typography data-sqa-id="today-label" sx={styles.commentsDay}>
                  Today
                </Typography>
                <Box sx={styles.noData}>
                  <Typography
                    data-sqa-id="no-comments-uploaded-yet"
                    sx={styles.noDataText}
                  >
                    No comments uploaded yet
                  </Typography>
                </Box>
                <Button
                  data-sqa-id="add-comment-btn"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    handleOpenModal("Add", format(new Date(), "y-MM-dd"))
                  }
                >
                  <Box sx={styles.addButtonContainer}>
                    <Add />
                    <Typography
                      data-sqa-id="add-comment-btn-text"
                      sx={styles.addButtonText}
                    >
                      Add Comment
                    </Typography>
                  </Box>
                </Button>
              </Box>
            )}
            <Box sx={styles.commentList}>
              {commentState?.comments?.days
                ?.sort((a: any, b: any) =>
                  b.day > a.day ? 1 : a.day > b.day ? -1 : 0
                )
                ?.map((item: CommentDay) => {
                  if (parseTime(item.day) <= new Date()) {
                    return (
                      <Box key={item.day} sx={styles.commentWrapper}>
                        <Typography sx={styles.commentsDay}>
                          {format(parseTime(item.day), "MMMM d, y") ===
                          format(new Date(), "MMMM d, y")
                            ? "Today"
                            : format(parseTime(item.day), "MMMM d, y")}
                        </Typography>
                        <TabCommentsData
                          commentDay={item.comments}
                          handleFetch={handleFetchComments}
                        />
                        <Button
                          data-sqa-id="add-comment-btn"
                          mt="10px"
                          sx={{ mb: "30px" }}
                          fullWidth
                          variant="contained"
                          color="primary"
                          onClick={() => handleOpenModal("Add", item.day)}
                        >
                          <Box sx={styles.addButtonContainer}>
                            <Add />
                            <Typography
                              data-sqa-id="add-comment-btn-text"
                              sx={styles.addButtonText}
                            >
                              Add Comment
                            </Typography>
                          </Box>
                        </Button>
                      </Box>
                    );
                  }
                })}
            </Box>
            {!commentState.isLoading ? (
              <Button
                data-sqa-id="load-more-btn"
                variant="outlined"
                fullWidth
                style={{
                  border: "1px solid #0D6FF0",
                  boxShadow: "none",
                }}
                onClick={() => setSize((prevState) => prevState + 1)}
              >
                <Box sx={styles.addButtonContainer}>
                  <Typography
                    data-sqa-id="load-more-btn-text"
                    sx={styles.loadMore}
                  >
                    Load More
                  </Typography>
                </Box>
              </Button>
            ) : (
              <Box
                data-sqa-id="circulat-progress-loader"
                display="flex"
                alignItems="center"
                justifyContent="center"
                mb="10px"
              >
                <CircularProgress />
              </Box>
            )}
          </Box>
        </PullToRefresh>
      </DeviceWrapper>
      {openModal && (
        <ActionsModal
          onFetchSuccess={handleFetchComments}
          elementDate={commentDate}
          modalActionType={modalActionType}
          isOpen={openModal}
          elementId={commentId}
          closeModal={() => setOpenModal(false)}
        />
      )}
    </>
  );
};

export default CommentsPage;
