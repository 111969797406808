import {
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOG_OUT,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_FAILURE,
  SIGN_UP_FAILURE,
  SIGN_UP_SUCCESS,
  UPDATE_USER_DATA_SUCCESS,
  CHECK_PASSWORD_RESET_FAILURE,
  CHECK_PASSWORD_RESET_SUCCESS,
  SEND_VERIFICATION_CODE_FAILURE,
  SEND_VERIFICATION_CODE_SUCCESS,
  SEND_VERIFICATION_SMS_FAILURE,
  SEND_VERIFICATION_SMS_SUCCESS,
  GET_ORGANIZATION_REQUEST,
  GET_ORGANIZATION_SUCCESS,
  GET_ORGANIZATION_FAILURE,
  UPDATE_ORGANIZATION_SUCCESS,
  UPDATE_ORGANIZATION_FAILURE,
  EMAIL_CONFIRMATION_SUCCESS,
  EMAIL_CONFIRMATION_FAILURE,
  DELETE_ORGANIZATION_SUCCESS,
  DELETE_ORGANIZATION_FAILURE,
  CURRENT_USER_DATA_SUCCESS,
  CURRENT_USER_DATA_FAILURE,
  DELETE_USER_PROFILE_PICTURE_FAILURE,
  EMAIL_CONFIRMATION_REQUEST,
  CLEAN_ERROR,
  RESET_STATE,
  DELETE_CURRENT_USER_FAILURE,
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_SUCCESS,
} from "./actionTypes";
import { AuthActions, AuthState } from "./types";

const initialState: AuthState = {
  isLoading: false,
  user: null,
  organization: null,
  token: null,
  error: null,
};

export default (state = initialState, action: AuthActions) => {
  switch (action.type) {
    case GET_ORGANIZATION_REQUEST:
    case EMAIL_CONFIRMATION_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case GET_ORGANIZATION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        organization: action.payload.organization,
      };
    }
    case UPDATE_ORGANIZATION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        organization: action.payload.organization,
      };
    }
    case DELETE_ORGANIZATION_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case SIGN_UP_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case LOGIN_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        error: null,
      };
    }
    case EMAIL_CONFIRMATION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };
    }
    case CURRENT_USER_DATA_SUCCESS: {
      return {
        ...state,
        user: action.payload.user,
      };
    }
    case UPDATE_USER_DATA_SUCCESS: {
      return {
        ...state,
        user: action.payload.user,
      };
    }
    case PASSWORD_RESET_SUCCESS: {
      return {
        ...state,
        token: action.payload.resetToken,
      };
    }
    case CHECK_PASSWORD_RESET_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case SEND_VERIFICATION_SMS_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case SEND_VERIFICATION_CODE_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case SIGN_UP_FAILURE:
    case LOGIN_FAILURE:
    case PASSWORD_RESET_FAILURE:
    case CHECK_PASSWORD_RESET_FAILURE:
    case SEND_VERIFICATION_SMS_FAILURE:
    case SEND_VERIFICATION_CODE_FAILURE:
    case CURRENT_USER_DATA_FAILURE:
    case CHANGE_PASSWORD_FAILURE:
    case GET_ORGANIZATION_FAILURE:
    case EMAIL_CONFIRMATION_FAILURE:
    case UPDATE_ORGANIZATION_FAILURE:
    case DELETE_ORGANIZATION_FAILURE:
    case DELETE_CURRENT_USER_FAILURE:
    case DELETE_USER_PROFILE_PICTURE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    case CLEAN_ERROR: {
      return {
        ...state,
        error: null,
      };
    }
    case RESET_STATE: {
      return initialState;
    }
    case LOG_OUT: {
      return initialState;
    }
    default:
      return state;
  }
};
