import { Box, Typography } from "@mui/material";
import React from "react";
import { UserBreakdown } from "src/types";

interface Props {
  elem: UserBreakdown;
}

const ReportTooltipText: React.FC<Props> = ({ elem }) => {
  return (
    <>
      <Box>
        {elem.hourlyRateVaries ? (
          <>
            <Typography fontSize="14px" fontWeight={500} color="#262626">
              Hourly Rate Varies
            </Typography>
            <Typography fontSize="14px" fontWeight={400} color="#8C8C8C">
              Indicates that the report includes timeclock records with
              different hourly rates tight to them. This can occur when a user
              is promoted/demoted and the hourly rate was changed. In this case,
              the past timeclock records will have an hourly rate that the user
              had before the update.
            </Typography>
          </>
        ) : null}
        {elem.recordsMissingHourlyRate ? (
          <>
            <Typography
              fontSize="14px"
              fontWeight={500}
              color="#262626"
              m="30px 0 10px"
            >
              Records Missing Hourly Rate
            </Typography>
            <Typography fontSize="14px" fontWeight={400} color="#8C8C8C">
              Indicates that the report includes timeclock records that do not
              have hourly rates tight to them. This can occur when a user does
              not have an hourly rate set.
            </Typography>
          </>
        ) : null}
        {elem.inProgressClockIn ? (
          <>
            <Typography
              fontSize="14px"
              fontWeight={500}
              color="#262626"
              m="30px 0 10px"
            >
              In-progress Timeclock
            </Typography>
            <Typography fontSize="14px" fontWeight={400} color="#8C8C8C">
              Indicates that the report includes timeclock records currently in
              progress, i.e. clocked in and still need to clock out.
            </Typography>
          </>
        ) : null}
      </Box>
    </>
  );
};

export default ReportTooltipText;
