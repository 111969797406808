import { isMobile } from "mobile-device-detect";
export const styles = {
  boxContainer: {
    display: "flex",
    flexDirection: "column",
    border: (theme) => `1px solid ${theme.palette.background.default}`,
    backgroundColor: "#fff",
    borderRadius: "4px",
    padding: "24px 22px",
  },
  flexEnd: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  topContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  buttonManualEntry: {
    height: "46px",
    backgroundColor: "#FFF",
    boxShadow: "none",
    border: (theme) => `1px solid ${theme.palette.background.secondary}`,
  },
  boxWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    columnGap: "20px",
  },
  filter: {
    fieldset: {
      border: (theme) => `1px solid ${theme.palette.background.secondary}`,
    },
  },
  dateStyle: {
    border: (theme) => `1px solid ${theme.palette.background.secondary}`,
    borderRadius: "4px",
    height: "46px",
    position: "relative",
    ".react-datepicker-wrapper": {
      height: "100%",
    },
    ".calendarPicker.react-datepicker-popper": {
      width: { xs: "100%", md: "auto" },
      ".react-datepicker": {
        width: { xs: "100%", md: "auto" },
        ".react-datepicker__month-container": {
          width: { xs: "100%", md: "auto" },
        },
      },
    },
  },
  typographyTitle: {
    color: "#262626",
    position: "absolute",
    top: "25%",
    translate: "transformY(50%)",
    left: "16px",
  },
  arrowDate: {
    height: "16px",
    position: "absolute",
    top: "35%",
    translate: "transformY(50%)",
    right: "3px",
  },
  arrowDateIcon: {
    height: "24px",
    position: "absolute",
    top: "30%",
    translate: "transformY(50%)",
    right: "7px",
  },
  filterListWrapper: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    columnGap: "10px",
    rowGap: "10px",
  },
  filterTitle: {
    width: "fit-content",
    backgroundColor: "#ECF3FE",
    borderRadius: "45px",
    p: "6px 15px",
  },
  filterItemWrapper: {
    display: "flex",
    width: "fit-content",
    backgroundColor: "#FAFAFA",
    borderRadius: "45px",
    p: "6px 15px",
  },
  filterItem: {
    ml: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "22px",
    height: "22px",
    borderRadius: "50%",
    backgroundColor: "#F0F0F0",
    cursor: "pointer",
  },
  pageTitle: {
    fontSize: "32px",
    fontWeight: 500,
    color: "#000",
  },
  teamContainer: {
    marginTop: "30px",
    padding: "30px 20px",
    backgroundColor: "#fff",
    borderRadius: "4px",
    border: "1px solid #F0F0F0",
  },
  input: {
    marginTop: 0,
    width: "300px",
  },
  addButtonContainer: {
    display: "flex",
    alignItems: "center",
  },
  addButtonText: {
    fontWeight: 500,
    ml: "10px",
    textTransform: "initial",
  },
  pagination: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  reportItemCards: {
    mt: "10px",
    display: "flex",
    alignItems: "center",
    columnGap: "10px",
  },
  countContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px 10px",
    border: "1px solid transparent",
    backgroundColor: "#ECF3FE",
    borderRadius: "4px",
  },
  emptyContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px 10px",
    border: "1px solid #F0F0F0",
    backgroundColor: "transparent",
    borderRadius: "4px",
  },
  threeDots: {
    backgroundColor: "#FFF",
    border: "1px solid #F0F0F0",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "40px",
    minHeight: "40px",
  },
  noData: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: isMobile ? "140px" : "340px",
    backgroundColor: "#F0F0F0",
    borderRadius: "4px",
  },
  noDataText: {
    fontWeight: 400,
    textAlign: "center",
    fontSize: isMobile ? "14px" : "18px",
    color: "#8C8C8C",
  },
};
