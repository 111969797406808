import {
  FETCH_ATTACHMENTS_SUCCESS,
  FETCH_ATTACHMENTS_FAILURE,
  ADD_ATTACHMENT_FAILURE,
  PRESIGNED_URL_SUCCESS,
  PRESIGNED_URL_FAILURE,
  FETCH_DAILY_ATTACHMENTS_REQUEST,
  FETCH_ATTACHMENTS_REQUEST,
  FETCH_DAILY_ATTACHMENTS_FAILURE,
  FETCH_DAILY_ATTACHMENTS_SUCCESS,
  DELETE_ATTACHMENT_FAILURE,
  UPDATE_ATTACHMENT_FAILURE,
  FETCH_ATTACHMENT_ID_REQUEST,
  FETCH_ATTACHMENT_ID_FAILURE,
  FETCH_ATTACHMENT_ID_SUCCESS,
  FETCH_ATTACHMENTS_DAY_BY_USER_FAILURE,
  FETCH_ATTACHMENTS_DAY_BY_USER_REQUEST,
  FETCH_ATTACHMENTS_DAY_BY_USER_SUCCESS,
  CLEAN_F3IMAGES,
} from "./actionTypes";
import { AuthActions, AttachmentsState } from "./types";

const initialState: AttachmentsState = {
  isLoading: false,
  attachments: {
    currentPage: 0,
    totalPages: 0,
    pageSize: 0,
    sortOrder: "",
    days: [],
  },
  bucketUrlPrefix: "",
  attachment: {
    bucketUrlPrefix: "",
    thumbnailBucketUrlPrefix: "",
    attachments: [],
  },
  f3images: [],
  error: null,
};

export default (state = initialState, action: AuthActions) => {
  switch (action.type) {
    case FETCH_ATTACHMENTS_REQUEST:
    case FETCH_DAILY_ATTACHMENTS_REQUEST:
    case FETCH_ATTACHMENT_ID_REQUEST:
    case FETCH_ATTACHMENTS_DAY_BY_USER_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case FETCH_ATTACHMENTS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };
    }
    case FETCH_ATTACHMENTS_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
      };
    }
    case FETCH_ATTACHMENTS_DAY_BY_USER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        ...action.payload.attachments,
      };
    }
    case FETCH_ATTACHMENTS_DAY_BY_USER_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
      };
    }
    case FETCH_DAILY_ATTACHMENTS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        ...action.payload.attachments,
      };
    }
    case FETCH_DAILY_ATTACHMENTS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    case FETCH_ATTACHMENT_ID_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };
    }
    case FETCH_ATTACHMENT_ID_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
      };
    }
    // case ADD_ATTACHMENT_SUCCESS: {
    //   return {
    //     ...state,
    //     ...action.payload,
    //   };
    // }
    case ADD_ATTACHMENT_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    // case UPDATE_ATTACHMENT_SUCCESS: {
    //   return {
    //     ...state,
    //     ...action.payload,
    //   };
    // }
    case UPDATE_ATTACHMENT_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    // case DELETE_ATTACHMENT_SUCCESS: {
    //   return {
    //     ...state,
    //     ...action.payload,
    //   };
    // }
    case DELETE_ATTACHMENT_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
      };
    }
    case PRESIGNED_URL_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case PRESIGNED_URL_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
      };
    }
    case CLEAN_F3IMAGES: {
      return {
        ...state,
        f3images: [],
      };
    }
    default:
      return state;
  }
};
