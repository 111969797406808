import React from "react";
import { Tooltip as MuiTooltip, TooltipProps } from "@mui/material";

const styles = {
  tooltip: {
    color: "#8C8C8C",
    bgcolor: "common.white",
    border: "1px solid #F0F0F0",
    p: "10px",
  },
};

interface Props extends TooltipProps {
  children: React.ReactElement;
}

const Tooltip: React.FC<Props> = ({ children, ...props }) => {
  return (
    <MuiTooltip
      componentsProps={{
        tooltip: {
          sx: styles.tooltip,
        },
      }}
      {...props}
    >
      {children}
    </MuiTooltip>
  );
};

export default Tooltip;
