import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: `Public Sans, sans-serif`,
  },
  palette: {
    primary: {
      main: "#0D6FF0",
    },
    secondary: {
      main: "#8C8C8C",
    },
    text: {
      secondary: "#8C8C8C",
      primary: "#000",
      green: "#67BB66",
    },
    background: {
      default: "#F0F0F0",
      paper: "#FFFFFF",
      main: "#FAFAFA",
      significantBlue: "#ECF3FE",
      significantGreen: "#F0F8F0",
      secondary: "#D9D9D9",
    },
    common: {
      black: "#262626",
    },
    info: {
      main: "rgba(250, 173, 20, 0.3)",
      contrastText: "#B87A00",
    },
    error: {
      "500": "#FF4D4F",
      "700": "#FF0000",
    },
    success: {
      main: "#52C41A",
      contrastText: "#FFFFFF",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1820,
    },
  },
});

declare module "@mui/material/styles" {
  interface TypeBackground {
    main: TypeBackground["default"];
    significantBlue: TypeBackground["default"];
    significantGreen: TypeBackground["default"];
    secondary: TypeBackground["default"];
  }
  interface TypeText {
    green: TypeText["primary"];
  }
  interface PaletteOptions {
    main?: PaletteOptions["background"];
    significantBlue?: PaletteOptions["background"];
    significantGreen?: PaletteOptions["background"];
  }
  interface BreakpointOverrides {
    xxl?: true;
  }
}

export default theme;
