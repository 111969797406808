import { createSelector } from "reselect";

import { AppState } from "../rootReducer";

const getTodaysClocks = (state: AppState) => state.clocks;

export const getTodaysClocksSelector = createSelector(
  getTodaysClocks,
  (todaysClocks) => todaysClocks
);
