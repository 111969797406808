import axios from "../../utils/axios";
import {
  AddAttachmentRequestPayload,
  DeleteAttachmentRequestPayload,
  FetchAttachmentIdRequestPayload,
  FetchAttachmentsDayByUserRequestPayload,
  FetchAttachmentsRequestPayload,
  FetchDailyAttachmentsRequestPayload,
  GetPresignedUrlRequestPayload,
  UpdateAttachmentRequestPayload,
} from "./types";

export const fetchAttachments = (payload: FetchAttachmentsRequestPayload) => {
  return axios.get(
    `/attachment?currentPage=${payload.currentPage}&pageSize=${payload.pageSize}&sortOrder=${payload.sortOrder}&fromDate=${payload.fromDate}&toDate=${payload.toDate}&sortBy=${payload.sortBy}`
  );
};

export const fetchAttachmentsDayByUser = (
  payload: FetchAttachmentsDayByUserRequestPayload
) => {
  return axios.get(
    `/attachment/${payload.date}/user/${payload.id}?receiptsOnly=${payload.receiptsOnly}`
  );
};

export const fetchDailyAttachments = (
  payload: FetchDailyAttachmentsRequestPayload
) => {
  return axios.get(`/attachment/day/${payload.date}`);
};

export const fetchAttachmentId = (payload: FetchAttachmentIdRequestPayload) => {
  return axios.get(`/attachment/${payload.id}`);
};

export const addAttachment = (payload: AddAttachmentRequestPayload) => {
  return axios.post(
    `/attachment?timezone=${payload.timezone}`,
    payload.attachments
  );
};

export const updateAttachment = (payload: UpdateAttachmentRequestPayload) => {
  return axios.put("/attachment", payload);
};

export const deleteAttachment = (payload: DeleteAttachmentRequestPayload) => {
  return axios.delete(`/attachment/${payload.id}`);
};

export const getPresignedUrl = (payload: GetPresignedUrlRequestPayload) => {
  return axios.post("/getPresignedUrl", payload);
};
