import { Theme } from "@mui/material";

export const styles = {
  modal: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "4px",
    maxHeight: "90vh",
    height: "100%",
  },
  modalContent: {
    padding: "24px",
    height: "inherit",
    px: {
      xs: "24px",
      md: "20px",
    },
  },
  contentWrapper: {
    height: "inherit",
    flexDirection: "column",
  },
  prevArrow: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
    borderRadius: "4px",
    padding: "10px",
    position: "absolute",
    top: "50%",
    left: "-8%",
    cursor: "pointer",
  },
  nextArrow: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
    borderRadius: "4px",
    padding: "10px",
    position: "absolute",
    top: "50%",
    right: "-8%",
    transform: "rotate(-180deg)",
    cursor: "pointer",
  },
  attachmentContainer: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "73%",
    backgroundColor: "#F0F0F0",
  },
  whiteBgReceipt: {
    position: "absolute",
    top: "16px",
    right: "16px",
    backgroundColor: "#fff",
    borderRadius: "4px",
    padding: "2px 10px",
  },
  caption: {
    fontSize: "10px",
    lineHeight: "22px",
    fontWeight: 400,
    color: "#0D6FF0",
  },
  attachment: {
    maxWidth: "100%",
    maxHeight: "100%",
    borderRadius: "10px",
    width: "auto",
    minHeight: "240px",
    backgroundColor: "#F0F0F0",
    margin: "0 auto",
  },
  captionText: {
    marginTop: "5px",
    fontWeight: "400",
    color: "#000",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
    wordBreak: "break-all",
    overflow: "hidden",
  },
  boxBorder: {
    border: "1px solid #F0F0F0",
    borderRadius: "4px",
  },
  togleContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "20%",
    height: "fit-content",
    padding: "16px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    columnGap: "20px",
    width: "20%",
  },
  actionButton: {
    textTransform: "none",
    color: (theme: Theme) => `${theme.palette.text.primary}`,
    borderColor: (theme: Theme) => `${theme.palette.background.default}`,
    "&:hover": {
      background: (theme: Theme) => `${theme.palette.background.paper}`,
      color: (theme: Theme) => `${theme.palette.primary.main}`,
    },
  },
  textField: {
    "& > div": { p: 0 },
    "& fieldset": { border: "none" },
  },
};
