import { Box, CircularProgress, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { styles } from "../Projects/projects.module";
import { useNavigate, useLocation } from "react-router-dom";
import { Back } from "src/assets/icons";
import TabDataSection from "src/components/TabDataSection";
import TabValue from "src/components/TabValue";
import DailyComments from "src/components/ReportTabs/DailyComments";
import Attachments from "src/components/ReportTabs/Attachments";
import { fetchAttachmentsDayByUserRequest } from "src/store/attachments/actions";
import { fetchCommentsDayByUserRequest } from "src/store/comments/actions";
import { format } from "date-fns";
import { getAttachmentsSelector } from "src/store/attachments/selectors";
import { getCommentsSelector } from "src/store/comments/selectors";
import DailyEntries from "src/components/ReportTabs/DailyEntries";
import { fetchDailyClockByUserRequest } from "src/store/clocks/actions";
import { getTodaysClocksSelector } from "src/store/clocks/selectors";
import DeviceWrapper from "src/pages/DeviceWrapper";
import { isMobile } from "mobile-device-detect";
import { parseTime } from "src/utils";

const ReportItem: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const activeTab: number = parseInt(sessionStorage.getItem("activeTab"));
  const commentsState = useSelector(getCommentsSelector);
  const attachmentsState = useSelector(getAttachmentsSelector);
  const clocksState = useSelector(getTodaysClocksSelector);
  const tabData = useLocation().state as {
    name: {
      id: string;
      userName: string;
    };
    date: string;
    atachmentsCount: number;
    commentsCount: number;
  };
  const [currentTab, setCurrentTab] = useState<number>(activeTab || 0);
  const [isReceipt, setIsReceipt] = useState<string>("Show Everything");

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
    sessionStorage.setItem("activeTab", newValue.toString());
  };

  const fetchAttachments = () => {
    dispatch(
      fetchAttachmentsDayByUserRequest({
        id: tabData.name.id,
        date: tabData.date,
        receiptsOnly: isReceipt === "Show Everything" ? false : true,
      })
    );
  };

  const fetchComments = () => {
    dispatch(
      fetchCommentsDayByUserRequest({
        id: tabData.name.id,
        date: tabData.date,
      })
    );
  };

  const fetchEntries = () => {
    dispatch(
      fetchDailyClockByUserRequest({
        id: tabData.name.id,
        date: tabData.date,
      })
    );
  };

  useEffect(() => {
    if (currentTab === 0) fetchEntries();
    if (currentTab === 1) fetchAttachments();
    if (currentTab === 2) fetchComments();
  }, [currentTab, isReceipt]);

  useEffect(() => {
    return () => {
      sessionStorage.setItem("activeTab", "0");
    };
  }, []);

  return (
    <>
      <DeviceWrapper>
        <>
          {commentsState.isLoading &&
          attachmentsState.isLoading &&
          clocksState.isLoading ? (
            <Box
              data-sqa-id="circular-progress-loader"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              {isMobile && (
                <Typography
                  color="#000"
                  fontWeight={500}
                  sx={{
                    position: "absolute",
                    top: "50px",
                    left: "50%",
                    transform: "translateX(-50%)",
                  }}
                >
                  {currentTab === 0
                    ? "Day Details"
                    : currentTab === 1
                    ? "Attachments"
                    : "Comments"}
                </Typography>
              )}
              <Box
                sx={styles.topContainerProject}
                onClick={() => {
                  if (!isMobile) navigate(-1);
                }}
              >
                {!isMobile && <Back width={19} height={18} color="#000" />}
                {isMobile ? (
                  <Box>
                    <Typography sx={[styles.pageTitle, { marginLeft: 0 }]}>
                      {format(parseTime(tabData.date), "MMMM d, y")}
                    </Typography>
                    <Typography sx={[styles.pageTitle, { margin: "0" }]}>
                      {tabData.name.userName}
                    </Typography>
                  </Box>
                ) : (
                  <Typography sx={[styles.pageTitle, { marginLeft: "15px" }]}>
                    {`${format(parseTime(tabData.date), "MMMM d, y")} for ${
                      tabData.name.userName
                    }
                  `}
                  </Typography>
                )}
              </Box>
              <Box
                sx={[
                  styles.teamContainer,
                  { marginTop: isMobile ? "16px" : "30px" },
                ]}
              >
                <Box>
                  <Tabs
                    sx={styles.tabsWrapper}
                    variant="scrollable"
                    value={currentTab}
                    onChange={handleTabChange}
                  >
                    <Tab sx={styles.tabWrapper} label="Daily Entries" />
                    <Tab
                      sx={styles.tabWrapper}
                      label="Attachments"
                      icon={
                        <TabValue
                          isSelected={currentTab === 1}
                          count={tabData.atachmentsCount}
                        />
                      }
                      iconPosition="end"
                    />
                    <Tab
                      sx={styles.tabWrapper}
                      label={isMobile ? "Comments" : "Daily Comments"}
                      icon={
                        <TabValue
                          isSelected={currentTab === 2}
                          count={tabData.commentsCount}
                        />
                      }
                      iconPosition="end"
                    />
                  </Tabs>
                  <TabDataSection mt="20px" border="none">
                    {currentTab === 0 && (
                      <DailyEntries
                        date={format(parseTime(tabData.date), "y-MM-dd")}
                        user={tabData.name}
                        fetchEntries={fetchEntries}
                      />
                    )}
                    {currentTab === 1 && (
                      <Attachments
                        isReceipt={isReceipt}
                        setIsReceipt={setIsReceipt}
                      />
                    )}
                    {currentTab === 2 && <DailyComments />}
                  </TabDataSection>
                </Box>
              </Box>
            </>
          )}
        </>
      </DeviceWrapper>
    </>
  );
};

export default ReportItem;
