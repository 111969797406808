import React from "react";
import { createRoot } from "react-dom/client";
import { ThemeProvider } from "@mui/system";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import App from "./App";
import store, { persistor } from "./store";
import theme from "./theme";
import "./index.css";
import "./assets/styles/datepicker.css";

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </ThemeProvider>
);
