import { Box } from "@mui/material";
import React from "react";
import ModalWrapper from "./ModalWrapper";
import PrivacyPolicyText from "../../pages/common/PrivacyPolicyText";

interface ModalProps {
  closeModal: () => void;
  isOpen: boolean;
  modalActionType: {
    action: string;
    page: string;
  };
}

const PrivacyPolicyModal: React.FC<ModalProps> = ({
  isOpen,
  modalActionType,
  closeModal,
}) => {
  return (
    <>
      <ModalWrapper
        closeModal={closeModal}
        isOpen={isOpen}
        maxWidth="750px"
        modalActionType={modalActionType}
        withCloseBtn
      >
        <Box px="10px" overflow="auto">
          <PrivacyPolicyText></PrivacyPolicyText>
        </Box>
      </ModalWrapper>
    </>
  );
};

export default PrivacyPolicyModal;
