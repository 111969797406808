import { Box, Theme } from "@mui/material";
import React, { useRef } from "react";
import { SecurityForm } from "src/components";

const styles = {
  tabsDataItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    mt: "20px",
  },
  tabsDataTime: {
    color: (theme: Theme) => `${theme.palette.text.primary}`,
    fontSize: "16px",
    fontWeight: 500,
    minWidth: "28%",
  },
  tabsDataPeriod: {
    color: (theme: Theme) => `${theme.palette.text.secondary}`,
    fontSize: "14px",
    fontWeight: 500,
  },
  tabsDataButton: {
    backgroundColor: (theme: Theme) => `${theme.palette.background.paper}`,
    border: (theme: Theme) => `1px solid ${theme.palette.background.default}`,
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "44px",
    minHeight: "44px",
  },
  cardWrapper: {
    mt: "35px",
    px: {
      xs: "24px",
      md: "30px",
    },
    py: "50px",
    minWidth: {
      md: "400px",
    },
  },
  form: {
    width: "100%",
  },
};

const TabChangePassword: React.FC = () => {
  const childRef = useRef<() => void>();

  return (
    <>
      <Box sx={styles.tabsDataItem}>
        <SecurityForm ref={childRef} />
      </Box>
    </>
  );
};

export default TabChangePassword;
