import React from "react";

interface Props {
  width?: number | string;
  height?: number | string;
}

const CheckBoxIndeterminate: React.FC<Props> = ({
  width = 24,
  height = 24,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6365_3474)">
        <rect
          x="0.5"
          y="0.5"
          width="19"
          height="19"
          rx="1.5"
          fill="white"
          stroke="#D9D9D9"
        />
        <rect x="5" y="5" width="10" height="10" fill="#0D6FF0" />
      </g>
      <defs>
        <clipPath id="clip0_6365_3474">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CheckBoxIndeterminate;
