import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DeleteForWeb } from "src/assets/icons";
import { AppBar } from "src/components";
import Button from "src/components/Button";
import { getUserSelector } from "src/store/users/selectors";
import { getProjectSelector } from "src/store/projects/selectors";
import { ProjectAll, User } from "src/types";
import { styles } from "./report.module";
import { format } from "date-fns";
import { addReportsFilter } from "src/store/report/actions";
import { getReportSelector } from "src/store/report/selectors";
import DatePicker from "react-datepicker";
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  ArrowDropDown,
} from "@mui/icons-material";
import { AppRoutes } from "src/constants";
import { useNavigate } from "react-router-dom";
import { formatDate } from "src/utils";
import { fetchProjectAllRequest } from "src/store/projects/actions";
import { fetchUsersRequest } from "src/store/users/actions";

const ITEM_HEIGHT = 36;
const MOBILE_ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MENU_ITEMS = 5;

const ReportFilters: React.FC = () => {
  const navigate = useNavigate();
  const projectState = useSelector(getProjectSelector);
  const userState = useSelector(getUserSelector);
  const reportState = useSelector(getReportSelector);
  const dispatch = useDispatch();
  const [userIds, setUserIds] = useState(
    reportState.report?.searchParameters?.userIds
      ? reportState.report?.searchParameters?.userIds
      : []
  );
  const [projectIds, setProjectIds] = useState(
    reportState.report?.searchParameters?.projectIds
      ? reportState.report?.searchParameters?.projectIds
      : []
  );
  const [selectedDate, setSelectedDate] = useState([
    reportState?.report?.searchParameters?.startDate
      ? formatDate(reportState?.report?.searchParameters?.startDate)
      : new Date(
          new Date().setDate(new Date().getDate() - new Date().getDay())
        ),
    reportState?.report?.searchParameters?.endDate
      ? formatDate(reportState?.report?.searchParameters?.endDate)
      : new Date(
          new Date().setDate(new Date().getDate() - new Date().getDay() + 6)
        ),
  ]);

  const handleUserChange = (event: SelectChangeEvent<typeof userIds>) => {
    setUserIds(
      typeof event.target.value === "string"
        ? event.target.value.split(",")
        : event.target.value
    );
    setSelectedDate((prevState) => [...prevState]);
  };

  const handleProjectChange = (event: SelectChangeEvent<typeof projectIds>) => {
    event.stopPropagation();
    setProjectIds(
      typeof event.target.value === "string"
        ? event.target.value.split(",")
        : event.target.value
    );
  };

  const applyFilters = () => {
    dispatch(
      addReportsFilter({
        searchParameters: {
          startDate: format(selectedDate[0], "y-MM-dd"),
          endDate: format(selectedDate[1], "y-MM-dd"),
          userIds: userIds.length ? userIds : null,
          projectIds: projectIds.length ? projectIds : null,
        },
      })
    );
    navigate(AppRoutes.Report);
  };

  useEffect(() => {
    dispatch(fetchProjectAllRequest());
    dispatch(
      fetchUsersRequest({
        currentPage: 1,
        pageSize: 1000,
        sortBy: "firstName",
        sortOrder: "asc",
      })
    );
  }, []);

  return (
    <AppBar>
      <>
        <Box sx={styles.boxContainer}>
          <FormControl sx={styles.filter}>
            <InputLabel sx={{ color: "#262626" }} id="filterByName">
              Filter by Name
            </InputLabel>
            <Select
              labelId="filterByNameLabel"
              id="filterByName"
              multiple
              name="filterName"
              value={userIds}
              onChange={handleUserChange}
              input={
                <OutlinedInput id="filterByNameLabel" label="Filter by Name" />
              }
              MenuProps={{
                PaperProps: {
                  sx: {
                    maxHeight: {
                      xs: MOBILE_ITEM_HEIGHT * MENU_ITEMS + ITEM_PADDING_TOP,
                      sm: ITEM_HEIGHT * MENU_ITEMS + ITEM_PADDING_TOP,
                    },
                  },
                },
              }}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip
                      key={value}
                      label={`${
                        userState.users.find((el: User) => el.id === value)
                          .firstName
                      } ${
                        userState.users.find((el: User) => el.id === value)
                          .lastName
                      }`}
                    />
                  ))}
                </Box>
              )}
            >
              {userState.users?.map((user: User) => (
                <MenuItem key={user.id} value={user.id}>
                  {`${user.firstName} ${user.lastName}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mt: "10px" }}>
            <Box sx={[styles.dateStyle, { height: "56px" }]} className="report">
              <Box
                sx={[
                  styles.filterItemWrapper,
                  {
                    ml: "10px",
                    position: "absolute",
                    top: "50%",
                    transform: "translateY(-50%)",
                    backgroundColor: "#F0F0F0",
                  },
                ]}
              >
                <Typography fontSize="12px" color="#262626">
                  {`${format(new Date(selectedDate[0]), "MMMM d, y")} — ${
                    selectedDate[1]
                      ? format(new Date(selectedDate[1]), "MMMM d, y")
                      : ""
                  }`}
                </Typography>
              </Box>
              <ArrowDropDown sx={styles.arrowDateIcon} color="action" />
              <DatePicker
                selectsRange={true}
                dateFormat="y-MM-dd"
                androidVariant="iosClone"
                startDate={selectedDate[0]}
                endDate={selectedDate[1]}
                onFocus={(e) => e.target.blur()}
                onChange={(update) => {
                  setSelectedDate(update);
                }}
                popperClassName="calendarPicker"
                formatWeekDay={(nameOfDay) => nameOfDay.toString().substr(0, 3)}
                renderCustomHeader={({
                  monthDate,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled,
                }) => (
                  <div
                    style={{
                      margin: 10,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <button
                      className="btn prev"
                      onClick={decreaseMonth}
                      disabled={prevMonthButtonDisabled}
                    >
                      {<KeyboardArrowLeft />}
                    </button>
                    <span className="react-datepicker__current-month">
                      {monthDate.toLocaleString("en-US", {
                        month: "long",
                        year: "numeric",
                      })}
                    </span>
                    <button
                      className="btn next"
                      onClick={increaseMonth}
                      disabled={nextMonthButtonDisabled}
                    >
                      {<KeyboardArrowRight />}
                    </button>
                  </div>
                )}
              />
            </Box>
          </FormControl>
          <FormControl margin="normal" sx={styles.filter}>
            <InputLabel
              sx={{ color: "#262626", zIndex: 0 }}
              id="filterByProject"
            >
              Filter by Project
            </InputLabel>
            <Select
              labelId="filterByProjectLabel"
              id="filterByProject"
              name="filterProject"
              multiple
              value={projectIds}
              onChange={handleProjectChange}
              input={
                <OutlinedInput
                  id="filterByProjectLabel"
                  label="Filter by Project"
                />
              }
              MenuProps={{
                PaperProps: {
                  sx: {
                    maxHeight: {
                      xs: MOBILE_ITEM_HEIGHT * MENU_ITEMS + ITEM_PADDING_TOP,
                      sm: ITEM_HEIGHT * MENU_ITEMS + ITEM_PADDING_TOP,
                    },
                  },
                },
              }}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip
                      key={value}
                      label={projectState.projectAll.map((el: ProjectAll) => {
                        if (el.subProjects) {
                          if (el.id === value) {
                            return el.projectName;
                          }
                          return el.subProjects.map((item) => {
                            if (item.id === value) {
                              return item.projectName;
                            }
                          });
                        } else {
                          if (el.id === value) return el.projectName;
                        }
                      })}
                    />
                  ))}
                </Box>
              )}
            >
              {projectState.projectAll?.map((project: ProjectAll) => {
                if (project?.subProjects) {
                  let arrProjectGroup = [...project.subProjects];
                  arrProjectGroup.unshift(project);
                  return arrProjectGroup.map(
                    (subproject: ProjectAll, i: number) => {
                      return (
                        <MenuItem
                          sx={{ pl: i ? "30px" : "16px" }}
                          key={subproject.id}
                          value={subproject.id}
                        >
                          {`${subproject.projectName} (${subproject.numericId})`}
                        </MenuItem>
                      );
                    }
                  );
                } else
                  return (
                    <MenuItem key={project.id} value={project.id}>
                      {`${project.projectName} (${project.numericId})`}
                    </MenuItem>
                  );
              })}
            </Select>
          </FormControl>
          <Button
            style={{ marginTop: "10px" }}
            fullWidth
            size="medium"
            variant="contained"
            color="primary"
            disabled={!selectedDate[1]}
            onClick={applyFilters}
          >
            <Typography>Apply</Typography>
          </Button>
          <Box mt="10px">
            <Button
              variant="outlined"
              size="medium"
              color="error"
              fullWidth
              onClick={() => {
                setUserIds([]);
                setProjectIds([]);
                setSelectedDate([
                  new Date(
                    new Date().setDate(
                      new Date().getDate() - new Date().getDay()
                    )
                  ),
                  new Date(
                    new Date().setDate(
                      new Date().getDate() - new Date().getDay() + 6
                    )
                  ),
                ]);
              }}
            >
              <DeleteForWeb />
              <Typography ml="12px" fontSize="14px" color="#FF0000">
                Clear Filters
              </Typography>
            </Button>
          </Box>
        </Box>
      </>
    </AppBar>
  );
};

export default ReportFilters;
