import React, { useCallback } from "react";
import { Container, Typography, Card, Box, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Button } from "src/components";
import { AppRoutes } from "src/constants";
import { isMobile } from "mobile-device-detect";
import { Capacitor } from "@capacitor/core";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    paddingTop: "24px",
    paddingBottom: "24px",
  },
  cardWrapper: {
    mt: "35px",
    px: {
      xs: "24px",
      md: "30px",
    },
    py: "30px",
    minWidth: {
      md: "400px",
    },
  },
  headerWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  subText: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#8C8C8C",
  },
  buttonText: {
    fontSize: "16px",
    fontWeight: 400,
  },
};

const Welcome = () => {
  const navigate = useNavigate();

  const openModal = () => {
    if (isMobile) {
      redirectToUpgradePlanMobile();
    } else {
      redirectToUpgradePlan();
    }
    sessionStorage.setItem("upgradePlan", "true");
  };

  const redirectToUpgradePlanMobile = useCallback(() => {
    navigate(AppRoutes.SettingsPlan);
  }, [navigate]);

  const redirectToUpgradePlan = useCallback(() => {
    navigate(AppRoutes.Settings, {
      state: { id: "2" },
    });
  }, [navigate]);

  const redirectToSignIn = useCallback(() => {
    navigate(AppRoutes.SignIn);
  }, [navigate]);

  const redirectToPrivacyPolicy = useCallback(() => {
    navigate(AppRoutes.PrivacyPolicy);
  }, [navigate]);

  const redirectToTermsConditions = useCallback(() => {
    navigate(AppRoutes.TermsConditions);
  }, [navigate]);

  return (
    <Container sx={styles.container} maxWidth="sm">
      <Typography
        data-sqa-id="page-title"
        color="primary"
        fontWeight="800"
        fontSize={{ xs: "24px", md: "36px" }}
      >
        BusyBucket
      </Typography>
      <Card variant="outlined" sx={styles.cardWrapper}>
        <Box sx={styles.headerWrapper}>
          <Typography
            fontWeight="bold"
            fontSize={{ xs: "20px", md: "24px" }}
            mb="30px"
          >
            Welcome to BusyBucket!
          </Typography>
          <Typography sx={styles.subText}>
            We are happy that you signed up. Your 14 days trial starts now! Feel
            free to contact us with any questions during your trial period.
          </Typography>
        </Box>
        <Box mt="30px">
          <Button
            variant="contained"
            color="primary"
            fullWidth
            size="small"
            onClick={redirectToSignIn}
          >
            <Typography color="#FFF">Continue to Trial</Typography>
          </Button>
        </Box>
        {Capacitor.getPlatform() === "ios" ? null : (
          <Box mt="10px">
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              size="small"
              onClick={openModal}
            >
              <Typography color="primary" sx={styles.buttonText}>
                Upgrade to Paid Plan
              </Typography>
            </Button>
          </Box>
        )}
      </Card>
      <Link
        data-sqa-id="terms-conditions-footer-link"
        mt="30px"
        variant="body1"
        component="button"
        fontSize={{ xs: "12px", md: "14px" }}
        color="secondary"
        sx={{ marginTop: "30px" }}
        onClick={redirectToTermsConditions}
      >
        Terms of Service
      </Link>
      <Link
        variant="body1"
        color="secondary"
        component="button"
        fontSize={{ xs: "12px", md: "14px" }}
        sx={{ marginTop: "20px" }}
        onClick={redirectToPrivacyPolicy}
      >
        Privacy Policy
      </Link>
    </Container>
  );
};

export default Welcome;
