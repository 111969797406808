import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  fetchUsers as fetchUsersApi,
  searchUser as searchUserApi,
  getUser as getUserApi,
  addUser as addUserApi,
  updateUser as updateUserApi,
  deleteUser as deleteUserApi,
} from "./api";
import {
  CREATE_USER_REQUEST,
  DELETE_USER_REQUEST,
  FETCH_USERS_REQUEST,
  GET_USER_REQUEST,
  SEARCH_USER_REQUEST,
  UPDATE_USER_REQUEST,
} from "./actionTypes";
import { AxiosResponse } from "axios";
import {
  addUserFailure,
  addUserSuccess,
  deleteUserFailure,
  deleteUserSuccess,
  fetchUsersFailure,
  fetchUsersSuccess,
  getUserFailure,
  getUserSuccess,
  updateUserFailure,
  updateUserSuccess,
} from "./actions";
import {
  AddUserRequest,
  DeleteUserRequest,
  FetchUsersRequest,
  GetUserRequest,
  SearchUserRequest,
  UpdateUserRequest,
} from "./types";
import { User, UserID } from "src/types";

function* fetchUsersSaga({ payload, callbacks }: FetchUsersRequest) {
  try {
    const response: AxiosResponse<User[]> = yield call(fetchUsersApi, payload);

    yield put(
      fetchUsersSuccess({
        users: response.data,
      })
    );
    if (callbacks?.onSuccess) {
      yield call(callbacks.onSuccess);
    }
  } catch (e) {
    yield put(
      fetchUsersFailure({
        // @ts-ignore
        error: e?.message,
      })
    );
  }
}

function* searchUserSaga({ payload, callbacks }: SearchUserRequest) {
  try {
    const response: AxiosResponse<User[]> = yield call(searchUserApi, payload);

    yield put(
      fetchUsersSuccess({
        users: response.data,
      })
    );
    if (callbacks?.onSuccess) {
      yield call(callbacks.onSuccess);
    }
  } catch (e) {
    yield put(
      fetchUsersFailure({
        // @ts-ignore
        error: e?.message,
      })
    );
  }
}

function* getUserSaga({ payload }: GetUserRequest) {
  try {
    const response: AxiosResponse<User> = yield call(getUserApi, payload);

    yield put(
      getUserSuccess({
        user: response.data,
      })
    );
  } catch (e) {
    yield put(
      getUserFailure({
        // @ts-ignore
        error: e?.message,
      })
    );
  }
}

function* addUserSaga({ payload, callbacks }: AddUserRequest) {
  try {
    const response: AxiosResponse<User> = yield call(addUserApi, payload);

    yield put(
      addUserSuccess({
        user: response.data,
      })
    );
    if (callbacks?.onSuccess) {
      yield call(callbacks.onSuccess);
    }
  } catch (e) {
    yield put(
      addUserFailure({
        // @ts-ignore
        error: e?.response?.data?.message,
      })
    );
  }
}

function* updateUserSaga({ payload, callbacks }: UpdateUserRequest) {
  try {
    const response: AxiosResponse<User> = yield call(updateUserApi, payload);

    yield put(
      updateUserSuccess({
        user: response.data,
      })
    );
    if (callbacks?.onSuccess) {
      yield call(callbacks.onSuccess);
    }
  } catch (e) {
    yield put(
      updateUserFailure({
        // @ts-ignore
        error: e?.message,
      })
    );
  }
}

function* deleteUserSaga({ payload, callbacks }: DeleteUserRequest) {
  try {
    const response: AxiosResponse<UserID> = yield call(deleteUserApi, payload);

    yield put(
      deleteUserSuccess({
        data: response.data,
      })
    );
    if (callbacks?.onSuccess) {
      yield call(callbacks.onSuccess);
    }
  } catch (e) {
    yield put(
      deleteUserFailure({
        // @ts-ignore

        error: e?.response.data.message,
      })
    );
  }
}

function* watchSaga() {
  yield all([
    takeLatest(FETCH_USERS_REQUEST, fetchUsersSaga),
    takeLatest(SEARCH_USER_REQUEST, searchUserSaga),
    takeLatest(GET_USER_REQUEST, getUserSaga),
    takeLatest(CREATE_USER_REQUEST, addUserSaga),
    takeLatest(UPDATE_USER_REQUEST, updateUserSaga),
    takeLatest(DELETE_USER_REQUEST, deleteUserSaga),
  ]);
}

export default watchSaga;
