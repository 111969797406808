import * as React from "react";
import { SVGProps } from "react";

interface Props {
  color: string;
}

const EditForWeb: React.FC<Props> = (
  { color },
  props: SVGProps<SVGSVGElement>
) => (
  <svg data-sqa-id="edit-icon" width={20} height={20} fill="none" {...props}>
    <path
      d="M4.252 15.286c.045 0 .09-.005.134-.011l3.755-.659a.219.219 0 0 0 .118-.062l9.462-9.462a.222.222 0 0 0 0-.315l-3.71-3.712A.221.221 0 0 0 13.853 1a.22.22 0 0 0-.159.065l-9.462 9.462a.227.227 0 0 0-.062.118L3.51 14.4a.748.748 0 0 0 .21.665.756.756 0 0 0 .531.22Zm1.505-3.893 8.096-8.094 1.636 1.636-8.096 8.094-1.985.35.349-1.986Zm12.386 5.768H1.714a.714.714 0 0 0-.714.714v.804c0 .098.08.178.179.178h17.5c.098 0 .178-.08.178-.178v-.804a.714.714 0 0 0-.714-.714Z"
      fill={color}
    />
  </svg>
);

export default EditForWeb;
