import {
  Avatar,
  Box,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getReportSelector } from "src/store/report/selectors";
import { ProjectBreakdown, UserBreakdown } from "src/types";
import { secondsToTime } from "src/utils";
import InfoIcon from "@mui/icons-material/Info";
import ReportTooltipText from "./TooltipText/ReportTooltipText";
import { isMobile } from "mobile-device-detect";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Button from "./Button";
import theme from "src/theme";
import Tooltip from "./Tooltip";

const styles = {
  lastBorderNone: {
    borderBottom: "1px solid #F0F0F0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: isMobile ? "flex-start" : "center",
    p: "14px 20px",
    "&:last-child": {
      borderBottom: "none",
    },
  },
  boxBlock: {
    width: "fit-content",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#ECF3FE",
    borderRadius: "4px",
    padding: "5px 10px",
  },
};

interface ModalProps {
  closeModal: () => void;
}

const CurrentReportBreakdown: React.FC<ModalProps> = ({ closeModal }) => {
  const reportState = useSelector(getReportSelector);
  const [hideUsers, setHideUsers] = useState(false);
  const [hideProjects, setHideProjects] = useState(false);

  function stringAvatar(name) {
    return {
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }

  return (
    <>
      {reportState.isBreakdownLoading ? (
        <Box
          data-sqa-id="circular-progress-loader"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box
          display="flex"
          flexDirection={isMobile ? "column" : "row"}
          justifyContent={isMobile ? "flex-start" : "space-between"}
          alignItems="flex-start"
          overflow="auto"
          height="450px"
        >
          <Box border="1px solid #F0F0F0" width={isMobile ? "100%" : "48%"}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p="20px"
              borderBottom="1px solid #F0F0F0"
              onClick={() => {
                if (isMobile) setHideUsers(!hideUsers);
              }}
            >
              <Typography fontSize="16px" fontWeight={500} color="#000">
                Employees Breakdown
              </Typography>
              {hideUsers
                ? isMobile && <ArrowDropDownIcon color="secondary" />
                : isMobile && <ArrowDropUpIcon color="secondary" />}
            </Box>
            <Box display={!hideUsers ? "block" : "none"}>
              {reportState.usersBreakdown.map((elem: UserBreakdown) => (
                <Box
                  sx={styles.lastBorderNone}
                  flexDirection={isMobile ? "column" : "row"}
                  key={elem.user.id}
                >
                  <Box display="flex" alignItems="center">
                    <Avatar
                      sx={{
                        width: isMobile ? "54px" : "32px",
                        height: isMobile ? "54px" : "32px",
                        fontSize: "14px",
                      }}
                      src={elem.user?.profilePictureUrl}
                      {...stringAvatar(
                        `${elem.user.firstName} ${elem.user.lastName}`
                      )}
                    />
                    <Box ml="10px">
                      <Typography
                        fontSize={{ xs: "14px", md: "16px" }}
                        fontWeight={400}
                        color="#262626"
                      >
                        {`${elem.user.firstName} ${elem.user.lastName}`}
                      </Typography>
                      {isMobile && (
                        <Box display="flex" columnGap="10px" mt="10px">
                          <Box sx={styles.boxBlock}>
                            <Typography fontSize="12px" color="#0D6FF0">
                              $
                              {elem.totalEarned.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                              })}
                            </Typography>
                          </Box>
                          <Box sx={styles.boxBlock}>
                            <Typography fontSize="12px" color="#0D6FF0">
                              {secondsToTime(elem.secondsTotalWorked)}
                            </Typography>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Box>
                  {!isMobile && (
                    <Typography
                      fontSize="16px"
                      fontWeight={400}
                      color="#262626"
                      display="flex"
                      alignItems="center"
                    >
                      $
                      {elem.totalEarned.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })}
                      {!elem.hourlyRateVaries &&
                      !elem.recordsMissingHourlyRate &&
                      !elem.inProgressClockIn ? null : (
                        <Tooltip
                          placement="right"
                          title={<ReportTooltipText elem={elem} />}
                        >
                          <IconButton>
                            <InfoIcon color="disabled" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Typography>
                  )}
                  {!isMobile && (
                    <Typography
                      fontSize="16px"
                      fontWeight={400}
                      color="#0D6FF0"
                    >
                      {secondsToTime(elem.secondsTotalWorked)}
                    </Typography>
                  )}
                </Box>
              ))}
            </Box>
          </Box>
          <Box
            border="1px solid #F0F0F0"
            width={isMobile ? "100%" : "48%"}
            mt={isMobile ? "20px" : 0}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p="20px"
              borderBottom="1px solid #F0F0F0"
              onClick={() => setHideProjects(!hideProjects)}
            >
              <Typography fontSize="16px" fontWeight={500} color="#000">
                Project Breakdown
              </Typography>
              {hideProjects
                ? isMobile && <ArrowDropDownIcon color="secondary" />
                : isMobile && <ArrowDropUpIcon color="secondary" />}
            </Box>
            <Box display={!hideProjects ? "block" : "none"}>
              {reportState.projectsBreakdown.map((elem: ProjectBreakdown) => (
                <Box
                  key={elem.project.id}
                  flexDirection={isMobile ? "column" : "row"}
                  sx={styles.lastBorderNone}
                >
                  <Box>
                    <Typography
                      fontSize="12px"
                      fontWeight={400}
                      color="#8C8C8C"
                    >
                      Project ID {elem.project.numericId}
                    </Typography>
                    <Typography
                      fontSize="16px"
                      fontWeight={400}
                      color="#262626"
                    >
                      {elem.project.projectName}
                    </Typography>
                  </Box>
                  <Box
                    sx={isMobile ? styles.boxBlock : null}
                    mt={isMobile ? "10px" : 0}
                  >
                    <Typography
                      fontSize={{ xs: "12px", md: "16px" }}
                      fontWeight={400}
                      color="#0D6FF0"
                    >
                      {secondsToTime(elem.secondsTotalWorked)}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      )}
      <Box display="flex" justifyContent="flex-end">
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          style={{
            padding: "10px 20px",
            boxShadow: "none",
            marginTop: "20px",
            width: isMobile ? "100%" : "10%",
          }}
          onClick={closeModal}
        >
          <Typography fontSize="14px" color={theme.palette.text.primary}>
            Close
          </Typography>
        </Button>
      </Box>
    </>
  );
};

export default CurrentReportBreakdown;
