import axios from "../../utils/axios";
import {
  ContactUsRequestPayload,
  GetInvoiceIDRequestPayload,
  StartSubscriptionRequestPayload,
  UpdatePaymentRequestPayload,
  UpdateSubscriptionRequestPayload,
} from "./types";

export const contactUs = (payload: ContactUsRequestPayload) => {
  return axios.post("/contactUs", payload);
};

export const getInvoice = () => {
  return axios.get("/invoice");
};

export const getInvoiceID = (payload: GetInvoiceIDRequestPayload) => {
  return axios.get(`/invoice/${payload.id}`);
};

export const getSubscription = () => {
  return axios.get("/subscription");
};

export const startSubscription = (payload: StartSubscriptionRequestPayload) => {
  return axios.post("/subscription", payload);
};

export const updateSubscription = (
  payload: UpdateSubscriptionRequestPayload
) => {
  return axios.put("/subscription", payload);
};

export const deleteSubscription = () => {
  return axios.delete("/subscription");
};

export const updatePayment = (payload: UpdatePaymentRequestPayload) => {
  return axios.post("/subscription/updatePayment", payload);
};
