import React, { useEffect, useState } from "react";
import { format, addDays, subDays } from "date-fns";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import { AppRoutes } from "src/constants";
import { Back, Calendar } from "src/assets/icons";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { styles } from "./dayCalendar.module";
import Button from "../Button";
import { formatDate } from "src/utils";

interface Props {
  date: string;
}

const DayCalendar: React.FC<Props> = ({ date }) => {
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState(formatDate(date));

  useEffect(() => {
    setSelectedDate(formatDate(date));
  }, [date]);

  const changeWeekHandle = (btnType: string) => {
    if (btnType === "prev") {
      setSelectedDate((date) => {
        return subDays(date, 1);
      });
      navigate(
        AppRoutes.HistoryDay + `/${format(subDays(selectedDate, 1), "y-MM-dd")}`
      );
    }
    if (btnType === "next") {
      setSelectedDate((date) => {
        return addDays(date, 1);
      });
      navigate(
        AppRoutes.HistoryDay + `/${format(addDays(selectedDate, 1), "y-MM-dd")}`
      );
    }
  };

  const onDateClickHandle = (day: Date) => {
    setSelectedDate(day);
    navigate(AppRoutes.HistoryDay + `/${format(day, "y-MM-dd")}`);
  };

  return (
    <Box>
      <Box
        data-sqa-id="top-section"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ marginBottom: "31px" }}
      >
        <Typography data-sqa-id="page-title-date" sx={styles.historyDay}>
          {format(selectedDate, "MMMM d, yyyy")}
        </Typography>

        <Box
          data-sqa-id="calendar-box"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          columnGap="15px"
        >
          <Box
            data-sqa-id="left-calendar-btn"
            sx={[styles.weekContainer]}
            onClick={() => changeWeekHandle("prev")}
          >
            <Back width={22} height={10} color="#0D6FF0" />
          </Box>
          <Box
            data-sqa-id="calendar-btn-box"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ position: "relative" }}
          >
            <Button
              data-sqa-id="calendar-btn"
              size="small"
              color="primary"
              variant="contained"
            >
              <Calendar color="#FFF" size="16" />
              <Typography
                data-sqa-id="calendar-btn-text"
                fontSize={{ xs: "14px", md: "16px" }}
                ml={"10px"}
              >
                {format(selectedDate, "MMMM d, yyyy")}
              </Typography>
            </Button>
            <Box
              sx={{
                width: "280px",
                opacity: 1,
                position: "absolute",
                right: "-55px",
              }}
              className="history-calendar"
            >
              <DatePicker
                selected={selectedDate}
                dateFormat="MMMM d, y"
                onChange={(date) => onDateClickHandle(date)}
                popperClassName="calendarPicker modalPicker dayCalendarClass"
                formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
                renderCustomHeader={({
                  monthDate,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled,
                }) => (
                  <div
                    style={{
                      margin: 10,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <button
                      className="btn prev"
                      onClick={decreaseMonth}
                      disabled={prevMonthButtonDisabled}
                    >
                      {<KeyboardArrowLeft />}
                    </button>
                    <span className="react-datepicker__current-month">
                      {monthDate.toLocaleString("en-US", {
                        month: "long",
                        year: "numeric",
                      })}
                    </span>
                    <button
                      className="btn next"
                      onClick={increaseMonth}
                      disabled={nextMonthButtonDisabled}
                    >
                      {<KeyboardArrowRight />}
                    </button>
                  </div>
                )}
              />
            </Box>
          </Box>
          <Box
            data-sqa-id="right-calendar-btn"
            sx={[styles.weekContainer, { transform: "rotate(180deg)" }]}
            onClick={() => changeWeekHandle("next")}
          >
            <Back width={22} height={10} color="#0D6FF0" />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DayCalendar;
