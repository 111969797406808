import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ActionWithUser from "src/components/ActionsModal/ActionWithUser";
import { fetchUsersRequest, searchUserRequest } from "src/store/users/actions";
import DeviceWrapper from "src/pages/DeviceWrapper";
import TeamWeb from "./TeamWeb";
import { isMobile } from "mobile-device-detect";
import TeamMobile from "./TeamMobile";
import { getOrganizationRequest } from "src/store/auth/actions";

const Team = () => {
  const [page, setPage] = useState<number>(1);
  const pageSize: number = 10;
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortBy, setSortBy] = useState("firstName");
  const [sortBySearch, setSortBySearch] = useState("first_name");
  const [sortByTableName, setSortByTableName] = useState("Name");
  const [searchTeamValue, setSearchTeamValue] = useState("");
  const [openActionModal, setOpenActionModal] = useState(false);
  const modalActionType = {
    action: "Add",
    page: "User",
  };
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const fetchUsers = () => {
    dispatch(
      fetchUsersRequest(
        {
          currentPage: isMobile ? 1 : page,
          pageSize: isMobile ? page * pageSize : pageSize,
          sortBy: sortBy,
          sortOrder: sortOrder,
        },
        {
          onSuccess: () => setIsLoading(false),
        }
      )
    );
  };

  useEffect(() => {
    if (isMobile && page !== 1) setIsLoading(false);
    else setIsLoading(true);
    if (searchTeamValue) {
      const timer = setTimeout(() => {
        dispatch(
          searchUserRequest(
            {
              currentPage: isMobile ? 1 : page,
              pageSize: isMobile ? page * pageSize : pageSize,
              sortBy: sortBySearch,
              sortOrder: sortOrder,
              searchQuery: searchTeamValue,
            },
            {
              onSuccess: () => setIsLoading(false),
            }
          )
        );
      }, 1000);
      return () => clearTimeout(timer);
    } else {
      fetchUsers();
    }
  }, [searchTeamValue, page, sortBy, sortOrder]);

  const requestSort = (elem: string) => {
    if (elem === "Name") {
      setSortBy("firstName");
      setSortBySearch("first_name");
      setSortByTableName("Name");
    }
    if (elem === "Email") {
      setSortBy("email");
      setSortBySearch("email");
      setSortByTableName("Email");
    }
    if (elem === "Rate") {
      setSortBy("hourlyRate");
      setSortBySearch("hourly_rate");
      setSortByTableName("Rate");
    }
    if (elem === "User Type") {
      setSortBy("isAdmin");
      setSortBySearch("is_admin");
      setSortByTableName("User Type");
    }
  };

  useEffect(() => {
    dispatch(getOrganizationRequest());
  }, []);

  return (
    <>
      <DeviceWrapper>
        <>
          {isMobile ? (
            <TeamMobile
              searchTeamValue={searchTeamValue}
              pageSize={pageSize}
              setPage={setPage}
              setSearchTeamValue={setSearchTeamValue}
              setSortBySearch={setSortBySearch}
              sortBySearch={sortBySearch}
              setSortOrder={setSortOrder}
              sortOrder={sortOrder}
              setSortBy={setSortBy}
              sortBy={sortBy}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              fetchUsers={fetchUsers}
            />
          ) : (
            <TeamWeb
              searchTeamValue={searchTeamValue}
              page={page}
              sortOrder={sortOrder}
              sortByTableName={sortByTableName}
              isLoading={isLoading}
              setPage={setPage}
              setSearchTeamValue={setSearchTeamValue}
              setOpenActionModal={setOpenActionModal}
              handleChangePage={handleChangePage}
              fetchUsers={fetchUsers}
              requestSort={requestSort}
              setSortOrder={setSortOrder}
            />
          )}
        </>
      </DeviceWrapper>

      {openActionModal && (
        <ActionWithUser
          modalActionType={modalActionType}
          fetchSuccess={fetchUsers}
          isOpen={openActionModal}
          closeModal={() => setOpenActionModal(false)}
        />
      )}
    </>
  );
};

export default Team;
