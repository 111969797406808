import React from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  Divider,
  FormControl,
  MenuItem,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SelectChangeEvent,
} from "@mui/material";
import theme from "src/theme";
import { CheckCircle as CheckCircleIcon } from "src/assets/icons";
import Select from "./Select";
import Button from "./Button";
import { useDispatch, useSelector } from "react-redux";
import { getSubscriptionSelector } from "src/store/subscription/selectors";
import { isMobile } from "mobile-device-detect";
import { AppRoutes } from "src/constants";
import { useNavigate } from "react-router-dom";
import { getOrganizationSelector } from "src/store/auth/selectors";
import {
  getInvoiceRequest,
  getSubscriptionRequest,
  updateSubscriptionRequest,
} from "src/store/subscription/actions";
import { toast } from "react-toastify";

const styles = {
  significant: {
    display: "inline-flex",
    p: "6px 16px",
    borderRadius: "4px",
  },
  planSwitcher: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ".switch": {
      margin: "0 10px",
      flexShrink: 0,
    },
  },
  planCard: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    boxShadow: "none",
    border: `1px solid ${theme.palette.background.default}`,
  },
  listCard: {
    p: "20px",
    display: "flex",
    flexDirection: "column",
    height: "inherit",
  },
  list: {
    p: 0,
    mb: "20px",
    ".MuiListItemIcon-root": {
      minWidth: "auto",
      marginRight: "12px",
    },
    ".MuiListItemText-root": {
      m: 0,
      span: {
        fontSize: { xs: "12px", md: "14px" },
      },
    },
  },
};

const paidPlan = [
  "Web app",
  "Mobile app",
  "Full access to features",
  "Unlimited report history",
  "Unlimited attachments",
  "Basic support",
];

const customPlan = [
  "Web app",
  "Mobile app",
  "Full access to features",
  "Unlimited report history",
  "Unlimited attachments",
  "Premium 24x7 support",
  "Payroll software integration",
  "On-demand custom integrations and features",
];

const users = ["2", "3", "4", "5", "10", "15", "20", "25", "30", "40", "50"];

interface Props {
  setModalActionType: any;
  user: number;
  setUser: any;
  setOpenContactUsModal?: () => void;
  closeModal?: () => void;
}

const PaidPlans: React.FC<Props> = ({
  user,
  setUser,
  setModalActionType,
  setOpenContactUsModal,
  closeModal,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const subscription = useSelector(getSubscriptionSelector);
  const organization = useSelector(getOrganizationSelector);

  const handleChange = (event: SelectChangeEvent) => {
    setUser(event.target.value as string);
  };

  const onSuccess = () => {
    dispatch(getSubscriptionRequest());
    dispatch(getInvoiceRequest());
    if (!isMobile) closeModal();
    else {
      navigate(AppRoutes.Settings);
      sessionStorage.setItem("activeTab", "2");
    }
    toast.success(
      <>
        <Typography fontSize="16px" color="#262626">
          Subscription updated
        </Typography>
      </>
    );
  };

  const handleUpgradePlan = () => {
    if (isMobile) {
      if (
        organization?.trial ||
        subscription.subscriptions?.[0]?.status === "canceled"
      ) {
        navigate(AppRoutes.PaidPlanPurchase, {
          state: { user },
        });
      } else if (
        Number(user) ===
        subscription.subscriptions?.[0]?.subscriptionUsersNumber
      ) {
        return;
      } else {
        dispatch(
          updateSubscriptionRequest(
            {
              subscriptionUsersNumber: Number(user),
              billingPeriod: "monthly",
            },
            {
              onSuccess,
            }
          )
        );
      }
    } else {
      if (
        organization?.trial ||
        subscription.subscriptions?.[0]?.status === "canceled" ||
        (Array.isArray(subscription.subscriptions) &&
          subscription.subscriptions.length === 0)
      ) {
        setModalActionType((prevState) => ({
          ...prevState,
          action: "Paid Plan Purchase",
        }));
      } else if (
        Number(user) ===
        subscription.subscriptions?.[0]?.subscriptionUsersNumber
      ) {
        return;
      } else {
        dispatch(
          updateSubscriptionRequest(
            {
              subscriptionUsersNumber: Number(user),
              billingPeriod: "monthly",
            },
            {
              onSuccess,
            }
          )
        );
      }
    }
  };

  return (
    <>
      <Box mb={"20px"}>
        <Grid container spacing="20px">
          <Grid item xs={12} md={6}>
            <Card sx={styles.planCard}>
              <Box p={"15px"} textAlign="center">
                <Typography fontSize={{ xs: "14px", md: "16px" }}>
                  Paid Plan
                </Typography>
              </Box>
              <Divider
                sx={{
                  borderColor: `${theme.palette.background.default}`,
                }}
              />
              <Box sx={styles.listCard}>
                <Typography
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  fontSize={{ xs: "24px", md: "30px" }}
                  lineHeight={{ xs: 1.3, md: "39px" }}
                  fontWeight={600}
                  color="primary"
                  textAlign={"center"}
                  mb={"20px"}
                >
                  $10
                  <Typography
                    component="span"
                    fontSize={{ xs: "14px", md: "18px" }}
                    fontWeight="400"
                    color={`${theme.palette.text.primary}`}
                  >
                    /mo per user
                  </Typography>
                </Typography>
                <Box mb={"20px"}>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={user}
                      onChange={handleChange}
                      size="small"
                      height="38px"
                    >
                      {users.map((element) => (
                        <MenuItem key={element} value={element}>
                          {element === "1"
                            ? element + " User"
                            : element + " Users"}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {/*<Typography
                    fontSize={{ xs: "10px", md: "12px" }}
                    fontWeight={"500"}
                    textAlign={"center"}
                    mt={"10px"}
                  >
                    Total $100.00/mo (billed monthly)
                  </Typography>*/}
                </Box>
                <List sx={styles.list}>
                  {paidPlan.map((item, i) => (
                    <ListItem sx={{ p: "5px 0" }} key={i}>
                      <ListItemIcon>
                        <CheckCircleIcon />
                      </ListItemIcon>
                      <ListItemText>{item}</ListItemText>
                    </ListItem>
                  ))}
                </List>
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  fullWidth
                  mt="auto"
                  disabled={subscription.isLoadingSubscription ? true : false}
                  onClick={handleUpgradePlan}
                >
                  <Typography fontSize={{ xs: "14px", md: "16px" }}>
                    {subscription.subscriptions?.[0]?.status !== "canceled" &&
                    !organization?.trial &&
                    Number(user) ===
                      subscription.subscriptions?.[0]?.subscriptionUsersNumber
                      ? "Current "
                      : "Upgrade "}
                    Plan
                  </Typography>
                </Button>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={styles.planCard}>
              <Box p={"15px"} textAlign="center">
                <Typography fontSize={{ xs: "14px", md: "16px" }}>
                  Custom Plan
                </Typography>
              </Box>
              <Divider
                sx={{
                  borderColor: `${theme.palette.background.default}`,
                }}
              />
              <Box sx={styles.listCard}>
                <Typography
                  fontSize={{ xs: "20px", md: "24px" }}
                  fontWeight={600}
                  lineHeight={{ xs: 1.3, md: "39px" }}
                  color="primary"
                  textAlign={"center"}
                  mb={"20px"}
                >
                  Custom Pricing
                </Typography>
                <List sx={styles.list}>
                  {customPlan.map((item, i) => (
                    <ListItem sx={{ p: "5px 0" }} key={i}>
                      <ListItemIcon>
                        <CheckCircleIcon />
                      </ListItemIcon>
                      <ListItemText>{item}</ListItemText>
                    </ListItem>
                  ))}
                </List>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  fullWidth
                  mt="auto"
                  onClick={() => {
                    if (isMobile) setOpenContactUsModal();
                    setModalActionType((prevState) => ({
                      ...prevState,
                      action: "Contact Us",
                    }));
                  }}
                >
                  <Typography fontSize={{ xs: "14px", md: "16px" }}>
                    Contact Us
                  </Typography>
                </Button>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Typography textAlign={"center"} fontSize={{ xs: "14px", md: "16px" }}>
        Have questions?{" "}
        <Typography
          sx={{
            textDecoration: "underline",
            cursor: "pointer",
          }}
          color="primary"
          component="span"
          onClick={() => {
            if (isMobile) setOpenContactUsModal();
            setModalActionType((prevState) => ({
              ...prevState,
              action: "Contact Us",
            }));
          }}
        >
          Contact Us
        </Typography>
      </Typography>
    </>
  );
};

export default PaidPlans;
