import React, { useEffect, useState } from "react";
import { Theme, Typography, Card } from "@mui/material";
import { format } from "date-fns";
import { isMobile } from "mobile-device-detect";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flexShrink: 0,
    p: isMobile ? "20px" : "0",
    border: isMobile
      ? (theme: Theme) => `1px solid ${theme.palette.background.default}`
      : "none",
    marginRight: { md: "20px" },
  },
  dateWrapper: {
    backgroundColor: (theme: Theme) => theme.palette.background.paper,
    color: (theme: Theme) => theme.palette.text.secondary,
    fontSize: isMobile ? "14px" : "18px",
    fontWeight: 500,
    lineHeight: 1.2,
    marginBottom: "3px",
  },
  timeWrapper: {
    backgroundColor: (theme: Theme) => theme.palette.background.paper,
    fontSize: isMobile ? "30px" : "40px",
    fontWeight: 700,
    lineHeight: 1.2,
  },
};

const DateTime = () => {
  const [currentTime, setCurrentTime] = useState(null);

  const currentDate = format(new Date(), "EEEE, MMMM do");

  const UpdateTime = () => {
    let time = format(new Date(), "p");
    setCurrentTime(time);
  };

  useEffect(() => {
    UpdateTime();
    const interval = setInterval(UpdateTime, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Card
      data-sqa-id="current-date-and-time"
      variant="outlined"
      sx={styles.container}
    >
      <Typography data-sqa-id="current-date" sx={styles.dateWrapper}>
        {currentDate}
      </Typography>
      <Typography data-sqa-id="current-time" sx={styles.timeWrapper}>
        {currentTime}
      </Typography>
    </Card>
  );
};

export default DateTime;
