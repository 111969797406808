import { Typography, Theme } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import {
  getOrganizationSelector,
  getUserSelector,
} from "src/store/auth/selectors";
import Button from "../Button";
import ModalWrapper from "./ModalWrapper";
import { isMobile } from "mobile-device-detect";
import { AppRoutes } from "src/constants";
import { useNavigate } from "react-router-dom";
import { parseTime } from "src/utils";
import { Capacitor } from "@capacitor/core";

const styles = {
  modal: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: 350,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "4px",
  },
  modalTitile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "16px 24px",
    borderBottom: (theme: Theme) =>
      `1px solid ${theme.palette.background.default}`,
  },
  modalClose: {
    color: (theme: Theme) => `${theme.palette.text.primary}`,
    p: 0,
  },
  buttonClose: {
    boxShadow: "none",
    backgroundColor: "#FFF",
    borderRadius: "4px",
    border: "1px solid #D9D9D9",
  },
  buttonText: {
    fontSize: "14px",
    fontWeight: 400,
  },
};

interface ModalProps {
  closeModal: () => void;
  isOpen: boolean;
  modalActionType: {
    action: string;
    page: string;
  };
  openChoosePlanModal?: () => void;
}

const TrialModal: React.FC<ModalProps> = ({
  isOpen,
  closeModal,
  modalActionType,
  openChoosePlanModal,
}) => {
  const organization = useSelector(getOrganizationSelector);
  const userState = useSelector(getUserSelector);
  const navigate = useNavigate();
  const roleMode = localStorage.getItem("roleMode");

  const days =
    Math.abs(
      new Date().getTime() - parseTime(organization?.trialExpires).getTime()
    ) / 1000;

  return (
    <>
      <ModalWrapper
        closeModal={closeModal}
        isOpen={isOpen}
        modalActionType={modalActionType}
        maxWidth={modalActionType.action === "Trial" ? "350px" : "580px"}
      >
        <>
          {userState.isAdmin ? (
            new Date().getTime() <
            parseTime(organization?.trialExpires).getTime() ? (
              <>
                <Typography>
                  Your trial will expire in {Math.floor(Math.abs(days) / 86400)}{" "}
                  days.
                </Typography>
                {Capacitor.getPlatform() === "ios" ? null : (
                  <Button
                    fullWidth
                    mt="24px"
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      if (isMobile) {
                        navigate(AppRoutes.SettingsPlan);
                      } else {
                        roleMode === "admin"
                          ? navigate(AppRoutes.Settings, {
                              state: { id: "2" },
                            })
                          : openChoosePlanModal();
                        closeModal();
                      }
                    }}
                  >
                    <Typography color="#FFF" sx={styles.buttonText}>
                      Upgrade to Paid Plan
                    </Typography>
                  </Button>
                )}
              </>
            ) : (
              <>
                <Typography>
                  Your trial expired! The app is read-only. Please upgrade to
                  Paid Plan if you wish to continue using the all.
                </Typography>
                {Capacitor.getPlatform() === "ios" ? null : (
                  <Button
                    fullWidth
                    mt="24px"
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      if (isMobile) {
                        navigate(AppRoutes.SettingsPlan);
                      } else {
                        navigate(AppRoutes.Settings);
                        sessionStorage.setItem("activeTab", "2");
                        closeModal();
                      }
                    }}
                  >
                    <Typography color="#FFF" sx={styles.buttonText}>
                      Upgrade to Paid Plan
                    </Typography>
                  </Button>
                )}
              </>
            )
          ) : new Date().getTime() <
            parseTime(organization?.trialExpires).getTime() ? (
            <>
              <Typography>
                Trial will expire in {Math.floor(Math.abs(days) / 86400)} days.
                Your company administrator should upgrade to the Paid Plan for
                the app to keep working.
              </Typography>
              <Button
                fullWidth
                mt="24px"
                variant="contained"
                style={styles.buttonClose}
                onClick={closeModal}
              >
                <Typography color="#262626" sx={styles.buttonText}>
                  Close
                </Typography>
              </Button>
            </>
          ) : (
            <>
              <Typography>
                Trial expired! Your company administrator neeeds to upgrade to
                the Paid Plan for the app to start working.
              </Typography>
              <Button
                fullWidth
                mt="24px"
                variant="contained"
                style={styles.buttonClose}
                onClick={closeModal}
              >
                <Typography color="#262626" sx={styles.buttonText}>
                  Close
                </Typography>
              </Button>
            </>
          )}
        </>
      </ModalWrapper>
    </>
  );
};

export default TrialModal;
