import {
  Box,
  Theme,
  Typography,
  Button,
  IconButton,
  InputAdornment,
  FormControl,
  MenuItem,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addUserRequest,
  cleanError,
  getUserRequest,
  updateUserRequest,
} from "src/store/users/actions";
import Input from "../../../../components/Input";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Select from "../../../../components/Select";
import { AppRoutes, PHONE_NUMBER_REGEX } from "src/constants";
import { formatPhoneNumber, isFieldHasError } from "src/utils";
import { getUserSelector } from "src/store/users/selectors";
import AppBar from "../../../../components/AppBar";
import { styles } from "./userActions.module";
import { useLocation, useNavigate } from "react-router-dom";
import ActionWithUser from "src/components/ActionsModal/ActionWithUser";
import { getAuthSelector } from "src/store/auth/selectors";
import ContactSupportTooltipText from "src/components/TooltipText/ContactSupportTooltipText";

const validationSchema = yup.object().shape({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  email: yup
    .string()
    .email("Email must be valid")
    .required("Email is required"),
  phoneNumber: yup
    .string()
    .required("Phone number is required")
    .matches(PHONE_NUMBER_REGEX, "Phone number is not valid"),
  userType: yup.string().required("User type is required"),
  rate: yup.number().positive().min(0),
});

const AddUser: React.FC = () => {
  const { pathname } = useLocation();
  const userId = useLocation().state as { id: string };
  const navigate = useNavigate();
  const [openActionModal, setOpenActionModal] = useState<boolean>(false);
  const dispatch = useDispatch();
  const userState = useSelector(getUserSelector);
  const auth = useSelector(getAuthSelector);
  const modalActionType = {
    action: "Delete",
    page: "User",
  };

  const isEditMode = pathname.includes("edit-user");

  const formik = useFormik({
    initialValues: {
      phoneNumber: pathname.includes("add-user")
        ? ""
        : formatPhoneNumber(userState.user?.phoneNumber),
      firstName: pathname.includes("add-user") ? "" : userState.user?.firstName,
      lastName: pathname.includes("add-user") ? "" : userState.user?.lastName,
      email: pathname.includes("add-user") ? "" : userState.user?.email,
      userType: pathname.includes("add-user")
        ? ""
        : userState.user?.isAdmin
        ? "Administrator"
        : "Employee",
      rate: pathname.includes("add-user")
        ? ""
        : userState.user?.hourlyRate?.toFixed(2) || "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: ({ phoneNumber, firstName, lastName, email, userType, rate }) => {
      const phoneNumberWithoutMask = phoneNumber.replace(/\D/g, "");
      const firstNameTrim = firstName.trim();
      const lastNameTrim = lastName.trim();
      if (!firstNameTrim || !lastNameTrim) return;
      else {
        if (pathname.includes("add-user")) {
          dispatch(
            addUserRequest(
              {
                firstName: firstName.trim(),
                lastName: lastName.trim(),
                phoneNumber: phoneNumberWithoutMask,
                email: email.trim(),
                isAdmin: userType === "Administrator" ? true : false,
                hourlyRate: parseFloat(rate),
              },
              { onSuccess: () => navigate(AppRoutes.Team) }
            )
          );
        } else {
          dispatch(
            updateUserRequest(
              {
                user: {
                  id: userState.user.id,
                  firstName: firstName.trim(),
                  lastName: lastName.trim(),
                  isAdmin: userType === "Administrator" ? true : false,
                  hourlyRate: parseFloat(rate),
                },
              },
              { onSuccess: () => navigate(AppRoutes.Team) }
            )
          );
        }
      }
    },
  });

  useEffect(() => {
    dispatch(cleanError());
    if (pathname.includes("edit-user")) {
      dispatch(getUserRequest({ id: userId?.id }));
    }
    return () => {
      dispatch(cleanError());
    };
  }, []);

  return (
    <AppBar>
      <>
        <Box sx={styles.boxContainer}>
          {userState.isLoading ? (
            <Box
              data-sqa-id="circular-progress-loader"
              height="fit-content"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <CircularProgress />
            </Box>
          ) : (
            <form onSubmit={formik.handleSubmit}>
              <Box>
                <Input
                  type="text"
                  fullWidth
                  required
                  size="small"
                  label="First Name"
                  inputProps={{ maxLength: 150 }}
                  id="firstName"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  error={isFieldHasError({
                    formik,
                    fieldName: "firstName",
                    isGlobalError: Boolean(userState.error),
                  })}
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                />
              </Box>
              <Box mt="20px">
                <Input
                  type="text"
                  fullWidth
                  required
                  size="small"
                  label="Last Name"
                  inputProps={{ maxLength: 150 }}
                  id="lastName"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  error={isFieldHasError({
                    formik,
                    fieldName: "lastName",
                    isGlobalError: Boolean(userState.error),
                  })}
                  helperText={formik.touched.lastName && formik.errors.lastName}
                />
              </Box>
              <Box mt="20px">
                <Input
                  required
                  mask="(999) 999-9999"
                  label="Phone Number"
                  fullWidth
                  size="small"
                  inputProps={{ maxLength: 30 }}
                  id="phoneNumber"
                  value={formik.values.phoneNumber}
                  isDisabled={isEditMode}
                  onChange={formik.handleChange}
                  error={isFieldHasError({
                    formik,
                    fieldName: "phoneNumber",
                    isGlobalError: Boolean(userState.error),
                  })}
                  helperText={
                    formik.touched.phoneNumber && formik.errors.phoneNumber
                  }
                  withLockIcon={isEditMode}
                  tooltipText={<ContactSupportTooltipText />}
                />
              </Box>
              <Box mt="20px">
                <Input
                  type="text"
                  fullWidth
                  required
                  size="small"
                  label="Email"
                  inputProps={{ maxLength: 150 }}
                  id="email"
                  value={formik.values.email}
                  disabled={isEditMode}
                  onChange={formik.handleChange}
                  error={isFieldHasError({
                    formik,
                    fieldName: "email",
                    isGlobalError: Boolean(userState.error),
                  })}
                  helperText={formik.touched.email && formik.errors.email}
                  withLockIcon={isEditMode}
                  tooltipText={<ContactSupportTooltipText />}
                />
              </Box>
              <Box mt="20px">
                <Typography mb={"8px"} fontSize="14px" color="#8C8C8C">
                  User Type{" "}
                  <Typography component="span" color="#FF0000">
                    *
                  </Typography>
                </Typography>
                <FormControl fullWidth>
                  <Select
                    value={formik.values.userType}
                    height="40px"
                    disabled={userState?.user?.id === auth.user.id}
                    fontSize="14px"
                    onChange={(opt) =>
                      formik.setFieldValue("userType", opt.target.value)
                    }
                    IconComponent={ArrowDropDownIcon}
                    error={isFieldHasError({
                      formik,
                      fieldName: "userType",
                      isGlobalError: Boolean(userState.error),
                    })}
                  >
                    <MenuItem key="Employee" value="Employee">
                      Employee
                    </MenuItem>
                    <MenuItem key="Administrator" value="Administrator">
                      Administrator
                    </MenuItem>
                  </Select>
                  {formik.touched.userType && formik.errors.userType && (
                    <FormHelperText
                      sx={{ color: (theme: Theme) => theme.palette.error.main }}
                    >
                      {formik.touched.userType && formik.errors.userType}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
              <Box sx={{ mt: "20px", position: "relative" }}>
                <Input
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="start">
                          <Typography color="#262626">$/hr</Typography>
                        </IconButton>
                      </InputAdornment>
                    ),
                    inputProps: { min: 0, step: "0.01", max: 9999 },
                  }}
                  type="number"
                  fullWidth
                  size="small"
                  label="Rate"
                  id="rate"
                  value={formik.values.rate}
                  onChange={formik.handleChange}
                />
              </Box>
              <Box display="flex" justifyContent="center" mt="20px">
                <FormHelperText
                  sx={{
                    color: (theme: Theme) => theme.palette.error.main,
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                >
                  {userState.error}
                </FormHelperText>
              </Box>
              {pathname.includes("add-user") && (
                <Typography
                  fontSize="14px"
                  fontWeight={400}
                  color="#8C8C8C"
                  mt="20px"
                >
                  Once the user is saved, the login instructions and temporary
                  password will be emailed and texted to the user.
                </Typography>
              )}
              <Box sx={styles.actionButtons}>
                {pathname.includes("edit-user") && (
                  <Button
                    variant="outlined"
                    color="error"
                    fullWidth
                    sx={styles.actionButtonSave}
                    onClick={() => setOpenActionModal(true)}
                  >
                    Delete User
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={styles.actionButtonSave}
                  type="submit"
                >
                  Save
                </Button>
              </Box>
            </form>
          )}
        </Box>

        {openActionModal && (
          <ActionWithUser
            fetchSuccess={() => navigate(AppRoutes.Team)}
            modalActionType={modalActionType}
            elem={userState.user}
            isOpen={openActionModal}
            closeModal={() => setOpenActionModal(false)}
          />
        )}
      </>
    </AppBar>
  );
};

export default AddUser;
