import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { PlusForWeb } from "src/assets/icons";
import { Button } from "src/components";
import { styles } from "./projects.module";
import PaginationContainer from "../../../components/Pagination";
import ProjectsTable from "./ProjectTable";
import { getProjectSelector } from "src/store/projects/selectors";
import SearchField from "src/components/SearchField";

interface Props {
  searchProjectValue: string;
  page: number;
  sortOrder: any;
  sortByTableName: string;
  isLoading: boolean;
  setSearchProjectValue: (word: string) => void;
  setOpenActionModal: (bol: boolean) => void;
  handleChangePage: (elem: unknown, pageNum: number) => void;
  fetchProjects: () => void;
  requestSort: (elem: string) => void;
  setSortOrder: (order: string) => void;
}

const ProjectsWeb: React.FC<Props> = ({
  searchProjectValue,
  page,
  sortOrder,
  sortByTableName,
  isLoading,
  setSearchProjectValue,
  setOpenActionModal,
  handleChangePage,
  fetchProjects,
  requestSort,
  setSortOrder,
}) => {
  const allProjects = useSelector(getProjectSelector);

  return (
    <>
      <Box sx={styles.topContainer}>
        <Typography data-sqa-id="page-title" sx={styles.pageTitle}>
          Projects
        </Typography>
      </Box>
      <Box sx={styles.teamContainer}>
        <Box sx={[styles.topContainer, { marginBottom: "20px" }]}>
          <Box>
            <SearchField
              id="projectsValue"
              searchValue={searchProjectValue}
              setSearchValue={setSearchProjectValue}
            />
          </Box>
          <Button
            style={{ width: "175px", height: "46px" }}
            variant="contained"
            color="primary"
            onClick={() => setOpenActionModal(true)}
          >
            <Box sx={styles.addButtonContainer}>
              <PlusForWeb />
              <Typography sx={styles.addButtonText}>Add Project</Typography>
            </Box>
          </Button>
        </Box>
        {allProjects.isLoading || isLoading ? (
          <Box
            data-sqa-id="circular-progress-loader"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress />
          </Box>
        ) : allProjects.projects.length ? (
          <>
            <ProjectsTable
              allProject={allProjects.projects}
              fetchProjects={fetchProjects}
              requestSort={requestSort}
              setSortOrder={setSortOrder}
              sortOrder={sortOrder}
              sortBy={sortByTableName}
            />
            {
              // Show pagination only when there is more than 1 page.
            }
            {allProjects.totalPages > 1 ? (
              <Box mt="20px" sx={styles.pagination}>
                <PaginationContainer
                  page={page}
                  handleChangePage={handleChangePage}
                  pages={allProjects.totalPages}
                />
              </Box>
            ) : null}
          </>
        ) : (
          <Box sx={styles.noData}>
            <Typography
              data-sqa-id="no-comments-yet-text"
              sx={styles.noDataText}
            >
              {!searchProjectValue.length
                ? "No projects have been created yet."
                : "No results found for your search. Please try again with different keywords."}
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
};

export default ProjectsWeb;
