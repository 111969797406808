import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Comments,
  DeleteForWeb,
  ProjectIcon,
  ViewForWeb,
} from "src/assets/icons";
import DeleteProject from "src/components/ActionsModal/DeleteProject";
import { AppRoutes } from "src/constants";
import { Project } from "src/types";
import projectsTable from "./projectsTable.json";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { styles } from "./projectTable.module";

interface Props {
  allProject: Project[];
  fetchProjects: () => void;
  requestSort: (elem: string) => void;
  setSortOrder: (order: string) => void;
  sortBy: string;
  sortOrder: any;
}

const ProjectsTable: React.FC<Props> = ({
  allProject,
  fetchProjects,
  requestSort,
  setSortOrder,
  sortBy,
  sortOrder,
}) => {
  const navigate = useNavigate();
  const [openDeleteProjectModal, setOpenDeleteProjectModal] = useState(false);
  const [elem, setElem] = useState();
  const [hover, setHover] = useState("");
  const [modalActionType, setModalActionType] = useState({
    action: "",
    page: "Project",
  });
  const projectList = allProject.filter((project) => !project.parentId);

  const handleOpenModal = (type: string, item?: any) => {
    setOpenDeleteProjectModal(true);
    setElem(item);
    setModalActionType((prevState) => ({
      ...prevState,
      action: type,
    }));
  };

  const chooseType = (elem, item) => {
    switch (elem.type) {
      case "id":
        return item[elem.array[0]];
      case "projectName":
        return item[elem.array[0]];
      case "address":
        return item[elem.array[0]] === null
          ? ""
          : `${
              item[elem.array[0]]?.address ? item[elem.array[0]]?.address : ""
            }${
              item[elem.array[0]]?.address &&
              (item[elem.array[0]]?.city ||
                item[elem.array[0]]?.state ||
                item[elem.array[0]]?.zip)
                ? ","
                : ""
            } ${item[elem.array[0]]?.city ? item[elem.array[0]]?.city : ""} ${
              item[elem.array[0]]?.state ? item[elem.array[0]]?.state : ""
            } ${item[elem.array[0]]?.zip ? item[elem.array[0]]?.zip : ""}`;
      case "subprojects":
        return (
          <Box
            sx={
              hover === "subprojects" + item.id
                ? styles.hoverContainer
                : item[elem.array[0]].subProjectCount === 0
                ? styles.emptyContainer
                : styles.countContainer
            }
            onClick={() => {
              navigate(AppRoutes.ProjectItem + `/${item.id}`);
              sessionStorage.setItem("activeTab", "1");
            }}
            onMouseEnter={() => setHover("subprojects" + item.id)}
            onMouseLeave={() => setHover("")}
          >
            <ProjectIcon
              color={
                hover === "subprojects" + item.id
                  ? "#FFF"
                  : item[elem.array[0]].subProjectCount === 0
                  ? "#8C8C8C"
                  : "#0D6FF0"
              }
            />
            <Typography
              ml="10px"
              fontSize={14}
              color={
                hover === "subprojects" + item.id
                  ? "#FFF"
                  : item[elem.array[0]].subProjectCount === 0
                  ? "#8C8C8C"
                  : "#0D6FF0"
              }
            >
              {item[elem.array[0]].subProjectCount}
            </Typography>
          </Box>
        );
      case "projectComments":
        return (
          <Box
            sx={
              hover === "projectComments" + item.id
                ? styles.hoverContainer
                : item[elem.array[0]].commentsCount === 0
                ? styles.emptyContainer
                : styles.countContainer
            }
            onClick={() => {
              navigate(AppRoutes.ProjectItem + `/${item.id}`);
              sessionStorage.setItem("activeTab", "2");
            }}
            onMouseEnter={() => setHover("projectComments" + item.id)}
            onMouseLeave={() => setHover("")}
          >
            <Comments
              size={18}
              color={
                hover === "projectComments" + item.id
                  ? "#FFF"
                  : item[elem.array[0]].commentsCount === 0
                  ? "#8C8C8C"
                  : "#0D6FF0"
              }
            />
            <Typography
              ml="10px"
              fontSize={14}
              color={
                hover === "projectComments" + item.id
                  ? "#FFF"
                  : item[elem.array[0]].commentsCount === 0
                  ? "#8C8C8C"
                  : "#0D6FF0"
              }
            >
              {item[elem.array[0]].commentsCount}
            </Typography>
          </Box>
        );
      case "actions":
        return (
          <Box sx={styles.boxContainer}>
            <Box
              mr="20px"
              onClick={() => {
                navigate(AppRoutes.ProjectItem + `/${item.id}`);
                sessionStorage.setItem("activeTab", "0");
              }}
              onMouseEnter={() => setHover("view" + item.id)}
              onMouseLeave={() => setHover("")}
            >
              <ViewForWeb
                color={hover === "view" + item.id ? "#0D6FF0" : "#8C8C8C"}
              />
            </Box>
            <Box
              onClick={() => handleOpenModal("Delete", item)}
              onMouseEnter={() => setHover("delete" + item.id)}
              onMouseLeave={() => setHover("")}
            >
              <DeleteForWeb
                color={hover === "delete" + item.id ? "#FF0003" : "#FF4D4F"}
              />
            </Box>
          </Box>
        );
    }
  };
  return (
    <>
      <TableContainer>
        <Table>
          <TableHead
            sx={{
              backgroundColor: "#FAFAFA",
            }}
          >
            <TableRow sx={styles.tableHeader}>
              {projectsTable[0]?.label?.map((elem: string) => (
                <TableCell
                  key={elem}
                  sx={[
                    styles.lineTable,
                    {
                      fontWeight: 500,
                      fontSize: "16px",
                      color: "#262626",
                      textTransform: "capitalize",
                    },
                  ]}
                >
                  {elem !== "Actions" ? (
                    <TableSortLabel
                      active={sortBy === elem}
                      direction={sortOrder}
                      onClick={() => {
                        requestSort(elem);
                        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                      }}
                      sx={{
                        "& .MuiTableSortLabel-icon": {
                          display: "none",
                        },
                      }}
                    >
                      {elem}
                      <Box sx={styles.boxWrapper}>
                        <Box
                          sx={styles.boxSort}
                          onClick={() => setSortOrder("asc")}
                        >
                          <ArrowDropUpIcon
                            sx={{ height: "24px" }}
                            color={
                              sortOrder === "asc" && sortBy === elem
                                ? "primary"
                                : "disabled"
                            }
                          />
                        </Box>
                        <Box
                          sx={styles.boxSort}
                          onClick={() => setSortOrder("desc")}
                        >
                          <ArrowDropDownIcon
                            sx={{ height: "24px" }}
                            color={
                              sortOrder === "desc" && sortBy === elem
                                ? "primary"
                                : "disabled"
                            }
                          />
                        </Box>
                      </Box>
                    </TableSortLabel>
                  ) : (
                    elem
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody sx={{ backgroundColor: "#FFF" }}>
            {projectList?.map((projectItem: Project) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={projectItem.id}
                  sx={styles.tableHeader}
                >
                  {projectsTable[1]?.data?.map((elem, i) => {
                    return (
                      <TableCell key={i} sx={styles.tableCell}>
                        {chooseType(elem, projectItem)}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {openDeleteProjectModal && (
        <DeleteProject
          fetchSuccess={fetchProjects}
          modalActionType={modalActionType}
          elem={elem}
          isOpen={openDeleteProjectModal}
          closeModal={() => setOpenDeleteProjectModal(false)}
        />
      )}
    </>
  );
};

export default ProjectsTable;
