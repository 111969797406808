import { format, parse, parseISO } from "date-fns";
// import { zonedTimeToUtc } from "date-fns-tz";
import { FormicConfiguration } from "../types";

export const isFieldHasError = ({
  formik,
  fieldName,
  isGlobalError,
}: {
  fieldName: string;
  isGlobalError?: boolean;
  formik: FormicConfiguration;
}) => {
  return (
    (formik.touched[fieldName] && Boolean(formik.errors[fieldName])) ||
    isGlobalError
  );
};

export const getMappedErrorMessage = (beError: string | null) => {
  if (beError) {
    return beError;
  }
};

export const secondsToTime = (secs: number) => {
  const seconds = Math.floor(secs);
  if (seconds < 60) return `${seconds} sec`;

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  if (minutes < 60) return `${minutes} min ${remainingSeconds} sec`;

  const hours = Math.floor(minutes / 60);
  const remainingMinutes = Math.floor(minutes % 60);
  return `${hours} hr ${remainingMinutes} min`;
};

export const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const parseTime = (time: string) => {
  return parseISO(time);
};

export const parseTimeForClock = (time: string) => {
  return parseISO(time);
};

export const formatDate = (date: string) => {
  return parse(date, "yyyy-MM-dd", new Date());
};

export const formatDateTime = (time: Date) => {
  return format(time, "yyyy-MM-dd HH:mm:ss.SSS");
};

export const formatPhoneNumber = (phoneNumberString: string) => {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
};
