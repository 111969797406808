import React, { useEffect, useState } from "react";
import {
  format,
  addDays,
  isSameDay,
  addWeeks,
  subWeeks,
  subDays,
} from "date-fns";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "src/constants";
import { Back } from "src/assets/icons";
import { styles } from "./weekCalendar.module";
import { formatDate } from "src/utils";

interface Props {
  date: string;
}

const WeekCalendar: React.FC<Props> = ({ date }) => {
  const navigate = useNavigate();
  const [currentMonth, setCurrentMonth] = useState(formatDate(date));
  const [selectedDate, setSelectedDate] = useState(formatDate(date));

  const changeWeekHandle = (btnType: string) => {
    if (btnType === "prev") {
      setSelectedDate((date) => {
        return subDays(date, 1);
      });
      setCurrentMonth(subWeeks(currentMonth, 1));
    }
    if (btnType === "next") {
      setSelectedDate((date) => {
        return addDays(date, 1);
      });
      setCurrentMonth(addWeeks(currentMonth, 1));
    }
  };

  useEffect(() => {
    navigate(AppRoutes.HistoryDay + `/${format(selectedDate, "y-MM-dd")}`);
  }, [selectedDate]);

  const onDateClickHandle = (day: Date) => {
    setSelectedDate(day);
    navigate(AppRoutes.HistoryDay + `/${format(day, "y-MM-dd")}`);
  };

  const renderCells = () => {
    const dateFormat = "d";
    const rows = [];
    let days = [];
    let date = new Date(selectedDate).setDate(selectedDate.getDate() - 3);
    let day = new Date(date);
    let formattedDate = "";
    for (let i = 0; i < 7; i++) {
      formattedDate = format(day, dateFormat);
      const cloneDay = day;
      days.push(
        <Box
          key={day.toString()}
          sx={
            isSameDay(day, selectedDate)
              ? styles.selectedWeekContainer
              : styles.weekContainer
          }
          onClick={() => {
            onDateClickHandle(cloneDay);
          }}
        >
          <Typography fontSize="14px">{formattedDate}</Typography>
        </Box>
      );
      day = addDays(day, 1);
    }
    rows.push(
      <Box key={day.toString()} sx={styles.weekBox}>
        {days}
      </Box>
    );
    days = [];
    return <Box sx={styles.weekDates}>{rows}</Box>;
  };
  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ marginBottom: "16px" }}
      >
        <Typography sx={styles.historyDay}>
          {format(selectedDate, "MMMM d, yyyy")}
        </Typography>
      </Box>
      <Box sx={styles.weekBox}>
        <Box
          sx={[styles.weekContainer, { maxWidth: "44px" }]}
          onClick={() => changeWeekHandle("prev")}
        >
          <Back width={22} height={10} color="#0D6FF0" />
        </Box>
        {renderCells()}
        <Box
          sx={[
            styles.weekContainer,
            { transform: "rotate(180deg)", maxWidth: "44px" },
          ]}
          onClick={() => changeWeekHandle("next")}
        >
          <Back width={22} height={10} color="#0D6FF0" />
        </Box>
      </Box>
    </Box>
  );
};

export default WeekCalendar;
