import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  checkPasswordReset,
  getOrganization,
  login,
  newPassword,
  passwordReset,
  phoneVerification,
  sendVerificationSms,
  signUp,
  updateOrganization,
  updateUser,
  deleteCurrentUser,
  emailConfirmation,
  deleteOrganization,
  currentUser,
  deleteUserProfilePicture,
  changePassword,
} from "./api";
import {
  loginSuccess,
  loginFailure,
  signUpSuccess,
  signUpFailure,
  updateUserDataSuccess,
  updateUserDataFailure,
  passwordResetSuccess,
  passwordResetFailure,
  checkPasswordResetFailure,
  checkPasswordResetSuccess,
  newPasswordFailure,
  newPasswordSuccess,
  sendVerificationFailure,
  sendVerificationSuccess,
  sendVerificationCodeFailure,
  sendVerificationCodeSuccess,
  getOrganizationSuccess,
  getOrganizationFailure,
  updateOrganizationFailure,
  updateOrganizationSuccess,
  deleteCurrentUserFailure,
  deleteCurrentUserSuccess,
  emailConfirmationFailure,
  emailConfirmationSuccess,
  deleteOrganizationFailure,
  deleteOrganizationSuccess,
  currentUserDataFailure,
  currentUserDataSuccess,
  deleteUserProfilePictureFailure,
  deleteUserProfilePictureSuccess,
  changePasswordFailure,
  changePasswordSuccess,
} from "./actions";
import {
  CHANGE_PASSWORD_REQUEST,
  CHECK_PASSWORD_RESET_REQUEST,
  CURRENT_USER_DATA_REQUEST,
  DELETE_CURRENT_USER_REQUEST,
  DELETE_ORGANIZATION_REQUEST,
  DELETE_USER_PROFILE_PICTURE_REQUEST,
  EMAIL_CONFIRMATION_REQUEST,
  GET_ORGANIZATION_REQUEST,
  LOGIN_REQUEST,
  NEW_PASSWORD_REQUEST,
  PASSWORD_RESET_REQUEST,
  SEND_VERIFICATION_CODE_REQUEST,
  SEND_VERIFICATION_SMS_REQUEST,
  SIGN_UP_REQUEST,
  UPDATE_ORGANIZATION_REQUEST,
  UPDATE_USER_DATA_REQUEST,
} from "./actionTypes";
import {
  ChangePasswordRequest,
  CheckPasswordResetRequest,
  CheckPasswordResetSuccessPayload,
  DeleteCurrentUserRequest,
  DeleteOrganizationRequest,
  DeleteUserProfilePictureRequest,
  EmailConfirmationRequest,
  LoginRequest,
  LoginSuccessPayload,
  NewPasswordRequest,
  NewPasswordSuccessPayload,
  PasswordResetRequest,
  PasswordResetSuccessPayload,
  SendVerificationCodeRequest,
  SendVerificationCodeSuccessPayload,
  SendVerificationSuccessPayload,
  SignUpRequest,
  SignUpSuccessPayload,
  UpdateOrganizationRequest,
  UpdateUserDataRequest,
} from "./types";
import { AxiosResponse } from "axios";
import { Organization, User } from "src/types";

function* loginSaga({ payload, callbacks }: LoginRequest) {
  try {
    const response: AxiosResponse<LoginSuccessPayload> = yield call(
      login,
      payload
    );

    yield put(
      loginSuccess({
        ...response.data,
      })
    );

    if (callbacks?.onSuccess) {
      yield call(callbacks.onSuccess);
    }
  } catch (e) {
    yield put(
      loginFailure({
        // @ts-ignore
        error: e?.response?.data?.message,
      })
    );
  }
}

function* signUpSaga({ payload, callbacks }: SignUpRequest) {
  try {
    const response: AxiosResponse<SignUpSuccessPayload> = yield call(
      signUp,
      payload
    );

    yield put(
      signUpSuccess({
        ...response?.data,
      })
    );

    if (callbacks?.onSuccess) {
      yield call(callbacks.onSuccess);
    }
  } catch (e) {
    yield put(
      signUpFailure({
        // @ts-ignore
        error: e?.response?.data?.message,
      })
    );
  }
}

function* emailConfirmationSaga({
  payload,
  callbacks,
}: EmailConfirmationRequest) {
  try {
    const response: AxiosResponse = yield call(emailConfirmation, payload);

    yield put(
      emailConfirmationSuccess({
        ...response.data,
      })
    );

    if (callbacks?.onSuccess) {
      yield call(callbacks.onSuccess);
    }
  } catch (e) {
    yield put(
      emailConfirmationFailure({
        // @ts-ignore
        error: e?.response?.data?.message,
      })
    );
  }
}

function* currentUserDataSaga() {
  try {
    const response: AxiosResponse = yield call(currentUser);

    yield put(
      currentUserDataSuccess({
        user: response.data,
      })
    );
  } catch (e) {
    yield put(
      currentUserDataFailure({
        // @ts-ignore
        error: e?.message,
      })
    );
  }
}

function* updateUserDataSaga({ payload, callbacks }: UpdateUserDataRequest) {
  try {
    const response: AxiosResponse<User> = yield call(updateUser, payload);

    yield put(
      updateUserDataSuccess({
        user: response.data,
      })
    );
    if (callbacks?.onSuccess) {
      yield call(callbacks.onSuccess);
    }
  } catch (e) {
    yield put(
      updateUserDataFailure({
        // @ts-ignore
        error: e?.message,
      })
    );
  }
}

function* deleteUserProfilePictureSaga({
  callbacks,
}: DeleteUserProfilePictureRequest) {
  try {
    const response: AxiosResponse = yield call(deleteUserProfilePicture);

    yield put(
      deleteUserProfilePictureSuccess({
        data: response.data,
      })
    );
    if (callbacks?.onSuccess) {
      yield call(callbacks.onSuccess);
    }
  } catch (e) {
    yield put(
      deleteUserProfilePictureFailure({
        // @ts-ignore
        error: e?.message,
      })
    );
  }
}

function* deleteCurrentUserSaga({ callbacks }: DeleteCurrentUserRequest) {
  try {
    const response: AxiosResponse = yield call(deleteCurrentUser);

    yield put(
      deleteCurrentUserSuccess({
        message: response.data,
      })
    );
    if (callbacks?.onSuccess) {
      yield call(callbacks.onSuccess);
    }
  } catch (e) {
    yield put(
      deleteCurrentUserFailure({
        // @ts-ignore
        error: e?.response?.data?.message,
      })
    );
  }
}

function* resetPasswordSaga({ payload, callbacks }: PasswordResetRequest) {
  try {
    const response: AxiosResponse<PasswordResetSuccessPayload> = yield call(
      passwordReset,
      payload
    );

    yield put(
      passwordResetSuccess({
        resetToken: response.data.resetToken,
      })
    );

    if (callbacks?.onSuccess) {
      yield call(callbacks.onSuccess);
    }
  } catch (e) {
    yield put(
      passwordResetFailure({
        // @ts-ignore
        error: e?.response?.data?.message,
      })
    );
  }
}

function* checkResetPasswordSaga({
  payload,
  callbacks,
}: CheckPasswordResetRequest) {
  try {
    const response: AxiosResponse<CheckPasswordResetSuccessPayload> =
      yield call(checkPasswordReset, payload);

    yield put(
      checkPasswordResetSuccess({
        ...response.data,
      })
    );
  } catch (e) {
    if (e.response.status === 302) {
      yield call(callbacks.onSuccess);
    } else {
      yield put(
        checkPasswordResetFailure({
          // @ts-ignore
          error: e?.response?.data?.message,
        })
      );
    }
  }
}

function* newPasswordSaga({ payload, callbacks }: NewPasswordRequest) {
  try {
    const response: AxiosResponse<NewPasswordSuccessPayload> = yield call(
      newPassword,
      payload
    );

    yield put(
      newPasswordSuccess({
        ...response.data,
      })
    );

    if (callbacks?.onSuccess) {
      yield call(callbacks.onSuccess);
    }
  } catch (e) {
    yield put(
      newPasswordFailure({
        // @ts-ignore
        error: e?.response?.data?.message,
      })
    );
  }
}

function* sendVerificationSaga() {
  try {
    const response: AxiosResponse<SendVerificationSuccessPayload> = yield call(
      sendVerificationSms
    );

    yield put(
      sendVerificationSuccess({
        ...response.data,
      })
    );
  } catch (e) {
    yield put(
      sendVerificationFailure({
        // @ts-ignore
        error: e?.response?.data?.message,
      })
    );
  }
}

function* sendVerificationCodeSaga({
  payload,
  callbacks,
}: SendVerificationCodeRequest) {
  try {
    const response: AxiosResponse<SendVerificationCodeSuccessPayload> =
      yield call(phoneVerification, payload);

    yield put(
      sendVerificationCodeSuccess({
        ...response.data,
      })
    );
    if (callbacks?.onSuccess) {
      yield call(callbacks.onSuccess);
    }
  } catch (e) {
    yield put(
      sendVerificationCodeFailure({
        // @ts-ignore
        error: e?.response?.data?.message,
      })
    );
  }
}

function* getOrganizationSaga() {
  try {
    const response: AxiosResponse<Organization> = yield call(getOrganization);

    yield put(
      getOrganizationSuccess({
        organization: response.data,
      })
    );
  } catch (e) {
    yield put(
      getOrganizationFailure({
        // @ts-ignore
        error: e?.response?.data?.message,
      })
    );
  }
}

function* updateOrganizationSaga({
  payload,
  callbacks,
}: UpdateOrganizationRequest) {
  try {
    const response: AxiosResponse<Organization> = yield call(
      updateOrganization,
      payload
    );

    yield put(
      updateOrganizationSuccess({
        organization: response.data,
      })
    );
    if (callbacks?.onSuccess) {
      yield call(callbacks.onSuccess);
    }
  } catch (e) {
    yield put(
      updateOrganizationFailure({
        // @ts-ignore
        error: e?.response?.data?.message,
      })
    );
  }
}

function* deleteOrganizationSaga({ callbacks }: DeleteOrganizationRequest) {
  try {
    const response: AxiosResponse = yield call(deleteOrganization);

    yield put(
      deleteOrganizationSuccess({
        message: response.data,
      })
    );
    if (callbacks?.onSuccess) {
      yield call(callbacks.onSuccess);
    }
  } catch (e) {
    yield put(
      deleteOrganizationFailure({
        // @ts-ignore
        error: e?.message,
      })
    );
  }
}

function* changePasswordSaga({ payload, callbacks }: ChangePasswordRequest) {
  try {
    const response: AxiosResponse = yield call(changePassword, payload);

    yield put(
      changePasswordSuccess({
        message: response.data,
      })
    );
    if (callbacks?.onSuccess) {
      yield call(callbacks.onSuccess);
    }
  } catch (e) {
    yield put(
      changePasswordFailure({
        // @ts-ignore
        error: e?.response?.data?.message,
      })
    );
  }
}

function* authSaga() {
  yield all([
    takeLatest(LOGIN_REQUEST, loginSaga),
    takeLatest(SIGN_UP_REQUEST, signUpSaga),
    takeLatest(EMAIL_CONFIRMATION_REQUEST, emailConfirmationSaga),
    takeLatest(CURRENT_USER_DATA_REQUEST, currentUserDataSaga),
    takeLatest(UPDATE_USER_DATA_REQUEST, updateUserDataSaga),
    takeLatest(
      DELETE_USER_PROFILE_PICTURE_REQUEST,
      deleteUserProfilePictureSaga
    ),
    takeLatest(DELETE_CURRENT_USER_REQUEST, deleteCurrentUserSaga),
    takeLatest(PASSWORD_RESET_REQUEST, resetPasswordSaga),
    takeLatest(CHECK_PASSWORD_RESET_REQUEST, checkResetPasswordSaga),
    takeLatest(NEW_PASSWORD_REQUEST, newPasswordSaga),
    takeLatest(SEND_VERIFICATION_SMS_REQUEST, sendVerificationSaga),
    takeLatest(SEND_VERIFICATION_CODE_REQUEST, sendVerificationCodeSaga),
    takeLatest(GET_ORGANIZATION_REQUEST, getOrganizationSaga),
    takeLatest(UPDATE_ORGANIZATION_REQUEST, updateOrganizationSaga),
    takeLatest(DELETE_ORGANIZATION_REQUEST, deleteOrganizationSaga),
    takeLatest(CHANGE_PASSWORD_REQUEST, changePasswordSaga),
  ]);
}

export default authSaga;
