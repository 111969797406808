import {
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHECK_PASSWORD_RESET_FAILURE,
  CHECK_PASSWORD_RESET_REQUEST,
  CHECK_PASSWORD_RESET_SUCCESS,
  CLEAN_ERROR,
  CURRENT_USER_DATA_FAILURE,
  CURRENT_USER_DATA_REQUEST,
  CURRENT_USER_DATA_SUCCESS,
  DELETE_CURRENT_USER_FAILURE,
  DELETE_CURRENT_USER_REQUEST,
  DELETE_CURRENT_USER_SUCCESS,
  DELETE_ORGANIZATION_FAILURE,
  DELETE_ORGANIZATION_REQUEST,
  DELETE_ORGANIZATION_SUCCESS,
  DELETE_USER_PROFILE_PICTURE_FAILURE,
  DELETE_USER_PROFILE_PICTURE_REQUEST,
  DELETE_USER_PROFILE_PICTURE_SUCCESS,
  EMAIL_CONFIRMATION_FAILURE,
  EMAIL_CONFIRMATION_REQUEST,
  EMAIL_CONFIRMATION_SUCCESS,
  GET_ORGANIZATION_FAILURE,
  GET_ORGANIZATION_REQUEST,
  GET_ORGANIZATION_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOG_OUT,
  NEW_PASSWORD_FAILURE,
  NEW_PASSWORD_REQUEST,
  NEW_PASSWORD_SUCCESS,
  PASSWORD_RESET_FAILURE,
  PASSWORD_RESET_REQUEST,
  PASSWORD_RESET_SUCCESS,
  RESET_STATE,
  SEND_VERIFICATION_CODE_FAILURE,
  SEND_VERIFICATION_CODE_REQUEST,
  SEND_VERIFICATION_CODE_SUCCESS,
  SEND_VERIFICATION_SMS_FAILURE,
  SEND_VERIFICATION_SMS_REQUEST,
  SEND_VERIFICATION_SMS_SUCCESS,
  SIGN_UP_FAILURE,
  SIGN_UP_REQUEST,
  SIGN_UP_SUCCESS,
  UPDATE_ORGANIZATION_FAILURE,
  UPDATE_ORGANIZATION_REQUEST,
  UPDATE_ORGANIZATION_SUCCESS,
  UPDATE_USER_DATA_FAILURE,
  UPDATE_USER_DATA_REQUEST,
  UPDATE_USER_DATA_SUCCESS,
} from "./actionTypes";
import {
  LoginRequest,
  LoginRequestPayload,
  LoginSuccess,
  LoginFailurePayload,
  LoginFailure,
  LoginSuccessPayload,
  LoginCallbacks,
  SignUpRequestPayload,
  SignUpCallbacks,
  SignUpRequest,
  SignUpFailurePayload,
  SignUpSuccessPayload,
  SignUpSuccess,
  SignUpFailure,
  Logout,
  UpdateUserDataRequestPayload,
  UpdateUserDataRequest,
  UpdateUserDataSuccessPayload,
  UpdateUserDataFailurePayload,
  UpdateUserDataFailure,
  UpdateUserDataSuccess,
  PasswordResetCallbacks,
  PasswordResetFailure,
  PasswordResetFailurePayload,
  PasswordResetRequest,
  PasswordResetRequestPayload,
  PasswordResetSuccess,
  PasswordResetSuccessPayload,
  CheckPasswordResetCallbacks,
  CheckPasswordResetFailure,
  CheckPasswordResetFailurePayload,
  CheckPasswordResetRequest,
  CheckPasswordResetRequestPayload,
  CheckPasswordResetSuccess,
  CheckPasswordResetSuccessPayload,
  NewPasswordCallbacks,
  NewPasswordFailure,
  NewPasswordFailurePayload,
  NewPasswordRequest,
  NewPasswordRequestPayload,
  NewPasswordSuccess,
  NewPasswordSuccessPayload,
  SendVerificationFailure,
  SendVerificationFailurePayload,
  SendVerificationRequest,
  SendVerificationSuccess,
  SendVerificationSuccessPayload,
  SendVerificationCodeCallbacks,
  SendVerificationCodeFailure,
  SendVerificationCodeFailurePayload,
  SendVerificationCodeRequest,
  SendVerificationCodeRequestPayload,
  SendVerificationCodeSuccess,
  SendVerificationCodeSuccessPayload,
  UpdateUserDataCallbacks,
  GetOrganizationFailurePayload,
  GetOrganizationSuccessPayload,
  UpdateOrganizationCallbacks,
  UpdateOrganizationFailurePayload,
  UpdateOrganizationRequestPayload,
  UpdateOrganizationSuccessPayload,
  DeleteCurrentUserCallbacks,
  DeleteCurrentUserFailurePayload,
  DeleteCurrentUserSuccessPayload,
  EmailConfirmationCallbacks,
  EmailConfirmationFailurePayload,
  EmailConfirmationRequestPayload,
  EmailConfirmationSuccessPayload,
  DeleteOrganizationCallbacks,
  DeleteOrganizationFailurePayload,
  DeleteOrganizationSuccessPayload,
  CurrentUserDataFailure,
  CurrentUserDataFailurePayload,
  CurrentUserDataSuccess,
  CurrentUserDataSuccessPayload,
  DeleteUserProfilePictureCallbacks,
  DeleteUserProfilePictureFailurePayload,
  DeleteUserProfilePictureSuccessPayload,
  CleanError,
  ResetState,
  ChangePasswordRequestPayload,
  ChangePasswordCallbacks,
  ChangePasswordFailurePayload,
  ChangePasswordSuccessPayload,
} from "./types";

export const loginRequest = (
  payload: LoginRequestPayload,
  callbacks: LoginCallbacks
): LoginRequest => ({
  type: LOGIN_REQUEST,
  payload,
  callbacks,
});

export const loginSuccess = (payload: LoginSuccessPayload): LoginSuccess => ({
  type: LOGIN_SUCCESS,
  payload,
});

export const loginFailure = (payload: LoginFailurePayload): LoginFailure => ({
  type: LOGIN_FAILURE,
  payload,
});

export const signUpRequest = (
  payload: SignUpRequestPayload,
  callbacks: SignUpCallbacks
): SignUpRequest => ({
  type: SIGN_UP_REQUEST,
  payload,
  callbacks,
});

export const signUpSuccess = (
  payload: SignUpSuccessPayload
): SignUpSuccess => ({
  type: SIGN_UP_SUCCESS,
  payload,
});

export const signUpFailure = (
  payload: SignUpFailurePayload
): SignUpFailure => ({
  type: SIGN_UP_FAILURE,
  payload,
});

export const emailConfirmationRequest = (
  payload: EmailConfirmationRequestPayload,
  callbacks?: EmailConfirmationCallbacks
) => ({
  type: EMAIL_CONFIRMATION_REQUEST,
  payload,
  callbacks,
});

export const emailConfirmationSuccess = (
  payload: EmailConfirmationSuccessPayload
) => ({
  type: EMAIL_CONFIRMATION_SUCCESS,
  payload,
});

export const emailConfirmationFailure = (
  payload: EmailConfirmationFailurePayload
) => ({
  type: EMAIL_CONFIRMATION_FAILURE,
  payload,
});

export const currentUserDataRequest = () => ({
  type: CURRENT_USER_DATA_REQUEST,
});

export const currentUserDataSuccess = (
  payload: CurrentUserDataSuccessPayload
): CurrentUserDataSuccess => ({
  type: CURRENT_USER_DATA_SUCCESS,
  payload,
});

export const currentUserDataFailure = (
  payload: CurrentUserDataFailurePayload
): CurrentUserDataFailure => ({
  type: CURRENT_USER_DATA_FAILURE,
  payload,
});

export const updateUserDataRequest = (
  payload: UpdateUserDataRequestPayload,
  callbacks?: UpdateUserDataCallbacks
): UpdateUserDataRequest => ({
  type: UPDATE_USER_DATA_REQUEST,
  payload,
  callbacks,
});

export const updateUserDataSuccess = (
  payload: UpdateUserDataSuccessPayload
): UpdateUserDataSuccess => ({
  type: UPDATE_USER_DATA_SUCCESS,
  payload,
});

export const updateUserDataFailure = (
  payload: UpdateUserDataFailurePayload
): UpdateUserDataFailure => ({
  type: UPDATE_USER_DATA_FAILURE,
  payload,
});

export const deleteUserProfilePictureRequest = (
  callbacks?: DeleteUserProfilePictureCallbacks
) => ({
  type: DELETE_USER_PROFILE_PICTURE_REQUEST,
  callbacks,
});

export const deleteUserProfilePictureSuccess = (
  payload: DeleteUserProfilePictureSuccessPayload
) => ({
  type: DELETE_USER_PROFILE_PICTURE_SUCCESS,
  payload,
});

export const deleteUserProfilePictureFailure = (
  payload: DeleteUserProfilePictureFailurePayload
) => ({
  type: DELETE_USER_PROFILE_PICTURE_FAILURE,
  payload,
});

export const deleteCurrentUserRequest = (
  callbacks?: DeleteCurrentUserCallbacks
) => ({
  type: DELETE_CURRENT_USER_REQUEST,
  callbacks,
});

export const deleteCurrentUserSuccess = (
  payload: DeleteCurrentUserSuccessPayload
) => ({
  type: DELETE_CURRENT_USER_SUCCESS,
  payload,
});

export const deleteCurrentUserFailure = (
  payload: DeleteCurrentUserFailurePayload
) => ({
  type: DELETE_CURRENT_USER_FAILURE,
  payload,
});

export const passwordResetRequest = (
  payload: PasswordResetRequestPayload,
  callbacks: PasswordResetCallbacks
): PasswordResetRequest => ({
  type: PASSWORD_RESET_REQUEST,
  payload,
  callbacks,
});

export const passwordResetSuccess = (
  payload: PasswordResetSuccessPayload
): PasswordResetSuccess => ({
  type: PASSWORD_RESET_SUCCESS,
  payload,
});

export const passwordResetFailure = (
  payload: PasswordResetFailurePayload
): PasswordResetFailure => ({
  type: PASSWORD_RESET_FAILURE,
  payload,
});

export const checkPasswordResetRequest = (
  payload: CheckPasswordResetRequestPayload,
  callbacks: CheckPasswordResetCallbacks
): CheckPasswordResetRequest => ({
  type: CHECK_PASSWORD_RESET_REQUEST,
  payload,
  callbacks,
});

export const checkPasswordResetSuccess = (
  payload: CheckPasswordResetSuccessPayload
): CheckPasswordResetSuccess => ({
  type: CHECK_PASSWORD_RESET_SUCCESS,
  payload,
});

export const checkPasswordResetFailure = (
  payload: CheckPasswordResetFailurePayload
): CheckPasswordResetFailure => ({
  type: CHECK_PASSWORD_RESET_FAILURE,
  payload,
});

export const newPasswordRequest = (
  payload: NewPasswordRequestPayload,
  callbacks: NewPasswordCallbacks
): NewPasswordRequest => ({
  type: NEW_PASSWORD_REQUEST,
  payload,
  callbacks,
});

export const newPasswordSuccess = (
  payload: NewPasswordSuccessPayload
): NewPasswordSuccess => ({
  type: NEW_PASSWORD_SUCCESS,
  payload,
});

export const newPasswordFailure = (
  payload: NewPasswordFailurePayload
): NewPasswordFailure => ({
  type: NEW_PASSWORD_FAILURE,
  payload,
});

export const sendVerificationRequest = (): SendVerificationRequest => ({
  type: SEND_VERIFICATION_SMS_REQUEST,
});

export const sendVerificationSuccess = (
  payload: SendVerificationSuccessPayload
): SendVerificationSuccess => ({
  type: SEND_VERIFICATION_SMS_SUCCESS,
  payload,
});

export const sendVerificationFailure = (
  payload: SendVerificationFailurePayload
): SendVerificationFailure => ({
  type: SEND_VERIFICATION_SMS_FAILURE,
  payload,
});

export const sendVerificationCodeRequest = (
  payload: SendVerificationCodeRequestPayload,
  callbacks: SendVerificationCodeCallbacks
): SendVerificationCodeRequest => ({
  type: SEND_VERIFICATION_CODE_REQUEST,
  payload,
  callbacks,
});

export const sendVerificationCodeSuccess = (
  payload: SendVerificationCodeSuccessPayload
): SendVerificationCodeSuccess => ({
  type: SEND_VERIFICATION_CODE_SUCCESS,
  payload,
});

export const sendVerificationCodeFailure = (
  payload: SendVerificationCodeFailurePayload
): SendVerificationCodeFailure => ({
  type: SEND_VERIFICATION_CODE_FAILURE,
  payload,
});

export const getOrganizationRequest = () => ({
  type: GET_ORGANIZATION_REQUEST,
});

export const getOrganizationSuccess = (
  payload: GetOrganizationSuccessPayload
) => ({
  type: GET_ORGANIZATION_SUCCESS,
  payload,
});

export const getOrganizationFailure = (
  payload: GetOrganizationFailurePayload
) => ({
  type: GET_ORGANIZATION_FAILURE,
  payload,
});

export const updateOrganizationRequest = (
  payload: UpdateOrganizationRequestPayload,
  callbacks?: UpdateOrganizationCallbacks
) => ({
  type: UPDATE_ORGANIZATION_REQUEST,
  payload,
  callbacks,
});

export const updateOrganizationSuccess = (
  payload: UpdateOrganizationSuccessPayload
) => ({
  type: UPDATE_ORGANIZATION_SUCCESS,
  payload,
});

export const updateOrganizationFailure = (
  payload: UpdateOrganizationFailurePayload
) => ({
  type: UPDATE_ORGANIZATION_FAILURE,
  payload,
});

export const deleteOrganizationRequest = (
  callbacks?: DeleteOrganizationCallbacks
) => ({
  type: DELETE_ORGANIZATION_REQUEST,
  callbacks,
});

export const deleteOrganizationSuccess = (
  payload: DeleteOrganizationSuccessPayload
) => ({
  type: DELETE_ORGANIZATION_SUCCESS,
  payload,
});

export const deleteOrganizationFailure = (
  payload: DeleteOrganizationFailurePayload
) => ({
  type: DELETE_ORGANIZATION_FAILURE,
  payload,
});

export const changePasswordRequest = (
  payload: ChangePasswordRequestPayload,
  callbacks?: ChangePasswordCallbacks
) => ({
  type: CHANGE_PASSWORD_REQUEST,
  payload,
  callbacks,
});

export const changePasswordSuccess = (
  payload: ChangePasswordSuccessPayload
) => ({
  type: CHANGE_PASSWORD_SUCCESS,
  payload,
});

export const changePasswordFailure = (
  payload: ChangePasswordFailurePayload
) => ({
  type: CHANGE_PASSWORD_FAILURE,
  payload,
});

export const cleanError = (): CleanError => ({
  type: CLEAN_ERROR,
});

export const resetState = (): ResetState => ({
  type: RESET_STATE,
});

export const logout = (): Logout => ({
  type: LOG_OUT,
});
