import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { MenuItem, SelectChangeEvent, Typography } from "@mui/material";
import { isMobile } from "mobile-device-detect";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Button from "../Button";
import Select from "../Select";
import ModalWrapper from "./ModalWrapper";

interface ModalProps {
  closeModal: () => void;
  setSortOrder: (order: string) => void;
  setSortBy: (elem: any) => void;
  setSortBySearch?: (text: string) => void;
  setIsLoading: any;
  sortTable?: any[];
  isOpen: boolean;
  modalActionType: {
    action: string;
    page: string;
  };
  sortOrder: string;
  sortBy: string;
  sortBySearch?: string;
}

const SortByModal: React.FC<ModalProps> = ({
  sortTable,
  isOpen,
  modalActionType,
  sortOrder,
  sortBy,
  sortBySearch,
  setIsLoading,
  closeModal,
  setSortOrder,
  setSortBy,
  setSortBySearch,
}) => {
  const [value, setValue] = useState(sortTable[0].value);
  const { pathname } = useLocation();

  const handleSumbit = () => {
    const val = value.split(" ");
    closeModal();
    setSortBy(val[0]);
    setSortOrder(val[1]);
    setIsLoading(true);
    if (pathname !== "/report") {
      setSortBySearch(val[2]);
    }
  };

  useEffect(() => {
    if (pathname === "/report") setValue(sortBy + " " + sortOrder);
    else setValue(sortBy + " " + sortOrder + " " + sortBySearch);
  }, []);

  return (
    <>
      <ModalWrapper
        closeModal={closeModal}
        isOpen={isOpen}
        maxWidth={isMobile ? "350px" : "500px"}
        modalActionType={modalActionType}
      >
        <>
          <Select
            value={value}
            fullWidth
            height="46px"
            fontSize="16px"
            onChange={(event: SelectChangeEvent) =>
              setValue(event.target.value)
            }
            IconComponent={ArrowDropDownIcon}
          >
            {sortTable.map((item) => (
              <MenuItem key={item.id} value={item.value}>
                {item.title}
              </MenuItem>
            ))}
          </Select>
          <Button
            style={{ marginTop: "10px" }}
            fullWidth
            size="medium"
            variant="contained"
            color="primary"
            onClick={handleSumbit}
          >
            <Typography>Apply</Typography>
          </Button>
        </>
      </ModalWrapper>
    </>
  );
};

export default SortByModal;
