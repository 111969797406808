import React, { useEffect, useState } from "react";
import DeviceWrapper from "../../DeviceWrapper";
import { isMobile } from "mobile-device-detect";
import DashboardMobile from "./DashboardMobile";
import DashboardWeb from "./DashboardWeb";
import { intervalToDuration } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchTodaysClockRequest,
  fetchWeeklyClockRequest,
} from "src/store/clocks/actions";
import {
  checkStartWatchRequest,
  startWatchRequest,
  stopWatchRequest,
} from "src/store/timeWatch/actions";
import { getTimeWatchSelector } from "src/store/timeWatch/selectors";
import { ModalTodaysClockRecord } from "src/components";
import { clientTimezone, parseTimeForClock } from "src/utils";

const Dashboard = () => {
  const [modalsState, setModalsState] = React.useState<string>("");
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalData, setModalData] = useState<string>(null);
  const [currentTab, setCurrentTab] = useState(0);
  const [disbledClock, setDisabledClock] = useState<boolean>(true);
  const dispatch = useDispatch();
  const timeWatch = useSelector(getTimeWatchSelector);
  const [currentWatch, setCurrentWatch] = useState(null);
  const [userPosition, setUserPosition] = useState(null);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  const handleOpenModal = (modalState: string, data?: any, tab?: number) => {
    setOpenModal(true);
    setModalData(data);
    setModalsState(modalState);
    if (tab || tab === 0) setCurrentTab(tab);
  };

  const handleFetch = () => {
    if (isMobile) {
      if (currentTab === 0) {
        dispatch(fetchTodaysClockRequest({ timezone: clientTimezone }));
      } else {
        dispatch(fetchWeeklyClockRequest({ timezone: clientTimezone }));
      }
    } else {
      dispatch(fetchTodaysClockRequest({ timezone: clientTimezone }));
      dispatch(fetchWeeklyClockRequest({ timezone: clientTimezone }));
    }
  };

  const checkStartWatchFetch = async () => {
    dispatch(checkStartWatchRequest());
  };

  useEffect(() => {
    if (isMobile) handleFetch();
  }, [currentTab]);

  useEffect(() => {
    if (!isMobile) handleFetch();
    checkStartWatchFetch();
  }, []);

  const toggleWatch = () => {
    if (timeWatch?.startTime && !timeWatch?.endTime) {
      const id = setInterval(updateWatch, 1000);
      return () => {
        setCurrentWatch(null);
        clearInterval(id);
      };
    }
  };

  const showLocation = () => {
    const geolocation = sessionStorage.getItem("geolocation");
    if (geolocation) {
      const geo = JSON.parse(geolocation);
      setUserPosition({
        lat: geo?.lat,
        lng: geo?.lng,
      });
    } else {
      setUserPosition({
        lat: null,
        lng: null,
      });
    }
    setDisabledClock(false);
  };

  useEffect(() => {
    showLocation();
  }, [sessionStorage.getItem("geolocation")]);

  useEffect(toggleWatch, [timeWatch]);

  const handleStartWatch = () => {
    setDisabledClock(true);
    dispatch(
      startWatchRequest(
        {
          startTime: null,
          startTimeLatitude: userPosition?.lat || null,
          startTimeLongitude: userPosition?.lng || null,
          startTimeTimezone: clientTimezone,
        },
        {
          onSuccess: () => {
            handleFetch();
            setDisabledClock(false);
          },
        }
      )
    );
  };

  const handleStopWatch = () => {
    setDisabledClock(true);
    dispatch(
      stopWatchRequest(
        {
          id: timeWatch.id,
          endTimeLatitude: userPosition?.lat,
          endTimeLongitude: userPosition?.lng,
          endTimeTimezone: clientTimezone,
        },
        {
          onSuccess: () => {
            handleFetch();
            setDisabledClock(false);
          },
        }
      )
    );
  };

  const zeroPad = (num) => String(num).padStart(2, "0");
  const updateWatch = () => {
    const startUtc = parseTimeForClock(timeWatch.startTime);
    const interval = intervalToDuration({ start: startUtc, end: new Date() });
    const totalHours =
      (interval.days ? interval.days * 24 : 0) + interval.hours;
    const formatInterval = `${zeroPad(totalHours)}:${zeroPad(
      interval.minutes
    )}:${zeroPad(interval.seconds)}`;
    setCurrentWatch(formatInterval);
  };

  return (
    <>
      <DeviceWrapper>
        <>
          {isMobile ? (
            <DashboardMobile
              handleStartWatch={handleStartWatch}
              handleStopWatch={handleStopWatch}
              handleOpenModal={handleOpenModal}
              handleTabChange={handleTabChange}
              handleFetch={handleFetch}
              checkStartWatchFetch={checkStartWatchFetch}
              currentWatch={currentWatch}
              currentTab={currentTab}
              disbledClock={disbledClock}
            />
          ) : (
            <DashboardWeb
              handleStartWatch={handleStartWatch}
              handleStopWatch={handleStopWatch}
              handleOpenModal={handleOpenModal}
              currentWatch={currentWatch}
              disbledClock={disbledClock}
            />
          )}
          {openModal && (
            <ModalTodaysClockRecord
              onFetchSuccess={handleFetch}
              closeModal={() => setOpenModal(false)}
              isOpen={openModal}
              modalData={modalData}
              modalsState={modalsState}
              setModalsState={setModalsState}
              currentTab={currentTab}
            />
          )}
        </>
      </DeviceWrapper>
    </>
  );
};

export default Dashboard;
