import { all, fork } from "redux-saga/effects";

import authSaga from "./auth/sagas";
import clocksSaga from "./clocks/sagas";
import watchSaga from "./timeWatch/sagas";
import attachmentsSaga from "./attachments/sagas";
import commentsSaga from "./comments/sagas";
import projectsSaga from "./projects/sagas";
import usersSaga from "./users/sagas";
import reportSaga from "./report/sagas";
import subscriptionSaga from "./subscription/sagas";

export function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(clocksSaga),
    fork(watchSaga),
    fork(attachmentsSaga),
    fork(commentsSaga),
    fork(projectsSaga),
    fork(usersSaga),
    fork(reportSaga),
    fork(subscriptionSaga),
  ]);
}
