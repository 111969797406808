import React from "react";
import { SelectProps, Select as MuiSelect } from "@mui/material";

interface Props extends SelectProps {
  fontSize?: string | { [key: string]: string };
  mt?: string;
  height?: string | { [key: string]: string };
}

const ITEM_HEIGHT = 36;
const MOBILE_ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MENU_ITEMS = 5;

const useStyles = ({ fontSize = "14px", mt, height }: Props) => ({
  select: {
    mt,
    height,
    fontSize,
    ".MuiSelect-icon": {
      top: "calc(50% - 6px)",
      fontSize: "16px",
    },
    ".MuiSelect-select": {
      p: "10px",
    },
    ".MuiOutlinedInput-notchedOutline": {
      border: `1px solid #D9D9D9`,
    },
    "&:hover": {
      ".MuiOutlinedInput-notchedOutline": {
        borderColor: "#D9D9D9",
      },
    },
  },
});

const Select: React.FC<Props> = ({ fontSize, mt, height, ...selectProps }) => {
  const styles = useStyles({ fontSize, mt, height });
  return (
    <MuiSelect
      {...selectProps}
      sx={styles.select}
      MenuProps={{
        PaperProps: {
          sx: {
            maxHeight: {
              xs: MOBILE_ITEM_HEIGHT * MENU_ITEMS + ITEM_PADDING_TOP,
              sm: ITEM_HEIGHT * MENU_ITEMS + ITEM_PADDING_TOP,
            },
          },
        },
      }}
    />
  );
};

export default Select;
