import { Box, Theme, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { getSubscriptionSelector } from "src/store/subscription/selectors";
import { isFieldHasError } from "src/utils";
import Input from "./Input";

const styles = {
  paydPlanCounter: {
    mt: "20px",
    p: "15px 20px",
    border: (theme: Theme) => `1px solid ${theme.palette.background.default}`,
    borderRadius: "4px",
  },
  CardData: {
    display: "flex",
    flexWrap: { xs: "wrap", md: "nowrap" },
    justifyContent: { xs: "space-between", md: "normal" },
    expires: {
      width: { xs: "100%", md: "50%" },
    },
    cvc: {
      width: { xs: "calc(50% - 5px)", md: "25%" },
      flexShrink: 0,
    },
    zipCode: {
      width: { xs: "calc(50% - 5px)", md: "25%" },
      flexShrink: 0,
    },
  },
  counterText: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: { xs: "column", md: "row" },
  },
};

interface Props {
  formik: any;
}

const CreditCardForm: React.FC<Props> = ({ formik }) => {
  const subscription = useSelector(getSubscriptionSelector);

  return (
    <>
      <Box>
        <Input
          type="text"
          label="Cardholder Name"
          fullWidth
          required
          size="small"
          id="cardHolderName"
          value={formik.values.cardHolderName}
          onChange={formik.handleChange}
          error={isFieldHasError({
            formik,
            fieldName: "cardHolderName",
            isGlobalError: Boolean(subscription.error),
          })}
          helperText={
            formik.touched.cardHolderName && formik.errors.cardHolderName
          }
        />
      </Box>
      <Box mt={"20px"}>
        <Input
          mask={
            formik.values.cardNumber.charAt(0) === "3"
              ? "99999 99999 99999"
              : "9999 9999 9999 9999"
          }
          label="Card Number"
          fullWidth
          required
          size="small"
          id="cardNumber"
          placeholder=""
          isDisabled={false}
          value={formik.values.cardNumber}
          onPaste={(e) => {
            e.persist();
            setTimeout(() => formik.handleChange(e), 0);
          }}
          onChange={formik.handleChange}
          error={isFieldHasError({
            formik,
            fieldName: "cardNumber",
            isGlobalError: Boolean(subscription.error),
          })}
          helperText={formik.touched.cardNumber && formik.errors.cardNumber}
        />
      </Box>
      <Box sx={styles.CardData}>
        <Box mt="20px" sx={styles.CardData.expires}>
          <Input
            mask="99 / 9999"
            label="Expires"
            fullWidth
            required
            size="small"
            id="expDate"
            placeholder=""
            isDisabled={false}
            value={formik.values.expDate}
            onChange={formik.handleChange}
            error={isFieldHasError({
              formik,
              fieldName: "expDate",
              isGlobalError: Boolean(subscription.error),
            })}
            helperText={formik.touched.expDate && formik.errors.expDate}
          />
        </Box>
        <Box mt="20px" mx={{ xs: "0", md: "20px" }} sx={styles.CardData.cvc}>
          <Input
            mask={formik.values.cardNumber.charAt(0) === "3" ? "9999" : "999"}
            type="password"
            label="CVC"
            fullWidth
            size="small"
            id="cvc"
            placeholder=""
            required
            isDisabled={false}
            value={formik.values.cvc}
            onChange={formik.handleChange}
            onPaste={(e) => {
              e.persist();
              setTimeout(() => formik.handleChange(e), 0);
            }}
            error={isFieldHasError({
              formik,
              fieldName: "cvc",
              isGlobalError: Boolean(subscription.error),
            })}
            helperText={formik.touched.cvc && formik.errors.cvc}
          />
        </Box>
        <Box mt="20px" sx={styles.CardData.zipCode}>
          <Input
            type="text"
            label="Zip Code"
            fullWidth
            size="small"
            id="zip"
            required
            value={formik.values.zip}
            onChange={formik.handleChange}
            error={isFieldHasError({
              formik,
              fieldName: "zip",
              isGlobalError: Boolean(subscription.error),
            })}
            helperText={formik.touched.zip && formik.errors.zip}
          />
        </Box>
      </Box>
      {subscription.error && (
        <Box mt="20px" display="flex" justifyContent="center">
          <Typography fontSize="14px" color="error">
            {subscription.error}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default CreditCardForm;
