import { Theme } from "@mui/material";

export const styles = {
  isLoading: {
    mt: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  licenseStatus: {
    backgroundColor: (theme: Theme) => theme.palette.info.main,
    padding: "8px 21px",
    borderRadius: "4px",
    color: (theme: Theme) => theme.palette.info.contrastText,
    fontWeight: "500",
    fontSize: "16px",
    cursor: "pointer",
  },
  attachmentWrapper: {
    mb: "30px",
  },
  attachmentList: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    mt: "16px",
  },
  attachmentsDay: {
    fontSize: "20px",
    fontWeight: 700,
    color: "#000",
  },
  attachmentContainer: {
    width: "49%",
    maxHeight: "170px",
    color: "#F0F0F0",
    borderRadius: "4px",
    position: "relative",
    mb: "10px",
  },
  whiteBgComment: {
    position: "absolute",
    bottom: "16px",
    right: "16px",
    backgroundColor: "#fff",
    borderRadius: "4px",
    padding: "7px 10px",
  },
  whiteBgReceipt: {
    position: "absolute",
    top: "16px",
    right: "16px",
    backgroundColor: "#fff",
    borderRadius: "4px",
    padding: "2px 10px",
  },
  caption: {
    fontSize: "10px",
    lineHeight: "22px",
    fontWeight: 400,
    color: "#0D6FF0",
  },
  attachment: {
    maxWidth: "100%",
    maxHeight: "100%",
    width: "100%",
    height: "170px",
    objectFit: "contain",
    borderRadius: "4px",
    backgroundColor: "#F0F0F0",
  },
  noData: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "104px",
    backgroundColor: "#F0F0F0",
    borderRadius: "4px",
  },
  noDataText: {
    fontWeight: 600,
    fontSize: "14px",
    color: "#8C8C8C",
  },
  addButtonContainer: {
    display: "flex",
    alignItems: "center",
  },
  addButtonText: {
    fontWeight: 500,
    fontSize: { xs: "14px", md: "16px" },
    ml: "10px",
    textTransform: "initial",
  },
  loadMore: {
    fontWeight: 500,
    color: "#0D6FF0",
    textTransform: "capitalize",
  },
  appBarWrapper: {
    backgroundColor: (theme) => theme.palette.background.paper,
    boxShadow: "none",
    border: (theme) => `1px solid ${theme.palette.background.default}`,
    paddingTop: "33px",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    px: "20px",
  },
  pageTitle: {
    color: (theme) => theme.palette.text.primary,
    fontSize: "16px",
    fontWeight: "500",
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
  },
  menuIcon: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  attachmentData: {
    mt: "10px",
    fontWeight: 400,
    fontSize: "14px",
    color: "#888",
  },
  attachmentBox: {
    padding: "16px",
    backgroundColor: "#FFF",
    border: "1px solid #F0F0F0",
    borderRadius: "4px",
    position: "relative",
    m: "10px 0",
  },
  attachmentText: {
    fontWeight: 400,
    fontSize: "14px",
    color: "#000",
    wordBreak: "break-word",
  },
  receiptToggle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
    backgroundColor: "#FFF",
    border: "1px solid #F0F0F0",
    borderRadius: "4px",
    position: "relative",
    mt: "24px",
  },
  boxContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    mt: "24px",
  },
  buttonStyle: {
    backgroundColor: "#FFF",
    width: "50%",
    margin: "0 0 0 5px",
    border: "1px solid #0D6FF0",
    boxShadow: "none",
  },
  arrowStyle: {
    fontWeight: 600,
    color: "#0D6FF0",
    fontSize: "14px",
    mx: "6px",
  },
  boxAction: {
    borderTop: "1px solid #F0F0F0",
    p: "20px 40px 25px",
    display: "flex",
    justifyContent: "space-between",
  },
  btnEdit: {
    backgroundColor: "#FAAD14",
    width: "50%",
    margin: "10px 5px 0 0",
  },
  btnDelete: {
    backgroundColor: "#FF4D4F",
    width: "50%",
    margin: "10px 0 0 5px",
  },
};
