import * as React from "react";
import { SVGProps } from "react";

interface Props {
  color?: string;
}

const PlusForWeb: React.FC<Props> = (
  { color },
  props: SVGProps<SVGSVGElement>
) => (
  <svg data-sqa-id="plus-icon" width={14} height={14} fill="none" {...props}>
    <g clipPath="url(#a)">
      <path
        d="M7.61 0H6.39c-.11 0-.164.052-.164.156V6.26H.163c-.109 0-.163.052-.163.156v1.166c0 .104.054.156.163.156h6.063v6.105c0 .104.055.156.163.156H7.61c.109 0 .163-.052.163-.156V7.74h6.064c.109 0 .163-.052.163-.156V6.417c0-.104-.054-.156-.163-.156H7.773V.156C7.773.052 7.719 0 7.61 0Z"
        fill={color ? color : "#fff"}
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill={color ? color : "#fff"} d="M0 0h14v14H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default PlusForWeb;
