import { Theme } from "@mui/material";
import { isMobile } from "mobile-device-detect";

export const styles = {
  historyTabContainer: {
    marginTop: "30px",
    padding: "30px 20px",
    backgroundColor: "#fff",
    borderRadius: "4px",
    border: "1px solid #F0F0F0",
  },
  boxTodayButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #F0F0F0",
    backgroundColor: "#fff",
    borderRadius: "4px",
    padding: "12px",
    marginBottom: "10px",
  },
  boxTodayButtonText: {
    fontWeight: 500,
    fontSize: "14px",
    color: "#0D6FF0",
    borderBottom: "1px solid #0D6FF0",
    cursor: "pointer",
  },
  boxHistoryDetails: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #F0F0F0",
    backgroundColor: "#fff",
    borderRadius: "4px",
    padding: "20px",
    marginTop: "30px",
  },
  historyDetails: {
    fontSize: "16px",
    color: "#8C8C8C",
    cursor: "pointer",
  },
  appBarWrapper: {
    backgroundColor: (theme) => theme.palette.background.paper,
    boxShadow: "none",
    borderBottom: (theme) => `1px solid ${theme.palette.background.default}`,
    paddingTop: "34px",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    px: "20px",
  },
  pageTitle: {
    color: (theme) => theme.palette.text.primary,
    fontSize: "16px",
    fontWeight: "500",
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
  },
  licenseStatus: {
    backgroundColor: (theme: Theme) => theme.palette.info.main,
    padding: "8px 21px",
    borderRadius: "4px",
    color: (theme: Theme) => theme.palette.info.contrastText,
    fontWeight: "500",
    fontSize: "16px",
    cursor: "pointer",
  },
  boxHistoryDayTotal: {
    display: "flex",
    flexDirection: "column",
    border: "1px solid #F0F0F0",
    backgroundColor: "#fff",
    borderRadius: "4px",
    padding: "22px",
    marginTop: "20px",
    marginBottom: "10px",
  },
  historyDayTotalTitle: {
    fontSize: "14px",
    color: "#8C8C8C",
  },
  historyDayTotalTime: {
    fontSize: "20px",
    fontWeight: 700,
    color: "#0D6FF0",
  },
  tabsWrapper: {
    ".MuiTabs-flexContainer": {
      width: "100%",
      display: "flex",
      justifyContent: isMobile ? "space-between" : "flex-start",
      columnGap: "15px",
      borderBottom: isMobile
        ? "none"
        : (theme) => `1px solid ${theme.palette.background.default}`,
    },
  },
  tabWrapper: {
    width: isMobile ? "33%" : "auto",
    fontSize: isMobile ? "12px" : "16px",
    fontWeight: isMobile ? 600 : 400,
    color: isMobile ? "#000" : "#262626",
    padding: isMobile ? 0 : "0 16px",
    textTransform: "none",
    borderBottom: isMobile ? "2px solid #000" : "1px solid #D9D9D9",
    mb: isMobile ? "0px" : "-1px",
  },
  menuIcon: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  noData: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "104px",
    backgroundColor: "#F0F0F0",
    borderRadius: "4px",
  },
  noDataText: {
    fontWeight: 600,
    fontSize: "14px",
    color: "#8C8C8C",
  },
  pageWrapper: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  },
};
