export const styles = {
  actionButtonSave: {
    display: "flex",
    padding: "3px 40px",
  },
  formWrapper: {
    mb: "20px",
    p: "30px 20px 20px",
    border: "1px solid #F0F0F0",
    borderRadius: "4px",
  },
  marginTop: {
    mt: "20px",
  },
  boxContainer: {
    mt: "20px",
    display: "flex",
    justifyContent: "space-between",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    marginTop: "20px",
  },
};
