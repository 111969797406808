import {
  Box,
  FormControl,
  MenuItem,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import React, { useEffect, useState } from "react";
import ModalWrapper from "./ModalWrapper";
import Button from "../Button";
import theme from "src/theme";
import { useDispatch, useSelector } from "react-redux";
import { linkTimeRecordByUserAndDayRequest } from "src/store/clocks/actions";
import Select from "../Select";
import { ProjectAll } from "src/types";
import { fetchProjectAllRequest } from "src/store/projects/actions";
import { getProjectSelector } from "src/store/projects/selectors";

interface ModalProps {
  closeModal: () => void;
  fetchSuccess: () => void;
  isOpen: boolean;
  modalActionType: {
    action: string;
    page: string;
  };
  elem: any[];
}

const LinkUserDayPairToProjectModal: React.FC<ModalProps> = ({
  isOpen,
  modalActionType,
  closeModal,
  fetchSuccess,
  elem,
}) => {
  const dispatch = useDispatch();
  const projectState = useSelector(getProjectSelector);
  const [projectValue, setProjectValue] = useState("");
  const handleChangeSelectProject = (event: SelectChangeEvent) => {
    setProjectValue(event.target.value as string);
  };

  const handleLinkProjects = () => {
    dispatch(
      linkTimeRecordByUserAndDayRequest(
        {
          data: {
            projectId: projectValue,
            userDayPairs: elem,
          },
        },
        {
          onSuccess,
        }
      )
    );
  };

  const onSuccess = () => {
    fetchSuccess();
    closeModal();
  };

  useEffect(() => {
    dispatch(fetchProjectAllRequest());
  }, []);

  return (
    <>
      <ModalWrapper
        closeModal={closeModal}
        isOpen={isOpen}
        modalActionType={modalActionType}
        maxWidth="450px"
      >
        <>
          <Box mb={"20px"}>
            <Typography fontSize={14} mb={"8px"}>
              Select Project
            </Typography>
            <FormControl fullWidth>
              <Select
                value={projectValue}
                onChange={handleChangeSelectProject}
                height={"40px"}
                IconComponent={KeyboardArrowDownIcon}
              >
                {projectState.projectAll?.map((project: ProjectAll) => {
                  if (project?.subProjects) {
                    let arrProjectGroup = [...project.subProjects];
                    arrProjectGroup.unshift(project);
                    return arrProjectGroup.map(
                      (subproject: ProjectAll, i: number) => {
                        return (
                          <MenuItem
                            sx={{ pl: i ? "30px" : "16px" }}
                            key={subproject.id}
                            value={subproject.id}
                          >
                            {`${subproject.projectName} (${subproject.numericId})`}
                          </MenuItem>
                        );
                      }
                    );
                  } else
                    return (
                      <MenuItem key={project.id} value={project.id}>
                        {`${project.projectName} (${project.numericId})`}
                      </MenuItem>
                    );
                })}
              </Select>
            </FormControl>
          </Box>
          <Box mt="20px" sx={{ textAlign: "right" }}>
            <Button
              variant="outlined"
              size="small"
              color="secondary"
              style={{
                marginRight: "20px",
                padding: "4px 40px",
              }}
              onClick={closeModal}
            >
              <Typography color={theme.palette.text.primary}>Cancel</Typography>
            </Button>
            <Button
              variant="contained"
              size="small"
              color="primary"
              style={{
                padding: "12px 40px",
                pointerEvents: !projectValue ? "none" : "auto",
              }}
              onClick={handleLinkProjects}
            >
              <Typography>Link to Project</Typography>
            </Button>
          </Box>
        </>
      </ModalWrapper>
    </>
  );
};

export default LinkUserDayPairToProjectModal;
