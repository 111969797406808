import {
  Box,
  CircularProgress,
  MenuItem,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getAttachmentsSelector } from "src/store/attachments/selectors";
import AttachmentItem from "./AttachmentItem";
import Select from "../Select";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AttachmetSlider from "./AttachmetSlider";
import { getUserSelector } from "src/store/auth/selectors";
import { Plus } from "src/assets/icons";
import Button from "../Button";
import AddAttachmet from "../ActionsModal/AddAttachment";
import { isMobile } from "mobile-device-detect";

const styles = {
  noAttachment: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    mt: "20px",
    width: "100%",
    height: isMobile ? "140px" : "340px",
    backgroundColor: "#F0F0F0",
    borderRadius: "4px",
  },
  noAttachmentText: {
    fontWeight: 400,
    fontSize: isMobile ? "14px" : "18px",
    color: "#8C8C8C",
  },
};

interface Props {
  handleFetch?: () => void;
  setIsReceipt?: any;
  isReceipt?: string;
}

const Attachments: React.FC<Props> = ({
  handleFetch,
  isReceipt,
  setIsReceipt,
}) => {
  const attachmentsState = useSelector(getAttachmentsSelector);
  const user = useSelector(getUserSelector);
  const [openAttachmentModal, setOpenAttachmentModal] =
    useState<boolean>(false);
  const [openAttachmetSlider, setOpenAttachmetSlider] =
    useState<boolean>(false);
  const [startCurrentAttachment, setStartCurrentAttachment] =
    useState<string>("");
  const modalActionType = {
    action: "Upload",
    page: "Attachment",
  };

  return (
    <>
      <Box>
        <Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent={
              user.isAdmin && localStorage.getItem("roleMode") === "admin"
                ? "space-between"
                : "flex-end"
            }
          >
            {user.isAdmin && localStorage.getItem("roleMode") === "admin" && (
              <Select
                data-sqa-id="show-everything-select"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                fullWidth={isMobile ? true : false}
                value={isReceipt}
                onChange={(e: SelectChangeEvent) =>
                  setIsReceipt(e.target.value)
                }
                height={"40px"}
                IconComponent={ArrowDropDownIcon}
              >
                <MenuItem
                  data-sqa-id="show-everything-item"
                  color="#262626"
                  value="Show Everything"
                >
                  Show Everything
                </MenuItem>
                <MenuItem
                  data-sqa-id="show-receipts-item"
                  color="#262626"
                  value="Show Receipts"
                >
                  Show Receipts
                </MenuItem>
              </Select>
            )}
            {(!user.isAdmin ||
              (user.isAdmin &&
                localStorage.getItem("roleMode") === "user")) && (
              <Button
                data-sqa-id="add-attachment-btn"
                size="small"
                color="primary"
                variant="contained"
                onClick={() => setOpenAttachmentModal(true)}
              >
                <Plus />
                <Typography
                  data-sqa-id="add-attachment-btn-text"
                  fontSize={{ xs: "14px", md: "16px" }}
                  ml={"10px"}
                >
                  Add Attachment
                </Typography>
              </Button>
            )}
          </Box>
          {attachmentsState.isLoading ? (
            <Box
              data-sqa-id="circular-progress-loader"
              display="flex"
              alignItems="center"
              justifyContent="center"
              mt="20px"
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Box
                display="flex"
                justifyContent="flex-start"
                flexWrap="wrap"
                columnGap="10px"
              >
                {Array.isArray(attachmentsState.attachments) &&
                attachmentsState.attachments.length ? (
                  attachmentsState.attachments.map((item) => (
                    <AttachmentItem
                      key={item.id}
                      item={item}
                      setOpenAttachmetSlider={setOpenAttachmetSlider}
                      setStartCurrentAttachment={setStartCurrentAttachment}
                      imageUrlPrefix={attachmentsState.bucketUrlPrefix}
                    />
                  ))
                ) : (
                  <Box sx={styles.noAttachment}>
                    <Typography
                      data-sqa-id="no-comments-yet-text"
                      sx={styles.noAttachmentText}
                    >
                      No attachments yet
                    </Typography>
                  </Box>
                )}
              </Box>
            </>
          )}
        </Box>
      </Box>

      {openAttachmentModal && (
        <AddAttachmet
          isOpen={openAttachmentModal}
          closeModal={() => setOpenAttachmentModal(false)}
          modalActionType={modalActionType}
          handleFetch={handleFetch}
        />
      )}

      {openAttachmetSlider && (
        <AttachmetSlider
          isOpen={openAttachmetSlider}
          closeModal={() => setOpenAttachmetSlider(false)}
          elem={attachmentsState.attachments}
          imageUrlPrefix={attachmentsState.bucketUrlPrefix}
          activeElem={startCurrentAttachment}
          fetchSuccess={handleFetch}
        />
      )}
    </>
  );
};

export default Attachments;
