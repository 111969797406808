import { Box, Tab, Tabs } from "@mui/material";
import { format } from "date-fns";
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import DayCalendar from "src/components/DayCalendar";
import Attachments from "src/components/ReportTabs/Attachments";
import DailyComments from "src/components/ReportTabs/DailyComments";
import DailyEntries from "src/components/ReportTabs/DailyEntries";
import TabDataSection from "src/components/TabDataSection";
import TabValue from "src/components/TabValue";
import { getAttachmentsSelector } from "src/store/attachments/selectors";
import { getUserSelector } from "src/store/auth/selectors";
import { getCommentsSelector } from "src/store/comments/selectors";
import { parseTime } from "src/utils";
import { styles } from "./history.module";

interface Props {
  currentTab: number;
  handleTabChange: (event: React.SyntheticEvent, newValue: number) => void;
  fetchDailyClock: () => void;
  fetchAttachments: () => void;
  fetchDailyComments: () => void;
}

const DayPageWeb: React.FC<Props> = ({
  currentTab,
  handleTabChange,
  fetchDailyClock,
  fetchAttachments,
  fetchDailyComments,
}) => {
  const { id } = useParams();
  const user = useSelector(getUserSelector);
  const commentState = useSelector(getCommentsSelector);
  const attachmentState = useSelector(getAttachmentsSelector);

  return (
    <>
      <Box>
        <DayCalendar date={id} />
      </Box>
      <Box sx={styles.historyTabContainer}>
        <Box data-sqa-id="tabs-main-container" sx={{ marginBottom: "20px" }}>
          <Tabs
            sx={styles.tabsWrapper}
            value={currentTab}
            onChange={handleTabChange}
          >
            <Tab
              data-sqa-id="daily-entries-tab"
              sx={styles.tabWrapper}
              label="Daily Entries"
            />
            <Tab
              data-sqa-id="attachments-tab"
              sx={styles.tabWrapper}
              label="Attachments"
              icon={
                <TabValue
                  isSelected={currentTab === 1}
                  count={
                    Array.isArray(attachmentState.attachments)
                      ? attachmentState.attachments?.length
                      : 0
                  }
                />
              }
              iconPosition="end"
            />
            <Tab
              data-sqa-id="daily-comments-tab"
              sx={styles.tabWrapper}
              label="Daily Comments"
              icon={
                <TabValue
                  isSelected={currentTab === 2}
                  count={
                    Array.isArray(commentState.comments)
                      ? commentState.comments?.length
                      : 0
                  }
                />
              }
              iconPosition="end"
            />
          </Tabs>
          <TabDataSection mt="20px" border="none">
            {currentTab === 0 && (
              <DailyEntries
                date={format(parseTime(id), "y-MM-dd")}
                user={user}
                fetchEntries={fetchDailyClock}
              />
            )}
            {currentTab === 1 && <Attachments handleFetch={fetchAttachments} />}
            {currentTab === 2 && (
              <DailyComments handleFetch={fetchDailyComments} />
            )}
          </TabDataSection>
        </Box>
      </Box>
    </>
  );
};

export default DayPageWeb;
