import { Box, Typography, IconButton, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Button from "../../Button";
import theme from "src/theme";
import { EntryModalBaseProps } from "../types";
import { useDispatch, useSelector } from "react-redux";
import {
  clientTimezone,
  formatDateTime,
  secondsToTime,
  parseTime,
} from "src/utils";
import { parse, format, isValid } from "date-fns";
import {
  cleanError,
  updateEntryTimeRecordRequest,
} from "src/store/clocks/actions";
import { styles } from "./editEntryModal.module";
import { getTodaysClocksSelector } from "src/store/clocks/selectors";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopTimePicker } from "@mui/x-date-pickers";

const EditEntryModal: React.FC<EntryModalBaseProps> = ({
  closeModal,
  onFetchSuccess,
  modalData,
}) => {
  const [startValue, setStartValue] = React.useState<Date | null>(
    new Date(modalData.startTime)
  );
  const [endValue, setEndValue] = React.useState<Date | null>(
    new Date(modalData.endTime)
  );
  const [formatStartData, setFormatStartData] = useState("");
  const [formatEndData, setFormatEndData] = useState("");
  const dispatch = useDispatch();
  const clocks = useSelector(getTodaysClocksSelector);
  const [errorMessage, setErrorMessage] = useState(false);
  const [flag, setFlag] = useState(false);

  const [hoursWorked, setHoursWorked] = useState(modalData.timeWorked);
  const [error, setError] = useState({
    startTime: "",
    endTime: "",
  });

  const countTimeWorked = () => {
    let formatStartData: any;
    let formatEndData: any;
    if (!isValid(startValue) || !isValid(endValue)) return;
    else {
      let formatStartDataToTime = startValue
        ? format(startValue, "h:mm a")
        : null;
      let formatEndDataToTime = endValue ? format(endValue, "h:mm a") : null;
      formatStartData = parse(
        formatStartDataToTime,
        "h:mm a",
        new Date(modalData.startTime)
      );
      formatEndData = parse(
        formatEndDataToTime,
        "h:mm a",
        new Date(modalData.endTime)
      );
    }
    if (startValue && endValue) {
      setFormatStartData(formatDateTime(formatStartData));
      setFormatEndData(formatDateTime(formatEndData));
      const hoursWorkedCounter = formatEndData / 1000 - formatStartData / 1000;
      setHoursWorked(hoursWorkedCounter > 0 ? hoursWorkedCounter : 0);
    }
  };

  useEffect(() => {
    if (flag) countTimeWorked();
  }, [startValue, endValue]);

  useEffect(() => {
    if (clocks.error) {
      setErrorMessage(true);
    }
  }, [clocks]);

  const submitUpdateEntryTimeRecord = () => {
    if (!startValue) {
      setError((prevState) => ({
        ...prevState,
        startTime: "Start Time is required",
      }));
    }
    if (!endValue) {
      setError((prevState) => ({
        ...prevState,
        endTime: "End Time is required",
      }));
    }
    if (startValue && endValue) {
      dispatch(
        updateEntryTimeRecordRequest(
          {
            clockRecord: {
              id: modalData.id,
              startTime: formatStartData.replace(" ", "T"),
              endTime: formatEndData.replace(" ", "T"),
              startTimeTimezone: clientTimezone,
              endTimeTimezone: clientTimezone,
            },
          },
          {
            onSuccess: () => {
              onFetchSuccess();
              closeModal();
            },
          }
        )
      );
    }
  };

  useEffect(() => {
    return () => {
      dispatch(cleanError());
    };
  }, []);

  return (
    <>
      <Box sx={styles.modalTitile}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          fontSize={16}
          fontWeight={700}
        >
          Edit Entry
        </Typography>
        <IconButton sx={styles.modalClose} onClick={closeModal}>
          <CloseIcon fontSize="small" color="disabled" />
        </IconButton>
      </Box>
      <Box sx={styles.modalContent}>
        <Box sx={styles.modalBox}>
          <Typography fontSize={14}>Entry Date</Typography>
          <Typography fontSize={14} fontWeight={600}>
            {format(parseTime(modalData.startTime), "MMMM d, y")}
          </Typography>
        </Box>
        <Box>
          <Typography fontSize={14} mb={"8px"}>
            Start Time
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopTimePicker
              value={startValue}
              onChange={(newValue) => {
                setStartValue(newValue);
                setFlag(true);
              }}
              onOpen={() =>
                setError((prevState) => ({
                  ...prevState,
                  startTime: "",
                }))
              }
              PopperProps={{
                placement: "bottom-start",
                sx: {
                  width: "302px",
                  ".MuiPickersArrowSwitcher-spacer": { width: "2px" },
                },
              }}
              renderInput={(params) => (
                <TextField
                  size="small"
                  sx={{ width: "100%" }}
                  {...params}
                  onFocus={() =>
                    setError((prevState) => ({
                      ...prevState,
                      startTime: "",
                    }))
                  }
                  error={
                    (error.startTime ? true : false) ||
                    (startValue && !isValid(startValue) ? true : false)
                  }
                  inputProps={{
                    ...params.inputProps,
                    type: "text",
                  }}
                />
              )}
            />
          </LocalizationProvider>
          <Box m="5px 0 10px 14px">
            {error.startTime && (
              <Typography color={"#ff0000"} fontSize={12}>
                {error.startTime}
              </Typography>
            )}
          </Box>
        </Box>
        <Box>
          <Typography fontSize={14} mb={"8px"}>
            End Time
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopTimePicker
              value={endValue}
              onChange={(newValue) => {
                setEndValue(newValue);
                setFlag(true);
              }}
              onOpen={() =>
                setError((prevState) => ({
                  ...prevState,
                  endTime: "",
                }))
              }
              PopperProps={{
                placement: "bottom-start",
                sx: {
                  width: "302px",
                  ".MuiPickersArrowSwitcher-spacer": { width: "2px" },
                },
              }}
              renderInput={(params) => (
                <TextField
                  size="small"
                  sx={{ width: "100%" }}
                  {...params}
                  onFocus={() =>
                    setError((prevState) => ({
                      ...prevState,
                      endTime: "",
                    }))
                  }
                  error={
                    (error.endTime ? true : false) ||
                    (endValue && !isValid(endValue) ? true : false)
                  }
                  inputProps={{
                    ...params.inputProps,
                    type: "text",
                  }}
                />
              )}
            />
          </LocalizationProvider>
          <Box m="5px 0 10px 14px">
            {error.endTime && (
              <Typography color={"#ff0000"} fontSize={12}>
                {error.endTime}
              </Typography>
            )}
          </Box>
        </Box>
        <Box sx={styles.modalBox}>
          <Typography
            fontSize={14}
            fontWeight={600}
            color={theme.palette.text.secondary}
          >
            Time Worked
          </Typography>
          <Typography fontSize={14} fontWeight={600} color="primary">
            {secondsToTime(hoursWorked)}
          </Typography>
        </Box>
        {errorMessage && (
          <Box>
            <Typography sx={styles.entryError}>{clocks.error}</Typography>
          </Box>
        )}
        <Box>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            size="medium"
            onClick={submitUpdateEntryTimeRecord}
          >
            Save
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default EditEntryModal;
