import React, { useState } from "react";
import { Box, Theme, Typography } from "@mui/material";
import { Button, CardUserProfile, Input } from "src/components";
import { getUserSelector } from "src/store/auth/selectors";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCurrentUserRequest,
  logout,
  updateUserDataRequest,
} from "src/store/auth/actions";
import { isMobile } from "mobile-device-detect";
import { formatPhoneNumber } from "src/utils";
import * as yup from "yup";
import { useFormik } from "formik";
import DeactivateUserModal from "./ActionsModal/DeactivateUserModal";
import { toast } from "react-toastify";
import ContactSupportTooltipText from "./TooltipText/ContactSupportTooltipText";

const styles = {
  form: {
    width: "100%",
  },
  card: {
    p: "20px",
    borderRadius: "4px",
    border: (theme: Theme) => `1px solid ${theme.palette.background.default}`,
  },
  btn: {
    display: "flex",
    marginLeft: "auto",
    padding: "3px 40px",
  },
};

const validationSchema = yup.object().shape({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
});
const PersonalDetails: React.FC = () => {
  const user = useSelector(getUserSelector);
  const [openModal, setOpenModal] = useState(false);
  const modalActionType = { action: "Delete", page: "Account" };

  const dispatch = useDispatch();

  const handleDeleteAccount = () => {
    dispatch(
      deleteCurrentUserRequest({
        onSuccess: () => dispatch(logout()),
      })
    );
  };

  const formik = useFormik({
    initialValues: {
      firstName: user.firstName,
      lastName: user.lastName,
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: ({ firstName, lastName }) => {
      const firstNameTrim = firstName.trim();
      const lastNameTrim = lastName.trim();
      if (!firstNameTrim || !lastNameTrim) return;
      else {
        dispatch(
          updateUserDataRequest(
            {
              user: {
                id: user.id,
                firstName: firstName.trim(),
                lastName: lastName.trim(),
              },
            },
            {
              onSuccess,
            }
          )
        );
        formik.resetForm({ values: { firstName, lastName } });
      }
    },
  });

  const onSuccess = () => {
    toast.success(
      <>
        <Typography fontSize="16px" color="#262626">
          Personal details updated successfully
        </Typography>
      </>
    );
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit} style={styles.form}>
        <Box sx={!isMobile ? styles.card : null}>
          <CardUserProfile />
          <Box>
            <Box mt="20px">
              <Input
                type="text"
                label="First Name"
                fullWidth
                required
                size="small"
                id="firstName"
                inputProps={{ maxLength: 150 }}
                value={formik.values.firstName}
                onChange={formik.handleChange}
              />
            </Box>
            <Box mt="20px">
              <Input
                type="text"
                label="Last Name"
                fullWidth
                required
                size="small"
                id="lastName"
                inputProps={{ maxLength: 150 }}
                value={formik.values.lastName}
                onChange={formik.handleChange}
              />
            </Box>
            <Box mt="20px">
              <Input
                label="Phone"
                fullWidth
                size="small"
                inputProps={{ maxLength: 30 }}
                id="phone"
                value={formatPhoneNumber(user?.phoneNumber)}
                disabled
                withLockIcon
                tooltipText={<ContactSupportTooltipText />}
              />
            </Box>
            <Box mt="20px">
              <Input
                label="Email"
                fullWidth
                size="small"
                inputProps={{ maxLength: 150 }}
                id="email"
                value={user?.email}
                disabled
                withLockIcon
                tooltipText={<ContactSupportTooltipText />}
              />
            </Box>
            {isMobile && (
              <Box mt="20px">
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                  disabled={!formik.dirty}
                >
                  <Typography fontSize={{ xs: "14px", md: "16px" }}>
                    Save
                  </Typography>
                </Button>

                {((user.isAdmin &&
                  localStorage.getItem("roleMode") === "user") ||
                  !user.isAdmin) && (
                  <Button
                    variant="outlined"
                    color="error"
                    fullWidth
                    mt="10px"
                    onClick={() => setOpenModal(true)}
                  >
                    <Typography fontSize={{ xs: "14px", md: "16px" }}>
                      Delete Account
                    </Typography>
                  </Button>
                )}
              </Box>
            )}
          </Box>
        </Box>
        {!isMobile && (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt="20px"
          >
            {((user.isAdmin && localStorage.getItem("roleMode") === "user") ||
              !user.isAdmin) && (
              <Button
                variant="outlined"
                color="error"
                style={{ width: "35%", height: "46px" }}
                onClick={() => setOpenModal(true)}
              >
                <Typography>Delete Account</Typography>
              </Button>
            )}
            <Button
              variant="contained"
              size="small"
              color="primary"
              style={styles.btn}
              type="submit"
              disabled={!formik.dirty}
            >
              <Typography fontSize={"16px"}>Save</Typography>
            </Button>
          </Box>
        )}
      </form>

      {openModal && (
        <DeactivateUserModal
          fetchRequest={handleDeleteAccount}
          modalActionType={modalActionType}
          elem="user"
          isOpen={openModal}
          closeModal={() => setOpenModal(false)}
        />
      )}
    </>
  );
};

PersonalDetails.displayName = "PersonalDetails";

export default PersonalDetails;
