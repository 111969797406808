import { Box, Typography } from "@mui/material";
import React from "react";
import Button from "../Button";
import ModalWrapper from "./ModalWrapper";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "src/constants";
import { DeleteForWeb, EditForWeb, ViewForWeb } from "src/assets/icons";
import { useDispatch } from "react-redux";
import {
  deleteCommentProjectRequest,
  deleteProjectRequest,
} from "src/store/projects/actions";
import { isMobile } from "mobile-device-detect";

const styles = {
  actionButtons: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    columnGap: "10px",
    marginTop: "20px",
    ">div": {
      width: "35%",
    },
  },
};

interface ModalProps {
  closeModal: () => void;
  setCommentEdit?: (bol: boolean) => void;
  fetchSuccess: () => void;
  setModalActionType?: any;
  elem: any;
  type: string;
  isOpen: boolean;
  projectId?: string;
  modalActionType: {
    action: string;
    page: string;
  };
}

const Actions: React.FC<ModalProps> = ({
  isOpen,
  modalActionType,
  closeModal,
  setCommentEdit,
  fetchSuccess,
  setModalActionType,
  elem,
  type,
  projectId,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const deleteElement = () => {
    if (type === "View") {
      dispatch(
        deleteProjectRequest({ id: elem.id }, { onSuccess: fetchSuccess })
      );
    } else {
      dispatch(
        deleteCommentProjectRequest(
          { id: elem.id, projectId },
          { onSuccess: fetchSuccess }
        )
      );
    }
    closeModal();
  };
  return (
    <>
      <ModalWrapper
        closeModal={closeModal}
        isOpen={isOpen}
        maxWidth={isMobile ? "350px" : "500px"}
        modalActionType={modalActionType}
      >
        {modalActionType.action === "Actions" ? (
          <>
            {type === "View" ? (
              <Box>
                <Button
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  onClick={() => {
                    navigate(`${AppRoutes.ProjectItemMobile}/${elem.id}`);
                    sessionStorage.setItem("activeTab", "0");
                  }}
                >
                  <ViewForWeb color="#0D6FF0" />
                  <Typography
                    ml="12px"
                    fontSize="14px"
                    fontWeight={500}
                    color="#262626"
                  >
                    View Project
                  </Typography>
                </Button>
              </Box>
            ) : (
              <Box>
                <Button
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  onClick={() => {
                    setCommentEdit(true);
                    closeModal();
                  }}
                >
                  <EditForWeb color="#0D6FF0" />
                  <Typography
                    ml="12px"
                    fontSize="14px"
                    fontWeight={500}
                    color="#262626"
                  >
                    Edit Comment
                  </Typography>
                </Button>
              </Box>
            )}
            <Box mt="10px">
              <Button
                variant="outlined"
                color="error"
                fullWidth
                onClick={() =>
                  setModalActionType((prevState) => ({
                    ...prevState,
                    action: "Delete",
                    page: type === "View" ? "Project" : "Comment",
                  }))
                }
              >
                <DeleteForWeb />
                <Typography
                  ml="12px"
                  fontSize="14px"
                  fontWeight={500}
                  color="#FF0000"
                >
                  Delete
                </Typography>
              </Button>
            </Box>
          </>
        ) : (
          <>
            <Typography fontSize={14}>
              Are you sure you want to delete the
              {type === "View" ? " project" : " comment"}?
            </Typography>
            <Box sx={styles.actionButtons}>
              <Box>
                <Button
                  variant="outlined"
                  fullWidth
                  color="secondary"
                  onClick={closeModal}
                >
                  <Typography fontSize="14px" color="#262626">
                    Cancel
                  </Typography>
                </Button>
              </Box>
              <Box>
                <Button
                  variant="outlined"
                  color="error"
                  fullWidth
                  onClick={deleteElement}
                >
                  Delete
                </Button>
              </Box>
            </Box>
          </>
        )}
      </ModalWrapper>
    </>
  );
};

export default Actions;
