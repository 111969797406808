import React, { useState } from "react";
import { Box, Theme, Typography } from "@mui/material";
import { ny } from "./CapacitorMapStyles";
import {
  GoogleMap,
  InfoWindow,
  useJsApiLoader,
  Marker,
} from "@react-google-maps/api";
import { isMobile } from "mobile-device-detect";

const styles = {
  container: {
    backgroundColor: (theme: Theme) => `${theme.palette.background.paper}`,
    border: (theme: Theme) => `1px solid ${theme.palette.background.default}`,
    borderRadius: "4px",
  },
  descriptionWrapper: {
    py: "11px",
    px: "12px",
    display: "flex",
    alignItems: "center",
  },
};

const mapMarker = isMobile
  ? "/assets/icons/map-marker-mobile.png"
  : "/assets/icons/map-marker.png";

const mapMarkerAnchor = isMobile ? { x: 9, y: 9 } : { x: 19, y: 19 };

interface Props {
  userPosition: any;
  coords: any[];
  height: string;
}

const GoogleMaps: React.FC<Props> = ({ coords, height, userPosition }) => {
  const [openModal, setOpenModal] = useState<number>(-1);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    // eslint-disable-next-line no-undef
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "",
  });

  if (!isLoaded || !userPosition) return null;

  return (
    <>
      <Box sx={styles.container}>
        <GoogleMap
          zoom={10}
          center={userPosition}
          mapContainerStyle={{ height }}
          options={{
            styles: ny,
            draggable: false,
            clickableIcons: false,
            disableDoubleClickZoom: true,
            disableDefaultUI: true,
            keyboardShortcuts: false,
            gestureHandling: "none",
          }}
        >
          {coords.map((marker, i) => {
            if (!marker.lat && !marker.lng) {
              return null;
            } else if (
              coords[0]?.lat === coords[1]?.lat &&
              coords[0]?.lng === coords[1]?.lng
            ) {
              return (
                <Marker
                  key={i}
                  icon={{
                    url: mapMarker,
                  }}
                  position={marker}
                  onClick={() => setOpenModal(i)}
                >
                  {openModal === i && marker?.text && (
                    <InfoWindow onCloseClick={() => setOpenModal(-1)}>
                      <Typography fontSize="14px" fontWeight={400} color="#000">
                        {coords[0]?.text} - {coords[1]?.text}
                      </Typography>
                    </InfoWindow>
                  )}
                </Marker>
              );
            } else {
              return (
                <Marker
                  key={i}
                  icon={{
                    url: mapMarker,
                    // eslint-disable-next-line no-undef
                    anchor: new google.maps.Point(
                      mapMarkerAnchor.x,
                      mapMarkerAnchor.y
                    ),
                  }}
                  position={marker}
                  onClick={() => setOpenModal(i)}
                >
                  {openModal === i && marker?.text && (
                    <InfoWindow onCloseClick={() => setOpenModal(-1)}>
                      <Typography fontSize="14px" fontWeight={400} color="#000">
                        {marker.text}
                      </Typography>
                    </InfoWindow>
                  )}
                </Marker>
              );
            }
          })}
        </GoogleMap>
      </Box>
    </>
  );
};

export default GoogleMaps;
