import theme from "src/theme";

export const styles = {
  topContainer: {
    display: { xs: "none", md: "flex" },
    justifyContent: "space-between",
    alignItems: "center",
  },
  pageTitle: {
    fontSize: "32px",
    fontWeight: 500,
    color: "#000",
  },
  settings: {
    padding: { xs: "20px", md: "30px 20px" },
    background: "#FFFFFF",
    border: (theme) => `1px solid ${theme.palette.background.default}`,
    borderRadius: "4px",
    marginTop: { xs: "0", md: "30px" },
  },
  tabs: {
    p: "0px",
    minHeight: "auto",
    ".MuiTabs-flexContainer": {
      position: "relative",
      "&::after": {
        position: "absolute",
        bottom: 0,
        left: 0,
        width: "100%",
        height: "1px",
        content: `""`,
        background: { xs: "none", md: `${theme.palette.background.default}` },
      },
    },
    button: {
      textTransform: "none",
      borderBottom: `1px solid #D9D9D9`,
      fontSize: { xs: "12px", md: "16px" },
      fontWeight: "400",
      minHeight: "auto",
      zIndex: 1,
      "&:not(:last-child)": {
        marginRight: "20px",
      },
    },
    ".MuiTabs-indicator": {
      zIndex: 1,
    },
  },
};
