import { createSelector } from "reselect";

import { AppState } from "../rootReducer";

const getAuth = (state: AppState) => state.auth;

const getUser = (state: AppState) => state.auth.user;

const getOrganization = (state: AppState) => state.auth.organization;

const getAuthError = (state: AppState) => state.auth.error;

export const getAuthSelector = createSelector(getAuth, (state) => state);

export const getUserSelector = createSelector(getUser, (user) => user);

export const getOrganizationSelector = createSelector(
  getOrganization,
  (organization) => organization
);

export const getErrorSelector = createSelector(getAuthError, (error) => error);
