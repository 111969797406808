import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  fetchComments as fetchCommentsApi,
  fetchCommentsDayByUser as fetchCommentsDayByUserApi,
  fetchDailyComments as fetchDailyCommentsApi,
  addComment as addCommentApi,
  updateComment as updateCommentApi,
  deleteComment as deleteCommentApi,
} from "./api";
import {
  fetchCommentsSuccess,
  fetchCommentsFailure,
  addCommentSuccess,
  addCommentFailure,
  deleteCommentSuccess,
  deleteCommentFailure,
  updateCommentSuccess,
  updateCommentFailure,
  fetchDailyCommentsFailure,
  fetchDailyCommentsSuccess,
  fetchCommentsDayByUserFailure,
  fetchCommentsDayByUserSuccess,
} from "./actions";
import {
  ADD_COMMENT_REQUEST,
  DELETE_COMMENT_REQUEST,
  FETCH_COMMENTS_DAY_BY_USER_REQUEST,
  FETCH_COMMENTS_REQUEST,
  FETCH_DAILY_COMMENTS_REQUEST,
  UPDATE_COMMENT_REQUEST,
} from "./actionTypes";
import { AxiosResponse } from "axios";
import { Comment, CommentID, Comments } from "src/types";
import {
  AddCommentRequest,
  DeleteCommentRequest,
  FetchCommentsDayByUserRequest,
  FetchCommentsRequest,
  FetchDailyCommentsRequest,
  UpdateCommentRequest,
} from "./types";

function* fetchCommentsSaga({ payload }: FetchCommentsRequest) {
  try {
    const response: AxiosResponse<Comments> = yield call(
      fetchCommentsApi,
      payload
    );
    yield put(
      fetchCommentsSuccess({
        comments: response.data,
      })
    );
  } catch (e) {
    yield put(
      fetchCommentsFailure({
        // @ts-ignore
        error: e?.response?.data?.message,
      })
    );
  }
}

function* fetchCommentsDayByUserSaga({
  payload,
}: FetchCommentsDayByUserRequest) {
  try {
    const response: AxiosResponse<Comments> = yield call(
      fetchCommentsDayByUserApi,
      payload
    );
    yield put(
      fetchCommentsDayByUserSuccess({
        comments: response.data,
      })
    );
  } catch (e) {
    yield put(
      fetchCommentsDayByUserFailure({
        // @ts-ignore
        error: e?.response?.data?.message,
      })
    );
  }
}

function* fetchDailyCommentsSaga({ payload }: FetchDailyCommentsRequest) {
  try {
    const response: AxiosResponse<Comments> = yield call(
      fetchDailyCommentsApi,
      payload
    );
    yield put(
      fetchDailyCommentsSuccess({
        comments: response.data,
      })
    );
  } catch (e) {
    yield put(
      fetchDailyCommentsFailure({
        // @ts-ignore
        error: e?.response?.data?.message,
      })
    );
  }
}

function* addCommentSaga({ payload, callbacks }: AddCommentRequest) {
  try {
    const response: AxiosResponse<Comment> = yield call(addCommentApi, payload);
    yield put(
      addCommentSuccess({
        comment: response.data,
      })
    );

    if (callbacks?.onSuccess) {
      yield call(callbacks.onSuccess);
    }
  } catch (e) {
    yield put(
      addCommentFailure({
        // @ts-ignore
        error: e?.response?.data?.message,
      })
    );
  }
}

function* updateCommentSaga({ payload, callbacks }: UpdateCommentRequest) {
  try {
    const response: AxiosResponse<Comment> = yield call(
      updateCommentApi,
      payload
    );
    yield put(
      updateCommentSuccess({
        comment: response.data,
      })
    );

    if (callbacks?.onSuccess) {
      yield call(callbacks.onSuccess);
    }
  } catch (e) {
    yield put(
      updateCommentFailure({
        // @ts-ignore
        error: e?.response?.data?.message,
      })
    );
  }
}

function* deleteCommentSaga({ payload, callbacks }: DeleteCommentRequest) {
  try {
    const response: AxiosResponse<CommentID> = yield call(
      deleteCommentApi,
      payload
    );
    yield put(
      deleteCommentSuccess({
        data: response.data,
      })
    );

    if (callbacks?.onSuccess) {
      yield call(callbacks.onSuccess);
    }
  } catch (e) {
    yield put(
      deleteCommentFailure({
        // @ts-ignore
        error: e?.response?.data?.message,
      })
    );
  }
}

function* commentsSaga() {
  yield all([
    takeLatest(FETCH_COMMENTS_REQUEST, fetchCommentsSaga),
    takeLatest(FETCH_COMMENTS_DAY_BY_USER_REQUEST, fetchCommentsDayByUserSaga),
    takeLatest(FETCH_DAILY_COMMENTS_REQUEST, fetchDailyCommentsSaga),
    takeLatest(ADD_COMMENT_REQUEST, addCommentSaga),
    takeLatest(UPDATE_COMMENT_REQUEST, updateCommentSaga),
    takeLatest(DELETE_COMMENT_REQUEST, deleteCommentSaga),
  ]);
}

export default commentsSaga;
