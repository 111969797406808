export const FETCH_ATTACHMENTS_DAY_BY_USER_REQUEST =
  "FETCH_ATTACHMENTS_DAY_BY_USER_REQUEST";
export const FETCH_ATTACHMENTS_DAY_BY_USER_SUCCESS =
  "FETCH_ATTACHMENTS_DAY_BY_USER_SUCCESS";
export const FETCH_ATTACHMENTS_DAY_BY_USER_FAILURE =
  "FETCH_ATTACHMENTS_DAY_BY_USER_FAILURE";

export const FETCH_ATTACHMENTS_REQUEST = "FETCH_ATTACHMENTS_REQUEST";
export const FETCH_ATTACHMENTS_SUCCESS = "FETCH_ATTACHMENTS_SUCCESS";
export const FETCH_ATTACHMENTS_FAILURE = "FETCH_ATTACHMENTS_FAILURE";

export const FETCH_DAILY_ATTACHMENTS_REQUEST =
  "FETCH_DAILY_ATTACHMENTS_REQUEST";
export const FETCH_DAILY_ATTACHMENTS_SUCCESS =
  "FETCH_DAILY_ATTACHMENTS_SUCCESS";
export const FETCH_DAILY_ATTACHMENTS_FAILURE =
  "FETCH_DAILY_ATTACHMENTS_FAILURE";

export const FETCH_ATTACHMENT_ID_REQUEST = "FETCH_ATTACHMENT_ID_REQUEST";
export const FETCH_ATTACHMENT_ID_SUCCESS = "FETCH_ATTACHMENT_ID_SUCCESS";
export const FETCH_ATTACHMENT_ID_FAILURE = "FETCH_ATTACHMENT_ID_FAILURE";

export const ADD_ATTACHMENT_REQUEST = "ADD_ATTACHMENT_REQUEST";
export const ADD_ATTACHMENT_SUCCESS = "ADD_ATTACHMENT_SUCCESS";
export const ADD_ATTACHMENT_FAILURE = "ADD_ATTACHMENT_FAILURE";

export const UPDATE_ATTACHMENT_REQUEST = "UPDATE_ATTACHMENT_REQUEST";
export const UPDATE_ATTACHMENT_SUCCESS = "UPDATE_ATTACHMENT_SUCCESS";
export const UPDATE_ATTACHMENT_FAILURE = "UPDATE_ATTACHMENT_FAILURE";

export const DELETE_ATTACHMENT_REQUEST = "DELETE_ATTACHMENT_REQUEST";
export const DELETE_ATTACHMENT_SUCCESS = "DELETE_ATTACHMENT_SUCCESS";
export const DELETE_ATTACHMENT_FAILURE = "DELETE_ATTACHMENT_FAILURE";

export const PRESIGNED_URL_REQUEST = "PRESIGNED_URL_REQUEST";
export const PRESIGNED_URL_SUCCESS = "PRESIGNED_URL_SUCCESS";
export const PRESIGNED_URL_FAILURE = "PRESIGNED_URL_FAILURE";

export const CLEAN_F3IMAGES = "CLEAN_F3IMAGES";
