import { Box, Typography } from "@mui/material";
import React from "react";

interface Props {
  count: number;
  isSelected: boolean;
}

export const styles = {
  boxContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "26px",
    height: "26px",
    borderRadius: "50%",
    marginLeft: "5px",
  },
};

const TabValue: React.FC<Props> = ({ count, isSelected }) => {
  return (
    <>
      <Box
        sx={[
          styles.boxContainer,
          { backgroundColor: isSelected ? "#0D6FF0" : "#F5F5F5" },
        ]}
      >
        <Typography
          data-sqa-id="tab-count"
          fontSize="14px"
          fontWeight={400}
          color={isSelected ? "#FFF" : "#262626"}
        >
          {count}
        </Typography>
      </Box>
    </>
  );
};

export default TabValue;
