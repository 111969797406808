import { Box, Typography } from "@mui/material";
import { isMobile } from "mobile-device-detect";
import React from "react";
import theme from "src/theme";
import Button from "../Button";
import ModalWrapper from "./ModalWrapper";

const styles = {
  actionButtons: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    columnGap: "10px",
    marginTop: "20px",
  },
  actionButton: {
    textTransform: "none",
  },
  actionButtonDelete: {
    textTransform: "none",
  },
};

interface ModalProps {
  closeModal: () => void;
  fetchRequest: () => void;
  modalActionType: {
    action: string;
    page: string;
  };
  isOpen: boolean;
}

const CancelSubscription: React.FC<ModalProps> = ({
  closeModal,
  fetchRequest,
  modalActionType,
  isOpen,
}) => {
  return (
    <>
      <ModalWrapper
        closeModal={closeModal}
        isOpen={isOpen}
        maxWidth={isMobile ? "390px" : "440px"}
        modalActionType={modalActionType}
      >
        <>
          <Typography fontSize={{ xs: "14px", md: "16px" }}>
            Are you sure you want to cancel your subscription? The application
            will become read-only at the end of the current pay period.
          </Typography>
          <Box sx={styles.actionButtons}>
            <Box>
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                style={{ padding: "10px 20px" }}
                fullWidth
                sx={styles.actionButton}
                onClick={closeModal}
              >
                <Typography fontSize="14px" color={theme.palette.text.primary}>
                  Cancel
                </Typography>
              </Button>
            </Box>
            <Box>
              <Button
                variant="outlined"
                color="error"
                size="small"
                style={{ padding: "10px 20px" }}
                fullWidth
                sx={styles.actionButtonDelete}
                onClick={fetchRequest}
              >
                <Typography fontSize="14px">Confirm Cancellation</Typography>
              </Button>
            </Box>
          </Box>
        </>
      </ModalWrapper>
    </>
  );
};

export default CancelSubscription;
