import React from "react";
import { Box } from "@mui/material";
import PersonalInfoForm from "../PersonalInfoForm";

const TabPersonalDetails: React.FC = () => {
  return (
    <>
      <Box sx={{ maxWidth: "540px" }}>
        <PersonalInfoForm />
      </Box>
    </>
  );
};

export default TabPersonalDetails;
