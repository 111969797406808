import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ModalTodaysClockRecord } from "src/components";
import {
  fetchReportRequest,
  reportTimesheetRequest,
} from "src/store/report/actions";
import { format } from "date-fns";
import DeleteTimeClock from "src/components/ActionsModal/DeleteTimeClock";
import LinkUserDayPairToProjectModal from "src/components/ActionsModal/LinkUserDayPairToProjectModal";
import DeviceWrapper from "src/pages/DeviceWrapper";
import ReportWeb from "./ReportWeb";
import { isMobile } from "mobile-device-detect";
import ReportMobile from "./ReportMobile";
import CurrentReportBreakdownModal from "src/components/ActionsModal/CurrentReportBreakdownModal";
import { getReportSelector } from "src/store/report/selectors";
import { Capacitor } from "@capacitor/core";
import { Browser } from "@capacitor/browser";
import { fetchProjectAllRequest } from "src/store/projects/actions";
import { fetchUsersRequest } from "src/store/users/actions";
import { formatDate } from "src/utils";

const Report = () => {
  const dispatch = useDispatch();
  const reportState = useSelector(getReportSelector);
  const [page, setPage] = useState(1);
  const pageSize = 10;
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortBy, setSortBy] = useState("first_name");
  const [sortByTableName, setSortByTableName] = useState("Name");
  const [openActionModal, setOpenActionModal] = useState(false);
  const [openDeleteReportModal, setOpenDeleteReportModal] = useState(false);
  const [openLinkProjectModal, setOpenLinkProjectModal] = useState(false);
  const [openViewBreakdown, setOpenViewBreakdown] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [elem, setElem] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [userIds, setUserIds] = useState(
    reportState.report?.searchParameters?.userIds
      ? reportState.report?.searchParameters?.userIds
      : []
  );
  const [selectedDate, setSelectedDate] = useState([
    reportState?.report?.searchParameters?.startDate
      ? formatDate(reportState?.report?.searchParameters?.startDate)
      : new Date(
          new Date().setDate(new Date().getDate() - new Date().getDay())
        ),
    reportState?.report?.searchParameters?.endDate
      ? formatDate(reportState?.report?.searchParameters?.endDate)
      : new Date(
          new Date().setDate(new Date().getDate() - new Date().getDay() + 6)
        ),
  ]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [projectIds, setProjectIds] = useState(
    reportState.report?.searchParameters?.projectIds
      ? reportState.report?.searchParameters?.projectIds
      : []
  );
  const [modalActionType, setModalActionType] = useState({
    action: "",
    page: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [checkTimesheet, setCheckTimesheet] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeValue = (
    event,
    child,
    state,
    setSelectedStateData,
    setSelectedIds
  ) => {
    const res = state.some((el) => el.id === event.target.value);
    if (res) return;
    setSelectedIds((prevState) => [...prevState, event.target.value]);
    setSelectedStateData((prevState) => [
      ...prevState,
      { id: event.target.value, name: child.props.children },
    ]);
  };

  const removeSelectedItem = (
    id,
    setSelectedStateData,
    stateData,
    setSelectedIdsData,
    stateIdsData
  ) => {
    setSelectedStateData(stateData.filter((item) => item.id !== id));
    setSelectedIdsData(stateIdsData.filter((item) => item !== id));
  };

  const handleOpenModal = (type, page, openModalState) => {
    openModalState(true);
    setModalActionType((prevState) => ({
      ...prevState,
      action: type,
      page,
    }));
  };

  const handleTimesheet = () => {
    dispatch(
      reportTimesheetRequest(
        {
          startDate: format(selectedDate[0], "y-MM-dd"),
          endDate: format(selectedDate[1], "y-MM-dd"),
          userIds: userIds.length ? userIds : null,
          includeUsersData: false,
          everyUserOnNewPage: true,
        },
        {
          onSuccess: () => setCheckTimesheet(true),
        }
      )
    );
  };

  const fetchReport = () => {
    dispatch(
      fetchReportRequest(
        {
          currentPage: isMobile ? 1 : page,
          pageSize: isMobile ? page * pageSize : pageSize,
          sortBy: sortBy,
          sortOrder: sortOrder,
          startDate: format(selectedDate[0], "y-MM-dd"),
          endDate: format(selectedDate[1], "y-MM-dd"),
          userIds: userIds.length ? userIds : null,
          projectIds: projectIds.length ? projectIds : null,
        },
        {
          onSuccess: () => setIsLoading(false),
        }
      )
    );
  };

  useEffect(() => {
    if (selectedDate[1] !== null) {
      fetchReport();
      setSelectedItems([]);
      setElem([]);
    }
  }, [
    page,
    sortBy,
    sortOrder,
    selectedUsers,
    selectedProjects,
    userIds,
    projectIds,
    selectedDate[1],
  ]);

  const requestSort = (elem) => {
    if (elem === "Name") {
      setSortBy("first_name");
      setSortByTableName("Name");
    }
    if (elem === "Date") {
      setSortBy("reportday");
      setSortByTableName("Date");
    }
    if (elem === "Daily Total") {
      setSortBy("totalworked");
      setSortByTableName("Daily Total");
    }
  };

  const generatePDF = async () => {
    if (Capacitor.getPlatform() === "web") {
      fetch(reportState.timesheet?.timesheetPdfUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/pdf",
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Timesheet.pdf");
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        });
    } else {
      await Browser.open({ url: `${reportState.timesheet?.timesheetPdfUrl}` });
    }
    setCheckTimesheet(false);
  };

  useEffect(() => {
    if (checkTimesheet) generatePDF();
  }, [checkTimesheet]);

  useEffect(() => {
    if (!isMobile) {
      dispatch(fetchProjectAllRequest());
      dispatch(
        fetchUsersRequest({
          currentPage: 1,
          pageSize: 1000,
          sortBy: "firstName",
          sortOrder: "asc",
        })
      );
    }
  }, []);

  return (
    <>
      <DeviceWrapper>
        <>
          {isMobile ? (
            <ReportMobile
              isLoading={isLoading}
              sortOrder={sortOrder}
              pageSize={pageSize}
              sortBy={sortBy}
              userIds={userIds}
              projectIds={projectIds}
              selectedDate={selectedDate}
              setIsLoading={setIsLoading}
              setUserIds={setUserIds}
              setProjectIds={setProjectIds}
              setSelectedDate={setSelectedDate}
              setPage={setPage}
              setSortOrder={setSortOrder}
              setSortBy={setSortBy}
              fetchReport={fetchReport}
              handleTimesheet={handleTimesheet}
            />
          ) : (
            <ReportWeb
              selectedUsers={selectedUsers}
              selectedProjects={selectedProjects}
              userIds={userIds}
              projectIds={projectIds}
              selectedItems={selectedItems}
              selectedDate={selectedDate}
              elem={elem}
              page={page}
              sortOrder={sortOrder}
              sortByTableName={sortByTableName}
              fetchReport={fetchReport}
              requestSort={requestSort}
              handleOpenModal={handleOpenModal}
              setOpenActionModal={setOpenActionModal}
              handleChangeValue={handleChangeValue}
              setSelectedUsers={setSelectedUsers}
              setUserIds={setUserIds}
              setProjectIds={setProjectIds}
              setSelectedDate={setSelectedDate}
              setSelectedProjects={setSelectedProjects}
              setSelectedItems={setSelectedItems}
              setOpenDeleteReportModal={setOpenDeleteReportModal}
              setOpenLinkProjectModal={setOpenLinkProjectModal}
              setOpenViewBreakdown={setOpenViewBreakdown}
              handleTimesheet={handleTimesheet}
              handleChangePage={handleChangePage}
              removeSelectedItem={removeSelectedItem}
              setElem={setElem}
              setPage={setPage}
              setSortOrder={setSortOrder}
            />
          )}
        </>
      </DeviceWrapper>

      {openActionModal && (
        <ModalTodaysClockRecord
          modalsState="MANUAL_ENTRY_MODAL"
          onFetchSuccess={fetchReport}
          isOpen={openActionModal}
          closeModal={() => setOpenActionModal(false)}
          currentTab={2}
        />
      )}

      {openDeleteReportModal && (
        <DeleteTimeClock
          modalActionType={modalActionType}
          fetchSuccess={fetchReport}
          elem={elem}
          isOpen={openDeleteReportModal}
          closeModal={() => setOpenDeleteReportModal(false)}
        />
      )}
      {openLinkProjectModal && (
        <LinkUserDayPairToProjectModal
          modalActionType={modalActionType}
          fetchSuccess={fetchReport}
          elem={elem}
          isOpen={openLinkProjectModal}
          closeModal={() => setOpenLinkProjectModal(false)}
        />
      )}

      {openViewBreakdown && (
        <CurrentReportBreakdownModal
          modalActionType={modalActionType}
          isOpen={openViewBreakdown}
          startDate={format(selectedDate[0], "y-MM-dd")}
          endDate={format(selectedDate[1], "y-MM-dd")}
          userIds={selectedUsers}
          projectIds={selectedProjects}
          closeModal={() => setOpenViewBreakdown(false)}
        />
      )}
    </>
  );
};

export default Report;
