import {
  Box,
  Typography,
  FormControl,
  MenuItem,
  TextField,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import * as yup from "yup";
import { useFormik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateProjectRequest } from "src/store/projects/actions";
import americaStates from "src/AmericaStates.json";
import { isFieldHasError } from "src/utils";
import Input from "../../Input";
import Select from "../../Select";
import { getProjectSelector } from "src/store/projects/selectors";
import { styles } from "./projectDetails.module";
import { isMobile } from "mobile-device-detect";
import Button from "src/components/Button";
import { ZIP_NUMBER_REGEX } from "src/constants";

interface Props {
  fetchSuccess: () => void;
}

const validationSchema = yup.object().shape({
  projectName: yup.string().required("Project name is required"),
  zip: yup.string().matches(ZIP_NUMBER_REGEX, "Zip code is not valid"),
});

const ProjectDetails: React.FC<Props> = ({ fetchSuccess }) => {
  const dispatch = useDispatch();
  const projectState = useSelector(getProjectSelector);

  const formik = useFormik({
    initialValues: {
      projectName: projectState.project?.projectName,
      projectDescription: projectState.project?.projectDescription || "",
      address1: projectState.project?.address?.address || "",
      address2: projectState.project?.address?.addressTwo || "",
      city: projectState.project?.address?.city || "",
      state: projectState.project?.address?.state || "",
      zip: projectState.project?.address?.zip || "",
    },
    validationSchema: validationSchema,
    onSubmit: ({
      projectName,
      projectDescription,
      address1,
      address2,
      city,
      state,
      zip,
    }) => {
      const projectNameTrim = projectName.trim();
      if (!projectNameTrim) return;
      else {
        dispatch(
          updateProjectRequest(
            {
              id: projectState.project.id,
              projectName: projectNameTrim.trim(),
              parentId: projectState.project?.parentId,
              projectDescription: projectDescription
                ? projectDescription.trim()
                : null,
              address: {
                address: address1 ? address1.trim() : null,
                addressTwo: address2 ? address2.trim() : null,
                city: city ? city.trim() : null,
                state: state ? state : null,
                zip: zip ? zip.toString() : null,
              },
            },
            { onSuccess: fetchSuccess }
          )
        );
      }
    },
  });

  return (
    <>
      <form
        style={{ width: isMobile ? "100%" : "50%" }}
        onSubmit={formik.handleSubmit}
      >
        <Box sx={isMobile ? null : styles.formWrapper}>
          <Box>
            <Input
              type="text"
              fullWidth
              required
              size="small"
              label="Project Name"
              id="projectName"
              inputProps={{ maxLength: 200 }}
              value={formik.values.projectName}
              onChange={formik.handleChange}
              error={isFieldHasError({
                formik,
                fieldName: "projectName",
                isGlobalError: Boolean(projectState.error),
              })}
              helperText={
                formik.touched.projectName && formik.errors.projectName
              }
            />
          </Box>
          <Box mt="20px">
            <Typography mb={"8px"} color="#8C8C8C">
              Project Description
            </Typography>
            <TextField
              multiline
              fullWidth
              inputProps={{ maxLength: 5000 }}
              rows={4}
              id="projectDescription"
              value={formik.values.projectDescription}
              onChange={formik.handleChange}
            />
          </Box>
        </Box>
        <Box sx={isMobile ? styles.marginTop : styles.formWrapper}>
          <Box>
            <Input
              type="text"
              fullWidth
              size="small"
              label="Address 1"
              inputProps={{ maxLength: 200 }}
              id="address1"
              value={formik.values.address1}
              onChange={formik.handleChange}
            />
          </Box>
          <Box mt="20px">
            <Input
              type="text"
              fullWidth
              size="small"
              label="Address 2 (optional)"
              inputProps={{ maxLength: 100 }}
              id="address2"
              value={formik.values.address2}
              onChange={formik.handleChange}
            />
          </Box>
          <Box
            sx={[
              styles.boxContainer,
              { flexWrap: isMobile ? "wrap" : "nowrap" },
            ]}
          >
            <Box sx={{ width: isMobile ? "100%" : "48%" }}>
              <Input
                type="text"
                fullWidth
                size="small"
                label="City"
                inputProps={{ maxLength: 100 }}
                id="city"
                value={formik.values.city}
                onChange={formik.handleChange}
              />
            </Box>
            <Box
              sx={{
                width: isMobile ? "48%" : "24%",
                mt: isMobile ? "20px" : 0,
              }}
            >
              <Typography
                fontSize={{ xs: "14px", md: "16px" }}
                mb={"10px"}
                color="#8C8C8C"
              >
                State
              </Typography>
              <FormControl fullWidth>
                <Select
                  value={formik.values.state}
                  height={{ xs: "40px", md: "46px" }}
                  fontSize="16px"
                  onChange={(opt) =>
                    formik.setFieldValue("state", opt.target.value)
                  }
                  IconComponent={ArrowDropDownIcon}
                >
                  {americaStates.map((elem: string) => (
                    <MenuItem key={elem} value={elem}>
                      {elem}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box
              sx={{
                width: isMobile ? "48%" : "24%",
                position: "relative",
                mt: isMobile ? "20px" : 0,
              }}
            >
              <Input
                type="text"
                fullWidth
                size="small"
                label="Zip"
                id="zip"
                value={formik.values.zip}
                onChange={formik.handleChange}
                error={isFieldHasError({
                  formik,
                  fieldName: "zip",
                  isGlobalError: Boolean(projectState.error),
                })}
                helperText={formik.touched.zip && formik.errors.zip}
              />
            </Box>
          </Box>
        </Box>
        <Box sx={styles.buttonContainer}>
          <Box sx={{ width: isMobile ? "100%" : "auto" }}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              size="small"
              style={styles.actionButtonSave}
              type="submit"
              disabled={!formik.dirty}
            >
              <Typography fontSize={"16px"}>Save</Typography>
            </Button>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default ProjectDetails;
