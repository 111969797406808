import { Avatar, Box, TextField, Typography } from "@mui/material";
import { format } from "date-fns";
import { isMobile } from "mobile-device-detect";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { DeleteForWeb, EditForWeb, ThreeDots } from "src/assets/icons";
import Actions from "src/components/ActionsModal/Actions";
import Button from "src/components/Button";
import { updateCommentProjectRequest } from "src/store/projects/actions";
import { ProjectComment } from "src/types";
import { parseTime } from "src/utils";
import DeleteProjectComment from "../../ActionsModal/DeleteProjectComment";
import { styles } from "./projectCommentsItem.module";

interface Props {
  item: ProjectComment;
  fetchProjectComments: () => void;
  fetchProjectID: () => void;
}

const ProjectCommentItem: React.FC<Props> = ({
  item,
  fetchProjectComments,
  fetchProjectID,
}) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [commentText, setCommentText] = useState(item.commentText);
  const [hover, setHover] = useState("");
  const [commentEdit, setCommentEdit] = useState(false);
  const [openActionModal, setOpenActionModal] = useState<boolean>(false);
  const [openDeleteProjectModal, setOpenDeleteProjectModal] = useState(false);
  const [modalActionType, setModalActionType] = useState({
    action: "",
    page: "",
  });

  const openModal = (action: string) => {
    setOpenActionModal(true);
    setModalActionType((prevState) => ({
      ...prevState,
      action,
      page: "",
    }));
  };

  const handleDeleteProjectComment = (type: string) => {
    setOpenDeleteProjectModal(true);
    setModalActionType((prevState) => ({
      ...prevState,
      action: type,
      page: "Comment",
    }));
  };

  const updateCommentSuccess = () => {
    fetchProjectComments();
    setCommentEdit(false);
  };

  function stringAvatar(name) {
    return {
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }

  const fetchSuccess = () => {
    fetchProjectComments();
    fetchProjectID();
  };

  const handleUpdateProjectComment = () => {
    const commentTextTrim = commentText.trim();
    if (!commentTextTrim) return setCommentText("");
    dispatch(
      updateCommentProjectRequest(
        {
          commentText: commentTextTrim.trim(),
          projectId: id,
          commentDay: format(new Date(), "y-MM-dd"),
          id: item.id,
        },
        { onSuccess: updateCommentSuccess }
      )
    );
  };

  return (
    <>
      <Box
        mt="20px"
        p={isMobile ? "24px 22px" : "30px"}
        sx={{ border: "1px solid #F0F0F0" }}
      >
        {!commentEdit ? (
          <>
            <Box sx={styles.boxContainer}>
              <Box sx={{ display: "flex" }}>
                <Avatar
                  {...stringAvatar(
                    `${item.user.firstName} ${item.user.lastName}`
                  )}
                  sx={styles.userPhoto}
                  src={item.user?.profilePictureUrl}
                />
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    fontWeight="400"
                    color="#000"
                    fontSize={{ xs: "14px" }}
                  >
                    {`${item.user.firstName} ${item.user.lastName}`}
                  </Typography>
                  <Typography
                    fontWeight="400"
                    color="#8C8C8C"
                    fontSize={{ xs: "12px", md: "14px" }}
                  >
                    {format(parseTime(item.createdAt), "MMMM d, h:mm a")}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: isMobile ? "none" : "flex" }}>
                <Button
                  style={styles.projectCommentButton}
                  size="small"
                  onClick={() => setCommentEdit(true)}
                  onMouseEnter={() => setHover("edit" + item.id)}
                  onMouseLeave={() => setHover("")}
                >
                  <EditForWeb
                    color={hover === "edit" + item.id ? "#0D6FF0" : "#8C8C8C"}
                  />
                </Button>
                <Button
                  style={styles.projectCommentButton}
                  disableRipple
                  size="small"
                  onClick={() => handleDeleteProjectComment("Delete")}
                  onMouseEnter={() => setHover("delete" + item.id)}
                  onMouseLeave={() => setHover("")}
                >
                  <DeleteForWeb
                    color={hover === "delete" + item.id ? "#FF0003" : "#FF4D4F"}
                  />
                </Button>
              </Box>
              {isMobile && (
                <Button
                  style={styles.threeDots}
                  size="small"
                  onClick={() => openModal("Actions")}
                >
                  <ThreeDots color="#000" />
                </Button>
              )}
            </Box>
            <Typography fontSize="14px" fontWeight={400} color="#000">
              {item.commentText}
            </Typography>
          </>
        ) : (
          <Box>
            <TextField
              multiline
              fullWidth
              rows={4}
              inputProps={{ maxLength: 500 }}
              value={commentText}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (commentText.length + e.target.value.length > 500)
                  return setCommentText(e.target.value.substring(0, 500));
                else setCommentText(e.target.value);
              }}
            />
            <Box sx={styles.actionButtons}>
              <Box sx={styles.buttonContainer}>
                <Box sx={{ width: isMobile ? "50%" : "45%" }}>
                  <Button
                    variant="outlined"
                    fullWidth
                    size="small"
                    color="secondary"
                    onClick={() => setCommentEdit(false)}
                  >
                    <Typography fontSize="14px" color="#262626">
                      Cancel
                    </Typography>
                  </Button>
                </Box>
                <Box sx={{ width: isMobile ? "50%" : "45%" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="small"
                    onClick={handleUpdateProjectComment}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>

      {openActionModal && (
        <Actions
          modalActionType={modalActionType}
          elem={item}
          type="Edit"
          projectId={id}
          isOpen={openActionModal}
          setCommentEdit={setCommentEdit}
          closeModal={() => setOpenActionModal(false)}
          setModalActionType={setModalActionType}
          fetchSuccess={fetchSuccess}
        />
      )}

      {openDeleteProjectModal && (
        <DeleteProjectComment
          fetchSuccess={fetchSuccess}
          modalActionType={modalActionType}
          elem={item}
          projectId={id}
          isOpen={openDeleteProjectModal}
          closeModal={() => setOpenDeleteProjectModal(false)}
        />
      )}
    </>
  );
};

export default ProjectCommentItem;
