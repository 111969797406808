import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { PlusForWeb, ThreeDots } from "src/assets/icons";
import { getTodaysClocksSelector } from "src/store/clocks/selectors";
import { ClockRecord } from "src/types";
import { formatDate, secondsToTime, parseTime } from "src/utils";
import Button from "../Button";
import ModalTodaysClockRecord from "../ModalTodaysClockRecord";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { getOrganizationSelector } from "src/store/auth/selectors";
import { format } from "date-fns";

const styles = {
  boxContainer: {
    mt: "15px",
    display: "flex",
    flexDirection: "column",
    border: "1px solid #F0F0F0",
    backgroundColor: "#fff",
    borderRadius: "4px",
    padding: "24px 22px",
  },
  topContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
};

interface Props {
  date: string;
  user?: any;
  fetchEntries: () => void;
}

const DailyEntriesMobile: React.FC<Props> = ({ date, user, fetchEntries }) => {
  const clocksState = useSelector(getTodaysClocksSelector);
  const organization = useSelector(getOrganizationSelector);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [elem, setElem] = useState<any>();
  const [modalsState, setModalsState] = useState<string>("");

  const handleOpenModal = (modalState: string, data?: any) => {
    setElem(data);
    setOpenModal(true);
    setModalsState(modalState);
  };

  const timeToText = (clockStart: string, clockEnd: string) => {
    const startUtc = parseTime(clockStart);
    const start = format(startUtc, "p");

    let end: any;
    if (clockEnd !== null) {
      const endUtc = parseTime(clockEnd);
      end = format(endUtc, "p");
    } else {
      end = "Still Working";
    }
    return `${start} - ${end}`;
  };

  return (
    <>
      <Box>
        <Box>
          <Box>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              size="medium"
              style={{ marginBottom: "15px" }}
              disabled={
                organization?.trial &&
                new Date().getTime() >
                  parseTime(organization?.trialExpires).getTime()
              }
              onClick={() => {
                handleOpenModal("MANUAL_ENTRY_MODAL", formatDate(date));
              }}
            >
              <PlusForWeb
                color={
                  organization?.trial &&
                  new Date().getTime() >
                    parseTime(organization?.trialExpires).getTime()
                    ? "#d9d9d9"
                    : "#FFF"
                }
              />
              <Typography
                ml="10px"
                fontSize="16px"
                fontWeight={400}
                color="#FFF"
              >
                Manual Entry
              </Typography>
            </Button>
          </Box>
          {clocksState.isLoading ? (
            <Box
              data-sqa-id="circular-progress-loader"
              display="flex"
              alignItems="center"
              justifyContent="center"
              mt="20px"
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Box
                p="15px 24px"
                display="flex"
                justifyContent="space-between"
                border="1px solid #F0F0F0"
                borderRadius="4px"
              >
                <Typography
                  fontSize={{ xs: "14px", md: "16px" }}
                  fontWeight={400}
                  color="#000"
                >
                  Daily Total
                </Typography>
                <Typography
                  fontSize={{ xs: "14px", md: "16px" }}
                  fontWeight={600}
                  color="#0D6FF0"
                >
                  {secondsToTime(clocksState.todaysClocks.dayTotal)}
                </Typography>
              </Box>
              {clocksState.todaysClocks.timeClockRecords.map(
                (item: ClockRecord) => {
                  return (
                    <Box key={item.id} sx={styles.boxContainer}>
                      <Box sx={styles.topContainer}>
                        <Box sx={styles.topContainer}>
                          <AccessTimeIcon color="primary" />
                          <Typography mx="10px" color="#262626">
                            {timeToText(item.startTime, item.endTime)}
                          </Typography>
                        </Box>
                        <Box
                          sx={[
                            styles.boxContainer,
                            {
                              mt: 0,
                              p: "14px 9px",
                              height: "40px",
                              justifyContent: "center",
                            },
                          ]}
                          onClick={() =>
                            handleOpenModal("GENERAL_ENTRY_MODAL", item)
                          }
                        >
                          <ThreeDots color="#262626" />
                        </Box>
                      </Box>
                      <Box sx={[styles.topContainer, { mt: "20px" }]}>
                        <Typography color="#8C8C8C" fontSize="14px">
                          Time Worked
                        </Typography>
                        <Typography color="#262626" fontSize="14px">
                          {secondsToTime(item.timeWorked)}
                        </Typography>
                      </Box>
                      {item.project?.numericId && (
                        <Box
                          sx={[
                            styles.topContainer,
                            { alignItems: "flex-start", mt: "14px" },
                          ]}
                        >
                          <Typography color="#8C8C8C" fontSize="14px">
                            Project
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-end",
                            }}
                          >
                            <Typography color="#8C8C8C" fontSize="12px">
                              Project ID {item.project?.numericId}
                            </Typography>
                            <Typography color="#262626" fontSize="14px">
                              {item.project?.projectName}
                            </Typography>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  );
                }
              )}
            </>
          )}
        </Box>
      </Box>

      {openModal && (
        <ModalTodaysClockRecord
          onFetchSuccess={fetchEntries}
          closeModal={() => setOpenModal(false)}
          isOpen={openModal}
          modalData={elem}
          modalsState={modalsState}
          setModalsState={setModalsState}
          currentTab={0}
          user={user}
        />
      )}
    </>
  );
};

export default DailyEntriesMobile;
