import {
  Box,
  CircularProgress,
  FormControl,
  MenuItem,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  DeleteForWeb,
  File,
  Link,
  PlusForWeb,
  ViewForWeb,
} from "src/assets/icons";
import { Button, Select } from "src/components";
import { styles } from "./report.module";
import PaginationContainer from "../../../components/Pagination";
import ReportTable from "./ReportTable";
import { getReportSelector } from "src/store/report/selectors";
import { ProjectAll, User } from "src/types";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { getUserSelector } from "src/store/users/selectors";
import { getProjectSelector } from "src/store/projects/selectors";
import CloseIcon from "@mui/icons-material/Close";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { parseTime } from "src/utils";
import { getOrganizationSelector } from "src/store/auth/selectors";

interface Props {
  selectedUsers: any[];
  selectedProjects: any[];
  selectedItems: string[];
  selectedDate: (number | Date)[];
  elem: any[];
  page: number;
  sortOrder: any;
  sortByTableName: string;
  userIds: string[];
  projectIds: string[];
  setSortOrder: (order: string) => void;
  fetchReport: () => void;
  requestSort: (elem: string) => void;
  handleOpenModal: (action: string, page: string, setState: any) => void;
  handleChangePage: (event: unknown, newPage: number) => void;
  setOpenDeleteReportModal: (bol: boolean) => void;
  setOpenActionModal: (bol: boolean) => void;
  handleChangeValue: (
    e: SelectChangeEvent,
    child: React.ReactNode,
    selectedUsers: any[],
    setSelectedUsers: any,
    setSelectedIds: any
  ) => void;
  setSelectedDate: (date) => void;
  setSelectedUsers: any;
  setSelectedProjects: any;
  setUserIds: any;
  setProjectIds: any;
  setOpenLinkProjectModal: (bol: boolean) => void;
  setOpenViewBreakdown: (bol: boolean) => void;
  handleTimesheet: () => void;
  setSelectedItems: any;
  removeSelectedItem: (
    userId: string,
    setSelectedUsers: any,
    selectedUsers: any,
    setSelectedIdsData: any,
    stateIdsData: string[]
  ) => void;
  setElem: any;
  setPage: (page: any) => void;
}

const ReportWeb: React.FC<Props> = ({
  selectedUsers,
  selectedProjects,
  userIds,
  projectIds,
  selectedItems,
  selectedDate,
  elem,
  page,
  sortOrder,
  sortByTableName,
  fetchReport,
  requestSort,
  handleOpenModal,
  setOpenActionModal,
  handleChangeValue,
  setSelectedUsers,
  setUserIds,
  setProjectIds,
  setSelectedDate,
  setSelectedProjects,
  setSelectedItems,
  setOpenDeleteReportModal,
  setOpenLinkProjectModal,
  setOpenViewBreakdown,
  handleTimesheet,
  handleChangePage,
  removeSelectedItem,
  setElem,
  setSortOrder,
  setPage,
}) => {
  const reportState = useSelector(getReportSelector);
  const projectState = useSelector(getProjectSelector);
  const userState = useSelector(getUserSelector);
  const organization = useSelector(getOrganizationSelector);

  useEffect(() => {
    const userIdsArr = [];
    const projectIdsArr = [];
    userState.users.map((elem) => {
      for (let i of userIds) {
        if (elem.id === i) {
          userIdsArr.push({
            name: `${elem.firstName} ${elem.lastName}`,
            id: elem.id,
          });
        }
      }
      return userIdsArr;
    });
    projectState.projectAll.map((elem) => {
      for (let i of projectIds) {
        if (elem.id === i) {
          projectIdsArr.push({
            name: elem.projectName,
            id: elem.id,
          });
        }
      }
      return projectIdsArr;
    });
    setSelectedProjects(projectIdsArr);
    setSelectedUsers(userIdsArr);
  }, []);

  return (
    <>
      <Box sx={styles.topContainer}>
        <Typography data-sqa-id="page-title" sx={styles.pageTitle}>
          Report
        </Typography>
      </Box>

      <Box sx={styles.teamContainer}>
        <Box sx={[styles.topContainer, { marginBottom: "20px", gap: "20px" }]}>
          <Box sx={styles.boxWrapper}>
            <FormControl fullWidth sx={{ width: "183px" }}>
              <Typography sx={styles.typographyTitle}>
                Filter by Name
              </Typography>
              <Select
                value=""
                onChange={(e: SelectChangeEvent, child: React.ReactNode) => {
                  handleChangeValue(
                    e,
                    child,
                    selectedUsers,
                    setSelectedUsers,
                    setUserIds
                  );
                  setPage(1);
                }}
                height={"46px"}
                fullWidth
                IconComponent={ArrowDropDownIcon}
              >
                {userState.users?.map((user: User) => {
                  return (
                    <MenuItem
                      key={user.id}
                      value={user.id}
                      data-user={`${user.firstName} ${user.lastName}`}
                    >
                      {`${user.firstName} ${user.lastName}`}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ width: "174px" }}>
              <Box sx={styles.dateStyle} className="report">
                <Typography sx={styles.typographyTitle}>
                  Filter by Date
                </Typography>
                <ArrowDropDownIcon sx={styles.arrowDate} color="action" />
                <DatePicker
                  selectsRange={true}
                  dateFormat="y-MM-dd"
                  startDate={selectedDate[0]}
                  endDate={selectedDate[1]}
                  onChange={(update) => {
                    setSelectedDate(update);
                    setPage(1);
                  }}
                  popperClassName="calendarPicker"
                  formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
                  renderCustomHeader={({
                    monthDate,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                  }) => (
                    <div
                      style={{
                        margin: 10,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <button
                        className="btn prev"
                        onClick={decreaseMonth}
                        disabled={prevMonthButtonDisabled}
                      >
                        {<KeyboardArrowLeft />}
                      </button>
                      <span className="react-datepicker__current-month">
                        {monthDate.toLocaleString("en-US", {
                          month: "long",
                          year: "numeric",
                        })}
                      </span>
                      <button
                        className="btn next"
                        onClick={increaseMonth}
                        disabled={nextMonthButtonDisabled}
                      >
                        {<KeyboardArrowRight />}
                      </button>
                    </div>
                  )}
                />
              </Box>
            </FormControl>
            <FormControl fullWidth sx={{ width: "192px" }}>
              <Typography sx={styles.typographyTitle}>
                Filter by Project
              </Typography>
              <Select
                value={""}
                onChange={(e: SelectChangeEvent, child: React.ReactNode) => {
                  handleChangeValue(
                    e,
                    child,
                    selectedProjects,
                    setSelectedProjects,
                    setProjectIds
                  );
                  setPage(1);
                }}
                height={"46px"}
                IconComponent={ArrowDropDownIcon}
              >
                {projectState.projectAll?.map((project: ProjectAll) => {
                  if (project?.subProjects) {
                    let arrProjectGroup = [...project.subProjects];
                    arrProjectGroup.unshift(project);
                    return arrProjectGroup.map(
                      (subproject: ProjectAll, i: number) => {
                        return (
                          <MenuItem
                            sx={{ pl: i ? "30px" : "16px" }}
                            key={subproject.id}
                            value={subproject.id}
                          >
                            {`${subproject.projectName} (${subproject.numericId})`}
                          </MenuItem>
                        );
                      }
                    );
                  } else
                    return (
                      <MenuItem key={project.id} value={project.id}>
                        {`${project.projectName} (${project.numericId})`}
                      </MenuItem>
                    );
                })}
              </Select>
            </FormControl>
          </Box>
          <Box sx={styles.boxWrapper}>
            <Button
              variant="contained"
              color="error"
              disabled={selectedItems.length === 0}
              style={{ height: "46px", minWidth: "auto" }}
              onClick={() =>
                handleOpenModal("Delete", "Report", setOpenDeleteReportModal)
              }
            >
              <DeleteForWeb />
              <Typography
                ml="10px"
                fontSize="16px"
                fontWeight={400}
                display={{ xs: "none", xxl: "block" }}
              >
                Delete
              </Typography>
            </Button>
            <Button
              variant="contained"
              disabled={selectedItems.length === 0}
              style={{ height: "46px", minWidth: "auto" }}
              onClick={() =>
                handleOpenModal(
                  "Link Time Records",
                  "to Project",
                  setOpenLinkProjectModal
                )
              }
            >
              <Link />
              <Typography
                ml="10px"
                fontSize="16px"
                fontWeight={400}
                display={{ xs: "none", xxl: "block" }}
              >
                Link to Project
              </Typography>
            </Button>
            <Button
              style={{ height: "46px", minWidth: "auto" }}
              variant="contained"
              color="primary"
              onClick={() =>
                handleOpenModal(
                  "Current Report",
                  "Breakdown",
                  setOpenViewBreakdown
                )
              }
            >
              <Box sx={styles.addButtonContainer}>
                <ViewForWeb color="#FFF" />
                <Typography
                  sx={styles.addButtonText}
                  display={{ xs: "none", xxl: "block" }}
                >
                  Breakdown
                </Typography>
              </Box>
            </Button>
            <Button
              style={{ height: "46px", minWidth: "auto" }}
              variant="contained"
              color="primary"
              disabled={reportState.isTimesheetLoading}
              onClick={handleTimesheet}
            >
              <Box sx={styles.addButtonContainer}>
                <File
                  color={reportState.isTimesheetLoading ? "#d9d9d9" : "#FFF"}
                />
                <Typography
                  sx={styles.addButtonText}
                  display={{ xs: "none", xl: "block" }}
                >
                  Timesheet
                </Typography>
              </Box>
            </Button>
            <Button
              style={{ height: "46px", minWidth: "auto" }}
              variant="contained"
              color="primary"
              disabled={
                organization?.trial &&
                new Date().getTime() >
                  parseTime(organization?.trialExpires).getTime()
              }
              onClick={() =>
                handleOpenModal("Manual", "Entry", setOpenActionModal)
              }
            >
              <Box sx={styles.addButtonContainer}>
                <PlusForWeb
                  color={
                    organization?.trial &&
                    new Date().getTime() >
                      parseTime(organization?.trialExpires).getTime()
                      ? "#d9d9d9"
                      : "#FFF"
                  }
                />
                <Typography
                  sx={styles.addButtonText}
                  display={{ xs: "none", xl: "block" }}
                >
                  Manual Entry
                </Typography>
              </Box>
            </Button>
          </Box>
        </Box>
        <Box my="20px" display="flex" flexDirection="column" rowGap="15px">
          {selectedUsers.length > 0 && (
            <Box sx={styles.filterListWrapper}>
              <Box sx={styles.filterTitle}>
                <Typography color="#0D6FF0">Filter by Name</Typography>
              </Box>
              {selectedUsers.map((user) => (
                <Box key={user.id} sx={styles.filterItemWrapper}>
                  <Typography color="#262626">{user.name}</Typography>
                  <Box
                    sx={styles.filterItem}
                    onClick={() =>
                      removeSelectedItem(
                        user.id,
                        setSelectedUsers,
                        selectedUsers,
                        setUserIds,
                        userIds
                      )
                    }
                  >
                    <CloseIcon fontSize="small" color="action" />
                  </Box>
                </Box>
              ))}
            </Box>
          )}
          <Box sx={styles.filterListWrapper}>
            <Box sx={styles.filterTitle}>
              <Typography color="#0D6FF0">Filter by Date</Typography>
            </Box>
            <Box sx={styles.filterItemWrapper}>
              <Typography color="#262626">
                {`${format(new Date(selectedDate[0]), "MMMM d, y")} — ${
                  selectedDate[1]
                    ? format(new Date(selectedDate[1]), "MMMM d, y")
                    : ""
                }`}
              </Typography>
            </Box>
          </Box>
          {selectedProjects.length > 0 && (
            <Box sx={styles.filterListWrapper}>
              <Box sx={styles.filterTitle}>
                <Typography color="#0D6FF0">Filter by Project</Typography>
              </Box>
              {selectedProjects.map((project) => (
                <Box key={project.id} sx={styles.filterItemWrapper}>
                  <Typography
                    maxWidth="250px"
                    textOverflow="ellipsis"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    color="#262626"
                  >
                    {project.name}
                  </Typography>
                  <Box
                    sx={styles.filterItem}
                    onClick={() =>
                      removeSelectedItem(
                        project.id,
                        setSelectedProjects,
                        selectedProjects,
                        setProjectIds,
                        projectIds
                      )
                    }
                  >
                    <CloseIcon fontSize="small" color="action" />
                  </Box>
                </Box>
              ))}
            </Box>
          )}
        </Box>
        {reportState.isLoading ? (
          <Box
            data-sqa-id="circular-progress-loader"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress />
          </Box>
        ) : reportState.report?.days.length ? (
          <>
            <ReportTable
              report={reportState.report?.days}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              elem={elem}
              setElem={setElem}
              fetchReport={fetchReport}
              requestSort={requestSort}
              setSortOrder={setSortOrder}
              sortOrder={sortOrder}
              sortBy={sortByTableName}
            />
            {
              // Show pagination only when there is more than 1 page.
            }
            {reportState?.report?.searchParameters?.totalPages > 1 ? (
              <Box mt="20px" sx={styles.pagination}>
                <PaginationContainer
                  page={page}
                  handleChangePage={handleChangePage}
                  pages={reportState?.report?.searchParameters?.totalPages}
                />
              </Box>
            ) : null}
          </>
        ) : (
          <Box sx={styles.noData}>
            <Typography
              data-sqa-id="no-comments-yet-text"
              sx={styles.noDataText}
            >
              No results found for your search. Please try again with different
              keywords.
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
};

export default ReportWeb;
