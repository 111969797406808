import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AppRoutes } from "src/constants";
import {
  SignIn,
  SignUp,
  ForgotPassword,
  CheckEmail,
  PrivacyPolicy,
  TermsConditions,
  Welcome,
  ResetPassword,
  Verification,
  ConfirmEmail,
} from "src/pages/common";
import { PrivateRoute } from "./components";
import {
  Team,
  Settings,
  Projects,
  ProjectItem,
  Report,
  ReportItem,
} from "./pages/AdminView";
import { Dashboard, HistoryDayPage } from "./pages/UserView";
import { getUserSelector } from "./store/auth/selectors";

function RoutingWeb() {
  const user = useSelector(getUserSelector);
  const [roleMode, setRoleMode] = useState<string>(
    localStorage.getItem("roleMode")
  );

  useEffect(() => {
    if (user && user.isAdmin && localStorage.getItem("roleMode") !== null) {
      localStorage.getItem("roleMode");
      setRoleMode(localStorage.getItem("roleMode"));
    } else {
      localStorage.setItem("roleMode", "admin");
      setRoleMode("admin");
    }
  }, [roleMode]);

  return (
    <BrowserRouter>
      <Routes>
        {!user && <Route path={AppRoutes.SignIn} element={<SignIn />} />}
        <Route path={AppRoutes.SignUp} element={<SignUp />} />
        <Route
          path={`${AppRoutes.EmailConfirm}/:id`}
          element={<ConfirmEmail />}
        />
        <Route path={AppRoutes.ForgotPassword} element={<ForgotPassword />} />
        <Route path={AppRoutes.CheckEmail} element={<CheckEmail />} />
        <Route
          path={`${AppRoutes.ResetPassword}/:id`}
          element={<ResetPassword />}
        />
        <Route path={AppRoutes.Verification} element={<Verification />} />
        <Route path={AppRoutes.Welcome} element={<Welcome />} />
        <Route path={AppRoutes.PrivacyPolicy} element={<PrivacyPolicy />} />
        <Route path={AppRoutes.TermsConditions} element={<TermsConditions />} />
        {user && user?.isAdmin && roleMode === "admin" ? (
          <Route
            path="/"
            element={
              <PrivateRoute>
                <Team />
              </PrivateRoute>
            }
          />
        ) : (
          <Route
            path={AppRoutes.Dashboard}
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
        )}
        {user && user?.isAdmin && roleMode === "admin" && (
          <Route
            path={AppRoutes.Projects}
            element={
              <PrivateRoute>
                <Projects />
              </PrivateRoute>
            }
          />
        )}
        {user && user?.isAdmin && roleMode === "admin" && (
          <Route
            path={`${AppRoutes.ProjectItem}/:id`}
            element={
              <PrivateRoute>
                <ProjectItem />
              </PrivateRoute>
            }
          />
        )}

        <Route
          path={AppRoutes.Settings}
          element={
            <PrivateRoute>
              <Settings />
            </PrivateRoute>
          }
        />
        {user && user?.isAdmin && roleMode === "admin" && (
          <Route
            path={AppRoutes.Report}
            element={
              <PrivateRoute>
                <Report />
              </PrivateRoute>
            }
          />
        )}
        {user && user?.isAdmin && roleMode === "admin" && (
          <Route
            path={`${AppRoutes.Report}/:id`}
            element={
              <PrivateRoute>
                <ReportItem />
              </PrivateRoute>
            }
          />
        )}
        {((user && user?.isAdmin && roleMode === "user") ||
          (user && !user?.isAdmin)) && (
          <Route
            path={`${AppRoutes.HistoryDay}/:id`}
            element={
              <PrivateRoute>
                <HistoryDayPage />
              </PrivateRoute>
            }
          />
        )}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        theme="light"
      />
    </BrowserRouter>
  );
}

export default RoutingWeb;
