import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import AppBar from "src/components/AppBar";
import PaidPlans from "src/components/PaidPlans";
import theme from "src/theme";
import { Lock as LockIcon } from "src/assets/icons";
import ChoosePlan from "src/components/ActionsModal/ChoosePlan";
import { useSelector } from "react-redux";
import { getSubscriptionSelector } from "src/store/subscription/selectors";

const styles = {
  boxContainer: {
    display: "flex",
    flexDirection: "column",
    border: "1px solid #F0F0F0",
    backgroundColor: "#fff",
    borderRadius: "4px",
    padding: "24px 22px",
  },
};

const ChoosePlanMobile = () => {
  const subscription = useSelector(getSubscriptionSelector);
  const [openContactUsModal, setOpenContactUsModal] = useState(false);
  const [modalActionType, setModalActionType] = useState({
    action: "",
    page: "",
  });
  const [user, setUser] = useState(
    subscription.subscriptions?.[0]?.subscriptionUsersNumber
      ? subscription.subscriptions?.[0]?.subscriptionUsersNumber
      : 1
  );

  useEffect(() => {
    if (subscription.subscriptions?.[0]?.status === "canceled") {
      setUser(1);
    }
  }, []);

  return (
    <>
      <AppBar>
        <>
          <Box sx={styles.boxContainer}>
            <PaidPlans
              setOpenContactUsModal={() => setOpenContactUsModal(true)}
              setModalActionType={setModalActionType}
              user={user}
              setUser={setUser}
            />
            <Box
              display={"flex"}
              alignItems="center"
              justifyContent={"center"}
              mt={"20px"}
            >
              <Box display={"flex"} ml={"6px"} alignItems="center">
                <LockIcon
                  color={theme.palette.text.secondary}
                  width={16}
                  height={16}
                />
                <Typography
                  color={theme.palette.text.secondary}
                  fontSize={{ xs: "12px", md: "14px" }}
                  ml={"6px"}
                >
                  Secure Payment.
                </Typography>
              </Box>
            </Box>
          </Box>
          {openContactUsModal && (
            <ChoosePlan
              modalActionType={modalActionType}
              setModalActionType={setModalActionType}
              isOpen={openContactUsModal}
              closeModal={() => setOpenContactUsModal(false)}
            />
          )}
        </>
      </AppBar>
    </>
  );
};

export default ChoosePlanMobile;
