import { Box, Typography } from "@mui/material";
import React from "react";
import theme from "src/theme";
import Button from "../Button";

const styles = {
  actionButtons: {
    display: "flex",
    justifyContent: "space-between",
    columnGap: "10px",
    marginTop: "20px",
    ">div": {
      width: "100%",
    },
  },
  actionButton: {
    textTransform: "none",
  },
  actionButtonDelete: {
    textTransform: "none",
  },
};

interface ModalProps {
  closeModal: () => void;
  deleteElement: () => void;
  deleteArticle: string;
}

const DeleteModal: React.FC<ModalProps> = ({
  closeModal,
  deleteElement,
  deleteArticle,
}) => {
  return (
    <>
      <Typography fontSize={14}>
        Are you sure you want to delete the {deleteArticle.toLowerCase()}?
      </Typography>
      <Box sx={styles.actionButtons}>
        <Box>
          <Button
            variant="outlined"
            size="medium"
            color="secondary"
            fullWidth
            sx={styles.actionButton}
            onClick={closeModal}
          >
            <Typography fontSize="14px" color={theme.palette.text.primary}>
              Cancel
            </Typography>
          </Button>
        </Box>
        <Box>
          <Button
            variant="outlined"
            size="medium"
            color="error"
            fullWidth
            sx={styles.actionButtonDelete}
            onClick={deleteElement}
          >
            <Typography fontSize="14px">Delete</Typography>
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default DeleteModal;
