export const FETCH_TODAYS_CLOCK_REQUEST = "FETCH_TODAYS_CLOCK_REQUEST";
export const FETCH_TODAYS_CLOCK_SUCCESS = "FETCH_TODAYS_CLOCK_SUCCESS";
export const FETCH_TODAYS_CLOCK_FAILURE = "FETCH_TODAYS_CLOCK_FAILURE";

export const FETCH_WEEKLY_CLOCK_REQUEST = "FETCH_WEEKLY_CLOCK_REQUEST";
export const FETCH_WEEKLY_CLOCK_SUCCESS = "FETCH_WEEKLY_CLOCK_SUCCESS";
export const FETCH_WEEKLY_CLOCK_FAILURE = "FETCH_WEEKLY_CLOCK_FAILURE";

export const FETCH_DAILY_CLOCK_REQUEST = "FETCH_DAILY_CLOCK_REQUEST";
export const FETCH_DAILY_CLOCK_SUCCESS = "FETCH_DAILY_CLOCK_SUCCESS";
export const FETCH_DAILY_CLOCK_FAILURE = "FETCH_DAILY_CLOCK_FAILURE";

export const FETCH_WORKED_MONTH_REQUEST = "FETCH_WORKED_MONTH_REQUEST";
export const FETCH_WORKED_MONTH_SUCCESS = "FETCH_WORKED_MONTH_SUCCESS";
export const FETCH_WORKED_MONTH_FAILURE = "FETCH_WORKED_MONTH_FAILURE";

export const FETCH_DAILY_CLOCK_BY_USER_REQUEST =
  "FETCH_DAILY_CLOCK_BY_USER_REQUEST";
export const FETCH_DAILY_CLOCK_BY_USER_SUCCESS =
  "FETCH_DAILY_CLOCK_BY_USER_SUCCESS";
export const FETCH_DAILY_CLOCK_BY_USER_FAILURE =
  "FETCH_DAILY_CLOCK_BY_USER_FAILURE";

export const DELETE_TODAYS_CLOCK_REQUEST = "DELETE_TODAYS_CLOCK_REQUEST";
export const DELETE_TODAYS_CLOCK_SUCCESS = "DELETE_TODAYS_CLOCK_SUCCESS";
export const DELETE_TODAYS_CLOCK_FAILURE = "DELETE_TODAYS_CLOCK_FAILURE";

export const DELETE_CLOCK_BY_USER_AND_DAY_REQUEST =
  "DELETE_CLOCK_BY_USER_AND_DAY_REQUEST";
export const DELETE_CLOCK_BY_USER_AND_DAY_SUCCESS =
  "DELETE_CLOCK_BY_USER_AND_DAY_SUCCESS";
export const DELETE_CLOCK_BY_USER_AND_DAY_FAILURE =
  "DELETE_CLOCK_BY_USER_AND_DAY_FAILURE";

export const LINK_TIME_RECORD_REQUEST = "LINK_TIME_RECORD_REQUEST";
export const LINK_TIME_RECORD_SUCCESS = "LINK_TIME_RECORD_SUCCESS";
export const LINK_TIME_RECORD_FAILURE = "LINK_TIME_RECORD_FAILURE";

export const LINK_TIME_RECORD_BY_USER_AND_DAY_REQUEST =
  "LINK_TIME_RECORD_BY_USER_AND_DAY_REQUEST";
export const LINK_TIME_RECORD_BY_USER_AND_DAY_SUCCESS =
  "LINK_TIME_RECORD_BY_USER_AND_DAY_SUCCESS";
export const LINK_TIME_RECORD_BY_USER_AND_DAY_FAILURE =
  "LINK_TIME_RECORD_BY_USER_AND_DAY_FAILURE";

export const MANUAL_ENTRY_REQUEST = "MANUAL_ENTRY_REQUEST";
export const MANUAL_ENTRY_SUCCESS = "MANUAL_ENTRY_SUCCESS";
export const MANUAL_ENTRY_FAILURE = "MANUAL_ENTRY_FAILURE";

export const MANUAL_ENTRY_BY_USER_REQUEST = "MANUAL_ENTRY_BY_USER_REQUEST";
export const MANUAL_ENTRY_BY_USER_SUCCESS = "MANUAL_ENTRY_BY_USER_SUCCESS";
export const MANUAL_ENTRY_BY_USER_FAILURE = "MANUAL_ENTRY_BY_USER_FAILURE";

export const UPDATE_ENTRY_TIME_RECORD_REQUEST =
  "UPDATE_ENTRY_TIME_RECORD_REQUEST";
export const UPDATE_ENTRY_TIME_RECORD_SUCCESS =
  "UPDATE_ENTRY_TIME_RECORD_SUCCESS";
export const UPDATE_ENTRY_TIME_RECORD_FAILURE =
  "UPDATE_ENTRY_TIME_RECORD_FAILURE";

export const CLEAN_ERROR = "CLEAN_ERROR";
