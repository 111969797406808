import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "./Button";
import Input from "./Input";
import Select from "./Select";
import americaStates from "src/AmericaStates.json";
import theme from "src/theme";
import { useSelector } from "react-redux";
import {
  getErrorSelector,
  getOrganizationSelector,
} from "src/store/auth/selectors";
import { useDispatch } from "react-redux";
import {
  cleanError,
  deleteOrganizationRequest,
  getOrganizationRequest,
  logout,
  updateOrganizationRequest,
} from "src/store/auth/actions";
import { isMobile } from "mobile-device-detect";
import DeactivateUserModal from "./ActionsModal/DeactivateUserModal";
import { formatPhoneNumber } from "src/utils";
import * as yup from "yup";
import { ZIP_NUMBER_REGEX } from "src/constants";
import { useFormik } from "formik";
import { isFieldHasError } from "src/utils";
import { toast } from "react-toastify";

const styles = {
  card: {
    p: { xs: 0, md: "20px" },
    borderRadius: "4px",
    border: {
      xs: "none",
      md: `1px solid ${theme.palette.background.default}`,
    },
  },
  selectLabel: {
    lineHeight: 1.5,
    fontSize: { xs: "14px", md: "16px" },
  },
  select: {
    height: { xs: "40px", md: "46px" },
  },
  actions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    mt: "20px",
    flexDirection: { xs: "column-reverse", md: "row" },
    button: {
      width: { xs: "100%", md: "auto" },
    },
  },
  btn: {
    display: "flex",
    padding: "3px 40px",
  },
  cityStateCode: {
    display: "flex",
    flexWrap: { xs: "wrap", md: "nowrap" },
    justifyContent: { xs: "space-between", md: "normal" },
    city: {
      width: { xs: "100%", md: "auto" },
    },
    state: {
      width: { xs: "calc(50% - 5px)", md: "115px" },
    },
    zipCode: {
      width: { xs: "calc(50% - 5px)", md: "auto" },
    },
  },
};

const validationSchema = yup.object().shape({
  companyName: yup.string().required("Company name is required"),
  zip: yup.string().matches(ZIP_NUMBER_REGEX, "Zip code is not valid"),
});

const AccountDetails = () => {
  const organization = useSelector(getOrganizationSelector);
  const erorState = useSelector(getErrorSelector);
  const dispatch = useDispatch();

  const [openModal, setOpenModal] = useState(false);
  const modalActionType = { action: "Close", page: "Account" };

  const handleDeleteAccount = () => {
    dispatch(
      deleteOrganizationRequest({
        onSuccess: () => dispatch(logout()),
      })
    );
  };

  const formik = useFormik({
    initialValues: {
      companyName: organization?.companyName ? organization?.companyName : "",
      phoneNumber: organization?.phoneNumber
        ? formatPhoneNumber(organization?.phoneNumber)
        : "",
      city: organization?.address?.city ? organization?.address?.city : "",
      address: organization?.address?.address
        ? organization?.address?.address
        : "",
      addressTwo: organization?.address?.addressTwo
        ? organization?.address?.addressTwo
        : "",
      state: organization?.address?.state ? organization?.address?.state : "",
      zip: organization?.address?.zip ? organization?.address?.zip : "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: ({
      companyName,
      phoneNumber,
      city,
      address,
      addressTwo,
      state,
      zip,
    }) => {
      const phoneNumberWithoutMask = phoneNumber.replace(/\D/g, "");
      const companyNameTrim = companyName.trim();
      if (!companyNameTrim) return;
      else {
        dispatch(
          updateOrganizationRequest(
            {
              organization: {
                id: organization.id,
                companyName: companyNameTrim.trim(),
                phoneNumber: phoneNumberWithoutMask
                  ? phoneNumberWithoutMask
                  : null,
                address: {
                  address: address ? address.trim() : null,
                  addressTwo: addressTwo ? addressTwo.trim() : null,
                  city: city ? city.trim() : null,
                  state: state ? state : null,
                  zip: zip ? zip.toString() : null,
                },
              },
            },
            {
              onSuccess,
            }
          )
        );
      }
    },
  });

  const onSuccess = () => {
    dispatch(getOrganizationRequest());
    toast.success(
      <>
        <Typography fontSize="16px" color="#262626">
          Account details updated successfully
        </Typography>
      </>
    );
  };

  useEffect(() => {
    dispatch(cleanError());
    return () => {
      dispatch(cleanError());
    };
  }, []);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ maxWidth: "540px" }}>
          <Box sx={styles.card}>
            <Box mt="10px">
              <Input
                type="text"
                label="Company Name"
                required
                fullWidth
                size="small"
                id="companyName"
                value={formik.values.companyName}
                onChange={formik.handleChange}
                error={isFieldHasError({
                  formik,
                  fieldName: "companyName",
                  isGlobalError: Boolean(erorState),
                })}
                helperText={
                  formik.touched.companyName && formik.errors.companyName
                }
              />
            </Box>
            <Box mt="20px">
              <Input
                mask="(999) 999-9999"
                label="Phone Number"
                fullWidth
                size="small"
                id="phoneNumber"
                value={formik.values.phoneNumber}
                onChange={formik.handleChange}
              />
            </Box>
            <Box mt="20px">
              <Input
                type="text"
                label="Company Address"
                fullWidth
                size="small"
                id="address"
                value={formik.values.address}
                onChange={formik.handleChange}
              />
            </Box>
            <Box mt="20px">
              <Input
                type="text"
                label="Address 2 (optional)"
                fullWidth
                size="small"
                id="addressTwo"
                value={formik.values.addressTwo}
                onChange={formik.handleChange}
              />
            </Box>
            <Box sx={styles.cityStateCode}>
              <Box mt="20px" sx={styles.cityStateCode.city}>
                <Input
                  type="text"
                  label="City"
                  fullWidth
                  size="small"
                  id="city"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                />
              </Box>
              <Box
                mt="20px"
                mx={{ xs: "0", md: "20px" }}
                sx={styles.cityStateCode.state}
              >
                <InputLabel sx={styles.selectLabel}>State</InputLabel>
                <FormControl fullWidth>
                  <Select
                    value={formik.values.state}
                    onChange={(opt) =>
                      formik.setFieldValue("state", opt.target.value)
                    }
                    size="small"
                    mt="10px"
                    fontSize="16px"
                    sx={styles.select}
                    height={{ xs: "40px", md: "46px" }}
                  >
                    {americaStates.map((elem: string) => (
                      <MenuItem key={elem} value={elem}>
                        {elem}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box mt="20px" sx={styles.cityStateCode.zipCode}>
                <Input
                  type="text"
                  label="Zip Code"
                  fullWidth
                  size="small"
                  id="zip"
                  value={formik.values.zip}
                  onChange={formik.handleChange}
                  error={isFieldHasError({
                    formik,
                    fieldName: "zip",
                    isGlobalError: Boolean(erorState),
                  })}
                  helperText={formik.touched.zip && formik.errors.zip}
                />
              </Box>
            </Box>
          </Box>
          <Box sx={styles.actions}>
            <Button
              variant="outlined"
              color="error"
              size={isMobile ? "medium" : "small"}
              style={styles.btn}
              mt={{ xs: "10px", md: "0" }}
              onClick={() => setOpenModal(true)}
            >
              <Typography fontSize={{ xs: "14px", md: "16px" }}>
                Close Account
              </Typography>
            </Button>
            <Button
              variant="contained"
              size={isMobile ? "medium" : "small"}
              color="primary"
              style={styles.btn}
              type="submit"
            >
              <Typography fontSize={{ xs: "14px", md: "16px" }}>
                Save
              </Typography>
            </Button>
          </Box>
        </Box>
      </form>

      {openModal && (
        <DeactivateUserModal
          fetchRequest={handleDeleteAccount}
          modalActionType={modalActionType}
          elem="admin"
          isOpen={openModal}
          closeModal={() => setOpenModal(false)}
        />
      )}
    </>
  );
};

export default AccountDetails;
