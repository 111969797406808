import * as React from "react";
import { SVGProps } from "react";

const DeleteIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" {...props}>
    <path
      d="M2.5 5h15M15.833 5v11.667a1.666 1.666 0 0 1-1.667 1.666H5.833a1.667 1.667 0 0 1-1.667-1.666V5m2.5 0V3.333a1.667 1.667 0 0 1 1.667-1.666h3.333a1.667 1.667 0 0 1 1.667 1.666V5M8.334 9.167v5M11.666 9.167v5"
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DeleteIcon;
