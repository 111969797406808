import React, { useCallback, useEffect, useState } from "react";
import ReactInputVerificationCode from "react-input-verification-code";
import "react-input-verification-code/dist";
import "./verificationCode.css";
import { Container, Typography, Card, Box, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Button } from "src/components";
import { AppRoutes } from "src/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  cleanError,
  sendVerificationCodeRequest,
  sendVerificationRequest,
} from "src/store/auth/actions";
import { getAuthSelector } from "src/store/auth/selectors";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    paddingTop: "24px",
    paddingBottom: "24px",
  },
  cardWrapper: {
    width: "100%",
    mt: "35px",
    px: {
      xs: "24px",
      md: "30px",
    },
    py: "30px",
    maxWidth: {
      xs: "350px",
      md: "450px",
    },
  },
  headerWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  subText: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#262626",
  },
  errorText: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#ff0000",
  },
};

const defaultContactInfo = {
  email: "support@busybucket.io",
};

const Verification = () => {
  const navigate = useNavigate();
  const authState = useSelector(getAuthSelector);
  console.log(authState.error);
  const dispatch = useDispatch();
  const [code, setCode] = useState("");
  const [showSendCode, setShowSendCode] = useState(true);
  // eslint-disable-next-line no-undef
  const email = process.env.REACT_APP_SUPPORT_EMAIL || defaultContactInfo.email;

  const resendCode = () => {
    dispatch(sendVerificationRequest());
    setShowSendCode(false);
  };

  const onVerificationSuccess = useCallback(() => {
    navigate(AppRoutes.Welcome);
  }, [navigate]);

  const redirectToPrivacyPolicy = useCallback(() => {
    navigate(AppRoutes.PrivacyPolicy);
  }, [navigate]);

  const redirectToTermsConditions = useCallback(() => {
    navigate(AppRoutes.TermsConditions);
  }, [navigate]);

  const handleVerificationSubmit = () => {
    dispatch(
      sendVerificationCodeRequest(
        {
          phoneVerificationCode: code,
        },
        {
          onSuccess: onVerificationSuccess,
        }
      )
    );
  };

  useEffect(() => {
    const id = setTimeout(() => setShowSendCode(true), 60000);
    return () => {
      clearInterval(id);
    };
  }, [showSendCode]);

  useEffect(() => {
    dispatch(cleanError());
    dispatch(sendVerificationRequest());
    return () => {
      dispatch(cleanError());
    };
  }, []);

  return (
    <Container sx={styles.container} maxWidth="sm">
      <Typography
        data-sqa-id="page-title"
        color="primary"
        fontWeight="800"
        fontSize={{ xs: "24px", md: "36px" }}
      >
        BusyBucket
      </Typography>
      <Card variant="outlined" sx={styles.cardWrapper}>
        <Box sx={styles.headerWrapper}>
          <Typography
            fontWeight="bold"
            fontSize={{ xs: "20px", md: "24px" }}
            mb="14px"
          >
            Enter Verification Code
          </Typography>
          <Typography sx={styles.subText}>
            An SMS with your verification code was sent to your phone.
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            mt: "30px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <ReactInputVerificationCode
            autoFocus
            onChange={setCode}
            value={code}
          />
        </Box>
        {authState.error && (
          <Box
            sx={{
              mt: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography sx={styles.errorText}>{authState.error}</Typography>
          </Box>
        )}
        <Box mt="30px">
          <Button
            variant="contained"
            color="primary"
            fullWidth
            size="small"
            onClick={handleVerificationSubmit}
          >
            Continue
          </Button>
        </Box>
        {showSendCode && (
          <Box
            sx={{
              marginTop: "16px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Link
              variant="body1"
              color="primary"
              component="button"
              fontSize={{ xs: "14px", md: "16px" }}
              onClick={resendCode}
            >
              Resend code
            </Link>
          </Box>
        )}
        <Box
          sx={{
            mt: "30px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography fontSize={{ xs: "14px", md: "14px" }}>
            Can&apos;t receive an SMS?
          </Typography>
          <Link
            fontSize={{ xs: "14px", md: "16px" }}
            color="primary"
            href={`mailto:${email}`}
          >
            Contact support
          </Link>
        </Box>
      </Card>
      <Link
        data-sqa-id="terms-conditions-footer-link"
        mt="30px"
        variant="body1"
        component="button"
        fontSize={{ xs: "12px", md: "14px" }}
        color="secondary"
        sx={{ marginTop: "30px" }}
        onClick={redirectToTermsConditions}
      >
        Terms of Service
      </Link>
      <Link
        variant="body1"
        color="secondary"
        component="button"
        fontSize={{ xs: "12px", md: "14px" }}
        sx={{ marginTop: "20px" }}
        onClick={redirectToPrivacyPolicy}
      >
        Privacy Policy
      </Link>
    </Container>
  );
};

export default Verification;
