import {
  Box,
  Typography,
  IconButton,
  FormControl,
  MenuItem,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import DatePicker from "react-datepicker";
import Button from "../../Button";
import Select from "../../Select";
import theme from "src/theme";
import { EntryModalBaseProps } from "../types";
import { useDispatch, useSelector } from "react-redux";
import {
  cleanError,
  manualEntryByUserRequest,
  manualEntryRequest,
} from "src/store/clocks/actions";
import {
  clientTimezone,
  formatDateTime,
  formatDate,
  secondsToTime,
} from "src/utils";
import { format, parse, isValid } from "date-fns";
import { getTodaysClocksSelector } from "src/store/clocks/selectors";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { getUserSelector } from "src/store/users/selectors";
import { styles } from "./manualEntryModal.module";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopTimePicker } from "@mui/x-date-pickers";

const ManualEntryModal: React.FC<EntryModalBaseProps> = ({
  closeModal,
  onFetchSuccess,
  currentTab,
  modalData,
  user,
  hideDefaultWrapper,
}) => {
  const [startValue, setStartValue] = React.useState<Date | null>(null);
  const [endValue, setEndValue] = React.useState<Date | null>(null);
  const [selectDate, setSelectDate] = useState<Date | string>(
    currentTab === 2 ? new Date() : ""
  );
  const [selectUser, setSelectUser] = useState("");
  const [formatStartData, setFormatStartData] = useState("");
  const [formatEndData, setFormatEndData] = useState("");
  const dispatch = useDispatch();
  const clocks = useSelector(getTodaysClocksSelector);
  const allUsers = useSelector(getUserSelector);
  const [errorMessage, setErrorMessage] = useState(false);
  const [userPosition, setUserPosition] = useState(null);

  const [hoursWorked, setHoursWorked] = useState(0);
  const [error, setError] = useState({
    startTime: "",
    endTime: "",
    selectDate: "",
    selectUser: "",
  });

  const handleChangeDate = (event: SelectChangeEvent) => {
    setSelectDate(event.target.value as string);
  };

  const handleChangeUser = (event: SelectChangeEvent) => {
    setSelectUser(event.target.value as string);
  };

  const countTimeWorked = () => {
    let formattingStartData: Date;
    let formattingEndData: Date;

    if (currentTab === 0) {
      if (!isValid(startValue) || !isValid(endValue)) return;
      else {
        let formatStartDataToTime = startValue
          ? format(startValue, "h:mm a")
          : null;
        let formatEndDataToTime = endValue ? format(endValue, "h:mm a") : null;
        formattingStartData = parse(
          formatStartDataToTime,
          "h:mm a",
          new Date(modalData)
        );
        formattingEndData = parse(
          formatEndDataToTime,
          "h:mm a",
          new Date(modalData)
        );
      }
    } else if (currentTab === 2) {
      const formattedStartDate = format(new Date(selectDate), "y-MM-dd");
      const formattedEndDate = format(new Date(selectDate), "y-MM-dd");
      if (!isValid(startValue) || !isValid(endValue)) return;
      else {
        let formatStartDataToTime = startValue
          ? format(startValue, "h:mm a")
          : null;
        let formatEndDataToTime = endValue ? format(endValue, "h:mm a") : null;
        formattingStartData = parse(
          formatStartDataToTime,
          "h:mm a",
          parse(formattedStartDate, "y-MM-dd", new Date())
        );
        formattingEndData = parse(
          formatEndDataToTime,
          "h:mm a",
          parse(formattedEndDate, "y-MM-dd", new Date())
        );
      }
    } else {
      if (!isValid(startValue) || !isValid(endValue)) return;
      else {
        let formatStartDataToTime = startValue
          ? format(startValue, "h:mm a")
          : null;
        let formatEndDataToTime = endValue ? format(endValue, "h:mm a") : null;
        formattingStartData = parse(
          formatStartDataToTime,
          "h:mm a",
          parse(String(selectDate), "y-MM-dd", new Date())
        );
        formattingEndData = parse(
          formatEndDataToTime,
          "h:mm a",
          parse(String(selectDate), "y-MM-dd", new Date())
        );
      }
    }
    if (
      startValue &&
      endValue &&
      (currentTab === 0 || currentTab === 2 || (currentTab === 1 && selectDate))
    ) {
      setFormatStartData(formatDateTime(formattingStartData));
      setFormatEndData(formatDateTime(formattingEndData));
      const hoursWorked = Math.ceil(
        Number(formattingEndData) / 1000 - Number(formattingStartData) / 1000
      );
      setHoursWorked(hoursWorked > 0 ? hoursWorked : 0);
    }
  };

  useEffect(() => {
    countTimeWorked();
  }, [startValue, endValue, selectDate]);

  useEffect(() => {
    if (clocks.error) {
      setErrorMessage(true);
    }
  }, [clocks]);

  const showLocation = () => {
    const geolocation = sessionStorage.getItem("geolocation");
    if (geolocation) {
      const geo = JSON.parse(geolocation);
      setUserPosition({
        lat: geo?.lat,
        lng: geo?.lng,
      });
    } else {
      setUserPosition({
        lat: null,
        lng: null,
      });
    }
  };

  useEffect(() => {
    showLocation();
  }, [sessionStorage.getItem("geolocation")]);

  const submitManualEntry = () => {
    if (!startValue) {
      setError((prevState) => ({
        ...prevState,
        startTime: "Start Time is required",
      }));
    }
    if (!endValue) {
      setError((prevState) => ({
        ...prevState,
        endTime: "End Time is required",
      }));
    }
    if (currentTab === 1 && !selectDate) {
      setError((prevState) => ({
        ...prevState,
        selectDate: "Select Date is required",
      }));
    }
    if (currentTab === 2 && !selectUser) {
      setError((prevState) => ({
        ...prevState,
        selectUser: "Select User is required",
      }));
    }
    if (
      startValue &&
      endValue &&
      (currentTab === 0 ||
        (currentTab === 1 && selectDate) ||
        (currentTab === 2 && selectUser))
    ) {
      if (currentTab === 2) {
        dispatch(
          manualEntryByUserRequest(
            {
              id: selectUser ? selectUser : user.id,
              startTime: formatStartData.replace(" ", "T"),
              endTime: formatEndData.replace(" ", "T"),
              startTimeTimezone: clientTimezone,
              endTimeTimezone: clientTimezone,
              startTimeLatitude: userPosition?.lat || null,
              startTimeLongitude: userPosition?.lng || null,
              endTimeLatitude: userPosition?.lat,
              endTimeLongitude: userPosition?.lng,
            },
            {
              onSuccess: () => {
                onFetchSuccess();
                closeModal();
              },
            }
          )
        );
      } else {
        dispatch(
          manualEntryRequest(
            {
              clockRecord: {
                startTime: formatStartData.replace(" ", "T"),
                endTime: formatEndData.replace(" ", "T"),
                startTimeTimezone: clientTimezone,
                endTimeTimezone: clientTimezone,
                startTimeLatitude: userPosition?.lat || null,
                startTimeLongitude: userPosition?.lng || null,
                endTimeLatitude: userPosition?.lat,
                endTimeLongitude: userPosition?.lng,
              },
            },
            {
              onSuccess: () => {
                onFetchSuccess();
                closeModal();
              },
            }
          )
        );
      }
    }
  };

  useEffect(() => {
    return () => {
      dispatch(cleanError());
    };
  }, []);

  return (
    <>
      {!hideDefaultWrapper && (
        <Box sx={styles.modalTitile}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            fontSize={16}
            fontWeight={700}
          >
            {currentTab === 0 ? "Manual Entry for Today" : "Manual Entry"}
          </Typography>
          <IconButton onClick={closeModal} sx={styles.modalClose}>
            <CloseIcon fontSize="small" color="disabled" />
          </IconButton>
        </Box>
      )}
      <Box sx={!hideDefaultWrapper ? styles.modalContent : null}>
        {currentTab === 1 && (
          <>
            <Box>
              <Typography fontSize={14} mb={"8px"}>
                Select Date
              </Typography>
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  error={error.selectDate ? true : false}
                  value={selectDate}
                  placeholder="Select Date"
                  onChange={handleChangeDate}
                  onFocus={() =>
                    setError((prevState) => ({
                      ...prevState,
                      selectDate: "",
                    }))
                  }
                  height={"40px"}
                  IconComponent={KeyboardArrowDownIcon}
                >
                  {clocks.todaysClocks?.weeklyRecords?.map((day) => {
                    if (formatDate(day.day) <= new Date()) {
                      return (
                        <MenuItem key={day.day} value={day.day}>
                          {format(formatDate(day.day), "EEEE, MMMM do")}
                        </MenuItem>
                      );
                    }
                  })}
                </Select>
              </FormControl>
            </Box>
            <Box m="5px 0 10px 14px">
              {error.selectDate && (
                <Typography color={"#ff0000"} fontSize={12}>
                  {error.selectDate}
                </Typography>
              )}
            </Box>
          </>
        )}
        {currentTab === 0 && (
          <Box sx={styles.modalBox}>
            <Typography fontSize={14}>Entry Date</Typography>
            <Typography fontSize={14} fontWeight={600}>
              {format(modalData, "MMMM d, y")}
            </Typography>
          </Box>
        )}
        {currentTab === 2 && (
          <>
            <Box mb="20px">
              <Typography fontSize={14} mb={"8px"}>
                Date
              </Typography>
              <Box sx={styles.dateStyle}>
                <CalendarTodayIcon sx={styles.calendarDate} color="action" />
                <DatePicker
                  selected={selectDate}
                  androidVariant="iosClone"
                  dateFormat="MMMM d, y"
                  onFocus={(e) => e.target.blur()}
                  disabledKeyboardNavigation
                  onChange={(date) => setSelectDate(date)}
                  popperClassName="calendarPicker modalPicker"
                  formatWeekDay={(nameOfDay) =>
                    nameOfDay.toString().substr(0, 3)
                  }
                  renderCustomHeader={({
                    monthDate,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                  }) => (
                    <div
                      style={{
                        margin: 10,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <button
                        className="btn prev"
                        onClick={decreaseMonth}
                        disabled={prevMonthButtonDisabled}
                      >
                        {<KeyboardArrowLeft />}
                      </button>
                      <span className="react-datepicker__current-month">
                        {monthDate.toLocaleString("en-US", {
                          month: "long",
                          year: "numeric",
                        })}
                      </span>
                      <button
                        className="btn next"
                        onClick={increaseMonth}
                        disabled={nextMonthButtonDisabled}
                      >
                        {<KeyboardArrowRight />}
                      </button>
                    </div>
                  )}
                />
              </Box>
            </Box>
            <Box>
              <Typography fontSize={14} mb={"8px"}>
                Name
              </Typography>
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  error={error.selectUser ? true : false}
                  value={selectUser}
                  onFocus={() =>
                    setError((prevState) => ({
                      ...prevState,
                      selectUser: "",
                    }))
                  }
                  onChange={handleChangeUser}
                  height={"40px"}
                  IconComponent={KeyboardArrowDownIcon}
                >
                  {allUsers.users.map((user) => (
                    <MenuItem key={user.id} value={user.id}>
                      {`${user.firstName} ${user.lastName}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box m="5px 0 10px 14px">
              {error.selectUser && (
                <Typography color={"#ff0000"} fontSize={12}>
                  {error.selectUser}
                </Typography>
              )}
            </Box>
          </>
        )}
        {user && (
          <Box sx={styles.modalBox}>
            <Typography fontSize={14}>Name</Typography>
            <Typography fontSize={14} fontWeight={600}>
              {user.userName
                ? user.userName
                : `${user.firstName} ${user.lastName}`}
            </Typography>
          </Box>
        )}
        <Box>
          <Typography fontSize={14} mb={"8px"}>
            Start Time
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopTimePicker
              value={startValue}
              onChange={(newValue) => {
                setStartValue(newValue);
              }}
              onOpen={() =>
                setError((prevState) => ({
                  ...prevState,
                  startTime: "",
                }))
              }
              PopperProps={{
                placement: "bottom-start",
                sx: {
                  width: "302px",
                  ".MuiPickersArrowSwitcher-spacer": { width: "2px" },
                },
              }}
              renderInput={(params) => (
                <TextField
                  size="small"
                  className="xxx"
                  sx={{ width: "100%" }}
                  {...params}
                  onFocus={() =>
                    setError((prevState) => ({
                      ...prevState,
                      startTime: "",
                    }))
                  }
                  error={
                    (error.startTime ? true : false) ||
                    (startValue && !isValid(startValue) ? true : false)
                  }
                  inputProps={{
                    ...params.inputProps,
                    type: "text",
                  }}
                />
              )}
            />
          </LocalizationProvider>
          <Box m="5px 0 10px 14px">
            {error.startTime && (
              <Typography color={"#ff0000"} fontSize={12}>
                {error.startTime}
              </Typography>
            )}
          </Box>
        </Box>
        <Box>
          <Typography fontSize={14} mb={"8px"}>
            End Time
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopTimePicker
              value={endValue}
              onChange={(newValue) => {
                setEndValue(newValue);
              }}
              onOpen={() =>
                setError((prevState) => ({
                  ...prevState,
                  endTime: "",
                }))
              }
              PopperProps={{
                placement: "bottom-start",
                sx: {
                  width: "302px",
                  ".MuiPickersArrowSwitcher-spacer": { width: "2px" },
                },
              }}
              renderInput={(params) => (
                <TextField
                  size="small"
                  sx={{ width: "100%" }}
                  {...params}
                  onFocus={() =>
                    setError((prevState) => ({
                      ...prevState,
                      endTime: "",
                    }))
                  }
                  error={
                    (error.endTime ? true : false) ||
                    (endValue && !isValid(endValue) ? true : false)
                  }
                  inputProps={{
                    ...params.inputProps,
                    type: "text",
                  }}
                />
              )}
            />
          </LocalizationProvider>
          <Box m="5px 0 10px 14px">
            {error.endTime && (
              <Typography color={"#ff0000"} fontSize={12}>
                {error.endTime}
              </Typography>
            )}
          </Box>
        </Box>
        <Box sx={styles.modalBox}>
          <Typography
            fontSize={14}
            fontWeight={600}
            color={theme.palette.text.secondary}
          >
            Time Worked
          </Typography>
          <Typography fontSize={14} fontWeight={600} color="primary">
            {secondsToTime(hoursWorked)}
          </Typography>
        </Box>
        {errorMessage && (
          <Box>
            <Typography sx={styles.entryError}>{clocks.error}</Typography>
          </Box>
        )}
        <Box>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            size="medium"
            onClick={submitManualEntry}
          >
            Save
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default ManualEntryModal;
