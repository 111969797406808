import React from "react";
import { Typography } from "@mui/material";

const ContactSupportTooltipText: React.FC = () => {
  return (
    <Typography fontSize="14px">
      Please contact support at
      <Typography color="#0D6FF0" fontSize="14px">
        <a href="mailto:support@busybucket.io">support@busybucket.io</a>
      </Typography>
      to update a phone number or email.
    </Typography>
  );
};

export default ContactSupportTooltipText;
