import {
  ADD_COMMENT_PROJECT_FAILURE,
  ADD_PROJECT_FAILURE,
  ADD_PROJECT_SUCCESS,
  ADD_PROJECT_REQUEST,
  DELETE_COMMENT_PROJECT_FAILURE,
  DELETE_PROJECT_FAILURE,
  FETCH_COMMENT_PROJECT_FAILURE,
  FETCH_COMMENT_PROJECT_REQUEST,
  FETCH_COMMENT_PROJECT_SUCCESS,
  FETCH_PROJECT_ALL_FAILURE,
  FETCH_PROJECT_ALL_SUCCESS,
  FETCH_PROJECT_FAILURE,
  FETCH_PROJECT_ID_FAILURE,
  FETCH_PROJECT_ID_REQUEST,
  FETCH_PROJECT_ID_SUCCESS,
  FETCH_PROJECT_REQUEST,
  FETCH_PROJECT_SUCCESS,
  FETCH_SUBPROJECT_FAILURE,
  FETCH_SUBPROJECT_REQUEST,
  FETCH_SUBPROJECT_SUCCESS,
  SEARCH_PROJECT_FAILURE,
  SEARCH_PROJECT_REQUEST,
  SEARCH_PROJECT_SUCCESS,
  UPDATE_COMMENT_PROJECT_FAILURE,
  UPDATE_PROJECT_FAILURE,
} from "./actionTypes";
import { ProjectAction, ProjectState } from "./types";

const initialState: ProjectState = {
  projects: [],
  projectAll: [],
  project: null,
  commentProjects: [],
  totalPages: 0,
  isLoading: false,
  isLoadingSubproject: false,
  isLoadingCommentProject: false,
  isLoadingAddProject: false,
  error: null,
};

export default (state = initialState, action: ProjectAction) => {
  switch (action.type) {
    case FETCH_PROJECT_REQUEST:
    case SEARCH_PROJECT_REQUEST:
    case FETCH_PROJECT_ID_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case FETCH_SUBPROJECT_REQUEST: {
      return {
        ...state,
        isLoadingSubproject: true,
      };
    }
    case FETCH_COMMENT_PROJECT_REQUEST: {
      return {
        ...state,
        isLoadingCommentProject: true,
      };
    }
    case ADD_PROJECT_REQUEST: {
      return {
        ...state,
        isLoadingAddProject: true,
      };
    }
    case FETCH_PROJECT_SUCCESS:
    case SEARCH_PROJECT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        ...action.payload.projects,
      };
    }
    case FETCH_PROJECT_ALL_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        projectAll: action.payload.projectAll,
      };
    }
    case FETCH_PROJECT_FAILURE:
    case FETCH_PROJECT_ALL_FAILURE:
    case SEARCH_PROJECT_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    case FETCH_SUBPROJECT_SUCCESS: {
      return {
        ...state,
        isLoadingSubproject: false,
        error: null,
        ...action.payload.projects,
      };
    }
    case FETCH_SUBPROJECT_FAILURE: {
      return {
        ...state,
        isLoadingSubproject: false,
        error: action.payload.error,
      };
    }
    case FETCH_PROJECT_ID_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        project: action.payload.project,
      };
    }
    case FETCH_PROJECT_ID_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    case FETCH_COMMENT_PROJECT_SUCCESS: {
      return {
        ...state,
        isLoadingCommentProject: false,
        error: null,
        commentProjects: action.payload.commentProjects,
      };
    }
    case FETCH_COMMENT_PROJECT_FAILURE: {
      return {
        ...state,
        isLoadingCommentProject: false,
        error: action.payload.error,
      };
    }
    case ADD_PROJECT_SUCCESS: {
      return {
        ...state,
        isLoadingAddProject: false,
        project: action.payload.project,
      };
    }
    case ADD_PROJECT_FAILURE: {
      return {
        ...state,
        isLoadingAddProject: false,
        error: action.payload.error,
      };
    }
    // case UPDATE_PROJECT_SUCCESS: {
    //   return {
    //     ...state,
    //     ...action.payload,
    //   };
    // }
    case UPDATE_PROJECT_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
      };
    }
    // case DELETE_PROJECT_SUCCESS: {
    //   return {
    //     ...state,
    //     ...action.payload,
    //   };
    // }
    case DELETE_PROJECT_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
      };
    }
    // case ADD_COMMENT_PROJECT_SUCCESS: {
    //   return {
    //     ...state,
    //     ...action.payload,
    //   };
    // }
    case ADD_COMMENT_PROJECT_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
      };
    }
    // case UPDATE_COMMENT_PROJECT_SUCCESS: {
    //   return {
    //     ...state,
    //     ...action.payload,
    //   };
    // }
    case UPDATE_COMMENT_PROJECT_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
      };
    }
    // case DELETE_COMMENT_PROJECT_SUCCESS: {
    //   return {
    //     ...state,
    //     ...action.payload,
    //   };
    // }
    case DELETE_COMMENT_PROJECT_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
      };
    }
    default:
      return state;
  }
};
