import * as React from "react";

interface Props {
  color?: string;
}

const File: React.FC<Props> = ({ color }, props) => (
  <svg data-sqa-id="file-icon" width={20} height={20} fill="none" {...props}>
    <path
      d="M10.833 1.667H5a1.667 1.667 0 0 0-1.667 1.666v13.334A1.667 1.667 0 0 0 5 18.333h10a1.667 1.667 0 0 0 1.667-1.666V7.5l-5.834-5.833Z"
      stroke={color ? color : "#fff"}
      strokeWidth={1.3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.833 1.667V7.5h5.834"
      stroke={color ? color : "#fff"}
      strokeWidth={1.3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default File;
