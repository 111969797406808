import { isMobile } from "mobile-device-detect";
export const styles = {
  userPhoto: {
    width: isMobile ? "35px" : "44px",
    height: isMobile ? "35px" : "44px",
    fontSize: "14px",
    borderRadius: "50%",
    marginRight: "10px",
  },
  actionButtons: {
    display: "flex",
    justifyContent: isMobile ? "space-between" : "flex-end",
    columnGap: "10px",
    marginTop: "20px",
  },
  boxContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    mb: "16px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: isMobile ? "100%" : "35%",
    columnGap: isMobile && "10px",
  },
  threeDots: {
    backgroundColor: "#FFF",
    border: "1px solid #F0F0F0",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "40px",
    maxWidth: "40px",
    minHeight: "40px",
    maxHeight: "40px",
  },
  projectCommentButton: {
    minWidth: "44px",
    background: "transparent",
    "&:hover": {
      background: "transparent",
    },
  },
};
