import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { AppRoutes } from "src/constants";
import { getUserSelector } from "src/store/auth/selectors";

interface Props {
  children: React.ReactElement;
}

const PrivateRoute: React.FC<Props> = ({ children }) => {
  const user = useSelector(getUserSelector);

  if (!user) {
    return <Navigate to={AppRoutes.SignIn} />;
  }

  return children;
};

export default PrivateRoute;
