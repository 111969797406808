import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  contactUs as contactUsApi,
  getInvoice as getInvoiceApi,
  getInvoiceID as getInvoiceIDApi,
  getSubscription as getSubscriptionApi,
  startSubscription as startSubscriptionApi,
  updateSubscription as updateSubscriptionApi,
  deleteSubscription as deleteSubscriptionApi,
  updatePayment as updatePaymentApi,
} from "./api";
import {
  contactUsFailure,
  contactUsSuccess,
  deleteSubscriptionFailure,
  deleteSubscriptionSuccess,
  getInvoiceFailure,
  getInvoiceIDFailure,
  getInvoiceIDSuccess,
  getInvoiceSuccess,
  getSubscriptionFailure,
  getSubscriptionSuccess,
  startSubscriptionFailure,
  startSubscriptionSuccess,
  updatePaymentFailure,
  updatePaymentSuccess,
  updateSubscriptionFailure,
  updateSubscriptionSuccess,
} from "./actions";
import {
  CONTACT_US_REQUEST,
  DELETE_SUBSCRIPTION_REQUEST,
  GET_INVOICE_ID_REQUEST,
  GET_INVOICE_REQUEST,
  GET_SUBSCRIPTION_REQUEST,
  START_SUBSCRIPTION_REQUEST,
  UPDATE_PAYMENT_REQUEST,
  UPDATE_SUBSCRIPTION_REQUEST,
} from "./actionTypes";
import { AxiosResponse } from "axios";
import {
  ContactUsRequest,
  DeleteSubscriptionRequest,
  GetInvoiceIDRequest,
  StartSubscriptionRequest,
  UpdatePaymentRequest,
  UpdateSubscriptionRequest,
} from "./types";
import { Invoice, Subscription, Subscriptions } from "src/types";

function* contactUsSaga({ payload, callbacks }: ContactUsRequest) {
  try {
    const response: AxiosResponse = yield call(contactUsApi, payload);
    yield put(
      contactUsSuccess({
        data: response.data,
      })
    );
    if (callbacks?.onSuccess) {
      yield call(callbacks.onSuccess);
    }
  } catch (e) {
    yield put(
      contactUsFailure({
        // @ts-ignore
        error: e?.response?.data?.message,
      })
    );
  }
}

function* getInvoiceSaga() {
  try {
    const response: AxiosResponse<Invoice[]> = yield call(getInvoiceApi);
    yield put(
      getInvoiceSuccess({
        invoices: response.data,
      })
    );
  } catch (e) {
    yield put(
      getInvoiceFailure({
        // @ts-ignore
        error: e?.response?.data?.message,
      })
    );
  }
}

function* getInvoiceIDSaga({ payload }: GetInvoiceIDRequest) {
  try {
    const response: AxiosResponse<Invoice> = yield call(
      getInvoiceIDApi,
      payload
    );
    yield put(
      getInvoiceIDSuccess({
        invoice: response.data,
      })
    );
  } catch (e) {
    yield put(
      getInvoiceIDFailure({
        // @ts-ignore
        error: e?.message,
      })
    );
  }
}

function* getSubscriptionSaga() {
  try {
    const response: AxiosResponse<Subscriptions> = yield call(
      getSubscriptionApi
    );
    yield put(
      getSubscriptionSuccess({
        subscriptions: response.data,
      })
    );
  } catch (e) {
    yield put(
      getSubscriptionFailure({
        // @ts-ignore
        error: e?.response?.data?.message,
      })
    );
  }
}

function* startSubscriptionSaga({
  payload,
  callbacks,
}: StartSubscriptionRequest) {
  try {
    const response: AxiosResponse<Subscription> = yield call(
      startSubscriptionApi,
      payload
    );
    yield put(
      startSubscriptionSuccess({
        subscription: response.data,
      })
    );
    if (callbacks?.onSuccess) {
      yield call(callbacks.onSuccess);
    }
  } catch (e) {
    yield put(
      startSubscriptionFailure({
        // @ts-ignore
        error: e?.response?.data?.message,
      })
    );
  }
}

function* updateSubscriptionSaga({
  payload,
  callbacks,
}: UpdateSubscriptionRequest) {
  try {
    const response: AxiosResponse<Subscription> = yield call(
      updateSubscriptionApi,
      payload
    );
    yield put(
      updateSubscriptionSuccess({
        subscription: response.data,
      })
    );
    if (callbacks?.onSuccess) {
      yield call(callbacks.onSuccess);
    }
  } catch (e) {
    yield put(
      updateSubscriptionFailure({
        // @ts-ignore
        error: e?.response?.data?.message,
      })
    );
  }
}

function* deleteSubscriptionSaga({ callbacks }: DeleteSubscriptionRequest) {
  try {
    const response: AxiosResponse<Subscription> = yield call(
      deleteSubscriptionApi
    );
    yield put(
      deleteSubscriptionSuccess({
        data: response.data,
      })
    );
    if (callbacks?.onSuccess) {
      yield call(callbacks.onSuccess);
    }
  } catch (e) {
    yield put(
      deleteSubscriptionFailure({
        // @ts-ignore
        error: e?.response?.data?.message,
      })
    );
  }
}

function* updatePaymentSaga({ payload, callbacks }: UpdatePaymentRequest) {
  try {
    const response: AxiosResponse<Subscription> = yield call(
      updatePaymentApi,
      payload
    );
    yield put(
      updatePaymentSuccess({
        subscription: response.data,
      })
    );
    if (callbacks?.onSuccess) {
      yield call(callbacks.onSuccess);
    }
  } catch (e) {
    yield put(
      updatePaymentFailure({
        // @ts-ignore
        error: e?.response?.data?.message,
      })
    );
  }
}

function* subscriptionSaga() {
  yield all([
    takeLatest(CONTACT_US_REQUEST, contactUsSaga),
    takeLatest(GET_INVOICE_REQUEST, getInvoiceSaga),
    takeLatest(GET_INVOICE_ID_REQUEST, getInvoiceIDSaga),
    takeLatest(GET_SUBSCRIPTION_REQUEST, getSubscriptionSaga),
    takeLatest(START_SUBSCRIPTION_REQUEST, startSubscriptionSaga),
    takeLatest(UPDATE_SUBSCRIPTION_REQUEST, updateSubscriptionSaga),
    takeLatest(DELETE_SUBSCRIPTION_REQUEST, deleteSubscriptionSaga),
    takeLatest(UPDATE_PAYMENT_REQUEST, updatePaymentSaga),
  ]);
}

export default subscriptionSaga;
