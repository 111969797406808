import React from "react";
import { IconComponentProps } from "src/types";

const HelpCircle: React.FC<IconComponentProps> = ({ color = "#8C8C8C" }) => {
  return (
    <svg
      data-sqa-id="help-circle-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      color={color}
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-help-circle"
    >
      <circle cx="12" cy="12" r="10"></circle>
      <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
      <line x1="12" y1="17" x2="12.01" y2="17"></line>
    </svg>
  );
};

export default HelpCircle;
