import React, { useState } from "react";
import {
  OutlinedTextFieldProps,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Eye as EyeIcon, EyeOff as EyeOffIcon } from "../assets/icons";
import { Input } from "src/components/";

const SafeInput: React.FC<Omit<OutlinedTextFieldProps, "variant">> = (
  props
) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <Input
      {...props}
      type={showPassword ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}
              edge="end"
            >
              {showPassword ? (
                <EyeOffIcon color="#8C8C8C" />
              ) : (
                <EyeIcon color="#8C8C8C" />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SafeInput;
