import React, { useMemo } from "react";
import { Box, Theme, Typography } from "@mui/material";

interface Props {
  isValid: boolean;
}

const styles = {
  elementsWrapper: {
    display: "flex",
    alignItems: "center",
  },
  elementWrapper: {
    width: "14px",
    height: "8px",
    backgroundColor: "#F5F5F5",
    mx: "1px",
  },
  poorElement: {
    backgroundColor: (theme: Theme) => theme.palette.error[500],
  },
  strongElement: {
    backgroundColor: (theme: Theme) => theme.palette.success.main,
  },
  validationText: {
    ml: "10px",
  },
};

const PasswordStrength: React.FC<Props> = ({ isValid }) => {
  const message = useMemo(() => {
    if (isValid) return "Strong";
    return "Poor";
  }, [isValid]);
  return (
    <Box sx={styles.elementsWrapper} mt="10px">
      <Box sx={styles.elementsWrapper}>
        <Box
          sx={{
            ...styles.elementWrapper,
            ...(isValid ? styles.strongElement : styles.poorElement),
          }}
        ></Box>
        <Box
          sx={{
            ...styles.elementWrapper,
            ...(isValid ? styles.strongElement : {}),
          }}
        ></Box>
        <Box
          sx={{
            ...styles.elementWrapper,
            ...(isValid ? styles.strongElement : {}),
          }}
        ></Box>
      </Box>
      <Typography
        color="text.secondary"
        fontSize="14px"
        sx={styles.validationText}
      >
        {message}
      </Typography>
    </Box>
  );
};

export default PasswordStrength;
