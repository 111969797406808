import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { isMobile } from "mobile-device-detect";
import DayPageMobile from "./DayPageMobile";
import DayPageWeb from "./DayPageWeb";
import { fetchDailyAttachmentsRequest } from "src/store/attachments/actions";
import { fetchDailyClockRequest } from "src/store/clocks/actions";
import { fetchDailyCommentsRequest } from "src/store/comments/actions";
import DeviceWrapper from "../../DeviceWrapper";

const HistoryDayPage = () => {
  const activeTab: number = sessionStorage.getItem("activeTab")
    ? parseInt(sessionStorage.getItem("activeTab"))
    : 0;
  const [currentTab, setCurrentTab] = useState<number>(activeTab);
  const dispatch = useDispatch();
  const { id } = useParams();

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
    sessionStorage.setItem("activeTab", newValue.toString());
  };

  const handleFetch = () => {
    if (isMobile) {
      if (currentTab === 0) {
        dispatch(
          fetchDailyClockRequest({
            date: id,
          })
        );
      } else if (currentTab === 1) {
        dispatch(
          fetchDailyAttachmentsRequest({
            date: id,
          })
        );
      } else {
        dispatch(
          fetchDailyCommentsRequest({
            date: id,
          })
        );
      }
    } else {
      fetchDailyClock();
      fetchAttachments();
      fetchDailyComments();
    }
  };

  const fetchDailyClock = () => {
    dispatch(
      fetchDailyClockRequest({
        date: id,
      })
    );
  };

  const fetchAttachments = () => {
    dispatch(
      fetchDailyAttachmentsRequest({
        date: id,
      })
    );
  };

  const fetchDailyComments = () => {
    dispatch(
      fetchDailyCommentsRequest({
        date: id,
      })
    );
  };

  useEffect(() => {
    handleFetch();
  }, [id]);

  useEffect(() => {
    if (isMobile) handleFetch();
  }, [currentTab]);

  useEffect(() => {
    setCurrentTab(activeTab);
  }, [activeTab]);

  useEffect(() => {
    setCurrentTab(activeTab);
    return () => {
      sessionStorage.setItem("activeTab", "0");
    };
  }, []);

  return (
    <>
      {isMobile ? (
        <DayPageMobile
          handleTabChange={handleTabChange}
          currentTab={currentTab}
          handleFetch={handleFetch}
        />
      ) : (
        <DeviceWrapper>
          <DayPageWeb
            handleTabChange={handleTabChange}
            fetchDailyClock={fetchDailyClock}
            fetchAttachments={fetchAttachments}
            fetchDailyComments={fetchDailyComments}
            currentTab={currentTab}
          />
        </DeviceWrapper>
      )}
    </>
  );
};

export default HistoryDayPage;
