export const styles = {
  commentList: {
    display: "flex",
    flexDirection: "column",
  },
  commentWrapper: {
    mb: "30px",
  },
  commentsDay: {
    fontSize: "20px",
    fontWeight: 700,
    color: "#000",
    mb: "16px",
  },
  commentContainer: {
    padding: "16px",
    backgroundColor: "#FFF",
    border: "1px solid #F0F0F0",
    borderRadius: "4px",
    position: "relative",
  },
  comment: {
    display: "-webkit-box",
    WebkitLineClamp: 2,
    fontWeight: 400,
    fontSize: "14px",
    color: "#000",
    textOverflow: "ellipsis",
    wordBreak: "break-all",
    overflow: "hidden",
    paddingRight: "40px",
  },
  commentData: {
    mt: "10px",
    fontWeight: 400,
    fontSize: "14px",
    color: "#888",
  },
  showAll: {
    position: "absolute",
    right: "16px",
    top: "15px",
    cursor: "pointer",
  },
  addButtonContainer: {
    display: "flex",
    alignItems: "center",
  },
  addButtonText: {
    fontWeight: 500,
    fontSize: { xs: "14px", md: "16px" },
    ml: "10px",
    textTransform: "initial",
  },
  loadMore: {
    fontWeight: 500,
    color: "#0D6FF0",
    textTransform: "capitalize",
  },
  noData: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "104px",
    backgroundColor: "#F0F0F0",
    borderRadius: "4px",
  },
  noDataText: {
    fontWeight: 600,
    fontSize: "14px",
    color: "#8C8C8C",
  },
};
