import React, { SVGProps } from "react";

const FailedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" {...props}>
    <circle cx={10} cy={10} r={9.6} fill="#FF2828" />
    <path
      d="m7 7 3 3m3 3-3-3m0 0-3 3 6-6"
      stroke="#fff"
      strokeWidth={1.3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default FailedIcon;
