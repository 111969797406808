import {
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
  CREATE_USER_FAILURE,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  SEARCH_USER_FAILURE,
  SEARCH_USER_REQUEST,
  SEARCH_USER_SUCCESS,
  GET_USER_FAILURE,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  CLEAN_ERROR,
} from "./actionTypes";
import {
  AddUserCallbacks,
  AddUserFailurePayload,
  AddUserRequestPayload,
  AddUserSuccessPayload,
  DeleteUserCallbacks,
  DeleteUserFailurePayload,
  DeleteUserRequestPayload,
  DeleteUserSuccessPayload,
  FetchUsersFailurePayload,
  FetchUsersRequestPayload,
  FetchUsersSuccessPayload,
  SearchUserFailurePayload,
  SearchUserRequestPayload,
  SearchUserSuccessPayload,
  UpdateUserRequestPayload,
  UpdateUserRequest,
  UpdateUserSuccessPayload,
  UpdateUserFailurePayload,
  UpdateUserFailure,
  UpdateUserSuccess,
  UpdateUserCallbacks,
  GetUserFailurePayload,
  GetUserRequestPayload,
  GetUserSuccessPayload,
  CleanError,
  FetchUsersCallbacks,
  SearchUserCallbacks,
} from "./types";

export const fetchUsersRequest = (
  payload: FetchUsersRequestPayload,
  callbacks?: FetchUsersCallbacks
) => ({
  type: FETCH_USERS_REQUEST,
  payload,
  callbacks,
});

export const fetchUsersSuccess = (payload: FetchUsersSuccessPayload) => ({
  type: FETCH_USERS_SUCCESS,
  payload,
});

export const fetchUsersFailure = (payload: FetchUsersFailurePayload) => ({
  type: FETCH_USERS_FAILURE,
  payload,
});

export const searchUserRequest = (
  payload: SearchUserRequestPayload,
  callbacks?: SearchUserCallbacks
) => ({
  type: SEARCH_USER_REQUEST,
  payload,
  callbacks,
});

export const searchUserSuccess = (payload: SearchUserSuccessPayload) => ({
  type: SEARCH_USER_SUCCESS,
  payload,
});

export const searchUserFailure = (payload: SearchUserFailurePayload) => ({
  type: SEARCH_USER_FAILURE,
  payload,
});

export const getUserRequest = (payload: GetUserRequestPayload) => ({
  type: GET_USER_REQUEST,
  payload,
});

export const getUserSuccess = (payload: GetUserSuccessPayload) => ({
  type: GET_USER_SUCCESS,
  payload,
});

export const getUserFailure = (payload: GetUserFailurePayload) => ({
  type: GET_USER_FAILURE,
  payload,
});

export const addUserRequest = (
  payload: AddUserRequestPayload,
  callbacks?: AddUserCallbacks
) => ({
  type: CREATE_USER_REQUEST,
  payload,
  callbacks,
});

export const addUserSuccess = (payload: AddUserSuccessPayload) => ({
  type: CREATE_USER_SUCCESS,
  payload,
});

export const addUserFailure = (payload: AddUserFailurePayload) => ({
  type: CREATE_USER_FAILURE,
  payload,
});

export const updateUserRequest = (
  payload: UpdateUserRequestPayload,
  callbacks?: UpdateUserCallbacks
): UpdateUserRequest => ({
  type: UPDATE_USER_REQUEST,
  payload,
  callbacks,
});

export const updateUserSuccess = (
  payload: UpdateUserSuccessPayload
): UpdateUserSuccess => ({
  type: UPDATE_USER_SUCCESS,
  payload,
});

export const updateUserFailure = (
  payload: UpdateUserFailurePayload
): UpdateUserFailure => ({
  type: UPDATE_USER_FAILURE,
  payload,
});

export const deleteUserRequest = (
  payload: DeleteUserRequestPayload,
  callbacks?: DeleteUserCallbacks
) => ({
  type: DELETE_USER_REQUEST,
  payload,
  callbacks,
});

export const deleteUserSuccess = (payload: DeleteUserSuccessPayload) => ({
  type: DELETE_USER_SUCCESS,
  payload,
});

export const deleteUserFailure = (payload: DeleteUserFailurePayload) => ({
  type: DELETE_USER_FAILURE,
  payload,
});

export const cleanError = (): CleanError => ({
  type: CLEAN_ERROR,
});
