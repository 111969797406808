import {
  Box,
  CircularProgress,
  Typography,
  Button as MuiButton,
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import PullToRefresh from "react-simple-pull-to-refresh";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { Button } from "src/components";
import { styles } from "./report.module";
import { getReportSelector } from "src/store/report/selectors";
import { Project, ReportItem } from "src/types";
import SortByModal from "src/components/ActionsModal/SortByModal";
import sortByReports from "./sortByReports.json";
import { Comments, DeleteForWeb, ImageIcon, ThreeDots } from "src/assets/icons";
import { secondsToTime, parseTime } from "src/utils";
import { AppRoutes } from "src/constants";
import ActionReportsModal from "src/components/ActionsModal/ActionReportsModal";
import ActionReportModal from "src/components/ActionsModal/ActionReportsModal/ActionReportModal";

interface Props {
  isLoading: boolean;
  sortOrder: any;
  pageSize: number;
  sortBy: string;
  projectIds: string[];
  userIds: string[];
  setProjectIds: any;
  setUserIds: any;
  selectedDate: (number | Date)[];
  setSelectedDate: any;
  setIsLoading: any;
  setSortOrder: (order: string) => void;
  setPage: (page: any) => void;
  setSortBy: (elem: string) => void;
  fetchReport: () => void;
  handleTimesheet: () => void;
}

const ReportMobile: React.FC<Props> = ({
  isLoading,
  pageSize,
  sortOrder,
  sortBy,
  projectIds,
  userIds,
  selectedDate,
  setProjectIds,
  setUserIds,
  setSelectedDate,
  setSortBy,
  setSortOrder,
  setPage,
  fetchReport,
  handleTimesheet,
  setIsLoading,
}) => {
  const reportState = useSelector(getReportSelector);
  const navigate = useNavigate();
  const [openSortModal, setOpenSortModal] = useState<boolean>(false);
  const [openActionModal, setOpenActionModal] = useState<boolean>(false);
  const [openActionReportModal, setOpenActionReportModal] =
    useState<boolean>(false);
  const [elem, setElem] = useState(null);
  const [modalActionType, setModalActionType] = useState({
    action: "",
    page: "",
  });

  const handleOpen = (seState: any, action: string, page?: string) => {
    seState(true);
    setModalActionType((prevState) => ({
      ...prevState,
      action,
      page,
    }));
  };

  const handleRefresh = async () => {
    fetchReport();
  };

  return (
    <>
      <PullToRefresh onRefresh={handleRefresh}>
        <Box sx={styles.boxContainer}>
          <Box sx={[styles.topContainer, { mb: "20px", columnGap: "10px" }]}>
            <Box>
              <Button
                style={{
                  minWidth: "54px",
                  height: "40px",
                  padding: "8px",
                  borderColor:
                    projectIds.length + userIds.length > 0
                      ? "primary"
                      : "#F0F0F0",
                  color:
                    projectIds.length + userIds.length > 0
                      ? "primary"
                      : "#262626",
                }}
                variant="outlined"
                fullWidth
                onClick={() => navigate(AppRoutes.ReportFilters)}
              >
                <FilterAltOutlinedIcon
                  style={{
                    color:
                      projectIds.length + userIds.length > 0
                        ? "primary"
                        : "#262626",
                  }}
                />
                <Typography
                  ml="5px"
                  fontSize="14px"
                  style={{
                    color:
                      projectIds.length + userIds.length > 0
                        ? "primary"
                        : "#262626",
                  }}
                >
                  {projectIds.length + userIds.length}
                </Typography>
              </Button>
            </Box>
            <Box>
              <Button
                style={{ height: "40px", padding: "12px" }}
                variant="outlined"
                color="error"
                fullWidth
                onClick={() => {
                  setIsLoading(true);
                  setUserIds([]);
                  setProjectIds([]);
                  setSelectedDate([
                    new Date(
                      new Date().setDate(
                        new Date().getDate() - new Date().getDay()
                      )
                    ),
                    new Date(
                      new Date().setDate(
                        new Date().getDate() - new Date().getDay() + 6
                      )
                    ),
                  ]);
                }}
              >
                <DeleteForWeb color="#FF0000" />
                <Typography ml="10px" fontSize="14px" color="#FF0000">
                  Clear Filters
                </Typography>
              </Button>
            </Box>
            <Box
              sx={[styles.boxContainer, { p: "7px" }]}
              onClick={() => handleOpen(setOpenSortModal, "Sort", "By")}
            >
              <SwapVertIcon />
            </Box>
            <Box
              sx={[
                styles.boxContainer,
                { p: "14px 9px", height: "40px", justifyContent: "center" },
              ]}
              onClick={() => handleOpen(setOpenActionModal, "Actions", "")}
            >
              <ThreeDots color="#262626" />
            </Box>
          </Box>
          {isLoading ? (
            <Box
              data-sqa-id="circular-progress-loader"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              {reportState.report?.days.length ? (
                <>
                  {reportState.report?.days?.map((report: ReportItem) => (
                    <Box
                      mb="20px"
                      sx={styles.boxContainer}
                      key={report.id}
                      onClick={() => {
                        navigate(`${AppRoutes.Report}/${report.id}`, {
                          state: {
                            name: {
                              id: report.user.id,
                              userName: `${report.user.firstName} ${report.user.lastName}`,
                            },
                            date: report.day,
                            atachmentsCount: report.attachmentsCount,
                            commentsCount: report.commentsCount,
                          },
                        });
                        sessionStorage.setItem("activeTab", "0");
                      }}
                    >
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Box>
                          <Typography color="#8C8C8C" fontSize="12px">
                            {format(parseTime(report.day), "MMMM d, y")}
                          </Typography>
                          <Typography mt="5px" color="#262626">
                            {`${report.user.firstName} ${report.user.lastName}`}
                          </Typography>
                        </Box>
                        <MuiButton
                          sx={styles.threeDots}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleOpen(setOpenActionReportModal, "Actions", "");
                            setElem({
                              userId: report.user.id,
                              day: format(parseTime(report.day), "y-MM-dd"),
                            });
                          }}
                        >
                          <ThreeDots color="#000" />
                        </MuiButton>
                      </Box>
                      <Box sx={styles.reportItemCards}>
                        <Box
                          sx={
                            report.attachmentsCount
                              ? styles.countContainer
                              : styles.emptyContainer
                          }
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(`${AppRoutes.Report}/${report.id}`, {
                              state: {
                                name: {
                                  id: report.user.id,
                                  userName: `${report.user.firstName} ${report.user.lastName}`,
                                },
                                date: report.day,
                                atachmentsCount: report.attachmentsCount,
                                commentsCount: report.commentsCount,
                              },
                            });
                            sessionStorage.setItem("activeTab", "1");
                          }}
                        >
                          <ImageIcon
                            color={
                              report.attachmentsCount ? "#0D6FF0" : "#8C8C8C"
                            }
                          />
                          <Typography
                            ml="6px"
                            color={
                              report.attachmentsCount ? "#0D6FF0" : "#8C8C8C"
                            }
                            fontSize="12px"
                          >
                            {report.attachmentsCount}
                          </Typography>
                        </Box>
                        <Box
                          sx={
                            report.commentsCount
                              ? styles.countContainer
                              : styles.emptyContainer
                          }
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(`${AppRoutes.Report}/${report.id}`, {
                              state: {
                                name: {
                                  id: report.user.id,
                                  userName: `${report.user.firstName} ${report.user.lastName}`,
                                },
                                date: report.day,
                                atachmentsCount: report.attachmentsCount,
                                commentsCount: report.commentsCount,
                              },
                            });
                            sessionStorage.setItem("activeTab", "2");
                          }}
                        >
                          <Comments
                            size={18}
                            color={report.commentsCount ? "#0D6FF0" : "#8C8C8C"}
                          />
                          <Typography
                            ml="6px"
                            color={report.commentsCount ? "#0D6FF0" : "#8C8C8C"}
                            fontSize="12px"
                          >
                            {report.commentsCount}
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={[styles.topContainer, { mt: "20px" }]}>
                        <Typography color="#8C8C8C" fontSize="14px">
                          Daily Total
                        </Typography>
                        <Typography color="#262626" fontSize="14px">
                          {secondsToTime(report.totalWorked)}
                        </Typography>
                      </Box>
                      {report?.projects?.length && (
                        <Box
                          sx={[
                            styles.topContainer,
                            { alignItems: "flex-start", mt: "14px" },
                          ]}
                        >
                          <Typography color="#8C8C8C" fontSize="14px">
                            Project
                          </Typography>
                          <Box>
                            {report.projects?.map((project: Project) => {
                              return (
                                <Box sx={styles.flexEnd} key={project.id}>
                                  <Typography color="#8C8C8C" fontSize="12px">
                                    Project ID {project.numericId}
                                  </Typography>
                                  <Typography color="#262626" fontSize="14px">
                                    {project.projectName}
                                  </Typography>
                                </Box>
                              );
                            })}
                          </Box>
                        </Box>
                      )}
                    </Box>
                  ))}
                  {reportState.isLoading ? (
                    <Box
                      data-sqa-id="circular-progress-loader"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <CircularProgress />
                    </Box>
                  ) : (
                    pageSize <
                      reportState.report?.searchParameters?.totalPages *
                        pageSize && (
                      <Button
                        data-sqa-id="load-more-btn"
                        variant="contained"
                        fullWidth
                        style={{
                          border: "1px solid #0D6FF0",
                          backgroundColor: "transparent",
                          boxShadow: "none",
                        }}
                        onClick={() => setPage((prevState) => prevState + 1)}
                      >
                        <Box sx={styles.addButtonContainer}>
                          <Typography
                            data-sqa-id="load-more-btn-text"
                            color="#0D6FF0"
                          >
                            Load More
                          </Typography>
                        </Box>
                      </Button>
                    )
                  )}
                </>
              ) : (
                <Box sx={styles.noData}>
                  <Typography
                    data-sqa-id="no-comments-yet-text"
                    sx={styles.noDataText}
                  >
                    No results available for selected filters.
                    <br />
                    Try to tweak filters.
                  </Typography>
                </Box>
              )}
            </>
          )}
        </Box>
      </PullToRefresh>

      {openActionModal && (
        <ActionReportsModal
          isOpen={openActionModal}
          closeModal={() => setOpenActionModal(false)}
          fetchSuccess={fetchReport}
          modalActionType={modalActionType}
          setModalActionType={setModalActionType}
          userIds={userIds}
          projectIds={projectIds}
          selectedDate={selectedDate}
          handleTimesheet={handleTimesheet}
        />
      )}

      {openActionReportModal && (
        <ActionReportModal
          isOpen={openActionReportModal}
          closeModal={() => setOpenActionReportModal(false)}
          fetchSuccess={fetchReport}
          elem={elem}
          modalActionType={modalActionType}
          setModalActionType={setModalActionType}
        />
      )}

      {openSortModal && (
        <SortByModal
          setIsLoading={setIsLoading}
          sortTable={sortByReports}
          setSortBy={setSortBy}
          sortBy={sortBy}
          setSortOrder={setSortOrder}
          sortOrder={sortOrder}
          modalActionType={modalActionType}
          isOpen={openSortModal}
          closeModal={() => setOpenSortModal(false)}
        />
      )}
    </>
  );
};

export default ReportMobile;
