import * as React from "react";
import { SVGProps } from "react";

interface Props {
  color: string;
}

const ViewForWeb: React.FC<Props> = (
  { color },
  props: SVGProps<SVGSVGElement>
) => (
  <svg width={20} height={20} fill="none" {...props}>
    <path
      d="M19.334 9.7C17.218 5.244 14.02 3 9.732 3 5.442 3 2.245 5.243.129 9.703a1.346 1.346 0 0 0 0 1.15c2.116 4.457 5.315 6.7 9.603 6.7 4.29 0 7.486-2.243 9.602-6.703a1.346 1.346 0 0 0 0-1.15Zm-9.602 6.246c-3.6 0-6.237-1.825-8.096-5.67C3.495 6.434 6.13 4.608 9.732 4.608c3.6 0 6.236 1.826 8.096 5.67-1.858 3.844-4.494 5.67-8.096 5.67Zm-.09-9.598a3.929 3.929 0 1 0 0 7.857 3.929 3.929 0 0 0 0-7.857Zm0 6.429a2.499 2.499 0 0 1-2.5-2.5c0-1.382 1.119-2.5 2.5-2.5 1.382 0 2.5 1.118 2.5 2.5s-1.118 2.5-2.5 2.5Z"
      fill={color}
    />
  </svg>
);

export default ViewForWeb;
