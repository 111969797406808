import { Box } from "@mui/material";
import { isMobile } from "mobile-device-detect";
import React from "react";
import DailyEntriesMobile from "./DailyEntriesMobile";
import DailyEntriesTable from "./DailyEntriesTable/index";

interface Props {
  date: string;
  user?: any;
  fetchEntries: () => void;
}

const DailyEntries: React.FC<Props> = ({ date, user, fetchEntries }) => {
  return (
    <>
      <Box>
        {isMobile ? (
          <DailyEntriesMobile
            fetchEntries={fetchEntries}
            date={date}
            user={user}
          />
        ) : (
          <>
            <DailyEntriesTable
              fetchEntries={fetchEntries}
              date={date}
              user={user}
            />
          </>
        )}
      </Box>
    </>
  );
};

export default DailyEntries;
