import {
  DELETE_TODAYS_CLOCK_FAILURE,
  DELETE_TODAYS_CLOCK_REQUEST,
  DELETE_TODAYS_CLOCK_SUCCESS,
  FETCH_TODAYS_CLOCK_FAILURE,
  FETCH_TODAYS_CLOCK_REQUEST,
  FETCH_TODAYS_CLOCK_SUCCESS,
  FETCH_WEEKLY_CLOCK_REQUEST,
  FETCH_WEEKLY_CLOCK_SUCCESS,
  FETCH_WEEKLY_CLOCK_FAILURE,
  LINK_TIME_RECORD_FAILURE,
  LINK_TIME_RECORD_REQUEST,
  LINK_TIME_RECORD_SUCCESS,
  MANUAL_ENTRY_FAILURE,
  MANUAL_ENTRY_REQUEST,
  MANUAL_ENTRY_SUCCESS,
  UPDATE_ENTRY_TIME_RECORD_FAILURE,
  UPDATE_ENTRY_TIME_RECORD_REQUEST,
  UPDATE_ENTRY_TIME_RECORD_SUCCESS,
  FETCH_DAILY_CLOCK_FAILURE,
  FETCH_DAILY_CLOCK_REQUEST,
  FETCH_DAILY_CLOCK_SUCCESS,
  FETCH_WORKED_MONTH_FAILURE,
  FETCH_WORKED_MONTH_REQUEST,
  FETCH_WORKED_MONTH_SUCCESS,
  FETCH_DAILY_CLOCK_BY_USER_FAILURE,
  FETCH_DAILY_CLOCK_BY_USER_REQUEST,
  FETCH_DAILY_CLOCK_BY_USER_SUCCESS,
  MANUAL_ENTRY_BY_USER_FAILURE,
  MANUAL_ENTRY_BY_USER_REQUEST,
  MANUAL_ENTRY_BY_USER_SUCCESS,
  DELETE_CLOCK_BY_USER_AND_DAY_FAILURE,
  DELETE_CLOCK_BY_USER_AND_DAY_REQUEST,
  DELETE_CLOCK_BY_USER_AND_DAY_SUCCESS,
  LINK_TIME_RECORD_BY_USER_AND_DAY_FAILURE,
  LINK_TIME_RECORD_BY_USER_AND_DAY_REQUEST,
  LINK_TIME_RECORD_BY_USER_AND_DAY_SUCCESS,
  CLEAN_ERROR,
} from "./actionTypes";
import {
  DeleteTodaysClockFailure,
  DeleteTodaysClockFailurePayload,
  DeleteTodaysClockRequest,
  DeleteTodaysClockCallbacks,
  DeleteTodaysClockRequestPayload,
  DeleteTodaysClockSuccess,
  DeleteTodaysClockSuccessPayload,
  FetchTodaysClockFailure,
  FetchTodaysClockFailurePayload,
  FetchTodaysClockRequest,
  FetchTodaysClockSuccess,
  FetchTodaysClockSuccessPayload,
  FetchWeeklyClockRequest,
  FetchWeeklyClockSuccess,
  FetchWeeklyClockFailure,
  FetchWeeklyClockSuccessPayload,
  FetchWeeklyClockFailurePayload,
  LinkTimeRecordFailure,
  LinkTimeRecordFailurePayload,
  LinkTimeRecordRequest,
  LinkTimeRecordRequestPayload,
  LinkTimeRecordSuccess,
  LinkTimeRecordSuccessPayload,
  ManualEntryFailure,
  ManualEntryFailurePayload,
  ManualEntryRequest,
  ManualEntryRequestPayload,
  ManualEntrySuccess,
  ManualEntrySuccessPayload,
  UpdateEntryTimeRecordFailure,
  UpdateEntryTimeRecordFailurePayload,
  UpdateEntryTimeRecordRequest,
  UpdateEntryTimeRecordRequestPayload,
  UpdateEntryTimeRecordSuccess,
  UpdateEntryTimeRecordSuccessPayload,
  FetchDailyClockFailure,
  FetchDailyClockFailurePayload,
  FetchDailyClockRequest,
  FetchDailyClockRequestPayload,
  FetchDailyClockSuccess,
  FetchDailyClockSuccessPayload,
  UpdateEntryTimeRecordCallbacks,
  ManualEntryCallbacks,
  FetchWorkedMonthFailure,
  FetchWorkedMonthFailurePayload,
  FetchWorkedMonthRequest,
  FetchWorkedMonthSuccess,
  FetchWorkedMonthSuccessPayload,
  FetchWorkedMonthRequestPayload,
  FetchDailyClockByUserFailure,
  FetchDailyClockByUserFailurePayload,
  FetchDailyClockByUserRequest,
  FetchDailyClockByUserRequestPayload,
  FetchDailyClockByUserSuccess,
  FetchDailyClockByUserSuccessPayload,
  ManualEntryByUserCallbacks,
  ManualEntryByUserFailure,
  ManualEntryByUserFailurePayload,
  ManualEntryByUserRequest,
  ManualEntryByUserRequestPayload,
  ManualEntryByUserSuccess,
  ManualEntryByUserSuccessPayload,
  LinkTimeRecordCallbacks,
  DeleteClockByUserAndDayCallbacks,
  DeleteClockByUserAndDayFailurePayload,
  DeleteClockByUserAndDayRequestPayload,
  DeleteClockByUserAndDaySuccessPayload,
  LinkTimeRecordByUserAndDayCallbacks,
  LinkTimeRecordByUserAndDayFailurePayload,
  LinkTimeRecordByUserAndDayRequestPayload,
  LinkTimeRecordByUserAndDaySuccessPayload,
  CleanError,
  FetchTodaysClockRequestPayload,
  FetchWeeklyClockRequestPayload,
} from "./types";

export const fetchTodaysClockRequest = (
  payload: FetchTodaysClockRequestPayload
): FetchTodaysClockRequest => ({
  type: FETCH_TODAYS_CLOCK_REQUEST,
  payload,
});

export const fetchTodaysClockSuccess = (
  payload: FetchTodaysClockSuccessPayload
): FetchTodaysClockSuccess => ({
  type: FETCH_TODAYS_CLOCK_SUCCESS,
  payload,
});

export const fetchTodaysClockFailure = (
  payload: FetchTodaysClockFailurePayload
): FetchTodaysClockFailure => ({
  type: FETCH_TODAYS_CLOCK_FAILURE,
  payload,
});

export const fetchWorkedMonthRequest = (
  payload: FetchWorkedMonthRequestPayload
): FetchWorkedMonthRequest => ({
  type: FETCH_WORKED_MONTH_REQUEST,
  payload,
});

export const fetchWorkedMonthSuccess = (
  payload: FetchWorkedMonthSuccessPayload
): FetchWorkedMonthSuccess => ({
  type: FETCH_WORKED_MONTH_SUCCESS,
  payload,
});

export const fetchWorkedMonthFailure = (
  payload: FetchWorkedMonthFailurePayload
): FetchWorkedMonthFailure => ({
  type: FETCH_WORKED_MONTH_FAILURE,
  payload,
});

export const fetchWeeklyClockRequest = (
  payload: FetchWeeklyClockRequestPayload
): FetchWeeklyClockRequest => ({
  type: FETCH_WEEKLY_CLOCK_REQUEST,
  payload,
});

export const fetchWeeklyClockSuccess = (
  payload: FetchWeeklyClockSuccessPayload
): FetchWeeklyClockSuccess => ({
  type: FETCH_WEEKLY_CLOCK_SUCCESS,
  payload,
});

export const fetchWeeklyClockFailure = (
  payload: FetchWeeklyClockFailurePayload
): FetchWeeklyClockFailure => ({
  type: FETCH_WEEKLY_CLOCK_FAILURE,
  payload,
});

export const fetchDailyClockRequest = (
  payload: FetchDailyClockRequestPayload
): FetchDailyClockRequest => ({
  type: FETCH_DAILY_CLOCK_REQUEST,
  payload,
});

export const fetchDailyClockSuccess = (
  payload: FetchDailyClockSuccessPayload
): FetchDailyClockSuccess => ({
  type: FETCH_DAILY_CLOCK_SUCCESS,
  payload,
});

export const fetchDailyClockFailure = (
  payload: FetchDailyClockFailurePayload
): FetchDailyClockFailure => ({
  type: FETCH_DAILY_CLOCK_FAILURE,
  payload,
});

export const fetchDailyClockByUserRequest = (
  payload: FetchDailyClockByUserRequestPayload
): FetchDailyClockByUserRequest => ({
  type: FETCH_DAILY_CLOCK_BY_USER_REQUEST,
  payload,
});

export const fetchDailyClockByUserSuccess = (
  payload: FetchDailyClockByUserSuccessPayload
): FetchDailyClockByUserSuccess => ({
  type: FETCH_DAILY_CLOCK_BY_USER_SUCCESS,
  payload,
});

export const fetchDailyClockByUserFailure = (
  payload: FetchDailyClockByUserFailurePayload
): FetchDailyClockByUserFailure => ({
  type: FETCH_DAILY_CLOCK_BY_USER_FAILURE,
  payload,
});

export const deleteTodaysClockRequest = (
  payload: DeleteTodaysClockRequestPayload,
  callbacks?: DeleteTodaysClockCallbacks
): DeleteTodaysClockRequest => ({
  type: DELETE_TODAYS_CLOCK_REQUEST,
  payload,
  callbacks,
});

export const deleteTodaysClockSuccess = (
  payload: DeleteTodaysClockSuccessPayload
): DeleteTodaysClockSuccess => ({
  type: DELETE_TODAYS_CLOCK_SUCCESS,
  payload,
});

export const deleteTodaysClockFailure = (
  payload: DeleteTodaysClockFailurePayload
): DeleteTodaysClockFailure => ({
  type: DELETE_TODAYS_CLOCK_FAILURE,
  payload,
});

export const deleteClockByUserAndDayRequest = (
  payload: DeleteClockByUserAndDayRequestPayload,
  callbacks: DeleteClockByUserAndDayCallbacks
) => ({
  type: DELETE_CLOCK_BY_USER_AND_DAY_REQUEST,
  payload,
  callbacks,
});

export const deleteClockByUserAndDaySuccess = (
  payload: DeleteClockByUserAndDaySuccessPayload
) => ({
  type: DELETE_CLOCK_BY_USER_AND_DAY_SUCCESS,
  payload,
});

export const deleteClockByUserAndDayFailure = (
  payload: DeleteClockByUserAndDayFailurePayload
) => ({
  type: DELETE_CLOCK_BY_USER_AND_DAY_FAILURE,
  payload,
});

export const linkTimeRecordRequest = (
  payload: LinkTimeRecordRequestPayload,
  callbacks?: LinkTimeRecordCallbacks
): LinkTimeRecordRequest => ({
  type: LINK_TIME_RECORD_REQUEST,
  payload,
  callbacks,
});

export const linkTimeRecordSuccess = (
  payload: LinkTimeRecordSuccessPayload
): LinkTimeRecordSuccess => ({
  type: LINK_TIME_RECORD_SUCCESS,
  payload,
});

export const linkTimeRecordFailure = (
  payload: LinkTimeRecordFailurePayload
): LinkTimeRecordFailure => ({
  type: LINK_TIME_RECORD_FAILURE,
  payload,
});

export const linkTimeRecordByUserAndDayRequest = (
  payload: LinkTimeRecordByUserAndDayRequestPayload,
  callbacks: LinkTimeRecordByUserAndDayCallbacks
) => ({
  type: LINK_TIME_RECORD_BY_USER_AND_DAY_REQUEST,
  payload,
  callbacks,
});

export const linkTimeRecordByUserAndDaySuccess = (
  payload: LinkTimeRecordByUserAndDaySuccessPayload
) => ({
  type: LINK_TIME_RECORD_BY_USER_AND_DAY_SUCCESS,
  payload,
});

export const linkTimeRecordByUserAndDayFailure = (
  payload: LinkTimeRecordByUserAndDayFailurePayload
) => ({
  type: LINK_TIME_RECORD_BY_USER_AND_DAY_FAILURE,
  payload,
});

export const manualEntryRequest = (
  payload: ManualEntryRequestPayload,
  callbacks: ManualEntryCallbacks
): ManualEntryRequest => ({
  type: MANUAL_ENTRY_REQUEST,
  payload,
  callbacks,
});

export const manualEntrySuccess = (
  payload: ManualEntrySuccessPayload
): ManualEntrySuccess => ({
  type: MANUAL_ENTRY_SUCCESS,
  payload,
});

export const manualEntryFailure = (
  payload: ManualEntryFailurePayload
): ManualEntryFailure => ({
  type: MANUAL_ENTRY_FAILURE,
  payload,
});

export const manualEntryByUserRequest = (
  payload: ManualEntryByUserRequestPayload,
  callbacks: ManualEntryByUserCallbacks
): ManualEntryByUserRequest => ({
  type: MANUAL_ENTRY_BY_USER_REQUEST,
  payload,
  callbacks,
});

export const manualEntryByUserSuccess = (
  payload: ManualEntryByUserSuccessPayload
): ManualEntryByUserSuccess => ({
  type: MANUAL_ENTRY_BY_USER_SUCCESS,
  payload,
});

export const manualEntryByUserFailure = (
  payload: ManualEntryByUserFailurePayload
): ManualEntryByUserFailure => ({
  type: MANUAL_ENTRY_BY_USER_FAILURE,
  payload,
});

export const updateEntryTimeRecordRequest = (
  payload: UpdateEntryTimeRecordRequestPayload,
  callbacks: UpdateEntryTimeRecordCallbacks
): UpdateEntryTimeRecordRequest => ({
  type: UPDATE_ENTRY_TIME_RECORD_REQUEST,
  payload,
  callbacks,
});

export const updateEntryTimeRecordSuccess = (
  payload: UpdateEntryTimeRecordSuccessPayload
): UpdateEntryTimeRecordSuccess => ({
  type: UPDATE_ENTRY_TIME_RECORD_SUCCESS,
  payload,
});

export const updateEntryTimeRecordFailure = (
  payload: UpdateEntryTimeRecordFailurePayload
): UpdateEntryTimeRecordFailure => ({
  type: UPDATE_ENTRY_TIME_RECORD_FAILURE,
  payload,
});

export const cleanError = (): CleanError => ({
  type: CLEAN_ERROR,
});
