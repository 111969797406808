import * as React from "react";
import { SVGProps } from "react";

interface Props {
  size?: string;
  color?: string;
}

const ReportIcon: React.FC<Props> = (
  { size, color },
  props: SVGProps<SVGSVGElement>
) => (
  <svg
    width={size ? size : 16}
    height={size ? size : 16}
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M15.833 13.448H1.5V1.159C1.5 1.07 1.425 1 1.333 1H.167C.075 1 0 1.07 0 1.158v13.551c0 .087.075.158.167.158h15.666c.092 0 .167-.071.167-.158v-1.103c0-.087-.075-.158-.167-.158Zm-12.5-1.575H4.5c.092 0 .167-.071.167-.158V8.88c0-.087-.075-.158-.167-.158H3.333c-.091 0-.166.071-.166.158v2.836c0 .087.075.158.166.158Zm3.167 0h1.167c.091 0 .166-.071.166-.158V5.412c0-.087-.075-.157-.166-.157H6.5c-.092 0-.167.07-.167.157v6.303c0 .087.075.158.167.158Zm3.167 0h1.166c.092 0 .167-.071.167-.158V6.948c0-.086-.075-.157-.167-.157H9.667c-.092 0-.167.07-.167.157v4.767c0 .087.075.158.167.158Zm3.166 0H14c.092 0 .167-.071.167-.158V3.836c0-.086-.075-.157-.167-.157h-1.167c-.091 0-.166.07-.166.157v7.88c0 .086.075.157.166.157Z"
      fill={color ? color : "currentColor"}
    />
  </svg>
);

export default ReportIcon;
