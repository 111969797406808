import React, { useState } from "react";
import {
  addCommentRequest,
  deleteCommentRequest,
} from "src/store/comments/actions";
import { useDispatch } from "react-redux";
import DeleteModal from "./DeleteModal";
import ChangeElementModal from "./ChangeElementModal";
import { deleteAttachmentRequest } from "src/store/attachments/actions";
import ModalWrapper from "./ModalWrapper";
import { format } from "date-fns";
import { parseTime } from "src/utils";
import { isMobile } from "mobile-device-detect";
import { clientTimezone } from "src/utils";

interface ModalProps {
  closeModal: () => void;
  onFetchSuccess?: () => void;
  elementId?: string;
  elementDate: string;
  isOpen: boolean;
  modalActionType: {
    action: string;
    page: string;
  };
}

const ActionsModal: React.FC<ModalProps> = ({
  elementId,
  elementDate,
  isOpen,
  modalActionType,
  closeModal,
  onFetchSuccess,
}) => {
  const dispatch = useDispatch();
  const [addText, setAddText] = useState<string>("");

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (addText.length + event.target.value.length > 500)
      return setAddText(event.target.value.substring(0, 500));
    else setAddText(event.target.value);
  };

  const handleDelete = () => {
    if (modalActionType.page === "Comment") {
      dispatch(
        deleteCommentRequest({ id: elementId }, { onSuccess: onFetchSuccess })
      );
    } else {
      dispatch(
        deleteAttachmentRequest(
          { id: elementId },
          { onSuccess: onFetchSuccess }
        )
      );
    }
    closeModal();
  };

  const handleAdd = () => {
    const commentText = addText.trim();
    if (commentText.length === 0) return setAddText("");
    else {
      dispatch(
        addCommentRequest(
          {
            commentText: commentText.trim(),
            commentDay: format(parseTime(elementDate), "y-MM-dd"),
            timezone: clientTimezone,
          },
          { onSuccess: onFetchSuccess }
        )
      );
      closeModal();
    }
  };

  return (
    <>
      <ModalWrapper
        closeModal={closeModal}
        isOpen={isOpen}
        modalActionType={modalActionType}
        maxWidth={isMobile ? "350px" : "600px"}
      >
        <>
          {modalActionType.action === "Delete" && (
            <DeleteModal
              closeModal={closeModal}
              deleteArticle={modalActionType.page}
              deleteElement={handleDelete}
            />
          )}
          {modalActionType.action === "Add" && (
            <ChangeElementModal
              elementText={addText}
              handleInput={handleInput}
              handleChangeElement={handleAdd}
              closeModal={closeModal}
            />
          )}
        </>
      </ModalWrapper>
    </>
  );
};

export default ActionsModal;
