import React from "react";

interface Props {
  color?: string;
  width?: number | string;
  height?: number | string;
}

const CheckBoxOutlineBlank: React.FC<Props> = ({
  color = "#F0F0F0",
  width = 24,
  height = 24,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="23"
        height="23"
        rx="3.5"
        fill="white"
        stroke={color}
      />
    </svg>
  );
};

export default CheckBoxOutlineBlank;
