import * as React from "react";
import { SVGProps } from "react";

const LogoutIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg data-sqa-id="logout-icon" width={18} height={18} fill="none" {...props}>
    <path
      d="M6.75 16.875h-3a1.4 1.4 0 0 1-1.06-.513 1.908 1.908 0 0 1-.44-1.237V2.875c0-.464.158-.91.44-1.237a1.4 1.4 0 0 1 1.06-.513h3M13.125 12.75 16.875 9l-3.75-3.75M16.875 9h-9"
      stroke="#FF4D4F"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LogoutIcon;
