import { Box, Typography, IconButton, Theme } from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { EntryModalBaseProps } from "./types";
import { useDispatch, useSelector } from "react-redux";
import { deleteTodaysClockRequest } from "src/store/clocks/actions";
import { stopWatchRequest } from "src/store/timeWatch/actions";
import { format } from "date-fns";
import { clientTimezone, parseTime } from "src/utils";
import Button from "../Button";
import theme from "src/theme";
import { getTimeWatchSelector } from "src/store/timeWatch/selectors";
import { Geolocation } from "@capacitor/geolocation";

const styles = {
  modalTitile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "16px 24px",
    borderBottom: (theme: Theme) =>
      `1px solid ${theme.palette.background.default}`,
  },
  modalContent: {
    padding: "24px",
  },
  modalClose: {
    color: (theme: Theme) => `${theme.palette.text.primary}`,
    p: 0,
  },
  actionButtons: {
    display: "flex",
    justifyContent: "space-between",
    columnGap: "10px",
    marginTop: "20px",
    ">div": {
      width: "100%",
    },
  },
  actionButton: {
    textTransform: "none",
  },
  actionButtonDelete: {
    textTransform: "none",
  },
};

const DeleteEntryModal: React.FC<EntryModalBaseProps> = ({
  closeModal,
  onFetchSuccess,
  modalData,
  currentTab,
}) => {
  const dispatch = useDispatch();
  const timeWatch = useSelector(getTimeWatchSelector);
  const [myPosition, setMyPosition] = useState(null);

  const deleteData: string[] =
    currentTab === 0
      ? Array.isArray(modalData)
        ? modalData
        : [modalData.id]
      : modalData.timeClockIDs;

  const handlerDeleteTime = () => {
    const isClockedIn =
      Array.isArray(modalData) && modalData.some((item) => !item.endTime);
    if (
      (currentTab === 0 && Array.isArray(modalData) && isClockedIn) ||
      (currentTab === 1 && timeWatch?.endTime === null)
    ) {
      const promise = new Promise((resolve) => {
        deleteData.forEach((item: any) => {
          if (
            (currentTab === 0 && !item.endTime) ||
            (currentTab === 1 && timeWatch?.id === item)
          ) {
            dispatch(
              stopWatchRequest(
                {
                  id: currentTab === 0 ? item.id : item,
                  endTimeLatitude: myPosition?.lat ? myPosition?.lat : null,
                  endTimeLongitude: myPosition?.lng ? myPosition?.lng : null,
                  endTimeTimezone: clientTimezone,
                },
                {
                  onSuccess: () => resolve(deleteData),
                }
              )
            );
          }
        });
      });
      promise.then(() => {
        dispatch(
          deleteTodaysClockRequest(
            {
              id:
                currentTab === 0
                  ? deleteData.map((item: any) => item.id)
                  : deleteData,
            },
            { onSuccess: onFetchSuccess }
          )
        );
      });
    } else if (
      (currentTab === 0 && modalData.endTime) ||
      (currentTab === 0 && Array.isArray(modalData) && !isClockedIn) ||
      (currentTab === 1 && (!timeWatch || timeWatch?.endTime !== null))
    ) {
      successDeleteClock();
    } else {
      dispatch(
        stopWatchRequest(
          {
            id: modalData.id,
            endTimeLatitude: myPosition?.lat ? myPosition?.lat : null,
            endTimeLongitude: myPosition?.lng ? myPosition?.lng : null,
            endTimeTimezone: clientTimezone,
          },
          {
            onSuccess: successDeleteClock,
          }
        )
      );
    }
    closeModal();
  };

  const successDeleteClock = () => {
    dispatch(
      deleteTodaysClockRequest(
        {
          id: Array.isArray(modalData)
            ? deleteData.map((item: any) => item.id)
            : deleteData,
        },
        { onSuccess: onFetchSuccess }
      )
    );
  };

  const getCurrentLocation = async () => {
    const coordinates = await Geolocation.getCurrentPosition();
    setMyPosition({
      lat: coordinates?.coords?.latitude,
      lng: coordinates?.coords?.longitude,
    });
  };

  useEffect(() => {
    getCurrentLocation();
  }, []);

  return (
    <>
      <Box sx={styles.modalTitile}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          fontSize={16}
          fontWeight={700}
        >
          {currentTab === 0 ? "Delete Entry" : "Delete Day Entries"}
        </Typography>
        <IconButton sx={styles.modalClose} onClick={closeModal}>
          <CloseIcon fontSize="small" color="disabled" />
        </IconButton>
      </Box>
      <Box sx={styles.modalContent}>
        <Typography fontSize={14}>
          {currentTab === 0
            ? "Are you sure you want to delete the entry?"
            : `This will delete all entries to the day of ${format(
                parseTime(modalData.day),
                "MMMM do, y"
              )}. Are you sure you want to continue?`}
        </Typography>
        <Box sx={styles.actionButtons}>
          <Box>
            <Button
              variant="outlined"
              color="secondary"
              size="medium"
              style={{ padding: "10px 20px" }}
              fullWidth
              sx={styles.actionButton}
              onClick={closeModal}
            >
              <Typography fontSize="14px" color={theme.palette.text.primary}>
                Cancel
              </Typography>
            </Button>
          </Box>
          <Box>
            <Button
              variant="outlined"
              color="error"
              size="medium"
              style={{ padding: "10px 20px" }}
              fullWidth
              sx={styles.actionButtonDelete}
              onClick={handlerDeleteTime}
            >
              <Typography fontSize="14px">Delete</Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default DeleteEntryModal;
