import {
  Avatar,
  Box,
  CircularProgress,
  Typography,
  IconButton,
  Snackbar,
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { PlusForWeb } from "src/assets/icons";
import { Button } from "src/components";
import { getUserSelector } from "src/store/users/selectors";
import { styles } from "./team.module";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import CallIcon from "@mui/icons-material/Call";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PullToRefresh from "react-simple-pull-to-refresh";
import { User } from "src/types";
import { formatPhoneNumber, parseTime } from "src/utils";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "src/constants";
import SortByModal from "src/components/ActionsModal/SortByModal";
import sortByUsers from "./sortByUsers.json";
import { getOrganizationSelector } from "src/store/auth/selectors";
import SearchField from "src/components/SearchField";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Copy } from "src/assets/icons";
import CloseIcon from "@mui/icons-material/Close";

interface Props {
  isLoading: boolean;
  searchTeamValue: string;
  pageSize: number;
  sortOrder: string;
  sortBy: string;
  sortBySearch: string;
  setIsLoading: any;
  setPage: (page: any) => void;
  setSearchTeamValue: (word: string) => void;
  setSortOrder: (order: string) => void;
  setSortBy: (elem: string) => void;
  setSortBySearch: (text: string) => void;
  fetchUsers?: () => void;
}

const TeamMobile: React.FC<Props> = ({
  isLoading,
  searchTeamValue,
  pageSize,
  sortOrder,
  sortBy,
  sortBySearch,
  setIsLoading,
  setPage,
  setSearchTeamValue,
  setSortOrder,
  setSortBy,
  setSortBySearch,
  fetchUsers,
}) => {
  const allUsers = useSelector(getUserSelector);
  const organization = useSelector(getOrganizationSelector);
  const navigate = useNavigate();
  const [openSortModal, setOpenSortModal] = useState<boolean>(false);
  const [isOpenSnackbar, setIsOpenSnackbar] = useState(false);
  const modalActionType = {
    action: "Sort",
    page: "By",
  };
  function stringAvatar(name) {
    return {
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }

  const handleRefresh = async () => {
    fetchUsers();
  };

  const handleClipboard = () => {
    setIsOpenSnackbar(true);
  };

  const handleCloseSnackbar = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setIsOpenSnackbar(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnackbar}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <>
      <PullToRefresh onRefresh={handleRefresh}>
        <Box sx={styles.boxContainer}>
          <Button
            style={{ height: "40px", marginTop: "14px" }}
            fullWidth
            variant="contained"
            color="primary"
            disabled={
              (organization?.trial &&
                new Date().getTime() >
                  parseTime(organization?.trialExpires).getTime()) ||
              (organization?.trial && allUsers.totalUsers === 10) ||
              (!organization?.trial &&
                organization?.subscriptionUsersNumber <= allUsers.totalUsers)
            }
            onClick={() => navigate(AppRoutes.AddUser)}
          >
            <Box sx={styles.addButtonContainer}>
              <PlusForWeb
                color={
                  (organization?.trial &&
                    new Date().getTime() >
                      parseTime(organization?.trialExpires).getTime()) ||
                  (organization?.trial && allUsers.totalUsers === 10) ||
                  (!organization?.trial &&
                    organization?.subscriptionUsersNumber <=
                      allUsers.totalUsers)
                    ? "#d9d9d9"
                    : "#fff"
                }
              />
              <Typography sx={styles.addButtonText}>Add User</Typography>
            </Box>
          </Button>
          <Box sx={[styles.topContainer, { my: "20px", columnGap: "15px" }]}>
            <Box width="100%">
              <SearchField
                id="searchTeamValue"
                searchValue={searchTeamValue}
                setSearchValue={setSearchTeamValue}
                setPage={setPage}
              />
            </Box>
            <Box
              sx={[styles.boxContainer, { p: "7px" }]}
              onClick={() => setOpenSortModal(true)}
            >
              <SwapVertIcon />
            </Box>
          </Box>
          {isLoading ? (
            <Box
              data-sqa-id="circular-progress-loader"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <CircularProgress />
            </Box>
          ) : allUsers.users.length ? (
            <>
              {allUsers.users.map((user: User) => (
                <Box
                  mb="20px"
                  sx={styles.boxContainer}
                  key={user.id}
                  onClick={() =>
                    navigate(AppRoutes.EditUser, {
                      state: { id: user.id },
                    })
                  }
                >
                  <Box display="flex" alignItems="center">
                    <Avatar
                      {...stringAvatar(`${user?.firstName} ${user?.lastName}`)}
                      sx={styles.avatar}
                      src={user?.profilePictureUrl}
                    />
                    <Box marginLeft="10px">
                      <Typography color="#262626">{`${user?.firstName} ${user?.lastName}`}</Typography>
                      <Box
                        display="flex"
                        alignItems="center"
                        columnGap="10px"
                        mt="10px"
                      >
                        <Box sx={styles.boxBlock}>
                          <Typography fontSize="12px" color="#0D6FF0">
                            {user?.isAdmin ? "Administrator" : "Employee"}
                          </Typography>
                        </Box>
                        <Box sx={styles.boxBlock}>
                          <Typography fontSize="12px" color="#0D6FF0">
                            {`$${user?.hourlyRate ? user?.hourlyRate : 0}/hr`}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box display="flex" mt="20px">
                    <Box mr="10px">
                      <CallIcon color="primary" />
                    </Box>
                    <Typography fontSize="14px" color="#262626">
                      <>
                        {formatPhoneNumber(user?.phoneNumber)}
                        <CopyToClipboard
                          text={user?.phoneNumber}
                          onCopy={handleClipboard}
                        >
                          <IconButton
                            aria-label="delete"
                            size="small"
                            sx={{ marginLeft: "5px" }}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <Copy width={16} height={16} />
                          </IconButton>
                        </CopyToClipboard>
                      </>
                    </Typography>
                  </Box>
                  <Box display="flex" mt="10px">
                    <Box mr="10px">
                      <MailOutlineIcon color="primary" />
                    </Box>
                    <Typography
                      fontSize="14px"
                      color="#262626"
                      sx={{ wordBreak: "break-all" }}
                    >
                      <>
                        {user?.email}
                        <CopyToClipboard
                          text={user?.email}
                          onCopy={handleClipboard}
                        >
                          <IconButton
                            aria-label="delete"
                            size="small"
                            sx={{ marginLeft: "5px" }}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <Copy width={16} height={16} />
                          </IconButton>
                        </CopyToClipboard>
                      </>
                    </Typography>
                  </Box>
                </Box>
              ))}
              {allUsers.isLoading ? (
                <Box
                  data-sqa-id="circular-progress-loader"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  mb="10px"
                >
                  <CircularProgress />
                </Box>
              ) : (
                pageSize < allUsers.totalPages * pageSize && (
                  <Box>
                    <Button
                      data-sqa-id="load-more-btn"
                      variant="contained"
                      fullWidth
                      style={{
                        border: "1px solid #0D6FF0",
                        backgroundColor: "transparent",
                        boxShadow: "none",
                      }}
                      onClick={() => setPage((prevState) => prevState + 1)}
                    >
                      <Box sx={styles.addButtonContainer}>
                        <Typography
                          data-sqa-id="load-more-btn-text"
                          color="#0D6FF0"
                        >
                          Load More
                        </Typography>
                      </Box>
                    </Button>
                    {/* <Box display="flex" mt="10px">
                    <Box mr="10px">
                      <MailOutlineIcon color="primary" />
                    </Box>
                    <Typography
                      fontSize="14px"
                      color="#262626"
                      sx={{ wordBreak: "break-all" }}
                    >
                      {user?.email}
                    </Typography>
                  </Box> */}
                  </Box>
                )
              )}
              {allUsers.isLoading ? (
                <Box
                  data-sqa-id="circular-progress-loader"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  mb="10px"
                >
                  <CircularProgress />
                </Box>
              ) : (
                pageSize < allUsers.totalPages * pageSize && (
                  <Button
                    data-sqa-id="load-more-btn"
                    variant="contained"
                    fullWidth
                    style={{
                      border: "1px solid #0D6FF0",
                      backgroundColor: "transparent",
                      boxShadow: "none",
                    }}
                    onClick={() => setPage((prevState) => prevState + 1)}
                  >
                    <Box sx={styles.addButtonContainer}>
                      <Typography
                        data-sqa-id="load-more-btn-text"
                        color="#0D6FF0"
                      >
                        Load More
                      </Typography>
                    </Box>
                  </Button>
                )
              )}
            </>
          ) : (
            <Box sx={styles.noData}>
              <Typography
                data-sqa-id="no-comments-yet-text"
                sx={styles.noDataText}
              >
                {!searchTeamValue.length ? (
                  "No users have been created yet."
                ) : (
                  <>
                    No results found for your search.
                    <br />
                    Please try again with different keywords.
                  </>
                )}
              </Typography>
            </Box>
          )}
        </Box>
      </PullToRefresh>
      {openSortModal && (
        <SortByModal
          setIsLoading={setIsLoading}
          sortTable={sortByUsers}
          setSortBy={setSortBy}
          sortBy={sortBy}
          setSortOrder={setSortOrder}
          sortOrder={sortOrder}
          setSortBySearch={setSortBySearch}
          sortBySearch={sortBySearch}
          modalActionType={modalActionType}
          isOpen={openSortModal}
          closeModal={() => setOpenSortModal(false)}
        />
      )}
      <Snackbar
        open={isOpenSnackbar}
        autoHideDuration={3000}
        message="Copied!"
        action={action}
        onClose={handleCloseSnackbar}
      />
    </>
  );
};

export default TeamMobile;
