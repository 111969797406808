import React from "react";
import { Box } from "@mui/material";
import PersonalInfoForm from "./PersonalInfoForm";

const styles = {
  tabsDataItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    mt: "20px",
  },
};

const TabPersonalInfo: React.FC = () => {
  return (
    <Box sx={styles.tabsDataItem}>
      <PersonalInfoForm />
    </Box>
  );
};

export default TabPersonalInfo;
