import * as React from "react";
import { SVGProps } from "react";

interface Props {
  size?: number;
}

const Comment: React.FC<Props> = ({ size }, props: SVGProps<SVGSVGElement>) => (
  <svg
    data-sqa-id="comment-icon"
    width={size ? size : 13}
    height={size ? size : 12}
    viewBox="0 0 13 12"
    fill="none"
    {...props}
  >
    <path
      d="M11.794 7.75c0 .31-.128.606-.356.825a1.247 1.247 0 0 1-.862.342H3.265L.828 11.25V1.917c0-.31.128-.607.357-.825.228-.22.538-.342.861-.342h8.53c.323 0 .633.123.862.342.228.218.356.515.356.825V7.75Z"
      stroke="#0D6FF0"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Comment;
