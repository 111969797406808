import React from "react";
import InputMask from "react-input-mask";
import {
  OutlinedTextFieldProps,
  Theme,
  Typography,
  TextField,
  Box,
  InputAdornment,
} from "@mui/material";
import Tooltip from "./Tooltip";
import { Lock as LockIcon } from "../assets/icons";
import theme from "src/theme";

const styles = {
  labelWrapper: {
    display: "flex",
  },
  requiredWrapper: {
    ml: "4px",
    fontSize: { xs: "14px", md: "16px" },
    color: (theme: Theme) => theme.palette.error.main,
  },
  input: {
    input: {
      fontSize: { xs: "14px", md: "16px" },
    },
    "& > .MuiInputBase-sizeSmall": {
      height: { xs: "40px", md: "46px" },
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
      margin: 0,
    },
  },
};

type InputProps = Omit<OutlinedTextFieldProps, "variant">;

interface Props extends InputProps {
  mask?: string;
  isDisabled?: boolean;
  borderStyle?: boolean;
  tooltipText?: string | React.ReactElement;
  withLockIcon?: boolean;
}

const Input: React.FC<Props> = ({
  label,
  mask,
  isDisabled,
  borderStyle,
  required,
  tooltipText,
  withLockIcon,
  InputProps,
  ...textFieldProps
}) => {
  const renderInput = () => {
    if (mask) {
      return (
        <InputMask
          mask={mask}
          placeholder="123-456-7890"
          {...textFieldProps}
          variant="outlined"
          sx={[
            styles.input,
            {
              ".MuiOutlinedInput-notchedOutline": {
                borderColor: "#D9D9D9",
              },
              ".MuiInputBase-root": {
                "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#D9D9D9",
                },
              },
            },
          ]}
          disabled={isDisabled}
        >
          {
            // @ts-ignore
            (inputProps: InputProps) => {
              return (
                <TextField
                  disabled={isDisabled}
                  {...inputProps}
                  type="tel"
                  InputProps={
                    withLockIcon
                      ? {
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip
                                title={tooltipText}
                                placement="bottom"
                                enterTouchDelay={0}
                              >
                                <span>
                                  <LockIcon
                                    width={16}
                                    height={16}
                                    color={theme.palette.text.primary}
                                  />
                                </span>
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }
                      : InputProps
                  }
                />
              );
            }
          }
        </InputMask>
      );
    }

    return (
      <TextField
        {...textFieldProps}
        variant="outlined"
        autoComplete="off"
        sx={[
          styles.input,
          {
            marginTop: borderStyle ? "0" : "10px",
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: borderStyle ? "transparent" : "#D9D9D9",
            },
            ".MuiInputBase-root": {
              "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                borderColor: borderStyle ? "transparent" : "#D9D9D9",
              },
            },
          },
        ]}
        InputProps={
          withLockIcon
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip
                      title={tooltipText}
                      placement="bottom"
                      enterTouchDelay={0}
                    >
                      <span>
                        <LockIcon
                          width={16}
                          height={16}
                          color={theme.palette.text.primary}
                        />
                      </span>
                    </Tooltip>
                  </InputAdornment>
                ),
              }
            : InputProps
        }
      />
    );
  };

  return (
    <>
      <Box sx={styles.labelWrapper}>
        <Typography
          data-sqa-id={
            label
              ? String(label).toLowerCase().replaceAll(" ", "-") +
                "-input-label"
              : "input-label"
          }
          color="text.secondary"
          fontSize={{ xs: "14px", md: "16px" }}
          mb={mask && "10px"}
        >
          {label}
        </Typography>
        {required ? (
          <Typography
            data-sqa-id={
              label
                ? String(label).toLowerCase().replaceAll(" ", "-") +
                  "-input-label-required"
                : "input-label-required"
            }
            sx={styles.requiredWrapper}
          >
            *
          </Typography>
        ) : null}
      </Box>

      {renderInput()}
    </>
  );
};

export default Input;
