import React from "react";
import { InputAdornment, IconButton } from "@mui/material";
import Input from "./Input";
import { ClearIcon, Search } from "src/assets/icons";
import { isMobile } from "mobile-device-detect";

const styles = {
  input: {
    marginTop: 0,
    width: isMobile ? "100%" : "300px",
  },
};

interface Props {
  id?: string;
  searchValue?: string;
  setSearchValue?: any;
  setPage?: any;
}

const SearchField: React.FC<Props> = ({
  id,
  searchValue,
  setSearchValue,
  setPage,
}) => {
  return (
    <>
      <Input
        InputProps={{
          startAdornment: (
            <InputAdornment position="end">
              <IconButton edge="start">
                <Search />
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: searchValue ? (
            <InputAdornment position="end">
              <IconButton edge="end" onClick={() => setSearchValue("")}>
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ) : null,
        }}
        style={styles.input}
        type="text"
        size="small"
        id={id}
        placeholder="Search"
        value={searchValue}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (setPage) setPage(1);
          setSearchValue(e.target.value);
        }}
      />
    </>
  );
};

export default SearchField;
