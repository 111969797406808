import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Theme } from "@mui/material";
import {
  DeleteEntryModal,
  EditEntryModal,
  GeneralEntryModal,
  LinkEntryModal,
  ManualEntryModal,
  ViewLocation,
} from "./todaysClockModalActions";
import { isMobile } from "mobile-device-detect";
const styles = {
  modal: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: 350,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "4px",
  },
  modalTitile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "16px 24px",
    borderBottom: (theme: Theme) =>
      `1px solid ${theme.palette.background.default}`,
  },
  modalClose: {
    color: (theme: Theme) => `${theme.palette.text.primary}`,
    p: 0,
  },
  modalContent: {
    padding: "24px",
  },
  actionButton: {
    height: 56,
    color: (theme: Theme) => `${theme.palette.text.primary}`,
    borderColor: (theme: Theme) => `${theme.palette.background.default}`,
    textTransform: "none",
    marginBottom: "10px",
    "&:hover": {
      background: (theme: Theme) => `${theme.palette.background.paper}`,
      color: (theme: Theme) => `${theme.palette.primary.main}`,
    },
    svg: {
      marginRight: "14px",
    },
  },
  actionButtonDelete: {
    height: 56,
    textTransform: "none",
  },
};

interface ModalProps {
  isOpen: boolean;
  closeModal: () => void;
  onFetchSuccess: () => void;
  modalData?: any | null;
  modalsState: string;
  setModalsState?: (elem: string) => void;
  currentTab: number;
  user?: any;
}

const ModalTodaysClockRecord: React.FC<ModalProps> = ({
  isOpen,
  closeModal,
  onFetchSuccess,
  modalData,
  currentTab,
  modalsState,
  user,
  setModalsState,
}) => {
  return (
    <div>
      <Modal
        disableAutoFocus={true}
        open={isOpen}
        onClose={closeModal}
        sx={{ padding: "20px" }}
      >
        <Box
          sx={[
            styles.modal,
            {
              maxWidth:
                !isMobile && modalsState === "VIEW_LOCATION_MODAL"
                  ? "500px"
                  : "350px",
            },
          ]}
        >
          {modalsState === "MANUAL_ENTRY_MODAL" && (
            <ManualEntryModal
              onFetchSuccess={onFetchSuccess}
              closeModal={closeModal}
              currentTab={currentTab}
              modalData={modalData}
              user={user}
            />
          )}
          {modalsState === "GENERAL_ENTRY_MODAL" && (
            <GeneralEntryModal
              setModalState={setModalsState}
              closeModal={closeModal}
              modalData={modalData}
              currentTab={currentTab}
            />
          )}
          {modalsState === "DELETE_ENTRY_MODAL" && (
            <DeleteEntryModal
              currentTab={currentTab}
              onFetchSuccess={onFetchSuccess}
              closeModal={closeModal}
              modalData={modalData}
            />
          )}
          {modalsState === "EDIT_ENTRY_MODAL" && (
            <EditEntryModal
              currentTab={currentTab}
              onFetchSuccess={onFetchSuccess}
              closeModal={closeModal}
              modalData={modalData}
            />
          )}
          {modalsState === "LINK_ENTRY_MODAL" && (
            <LinkEntryModal
              currentTab={currentTab}
              onFetchSuccess={onFetchSuccess}
              closeModal={closeModal}
              modalData={modalData}
            />
          )}
          {modalsState === "VIEW_LOCATION_MODAL" && (
            <ViewLocation
              currentTab={currentTab}
              onFetchSuccess={onFetchSuccess}
              closeModal={closeModal}
              modalData={modalData}
            />
          )}
        </Box>
      </Modal>
    </div>
  );
};
export default ModalTodaysClockRecord;
