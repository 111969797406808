import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { Button } from "src/components";
import { styles } from "src/pages/UserView/Comments/comments.module";
import { format } from "date-fns";
import { useDispatch } from "react-redux";
import { Arrow, EditIcon, DeleteIcon } from "src/assets/icons";
import { updateCommentRequest } from "src/store/comments/actions";
import ChangeElementModal from "./ActionsModal/ChangeElementModal";
import ActionsModal from "./ActionsModal/ActionsModal";
import { Comment } from "src/types";
import { parseTime } from "src/utils";

interface Props {
  commentDay: Comment[];
  handleFetch: () => void;
}

const TabCommentsData: React.FC<Props> = ({ commentDay, handleFetch }) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [updateCommentState, setUpdateCommentState] = useState<string>("");
  const [commentId, setCommentId] = useState<string>("");
  const [commentDate, setCommentDate] = useState<string>("");
  const [commentText, setCommentText] = useState<string>("");
  const [modalActionType, setModalActionType] = useState({
    action: "",
    page: "Comment",
  });
  const [showMore, setShowMore] = useState<string[]>([]);
  const dispatch = useDispatch();

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) =>
    setCommentText(event.target.value);

  const showMoreAccordeon = (id: string) => {
    if (showMore.includes(id))
      setShowMore(showMore.filter((elem: string) => elem !== id));
    else setShowMore([...showMore, id]);
  };

  const handleUpdateState = (id: string, commentTextEdit: string) => {
    setUpdateCommentState(id);
    setCommentText(commentTextEdit);
  };

  const handleOpenModal = (type: string, elem?: string) => {
    setOpenModal(true);
    setCommentId(elem);
    setCommentDate(elem);
    setModalActionType((prevState) => ({
      ...prevState,
      action: type,
    }));
  };

  const closeEditModalWithAccordeon = (commentId: string) => {
    setUpdateCommentState("");
    showMoreAccordeon(commentId);
  };

  const handleUpdateComment = (item: Comment) => {
    const updateCommentText = commentText.trim();
    if (updateCommentText.length === 0) return setCommentText("");
    else {
      dispatch(
        updateCommentRequest(
          {
            id: item.id,
            commentText: updateCommentText.trim(),
            commentDay: item.commentDay,
            projectId: item.id,
          },
          { onSuccess: handleFetch }
        )
      );
      setShowMore(showMore.filter((elem: string) => elem !== item.id));
      setUpdateCommentState("");
    }
  };

  return (
    <>
      <Box display="flex" flexDirection="column" rowGap="10px">
        {commentDay.length !== 0 ? (
          commentDay?.map((comment: Comment) => (
            <Box key={comment.id} sx={styles.commentContainer}>
              {updateCommentState !== comment.id ? (
                <>
                  <Box
                    sx={{ position: "relative" }}
                    onClick={() => showMoreAccordeon(comment.id)}
                  >
                    <Typography
                      sx={styles.comment}
                      style={{
                        WebkitBoxOrient: showMore.includes(comment.id)
                          ? "unset"
                          : "vertical",
                        maxHeight: showMore.includes(comment.id)
                          ? "fit-content"
                          : "auto",
                      }}
                    >
                      {comment.commentText}
                    </Typography>
                    <Typography sx={styles.commentData}>
                      {format(parseTime(comment.createdAt), "MMMM d, h:mm a")}
                    </Typography>
                    <Box
                      sx={styles.showAll}
                      style={{
                        transform: showMore.includes(comment.id)
                          ? "rotate(-180deg)"
                          : "rotate(-90deg)",
                      }}
                    >
                      <Arrow color="#00000033" width={12} height={12} />
                    </Box>
                  </Box>
                  {showMore.includes(comment.id) && (
                    <Box
                      sx={{
                        mt: "20px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        mt="10px"
                        variant="contained"
                        style={{
                          backgroundColor: "#FAAD14",
                          width: "50%",
                          margin: "0 5px 0 0",
                        }}
                        onClick={() =>
                          handleUpdateState(comment.id, comment.commentText)
                        }
                      >
                        <Box sx={styles.addButtonContainer}>
                          <EditIcon />
                          <Typography sx={styles.addButtonText}>
                            Edit
                          </Typography>
                        </Box>
                      </Button>
                      <Button
                        mt="10px"
                        variant="contained"
                        style={{
                          backgroundColor: "#FF4D4F",
                          width: "50%",
                          margin: "0 0 0 5px",
                        }}
                        onClick={() => handleOpenModal("Delete", comment.id)}
                      >
                        <Box sx={styles.addButtonContainer}>
                          <DeleteIcon />
                          <Typography sx={styles.addButtonText}>
                            Delete
                          </Typography>
                        </Box>
                      </Button>
                    </Box>
                  )}
                </>
              ) : (
                <ChangeElementModal
                  elementText={commentText}
                  handleInput={handleInput}
                  handleChangeElement={() => handleUpdateComment(comment)}
                  closeModal={() => {
                    closeEditModalWithAccordeon(comment.id);
                  }}
                />
              )}
            </Box>
          ))
        ) : (
          <Box sx={styles.noData}>
            <Typography sx={styles.noDataText}>
              No comments uploaded yet
            </Typography>
          </Box>
        )}
      </Box>

      {openModal && (
        <ActionsModal
          onFetchSuccess={handleFetch}
          elementDate={commentDate}
          modalActionType={modalActionType}
          isOpen={openModal}
          elementId={commentId}
          closeModal={() => setOpenModal(false)}
        />
      )}
    </>
  );
};

export default TabCommentsData;
