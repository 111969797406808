import { Box, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProjectSelector } from "src/store/projects/selectors";
import { useParams } from "react-router-dom";
import { fetchProjectIDRequest } from "src/store/projects/actions";
import { isMobile } from "mobile-device-detect";
import DeviceWrapper from "src/pages/DeviceWrapper";
import ProjectItemMobile from "./ProjectItemMobile";
import ProjectItemWeb from "./ProjectItemWeb";

const ProjectItem = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const project = useSelector(getProjectSelector);
  const activeTab: number = parseInt(sessionStorage.getItem("activeTab"));
  const [currentTab, setCurrentTab] = useState<number>(activeTab || 0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
    sessionStorage.setItem("activeTab", newValue.toString());
  };

  const fetchProject = () => {
    dispatch(
      fetchProjectIDRequest({
        id,
      })
    );
  };

  useEffect(() => {
    fetchProject();
  }, [id]);

  useEffect(() => {
    setCurrentTab(activeTab);
  }, [activeTab]);

  useEffect(() => {
    return () => {
      sessionStorage.setItem("activeTab", "0");
    };
  }, []);

  return (
    <>
      <DeviceWrapper>
        <>
          {project.isLoading ? (
            <Box
              data-sqa-id="circular-progress-loader"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              {isMobile ? (
                <ProjectItemMobile
                  currentTab={currentTab}
                  handleTabChange={handleTabChange}
                  fetchProject={fetchProject}
                />
              ) : (
                <ProjectItemWeb
                  currentTab={currentTab}
                  handleTabChange={handleTabChange}
                  fetchProject={fetchProject}
                />
              )}
            </>
          )}
        </>
      </DeviceWrapper>
    </>
  );
};

export default ProjectItem;
