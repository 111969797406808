import axios from "../../utils/axios";
import {
  FetchReportRequestPayload,
  ReportBreakdownRequestPayload,
  ReportTimesheetRequestPayload,
} from "./types";

export const fetchReport = (payload: FetchReportRequestPayload) => {
  return axios.post(
    `/report?currentPage=${payload.currentPage}&pageSize=${payload.pageSize}&sortOrder=${payload.sortOrder}&sortBy=${payload.sortBy}`,
    payload
  );
};

export const reportBreakdown = (payload: ReportBreakdownRequestPayload) => {
  return axios.post("/report/breakdown", payload);
};

export const reportTimesheet = (payload: ReportTimesheetRequestPayload) => {
  return axios.post("/report/timesheet", payload);
};
