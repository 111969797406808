import { Box, CardMedia, Typography } from "@mui/material";
import React from "react";
import { Attachment } from "src/types";
import { format } from "date-fns";
import { isMobile } from "mobile-device-detect";
import { AppRoutes } from "src/constants";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Comment } from "src/assets/icons";
import { parseTime } from "src/utils";

interface Props {
  item: Attachment;
  imageUrlPrefix: string;
  setOpenAttachmetSlider: (bol: boolean) => void;
  setStartCurrentAttachment: (el: string) => void;
}

const styles = {
  wrapper: {
    width: isMobile ? "48%" : "16%",
    marginTop: "20px",
    cursor: "pointer",
  },
  attachmentContainer: {
    position: "relative",
  },
  whiteBgReceipt: {
    position: "absolute",
    top: "16px",
    right: "16px",
    backgroundColor: "#fff",
    borderRadius: "4px",
    padding: "5px 12px",
  },
  caption: {
    fontSize: "12px",
    lineHeight: "22px",
    fontWeight: 400,
    color: "#0D6FF0",
  },
  attachment: {
    maxWidth: "100%",
    maxHeight: "100%",
    width: "100%",
    objectFit: "contain",
    height: isMobile ? "148px" : "240px",
    backgroundColor: "#F0F0F0",
  },
  captionText: {
    marginTop: "5px",
    fontSize: "16px",
    fontWeight: "400",
    color: "#000",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
    wordBreak: "break-all",
    overflow: "hidden",
  },
  whiteBgComment: {
    position: "absolute",
    bottom: "16px",
    right: "16px",
    backgroundColor: "#fff",
    borderRadius: "4px",
    padding: "7px 10px",
  },
};

const AttachmentItem: React.FC<Props> = ({
  item,
  imageUrlPrefix,
  setOpenAttachmetSlider,
  setStartCurrentAttachment,
}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const tabData = useLocation().state as {
    name: {
      id: string;
      userName: string;
    };
    date: string;
    atachmentsCount: number;
    commentsCount: number;
  };
  const handleClick = () => {
    if (isMobile) {
      navigate(`${AppRoutes.Attachment}/${item.id}`, {
        state: {
          name: {
            id: tabData.name.id,
            userName: tabData.name.userName,
          },
          date: tabData.date,
          atachmentsCount: tabData.atachmentsCount,
          commentsCount: tabData.commentsCount,
        },
      });
      sessionStorage.setItem("reportID", id);
    } else {
      setOpenAttachmetSlider(true);
      setStartCurrentAttachment(item.id);
    }
  };
  return (
    <>
      <Box sx={styles.wrapper} onClick={handleClick}>
        <Box sx={styles.attachmentContainer}>
          {item.isReceipt && (
            <Box sx={styles.whiteBgReceipt}>
              <Typography data-sqa-id="is-receipt" sx={styles.caption}>
                Receipt
              </Typography>
            </Box>
          )}
          {isMobile && (
            <>
              {item.caption && (
                <Box data-sqa-id="is-comment" sx={styles.whiteBgComment}>
                  <Comment />
                </Box>
              )}
            </>
          )}
          {`${imageUrlPrefix}${item.s3FilePath}` ? (
            <CardMedia
              data-sqa-id="attachment-card-media"
              sx={styles.attachment}
              component="img"
              image={`${imageUrlPrefix}${item.s3FilePath}`}
              alt="attachment"
            />
          ) : (
            <Box sx={styles.attachment} />
          )}
        </Box>
        {!isMobile && (
          <Box mt="20px">
            <Typography
              data-sqa-id="attachment-date-time"
              fontSize="16px"
              fontWeight={400}
              color="#8C8C8C"
            >
              {format(parseTime(item.createdAt), "MMMM d, h:mm a")}
            </Typography>
            <Typography
              data-sqa-id="attachment-caption-text"
              sx={styles.captionText}
            >
              {item.caption}
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
};

export default AttachmentItem;
