import {
  ADD_COMMENT_PROJECT_FAILURE,
  ADD_COMMENT_PROJECT_REQUEST,
  ADD_COMMENT_PROJECT_SUCCESS,
  ADD_PROJECT_FAILURE,
  ADD_PROJECT_REQUEST,
  ADD_PROJECT_SUCCESS,
  DELETE_COMMENT_PROJECT_FAILURE,
  DELETE_COMMENT_PROJECT_REQUEST,
  DELETE_COMMENT_PROJECT_SUCCESS,
  DELETE_PROJECT_FAILURE,
  DELETE_PROJECT_REQUEST,
  DELETE_PROJECT_SUCCESS,
  FETCH_COMMENT_PROJECT_FAILURE,
  FETCH_COMMENT_PROJECT_REQUEST,
  FETCH_COMMENT_PROJECT_SUCCESS,
  FETCH_PROJECT_ALL_FAILURE,
  FETCH_PROJECT_ALL_REQUEST,
  FETCH_PROJECT_ALL_SUCCESS,
  FETCH_PROJECT_FAILURE,
  FETCH_PROJECT_ID_FAILURE,
  FETCH_PROJECT_ID_REQUEST,
  FETCH_PROJECT_ID_SUCCESS,
  FETCH_PROJECT_REQUEST,
  FETCH_PROJECT_SUCCESS,
  FETCH_SUBPROJECT_FAILURE,
  FETCH_SUBPROJECT_REQUEST,
  FETCH_SUBPROJECT_SUCCESS,
  SEARCH_PROJECT_FAILURE,
  SEARCH_PROJECT_REQUEST,
  SEARCH_PROJECT_SUCCESS,
  UPDATE_COMMENT_PROJECT_FAILURE,
  UPDATE_COMMENT_PROJECT_REQUEST,
  UPDATE_COMMENT_PROJECT_SUCCESS,
  UPDATE_PROJECT_FAILURE,
  UPDATE_PROJECT_REQUEST,
  UPDATE_PROJECT_SUCCESS,
} from "./actionTypes";
import {
  FetchProjectFailurePayload,
  FetchProjectSuccessPayload,
  FetchProjectRequestPayload,
  FetchSubProjectFailurePayload,
  FetchSubProjectRequestPayload,
  FetchSubProjectSuccessPayload,
  FetchProjectIDFailurePayload,
  FetchProjectIDRequestPayload,
  FetchProjectIDSuccessPayload,
  AddProjectCallbacks,
  AddProjectFailurePayload,
  AddProjectRequestPayload,
  AddProjectSuccessPayload,
  UpdateProjectCallbacks,
  UpdateProjectFailurePayload,
  UpdateProjectRequestPayload,
  UpdateProjectSuccessPayload,
  DeleteProjectCallbacks,
  DeleteProjectFailurePayload,
  DeleteProjectRequestPayload,
  DeleteProjectSuccessPayload,
  FetchCommentProjectFailurePayload,
  FetchCommentProjectRequestPayload,
  FetchCommentProjectSuccessPayload,
  AddCommentProjectCallbacks,
  AddCommentProjectFailurePayload,
  AddCommentProjectRequestPayload,
  AddCommentProjectSuccessPayload,
  DeleteCommentProjectCallbacks,
  DeleteCommentProjectFailurePayload,
  DeleteCommentProjectRequestPayload,
  DeleteCommentProjectSuccessPayload,
  UpdateCommentProjectCallbacks,
  UpdateCommentProjectFailurePayload,
  UpdateCommentProjectRequestPayload,
  UpdateCommentProjectSuccessPayload,
  SearchProjectFailurePayload,
  SearchProjectRequestPayload,
  SearchProjectSuccessPayload,
  FetchProjectAllFailurePayload,
  FetchProjectAllSuccessPayload,
  FetchProjectCallbacks,
  FetchSubProjectCallbacks,
  SearchProjectCallbacks,
} from "./types";

export const fetchProjectRequest = (
  payload: FetchProjectRequestPayload,
  callbacks?: FetchProjectCallbacks
) => ({
  type: FETCH_PROJECT_REQUEST,
  payload,
  callbacks,
});

export const fetchProjectSuccess = (payload: FetchProjectSuccessPayload) => ({
  type: FETCH_PROJECT_SUCCESS,
  payload,
});

export const fetchProjectFailure = (payload: FetchProjectFailurePayload) => ({
  type: FETCH_PROJECT_FAILURE,
  payload,
});

export const fetchProjectAllRequest = () => ({
  type: FETCH_PROJECT_ALL_REQUEST,
});

export const fetchProjectAllSuccess = (
  payload: FetchProjectAllSuccessPayload
) => ({
  type: FETCH_PROJECT_ALL_SUCCESS,
  payload,
});

export const fetchProjectAllFailure = (
  payload: FetchProjectAllFailurePayload
) => ({
  type: FETCH_PROJECT_ALL_FAILURE,
  payload,
});

export const fetchSubProjectRequest = (
  payload: FetchSubProjectRequestPayload,
  callbacks?: FetchSubProjectCallbacks
) => ({
  type: FETCH_SUBPROJECT_REQUEST,
  payload,
  callbacks,
});

export const fetchSubProjectSuccess = (
  payload: FetchSubProjectSuccessPayload
) => ({
  type: FETCH_SUBPROJECT_SUCCESS,
  payload,
});

export const fetchSubProjectFailure = (
  payload: FetchSubProjectFailurePayload
) => ({
  type: FETCH_SUBPROJECT_FAILURE,
  payload,
});

export const fetchProjectIDRequest = (
  payload: FetchProjectIDRequestPayload
) => ({
  type: FETCH_PROJECT_ID_REQUEST,
  payload,
});

export const fetchProjectIDSuccess = (
  payload: FetchProjectIDSuccessPayload
) => ({
  type: FETCH_PROJECT_ID_SUCCESS,
  payload,
});

export const fetchProjectIDFailure = (
  payload: FetchProjectIDFailurePayload
) => ({
  type: FETCH_PROJECT_ID_FAILURE,
  payload,
});

export const fetchCommentProjectRequest = (
  payload: FetchCommentProjectRequestPayload
) => ({
  type: FETCH_COMMENT_PROJECT_REQUEST,
  payload,
});

export const fetchCommentProjectSuccess = (
  payload: FetchCommentProjectSuccessPayload
) => ({
  type: FETCH_COMMENT_PROJECT_SUCCESS,
  payload,
});

export const fetchCommentProjectFailure = (
  payload: FetchCommentProjectFailurePayload
) => ({
  type: FETCH_COMMENT_PROJECT_FAILURE,
  payload,
});

export const searchProjectRequest = (
  payload: SearchProjectRequestPayload,
  callbacks?: SearchProjectCallbacks
) => ({
  type: SEARCH_PROJECT_REQUEST,
  payload,
  callbacks,
});

export const searchProjectSuccess = (payload: SearchProjectSuccessPayload) => ({
  type: SEARCH_PROJECT_SUCCESS,
  payload,
});

export const searchProjectFailure = (payload: SearchProjectFailurePayload) => ({
  type: SEARCH_PROJECT_FAILURE,
  payload,
});

export const addProjectRequest = (
  payload: AddProjectRequestPayload,
  callbacks?: AddProjectCallbacks
) => ({
  type: ADD_PROJECT_REQUEST,
  payload,
  callbacks,
});

export const addProjectSuccess = (payload: AddProjectSuccessPayload) => ({
  type: ADD_PROJECT_SUCCESS,
  payload,
});

export const addProjectFailure = (payload: AddProjectFailurePayload) => ({
  type: ADD_PROJECT_FAILURE,
  payload,
});

export const updateProjectRequest = (
  payload: UpdateProjectRequestPayload,
  callbacks?: UpdateProjectCallbacks
) => ({
  type: UPDATE_PROJECT_REQUEST,
  payload,
  callbacks,
});

export const updateProjectSuccess = (payload: UpdateProjectSuccessPayload) => ({
  type: UPDATE_PROJECT_SUCCESS,
  payload,
});

export const updateProjectFailure = (payload: UpdateProjectFailurePayload) => ({
  type: UPDATE_PROJECT_FAILURE,
  payload,
});

export const deleteProjectRequest = (
  payload: DeleteProjectRequestPayload,
  callbacks?: DeleteProjectCallbacks
) => ({
  type: DELETE_PROJECT_REQUEST,
  payload,
  callbacks,
});

export const deleteProjectSuccess = (payload: DeleteProjectSuccessPayload) => ({
  type: DELETE_PROJECT_SUCCESS,
  payload,
});

export const deleteProjectFailure = (payload: DeleteProjectFailurePayload) => ({
  type: DELETE_PROJECT_FAILURE,
  payload,
});

export const addCommentProjectRequest = (
  payload: AddCommentProjectRequestPayload,
  callbacks?: AddCommentProjectCallbacks
) => ({
  type: ADD_COMMENT_PROJECT_REQUEST,
  payload,
  callbacks,
});

export const addCommentProjectSuccess = (
  payload: AddCommentProjectSuccessPayload
) => ({
  type: ADD_COMMENT_PROJECT_SUCCESS,
  payload,
});

export const addCommentProjectFailure = (
  payload: AddCommentProjectFailurePayload
) => ({
  type: ADD_COMMENT_PROJECT_FAILURE,
  payload,
});

export const updateCommentProjectRequest = (
  payload: UpdateCommentProjectRequestPayload,
  callbacks?: UpdateCommentProjectCallbacks
) => ({
  type: UPDATE_COMMENT_PROJECT_REQUEST,
  payload,
  callbacks,
});

export const updateCommentProjectSuccess = (
  payload: UpdateCommentProjectSuccessPayload
) => ({
  type: UPDATE_COMMENT_PROJECT_SUCCESS,
  payload,
});

export const updateCommentProjectFailure = (
  payload: UpdateCommentProjectFailurePayload
) => ({
  type: UPDATE_COMMENT_PROJECT_FAILURE,
  payload,
});

export const deleteCommentProjectRequest = (
  payload: DeleteCommentProjectRequestPayload,
  callbacks?: DeleteCommentProjectCallbacks
) => ({
  type: DELETE_COMMENT_PROJECT_REQUEST,
  payload,
  callbacks,
});

export const deleteCommentProjectSuccess = (
  payload: DeleteCommentProjectSuccessPayload
) => ({
  type: DELETE_COMMENT_PROJECT_SUCCESS,
  payload,
});

export const deleteCommentProjectFailure = (
  payload: DeleteCommentProjectFailurePayload
) => ({
  type: DELETE_COMMENT_PROJECT_FAILURE,
  payload,
});
