import axios from "../../utils/axios";
import {
  DeleteClockByUserAndDayRequestPayload,
  FetchDailyClockByUserRequestPayload,
  FetchDailyClockRequestPayload,
  FetchTodaysClockRequestPayload,
  FetchWeeklyClockRequestPayload,
  FetchWorkedMonthRequestPayload,
  LinkTimeRecordByUserAndDayRequestPayload,
  LinkTimeRecordRequestPayload,
  ManualEntryByUserRequestPayload,
  ManualEntryRequestPayload,
  UpdateEntryTimeRecordRequestPayload,
} from "./types";
import { DeleteTodaysClockRequestPayload } from "./types";

export const fetchTodaysClock = (payload: FetchTodaysClockRequestPayload) => {
  return axios.get(`/timeClock/today?timezone=${payload.timezone}`);
};

export const fetchWeeklyClock = (payload: FetchWeeklyClockRequestPayload) => {
  return axios.get(`/timeClock/weekly?timezone=${payload.timezone}`);
};

export const fetchWorkedMonth = (payload: FetchWorkedMonthRequestPayload) => {
  return axios.get(
    `/timeClock/workedDays/month?date=${payload.date}&timezone=${payload.timezone}`
  );
};

export const fetchDailyClock = (payload: FetchDailyClockRequestPayload) => {
  return axios.get(`/timeClock/day/${payload.date}`);
};

export const fetchDailyClockByUser = (
  payload: FetchDailyClockByUserRequestPayload
) => {
  return axios.get(`/timeClock/${payload.date}/user/${payload.id}`);
};

export const deleteTodaysClock = (payload: DeleteTodaysClockRequestPayload) => {
  return axios.delete("/timeClock", { data: payload.id });
};

export const deleteClockByUserAndDay = (
  payload: DeleteClockByUserAndDayRequestPayload
) => {
  return axios.delete(`/timeClock/deleteForDayAndUser`, {
    data: payload.userDayPairs,
  });
};

export const linkTimeRecord = (payload: LinkTimeRecordRequestPayload) => {
  return axios.post("/timeClock/linkTimeRecord", payload.linkEntries);
};

export const linkTimeRecordByUserAndDay = (
  payload: LinkTimeRecordByUserAndDayRequestPayload
) => {
  return axios.post("/timeClock/linkUserDayPairToProject", payload.data);
};

export const manualEntryRecord = (payload: ManualEntryRequestPayload) => {
  return axios.post("/timeClock/manualEntry", payload.clockRecord);
};

export const manualEntryRecordByUser = (
  payload: ManualEntryByUserRequestPayload
) => {
  return axios.post(`/timeClock/manualEntry/${payload.id}`, payload);
};

export const updateEntryTimeRecord = (
  payload: UpdateEntryTimeRecordRequestPayload
) => {
  return axios.put("/timeClock", payload.clockRecord);
};
