import { Box, Typography } from "@mui/material";
import React from "react";
import Button from "../../Button";
import ModalWrapper from "../ModalWrapper";
import { File, PlusForWeb, ViewForWeb } from "src/assets/icons";
import { reportBreakdownRequest } from "src/store/report/actions";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import CurrentReportBreakdown from "src/components/CurrentReportDreakdown";
import { ManualEntryModal } from "src/components/todaysClockModalActions";
import { parseTime } from "src/utils";
import { getOrganizationSelector } from "src/store/auth/selectors";

interface ModalProps {
  closeModal: () => void;
  fetchSuccess: () => void;
  handleTimesheet: () => void;
  setModalActionType?: any;
  isOpen: boolean;
  selectedDate: (number | Date)[];
  userIds: string[];
  projectIds: string[];
  modalActionType: {
    action: string;
    page: string;
  };
}

const ActionReportsModal: React.FC<ModalProps> = ({
  isOpen,
  modalActionType,
  selectedDate,
  userIds,
  projectIds,
  setModalActionType,
  closeModal,
  handleTimesheet,
  fetchSuccess,
}) => {
  const dispatch = useDispatch();
  const organization = useSelector(getOrganizationSelector);

  const handleOpenModal = (action: string, page: string, func?: () => void) => {
    setModalActionType((prevState) => ({
      ...prevState,
      action,
      page,
    }));
    if (func) func();
  };

  const handleCurrentBreakdown = () => {
    dispatch(
      reportBreakdownRequest({
        startDate: format(selectedDate[0], "y-MM-dd"),
        endDate: format(selectedDate[1], "y-MM-dd"),
        userIds: userIds.length ? userIds : null,
        projectIds: projectIds.length ? projectIds : null,
      })
    );
  };

  return (
    <>
      <ModalWrapper
        closeModal={closeModal}
        isOpen={isOpen}
        maxWidth="350px"
        modalActionType={modalActionType}
      >
        <>
          {modalActionType.action === "Actions" && (
            <>
              <Box>
                <Button
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  onClick={() => {
                    handleTimesheet();
                    closeModal();
                  }}
                >
                  <File color="#0D6FF0" />
                  <Typography
                    ml="12px"
                    fontSize="14px"
                    fontWeight={500}
                    color="#262626"
                  >
                    Timesheet
                  </Typography>
                </Button>
              </Box>
              <Box mt="10px">
                <Button
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  onClick={() =>
                    handleOpenModal("View", "Breakdown", handleCurrentBreakdown)
                  }
                >
                  <ViewForWeb color="#0D6FF0" />
                  <Typography
                    ml="12px"
                    fontSize="14px"
                    fontWeight={500}
                    color="#262626"
                  >
                    View Breakdown
                  </Typography>
                </Button>
              </Box>
              <Box mt="10px">
                <Button
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  disabled={
                    organization?.trial &&
                    new Date().getTime() >
                      parseTime(organization?.trialExpires).getTime()
                  }
                  onClick={() => handleOpenModal("Manual", "Entry")}
                >
                  <PlusForWeb
                    color={
                      organization?.trial &&
                      new Date().getTime() >
                        parseTime(organization?.trialExpires).getTime()
                        ? "#d9d9d9"
                        : "#0D6FF0"
                    }
                  />
                  <Typography
                    ml="12px"
                    fontSize="14px"
                    fontWeight={500}
                    color="#262626"
                  >
                    Manual Entry
                  </Typography>
                </Button>
              </Box>
            </>
          )}
          {modalActionType.action === "View" && (
            <CurrentReportBreakdown closeModal={closeModal} />
          )}
          {modalActionType.action === "Manual" && (
            <ManualEntryModal
              onFetchSuccess={fetchSuccess}
              hideDefaultWrapper={true}
              closeModal={closeModal}
              currentTab={2}
            />
          )}
        </>
      </ModalWrapper>
    </>
  );
};

export default ActionReportsModal;
