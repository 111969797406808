import * as React from "react";
import { SVGProps } from "react";

type IconProps = {
  width?: number;
  height?: number;
  color?: string;
};

const Back: React.FC<IconProps> = (
  { width, height, color },
  props: SVGProps<SVGSVGElement>
) => (
  <svg
    data-sqa-id="arrow-left-right-icon"
    width={width}
    height={height}
    fill="none"
    viewBox={`0 0 26 18`}
    {...props}
  >
    <path
      d="M24.5 9H1m0 0 7.5-7.5M1 9l7.5 7.5"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Back;
