import * as React from "react";
import { SVGProps } from "react";

interface Props {
  color?: string;
  size?: string;
}

const TeamIcon: React.FC<Props> = (
  { size, color },
  props: SVGProps<SVGSVGElement>
) => (
  <svg
    width={size ? size : 16}
    height={size ? size : 16}
    viewBox="0 0 21 20"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.7154 16.3216C19.2137 15.1989 18.4856 14.1792 17.5718 13.3191C16.6607 12.4566 15.5814 11.7689 14.3936 11.294C14.383 11.2889 14.3723 11.2864 14.3617 11.2814C16.0186 10.1508 17.0957 8.30904 17.0957 6.23116C17.0957 2.78894 14.1436 0 10.5 0C6.85641 0 3.9043 2.78894 3.9043 6.23116C3.9043 8.30904 4.98142 10.1508 6.63832 11.2839C6.62769 11.2889 6.61705 11.2915 6.60641 11.2965C5.41493 11.7714 4.34579 12.4523 3.42824 13.3216C2.51524 14.1823 1.7873 15.2019 1.28464 16.3241C0.790815 17.4227 0.524486 18.6006 0.500067 19.794C0.499357 19.8208 0.504335 19.8475 0.51471 19.8724C0.525084 19.8974 0.540643 19.9202 0.560471 19.9394C0.5803 19.9586 0.603995 19.9738 0.630162 19.9842C0.656329 19.9946 0.684437 20 0.712831 20H2.30857C2.42559 20 2.51867 19.9121 2.52133 19.804C2.57452 17.8643 3.39898 16.0477 4.85642 14.6709C6.36439 13.2462 8.36704 12.4623 10.5 12.4623C12.633 12.4623 14.6356 13.2462 16.1436 14.6709C17.601 16.0477 18.4255 17.8643 18.4787 19.804C18.4813 19.9146 18.5744 20 18.6914 20H20.2872C20.3156 20 20.3437 19.9946 20.3698 19.9842C20.396 19.9738 20.4197 19.9586 20.4395 19.9394C20.4594 19.9202 20.4749 19.8974 20.4853 19.8724C20.4957 19.8475 20.5006 19.8208 20.4999 19.794C20.4733 18.593 20.21 17.4246 19.7154 16.3216ZM10.5 10.5528C9.27926 10.5528 8.13033 10.103 7.26598 9.28643C6.40162 8.46985 5.92556 7.38442 5.92556 6.23116C5.92556 5.07789 6.40162 3.99246 7.26598 3.17588C8.13033 2.3593 9.27926 1.90955 10.5 1.90955C11.7207 1.90955 12.8697 2.3593 13.734 3.17588C14.5984 3.99246 15.0744 5.07789 15.0744 6.23116C15.0744 7.38442 14.5984 8.46985 13.734 9.28643C12.8697 10.103 11.7207 10.5528 10.5 10.5528Z"
      fill={color ? color : "currentColor"}
    />
  </svg>
);

export default TeamIcon;
