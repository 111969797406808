import { Box, Button, IconButton, Theme, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Map from "../Map";
import { EntryModalBaseProps } from "./types";
import CloseIcon from "@mui/icons-material/Close";
import { isMobile } from "mobile-device-detect";
import { parseTime } from "src/utils";
import { format } from "date-fns";
import theme from "src/theme";

const styles = {
  modalTitile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "16px 24px",
    borderBottom: (theme: Theme) =>
      `1px solid ${theme.palette.background.default}`,
  },
  modalClose: {
    color: (theme: Theme) => `${theme.palette.text.primary}`,
    p: 0,
  },
};

const ViewLocation: React.FC<EntryModalBaseProps> = ({
  closeModal,
  modalData,
}) => {
  const [coords, setCoords] = useState(null);
  useEffect(() => {
    setCoords([
      {
        lat: modalData?.startTimeLatitude
          ? parseFloat(modalData?.startTimeLatitude)
          : null,
        lng: modalData?.startTimeLongitude
          ? parseFloat(modalData?.startTimeLongitude)
          : null,
        text: `Start time ${format(parseTime(modalData?.startTime), "p")}`,
      },
      {
        lat: modalData?.endTimeLatitude
          ? parseFloat(modalData?.endTimeLatitude)
          : null,
        lng: modalData?.endTimeLongitude
          ? parseFloat(modalData?.endTimeLongitude)
          : null,
        text: modalData?.endTime
          ? `End time ${format(parseTime(modalData?.endTime), "p")}`
          : "",
      },
    ]);
  }, []);
  return (
    <>
      <Box sx={styles.modalTitile}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          fontSize={16}
          fontWeight={700}
        >
          View Location
        </Typography>
        <IconButton sx={styles.modalClose} onClick={closeModal}>
          <CloseIcon fontSize="small" color="disabled" />
        </IconButton>
      </Box>
      <Box p="20px">
        <Map coords={coords} height={isMobile ? "350px" : "400px"} />
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            style={{
              marginTop: "20px",
              boxShadow: "none",
              textTransform: "capitalize",
              padding: "10px 40px",
            }}
            onClick={closeModal}
          >
            <Typography fontSize="14px" color={theme.palette.text.primary}>
              Close
            </Typography>
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default ViewLocation;
