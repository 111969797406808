export const styles = {
  boxContainer: {
    display: "flex",
    flexDirection: "column",
    border: "1px solid #F0F0F0",
    backgroundColor: "#fff",
    borderRadius: "4px",
    padding: "24px 22px",
  },
  actionButtons: {
    display: "flex",
    justifyContent: "space-between",
    columnGap: "10px",
    marginTop: "20px",
  },
  actionButtonSave: {
    height: 46,
    textTransform: "none",
    boxShadow: "none",
  },
};
