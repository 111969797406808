import { Theme } from "@mui/material";
export const styles = {
  modalTitile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "16px 24px",
    borderBottom: (theme: Theme) =>
      `1px solid ${theme.palette.background.default}`,
  },
  modalContent: {
    padding: "24px",
  },
  modalClose: {
    color: (theme: Theme) => `${theme.palette.text.primary}`,
    p: 0,
  },
  actionButton: {
    height: 56,
    color: (theme: Theme) => `${theme.palette.text.primary}`,
    borderColor: (theme: Theme) => `${theme.palette.background.default}`,
    textTransform: "none",
    marginBottom: "10px",
    "&:hover": {
      background: (theme: Theme) => `${theme.palette.background.paper}`,
      color: (theme: Theme) => `${theme.palette.primary.main}`,
    },
    svg: {
      marginRight: "14px",
    },
  },
  actionButtonDelete: {
    height: 56,
    textTransform: "none",
  },
  modalBox: { display: "flex", justifyContent: "space-between", mb: "20px" },
  flexBoxStyle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  entryError: {
    fontSize: "14px",
    mb: "10px",
    color: (theme: Theme) => `${theme.palette.error.main}`,
  },
};
