export const FETCH_REPORT_REQUEST = "FETCH_REPORT_REQUEST";
export const FETCH_REPORT_SUCCESS = "FETCH_REPORT_SUCCESS";
export const FETCH_REPORT_FAILURE = "FETCH_REPORT_FAILURE";

export const REPORT_BREAKDOWN_REQUEST = "REPORT_BREAKDOWN_REQUEST";
export const REPORT_BREAKDOWN_SUCCESS = "REPORT_BREAKDOWN_SUCCESS";
export const REPORT_BREAKDOWN_FAILURE = "REPORT_BREAKDOWN_FAILURE";

export const REPORT_TIMESHEET_REQUEST = "REPORT_TIMESHEET_REQUEST";
export const REPORT_TIMESHEET_SUCCESS = "REPORT_TIMESHEET_SUCCESS";
export const REPORT_TIMESHEET_FAILURE = "REPORT_TIMESHEET_FAILURE";

export const ADD_REPORT_FILTERS = "ADD_REPORT_FILTERS";

export const CLEAR_FILTER = "CLEAR_FILTER";
