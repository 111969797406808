import {
  Box,
  CircularProgress,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { isMobile } from "mobile-device-detect";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "src/constants";
import InvoicesTable from "src/pages/AdminView/Settings/InvoicesTable";
import theme from "src/theme";
import ChoosePlan from "./ActionsModal/ChoosePlan";
import CreditCardUpdate from "./ActionsModal/CreditCardUpdate";
import Button from "./Button";
import { useSelector } from "react-redux";
import { getSubscriptionSelector } from "src/store/subscription/selectors";
import { getOrganizationSelector } from "src/store/auth/selectors";
import { useDispatch } from "react-redux";
import {
  deleteSubscriptionRequest,
  getInvoiceRequest,
  getSubscriptionRequest,
} from "src/store/subscription/actions";
import { format } from "date-fns";
import { parseTime } from "src/utils";
import CancelSubscription from "./ActionsModal/CancelSubscription";
import {
  Discover,
  Visa,
  Maestro,
  MasterCard,
  AmericanExpress,
} from "src/assets/icons";
import { Capacitor } from "@capacitor/core";

const styles = {
  isLoading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  btn: {
    width: { xs: "100%", md: "auto" },
  },
  planContainer: {
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
  },
  card: {
    p: { xs: "22px", md: "30px" },
    borderRadius: "4px",
    border: (theme) => `1px solid ${theme.palette.background.default}`,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  invoicesCard: {
    alignItems: "stretch",
    margin: "16px 0",
    hr: {
      margin: "14px 0",
    },
  },
  significant: {
    display: "inline-flex",
    p: "5px 15px",
    borderRadius: "4px",
  },
  paidPlanAction: {
    width: "100%",
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    columnGap: { xs: "0", md: "20px" },
    rowGap: { xs: "10px", md: "0" },
  },
  dots: {
    display: "flex",
    alignItems: "center",
    margin: "0 8px",
  },
  dot: {
    width: "3px",
    height: "3px",
    borderRadius: "50%",
    background: theme.palette.background.secondary,
    margin: "0 2px",
  },
};

let paymentCards = [
  {
    type: "visa",
    cardImg: <Visa />,
  },
  {
    type: "maestro",
    cardImg: <Maestro />,
  },
  {
    type: "mastercard",
    cardImg: <MasterCard />,
  },
  {
    type: "amex",
    cardImg: <AmericanExpress />,
  },
  {
    type: "discover",
    cardImg: <Discover />,
  },
];

interface Props {
  triggerClickPlan: boolean;
  closeTriggerState: () => void;
}

const TabBillingAndInvoices: React.FC<Props> = ({
  triggerClickPlan,
  closeTriggerState,
}) => {
  const { isLoading, subscriptions, invoices } = useSelector(
    getSubscriptionSelector
  );
  const organization = useSelector(getOrganizationSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sortOrder, setSortOrder] = useState("asc");
  const [openUpdatePlanModal, setOpenUpdatePlanModal] = useState(false);
  const [openCreditCardUpdate, setOpenCreditCardUpdate] = useState(false);
  const [openCancelSubscriptionModal, setOpenCancelSubscriptionModal] =
    useState(false);

  const [modalActionType, setModalActionType] = useState({
    action: "",
    page: "",
  });

  const isTrialValid =
    new Date().getTime() < parseTime(organization?.trialExpires).getTime();
  const subscriptionUsers =
    subscriptions?.[0]?.subscriptionUsersNumber || (organization?.trial && 10);

  const handleUpdatePlan = (type: string) => {
    setOpenUpdatePlanModal(true);
    setModalActionType((prevState) => ({
      ...prevState,
      action: type,
    }));
  };

  const handleUpdatePlanMobile = () => {
    navigate(AppRoutes.SettingsPlan);
  };

  const handleCreditCardUpdate = (type: string) => {
    setOpenCreditCardUpdate(true);
    setModalActionType((prevState) => ({
      ...prevState,
      action: type,
    }));
  };

  const handleCancelSubscription = () => {
    setOpenCancelSubscriptionModal(true);
    setModalActionType((prevState) => ({
      ...prevState,
      action: "Cancel",
      page: "Subscription",
    }));
  };

  const cancelSubscription = () => {
    dispatch(
      deleteSubscriptionRequest({
        onSuccess: () => {
          getSubscription();
          setOpenCancelSubscriptionModal(false);
        },
      })
    );
  };

  const getSubscription = () => {
    dispatch(getSubscriptionRequest());
  };

  useEffect(() => {
    getSubscription();
    dispatch(getInvoiceRequest());
  }, []);

  useEffect(() => {
    if (triggerClickPlan) {
      isMobile ? handleUpdatePlanMobile() : handleUpdatePlan("Choose a Plan");
      closeTriggerState();
    }
  }, [triggerClickPlan]);

  let cardType = subscriptions?.[0]?.paymentMethod?.type;
  const days =
    Math.abs(
      new Date().getTime() - parseTime(organization?.trialExpires).getTime()
    ) / 1000;

  if (isLoading)
    return (
      <Box data-sqa-id="circular-progress-loader" sx={styles.isLoading}>
        <CircularProgress />
      </Box>
    );

  return (
    <>
      <Box sx={styles.planContainer} mb={"50px"}>
        {!organization?.trial && subscriptions?.[0] && (
          <Box
            sx={styles.card}
            mr={{ xs: "0", md: "20px" }}
            mb={{ xs: "20px", md: "0" }}
            maxWidth={isMobile ? "100%" : "50%"}
          >
            <Typography
              fontSize={{ xs: "16px", md: "18px" }}
              fontWeight={500}
              color={theme.palette.text.primary}
              sx={{ wordBreak: "break-all" }}
            >
              {subscriptions?.[0]?.paymentMethod?.cardHolderName}
            </Typography>
            <Stack
              direction="row"
              spacing={"20px"}
              display="flex"
              alignItems={"center"}
              mt="15px"
              mb={Capacitor.getPlatform() === "ios" ? "0px" : "20px"}
              divider={
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{
                    height: "16px",
                    alignSelf: "center",
                  }}
                />
              }
            >
              <Box display="flex" alignItems="center">
                {paymentCards.map((el) => {
                  if (el.type === cardType) {
                    return (
                      <Box key="el" display="flex">
                        {el.cardImg}
                      </Box>
                    );
                  }
                })}
                <Box sx={styles.dots}>
                  <Box sx={styles.dot}></Box>
                  <Box sx={styles.dot}></Box>
                  <Box sx={styles.dot}></Box>
                  <Box sx={styles.dot}></Box>
                </Box>
                <Typography
                  color={theme.palette.text.primary}
                  fontSize={{ xs: "14px", md: "16px" }}
                >
                  {subscriptions?.[0]?.paymentMethod?.lastFour}
                </Typography>
              </Box>
              <Typography
                color={theme.palette.text.primary}
                fontSize={{ xs: "14px", md: "16px" }}
              >
                {`Expires ${subscriptions?.[0]?.paymentMethod?.expirationDate}`}
              </Typography>
            </Stack>
            {Capacitor.getPlatform() !== "ios" ? (
              <Box mt={"auto"} width={"100%"}>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  style={{ padding: "4px 40px" }}
                  fullWidth={isMobile ? true : false}
                  onClick={() => handleCreditCardUpdate("Update Credit Card")}
                >
                  <Typography fontSize={{ xs: "14px", md: "16px" }}>
                    Update Card
                  </Typography>
                </Button>
              </Box>
            ) : null}
          </Box>
        )}
        <Box
          sx={styles.card}
          minWidth={isMobile ? "100%" : "450px"}
          maxWidth={isMobile ? "100%" : "50%"}
        >
          <Typography
            fontSize={{ xs: "16px", md: "18px" }}
            fontWeight={500}
            color={theme.palette.text.primary}
          >
            {organization?.trial
              ? new Date().getTime() <
                parseTime(organization?.trialExpires).getTime()
                ? "Trial Period"
                : "Trial Period Exired"
              : "Paid Plan"}
          </Typography>
          <Box
            mt="15px"
            mb="20px"
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              columnGap: "8px",
            }}
          >
            {!organization?.trial && (
              <Box
                color={theme.palette.primary.main}
                sx={styles.significant}
                bgcolor={theme.palette.background.significantBlue}
              >
                <Typography fontSize={12} fontWeight={400} lineHeight={"20px"}>
                  {`${subscriptionUsers} ${
                    subscriptionUsers === 1 ? "user" : "users"
                  }`}
                </Typography>
              </Box>
            )}
            <Box
              color={
                organization?.trial
                  ? isTrialValid
                    ? "#262626"
                    : "#FF0000"
                  : subscriptions?.[0]?.status === "canceled"
                  ? "#FF0000"
                  : theme.palette.primary.main
              }
              sx={styles.significant}
              bgcolor={
                organization?.trial
                  ? isTrialValid
                    ? "#F5F5F5"
                    : "#FFEDED"
                  : subscriptions?.[0]?.status === "canceled"
                  ? "#FFEDED"
                  : theme.palette.background.significantBlue
              }
            >
              <Typography fontSize={12} fontWeight={400} lineHeight={"20px"}>
                {organization?.trial
                  ? isTrialValid
                    ? `Your trial will expire in ${Math.floor(
                        Math.abs(days) / 86400
                      )} days`
                    : "Your trial period expired. Please upgrade to the Paid Plan."
                  : subscriptions?.[0]?.status === "canceled" &&
                    new Date().getTime() <
                      parseTime(subscriptions?.[0]?.currentPeriodEnd).getTime()
                  ? `Will be cancelled on ${format(
                      parseTime(subscriptions?.[0]?.currentPeriodEnd),
                      "MMMM do, y"
                    )}`
                  : subscriptions?.[0]?.status === "canceled" &&
                    new Date().getTime() >
                      parseTime(subscriptions?.[0]?.currentPeriodEnd).getTime()
                  ? "Cancelled. Use the Update Plan to activate subscription."
                  : `Renews
                ${
                  subscriptions?.[0]?.currentPeriodEnd &&
                  format(
                    parseTime(subscriptions?.[0]?.currentPeriodEnd),
                    "MMMM do, y"
                  )
                }`}
              </Typography>
            </Box>
          </Box>
          {Capacitor.getPlatform() === "ios" ? null : (
            <Box mt={"auto"} sx={styles.paidPlanAction}>
              <Button
                variant="contained"
                size="small"
                color="primary"
                style={{ padding: "4px 40px" }}
                onClick={() => {
                  isMobile
                    ? handleUpdatePlanMobile()
                    : handleUpdatePlan(
                        organization?.trial ? "Choose a Plan" : "Update Plan"
                      );
                }}
              >
                <Typography fontSize={{ xs: "14px", md: "16px" }}>
                  {organization?.trial ? "Upgrade to Paid Plan" : "Update Plan"}
                </Typography>
              </Button>
              {!organization?.trial &&
                subscriptions?.[0]?.status !== "canceled" && (
                  <Button
                    variant="outlined"
                    size="small"
                    color="secondary"
                    style={{
                      padding: "4px 40px",
                    }}
                    onClick={handleCancelSubscription}
                  >
                    <Typography
                      color={theme.palette.text.primary}
                      fontSize={{ xs: "14px", md: "16px" }}
                    >
                      Cancel Subscription
                    </Typography>
                  </Button>
                )}
            </Box>
          )}
        </Box>
      </Box>
      <Box>
        {invoices.length > 0 ? (
          <Typography
            fontSize={{ xs: "20px", md: "32px" }}
            fontWeight={{ xs: 600, md: 500 }}
            color={theme.palette.text.primary}
            mb={"20px"}
          >
            Invoices
          </Typography>
        ) : null}
        {isMobile ? (
          invoices.map((invoice) => (
            <Box key={invoice.id} sx={[styles.card, styles.invoicesCard]}>
              <Box>
                <Typography fontSize={14} color={theme.palette.text.secondary}>
                  Description
                </Typography>
                <Typography fontSize={14} color={theme.palette.text.primary}>
                  {invoice.invoiceStatus.charAt(0).toUpperCase() +
                    invoice.invoiceStatus.slice(1)}{" "}
                  Plan, {invoice.subscriptionUsersNumber} users (
                  {invoice.pricePerUser}$/user) {invoice.subscriptionPeriod}{" "}
                  subscription
                </Typography>
              </Box>
              <Divider variant="middle" />
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography fontSize={14} color={theme.palette.text.secondary}>
                  Date
                </Typography>
                <Typography fontSize={14} color={theme.palette.text.primary}>
                  {format(parseTime(invoice.createdAt), "MMMM d, y")}
                </Typography>
              </Box>
              <Divider variant="middle" />
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography fontSize={14} color={theme.palette.text.secondary}>
                  Invoice Total
                </Typography>
                <Typography fontSize={14} color={theme.palette.text.primary}>
                  ${Number(invoice.total).toFixed(2)}
                </Typography>
              </Box>
              <Divider variant="middle" />
              <Button
                variant="outlined"
                size="small"
                color="secondary"
                fullWidth
                style={{
                  padding: "4px 40px",
                }}
                mt="6px"
                onClick={() => window.open(invoice.invoicePdf, "_blank")}
              >
                <Typography color={theme.palette.text.primary} fontSize={14}>
                  View Invoice
                </Typography>
              </Button>
            </Box>
          ))
        ) : (
          <>
            <InvoicesTable
              allInvoice={invoices}
              setSortOrder={setSortOrder}
              sortBy={"Date"}
              sortOrder={sortOrder}
            />
          </>
        )}
      </Box>
      {openUpdatePlanModal && (
        <ChoosePlan
          modalActionType={modalActionType}
          setModalActionType={setModalActionType}
          isOpen={openUpdatePlanModal}
          closeModal={() => setOpenUpdatePlanModal(false)}
        />
      )}
      {openCreditCardUpdate && (
        <CreditCardUpdate
          isOpen={openCreditCardUpdate}
          modalActionType={modalActionType}
          closeModal={() => setOpenCreditCardUpdate(false)}
        />
      )}
      {openCancelSubscriptionModal && (
        <CancelSubscription
          isOpen={openCancelSubscriptionModal}
          modalActionType={modalActionType}
          fetchRequest={cancelSubscription}
          closeModal={() => setOpenCancelSubscriptionModal(false)}
        />
      )}
    </>
  );
};

export default TabBillingAndInvoices;
