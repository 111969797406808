import * as React from "react";
import { SVGProps } from "react";

interface Props {
  color?: string;
  size?: string;
}

const ProjectIcon: React.FC<Props> = (
  { color, size },
  props: SVGProps<SVGSVGElement>
) => (
  <svg
    width={size ? size : 16}
    height={size ? size : 16}
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M13.334 4.667H2.667c-.737 0-1.333.596-1.333 1.333v6.666c0 .737.596 1.334 1.333 1.334h10.666c.737 0 1.334-.597 1.334-1.334V6c0-.737-.597-1.333-1.333-1.333Z"
      stroke={color ? color : "currentColor"}
      strokeWidth={1.3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.667 14V3.333A1.333 1.333 0 0 0 9.334 2H6.667a1.333 1.333 0 0 0-1.333 1.333V14"
      stroke={color ? color : "currentColor"}
      strokeWidth={1.3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ProjectIcon;
