import {
  CLEAN_ERROR,
  CONTACT_US_FAILURE,
  CONTACT_US_SUCCESS,
  DELETE_SUBSCRIPTION_FAILURE,
  GET_INVOICE_FAILURE,
  GET_INVOICE_ID_FAILURE,
  GET_INVOICE_ID_SUCCESS,
  GET_INVOICE_REQUEST,
  GET_INVOICE_SUCCESS,
  GET_SUBSCRIPTION_FAILURE,
  GET_SUBSCRIPTION_REQUEST,
  GET_SUBSCRIPTION_SUCCESS,
  START_SUBSCRIPTION_FAILURE,
  START_SUBSCRIPTION_REQUEST,
  START_SUBSCRIPTION_SUCCESS,
  UPDATE_PAYMENT_REQUEST,
  UPDATE_PAYMENT_FAILURE,
  UPDATE_PAYMENT_SUCCESS,
  UPDATE_SUBSCRIPTION_FAILURE,
  UPDATE_SUBSCRIPTION_REQUEST,
  UPDATE_SUBSCRIPTION_SUCCESS,
} from "./actionTypes";
import { AuthActions, SubscriptionState } from "./types";

const initialState: SubscriptionState = {
  isLoading: false,
  isLoadingSubscription: false,
  isLoadingPaymentUpdate: false,
  invoices: [],
  invoice: null,
  subscriptions: null,
  subscription: null,
  error: null,
};

export default (state = initialState, action: AuthActions) => {
  switch (action.type) {
    case START_SUBSCRIPTION_REQUEST:
    case UPDATE_SUBSCRIPTION_REQUEST: {
      return {
        ...state,
        isLoadingSubscription: true,
      };
    }
    case CONTACT_US_SUCCESS: {
      return {
        ...state,
        error: null,
        ...action.payload,
      };
    }
    case CONTACT_US_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
      };
    }
    case GET_INVOICE_REQUEST:
    case GET_SUBSCRIPTION_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case GET_INVOICE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        ...action.payload.invoices,
      };
    }
    case GET_INVOICE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    case GET_INVOICE_ID_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        invoice: action.payload.invoice,
      };
    }
    case GET_INVOICE_ID_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    case GET_SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        ...action.payload.subscriptions,
      };
    }
    case GET_SUBSCRIPTION_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    case START_SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        isLoadingSubscription: false,
        error: null,
        subscription: action.payload.subscription,
      };
    }
    case START_SUBSCRIPTION_FAILURE: {
      return {
        ...state,
        isLoadingSubscription: false,
        error: action.payload.error,
      };
    }
    case UPDATE_SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        isLoadingSubscription: false,
        error: null,
        subscription: action.payload.subscription,
      };
    }
    case UPDATE_SUBSCRIPTION_FAILURE: {
      return {
        ...state,
        isLoadingSubscription: false,
        error: action.payload.error,
      };
    }
    case DELETE_SUBSCRIPTION_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
      };
    }
    case UPDATE_PAYMENT_REQUEST: {
      return {
        ...state,
        isLoadingPaymentUpdate: true,
      };
    }
    case UPDATE_PAYMENT_SUCCESS: {
      return {
        ...state,
        error: null,
        isLoadingPaymentUpdate: false,
        subscription: action.payload.subscription,
      };
    }
    case UPDATE_PAYMENT_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        isLoadingPaymentUpdate: false,
      };
    }
    case CLEAN_ERROR: {
      return {
        ...state,
        error: null,
      };
    }
    default:
      return state;
  }
};
