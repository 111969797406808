import React, { useEffect, useState } from "react";
import ModalWrapper from "./ModalWrapper";
import PaidPlans from "../PaidPlans";
import { Box, Typography } from "@mui/material";
import Button from "../Button";
import { Lock as LockIcon } from "src/assets/icons";
import theme from "src/theme";
import ContactUsForm from "../ContactUsForm";
import PaidPlanUpgrade from "./PaidPlanUpgrade";
import { useSelector } from "react-redux";
import { getSubscriptionSelector } from "src/store/subscription/selectors";
import { isMobile } from "mobile-device-detect";

interface ModalProps {
  closeModal: () => void;
  setModalActionType?: any;
  isOpen?: boolean;
  modalActionType: {
    action: string;
    page: string;
  };
}

const ChoosePlan: React.FC<ModalProps> = ({
  isOpen,
  modalActionType,
  setModalActionType,
  closeModal,
}) => {
  const subscription = useSelector(getSubscriptionSelector);
  const [user, setUser] = useState(
    subscription.subscriptions?.[0]?.subscriptionUsersNumber
      ? subscription.subscriptions?.[0]?.subscriptionUsersNumber
      : 1
  );

  useEffect(() => {
    if (
      sessionStorage.getItem("upgradePlan") === "true" ||
      subscription.subscriptions?.[0]?.status === "canceled"
    ) {
      setUser(1);
    }
  }, [0]);

  return (
    <>
      <ModalWrapper
        closeModal={closeModal}
        isOpen={isOpen}
        modalActionType={modalActionType}
        maxWidth={isMobile ? "350px" : "580px"}
      >
        <>
          {(modalActionType.action === "Choose a Plan" ||
            modalActionType.action === "Update Plan") && (
            <>
              <PaidPlans
                closeModal={closeModal}
                setModalActionType={setModalActionType}
                user={user}
                setUser={setUser}
              />
              <Box
                display={"flex"}
                alignItems="center"
                justifyContent={"space-between"}
                mt={"20px"}
              >
                <Box display={"flex"} ml={"6px"} alignItems="center">
                  <LockIcon
                    color={theme.palette.text.secondary}
                    width={16}
                    height={16}
                  />
                  <Typography
                    color={theme.palette.text.secondary}
                    fontSize={{ xs: "12px", md: "14px" }}
                    ml={"6px"}
                  >
                    Secure Payment.
                  </Typography>
                </Box>
                <Button
                  variant="outlined"
                  size="small"
                  color="secondary"
                  style={{ padding: "4px 63px" }}
                  onClick={closeModal}
                >
                  <Typography color={theme.palette.common.black}>
                    Close
                  </Typography>
                </Button>
              </Box>
            </>
          )}
          {modalActionType.action === "Contact Us" && (
            <ContactUsForm closeModal={closeModal} />
          )}
          {modalActionType.action === "Paid Plan Purchase" && (
            <PaidPlanUpgrade user={user} closeModal={closeModal} />
          )}
        </>
      </ModalWrapper>
    </>
  );
};

export default ChoosePlan;
