import { isMobile } from "mobile-device-detect";

export const styles = {
  boxContainer: {
    display: "flex",
    flexDirection: "column",
    border: "1px solid #F0F0F0",
    backgroundColor: "#fff",
    borderRadius: "4px",
    padding: "24px 22px",
  },
  topContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  pageTitle: {
    fontSize: "32px",
    fontWeight: 500,
    color: "#000",
  },
  moreContainer: {
    backgroundColor: "#fff",
    borderRadius: "4px",
    border: "1px solid #F0F0F0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 30px",
    height: "44px",
  },
  moreText: {
    fontSize: "16px",
    fontWeight: 400,
    color: "#8C8C8C",
  },
  moreButton: {
    fontSize: "16px",
    fontWeight: 400,
    color: "#0D6FF0",
    background: "transparent",
    textDecoration: "none",
  },
  teamContainer: {
    marginTop: "30px",
    padding: "30px 20px",
    backgroundColor: "#fff",
    borderRadius: "4px",
    border: "1px solid #F0F0F0",
  },
  input: {
    marginTop: 0,
    width: isMobile ? "100%" : "300px",
  },
  addButtonContainer: {
    display: "flex",
    alignItems: "center",
  },
  addButtonText: {
    fontWeight: 500,
    ml: "10px",
    textTransform: "initial",
  },
  avatar: {
    width: "54px",
    height: "54px",
  },
  boxBlock: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#ECF3FE",
    borderRadius: "4px",
    padding: "5px 10px",
  },
  pagination: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  noData: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: isMobile ? "140px" : "340px",
    backgroundColor: "#F0F0F0",
    borderRadius: "4px",
  },
  noDataText: {
    fontWeight: 400,
    textAlign: "center",
    fontSize: isMobile ? "14px" : "18px",
    color: "#8C8C8C",
  },
};
