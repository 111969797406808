import { createSelector } from "reselect";

import { AppState } from "../rootReducer";

const getComments = (state: AppState) => state.comments;

export const getCommentsSelector = createSelector(
  getComments,
  (comment) => comment
);
