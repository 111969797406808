import axios from "../../utils/axios";
import { StartWatchRequestPayload, StopWatchRequestPayload } from "./types";

export const requestStartWatch = (payload: StartWatchRequestPayload) => {
  return axios.post("/timeClock/clockIn", payload);
};

export const requestCheckStartWatch = () => {
  return axios.get("/timeClock/isClockedIn");
};

export const requestStopWatch = (payload: StopWatchRequestPayload) => {
  return axios.post("/timeClock/clockOut", payload);
};
