import * as React from "react";
import { SVGProps } from "react";

const EditIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" {...props}>
    <g
      clipPath="url(#a)"
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M9.166 3.333H3.333A1.667 1.667 0 0 0 1.666 5v11.667a1.667 1.667 0 0 0 1.667 1.666H15a1.666 1.666 0 0 0 1.666-1.666v-5.834" />
      <path d="M15.416 2.083a1.768 1.768 0 0 1 2.5 2.5L10 12.5l-3.333.833L7.5 10l7.916-7.917Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default EditIcon;
