import {
  Box,
  Typography,
  Button as MuiButton,
  CircularProgress,
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Comments, ProjectIcon, ThreeDots } from "src/assets/icons";
import { Button } from "src/components";
import { styles } from "../../../pages/AdminView/Projects/projects.module";
import { getProjectSelector } from "src/store/projects/selectors";
import { Project } from "src/types";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import Actions from "src/components/ActionsModal/Actions";
import { AppRoutes } from "src/constants";
import { useNavigate } from "react-router-dom";

interface Props {
  pageSize: number;
  setPage: (page: any) => void;
  fetchSuccess: () => void;
}

const SubProjectsMobile: React.FC<Props> = ({
  pageSize,
  setPage,
  fetchSuccess,
}) => {
  const allProjects = useSelector(getProjectSelector);
  const navigate = useNavigate();
  const [openActionModal, setOpenActionModal] = useState<boolean>(false);
  const [elem, setElem] = useState<Project>(null);
  const [modalActionType, setModalActionType] = useState({
    action: "",
    page: "",
  });

  const openModal = (elem: Project, action: string) => {
    setOpenActionModal(true);
    setElem(elem);
    setModalActionType((prevState) => ({
      ...prevState,
      action,
      page: "",
    }));
  };

  return (
    <>
      {allProjects.projects.map((project: Project) => (
        <Box
          mb="20px"
          sx={styles.boxContainer}
          key={project.id}
          onClick={() => {
            navigate(AppRoutes.ProjectItemMobile + `/${project.id}`);
            sessionStorage.setItem("activeTab", "0");
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            columnGap="10px"
          >
            <Box>
              <Typography mb="5px" fontSize="12px" color="#8C8C8C">
                Project ID {project.numericId}
              </Typography>
              <Typography color="#262626">{project.projectName}</Typography>
              <Box
                mt="10px"
                display="flex"
                alignItems="center"
                columnGap="10px"
              >
                <Box
                  sx={
                    project.projectCountV.subProjectCount === 0
                      ? styles.emptyContainer
                      : styles.countContainer
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(AppRoutes.ProjectItemMobile + `/${project.id}`);
                    sessionStorage.setItem("activeTab", "1");
                  }}
                >
                  <ProjectIcon
                    size="16px"
                    color={
                      project.projectCountV.subProjectCount === 0
                        ? "#8C8C8C"
                        : "#0D6FF0"
                    }
                  />
                  <Typography
                    ml="6px"
                    fontSize="12px"
                    color={
                      project.projectCountV.subProjectCount === 0
                        ? "#8C8C8C"
                        : "#0D6FF0"
                    }
                  >
                    {project.projectCountV.subProjectCount}
                  </Typography>
                </Box>
                <Box
                  sx={
                    project.projectCountV.commentsCount === 0
                      ? styles.emptyContainer
                      : styles.countContainer
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(AppRoutes.ProjectItemMobile + `/${project.id}`);
                    sessionStorage.setItem("activeTab", "2");
                  }}
                >
                  <Comments
                    size={16}
                    color={
                      project.projectCountV.commentsCount === 0
                        ? "#8C8C8C"
                        : "#0D6FF0"
                    }
                  />
                  <Typography
                    ml="6px"
                    fontSize="12px"
                    color={
                      project.projectCountV.commentsCount === 0
                        ? "#8C8C8C"
                        : "#0D6FF0"
                    }
                  >
                    {project.projectCountV.commentsCount}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <MuiButton
              sx={styles.threeDots}
              onClick={(e) => {
                e.stopPropagation();
                openModal(project, "Actions");
              }}
            >
              <ThreeDots color="#000" />
            </MuiButton>
          </Box>
          {project.address && (
            <Box display="flex" alignItems="center" mt="20px">
              <Box mr="10px">
                <LocationOnOutlinedIcon color="primary" />
              </Box>
              <Typography fontSize="14px" color="#262626">
                {`${project.address.address ? project.address.address : ""}${
                  project.address.state || project.address.zip ? "," : ""
                } ${project.address.state ? project.address.state : ""} ${
                  project.address.zip ? project.address.zip : ""
                }`}
              </Typography>
            </Box>
          )}
        </Box>
      ))}
      {allProjects.isLoadingSubproject ? (
        <Box
          data-sqa-id="circular-progress-loader"
          display="flex"
          alignItems="center"
          justifyContent="center"
          mb="10px"
        >
          <CircularProgress />
        </Box>
      ) : (
        pageSize < allProjects.totalPages * pageSize && (
          <Button
            data-sqa-id="load-more-btn"
            variant="contained"
            fullWidth
            style={{
              border: "1px solid #0D6FF0",
              backgroundColor: "transparent",
              boxShadow: "none",
            }}
            onClick={() => setPage((prevState) => prevState + 1)}
          >
            <Box sx={styles.addButtonContainer}>
              <Typography data-sqa-id="load-more-btn-text" color="#0D6FF0">
                Load More
              </Typography>
            </Box>
          </Button>
        )
      )}

      {openActionModal && (
        <Actions
          modalActionType={modalActionType}
          elem={elem}
          type="View"
          isOpen={openActionModal}
          closeModal={() => setOpenActionModal(false)}
          fetchSuccess={fetchSuccess}
          setModalActionType={setModalActionType}
        />
      )}
    </>
  );
};

export default SubProjectsMobile;
