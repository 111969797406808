import React from "react";
import { isMobile } from "mobile-device-detect";
import RoutingMobile from "./RoutingMobile";
import RoutingWeb from "./RoutingWeb";

function App() {
  return isMobile ? <RoutingMobile /> : <RoutingWeb />;
}

export default App;
