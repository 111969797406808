import { Box, Button, Theme, Typography } from "@mui/material";
import { format } from "date-fns";
import { isMobile } from "mobile-device-detect";
import React, { useMemo, useState } from "react";
import {
  DeleteForWeb,
  Edit,
  Link,
  ThreeDots,
  ViewForWeb,
} from "src/assets/icons";
import { ClockRecord, WeeklyRecord } from "src/types";
import { secondsToTime, parseTime } from "src/utils";
import UpdateIcon from "@mui/icons-material/Update";

const styles = {
  tabsDataItem: {
    borderBottom: (theme: Theme) =>
      `1px solid ${theme.palette.background.default}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    py: "10px",
    "&:last-child": {
      borderBottom: (theme: Theme) =>
        isMobile ? `1px solid ${theme.palette.background.default}` : "none",
    },
  },
  tabsDataTime: {
    width: isMobile ? "100px" : "140px",
    flexShrink: 0,
    color: (theme: Theme) => `${theme.palette.text.primary}`,
    fontSize: isMobile ? "16px" : "18px",
    fontWeight: 600,
  },
  tabsProjectName: {
    color: (theme: Theme) => `${theme.palette.text.secondary}`,
    fontSize: isMobile ? "12px" : "16px",
    fontWeight: 500,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  boxTime: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tabsDataPeriod: {
    color: (theme: Theme) => `${theme.palette.text.secondary}`,
    fontSize: isMobile ? "14px" : "16px",
    fontWeight: 500,
    width: "100%",
    maxWidth: isMobile ? "auto" : "160px",
    flexShrink: "0",
  },
  tabsDataButton: {
    backgroundColor: (theme: Theme) => `${theme.palette.background.paper}`,
    border: (theme: Theme) =>
      isMobile ? `1px solid ${theme.palette.background.default}` : "none",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "44px",
    minHeight: "44px",
    "&:hover": {
      background: "none",
    },
  },
  wrapProjectPeriod: {
    width: "100%",
    maxWidth: { xs: "140px", lg: "38%", xl: "60%" },
    display: "flex",
    flexDirection: { xs: "column", xl: "row" },
    mx: "6px",
    marginRight: "auto",
    paddingRight: "6px",
  },
};

interface Props {
  clock?: ClockRecord;
  weekclock?: WeeklyRecord;
  onClick?: () => void;
  onLink?: () => void;
  onEdit?: () => void;
  onView?: () => void;
  onDelete?: () => void;
  currentTab: number;
  isHistoryView: boolean;
}

const TabDataItem: React.FC<Props> = ({
  clock,
  weekclock,
  onClick,
  onLink,
  onEdit,
  onView,
  onDelete,
  currentTab,
  isHistoryView,
}) => {
  const [hover, setHover] = useState<string>("");
  let periodWorked = "";
  if (currentTab === 0) {
    periodWorked = useMemo(() => {
      const startUtc = parseTime(clock.startTime);
      const start = format(startUtc, "p");

      let end: any;
      if (clock.endTime !== null) {
        const endUtc = parseTime(clock.endTime);
        end = format(endUtc, "p");
      } else {
        end = "Still Working";
      }
      return `${start} - ${end}`;
    }, [clock]);
  }
  return (
    <Box data-sqa-id="time-clock-item" sx={styles.tabsDataItem}>
      {currentTab === 0 ? (
        <>
          <Box
            sx={[
              styles.boxTime,
              isHistoryView && {
                flexDirection: "column",
                alignItems: "flex-start",
              },
            ]}
          >
            {clock?.timeWorked ? (
              <Typography data-sqa-id="time-worked" sx={styles.tabsDataTime}>
                {secondsToTime(clock.timeWorked)}
              </Typography>
            ) : (
              <Box data-sqa-id="time-worked" sx={styles.tabsDataTime}>
                <UpdateIcon />
              </Box>
            )}
          </Box>
          <Box sx={styles.wrapProjectPeriod}>
            <Typography
              data-sqa-id="time-clock-period"
              sx={[styles.tabsDataPeriod, isHistoryView && { margin: 0 }]}
            >
              {periodWorked}
            </Typography>
            <Typography data-sqa-id="project-name" sx={styles.tabsProjectName}>
              {clock?.project?.projectName}
            </Typography>
          </Box>
        </>
      ) : (
        <>
          <Box sx={styles.boxTime}>
            <Typography
              data-sqa-id="total-worked-for-week-day"
              sx={styles.tabsDataTime}
            >
              {secondsToTime(weekclock.totalWorkedInSecond)}
            </Typography>
          </Box>
          <Box>
            <Typography data-sqa-id="weekday" sx={styles.tabsDataPeriod}>
              {format(parseTime(weekclock.day), "E, MMMM d")}
            </Typography>
          </Box>
        </>
      )}
      {isMobile ? (
        <Button onClick={onClick} sx={styles.tabsDataButton}>
          <ThreeDots />
        </Button>
      ) : (
        <Box display="flex" alignItems="center">
          <Button
            data-sqa-id="link-to-project-btn"
            onClick={onLink}
            sx={styles.tabsDataButton}
            onMouseEnter={() => setHover("link")}
            onMouseLeave={() => setHover("")}
            disableRipple
          >
            <Link color={hover === "link" ? "#0D6FF0" : "#8C8C8C"} />
          </Button>
          {currentTab === 0 && (
            <Button
              data-sqa-id="edit-time-clock-btn"
              onClick={onEdit}
              disabled={!clock.endTime}
              sx={styles.tabsDataButton}
              onMouseEnter={() => setHover("edit")}
              onMouseLeave={() => setHover("")}
              disableRipple
            >
              <Edit
                color={
                  hover === "edit"
                    ? "#0D6FF0"
                    : !clock.endTime
                    ? "#D9D9D9"
                    : "#8C8C8C"
                }
              />
            </Button>
          )}
          {currentTab === 1 && (
            <Button
              onClick={onView}
              sx={styles.tabsDataButton}
              onMouseEnter={() => setHover("view")}
              onMouseLeave={() => setHover("")}
              disableRipple
            >
              <ViewForWeb color={hover === "view" ? "#0D6FF0" : "#8C8C8C"} />
            </Button>
          )}
          <Button
            data-sqa-id="delete-time-clock-btn"
            onClick={onDelete}
            sx={styles.tabsDataButton}
            onMouseEnter={() => setHover("delete")}
            onMouseLeave={() => setHover("")}
            disableRipple
          >
            <DeleteForWeb color={hover === "delete" ? "#FF0003" : "#FF4D4F"} />
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default TabDataItem;
