import { createSelector } from "reselect";

import { AppState } from "../rootReducer";

const getSubscription = (state: AppState) => state.subscription;

export const getSubscriptionSelector = createSelector(
  getSubscription,
  (subscription) => subscription
);
