import {
  Box,
  Checkbox,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  DeleteForWeb,
  EditForWeb,
  Link,
  PlusForWeb,
  Location,
  CheckBoxOutlineBlank,
  CheckBox,
  CheckBoxIndeterminate,
} from "src/assets/icons";
import { getTodaysClocksSelector } from "src/store/clocks/selectors";
import Button from "../../Button";
import dailyEntries from "../DailyEntriesTable.json";
import { formatDate, secondsToTime, parseTime } from "src/utils";
import ModalTodaysClockRecord from "../../ModalTodaysClockRecord";
import { styles } from "./dailyEntriesTable.module";
import { format } from "date-fns";
import { getOrganizationSelector } from "src/store/auth/selectors";

interface Props {
  fetchEntries: () => void;
  date: string;
  user: any;
}

const DailyEntriesTable: React.FC<Props> = ({ fetchEntries, date, user }) => {
  const clocksState = useSelector(getTodaysClocksSelector);
  const organization = useSelector(getOrganizationSelector);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItemsForDelete, setSelectedItemsForDelete] = useState([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [elem, setElem] = useState<any>();
  const [modalsState, setModalsState] = useState<string>("");
  const [hover, setHover] = useState("");

  const handleOpenModal = (modalState: string, data?: any) => {
    setElem(data);
    setOpenModal(true);
    setModalsState(modalState);
  };

  const handleSelectAllItems = (event) => {
    setSelectedItems(
      event.target.checked
        ? clocksState.todaysClocks.timeClockRecords.map((elem) => elem.id)
        : []
    );
    setSelectedItemsForDelete(
      event.target.checked
        ? clocksState.todaysClocks.timeClockRecords.map((elem) => {
            return {
              id: elem.id,
              endTime: elem.endTime,
            };
          })
        : []
    );
  };

  const handleSelectOneItem = (event, itemId, endTime?: string) => {
    if (!selectedItems.includes(itemId)) {
      setSelectedItems((prevSelected) => [...prevSelected, itemId]);
      setSelectedItemsForDelete((prevSelected) => [
        ...prevSelected,
        {
          id: itemId,
          endTime,
        },
      ]);
    } else {
      setSelectedItems((prevSelected) =>
        prevSelected.filter((id) => id !== itemId)
      );
      setSelectedItemsForDelete((prevSelected) =>
        prevSelected.filter((item) => item.id !== itemId)
      );
    }
  };

  const selectedSomeItems =
    selectedItems.length > 0 &&
    selectedItems.length < clocksState.todaysClocks.timeClockRecords.length;
  const selectedAllItems =
    selectedItems.length === clocksState.todaysClocks?.timeClockRecords?.length;

  const chooseType = (elem, item) => {
    switch (elem.type) {
      case "time":
        return `${format(parseTime(item[elem.array[0]]), "p")} - ${
          item[elem.array[1]]
            ? format(parseTime(item[elem.array[1]]), "p")
            : "Still Working"
        }`;
      case "timeWorked":
        return secondsToTime(item[elem.array[0]]);
      case "project":
        return item[elem.array[0]] ? (
          <Box data-sqa-id="project-info">
            <Typography
              data-sqa-id="project-id"
              fontSize="12px"
              fontWeight={400}
              color="#8C8C8C"
            >
              Project ID {item[elem.array[0]]?.numericId}
            </Typography>
            <Typography data-sqa-id="project-text" sx={styles.textStyle}>
              {item[elem.array[0]]?.projectName}
            </Typography>
          </Box>
        ) : (
          ""
        );
      case "actions":
        return (
          <Box data-sqa-id="time-clock-btn-box" sx={styles.boxActionsWrapper}>
            <Box
              data-sqa-id="time-clock-location-btn"
              mr="20px"
              onMouseEnter={() => setHover("location" + item.id)}
              onMouseLeave={() => setHover("")}
              onClick={() => handleOpenModal("VIEW_LOCATION_MODAL", item)}
            >
              <Location
                color={hover === "location" + item.id ? "#0D6FF0" : "#8C8C8C"}
              />
            </Box>
            <Box
              data-sqa-id="time-clock-edit-btn"
              mr="20px"
              onMouseEnter={() => setHover("view" + item.id)}
              onMouseLeave={() => setHover("")}
              onClick={() => {
                if (item.endTime) handleOpenModal("EDIT_ENTRY_MODAL", item);
              }}
            >
              <EditForWeb
                color={
                  !item.endTime
                    ? "#D9D9D9"
                    : hover === "view" + item.id
                    ? "#0D6FF0"
                    : "#8C8C8C"
                }
              />
            </Box>
            <Box
              data-sqa-id="time-clock-delete-btn"
              onMouseEnter={() => setHover("delete" + item.id)}
              onMouseLeave={() => setHover("")}
              onClick={() => {
                handleOpenModal("DELETE_ENTRY_MODAL", item);
              }}
            >
              <DeleteForWeb
                color={hover === "delete" + item.id ? "#FF0003" : "#FF4D4F"}
              />
            </Box>
          </Box>
        );
    }
  };

  return (
    <>
      <Box data-sqa-id="buttons-box" sx={styles.boxContainer}>
        <Box data-sqa-id="delete-btn-box">
          <Button
            data-sqa-id="delete-btn"
            variant="contained"
            color="error"
            disabled={selectedItems.length === 0}
            fullWidth
            style={{ height: "46px" }}
            onClick={() => {
              handleOpenModal("DELETE_ENTRY_MODAL", selectedItemsForDelete);
            }}
          >
            <DeleteForWeb />
            <Typography
              data-sqa-id="delete-btn-text"
              ml="10px"
              fontSize="16px"
              fontWeight={400}
            >
              Delete
            </Typography>
          </Button>
        </Box>
        <Box data-sqa-id="link-to-project-btn-box">
          <Button
            data-sqa-id="link-to-project-btn"
            variant="contained"
            disabled={selectedItems.length === 0}
            fullWidth
            style={{ height: "46px" }}
            onClick={() => {
              handleOpenModal("LINK_ENTRY_MODAL", selectedItems);
            }}
          >
            <Link />
            <Typography
              data-sqa-id="link-to-project-btn-text"
              ml="10px"
              fontSize="16px"
              fontWeight={400}
            >
              Link to Project
            </Typography>
          </Button>
        </Box>
        <Box data-sqa-id="manual-entry-box">
          <Button
            data-sqa-id="manual-entry-btn"
            variant="contained"
            color="primary"
            fullWidth
            style={{ height: "46px" }}
            disabled={
              organization?.trial &&
              new Date().getTime() >
                parseTime(organization?.trialExpires).getTime()
            }
            onClick={() => {
              handleOpenModal("MANUAL_ENTRY_MODAL", formatDate(date));
            }}
          >
            <PlusForWeb
              color={
                organization?.trial &&
                new Date().getTime() >
                  parseTime(organization?.trialExpires).getTime()
                  ? "#d9d9d9"
                  : "#FFF"
              }
            />
            <Typography
              data-sqa-id="manual-entry-btn-text"
              ml="10px"
              fontSize="16px"
              fontWeight={400}
              color="#FFF"
            >
              Manual Entry
            </Typography>
          </Button>
        </Box>
      </Box>
      {clocksState.isLoading ? (
        <Box
          data-sqa-id="circulat-progress-loader"
          display="flex"
          alignItems="center"
          justifyContent="center"
          mt="20px"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <TableContainer>
            <Table data-sqa-id="daily-entries-table">
              <TableHead id="tableHeader" sx={{ backgroundColor: "#FAFAFA" }}>
                <TableRow id="tableHeaderRow" sx={styles.tableRow}>
                  <TableCell
                    data-sqa-id="checkbox-select-header-cell"
                    sx={[
                      styles.lineTable,
                      { borderBottom: "none", position: "relative" },
                    ]}
                  >
                    <Checkbox
                      checked={selectedAllItems}
                      indeterminate={selectedSomeItems}
                      onChange={handleSelectAllItems}
                      sx={styles.checkbox}
                      icon={
                        <CheckBoxOutlineBlank
                          color="#D9D9D9"
                          width={20}
                          height={20}
                        />
                      }
                      checkedIcon={<CheckBox width={20} height={20} />}
                      indeterminateIcon={
                        <CheckBoxIndeterminate width={20} height={20} />
                      }
                    />
                  </TableCell>
                  {dailyEntries[0]?.label?.map((elem: string) => (
                    <TableCell
                      data-sqa-id="header-cell"
                      key={elem}
                      sx={styles.lineTable}
                    >
                      {elem}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody sx={{ backgroundColor: "#FFF" }}>
                {clocksState.todaysClocks?.timeClockRecords?.map(
                  (timeRecordItem) => {
                    const isItemSelected = selectedItems.includes(
                      timeRecordItem.id
                    );
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        data-sqa-id="table-row"
                        tabIndex={-1}
                        key={timeRecordItem.id}
                        sx={styles.tableRow}
                      >
                        <TableCell
                          data-sqa-id="checkbox-table-cell"
                          sx={{ borderBottom: "none" }}
                        >
                          <Checkbox
                            checked={isItemSelected}
                            onChange={(event) =>
                              handleSelectOneItem(
                                event,
                                timeRecordItem.id,
                                timeRecordItem.endTime
                              )
                            }
                            sx={styles.checkbox}
                            icon={
                              <CheckBoxOutlineBlank
                                color="#D9D9D9"
                                width={20}
                                height={20}
                              />
                            }
                            checkedIcon={<CheckBox width={20} height={20} />}
                          />
                        </TableCell>
                        {dailyEntries[1]?.data?.map((elem, i) => {
                          return (
                            <TableCell
                              data-sqa-id={elem.type + "-table-cell"}
                              key={i}
                              sx={styles.tableCell}
                            >
                              {chooseType(elem, timeRecordItem)}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            data-sqa-id="daily-total-box"
            p="30px 16px"
            display="flex"
            justifyContent="space-between"
            borderBottom="1px solid #F0F0F0"
          >
            <Typography
              data-sqa-id="daily-total-label"
              id="dailyTotal"
              fontSize="16px"
              fontWeight={600}
              color="#262626"
            >
              Daily Total
            </Typography>
            <Typography
              data-sqa-id="daily-total-time-worked"
              fontSize="16px"
              fontWeight={600}
              color="#0D6FF0"
            >
              {secondsToTime(clocksState.todaysClocks.dayTotal)}
            </Typography>
          </Box>
        </>
      )}

      {openModal && (
        <ModalTodaysClockRecord
          onFetchSuccess={fetchEntries}
          closeModal={() => setOpenModal(false)}
          isOpen={openModal}
          modalData={elem}
          modalsState={modalsState}
          setModalsState={setModalsState}
          currentTab={0}
          user={user}
        />
      )}
    </>
  );
};

export default DailyEntriesTable;
