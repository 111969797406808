import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { styles } from "./history.module";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import { AppRoutes } from "src/constants";
import { useNavigate } from "react-router-dom";
import "./datepicker.css";
import { Back } from "src/assets/icons";
import { useDispatch, useSelector } from "react-redux";
import { fetchWorkedMonthRequest } from "src/store/clocks/actions";
import { getTodaysClocksSelector } from "src/store/clocks/selectors";
import DeviceWrapper from "../../DeviceWrapper";
import { clientTimezone } from "src/utils";

const HistoryPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState<Date>(new Date());
  const workedMonth = useSelector(getTodaysClocksSelector);

  const viewDetails = (date: Date) => {
    setStartDate(date);
    navigate(AppRoutes.HistoryDay + `/${format(date, "y-MM-dd")}`, {
      state: { id: AppRoutes.History },
    });
    sessionStorage.setItem("activeTab", "0");
  };

  useEffect(() => {
    dispatch(
      fetchWorkedMonthRequest({
        date: format(startDate, "y-MM-dd"),
        timezone: clientTimezone,
      })
    );
  }, [startDate]);

  return (
    <>
      <DeviceWrapper>
        <>
          <Box className="history">
            {format(startDate, "MMMM") !== format(new Date(), "MMMM") && (
              <Box sx={styles.boxTodayButton}>
                <Typography
                  sx={styles.boxTodayButtonText}
                  onClick={() => setStartDate(new Date())}
                >
                  Return to Today
                </Typography>
              </Box>
            )}
            <DatePicker
              selected={startDate}
              androidVariant="iosClone"
              onMonthChange={(date: Date) => setStartDate(date)}
              onChange={(date: Date) => viewDetails(date)}
              dateFormat="yyyy/MM/dd"
              dayClassName={(day) => {
                if (
                  workedMonth?.workedMonth?.datesWorked?.includes(
                    format(day, "y-MM-dd")
                  )
                )
                  return "worked-time";
              }}
              formatWeekDay={(nameOfDay) => nameOfDay.toString().substr(0, 1)}
              inline
              renderCustomHeader={({
                monthDate,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => (
                <div
                  style={{
                    margin: 10,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <button
                    onClick={decreaseMonth}
                    disabled={prevMonthButtonDisabled}
                    className="datepicker__navigation"
                  >
                    {<Back width={22} height={10} color="#fff" />}
                  </button>
                  <span className="react-datepicker__current-month">
                    {monthDate.toLocaleString("en-US", {
                      month: "long",
                      year: "numeric",
                    })}
                  </span>
                  <button
                    onClick={increaseMonth}
                    disabled={nextMonthButtonDisabled}
                    style={{ transform: "rotate(180deg)" }}
                    className="datepicker__navigation"
                  >
                    {<Back width={22} height={10} color="#fff" />}
                  </button>
                </div>
              )}
            />
            <Box sx={styles.boxHistoryDetails}>
              <Typography sx={styles.historyDetails}>
                Click on a date to see the details
              </Typography>
            </Box>
          </Box>
        </>
      </DeviceWrapper>
    </>
  );
};

export default HistoryPage;
