export enum AppRoutes {
  SignIn = "/sign-in",
  SignUp = "/sign-up",
  EmailConfirm = "/emailConfirmation",
  ForgotPassword = "/forgot-password",
  CheckEmail = "/check-email",
  ResetPassword = "/passwordReset",
  Verification = "/verification",
  Welcome = "/welcome",
  Support = "/support",
  TermsConditions = "/terms",
  PrivacyPolicy = "/privacy-policy",
  Dashboard = "/",
  Attachments = "/attachments",
  AddAttachments = "/attachments/add",
  Attachment = "/attachment",
  Comments = "/comments",
  History = "/history",
  HistoryDay = "/history/day",
  Profile = "/profile",
  Team = "/team",
  AddUser = "/team/add-user",
  EditUser = "/team/edit-user",
  Report = "/report",
  ReportFilters = "/report/filters",
  Projects = "/projects",
  AddProject = "/projects/add-project",
  ProjectItem = "/project",
  ProjectItemMobile = "/projects/view-project",
  Settings = "/settings",
  SettingsPlan = "/settings/choose-plan",
  PaidPlanPurchase = "/settings/paid-plan-purchase",
}

export const PHONE_NUMBER_REGEX = /^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/;
export const ZIP_NUMBER_REGEX = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
export const PASSWORD_REGEX = /^(?=.*?[A-Z])(?=.*?[0-9]).{8,}$/;

export const PageTitle = {
  [AppRoutes.Dashboard]: "Home",
  [AppRoutes.Attachments]: "Attachments",
  [AppRoutes.Comments]: "Comments",
  [AppRoutes.History]: "History",
  [AppRoutes.HistoryDay]: "History",
  [AppRoutes.Profile]: "My profile",
  [AppRoutes.Support]: "Support",
  [AppRoutes.Settings]: "Settings",
  [AppRoutes.Team]: "Team",
  [AppRoutes.AddUser]: "Add User",
  [AppRoutes.EditUser]: "Edit User",
  [AppRoutes.Projects]: "Projects",
  [AppRoutes.ProjectItemMobile]: "View Project",
  [AppRoutes.AddProject]: "Add Project",
  [AppRoutes.Report]: "Report",
  [AppRoutes.ReportFilters]: "Report Filters",
  [AppRoutes.TermsConditions]: "Terms and Conditions",
  [AppRoutes.PrivacyPolicy]: "Privacy Policy",
  [AppRoutes.SettingsPlan]: "Choose a Plan",
  [AppRoutes.PaidPlanPurchase]: "Paid Plan Purchase",
};
