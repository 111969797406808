import {
  Box,
  Card,
  Container,
  Typography,
  Link,
  Alert,
  Theme,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useCallback, useEffect } from "react";
import * as yup from "yup";
import { Button, PasswordStrength, SafeInput } from "src/components";
import { useDispatch, useSelector } from "react-redux";
import { AppRoutes, PASSWORD_REGEX } from "src/constants";
import { getErrorSelector } from "src/store/auth/selectors";
import { useNavigate, useParams } from "react-router-dom";
import { getMappedErrorMessage, isFieldHasError } from "src/utils";
import {
  checkPasswordResetRequest,
  cleanError,
  newPasswordRequest,
  resetState,
} from "src/store/auth/actions";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    paddingTop: "24px",
    paddingBottom: "24px",
  },
  cardWrapper: {
    width: "100%",
    mt: "33px",
    boxSizing: "border-box",
    borderColor: (theme: Theme) => `${theme.palette.background.default}`,
    px: {
      xs: "24px",
      md: "30px",
    },
    py: "50px",
    maxWidth: {
      xs: "350px",
      md: "460px",
    },
  },
  headerWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  subText: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#8C8C8C",
  },
  alertErrorWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  alertError: {
    mt: "20px",
    background: "transparent",
    fontSize: "14px",
    color: (theme: Theme) => theme.palette.error[700],
  },
};

const validationSchema = yup.object().shape({
  newPassword: yup
    .string()
    .required("Password is required")
    .matches(PASSWORD_REGEX, "Password is not valid."),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
});

const ResetPassword = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const authenticationError = useSelector(getErrorSelector);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      newPassword: "",
      passwordConfirmation: "",
    },
    validationSchema: validationSchema,
    onSubmit: ({ newPassword }) => {
      if (!formik.values.newPassword && !formik.values.passwordConfirmation) {
        return;
      }
      dispatch(
        newPasswordRequest(
          {
            token: id,
            newPassword,
          },
          {
            onSuccess: onSingInSuccess,
          }
        )
      );
    },
  });

  const checkToken = () => {
    dispatch(
      checkPasswordResetRequest(
        {
          token: id,
        },
        { onSuccess: formik.handleSubmit }
      )
    );
  };

  useEffect(() => {
    dispatch(resetState());
    dispatch(cleanError());
    return () => {
      dispatch(cleanError());
    };
  }, []);

  const onSingInSuccess = useCallback(() => {
    navigate(AppRoutes.SignIn);
  }, [navigate]);

  const redirectToPrivacyPolicy = useCallback(() => {
    navigate(AppRoutes.PrivacyPolicy);
  }, [navigate]);

  const redirectToTermsConditions = useCallback(() => {
    navigate(AppRoutes.TermsConditions);
  }, [navigate]);

  return (
    <Container sx={styles.container} maxWidth="sm">
      <Typography
        data-sqa-id="page-title"
        color="primary"
        fontWeight="800"
        fontSize={{ xs: "24px", md: "36px" }}
      >
        BusyBucket
      </Typography>
      <Card variant="outlined" sx={styles.cardWrapper}>
        <form style={{ width: "100%" }}>
          <Box sx={styles.headerWrapper}>
            <Typography
              data-sqa-id="form-title"
              fontWeight="bold"
              fontSize={{ xs: "20px", md: "24px" }}
              mb="14px"
            >
              Reset Password
            </Typography>
            <Typography data-sqa-id="form-description" sx={styles.subText}>
              Please set a new password.
            </Typography>
          </Box>
          <Box mt="30px">
            <SafeInput
              data-sqa-id="new-password-input"
              label="New Password"
              required
              fullWidth
              size="small"
              type="password"
              inputProps={{ maxLength: 50 }}
              id="newPassword"
              value={formik.values.newPassword}
              onChange={formik.handleChange}
              error={isFieldHasError({
                formik,
                fieldName: "newPassword",
                isGlobalError: Boolean(authenticationError),
              })}
              helperText={
                formik.touched.newPassword && formik.errors.newPassword
              }
            />
            <PasswordStrength isValid={!formik.errors.newPassword} />
          </Box>
          <Box mt="20px">
            <SafeInput
              data-sqa-id="confirm-password-input"
              label="Confirm Password"
              required
              fullWidth
              size="small"
              type="password"
              inputProps={{ maxLength: 50 }}
              id="passwordConfirmation"
              value={formik.values.passwordConfirmation}
              onChange={formik.handleChange}
              error={isFieldHasError({
                formik,
                fieldName: "passwordConfirmation",
                isGlobalError: Boolean(authenticationError),
              })}
              helperText={
                formik.touched.passwordConfirmation &&
                formik.errors.passwordConfirmation
              }
            />
          </Box>

          {Boolean(authenticationError) && (
            <Box sx={styles.alertErrorWrapper}>
              <Alert
                severity="error"
                data-sqa-id="error-wrapper"
                sx={styles.alertError}
              >
                {getMappedErrorMessage(authenticationError)}
              </Alert>
            </Box>
          )}
          <Box mt="15px">
            <Button
              data-sqa-id="save-btn"
              variant="contained"
              color="primary"
              fullWidth
              onClick={checkToken}
            >
              <Typography
                data-sqa-id="save-btn-text"
                fontSize={{ xs: "14px", md: "16px" }}
              >
                Save
              </Typography>
            </Button>
          </Box>
        </form>
      </Card>
      <Link
        data-sqa-id="terms-conditions-footer-link"
        mt="30px"
        variant="body1"
        component="button"
        fontSize={{ xs: "12px", md: "14px" }}
        color="secondary"
        sx={{ marginTop: "30px" }}
        onClick={redirectToTermsConditions}
      >
        Terms of Service
      </Link>
      <Link
        data-sqa-id="terms-conditions-footer-link"
        variant="body1"
        color="secondary"
        component="button"
        fontSize={{ xs: "12px", md: "14px" }}
        sx={{ marginTop: "20px" }}
        onClick={redirectToPrivacyPolicy}
      >
        Privacy Policy
      </Link>
    </Container>
  );
};

export default ResetPassword;
