import {
  FETCH_REPORT_REQUEST,
  FETCH_REPORT_SUCCESS,
  FETCH_REPORT_FAILURE,
  REPORT_BREAKDOWN_FAILURE,
  REPORT_BREAKDOWN_SUCCESS,
  REPORT_BREAKDOWN_REQUEST,
  REPORT_TIMESHEET_REQUEST,
  REPORT_TIMESHEET_FAILURE,
  REPORT_TIMESHEET_SUCCESS,
  CLEAR_FILTER,
  ADD_REPORT_FILTERS,
} from "./actionTypes";
import { AuthActions, ReportState } from "./types";

const initialState: ReportState = {
  isLoading: false,
  isBreakdownLoading: false,
  isTimesheetLoading: false,
  report: null,
  projectsBreakdown: [],
  usersBreakdown: [],
  timesheet: null,
  error: null,
};

export default (state = initialState, action: AuthActions) => {
  switch (action.type) {
    case FETCH_REPORT_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case REPORT_BREAKDOWN_REQUEST: {
      return {
        ...state,
        isBreakdownLoading: true,
      };
    }
    case REPORT_TIMESHEET_REQUEST: {
      return {
        ...state,
        isTimesheetLoading: true,
      };
    }
    case FETCH_REPORT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };
    }
    case FETCH_REPORT_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
      };
    }
    case REPORT_BREAKDOWN_SUCCESS: {
      return {
        ...state,
        isBreakdownLoading: false,
        ...action.payload,
      };
    }
    case REPORT_BREAKDOWN_FAILURE: {
      return {
        ...state,
        isBreakdownLoading: false,
        error: action.payload.error,
      };
    }
    case REPORT_TIMESHEET_SUCCESS: {
      return {
        ...state,
        isTimesheetLoading: false,
        timesheet: action.payload.timesheet,
      };
    }
    case REPORT_TIMESHEET_FAILURE: {
      return {
        ...state,
        isTimesheetLoading: false,
        error: action.payload.error,
      };
    }
    case ADD_REPORT_FILTERS: {
      return {
        ...state,
        report: action.payload,
      };
    }
    case CLEAR_FILTER: {
      return {
        ...state,
        report: null,
        error: null,
      };
    }
    default:
      return state;
  }
};
