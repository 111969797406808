import React from "react";

const styles = {
  marginBottom: "5px",
};

interface Props {
  icon: React.ReactElement;
}

const BottomTabBarIcon: React.FC<Props> = ({ icon }) => {
  return <div style={styles}>{icon}</div>;
};

export default BottomTabBarIcon;
