import React, { useMemo } from "react";
import { Box } from "@mui/system";
import { styles } from "./dashboard.module";
import { Typography, CircularProgress, Theme } from "@mui/material";
import { DateTime, Button, Map } from "src/components";
import { PlusForWeb } from "src/assets/icons";
import TabDataSection from "src/components/TabDataSection";
import TabDataItem from "src/components/TabDataItem";
import { useSelector } from "react-redux";
import { getTodaysClocksSelector } from "src/store/clocks/selectors";
import { getTimeWatchSelector } from "src/store/timeWatch/selectors";
import theme from "src/theme";
import { ClockRecord, WeeklyRecord } from "src/types";
import { AppRoutes } from "src/constants";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { secondsToTime, parseTime } from "src/utils";
import { getOrganizationSelector } from "src/store/auth/selectors";

interface Props {
  handleStartWatch: () => void;
  handleStopWatch: () => void;
  handleOpenModal: (name: string, data?: any, tab?: number) => void;
  currentWatch: null;
  disbledClock: boolean;
}

const DashboardWeb: React.FC<Props> = ({
  handleStartWatch,
  handleStopWatch,
  handleOpenModal,
  currentWatch,
  disbledClock,
}) => {
  const clocks = useSelector(getTodaysClocksSelector);
  const organization = useSelector(getOrganizationSelector);
  const timeWatch = useSelector(getTimeWatchSelector);
  const navigate = useNavigate();

  const dayTimeWorked = useMemo(
    () => secondsToTime(clocks.todaysClocks.dayTotal || 0),
    [clocks.todaysClocks]
  );

  const weeklyTimeWorked = useMemo(
    () => secondsToTime(clocks.todaysClocks.weeklyTotal || 0),
    [clocks.todaysClocks]
  );

  return (
    <>
      <Box display="flex" justifyContent="space-between" columnGap="30px">
        <Box sx={styles.clockTimeContainer}>
          <DateTime />
          {!timeWatch?.id || timeWatch.endTime ? (
            <Button
              style={styles.clockInButtonStyle}
              variant="contained"
              color="primary"
              disabled={
                disbledClock ||
                (organization?.trial &&
                  new Date().getTime() >
                    parseTime(organization?.trialExpires).getTime())
              }
              onClick={handleStartWatch}
              data-sqa-id="clock-in-btn"
            >
              <Box sx={styles.clockInButtonContainer}>
                <Typography
                  id="clockInBtn"
                  sx={styles.clockInButtonText.desktop}
                  fontSize={"22px"}
                >
                  Clock In
                </Typography>
              </Box>
            </Button>
          ) : (
            <Button
              variant="contained"
              color="success"
              disabled={disbledClock}
              onClick={handleStopWatch}
              style={styles.clockInButtonStop}
              data-sqa-id="clock-out-btn"
            >
              <Box sx={styles.clockInButtonContainer}>
                <Typography
                  sx={styles.clockInButtonText.desktop}
                  fontSize={"22px"}
                >
                  Clock Out
                </Typography>
              </Box>
              <Box>
                <span>{currentWatch}</span>
              </Box>
            </Button>
          )}
        </Box>
        <Box width="50%">
          <Map height="100px" />
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <TabDataSection mt="16px" width="calc(50% - 15px)">
          {!clocks.isLoading ? (
            <>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                borderBottom={`1px solid ${theme.palette.background.default}`}
                padding={"16px 30px"}
                data-sqa-id="todays-activity-box"
              >
                <Typography
                  data-sqa-id="todays-activity-label"
                  fontSize="20px"
                  fontWeight={600}
                  color="#262626"
                >
                  Today&apos;s Activity
                </Typography>
                <Button
                  data-sqa-id="todays-manual-entry-btn"
                  size="small"
                  color="primary"
                  variant="contained"
                  disabled={
                    organization?.trial &&
                    new Date().getTime() >
                      parseTime(organization?.trialExpires).getTime()
                  }
                  onClick={() =>
                    handleOpenModal("MANUAL_ENTRY_MODAL", new Date(), 0)
                  }
                  style={{ padding: "4px 16px" }}
                >
                  <PlusForWeb
                    color={
                      organization?.trial &&
                      new Date().getTime() >
                        parseTime(organization?.trialExpires).getTime()
                        ? "#d9d9d9"
                        : "#FFF"
                    }
                  />
                  <Typography
                    data-sqa-id="manual-entry-btn-text"
                    fontSize={{ xs: "14px", md: "16px" }}
                    ml={"10px"}
                  >
                    Manual Entry
                  </Typography>
                </Button>
              </Box>
              <Box padding={"0 30px"} data-sqa-id="time-clock-tbl">
                {clocks.todaysClocks?.timeClockRecords?.length !== 0 ? (
                  clocks.todaysClocks.timeClockRecords?.map(
                    (clock: ClockRecord) => (
                      <TabDataItem
                        key={clock.id}
                        currentTab={0}
                        isHistoryView={false}
                        clock={clock}
                        onLink={() => {
                          handleOpenModal("LINK_ENTRY_MODAL", [clock.id], 0);
                        }}
                        onEdit={() => {
                          handleOpenModal("EDIT_ENTRY_MODAL", clock, 0);
                        }}
                        onDelete={() => {
                          handleOpenModal("DELETE_ENTRY_MODAL", clock, 0);
                        }}
                      />
                    )
                  )
                ) : (
                  <Box my={"20px"}>
                    <Typography
                      data-sqa-id="no-time-entries-yet"
                      fontSize={14}
                      fontWeight={600}
                      color={theme.palette.text.secondary}
                      textAlign="center"
                    >
                      No time entries yet
                    </Typography>
                  </Box>
                )}
              </Box>
              {clocks.todaysClocks?.timeClockRecords?.length !== 0 && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderTop: (theme: Theme) =>
                      `1px solid ${theme.palette.background.default}`,
                  }}
                  mx="30px"
                  py="20px"
                >
                  <Typography
                    data-sqa-id="todays-total-label"
                    fontSize={18}
                    fontWeight={500}
                    color={theme.palette.text.secondary}
                  >
                    Today’s Total
                  </Typography>
                  <Typography
                    data-sqa-id="daily-total-time-worked"
                    fontSize={18}
                    fontWeight={500}
                    color="primary"
                  >
                    {dayTimeWorked}
                  </Typography>
                </Box>
              )}
            </>
          ) : (
            <Box
              data-sqa-id="circular-progress-loader"
              display="flex"
              alignItems="center"
              justifyContent="center"
              p={"15px"}
            >
              <CircularProgress />
            </Box>
          )}
        </TabDataSection>
        <TabDataSection mt="16px" width="calc(50% - 15px)">
          {!clocks.isLoading ? (
            <>
              <Box
                data-sqa-id="weekly-activity-box"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                borderBottom="1px solid #F0F0F0"
                padding={"16px 30px"}
              >
                <Typography
                  data-sqa-id="weekly-activity-label"
                  fontSize="20px"
                  fontWeight={600}
                  color="#262626"
                >
                  Weekly Activity
                </Typography>
                <Button
                  data-sqa-id="weekly-manual-entry-btn"
                  size="small"
                  color="primary"
                  variant="contained"
                  disabled={
                    organization?.trial &&
                    new Date().getTime() >
                      parseTime(organization?.trialExpires).getTime()
                  }
                  onClick={() =>
                    handleOpenModal("MANUAL_ENTRY_MODAL", new Date(), 1)
                  }
                  style={{ padding: "4px 16px" }}
                >
                  <PlusForWeb
                    color={
                      organization?.trial &&
                      new Date().getTime() >
                        parseTime(organization?.trialExpires).getTime()
                        ? "#d9d9d9"
                        : "#FFF"
                    }
                  />
                  <Typography fontSize={{ xs: "14px", md: "16px" }} ml={"10px"}>
                    Manual Entry
                  </Typography>
                </Button>
              </Box>
              <Box padding="0 30px">
                {clocks.todaysClocks?.weeklyRecords?.length !== 0 ? (
                  clocks.todaysClocks?.weeklyRecords?.map(
                    (clock: WeeklyRecord) => (
                      <TabDataItem
                        key={clock.day}
                        currentTab={1}
                        isHistoryView={false}
                        weekclock={clock}
                        onLink={() => {
                          handleOpenModal("LINK_ENTRY_MODAL", clock, 1);
                        }}
                        onView={() => {
                          navigate(
                            AppRoutes.HistoryDay +
                              `/${format(parseTime(clock.day), "y-MM-dd")}`
                          );
                        }}
                        onDelete={() => {
                          handleOpenModal("DELETE_ENTRY_MODAL", clock, 1);
                        }}
                      />
                    )
                  )
                ) : (
                  <Box my={"20px"}>
                    <Typography
                      data-sqa-id="no-weekly-summary-yet"
                      fontSize={14}
                      fontWeight={600}
                      color={theme.palette.text.secondary}
                      textAlign="center"
                    >
                      No weekly summary yet
                    </Typography>
                  </Box>
                )}
              </Box>
              {clocks.todaysClocks?.weeklyRecords?.length !== 0 && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderTop: (theme: Theme) =>
                      `1px solid ${theme.palette.background.default}`,
                  }}
                  mx="30px"
                  py="20px"
                >
                  <Typography
                    data-sqa-id="weekly-total-label"
                    fontSize={18}
                    fontWeight={500}
                    color={theme.palette.text.secondary}
                  >
                    Weekly Total
                  </Typography>
                  <Typography
                    data-sqa-id="weekly-total-time-worked"
                    fontSize={18}
                    fontWeight={500}
                    color="primary"
                  >
                    {weeklyTimeWorked}
                  </Typography>
                </Box>
              )}
            </>
          ) : (
            <Box
              data-sqa-id="circulat-progress-loader"
              display="flex"
              alignItems="center"
              justifyContent="center"
              p={"15px"}
            >
              <CircularProgress />
            </Box>
          )}
        </TabDataSection>
      </Box>
    </>
  );
};

export default DashboardWeb;
