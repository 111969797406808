import {
  FETCH_COMMENTS_REQUEST,
  FETCH_COMMENTS_SUCCESS,
  FETCH_COMMENTS_FAILURE,
  ADD_COMMENT_FAILURE,
  ADD_COMMENT_REQUEST,
  ADD_COMMENT_SUCCESS,
  DELETE_COMMENT_FAILURE,
  DELETE_COMMENT_REQUEST,
  DELETE_COMMENT_SUCCESS,
  UPDATE_COMMENT_FAILURE,
  UPDATE_COMMENT_REQUEST,
  UPDATE_COMMENT_SUCCESS,
  FETCH_DAILY_COMMENTS_FAILURE,
  FETCH_DAILY_COMMENTS_REQUEST,
  FETCH_DAILY_COMMENTS_SUCCESS,
  FETCH_COMMENTS_DAY_BY_USER_FAILURE,
  FETCH_COMMENTS_DAY_BY_USER_REQUEST,
  FETCH_COMMENTS_DAY_BY_USER_SUCCESS,
} from "./actionTypes";
import {
  FetchCommentsFailure,
  FetchCommentsFailurePayload,
  FetchCommentsRequest,
  FetchCommentsRequestPayload,
  FetchCommentsSuccess,
  FetchCommentsSuccessPayload,
  AddCommentFailure,
  AddCommentFailurePayload,
  AddCommentRequest,
  AddCommentRequestPayload,
  AddCommentSuccess,
  AddCommentSuccessPayload,
  DeleteCommentFailure,
  DeleteCommentFailurePayload,
  DeleteCommentRequest,
  DeleteCommentRequestPayload,
  DeleteCommentSuccess,
  DeleteCommentSuccessPayload,
  UpdateCommentFailure,
  UpdateCommentFailurePayload,
  UpdateCommentRequest,
  UpdateCommentRequestPayload,
  UpdateCommentSuccess,
  UpdateCommentSuccessPayload,
  FetchDailyCommentsFailure,
  FetchDailyCommentsFailurePayload,
  FetchDailyCommentsRequest,
  FetchDailyCommentsRequestPayload,
  FetchDailyCommentsSuccess,
  FetchDailyCommentsSuccessPayload,
  AddCommentCallbacks,
  DeleteCommentCallbacks,
  UpdateCommentCallbacks,
  FetchCommentsDayByUserFailure,
  FetchCommentsDayByUserFailurePayload,
  FetchCommentsDayByUserRequest,
  FetchCommentsDayByUserRequestPayload,
  FetchCommentsDayByUserSuccess,
  FetchCommentsDayByUserSuccessPayload,
} from "./types";

export const fetchCommentsRequest = (
  payload: FetchCommentsRequestPayload
): FetchCommentsRequest => ({
  type: FETCH_COMMENTS_REQUEST,
  payload,
});

export const fetchCommentsSuccess = (
  payload: FetchCommentsSuccessPayload
): FetchCommentsSuccess => ({
  type: FETCH_COMMENTS_SUCCESS,
  payload,
});

export const fetchCommentsFailure = (
  payload: FetchCommentsFailurePayload
): FetchCommentsFailure => ({
  type: FETCH_COMMENTS_FAILURE,
  payload,
});

export const fetchCommentsDayByUserRequest = (
  payload: FetchCommentsDayByUserRequestPayload
): FetchCommentsDayByUserRequest => ({
  type: FETCH_COMMENTS_DAY_BY_USER_REQUEST,
  payload,
});

export const fetchCommentsDayByUserSuccess = (
  payload: FetchCommentsDayByUserSuccessPayload
): FetchCommentsDayByUserSuccess => ({
  type: FETCH_COMMENTS_DAY_BY_USER_SUCCESS,
  payload,
});

export const fetchCommentsDayByUserFailure = (
  payload: FetchCommentsDayByUserFailurePayload
): FetchCommentsDayByUserFailure => ({
  type: FETCH_COMMENTS_DAY_BY_USER_FAILURE,
  payload,
});

export const fetchDailyCommentsRequest = (
  payload: FetchDailyCommentsRequestPayload
): FetchDailyCommentsRequest => ({
  type: FETCH_DAILY_COMMENTS_REQUEST,
  payload,
});

export const fetchDailyCommentsSuccess = (
  payload: FetchDailyCommentsSuccessPayload
): FetchDailyCommentsSuccess => ({
  type: FETCH_DAILY_COMMENTS_SUCCESS,
  payload,
});

export const fetchDailyCommentsFailure = (
  payload: FetchDailyCommentsFailurePayload
): FetchDailyCommentsFailure => ({
  type: FETCH_DAILY_COMMENTS_FAILURE,
  payload,
});

export const addCommentRequest = (
  payload: AddCommentRequestPayload,
  callbacks: AddCommentCallbacks
): AddCommentRequest => ({
  type: ADD_COMMENT_REQUEST,
  payload,
  callbacks,
});

export const addCommentSuccess = (
  payload: AddCommentSuccessPayload
): AddCommentSuccess => ({
  type: ADD_COMMENT_SUCCESS,
  payload,
});

export const addCommentFailure = (
  payload: AddCommentFailurePayload
): AddCommentFailure => ({
  type: ADD_COMMENT_FAILURE,
  payload,
});

export const updateCommentRequest = (
  payload: UpdateCommentRequestPayload,
  callbacks: UpdateCommentCallbacks
): UpdateCommentRequest => ({
  type: UPDATE_COMMENT_REQUEST,
  payload,
  callbacks,
});

export const updateCommentSuccess = (
  payload: UpdateCommentSuccessPayload
): UpdateCommentSuccess => ({
  type: UPDATE_COMMENT_SUCCESS,
  payload,
});

export const updateCommentFailure = (
  payload: UpdateCommentFailurePayload
): UpdateCommentFailure => ({
  type: UPDATE_COMMENT_FAILURE,
  payload,
});

export const deleteCommentRequest = (
  payload: DeleteCommentRequestPayload,
  callbacks: DeleteCommentCallbacks
): DeleteCommentRequest => ({
  type: DELETE_COMMENT_REQUEST,
  payload,
  callbacks,
});

export const deleteCommentSuccess = (
  payload: DeleteCommentSuccessPayload
): DeleteCommentSuccess => ({
  type: DELETE_COMMENT_SUCCESS,
  payload,
});

export const deleteCommentFailure = (
  payload: DeleteCommentFailurePayload
): DeleteCommentFailure => ({
  type: DELETE_COMMENT_FAILURE,
  payload,
});
