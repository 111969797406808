import { Box, CardMedia, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { format } from "date-fns";
import { styles } from "./attachmetSlider.module";
import { useDispatch, useSelector } from "react-redux";
import { getUserSelector } from "src/store/auth/selectors";
import Toggle from "src/components/Toggle";
import Button from "src/components/Button";
import { updateAttachmentRequest } from "src/store/attachments/actions";
import { useParams } from "react-router-dom";
import { Attachment } from "src/types";
import ActionsModal from "src/components/ActionsModal/ActionsModal";
import { parseTime } from "src/utils";

interface Props {
  closeModal: () => void;
  fetchSuccess: () => void;
  item: Attachment;
  imageUrlPrefix: string;
}

const AttachmentSlide: React.FC<Props> = ({
  item,
  imageUrlPrefix,
  closeModal,
  fetchSuccess,
}) => {
  const user = useSelector(getUserSelector);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [toggleReceipt, setToggleReceipt] = useState<boolean>(item.isReceipt);
  const [caption, setCaption] = useState<string>(item.caption);
  const modalActionType = {
    action: "Delete",
    page: "Attachment",
  };

  const handleUpdateAttachment = (item: Attachment) => {
    dispatch(
      updateAttachmentRequest(
        {
          id: item.id,
          attachmentDay: id,
          isReceipt: toggleReceipt,
          caption: caption.trim(),
          s3FilePath: item.s3FilePath,
          thumbnailS3FilePath: item.thumbnailS3FilePath,
        },
        {
          onSuccess,
        }
      )
    );
  };

  const onSuccess = () => {
    fetchSuccess();
    closeModal();
  };

  return (
    <>
      <Box sx={styles.attachmentContainer}>
        {item.isReceipt &&
          user.isAdmin &&
          localStorage.getItem("roleMode") === "admin" && (
            <Box sx={styles.whiteBgReceipt}>
              <Typography sx={styles.caption}>Receipt</Typography>
            </Box>
          )}
        {`${imageUrlPrefix}${item.s3FilePath}` ? (
          <CardMedia
            sx={styles.attachment}
            component="img"
            image={`${imageUrlPrefix}${item.s3FilePath}`}
            alt="attachment"
          />
        ) : (
          <Box sx={styles.attachment} />
        )}
      </Box>
      <Box
        mt="20px"
        display="flex"
        justifyContent="space-between"
        columnGap="20px"
      >
        <Box
          p={
            user.isAdmin && localStorage.getItem("roleMode") === "admin"
              ? "30px"
              : "20px"
          }
          width={
            user.isAdmin && localStorage.getItem("roleMode") === "admin"
              ? "100%"
              : "80%"
          }
          sx={styles.boxBorder}
        >
          <Typography
            fontSize={
              user.isAdmin && localStorage.getItem("roleMode") === "admin"
                ? "18px"
                : "16px"
            }
            fontWeight={
              user.isAdmin && localStorage.getItem("roleMode") === "admin"
                ? 400
                : 500
            }
            color="#8C8C8C"
          >
            {format(parseTime(item.createdAt), "MMMM d, h:mm a")}
          </Typography>
          {user.isAdmin && localStorage.getItem("roleMode") === "admin" ? (
            <Typography fontSize="18px" sx={styles.captionText}>
              {item.caption}
            </Typography>
          ) : (
            <TextField
              multiline
              fullWidth
              sx={[styles.captionText, styles.textField]}
              minRows={1}
              maxRows={3}
              placeholder="Enter photo caption"
              value={caption}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (caption.length + e.target.value.length > 500)
                  return setCaption(e.target.value.substring(0, 500));
                else setCaption(e.target.value);
              }}
              type="text"
              inputProps={{ maxLength: 500 }}
            />
          )}
        </Box>
        {((user.isAdmin && localStorage.getItem("roleMode") === "user") ||
          !user.isAdmin) && (
          <Box sx={[styles.boxBorder, styles.togleContainer]}>
            <Typography color="#262626">Receipt</Typography>
            <Toggle
              checked={toggleReceipt}
              onChange={() => setToggleReceipt(!toggleReceipt)}
            />
          </Box>
        )}
      </Box>
      {((user.isAdmin && localStorage.getItem("roleMode") === "user") ||
        !user.isAdmin) && (
        <Box
          mt="20px"
          display="flex"
          justifyContent="space-between"
          columnGap="20px"
        >
          <Box width="80%">
            <Button
              variant="outlined"
              size="small"
              color="error"
              style={{ width: "20%" }}
              onClick={() => setOpenModal(true)}
            >
              Delete Attachment
            </Button>
          </Box>
          <Box sx={styles.buttonContainer}>
            <Box width="50%">
              <Button
                variant="outlined"
                fullWidth
                color="secondary"
                size="small"
                sx={styles.actionButton}
                onClick={closeModal}
              >
                <Typography color="#262626" fontSize="14px">
                  Cancel
                </Typography>
              </Button>
            </Box>
            <Box width="50%">
              <Button
                variant="contained"
                size="small"
                color="primary"
                fullWidth
                onClick={() => handleUpdateAttachment(item)}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      )}

      {openModal && (
        <ActionsModal
          onFetchSuccess={onSuccess}
          elementDate={id}
          modalActionType={modalActionType}
          isOpen={openModal}
          elementId={item.id}
          closeModal={() => setOpenModal(false)}
        />
      )}
    </>
  );
};

export default AttachmentSlide;
