import {
  Box,
  Typography,
  IconButton,
  Checkbox,
  FormControl,
  SelectChangeEvent,
  MenuItem,
  List,
  ListItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Button from "../../Button";
import Select from "../../Select";
import { CheckBox, CheckBoxOutlineBlank } from "src/assets/icons";
import { Link as LinkIcon } from "src/assets/icons";
import theme from "src/theme";
import { EntryModalBaseProps } from "../types";
import { useDispatch, useSelector } from "react-redux";
import { linkTimeRecordRequest } from "src/store/clocks/actions";
import { fetchProjectAllRequest } from "src/store/projects/actions";
import { getProjectSelector } from "src/store/projects/selectors";
import { LinkTimeRecords, ProjectAll } from "src/types";
import { getTodaysClocksSelector } from "src/store/clocks/selectors";
import { secondsToTime, parseTime } from "src/utils";
import { format } from "date-fns";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { styles } from "./linkEntryModal.module";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const DeleteEntryModal: React.FC<EntryModalBaseProps> = ({
  closeModal,
  onFetchSuccess,
  currentTab,
  modalData,
}) => {
  const [projectValue, setProjectValue] = useState("");
  const [linksData, setLinksData] = useState<LinkTimeRecords[]>([]);
  const dispatch = useDispatch();
  const projectState = useSelector(getProjectSelector);
  const clocks = useSelector(getTodaysClocksSelector);

  const projectByTime = clocks.todaysClocks?.timeClockRecords.filter(
    (clock) => clock.id === modalData[0]
  );

  const handleChangeSelectProject = (event: SelectChangeEvent) => {
    setProjectValue(event.target.value as string);
    const data: any[] = linksData.map((elem) => ({
      ...elem,
      projectId: event.target.value,
    }));
    setLinksData(data);
  };

  const handleChangeLinkProject = (clockId) => {
    if (linksData.some((clock) => clock.timeClockId === clockId)) {
      setLinksData(linksData.filter((clock) => clock.timeClockId !== clockId));
    } else {
      setLinksData([
        ...linksData,
        {
          timeClockId: clockId,
          projectId: projectValue,
        },
      ]);
    }
  };

  const handleChangeLinkProjects = (timeClockIDs) => {
    const existsItems = linksData.filter((r) =>
      timeClockIDs.some((existItem) => existItem === r.timeClockId)
    );

    if (existsItems.length > 0) {
      const newList = linksData.filter(
        (elem) => !timeClockIDs.some((clockId) => clockId === elem.timeClockId)
      );
      setLinksData(newList);
    } else {
      const newItems = timeClockIDs.map((clockId) => ({
        timeClockId: clockId,
        projectId: projectValue,
      }));
      setLinksData((prevState) => [...prevState, ...newItems]);
    }
  };

  const handlerLinkTimeRecord = () => {
    dispatch(
      linkTimeRecordRequest(
        {
          linkEntries: linksData,
        },
        {
          onSuccess: onFetchSuccess,
        }
      )
    );
    closeModal();
  };

  useEffect(() => {
    dispatch(fetchProjectAllRequest());
  }, []);

  useEffect(() => {
    if (currentTab === 1) {
      let newItems = null;
      clocks.todaysClocks?.weeklyRecords.map((item) => {
        if (item.day === modalData.day) {
          newItems = modalData?.timeClockIDs?.map((id) => ({
            timeClockId: id,
            projectId: projectValue,
          }));
        }
      });
      setLinksData((prevState) => [...prevState, ...newItems]);
    } else if (currentTab === 0 && modalData.length) {
      const newItems = modalData.map((id) => ({
        timeClockId: id,
        projectId: projectValue,
      }));
      setLinksData((prevState) => [...prevState, ...newItems]);
    } else {
      const newItems = {
        timeClockId: modalData.id,
        projectId: projectValue,
      };
      setLinksData((prevState) => [...prevState, newItems]);
    }
  }, []);

  const periodWorked = (startClock: string, endClock: string) => {
    const start = format(parseTime(startClock), "p");
    let end: string;
    if (endClock) {
      end = format(parseTime(endClock), "p");
    } else {
      end = "Still Working";
    }
    return `${start} - ${end}`;
  };

  return (
    <>
      <Box sx={styles.modalTitile}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          fontSize={16}
          fontWeight={700}
        >
          Link {currentTab === 1 && "Daily"} Entries to Project
        </Typography>
        <IconButton sx={styles.modalClose} onClick={closeModal}>
          <CloseIcon fontSize="small" color="disabled" />
        </IconButton>
      </Box>
      <Box sx={styles.modalContent}>
        <Box mb={"20px"}>
          <Typography fontSize={14} mb={"8px"}>
            Select Project
          </Typography>
          <FormControl fullWidth>
            <Select
              value={projectValue}
              onChange={handleChangeSelectProject}
              height={"40px"}
              IconComponent={KeyboardArrowDownIcon}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              {projectState.projectAll?.map((project: ProjectAll) => {
                if (project?.subProjects) {
                  let arrProjectGroup = [...project.subProjects];
                  arrProjectGroup.unshift(project);
                  return arrProjectGroup.map(
                    (subproject: ProjectAll, i: number) => {
                      return (
                        <MenuItem
                          sx={{ pl: i ? "30px" : "16px" }}
                          key={subproject.id}
                          value={subproject.id}
                        >
                          {`${subproject.projectName} (${subproject.numericId})`}
                        </MenuItem>
                      );
                    }
                  );
                } else
                  return (
                    <MenuItem key={project.id} value={project.id}>
                      {`${project.projectName} (${project.numericId})`}
                    </MenuItem>
                  );
              })}
            </Select>
          </FormControl>
        </Box>
        <Box>
          <Typography fontSize={14} mb={"8px"}>
            Select Entries
          </Typography>
          <List>
            {currentTab === 0
              ? clocks.todaysClocks?.timeClockRecords.map((clock) => {
                  const clockisChecked = linksData.map(
                    (elem) => elem.timeClockId
                  );
                  return (
                    <ListItem key={clock.id} sx={styles.listItem}>
                      <Checkbox
                        {...label}
                        sx={styles.check}
                        icon={<CheckBoxOutlineBlank />}
                        checkedIcon={<CheckBox />}
                        onChange={() => handleChangeLinkProject(clock.id)}
                        checked={clockisChecked.includes(clock.id)}
                      />
                      <Box sx={styles.listItemContent}>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent={"space-between"}
                        >
                          <Typography fontSize={16} fontWeight={600} mr={"9px"}>
                            {secondsToTime(clock.timeWorked)}
                          </Typography>
                          <Typography
                            fontSize={14}
                            fontWeight={500}
                            color={theme.palette.text.secondary}
                            ml={"auto"}
                          >
                            {clock.endTime
                              ? periodWorked(clock.startTime, clock.endTime)
                              : periodWorked(clock.startTime, "")}
                          </Typography>
                        </Box>
                        {clock?.project?.projectName ? (
                          <Typography
                            width={"100%"}
                            fontSize={"12px"}
                            lineHeight={1.2}
                            fontWeight={500}
                            color={theme.palette.text.secondary}
                            sx={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {clock.project.projectName}
                          </Typography>
                        ) : null}
                      </Box>
                    </ListItem>
                  );
                })
              : clocks.todaysClocks?.weeklyRecords.map((clock) => (
                  <ListItem key={clock.day} sx={styles.listItem}>
                    <Checkbox
                      {...label}
                      sx={styles.check}
                      icon={<CheckBoxOutlineBlank />}
                      checkedIcon={<CheckBox />}
                      onChange={() =>
                        handleChangeLinkProjects(clock.timeClockIDs)
                      }
                      checked={linksData.some((clockId) =>
                        clock.timeClockIDs.includes(clockId.timeClockId)
                      )}
                    />
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      width={"100%"}
                    >
                      <Box
                        display={"flex"}
                        flexDirection={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        width={"100%"}
                      >
                        <Typography fontSize={16} fontWeight={600} mx={"9px"}>
                          {secondsToTime(clock.totalWorkedInSecond)}
                        </Typography>
                        <Typography
                          fontSize={12}
                          fontWeight={500}
                          color={theme.palette.text.secondary}
                          ml={"auto"}
                        >
                          {format(parseTime(clock.day), "E, MMMM d, y")}
                        </Typography>
                      </Box>

                      {Boolean(projectByTime[0]?.project) &&
                        linksData.some((clockId) =>
                          clock.timeClockIDs.includes(clockId.timeClockId)
                        ) && (
                          <Typography
                            fontSize={12}
                            fontWeight={500}
                            color={theme.palette.text.secondary}
                            mx={"9px"}
                            width={"auto"}
                          >
                            {projectByTime[0].project.projectName}
                          </Typography>
                        )}
                    </Box>
                  </ListItem>
                ))}
          </List>
        </Box>
        <Box>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            style={
              projectValue !== "" && linksData.length > 0
                ? { pointerEvents: "auto" }
                : { pointerEvents: "none" }
            }
            onClick={handlerLinkTimeRecord}
          >
            <LinkIcon color={theme.palette.primary.contrastText} />
            <Typography ml={"10px"} fontSize={{ xs: "14px", md: "16px" }}>
              Link to Project
            </Typography>
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default DeleteEntryModal;
