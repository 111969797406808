import React from "react";

type IconProps = {
  width?: number;
  height?: number;
};

const Copy: React.FC<IconProps> = ({ width = 20, height = 20 }, props: any) => {
  return (
    <svg
      data-sqa-id="copy-icon"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-copy"
    >
      <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
      <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
    </svg>
  );
};

export default Copy;
