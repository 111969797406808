import { Box } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import PaidPlanUpgrade from "src/components/ActionsModal/PaidPlanUpgrade";
import AppBar from "src/components/AppBar";

const styles = {
  boxContainer: {
    display: "flex",
    flexDirection: "column",
    border: "1px solid #F0F0F0",
    backgroundColor: "#fff",
    borderRadius: "4px",
    padding: "24px 22px",
  },
};

const PaidPlanPurchase = () => {
  const params = useLocation().state as { user: number };
  return (
    <>
      <AppBar>
        <>
          <Box sx={styles.boxContainer}>
            <PaidPlanUpgrade user={params.user} />
          </Box>
        </>
      </AppBar>
    </>
  );
};

export default PaidPlanPurchase;
