import { Box, TextField, Typography } from "@mui/material";
import { isMobile } from "mobile-device-detect";
import React from "react";
import Button from "../Button";

const styles = {
  actionButtons: {
    display: "flex",
    justifyContent: "flex-end",
    columnGap: "10px",
    marginTop: "20px",
  },
  actionButtonSave: {
    textTransform: "none",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "40%",
  },
  addButtonText: {
    fontWeight: 500,
    textTransform: "initial",
  },
};

interface ModalProps {
  handleChangeElement: () => void;
  elementText: string;
  handleInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
  closeModal?: () => void;
}

const AddComment: React.FC<ModalProps> = ({
  handleChangeElement,
  elementText,
  handleInput,
  closeModal,
}) => {
  return (
    <>
      <Box>
        <TextField
          multiline
          fullWidth
          rows={5}
          value={elementText}
          onChange={handleInput}
          type="text"
          inputProps={{ maxLength: 500 }}
        />
      </Box>
      <Typography
        sx={{ width: "100%", textAlign: "right", mb: "20px" }}
        color="#D9D9D9"
      >{`${elementText.length} / 500`}</Typography>
      {isMobile ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            gap: "10px",
          }}
        >
          <Button
            variant="outlined"
            fullWidth
            color="secondary"
            onClick={closeModal}
          >
            <Typography color="#262626" fontSize="14px">
              Cancel
            </Typography>
          </Button>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleChangeElement}
          >
            <Typography sx={styles.addButtonText}>Save</Typography>
          </Button>
        </Box>
      ) : (
        <Box sx={styles.actionButtons}>
          <Box sx={styles.buttonContainer}>
            <Box sx={{ width: "45%" }}>
              <Button
                size="small"
                variant="outlined"
                fullWidth
                color="secondary"
                onClick={closeModal}
              >
                <Typography color="#262626" fontSize="14px">
                  Cancel
                </Typography>
              </Button>
            </Box>
            <Box sx={{ width: "45%" }}>
              <Button
                size="small"
                variant="contained"
                color="primary"
                fullWidth
                sx={styles.actionButtonSave}
                onClick={handleChangeElement}
              >
                <Typography fontSize="14px">Save</Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default AddComment;
