import {
  CONTACT_US_REQUEST,
  CONTACT_US_SUCCESS,
  CONTACT_US_FAILURE,
  GET_INVOICE_FAILURE,
  GET_INVOICE_REQUEST,
  GET_INVOICE_SUCCESS,
  GET_INVOICE_ID_FAILURE,
  GET_INVOICE_ID_REQUEST,
  GET_INVOICE_ID_SUCCESS,
  GET_SUBSCRIPTION_FAILURE,
  GET_SUBSCRIPTION_REQUEST,
  GET_SUBSCRIPTION_SUCCESS,
  START_SUBSCRIPTION_FAILURE,
  START_SUBSCRIPTION_REQUEST,
  START_SUBSCRIPTION_SUCCESS,
  UPDATE_SUBSCRIPTION_FAILURE,
  UPDATE_SUBSCRIPTION_REQUEST,
  UPDATE_SUBSCRIPTION_SUCCESS,
  DELETE_SUBSCRIPTION_FAILURE,
  DELETE_SUBSCRIPTION_REQUEST,
  DELETE_SUBSCRIPTION_SUCCESS,
  UPDATE_PAYMENT_FAILURE,
  UPDATE_PAYMENT_REQUEST,
  UPDATE_PAYMENT_SUCCESS,
  CLEAN_ERROR,
} from "./actionTypes";
import {
  ContactUsSuccessPayload,
  ContactUsFailurePayload,
  ContactUsRequestPayload,
  GetInvoiceFailurePayload,
  GetInvoiceSuccessPayload,
  GetInvoiceIDRequestPayload,
  GetInvoiceIDSuccessPayload,
  GetSubscriptionFailurePayload,
  GetSubscriptionSuccessPayload,
  StartSubscriptionCallbacks,
  StartSubscriptionFailurePayload,
  StartSubscriptionRequestPayload,
  StartSubscriptionSuccessPayload,
  UpdateSubscriptionCallbacks,
  UpdateSubscriptionFailurePayload,
  UpdateSubscriptionRequestPayload,
  UpdateSubscriptionSuccessPayload,
  DeleteSubscriptionCallbacks,
  DeleteSubscriptionFailurePayload,
  DeleteSubscriptionSuccessPayload,
  UpdatePaymentCallbacks,
  UpdatePaymentFailurePayload,
  UpdatePaymentRequestPayload,
  UpdatePaymentSuccessPayload,
  CleanError,
  ContactUsCallbacks,
} from "./types";

export const contactUsRequest = (
  payload: ContactUsRequestPayload,
  callbacks?: ContactUsCallbacks
) => ({
  type: CONTACT_US_REQUEST,
  payload,
  callbacks,
});

export const contactUsSuccess = (payload: ContactUsSuccessPayload) => ({
  type: CONTACT_US_SUCCESS,
  payload,
});

export const contactUsFailure = (payload: ContactUsFailurePayload) => ({
  type: CONTACT_US_FAILURE,
  payload,
});

export const getInvoiceRequest = () => ({
  type: GET_INVOICE_REQUEST,
});

export const getInvoiceSuccess = (payload: GetInvoiceSuccessPayload) => ({
  type: GET_INVOICE_SUCCESS,
  payload,
});

export const getInvoiceFailure = (payload: GetInvoiceFailurePayload) => ({
  type: GET_INVOICE_FAILURE,
  payload,
});

export const getInvoiceIDRequest = (payload: GetInvoiceIDRequestPayload) => ({
  type: GET_INVOICE_ID_REQUEST,
  payload,
});

export const getInvoiceIDSuccess = (payload: GetInvoiceIDSuccessPayload) => ({
  type: GET_INVOICE_ID_SUCCESS,
  payload,
});

export const getInvoiceIDFailure = (payload: GetInvoiceFailurePayload) => ({
  type: GET_INVOICE_ID_FAILURE,
  payload,
});

export const getSubscriptionRequest = () => ({
  type: GET_SUBSCRIPTION_REQUEST,
});

export const getSubscriptionSuccess = (
  payload: GetSubscriptionSuccessPayload
) => ({
  type: GET_SUBSCRIPTION_SUCCESS,
  payload,
});

export const getSubscriptionFailure = (
  payload: GetSubscriptionFailurePayload
) => ({
  type: GET_SUBSCRIPTION_FAILURE,
  payload,
});

export const startSubscriptionRequest = (
  payload: StartSubscriptionRequestPayload,
  callbacks?: StartSubscriptionCallbacks
) => ({
  type: START_SUBSCRIPTION_REQUEST,
  payload,
  callbacks,
});

export const startSubscriptionSuccess = (
  payload: StartSubscriptionSuccessPayload
) => ({
  type: START_SUBSCRIPTION_SUCCESS,
  payload,
});

export const startSubscriptionFailure = (
  payload: StartSubscriptionFailurePayload
) => ({
  type: START_SUBSCRIPTION_FAILURE,
  payload,
});

export const updateSubscriptionRequest = (
  payload: UpdateSubscriptionRequestPayload,
  callbacks?: UpdateSubscriptionCallbacks
) => ({
  type: UPDATE_SUBSCRIPTION_REQUEST,
  payload,
  callbacks,
});

export const updateSubscriptionSuccess = (
  payload: UpdateSubscriptionSuccessPayload
) => ({
  type: UPDATE_SUBSCRIPTION_SUCCESS,
  payload,
});

export const updateSubscriptionFailure = (
  payload: UpdateSubscriptionFailurePayload
) => ({
  type: UPDATE_SUBSCRIPTION_FAILURE,
  payload,
});

export const deleteSubscriptionRequest = (
  callbacks?: DeleteSubscriptionCallbacks
) => ({
  type: DELETE_SUBSCRIPTION_REQUEST,
  callbacks,
});

export const deleteSubscriptionSuccess = (
  payload: DeleteSubscriptionSuccessPayload
) => ({
  type: DELETE_SUBSCRIPTION_SUCCESS,
  payload,
});

export const deleteSubscriptionFailure = (
  payload: DeleteSubscriptionFailurePayload
) => ({
  type: DELETE_SUBSCRIPTION_FAILURE,
  payload,
});

export const updatePaymentRequest = (
  payload: UpdatePaymentRequestPayload,
  callbacks?: UpdatePaymentCallbacks
) => ({
  type: UPDATE_PAYMENT_REQUEST,
  payload,
  callbacks,
});

export const updatePaymentSuccess = (payload: UpdatePaymentSuccessPayload) => ({
  type: UPDATE_PAYMENT_SUCCESS,
  payload,
});

export const updatePaymentFailure = (payload: UpdatePaymentFailurePayload) => ({
  type: UPDATE_PAYMENT_FAILURE,
  payload,
});

export const cleanError = (): CleanError => ({
  type: CLEAN_ERROR,
});
