import { Box, Typography } from "@mui/material";
import { isMobile } from "mobile-device-detect";
import React from "react";
import { useDispatch } from "react-redux";
import { deleteCommentProjectRequest } from "src/store/projects/actions";
import theme from "src/theme";
import { ProjectComment } from "src/types";
import Button from "../Button";
import ModalWrapper from "./ModalWrapper";

const styles = {
  actionButtons: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    columnGap: "10px",
    marginTop: "20px",
  },
  actionButton: {
    textTransform: "none",
  },
  actionButtonDelete: {
    textTransform: "none",
  },
};

interface ModalProps {
  closeModal: () => void;
  fetchSuccess: () => void;
  elem: ProjectComment;
  projectId: string;
  isOpen: boolean;
  modalActionType: {
    action: string;
    page: string;
  };
}

const DeleteProjectComment: React.FC<ModalProps> = ({
  isOpen,
  modalActionType,
  closeModal,
  fetchSuccess,
  elem,
  projectId,
}) => {
  const dispatch = useDispatch();
  const deleteElement = () => {
    dispatch(
      deleteCommentProjectRequest(
        { id: elem.id, projectId },
        { onSuccess: fetchSuccess }
      )
    );
    closeModal();
  };
  return (
    <>
      <ModalWrapper
        closeModal={closeModal}
        isOpen={isOpen}
        maxWidth={isMobile ? "350px" : "500px"}
        modalActionType={modalActionType}
      >
        <>
          <Typography fontSize={14}>
            Are you sure you want to delete the comment?
          </Typography>
          <Box sx={styles.actionButtons}>
            <Box>
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                style={{ padding: "10px 20px" }}
                fullWidth
                sx={styles.actionButton}
                onClick={closeModal}
              >
                <Typography fontSize="14px" color={theme.palette.text.primary}>
                  Cancel
                </Typography>
              </Button>
            </Box>
            <Box>
              <Button
                variant="outlined"
                color="error"
                size="small"
                style={{ padding: "10px 20px" }}
                fullWidth
                sx={styles.actionButtonDelete}
                onClick={deleteElement}
              >
                <Typography fontSize="14px">Delete Comment</Typography>
              </Button>
            </Box>
          </Box>
        </>
      </ModalWrapper>
    </>
  );
};

export default DeleteProjectComment;
