import {
  FETCH_REPORT_REQUEST,
  FETCH_REPORT_SUCCESS,
  FETCH_REPORT_FAILURE,
  REPORT_BREAKDOWN_FAILURE,
  REPORT_BREAKDOWN_REQUEST,
  REPORT_BREAKDOWN_SUCCESS,
  REPORT_TIMESHEET_FAILURE,
  REPORT_TIMESHEET_REQUEST,
  REPORT_TIMESHEET_SUCCESS,
  CLEAR_FILTER,
  ADD_REPORT_FILTERS,
} from "./actionTypes";
import {
  FetchReportRequestPayload,
  FetchReportSuccessPayload,
  FetchReportFailurePayload,
  ReportBreakdownFailurePayload,
  ReportBreakdownRequestPayload,
  ReportBreakdownSuccessPayload,
  ReportTimesheetFailurePayload,
  ReportTimesheetRequestPayload,
  ReportTimesheetSuccessPayload,
  ReportTimesheetCallbacks,
  FetchReportCallbacks,
  CleanFilter,
  AddReportsFilterPayload,
} from "./types";

export const fetchReportRequest = (
  payload: FetchReportRequestPayload,
  callbacks?: FetchReportCallbacks
) => ({
  type: FETCH_REPORT_REQUEST,
  payload,
  callbacks,
});

export const fetchReportSuccess = (payload: FetchReportSuccessPayload) => ({
  type: FETCH_REPORT_SUCCESS,
  payload,
});

export const fetchReportFailure = (payload: FetchReportFailurePayload) => ({
  type: FETCH_REPORT_FAILURE,
  payload,
});

export const reportBreakdownRequest = (
  payload: ReportBreakdownRequestPayload
) => ({
  type: REPORT_BREAKDOWN_REQUEST,
  payload,
});

export const reportBreakdownSuccess = (
  payload: ReportBreakdownSuccessPayload
) => ({
  type: REPORT_BREAKDOWN_SUCCESS,
  payload,
});

export const reportBreakdownFailure = (
  payload: ReportBreakdownFailurePayload
) => ({
  type: REPORT_BREAKDOWN_FAILURE,
  payload,
});

export const reportTimesheetRequest = (
  payload: ReportTimesheetRequestPayload,
  callbacks?: ReportTimesheetCallbacks
) => ({
  type: REPORT_TIMESHEET_REQUEST,
  payload,
  callbacks,
});

export const reportTimesheetSuccess = (
  payload: ReportTimesheetSuccessPayload
) => ({
  type: REPORT_TIMESHEET_SUCCESS,
  payload,
});

export const reportTimesheetFailure = (
  payload: ReportTimesheetFailurePayload
) => ({
  type: REPORT_TIMESHEET_FAILURE,
  payload,
});

export const addReportsFilter = (payload: AddReportsFilterPayload) => ({
  type: ADD_REPORT_FILTERS,
  payload,
});

export const clearFilter = (): CleanFilter => ({
  type: CLEAR_FILTER,
});
