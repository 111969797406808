import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserSelector } from "src/store/auth/selectors";
import Input from "./Input";
import { formatPhoneNumber } from "src/utils";
import Button from "./Button";
import theme from "src/theme";
import * as yup from "yup";
import { useFormik } from "formik";
import { PHONE_NUMBER_REGEX } from "src/constants";
import { cleanError, contactUsRequest } from "src/store/subscription/actions";
import { isFieldHasError } from "src/utils";
import { getSubscriptionSelector } from "src/store/subscription/selectors";
import { toast } from "react-toastify";

const validationSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  email: yup
    .string()
    .email("Email must be valid")
    .required("Email is required"),
  phoneNumber: yup
    .string()
    .required("Phone number is required")
    .matches(PHONE_NUMBER_REGEX, "Phone number is not valid"),
  messageText: yup.string().required("Message text is required"),
});

interface Props {
  closeModal?: () => void;
}

const ContactUsForm: React.FC<Props> = ({ closeModal }) => {
  const subscription = useSelector(getSubscriptionSelector);
  const user = useSelector(getUserSelector);

  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      name: `${user?.firstName} ${user?.lastName}`,
      email: user?.email,
      phoneNumber: formatPhoneNumber(user?.phoneNumber),
      messageText: "Hi. I am interested in Custom Plan.",
    },
    validationSchema: validationSchema,
    onSubmit: ({ name, email, phoneNumber, messageText }) => {
      const phoneNumberWithoutMask = phoneNumber.replace(/\D/g, "");
      const messageTextTrim = messageText.trim();
      const emailTrim = email.trim();
      const nameTrim = name.trim();
      if (!messageTextTrim || !emailTrim || !nameTrim) return;
      dispatch(
        contactUsRequest(
          {
            user: {
              firstName: nameTrim.split(" ").slice(0, -1).join(" "),
              lastName: nameTrim.split(" ").slice(-1).join(" "),
              email: emailTrim.trim(),
              phoneNumber: phoneNumberWithoutMask,
            },
            messageText: messageTextTrim.trim(),
          },
          {
            onSuccess,
          }
        )
      );
      closeModal();
    },
  });

  const onSuccess = () => {
    toast.success(
      <>
        <Typography fontSize="16px" color="#262626">
          Message sent successfully
        </Typography>
      </>
    );
  };

  useEffect(() => {
    dispatch(cleanError());
    return () => {
      dispatch(cleanError());
    };
  }, []);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box>
        <Input
          type="text"
          label="Name"
          fullWidth
          required
          size="small"
          id="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={isFieldHasError({
            formik,
            fieldName: "name",
            isGlobalError: Boolean(subscription.error),
          })}
          helperText={formik.touched.name && formik.errors.name}
        />
      </Box>
      <Box mt="20px">
        <Input
          label="Phone"
          mask="(999) 999-9999"
          fullWidth
          required
          size="small"
          id="phoneNumber"
          value={formatPhoneNumber(formik.values.phoneNumber)}
          onChange={formik.handleChange}
          error={isFieldHasError({
            formik,
            fieldName: "phoneNumber",
            isGlobalError: Boolean(subscription.error),
          })}
          helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
        />
      </Box>
      <Box mt="20px">
        <Input
          label="Email"
          fullWidth
          required
          size="small"
          id="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={isFieldHasError({
            formik,
            fieldName: "email",
            isGlobalError: Boolean(subscription.error),
          })}
          helperText={formik.touched.email && formik.errors.email}
        />
      </Box>
      <Box mt="20px">
        <Input
          label="Message"
          rows={4}
          multiline
          fullWidth
          required
          inputProps={{ maxLength: 1000 }}
          id="messageText"
          value={formik.values.messageText}
          onChange={(e) => {
            if (formik.values.messageText.length > 1001)
              return formik.values.messageText.substring(0, 1000);
            else formik.handleChange(e);
          }}
          error={isFieldHasError({
            formik,
            fieldName: "messageText",
            isGlobalError: Boolean(subscription.error),
          })}
          helperText={formik.touched.messageText && formik.errors.messageText}
        />
        <Typography
          sx={{ width: "100%", textAlign: "right", mb: "20px" }}
          color="#D9D9D9"
        >{`${formik.values.messageText.length} / 1000`}</Typography>
      </Box>
      <Box mt="20px" sx={{ textAlign: "right" }}>
        <Button
          variant="outlined"
          size="small"
          color="secondary"
          style={{
            marginRight: "20px",
            padding: "4px 40px",
          }}
          onClick={closeModal}
        >
          <Typography color={theme.palette.text.primary}>Cancel</Typography>
        </Button>
        <Button
          variant="contained"
          size="small"
          color="primary"
          style={{ padding: "4px 40px" }}
          type="submit"
        >
          <Typography>Send</Typography>
        </Button>
      </Box>
    </form>
  );
};

export default ContactUsForm;
