import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Theme,
  Typography,
  Toolbar,
  IconButton,
  AppBar as MuiAppBar,
} from "@mui/material";
import { App } from "@capacitor/app";
import { Back, HamburgerMenu } from "src/assets/icons";
import { AppRoutes, PageTitle } from "src/constants";
import Sidebar from "./SideBar";
import BottomTabBar from "./BottomTabBar";
import TrialModal from "./ActionsModal/TrialModal";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrganizationSelector,
  getUserSelector,
} from "src/store/auth/selectors";
import Div100vh from "react-div-100vh";
import { parseTime } from "src/utils";
import { StatusBar, Style } from "@capacitor/status-bar";
import { Capacitor } from "@capacitor/core";
import { clearFilter } from "src/store/report/actions";

interface Props {
  children: React.ReactElement;
}

const styles = {
  appBarWrapper: {
    backgroundColor: (theme: Theme) => theme.palette.background.paper,
    boxShadow: "none",
    borderBottom: (theme: Theme) =>
      `1px solid ${theme.palette.background.default}`,
    paddingTop: "34px",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    px: "20px",
  },
  menuIcon: {
    px: 0,
  },
  pageTitle: {
    width: "calc(100% - 210px)",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    textAlign: "center",
    color: (theme: Theme) => theme.palette.text.primary,
    fontSize: "16px",
    lineHeight: 1.2,
    fontWeight: "500",
    boxSizing: "border-box",
  },
  licenseStatus: {
    backgroundColor: (theme: Theme) => theme.palette.info.main,
    padding: "8px 21px",
    borderRadius: "4px",
    color: (theme: Theme) => theme.palette.info.contrastText,
    fontWeight: "500",
    fontSize: "16px",
    cursor: "pointer",
  },
  pageContainer: {
    flexGrow: 1,
    px: "20px",
    py: "20px",
    overflow: "auto",
  },
  pageWrapper: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
};

const AppBar: React.FC<Props> = ({ children }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const organization = useSelector(getOrganizationSelector);
  const user = useSelector(getUserSelector);
  const [modalActionType, setModalActionType] = useState({
    action: "",
    page: "",
  });

  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const handleSidebarOpen = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const openModal = () => {
    setOpen(true);
    setModalActionType((prevState) => ({
      ...prevState,
      action:
        new Date().getTime() < parseTime(organization?.trialExpires).getTime()
          ? "Trial"
          : "Trial expired",
    }));
  };

  useEffect(() => {
    App.addListener("backButton", ({ canGoBack }) => {
      if (!canGoBack) {
        App.exitApp();
      } else
        navigate(
          user && user?.isAdmin && localStorage.getItem("roleMode") === "admin"
            ? AppRoutes.Report
            : AppRoutes.Dashboard
        );
    });
  }, [navigate]);

  useEffect(() => {
    if (
      Capacitor.getPlatform() === "ios" ||
      Capacitor.getPlatform() === "android"
    ) {
      StatusBar.setStyle({ style: Style.Light });
    }
  }, []);

  useEffect(() => {
    if (!pathname.slice(1).includes("report/")) {
      dispatch(clearFilter());
    }
  }, []);

  return (
    <Div100vh>
      <Box sx={styles.pageWrapper}>
        <MuiAppBar position="static" sx={styles.appBarWrapper}>
          <Toolbar sx={styles.toolbar}>
            {pathname.slice(1).split("/").length <= 1 ? (
              <IconButton
                sx={styles.menuIcon}
                size="large"
                color="inherit"
                aria-label="menu"
                onClick={handleSidebarOpen}
              >
                <HamburgerMenu />
              </IconButton>
            ) : (
              <IconButton
                sx={styles.menuIcon}
                size="large"
                color="inherit"
                aria-label="menu"
                onClick={() => {
                  if (pathname.slice(1).includes("report/"))
                    navigate(AppRoutes.Report);
                  else if (pathname.slice(1).includes("projects/view-project"))
                    navigate(AppRoutes.Projects);
                  else navigate(-1);
                }}
              >
                <Back width={26} height={18} color="#000" />
              </IconButton>
            )}
            <Typography sx={styles.pageTitle}>
              {pathname.includes("view")
                ? PageTitle[AppRoutes.ProjectItemMobile]
                : pathname.slice(1).includes("settings/choose-plan") &&
                  !organization.trial
                ? "Update Plan"
                : PageTitle[pathname]}
            </Typography>
            {organization?.trial && Capacitor.getPlatform() != "ios" && (
              <Box
                sx={[
                  styles.licenseStatus,
                  {
                    backgroundColor:
                      new Date().getTime() <
                      parseTime(organization?.trialExpires).getTime()
                        ? (theme: Theme) => theme.palette.info.main
                        : "#FF4D4F",
                    color:
                      new Date().getTime() <
                      parseTime(organization?.trialExpires).getTime()
                        ? (theme: Theme) => theme.palette.info.contrastText
                        : "#FFF",
                  },
                ]}
                onClick={openModal}
              >
                Trial
              </Box>
            )}
          </Toolbar>
          <Sidebar state={isSidebarOpen} action={handleSidebarOpen} />
        </MuiAppBar>
        <Box sx={styles.pageContainer}>{children}</Box>
        <BottomTabBar
          page={pathname.slice(1).split("/").slice(0, 1).join("")}
        />
      </Box>

      {open && (
        <TrialModal
          modalActionType={modalActionType}
          isOpen={open}
          closeModal={() => setOpen(false)}
        />
      )}
    </Div100vh>
  );
};

export default AppBar;
