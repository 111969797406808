import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  fetchAttachments as fetchAttachmentsApi,
  fetchAttachmentsDayByUser as fetchAttachmentsDayByUserApi,
  fetchDailyAttachments as fetchDailyAttachmentsApi,
  fetchAttachmentId as fetchAttachmentIdApi,
  addAttachment as addAttachmentApi,
  updateAttachment as updateAttachmentApi,
  deleteAttachment as deleteAttachmentApi,
  getPresignedUrl as getPresignedUrlApi,
} from "./api";
import {
  fetchAttachmentsSuccess,
  fetchAttachmentsFailure,
  addAttachmentSuccess,
  addAttachmentFailure,
  fetchDailyAttachmentsFailure,
  fetchDailyAttachmentsSuccess,
  getPresignedUrlSuccess,
  getPresignedUrlFailure,
  deleteAttachmentFailure,
  deleteAttachmentSuccess,
  updateAttachmentFailure,
  updateAttachmentSuccess,
  fetchAttachmentIdFailure,
  fetchAttachmentIdSuccess,
  fetchAttachmentsDayByUserFailure,
  fetchAttachmentsDayByUserSuccess,
} from "./actions";
import {
  ADD_ATTACHMENT_REQUEST,
  DELETE_ATTACHMENT_REQUEST,
  FETCH_ATTACHMENTS_DAY_BY_USER_REQUEST,
  FETCH_ATTACHMENTS_REQUEST,
  FETCH_ATTACHMENT_ID_REQUEST,
  FETCH_DAILY_ATTACHMENTS_REQUEST,
  PRESIGNED_URL_REQUEST,
  UPDATE_ATTACHMENT_REQUEST,
} from "./actionTypes";
import { AxiosResponse } from "axios";
import {
  Attachment,
  AttachmentID,
  AttachmentItem,
  Attachments,
  F3Image,
} from "src/types";
import {
  AddAttachmentRequest,
  DeleteAttachmentRequest,
  FetchAttachmentIdRequest,
  FetchAttachmentsDayByUserRequest,
  FetchAttachmentsRequest,
  FetchDailyAttachmentsRequest,
  GetPresignedUrlRequest,
  UpdateAttachmentRequest,
} from "./types";

function* fetchAttachmentsSaga({ payload }: FetchAttachmentsRequest) {
  try {
    const response: AxiosResponse<Attachments> = yield call(
      fetchAttachmentsApi,
      payload
    );
    yield put(
      fetchAttachmentsSuccess({
        attachments: response.data,
      })
    );
  } catch (e) {
    yield put(
      fetchAttachmentsFailure({
        // @ts-ignore
        error: e?.response?.data?.message,
      })
    );
  }
}

function* fetchAttachmentsDayByUserSaga({
  payload,
}: FetchAttachmentsDayByUserRequest) {
  try {
    const response: AxiosResponse<Attachments> = yield call(
      fetchAttachmentsDayByUserApi,
      payload
    );
    yield put(
      fetchAttachmentsDayByUserSuccess({
        attachments: response.data,
      })
    );
  } catch (e) {
    yield put(
      fetchAttachmentsDayByUserFailure({
        // @ts-ignore
        error: e?.response?.data?.message,
      })
    );
  }
}

function* fetchDailyAttachmentsSaga({ payload }: FetchDailyAttachmentsRequest) {
  try {
    const response: AxiosResponse<Attachments> = yield call(
      fetchDailyAttachmentsApi,
      payload
    );
    yield put(
      fetchDailyAttachmentsSuccess({
        attachments: response.data,
      })
    );
  } catch (e) {
    yield put(
      fetchDailyAttachmentsFailure({
        // @ts-ignore
        error: e?.response?.data?.message,
      })
    );
  }
}

function* fetchAttachmentIdSaga({ payload }: FetchAttachmentIdRequest) {
  try {
    const response: AxiosResponse<AttachmentItem> = yield call(
      fetchAttachmentIdApi,
      payload
    );
    yield put(
      fetchAttachmentIdSuccess({
        attachment: response.data,
      })
    );
  } catch (e) {
    yield put(
      fetchAttachmentIdFailure({
        // @ts-ignore
        error: e?.response?.data?.message,
      })
    );
  }
}

function* addAttachmentSaga({ payload, callbacks }: AddAttachmentRequest) {
  try {
    const response: AxiosResponse<Attachment[]> = yield call(
      addAttachmentApi,
      payload
    );
    yield put(
      addAttachmentSuccess({
        attachment: response.data,
      })
    );
    if (callbacks?.onSuccess) {
      yield call(callbacks.onSuccess);
    }
  } catch (e) {
    yield put(
      addAttachmentFailure({
        // @ts-ignore
        error: e?.response?.data?.message,
      })
    );
  }
}

function* updateAttachmentSaga({
  payload,
  callbacks,
}: UpdateAttachmentRequest) {
  try {
    const response: AxiosResponse<Attachment> = yield call(
      updateAttachmentApi,
      payload
    );
    yield put(
      updateAttachmentSuccess({
        attachment: response.data,
      })
    );
    if (callbacks?.onSuccess) {
      yield call(callbacks.onSuccess);
    }
  } catch (e) {
    yield put(
      updateAttachmentFailure({
        // @ts-ignore
        error: e?.response?.data?.message,
      })
    );
  }
}

function* deleteAttachmentSaga({
  payload,
  callbacks,
}: DeleteAttachmentRequest) {
  try {
    const response: AxiosResponse<AttachmentID> = yield call(
      deleteAttachmentApi,
      payload
    );
    yield put(
      deleteAttachmentSuccess({
        data: response.data,
      })
    );
    if (callbacks?.onSuccess) {
      yield call(callbacks.onSuccess);
    }
  } catch (e) {
    yield put(
      deleteAttachmentFailure({
        // @ts-ignore
        error: e?.response?.data?.message,
      })
    );
  }
}

function* getPresignedUrlSaga({ payload, callbacks }: GetPresignedUrlRequest) {
  try {
    const response: AxiosResponse<F3Image[]> = yield call(
      getPresignedUrlApi,
      payload
    );
    yield put(
      getPresignedUrlSuccess({
        f3images: response.data,
      })
    );
    if (callbacks?.onSuccess) {
      yield call(callbacks.onSuccess);
    }
  } catch (e) {
    yield put(
      getPresignedUrlFailure({
        // @ts-ignore
        error: e?.response?.data?.message,
      })
    );
  }
}

function* attachmentsSaga() {
  yield all([
    takeLatest(FETCH_ATTACHMENTS_REQUEST, fetchAttachmentsSaga),
    takeLatest(
      FETCH_ATTACHMENTS_DAY_BY_USER_REQUEST,
      fetchAttachmentsDayByUserSaga
    ),
    takeLatest(FETCH_DAILY_ATTACHMENTS_REQUEST, fetchDailyAttachmentsSaga),
    takeLatest(FETCH_ATTACHMENT_ID_REQUEST, fetchAttachmentIdSaga),
    takeLatest(ADD_ATTACHMENT_REQUEST, addAttachmentSaga),
    takeLatest(UPDATE_ATTACHMENT_REQUEST, updateAttachmentSaga),
    takeLatest(DELETE_ATTACHMENT_REQUEST, deleteAttachmentSaga),
    takeLatest(PRESIGNED_URL_REQUEST, getPresignedUrlSaga),
  ]);
}

export default attachmentsSaga;
