import {
  CHECK_START_WATCH_FAILURE,
  CHECK_START_WATCH_REQUEST,
  CHECK_START_WATCH_SUCCESS,
  START_WATCH_FAILURE,
  START_WATCH_REQUEST,
  START_WATCH_SUCCESS,
  STOP_WATCH_FAILURE,
  STOP_WATCH_REQUEST,
  STOP_WATCH_SUCCESS,
} from "./actionTypes";
import {
  CheckStartWatchFailure,
  CheckStartWatchFailurePayload,
  CheckStartWatchRequest,
  CheckStartWatchSuccess,
  CheckStartWatchSuccessPayload,
  StartWatchCallbacks,
  StartWatchFailure,
  StartWatchFailurePayload,
  StartWatchRequest,
  StartWatchRequestPayload,
  StartWatchSuccess,
  StartWatchSuccessPayload,
  StopWatchCallbacks,
  StopWatchFailure,
  StopWatchFailurePayload,
  StopWatchRequest,
  StopWatchRequestPayload,
  StopWatchSuccess,
  StopWatchSuccessPayload,
} from "./types";

export const startWatchRequest = (
  payload: StartWatchRequestPayload,
  callbacks: StartWatchCallbacks
): StartWatchRequest => ({
  type: START_WATCH_REQUEST,
  payload,
  callbacks,
});

export const startWatchSuccess = (
  payload: StartWatchSuccessPayload
): StartWatchSuccess => ({
  type: START_WATCH_SUCCESS,
  payload,
});

export const startWatchFailure = (
  payload: StartWatchFailurePayload
): StartWatchFailure => ({
  type: START_WATCH_FAILURE,
  payload,
});

export const checkStartWatchRequest = (): CheckStartWatchRequest => ({
  type: CHECK_START_WATCH_REQUEST,
});

export const checkStartWatchSuccess = (
  payload: CheckStartWatchSuccessPayload
): CheckStartWatchSuccess => ({
  type: CHECK_START_WATCH_SUCCESS,
  payload,
});

export const checkStartWatchFailure = (
  payload: CheckStartWatchFailurePayload
): CheckStartWatchFailure => ({
  type: CHECK_START_WATCH_FAILURE,
  payload,
});

export const stopWatchRequest = (
  payload: StopWatchRequestPayload,
  callbacks?: StopWatchCallbacks
): StopWatchRequest => ({
  type: STOP_WATCH_REQUEST,
  payload,
  callbacks,
});

export const stopWatchSuccess = (
  payload: StopWatchSuccessPayload
): StopWatchSuccess => ({
  type: STOP_WATCH_SUCCESS,
  payload,
});

export const stopWatchFailure = (
  payload: StopWatchFailurePayload
): StopWatchFailure => ({
  type: STOP_WATCH_FAILURE,
  payload,
});
