import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useState } from "react";
import DailyCommentItem from "./DailyCommentsItem";
import { useSelector } from "react-redux";
import { getCommentsSelector } from "src/store/comments/selectors";
import { Plus } from "src/assets/icons";
import Button from "../Button";
import { getUserSelector } from "src/store/auth/selectors";
import ActionsModal from "../ActionsModal/ActionsModal";
import { useParams } from "react-router-dom";
import { isMobile } from "mobile-device-detect";

const styles = {
  noComment: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    mt: "20px",
    width: "100%",
    height: isMobile ? "140px" : "340px",
    backgroundColor: "#F0F0F0",
  },
  noCommentText: {
    fontWeight: 400,
    fontSize: isMobile ? "14px" : "18px",
    color: "#8C8C8C",
  },
};

interface Props {
  handleFetch?: () => void;
}

const DailyComments: React.FC<Props> = ({ handleFetch }) => {
  const commentsState = useSelector(getCommentsSelector);
  const user = useSelector(getUserSelector);
  const { id } = useParams();
  const [openCommentModal, setOpenCommentModal] = useState<boolean>(false);
  const modalActionType = {
    action: "Add",
    page: "Comment",
  };

  return (
    <>
      <Box>
        <Box>
          {(!user.isAdmin ||
            (user.isAdmin && localStorage.getItem("roleMode") === "user")) && (
            <Box display="flex" justifyContent="flex-end">
              <Button
                data-sqa-id="add-comment-btn"
                size="small"
                color="primary"
                variant="contained"
                onClick={() => setOpenCommentModal(true)}
              >
                <Plus />
                <Typography
                  data-sqa-id="add-comment-btn-text"
                  fontSize={{ xs: "14px", md: "16px" }}
                  ml={"10px"}
                >
                  Add Comment
                </Typography>
              </Button>
            </Box>
          )}
          {commentsState.isLoading ? (
            <Box
              data-sqa-id="circular-progress-loader"
              display="flex"
              alignItems="center"
              justifyContent="center"
              mt="20px"
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              {Array.isArray(commentsState.comments) &&
              commentsState.comments.length ? (
                commentsState.comments.map((item) => (
                  <DailyCommentItem
                    key={item.id}
                    item={item}
                    handleFetch={handleFetch}
                  />
                ))
              ) : (
                <Box data-sqa-id="no-comments-yet-box" sx={styles.noComment}>
                  <Typography
                    data-sqa-id="no-comments-yet-text"
                    sx={styles.noCommentText}
                  >
                    No comments yet
                  </Typography>
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>
      {openCommentModal && (
        <ActionsModal
          onFetchSuccess={handleFetch}
          elementDate={id}
          modalActionType={modalActionType}
          isOpen={openCommentModal}
          closeModal={() => setOpenCommentModal(false)}
        />
      )}
    </>
  );
};

export default DailyComments;
