export const styles = {
  actionButtons: {
    display: "flex",
    justifyContent: "flex-end",
    columnGap: "10px",
    marginTop: "20px",
  },
  toastStyle: {
    backgroundColor: "#F0F9F0",
    border: "1px solid #67BB66",
    width: "320px",
    paddingBottom: "50px",
  },
  boxContainer: {
    mt: "20px",
    display: "flex",
    justifyContent: "space-between",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "50%",
  },
};
