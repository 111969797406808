export const styles = {
  clockInButtonContainer: {
    display: "flex",
    alignItems: "center",
  },
  clockTimeContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "50%",
    backgroundColor: "#FFF",
    border: "1px solid #F0F0F0",
    borderRadius: "4px",
    p: "30px",
    boxSizing: "border-box",
  },
  clockInButtonStyle: {
    width: "100%",
    maxWidth: "450px",
    height: "84px",
  },
  clockInButtonStop: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    maxWidth: "450px",
    height: "84px",
  },
  clockInButtonText: {
    mobile: {
      fontWeight: 500,
      ml: "10px",
    },
    desktop: {
      fontWeight: 700,
    },
  },
  mapContainer: {
    mt: "24px",
    mb: "10px",
  },
  tabsWrapper: {
    minHeight: "auto",
    width: "100%",
    ".MuiTabs-flexContainer": {
      justifyContent: "space-between",
    },
  },
  tabWrapper: {
    width: "calc(50% - 8px)",
    textTransform: "initial",
    borderBottom: "2px solid #000",
    fontWeight: 600,
    color: "#000",
    lineHeight: 1.2,
    fontSize: "16px",
    minHeight: "auto",
  },
  stopWatch: {
    display: "flex",
    justifyContent: "space-between",
  },
};
