import React from "react";

const HamburgerMenu = () => {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.250349 6.4382H10.7503C10.8486 6.4382 10.9289 6.35784 10.9289 6.25963V5.00963C10.9289 4.91141 10.8486 4.83106 10.7503 4.83106H0.250349C0.152135 4.83106 0.0717773 4.91141 0.0717773 5.00963V6.25963C0.0717773 6.35784 0.152135 6.4382 0.250349 6.4382ZM0.0717773 10.9918C0.0717773 11.09 0.152135 11.1703 0.250349 11.1703H17.7503C17.8486 11.1703 17.9289 11.09 17.9289 10.9918V9.74177C17.9289 9.64356 17.8486 9.5632 17.7503 9.5632H0.250349C0.152135 9.5632 0.0717773 9.64356 0.0717773 9.74177V10.9918ZM17.7503 0.143555H0.250348C0.152134 0.143555 0.0717773 0.223912 0.0717773 0.322126V1.57213C0.0717773 1.67034 0.152134 1.7507 0.250348 1.7507H17.7503C17.8486 1.7507 17.9289 1.67034 17.9289 1.57213V0.322126C17.9289 0.223912 17.8486 0.143555 17.7503 0.143555ZM14.7503 14.2507H0.250348C0.152134 14.2507 0.0717773 14.3311 0.0717773 14.4293V15.6793C0.0717773 15.7775 0.152134 15.8578 0.250348 15.8578H14.7503C14.8486 15.8578 14.9289 15.7775 14.9289 15.6793V14.4293C14.9289 14.3311 14.8486 14.2507 14.7503 14.2507Z"
        fill="#262626"
      />
    </svg>
  );
};

export default HamburgerMenu;
