import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Input from "../Input";
import Button from "../Button";
import ProjectCommentItem from "./ProjectCommentsItem";
import { useDispatch, useSelector } from "react-redux";
import { getProjectSelector } from "src/store/projects/selectors";
import { useParams } from "react-router-dom";
import {
  addCommentProjectRequest,
  fetchCommentProjectRequest,
} from "src/store/projects/actions";
import { ProjectComment } from "src/types";
import { format } from "date-fns";
import { isMobile } from "mobile-device-detect";
import { clientTimezone } from "src/utils";

const styles = {
  boxInputField: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: "1px solid #D9D9D9",
    borderRadius: "4px",
    backgroundColor: "transparent",
    padding: "7px 16px",
  },
  noData: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    mt: "20px",
    width: "100%",
    height: isMobile ? "140px" : "340px",
    backgroundColor: "#F0F0F0",
    borderRadius: "4px",
  },
  noDataText: {
    fontWeight: 400,
    textAlign: "center",
    fontSize: isMobile ? "14px" : "18px",
    color: "#8C8C8C",
  },
};

interface Props {
  fetchProjectID: () => void;
}

const ProjectComments: React.FC<Props> = ({ fetchProjectID }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const projectComments = useSelector(getProjectSelector);
  const [commentText, setCommentText] = useState("");

  const fetchCommentProject = () => {
    dispatch(
      fetchCommentProjectRequest({
        id,
      })
    );
  };

  const handleAddCommentProject = () => {
    const commentTextTrim = commentText.trim();
    if (!commentTextTrim) return setCommentText("");
    dispatch(
      addCommentProjectRequest(
        {
          commentText: commentTextTrim.trim(),
          projectId: id,
          commentDay: format(new Date(), "y-MM-dd"),
          timezone: clientTimezone,
        },
        {
          onSuccess: () => {
            fetchCommentProject();
            fetchProjectID();
          },
        }
      )
    );
    setCommentText("");
  };

  useEffect(() => {
    fetchCommentProject();
  }, []);

  return (
    <>
      <Box sx={{ width: isMobile ? "auto" : "50%" }}>
        <Box sx={styles.boxInputField}>
          <Input
            type="text"
            fullWidth
            size="small"
            placeholder="Type your comment here"
            borderStyle={true}
            id="projectComment"
            value={commentText}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setCommentText(e.target.value)
            }
          />
          <Box sx={{ marginLeft: "15px", width: isMobile ? "auto" : "15%" }}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              fullWidth
              onClick={handleAddCommentProject}
            >
              Send
            </Button>
          </Box>
        </Box>
        <Box>
          {projectComments.isLoadingCommentProject ? (
            <Box
              data-sqa-id="circular-progress-loader"
              display="flex"
              alignItems="center"
              justifyContent="center"
              mt="20px"
            >
              <CircularProgress />
            </Box>
          ) : projectComments.commentProjects.length ? (
            projectComments.commentProjects.map((item: ProjectComment) => (
              <ProjectCommentItem
                key={item.id}
                item={item}
                fetchProjectComments={fetchCommentProject}
                fetchProjectID={fetchProjectID}
              />
            ))
          ) : (
            <Box sx={styles.noData}>
              <Typography
                data-sqa-id="no-comments-yet-text"
                sx={styles.noDataText}
              >
                No projects comments yet
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default ProjectComments;
