export const styles = {
  lineTable: {
    fontWeight: 500,
    fontSize: "16px",
    color: "#262626",
    textTransform: "capitalize",
    borderBottom: "none",
    position: "relative",
    "&::after": {
      content: "''",
      position: "absolute",
      right: 0,
      top: "50%",
      transform: "translateY(-50%)",
      width: "1px",
      height: "50%",
      backgroundColor: "#F0F0F0",
    },
    "&:last-child": {
      width: "92px",
      "&:after": {
        display: "none",
      },
    },
  },
  countContainer: {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px 10px",
    border: "1px solid transparent",
    backgroundColor: "#ECF3FE",
    borderRadius: "4px",
  },
  emptyContainer: {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px 10px",
    border: "1px solid #F0F0F0",
    backgroundColor: "transparent",
    borderRadius: "4px",
  },
  hoverContainer: {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px 10px",
    border: "1px solid #0D6FF0",
    backgroundColor: "#0D6FF0",
    borderRadius: "4px",
  },
  boxContainer: {
    display: "flex",
    alignItems: "center",
  },
  tableHeader: {
    borderBottom: "1px solid #F0F0F0",
  },
  boxColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  columnSort: {
    height: "10px",
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
  },
  tableCell: {
    fontWeight: 400,
    fontSize: "16px",
    color: "#262626",
    cursor: "pointer",
    borderBottom: "none",
  },
};
