import React from "react";
import { User } from "src/types";
import ModalWrapper from "../ModalWrapper";
import DeleteUser from "../DeleteUser";
import ActionWithUser from "./ActionWithUser";
import { isMobile } from "mobile-device-detect";

interface ModalProps {
  closeModal: () => void;
  fetchSuccess: () => void;
  setModalActionType?: any;
  elem?: User;
  isOpen: boolean;
  modalActionType: {
    action: string;
    page: string;
  };
}

const ActionsWithUser: React.FC<ModalProps> = ({
  isOpen,
  elem,
  modalActionType,
  closeModal,
  fetchSuccess,
  setModalActionType,
}) => {
  return (
    <>
      <ModalWrapper
        closeModal={closeModal}
        isOpen={isOpen}
        modalActionType={modalActionType}
        maxWidth={
          modalActionType.action === "Delete"
            ? isMobile
              ? "350px"
              : "450px"
            : "900px"
        }
      >
        <>
          {modalActionType.action === "Delete" ? (
            <DeleteUser
              fetchSuccess={fetchSuccess}
              modalActionType={modalActionType}
              elem={elem}
              closeModal={closeModal}
            />
          ) : (
            <ActionWithUser
              fetchSuccess={fetchSuccess}
              modalActionType={modalActionType}
              elem={elem}
              closeModal={closeModal}
              setModalActionType={setModalActionType}
            />
          )}
        </>
      </ModalWrapper>
    </>
  );
};

export default ActionsWithUser;
