import React, { useRef } from "react";
import { Box, Typography } from "@mui/material";
import Button from "./Button";
import SecurityForm from "./SecurityForm";
import theme from "src/theme";
import { isMobile } from "mobile-device-detect";

const styles = {
  card: {
    p: { xs: "0", md: "20px" },
    borderRadius: "4px",
    border: { xs: "none", md: `1px solid ${theme.palette.background.default}` },
  },
  cardUser: {
    display: "flex",
    alignItems: "center",
  },
  btn: {
    display: "flex",
    marginLeft: "auto",
    padding: "3px 40px",
  },
};

const TabSecurity: React.FC = () => {
  const childRef = useRef<() => void>();

  return (
    <>
      <Box sx={{ maxWidth: "540px" }}>
        <Box sx={styles.card}>
          <SecurityForm ref={childRef} />
        </Box>
        {!isMobile && (
          <Button
            variant="contained"
            size="small"
            color="primary"
            mt="20px"
            style={styles.btn}
            onClick={() => {
              childRef.current();
            }}
          >
            <Typography fontSize={"16px"}>Save</Typography>
          </Button>
        )}
      </Box>
    </>
  );
};

export default TabSecurity;
