import React from "react";
import {
  Container,
  Typography,
  Card,
  Box,
  IconButton,
  Toolbar,
  Theme,
} from "@mui/material";
import { Back } from "src/assets/icons";
import { useNavigate } from "react-router-dom";
import Div100vh from "react-div-100vh";
import TermsOfServiceText from "./TermsOfServiceText";

const styles = {
  appBarWrapper: {
    backgroundColor: (theme: Theme) => theme.palette.background.paper,
    boxShadow: "none",
    borderBottom: (theme: Theme) =>
      `1px solid ${theme.palette.background.default}`,
    paddingTop: "34px",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    px: "20px",
    position: "relative",
  },
  pageTitle: {
    color: (theme: Theme) => theme.palette.text.primary,
    fontSize: "16px",
    fontWeight: "500",
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "24px",
    paddingBottom: "24px",
    overflow: "auto",
    flexGrow: 1,
  },
  cardWrapper: {
    px: {
      xs: "24px",
      md: "30px",
    },
    py: "10px",
    minWidth: {
      md: "400px",
    },
    overflow: "visible",
    border: (theme: Theme) => `1px solid ${theme.palette.background.default}`,
  },
  pageWrapper: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
};

const TermsConditions = () => {
  const navigate = useNavigate();
  return (
    <Div100vh>
      <Box sx={styles.pageWrapper}>
        <Box position="static" sx={styles.appBarWrapper}>
          <Toolbar sx={styles.toolbar}>
            <IconButton
              size="large"
              color="inherit"
              aria-label="menu"
              onClick={() => navigate(-1)}
            >
              <Back width={26} height={18} color="#000" />
            </IconButton>
            <Typography sx={styles.pageTitle}>Terms of Service</Typography>
          </Toolbar>
        </Box>
        <Container sx={styles.container} maxWidth="lg">
          <Card variant="outlined" sx={styles.cardWrapper}>
            <TermsOfServiceText></TermsOfServiceText>
          </Card>
        </Container>
      </Box>
    </Div100vh>
  );
};

export default TermsConditions;
