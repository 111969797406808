import React from "react";
import "./toggle.css";

interface Props {
  name?: string;
  checked: boolean;
  onChange: any;
}

const Toggle: React.FC<Props> = ({ name, checked, onChange }) => {
  return (
    <label className="switch">
      <input
        name={name ? name : "name"}
        type="checkbox"
        checked={checked}
        onChange={onChange}
      />
      <span className="slider round" />
    </label>
  );
};

export default Toggle;
