export const FETCH_PROJECT_REQUEST = "FETCH_PROJECT_REQUEST";
export const FETCH_PROJECT_SUCCESS = "FETCH_PROJECT_SUCCESS";
export const FETCH_PROJECT_FAILURE = "FETCH_PROJECT_FAILURE";

export const FETCH_PROJECT_ALL_REQUEST = "FETCH_PROJECT_ALL_REQUEST";
export const FETCH_PROJECT_ALL_SUCCESS = "FETCH_PROJECT_ALL_SUCCESS";
export const FETCH_PROJECT_ALL_FAILURE = "FETCH_PROJECT_ALL_FAILURE";

export const FETCH_SUBPROJECT_REQUEST = "FETCH_SUBPROJECT_REQUEST";
export const FETCH_SUBPROJECT_SUCCESS = "FETCH_SUBPROJECT_SUCCESS";
export const FETCH_SUBPROJECT_FAILURE = "FETCH_SUBPROJECT_FAILURE";

export const FETCH_PROJECT_ID_REQUEST = "FETCH_PROJECT_ID_REQUEST";
export const FETCH_PROJECT_ID_SUCCESS = "FETCH_PROJECT_ID_SUCCESS";
export const FETCH_PROJECT_ID_FAILURE = "FETCH_PROJECT_ID_FAILURE";

export const FETCH_COMMENT_PROJECT_REQUEST = "FETCH_COMMENT_PROJECT_REQUEST";
export const FETCH_COMMENT_PROJECT_SUCCESS = "FETCH_COMMENT_PROJECT_SUCCESS";
export const FETCH_COMMENT_PROJECT_FAILURE = "FETCH_COMMENT_PROJECT_FAILURE";

export const SEARCH_PROJECT_REQUEST = "SEARCH_PROJECT_REQUEST";
export const SEARCH_PROJECT_SUCCESS = "SEARCH_PROJECT_SUCCESS";
export const SEARCH_PROJECT_FAILURE = "SEARCH_PROJECT_FAILURE";

export const ADD_PROJECT_REQUEST = "ADD_PROJECT_REQUEST";
export const ADD_PROJECT_SUCCESS = "ADD_PROJECT_SUCCESS";
export const ADD_PROJECT_FAILURE = "ADD_PROJECT_FAILURE";

export const UPDATE_PROJECT_REQUEST = "UPDATE_PROJECT_REQUEST";
export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS";
export const UPDATE_PROJECT_FAILURE = "UPDATE_PROJECT_FAILURE";

export const DELETE_PROJECT_REQUEST = "DELETE_PROJECT_REQUEST";
export const DELETE_PROJECT_SUCCESS = "DELETE_PROJECT_SUCCESS";
export const DELETE_PROJECT_FAILURE = "DELETE_PROJECT_FAILURE";

export const ADD_COMMENT_PROJECT_REQUEST = "ADD_COMMENT_PROJECT_REQUEST";
export const ADD_COMMENT_PROJECT_SUCCESS = "ADD_COMMENT_PROJECT_SUCCESS";
export const ADD_COMMENT_PROJECT_FAILURE = "ADD_COMMENT_PROJECT_FAILURE";

export const UPDATE_COMMENT_PROJECT_REQUEST = "UPDATE_COMMENT_PROJECT_REQUEST";
export const UPDATE_COMMENT_PROJECT_SUCCESS = "UPDATE_COMMENT_PROJECT_SUCCESS";
export const UPDATE_COMMENT_PROJECT_FAILURE = "UPDATE_COMMENT_PROJECT_FAILURE";

export const DELETE_COMMENT_PROJECT_REQUEST = "DELETE_COMMENT_PROJECT_REQUEST";
export const DELETE_COMMENT_PROJECT_SUCCESS = "DELETE_COMMENT_PROJECT_SUCCESS";
export const DELETE_COMMENT_PROJECT_FAILURE = "DELETE_COMMENT_PROJECT_FAILURE";
