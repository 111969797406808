import { Theme } from "@mui/material";
import theme from "src/theme";
export const styles = {
  sidebar: {
    "& .MuiPaper-root": {
      width: "80%",
      maxWidth: "300px",
      padding: "20px",
      backgroundColor: `${theme.palette.background.main}`,
      boxSizing: "border-box",
    },
  },
  listItem: {
    height: "56px",
    borderRadius: "4px",
    border: `1px solid ${theme.palette.background.default}`,
    marginBottom: "10px",
    backgroundColor: (theme: Theme) => `${theme.palette.background.paper}`,
  },
  icon: {
    minWidth: "auto",
    width: "24px",
    height: "24px",
    display: "flex",
    marginRight: "16px",
    marginLeft: "7px",
  },
  listItemText: {
    fontSize: 14,
  },
  p: {
    fontSize: 30,
  },
};
