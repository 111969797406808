import { Box, Tab, Tabs, Theme } from "@mui/material";
import React, { useEffect, useState } from "react";
import AppBar from "src/components/AppBar";
import {
  TabProfileSection,
  TabPersonalInfo,
  TabChangePassword,
} from "src/components/";

const styles = {
  tabsProfileContainer: {
    backgroundColor: (theme: Theme) => `${theme.palette.background.paper}`,
    border: (theme: Theme) => `1px solid ${theme.palette.background.default}`,
    borderRadius: "4px",
    padding: "22px",
  },
  clockInButtonContainer: {
    display: "flex",
    alignItems: "center",
  },
  clockInButtonText: {
    fontWeight: 500,
    ml: "10px",
    textTransform: "initial",
  },
  mapContainer: {
    mt: "31px",
  },
  tabsWrapper: {
    minHeight: "auto",
    ".MuiTabs-flexContainer": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
    },
  },
  tabWrapper: {
    borderBottom: "2px solid #000",
    minWidth: "auto",
    maxWidth: "none",
    minHeight: "auto",
    width: `calc(50% - 8px)`,
    fontSize: "12px",
    fontWeight: 600,
    color: "#000",
    textTransform: "none",
    padding: "10px",
  },
};

const Profile = () => {
  const activeTab: number = parseInt(sessionStorage.getItem("activeTab"));
  const [currentTab, setCurrentTab] = useState(activeTab || 0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
    sessionStorage.setItem("activeTab", newValue.toString());
  };

  useEffect(() => {
    return () => {
      sessionStorage.setItem("activeTab", "0");
    };
  }, []);

  return (
    <>
      <AppBar>
        <Box sx={styles.tabsProfileContainer}>
          <Box>
            <Tabs
              sx={styles.tabsWrapper}
              value={currentTab}
              onChange={handleTabChange}
            >
              <Tab sx={styles.tabWrapper} label="Personal Information" />
              <Tab sx={styles.tabWrapper} label="Change Password" />
            </Tabs>
            <TabProfileSection>
              {currentTab === 0 ? <TabPersonalInfo /> : <TabChangePassword />}
            </TabProfileSection>
          </Box>
        </Box>
      </AppBar>
    </>
  );
};

export default Profile;
