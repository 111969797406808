import {
  FETCH_ATTACHMENTS_REQUEST,
  FETCH_ATTACHMENTS_SUCCESS,
  FETCH_ATTACHMENTS_FAILURE,
  ADD_ATTACHMENT_FAILURE,
  ADD_ATTACHMENT_REQUEST,
  ADD_ATTACHMENT_SUCCESS,
  FETCH_DAILY_ATTACHMENTS_FAILURE,
  FETCH_DAILY_ATTACHMENTS_REQUEST,
  FETCH_DAILY_ATTACHMENTS_SUCCESS,
  PRESIGNED_URL_FAILURE,
  PRESIGNED_URL_REQUEST,
  PRESIGNED_URL_SUCCESS,
  UPDATE_ATTACHMENT_FAILURE,
  UPDATE_ATTACHMENT_REQUEST,
  UPDATE_ATTACHMENT_SUCCESS,
  DELETE_ATTACHMENT_FAILURE,
  DELETE_ATTACHMENT_REQUEST,
  DELETE_ATTACHMENT_SUCCESS,
  FETCH_ATTACHMENT_ID_FAILURE,
  FETCH_ATTACHMENT_ID_REQUEST,
  FETCH_ATTACHMENT_ID_SUCCESS,
  FETCH_ATTACHMENTS_DAY_BY_USER_FAILURE,
  FETCH_ATTACHMENTS_DAY_BY_USER_REQUEST,
  FETCH_ATTACHMENTS_DAY_BY_USER_SUCCESS,
  CLEAN_F3IMAGES,
} from "./actionTypes";
import {
  FetchAttachmentsFailure,
  FetchAttachmentsFailurePayload,
  FetchAttachmentsRequest,
  FetchAttachmentsRequestPayload,
  FetchAttachmentsSuccess,
  FetchAttachmentsSuccessPayload,
  AddAttachmentFailure,
  AddAttachmentFailurePayload,
  AddAttachmentRequest,
  AddAttachmentRequestPayload,
  AddAttachmentSuccess,
  AddAttachmentSuccessPayload,
  FetchDailyAttachmentsFailure,
  FetchDailyAttachmentsFailurePayload,
  FetchDailyAttachmentsRequest,
  FetchDailyAttachmentsRequestPayload,
  FetchDailyAttachmentsSuccess,
  FetchDailyAttachmentsSuccessPayload,
  GetPresignedUrlFailure,
  GetPresignedUrlFailurePayload,
  GetPresignedUrlRequest,
  GetPresignedUrlRequestPayload,
  GetPresignedUrlSuccess,
  GetPresignedUrlSuccessPayload,
  GetPresignedUrlCallbacks,
  UpdateAttachmentFailure,
  UpdateAttachmentFailurePayload,
  UpdateAttachmentRequest,
  UpdateAttachmentRequestPayload,
  UpdateAttachmentSuccess,
  UpdateAttachmentSuccessPayload,
  DeleteAttachmentFailure,
  DeleteAttachmentFailurePayload,
  DeleteAttachmentRequest,
  DeleteAttachmentRequestPayload,
  DeleteAttachmentSuccess,
  DeleteAttachmentSuccessPayload,
  FetchAttachmentIdFailure,
  FetchAttachmentIdFailurePayload,
  FetchAttachmentIdRequest,
  FetchAttachmentIdRequestPayload,
  FetchAttachmentIdSuccess,
  FetchAttachmentIdSuccessPayload,
  DeleteAttachmentCallbacks,
  AddAttachmentCallbacks,
  UpdateAttachmentCallbacks,
  FetchAttachmentsDayByUserFailure,
  FetchAttachmentsDayByUserFailurePayload,
  FetchAttachmentsDayByUserRequest,
  FetchAttachmentsDayByUserRequestPayload,
  FetchAttachmentsDayByUserSuccess,
  FetchAttachmentsDayByUserSuccessPayload,
  CleanF3images,
} from "./types";

export const fetchAttachmentsRequest = (
  payload: FetchAttachmentsRequestPayload
): FetchAttachmentsRequest => ({
  type: FETCH_ATTACHMENTS_REQUEST,
  payload,
});

export const fetchAttachmentsSuccess = (
  payload: FetchAttachmentsSuccessPayload
): FetchAttachmentsSuccess => ({
  type: FETCH_ATTACHMENTS_SUCCESS,
  payload,
});

export const fetchAttachmentsFailure = (
  payload: FetchAttachmentsFailurePayload
): FetchAttachmentsFailure => ({
  type: FETCH_ATTACHMENTS_FAILURE,
  payload,
});

export const fetchAttachmentsDayByUserRequest = (
  payload: FetchAttachmentsDayByUserRequestPayload
): FetchAttachmentsDayByUserRequest => ({
  type: FETCH_ATTACHMENTS_DAY_BY_USER_REQUEST,
  payload,
});

export const fetchAttachmentsDayByUserSuccess = (
  payload: FetchAttachmentsDayByUserSuccessPayload
): FetchAttachmentsDayByUserSuccess => ({
  type: FETCH_ATTACHMENTS_DAY_BY_USER_SUCCESS,
  payload,
});

export const fetchAttachmentsDayByUserFailure = (
  payload: FetchAttachmentsDayByUserFailurePayload
): FetchAttachmentsDayByUserFailure => ({
  type: FETCH_ATTACHMENTS_DAY_BY_USER_FAILURE,
  payload,
});

export const fetchDailyAttachmentsRequest = (
  payload: FetchDailyAttachmentsRequestPayload
): FetchDailyAttachmentsRequest => ({
  type: FETCH_DAILY_ATTACHMENTS_REQUEST,
  payload,
});

export const fetchDailyAttachmentsSuccess = (
  payload: FetchDailyAttachmentsSuccessPayload
): FetchDailyAttachmentsSuccess => ({
  type: FETCH_DAILY_ATTACHMENTS_SUCCESS,
  payload,
});

export const fetchDailyAttachmentsFailure = (
  payload: FetchDailyAttachmentsFailurePayload
): FetchDailyAttachmentsFailure => ({
  type: FETCH_DAILY_ATTACHMENTS_FAILURE,
  payload,
});

export const fetchAttachmentIdRequest = (
  payload: FetchAttachmentIdRequestPayload
): FetchAttachmentIdRequest => ({
  type: FETCH_ATTACHMENT_ID_REQUEST,
  payload,
});

export const fetchAttachmentIdSuccess = (
  payload: FetchAttachmentIdSuccessPayload
): FetchAttachmentIdSuccess => ({
  type: FETCH_ATTACHMENT_ID_SUCCESS,
  payload,
});

export const fetchAttachmentIdFailure = (
  payload: FetchAttachmentIdFailurePayload
): FetchAttachmentIdFailure => ({
  type: FETCH_ATTACHMENT_ID_FAILURE,
  payload,
});

export const addAttachmentRequest = (
  payload: AddAttachmentRequestPayload,
  callbacks?: AddAttachmentCallbacks
): AddAttachmentRequest => ({
  type: ADD_ATTACHMENT_REQUEST,
  payload,
  callbacks,
});

export const addAttachmentSuccess = (
  payload: AddAttachmentSuccessPayload
): AddAttachmentSuccess => ({
  type: ADD_ATTACHMENT_SUCCESS,
  payload,
});

export const addAttachmentFailure = (
  payload: AddAttachmentFailurePayload
): AddAttachmentFailure => ({
  type: ADD_ATTACHMENT_FAILURE,
  payload,
});

export const updateAttachmentRequest = (
  payload: UpdateAttachmentRequestPayload,
  callbacks?: UpdateAttachmentCallbacks
): UpdateAttachmentRequest => ({
  type: UPDATE_ATTACHMENT_REQUEST,
  payload,
  callbacks,
});

export const updateAttachmentSuccess = (
  payload: UpdateAttachmentSuccessPayload
): UpdateAttachmentSuccess => ({
  type: UPDATE_ATTACHMENT_SUCCESS,
  payload,
});

export const updateAttachmentFailure = (
  payload: UpdateAttachmentFailurePayload
): UpdateAttachmentFailure => ({
  type: UPDATE_ATTACHMENT_FAILURE,
  payload,
});

export const deleteAttachmentRequest = (
  payload: DeleteAttachmentRequestPayload,
  callbacks?: DeleteAttachmentCallbacks
): DeleteAttachmentRequest => ({
  type: DELETE_ATTACHMENT_REQUEST,
  payload,
  callbacks,
});

export const deleteAttachmentSuccess = (
  payload: DeleteAttachmentSuccessPayload
): DeleteAttachmentSuccess => ({
  type: DELETE_ATTACHMENT_SUCCESS,
  payload,
});

export const deleteAttachmentFailure = (
  payload: DeleteAttachmentFailurePayload
): DeleteAttachmentFailure => ({
  type: DELETE_ATTACHMENT_FAILURE,
  payload,
});

export const getPresignedUrlRequest = (
  payload: GetPresignedUrlRequestPayload,
  callbacks?: GetPresignedUrlCallbacks
): GetPresignedUrlRequest => ({
  type: PRESIGNED_URL_REQUEST,
  payload,
  callbacks,
});

export const getPresignedUrlSuccess = (
  payload: GetPresignedUrlSuccessPayload
): GetPresignedUrlSuccess => ({
  type: PRESIGNED_URL_SUCCESS,
  payload,
});

export const getPresignedUrlFailure = (
  payload: GetPresignedUrlFailurePayload
): GetPresignedUrlFailure => ({
  type: PRESIGNED_URL_FAILURE,
  payload,
});

export const cleanF3images = (): CleanF3images => ({
  type: CLEAN_F3IMAGES,
});
