import axios, { AxiosError, AxiosResponse } from "axios";
import { logout } from "src/store/auth/actions";
import store from "../store";

const axiosInstance = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: process.env.REACT_APP_BASE_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(function (config) {
  const authToken = store.getState().auth.token;
  config.headers.Authorization = authToken ? `Bearer ${authToken}` : "";
  return config;
});

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    const originalConfig = error.config;
    if (originalConfig.url !== "/sign-in" && error.response.status === 401) {
      store.dispatch(logout());
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
