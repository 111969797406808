import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  fetchProjectRequest,
  searchProjectRequest,
} from "src/store/projects/actions";
import AddProject from "src/components/ActionsModal/AddProject";
import DeviceWrapper from "src/pages/DeviceWrapper";
import { isMobile } from "mobile-device-detect";
import ProjectsMobile from "./ProjectsMobile";
import ProjectsWeb from "./ProjectsWeb";

const Projects = () => {
  const [page, setPage] = useState<number>(1);
  const pageSize: number = 10;
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortBy, setSortBy] = useState("projectName");
  const [sortBySearch, setSortBySearch] = useState("project_name");
  const [sortByTableName, setSortByTableName] = useState("Project Name");
  const [searchProjectValue, setSearchProjectValue] = useState("");
  const [openActionModal, setOpenActionModal] = useState(false);
  const modalActionType = {
    action: "Add",
    page: "Project",
  };
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const fetchProjects = () => {
    dispatch(
      fetchProjectRequest(
        {
          currentPage: isMobile ? 1 : page,
          pageSize: isMobile ? page * pageSize : pageSize,
          sortBy: sortBy,
          sortOrder: sortOrder,
        },
        {
          onSuccess: () => setIsLoading(false),
        }
      )
    );
  };

  useEffect(() => {
    if (isMobile && page !== 1) setIsLoading(false);
    else setIsLoading(true);
    if (searchProjectValue) {
      const timer = setTimeout(() => {
        dispatch(
          searchProjectRequest(
            {
              currentPage: isMobile ? 1 : page,
              pageSize: isMobile ? page * pageSize : pageSize,
              sortBy: sortBySearch,
              sortOrder: sortOrder,
              searchQuery: searchProjectValue,
            },
            {
              onSuccess: () => setIsLoading(false),
            }
          )
        );
      }, 1000);
      return () => clearTimeout(timer);
    } else {
      fetchProjects();
    }
  }, [searchProjectValue, page, sortBy, sortOrder]);

  const requestSort = (elem: string) => {
    if (elem === "ID") {
      setSortBy("numericId");
      setSortBySearch("numeric_id");
      setSortByTableName("ID");
    }
    if (elem === "Project Name") {
      setSortBy("projectName");
      setSortBySearch("project_name");
      setSortByTableName("Project Name");
    }
    if (elem === "Project Address") {
      setSortBy("address");
      setSortBySearch("a.address");
      setSortByTableName("Project Address");
    }
    if (elem === "Subprojects") {
      setSortBy("projectCountV.subProjectCount");
      setSortByTableName("Subprojects");
    }
    if (elem === "Project Comments") {
      setSortBy("projectCountV.commentsCount");
      setSortByTableName("Project Comments");
    }
  };

  return (
    <>
      <DeviceWrapper>
        <>
          {isMobile ? (
            <ProjectsMobile
              fetchProjects={fetchProjects}
              searchProjectValue={searchProjectValue}
              pageSize={pageSize}
              setPage={setPage}
              setSearchProjectValue={setSearchProjectValue}
              setSortOrder={setSortOrder}
              sortOrder={sortOrder}
              setSortBy={setSortBy}
              sortBy={sortBy}
              setSortBySearch={setSortBySearch}
              sortBySearch={sortBySearch}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
            />
          ) : (
            <ProjectsWeb
              isLoading={isLoading}
              searchProjectValue={searchProjectValue}
              page={page}
              sortOrder={sortOrder}
              sortByTableName={sortByTableName}
              setSearchProjectValue={setSearchProjectValue}
              setOpenActionModal={setOpenActionModal}
              handleChangePage={handleChangePage}
              fetchProjects={fetchProjects}
              requestSort={requestSort}
              setSortOrder={setSortOrder}
            />
          )}
        </>
      </DeviceWrapper>

      {openActionModal && (
        <AddProject
          modalActionType={modalActionType}
          subprojectId={null}
          showToast={true}
          fetchSuccess={fetchProjects}
          isOpen={openActionModal}
          closeModal={() => setOpenActionModal(false)}
        />
      )}
    </>
  );
};

export default Projects;
