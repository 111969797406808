import React, { useCallback } from "react";
import { Container, Typography, Card, Box, Link, Theme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Button } from "src/components";
import { AppRoutes } from "src/constants";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    paddingTop: "24px",
    paddingBottom: "24px",
  },
  cardWrapper: {
    width: "100%",
    mt: "33px",
    boxSizing: "border-box",
    borderColor: (theme: Theme) => `${theme.palette.background.default}`,
    px: {
      xs: "24px",
      md: "30px",
    },
    py: "50px",
    maxWidth: {
      xs: "350px",
      md: "460px",
    },
  },
  headerWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  subText: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#8C8C8C",
  },
};

const CheckEmail = () => {
  const navigate = useNavigate();

  const redirectToSignIn = useCallback(() => {
    navigate(AppRoutes.SignIn);
  }, [navigate]);

  const redirectToPrivacyPolicy = useCallback(() => {
    navigate(AppRoutes.PrivacyPolicy);
  }, [navigate]);

  const redirectToTermsConditions = useCallback(() => {
    navigate(AppRoutes.TermsConditions);
  }, [navigate]);

  return (
    <Container sx={styles.container} maxWidth="sm">
      <Typography
        data-sqa-id="page-title"
        color="primary"
        fontWeight="800"
        fontSize={{ xs: "24px", md: "36px" }}
      >
        BusyBucket
      </Typography>
      <Card variant="outlined" sx={styles.cardWrapper}>
        <Box sx={styles.headerWrapper}>
          <Typography
            fontWeight="bold"
            fontSize={{ xs: "20px", md: "24px" }}
            mb="14px"
          >
            Check Your Email
          </Typography>
          <Typography sx={styles.subText}>
            We have sent password recovery instructions to your email.
          </Typography>
        </Box>
        <Box mt="30px">
          <Button
            variant="contained"
            color="primary"
            fullWidth
            size="small"
            onClick={redirectToSignIn}
          >
            <Typography fontSize={{ xs: "14px", md: "16px" }}>
              Sign In
            </Typography>
          </Button>
        </Box>
      </Card>
      <Link
        data-sqa-id="terms-conditions-footer-link"
        mt="30px"
        variant="body1"
        component="button"
        fontSize={{ xs: "12px", md: "14px" }}
        color="secondary"
        sx={{ marginTop: "30px" }}
        onClick={redirectToTermsConditions}
      >
        Terms of Service
      </Link>
      <Link
        data-sqa-id="terms-conditions-footer-link"
        variant="body1"
        color="secondary"
        component="button"
        fontSize={{ xs: "12px", md: "14px" }}
        sx={{ marginTop: "20px" }}
        onClick={redirectToPrivacyPolicy}
      >
        Privacy Policy
      </Link>
    </Container>
  );
};

export default CheckEmail;
