import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  IconButton,
  Tab,
  Tabs,
  Theme,
  Toolbar,
  Typography,
} from "@mui/material";
import { styles } from "./history.module";
import { Add, Back, Plus } from "src/assets/icons";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import PullToRefresh from "react-simple-pull-to-refresh";
import { getTodaysClocksSelector } from "src/store/clocks/selectors";
import TabDataSection from "src/components/TabDataSection";
import TabDataItem from "src/components/TabDataItem";
import theme from "src/theme";
import { Button, ModalTodaysClockRecord } from "src/components";
import { ClockRecord } from "src/types";
import { getCommentsSelector } from "src/store/comments/selectors";
import TabCommentsData from "src/components/TabCommentsData";
import ActionsModal from "src/components/ActionsModal/ActionsModal";
import { getAttachmentsSelector } from "src/store/attachments/selectors";
import TabAttachmentsData from "src/components/TabAttachmentsData";
import WeekCalendar from "src/components/WeekCalendar";
import { formatDate, secondsToTime, parseTime } from "src/utils";
import AddAttachmentModal from "src/components/ActionsModal/AddAttachmentModal";
import { getOrganizationSelector } from "src/store/auth/selectors";
import { AppRoutes } from "src/constants";
import TrialModal from "src/components/ActionsModal/TrialModal";
import { Capacitor } from "@capacitor/core";

interface Props {
  currentTab: number;
  handleTabChange: (event: React.SyntheticEvent, newValue: number) => void;
  handleFetch: () => void;
}

const DayPageMobile: React.FC<Props> = ({
  currentTab,
  handleTabChange,
  handleFetch,
}) => {
  const clocks = useSelector(getTodaysClocksSelector);
  const commentState = useSelector(getCommentsSelector);
  const attachmentState = useSelector(getAttachmentsSelector);
  const organization = useSelector(getOrganizationSelector);
  const { id } = useParams();
  const route = useLocation().state as { id: string };
  const [routeBack, setRouteBack] = useState("");
  const navigate = useNavigate();
  const [modalsState, setModalsState] = useState<string>("");
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [openAttachmentModal, setOpenAttachmentModal] =
    useState<boolean>(false);
  const [openCommentModal, setOpenCommentModal] = useState<boolean>(false);
  const [modalData, setModalData] = useState<string>(null);
  const [modalActionType, setModalActionType] = useState({
    action: "",
    page: "",
  });

  const handleOpenModal = (modalState: string, data?: any) => {
    if (currentTab === 0) {
      setOpenModal(true);
      setModalData(data);
      setModalsState(modalState);
    }
    if (currentTab === 1) {
      setOpenAttachmentModal(true);
      setModalActionType({
        page: "an Action",
        action: modalState,
      });
    }
    if (currentTab === 2) {
      setOpenCommentModal(true);
      setModalActionType({
        page: "Comment",
        action: modalState,
      });
    }
  };

  const handleOpenTrialModal = () => {
    setOpen(true);
    setModalActionType((prevState) => ({
      ...prevState,
      action:
        new Date().getTime() < parseTime(organization?.trialExpires).getTime()
          ? "Trial"
          : "Trial expired",
    }));
  };

  useEffect(() => {
    setRouteBack(route ? route?.id : AppRoutes.History);
  }, []);

  const handleRefresh = async () => {
    handleFetch();
  };

  return (
    <>
      <PullToRefresh onRefresh={handleRefresh}>
        <Box sx={styles.pageWrapper}>
          <Box sx={styles.appBarWrapper}>
            <Toolbar sx={styles.toolbar}>
              <IconButton
                sx={styles.menuIcon}
                size="large"
                color="inherit"
                aria-label="menu"
                onClick={() => navigate(routeBack)}
              >
                <Back width={26} height={18} color="#000" />
              </IconButton>
              <Typography sx={styles.pageTitle}>History</Typography>
              {organization?.trial && Capacitor.getPlatform() != "ios" && (
                <Box
                  sx={[
                    styles.licenseStatus,
                    {
                      backgroundColor:
                        new Date().getTime() <
                        parseTime(organization?.trialExpires).getTime()
                          ? (theme: Theme) => theme.palette.info.main
                          : "#FF4D4F",
                      color:
                        new Date().getTime() <
                        parseTime(organization?.trialExpires).getTime()
                          ? (theme: Theme) => theme.palette.info.contrastText
                          : "#FFF",
                    },
                  ]}
                  onClick={handleOpenTrialModal}
                >
                  Trial
                </Box>
              )}
            </Toolbar>
          </Box>
          <Box sx={{ p: "20px", flexGrow: 1, overflow: "auto" }}>
            <Box>
              <Box>
                <Box>
                  <WeekCalendar date={id} />
                </Box>
                <Box sx={styles.boxHistoryDayTotal}>
                  <Typography sx={styles.historyDayTotalTitle}>
                    Daily Total
                  </Typography>
                  {!clocks.isLoading ? (
                    <Typography sx={styles.historyDayTotalTime}>
                      {secondsToTime(clocks.todaysClocks.dayTotal)}
                    </Typography>
                  ) : (
                    <Box
                      data-sqa-id="circular-progress-loader"
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-start"
                    >
                      <CircularProgress />
                    </Box>
                  )}
                </Box>
              </Box>
              <Box sx={styles.boxHistoryDayTotal}>
                <Tabs
                  sx={styles.tabsWrapper}
                  value={currentTab}
                  variant="scrollable"
                  onChange={handleTabChange}
                >
                  <Tab sx={styles.tabWrapper} label="Daily Entries" />
                  <Tab sx={styles.tabWrapper} label="Attachments" />
                  <Tab sx={styles.tabWrapper} label="Comments" />
                </Tabs>
                <TabDataSection px="0" mt="16px" border="none">
                  {!clocks.isLoading &&
                  !commentState.isLoading &&
                  !attachmentState.isLoading ? (
                    <>
                      {currentTab === 0 &&
                        (clocks.todaysClocks?.timeClockRecords?.length ? (
                          clocks.todaysClocks?.timeClockRecords.map(
                            (clock: ClockRecord) => (
                              <TabDataItem
                                key={clock.id}
                                currentTab={0}
                                isHistoryView={true}
                                clock={clock}
                                onClick={() => {
                                  handleOpenModal("GENERAL_ENTRY_MODAL", clock);
                                }}
                              />
                            )
                          )
                        ) : (
                          <Box my={"20px"}>
                            <Typography
                              fontSize={14}
                              fontWeight={600}
                              color={theme.palette.text.secondary}
                              textAlign="center"
                            >
                              No time entries yet
                            </Typography>
                          </Box>
                        ))}
                      {currentTab === 1 &&
                        Array.isArray(attachmentState.attachments) && (
                          <TabAttachmentsData
                            attachmentsState={attachmentState.bucketUrlPrefix}
                            attachmentDay={attachmentState.attachments}
                          />
                        )}
                      {currentTab === 1 &&
                        attachmentState.attachments === null && (
                          <Box sx={styles.noData}>
                            <Typography
                              data-sqa-id="no-attachments-uploaded-yet"
                              sx={styles.noDataText}
                            >
                              No attachments uploaded yet
                            </Typography>
                          </Box>
                        )}
                      {currentTab === 1 && (
                        <Button
                          mt="10px"
                          sx={{ mb: "30px" }}
                          fullWidth
                          variant="contained"
                          color="primary"
                          onClick={() => handleOpenModal("Choose")}
                        >
                          <Add />
                          <Typography
                            fontSize={{ xs: "14px", md: "16px" }}
                            ml={"10px"}
                          >
                            Add Attachment
                          </Typography>
                        </Button>
                      )}
                      {currentTab === 2 &&
                        Array.isArray(commentState.comments) && (
                          <TabCommentsData
                            commentDay={commentState?.comments}
                            handleFetch={handleFetch}
                          />
                        )}
                      {currentTab === 2 && (
                        <Button
                          data-sqa-id="add-comment-btn"
                          mt="10px"
                          sx={{ mb: "30px" }}
                          fullWidth
                          variant="contained"
                          color="primary"
                          onClick={() => handleOpenModal("Add")}
                        >
                          <Add />
                          <Typography
                            data-sqa-id="add-comment-btn-text"
                            fontSize={{ xs: "14px", md: "16px" }}
                            ml={"10px"}
                          >
                            Add Comment
                          </Typography>
                        </Button>
                      )}
                      {currentTab === 0 && (
                        <Button
                          data-sqa-id="manual-entry-btn"
                          color="primary"
                          mt="10px"
                          fullWidth
                          variant="contained"
                          disabled={
                            organization?.trial &&
                            new Date().getTime() >
                              parseTime(organization?.trialExpires).getTime()
                          }
                          onClick={() =>
                            handleOpenModal(
                              "MANUAL_ENTRY_MODAL",
                              formatDate(id)
                            )
                          }
                        >
                          <Plus
                            color={
                              organization?.trial &&
                              new Date().getTime() >
                                parseTime(organization?.trialExpires).getTime()
                                ? "#d9d9d9"
                                : "#FFF"
                            }
                          />
                          <Typography
                            data-sqa-id="manual-entry-btn-text"
                            fontSize={{ xs: "14px", md: "16px" }}
                            ml={"10px"}
                          >
                            Manual Entry
                          </Typography>
                        </Button>
                      )}
                    </>
                  ) : (
                    <Box
                      data-sqa-id="circular-progress-loader"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <CircularProgress />
                    </Box>
                  )}
                </TabDataSection>
              </Box>
            </Box>
          </Box>
        </Box>
      </PullToRefresh>
      {openModal && (
        <ModalTodaysClockRecord
          onFetchSuccess={handleFetch}
          closeModal={() => setOpenModal(false)}
          isOpen={openModal}
          modalData={modalData}
          modalsState={modalsState}
          setModalsState={setModalsState}
          currentTab={currentTab}
        />
      )}

      {open && (
        <TrialModal
          modalActionType={modalActionType}
          isOpen={open}
          closeModal={() => setOpen(false)}
        />
      )}

      {openCommentModal && (
        <ActionsModal
          onFetchSuccess={handleFetch}
          elementDate={id}
          modalActionType={modalActionType}
          isOpen={openCommentModal}
          closeModal={() => setOpenCommentModal(false)}
        />
      )}

      {openAttachmentModal && (
        <AddAttachmentModal
          onFetchSuccess={handleFetch}
          elementDate={id}
          modalActionType={modalActionType}
          isOpen={openAttachmentModal}
          closeModal={() => setOpenAttachmentModal(false)}
        />
      )}
    </>
  );
};

export default DayPageMobile;
