import axios from "../../utils/axios";
import {
  AddCommentRequestPayload,
  DeleteCommentRequestPayload,
  FetchCommentsDayByUserRequestPayload,
  FetchCommentsRequestPayload,
  FetchDailyCommentsRequestPayload,
  UpdateCommentRequestPayload,
} from "./types";

export const fetchComments = (payload: FetchCommentsRequestPayload) => {
  return axios.get(
    `/comment?currentPage=${payload.currentPage}&pageSize=${payload.pageSize}&sortOrder=${payload.sortOrder}&fromDate=${payload.fromDate}&toDate=${payload.toDate}&sortBy=${payload.sortBy}`
  );
};

export const fetchCommentsDayByUser = (
  payload: FetchCommentsDayByUserRequestPayload
) => {
  return axios.get(`/comment/${payload.date}/user/${payload.id}`);
};

export const fetchDailyComments = (
  payload: FetchDailyCommentsRequestPayload
) => {
  return axios.get(`/comment/day/${payload.date}`);
};

export const addComment = (payload: AddCommentRequestPayload) => {
  return axios.post("/comment", payload);
};

export const updateComment = (payload: UpdateCommentRequestPayload) => {
  return axios.put("/comment", payload);
};

export const deleteComment = (payload: DeleteCommentRequestPayload) => {
  return axios.delete(`/comment/${payload.id}`);
};
