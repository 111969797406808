export const FETCH_COMMENTS_DAY_BY_USER_REQUEST =
  "FETCH_COMMENTS_DAY_BY_USER_REQUEST";
export const FETCH_COMMENTS_DAY_BY_USER_SUCCESS =
  "FETCH_COMMENTS_DAY_BY_USER_SUCCESS";
export const FETCH_COMMENTS_DAY_BY_USER_FAILURE =
  "FETCH_COMMENTS_DAY_BY_USER_FAILURE";

export const FETCH_COMMENTS_REQUEST = "FETCH_COMMENTS_REQUEST";
export const FETCH_COMMENTS_SUCCESS = "FETCH_COMMENTS_SUCCESS";
export const FETCH_COMMENTS_FAILURE = "FETCH_COMMENTS_FAILURE";

export const FETCH_DAILY_COMMENTS_REQUEST = "FETCH_DAILY_COMMENTS_REQUEST";
export const FETCH_DAILY_COMMENTS_SUCCESS = "FETCH_DAILY_COMMENTS_SUCCESS";
export const FETCH_DAILY_COMMENTS_FAILURE = "FETCH_DAILY_COMMENTS_FAILURE";

export const ADD_COMMENT_REQUEST = "ADD_COMMENT_REQUEST";
export const ADD_COMMENT_SUCCESS = "ADD_COMMENT_SUCCESS";
export const ADD_COMMENT_FAILURE = "ADD_COMMENT_FAILURE";

export const UPDATE_COMMENT_REQUEST = "UPDATE_COMMENT_REQUEST";
export const UPDATE_COMMENT_SUCCESS = "UPDATE_COMMENT_SUCCESS";
export const UPDATE_COMMENT_FAILURE = "UPDATE_COMMENT_FAILURE";

export const DELETE_COMMENT_REQUEST = "DELETE_COMMENT_REQUEST";
export const DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS";
export const DELETE_COMMENT_FAILURE = "DELETE_COMMENT_FAILURE";
