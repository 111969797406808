import {
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import {
  Comments,
  DeleteForWeb,
  ImageIcon,
  ViewForWeb,
} from "src/assets/icons";
import { ReportItem } from "src/types";
import { secondsToTime, parseTime } from "src/utils";
import reportTable from "./reportTable.json";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "src/constants";
import { format } from "date-fns";
import DeleteTimeClock from "src/components/ActionsModal/DeleteTimeClock";
import { styles } from "./reportTable.module";
import {
  CheckBox,
  CheckBoxOutlineBlank,
  CheckBoxIndeterminate,
} from "src/assets/icons";

interface Props {
  report: ReportItem[];
  fetchReport: () => void;
  requestSort: (elem: string) => void;
  setSortOrder: (order: string) => void;
  sortBy: string;
  sortOrder: any;
  selectedItems: string[];
  setSelectedItems: (elem: any) => void;
  elem: any[];
  setElem: (elem: any) => void;
}

const ReportTable: React.FC<Props> = ({
  report,
  fetchReport,
  requestSort,
  setSortOrder,
  sortBy,
  sortOrder,
  selectedItems,
  setSelectedItems,
  elem,
  setElem,
}) => {
  const navigate = useNavigate();
  const [openDeleteReportModal, setOpenDeleteReportModal] = useState(false);
  const [hover, setHover] = useState("");
  const [modalActionType, setModalActionType] = useState({
    action: "",
    page: "Report",
  });

  const handleOpenModal = (type: string, item?: any) => {
    setOpenDeleteReportModal(true);
    setElem((prevState) => [
      ...prevState,
      {
        userId: item.user.id,
        day: item.day,
      },
    ]);
    setModalActionType((prevState) => ({
      ...prevState,
      action: type,
    }));
  };

  const handleSelectAllItems = (event) => {
    setSelectedItems(event.target.checked ? report.map((elem) => elem.id) : []);
    setElem(
      event.target.checked
        ? report.map((elem) => ({
            userId: elem.user.id,
            day: elem.day,
          }))
        : []
    );
  };

  const handleSelectOneItem = (event, item) => {
    if (!selectedItems.includes(item.id)) {
      setSelectedItems((prevSelected) => [...prevSelected, item.id]);
      setElem((prevState) => [
        ...prevState,
        {
          userId: item.user.id,
          day: item.day,
        },
      ]);
    } else {
      setSelectedItems((prevSelected) =>
        prevSelected.filter((id) => id !== item.id)
      );
      setElem((prevState) => prevState.filter((elem) => elem.day !== item.day));
    }
  };

  const selectedSomeItems =
    selectedItems.length > 0 && selectedItems.length < report.length;
  const selectedAllItems = selectedItems.length === report?.length;

  const chooseType = (elem, item) => {
    switch (elem.type) {
      case "date":
        return format(parseTime(item[elem.array[0]]), "MMMM d, y");
      case "name":
        return `${item[elem.array[0]]?.firstName} ${
          item[elem.array[0]]?.lastName
        }`;
      case "dailyTotal":
        return secondsToTime(item[elem.array[0]]);
      case "dailyItems":
        return (
          <Box sx={styles.boxContainer}>
            <Box
              sx={
                hover === "attachmentsCount" + item.id
                  ? styles.hoverContainer
                  : item[elem.array[0]] === 0
                  ? styles.emptyContainer
                  : styles.countContainer
              }
              onMouseEnter={() => setHover("attachmentsCount" + item.id)}
              onMouseLeave={() => setHover("")}
              onClick={() => {
                navigate(AppRoutes.Report + `/${item.id}`, {
                  state: {
                    name: {
                      id: item.user.id,
                      userName: `${item.user.firstName} ${item.user.lastName}`,
                    },
                    date: item.day,
                    atachmentsCount: item.attachmentsCount,
                    commentsCount: item.commentsCount,
                  },
                });
                sessionStorage.setItem("activeTab", "1");
              }}
            >
              <ImageIcon
                color={
                  hover === "attachmentsCount" + item.id
                    ? "#FFF"
                    : item[elem.array[0]] === 0
                    ? "#8C8C8C"
                    : "#0D6FF0"
                }
              />
              <Typography
                ml="10px"
                color={
                  hover === "attachmentsCount" + item.id
                    ? "#FFF"
                    : item[elem.array[0]] === 0
                    ? "#8C8C8C"
                    : "#0D6FF0"
                }
              >
                {item[elem.array[0]]}
              </Typography>
            </Box>
            <Box
              sx={
                hover === "commentsCount" + item.id
                  ? styles.hoverContainer
                  : item[elem.array[1]] === 0
                  ? styles.emptyContainer
                  : styles.countContainer
              }
              onMouseEnter={() => setHover("commentsCount" + item.id)}
              onMouseLeave={() => setHover("")}
              onClick={() => {
                navigate(AppRoutes.Report + `/${item.id}`, {
                  state: {
                    name: {
                      id: item.user.id,
                      userName: `${item.user.firstName} ${item.user.lastName}`,
                    },
                    date: item.day,
                    atachmentsCount: item.attachmentsCount,
                    commentsCount: item.commentsCount,
                  },
                });
                sessionStorage.setItem("activeTab", "2");
              }}
            >
              <Comments
                size={18}
                color={
                  hover === "commentsCount" + item.id
                    ? "#FFF"
                    : item[elem.array[1]] === 0
                    ? "#8C8C8C"
                    : "#0D6FF0"
                }
              />
              <Typography
                ml="10px"
                color={
                  hover === "commentsCount" + item.id
                    ? "#FFF"
                    : item[elem.array[1]] === 0
                    ? "#8C8C8C"
                    : "#0D6FF0"
                }
              >
                {item[elem.array[1]]}
              </Typography>
            </Box>
          </Box>
        );
      case "project":
        return item[elem.array[0]] ? (
          <Box>
            <Typography fontSize="12px" fontWeight={400} color="#8C8C8C">
              Project ID{" "}
              {item[elem.array[0]].map((elem, i, arr) => {
                if (i < arr.length - 1) return `${elem.numericId}, `;
                else return `${elem.numericId}`;
              })}
            </Typography>
            <Typography fontSize="16px" fontWeight={400} color="#262626">
              {item[elem.array[0]].map((elem, i, arr) => {
                if (i < arr.length - 1) return `${elem.projectName}, `;
                else return `${elem.projectName}`;
              })}
            </Typography>
          </Box>
        ) : (
          ""
        );
      case "actions":
        return (
          <Box sx={styles.boxWrapper}>
            <Box
              mr="20px"
              onClick={() => {
                navigate(AppRoutes.Report + `/${item.id}`, {
                  state: {
                    name: {
                      id: item.user.id,
                      userName: `${item.user.firstName} ${item.user.lastName}`,
                    },
                    date: item.day,
                    atachmentsCount: item.attachmentsCount,
                    commentsCount: item.commentsCount,
                  },
                });
                sessionStorage.setItem("activeTab", "0");
              }}
              onMouseEnter={() => setHover("view" + item.id)}
              onMouseLeave={() => setHover("")}
            >
              <ViewForWeb
                color={hover === "view" + item.id ? "#0D6FF0" : "#8C8C8C"}
              />
            </Box>
            <Box
              onClick={() => handleOpenModal("Delete", item)}
              onMouseEnter={() => setHover("delete" + item.id)}
              onMouseLeave={() => setHover("")}
            >
              <DeleteForWeb
                color={hover === "delete" + item.id ? "#FF0003" : "#FF4D4F"}
              />
            </Box>
          </Box>
        );
    }
  };
  return (
    <>
      <TableContainer>
        <Table>
          <TableHead
            sx={{
              backgroundColor: "#FAFAFA",
            }}
          >
            <TableRow sx={styles.tableHeader}>
              <TableCell sx={styles.lineTable}>
                <Checkbox
                  checked={selectedAllItems}
                  indeterminate={selectedSomeItems}
                  onChange={handleSelectAllItems}
                  sx={styles.checkbox}
                  icon={
                    <CheckBoxOutlineBlank
                      color="#D9D9D9"
                      width={20}
                      height={20}
                    />
                  }
                  checkedIcon={<CheckBox width={20} height={20} />}
                  indeterminateIcon={
                    <CheckBoxIndeterminate width={20} height={20} />
                  }
                />
              </TableCell>
              {reportTable[0]?.label?.map((elem: string) => (
                <TableCell
                  key={elem}
                  sx={[
                    styles.lineTable,
                    {
                      fontWeight: 500,
                      fontSize: "16px",
                      color: "#262626",
                      textTransform: "capitalize",
                    },
                  ]}
                >
                  {elem === "Date" ||
                  elem === "Name" ||
                  elem === "Daily Total" ? (
                    <TableSortLabel
                      active={sortBy === elem}
                      direction={sortOrder}
                      onClick={() => {
                        requestSort(elem);
                        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                      }}
                      sx={{
                        "& .MuiTableSortLabel-icon": {
                          display: "none",
                        },
                      }}
                    >
                      {elem}
                      <Box sx={styles.boxSortWrapper}>
                        <Box
                          sx={styles.boxSort}
                          onClick={() => setSortOrder("asc")}
                        >
                          <ArrowDropUpIcon
                            sx={{ height: "24px" }}
                            color={
                              sortOrder === "asc" && sortBy === elem
                                ? "primary"
                                : "disabled"
                            }
                          />
                        </Box>
                        <Box
                          sx={styles.boxSort}
                          onClick={() => setSortOrder("desc")}
                        >
                          <ArrowDropDownIcon
                            sx={{ height: "24px" }}
                            color={
                              sortOrder === "desc" && sortBy === elem
                                ? "primary"
                                : "disabled"
                            }
                          />
                        </Box>
                      </Box>
                    </TableSortLabel>
                  ) : (
                    elem
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody sx={{ backgroundColor: "#FFF" }}>
            {report?.map((reportItem: ReportItem) => {
              const isItemSelected = selectedItems.includes(reportItem.id);
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={reportItem.id}
                  sx={styles.tableHeader}
                >
                  <TableCell sx={{ borderBottom: "none" }}>
                    <Checkbox
                      checked={isItemSelected}
                      onChange={(event) =>
                        handleSelectOneItem(event, reportItem)
                      }
                      sx={styles.checkbox}
                      icon={
                        <CheckBoxOutlineBlank
                          color="#D9D9D9"
                          width={20}
                          height={20}
                        />
                      }
                      checkedIcon={<CheckBox width={20} height={20} />}
                    />
                  </TableCell>
                  {reportTable[1]?.data?.map((elem, i) => {
                    return (
                      <TableCell key={i} sx={styles.tableCell}>
                        {chooseType(elem, reportItem)}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {openDeleteReportModal && (
        <DeleteTimeClock
          modalActionType={modalActionType}
          fetchSuccess={fetchReport}
          elem={elem}
          isOpen={openDeleteReportModal}
          closeModal={() => setOpenDeleteReportModal(false)}
        />
      )}
    </>
  );
};

export default ReportTable;
