export const START_WATCH_REQUEST = "START_WATCH_REQUEST";
export const START_WATCH_SUCCESS = "START_WATCH_SUCCESS";
export const START_WATCH_FAILURE = "START_WATCH_FAILURE";

export const CHECK_START_WATCH_REQUEST = "CHECK_START_WATCH_REQUEST";
export const CHECK_START_WATCH_SUCCESS = "CHECK_START_WATCH_SUCCESS";
export const CHECK_START_WATCH_FAILURE = "CHECK_START_WATCH_FAILURE";

export const STOP_WATCH_REQUEST = "STOP_WATCH_REQUEST";
export const STOP_WATCH_SUCCESS = "STOP_WATCH_SUCCESS";
export const STOP_WATCH_FAILURE = "STOP_WATCH_FAILURE";
