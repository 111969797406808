import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import React, { useState } from "react";
import { Download, ViewForWeb } from "src/assets/icons";
import { Invoice } from "src/types";
import invoicesTable from "./invoicesTable.json";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { format } from "date-fns";
import { parseTime } from "src/utils";

interface Props {
  allInvoice: Invoice[];
  setSortOrder: (order: string) => void;
  sortBy: string;
  sortOrder: any;
}

const styles = {
  lineTable: {
    "&::after": {
      content: "''",
      position: "absolute",
      right: 0,
      top: "50%",
      transform: "translateY(-50%)",
      width: "1px",
      height: "50%",
      backgroundColor: "#F0F0F0",
    },
  },
};

const InvoicesTable: React.FC<Props> = ({
  allInvoice,
  setSortOrder,
  sortBy,
  sortOrder,
}) => {
  const [hover, setHover] = useState("");

  const chooseType = (elem, item) => {
    switch (elem.type) {
      case "createdAt":
        return format(parseTime(item[elem.array[0]]), "MMMM d, y");
      case "invoiceTotal":
        return `$ ${Number(item[elem.array[0]]).toFixed(2)}`;
      case "description":
        return `${
          item[elem.array[0]].charAt(0).toUpperCase() +
          item[elem.array[0]].slice(1)
        } Plan, ${item[elem.array[1]]} users ($${item[elem.array[2]]}/user) ${
          item[elem.array[3]]
        } subscription`;
      case "actions":
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              mr="20px"
              onMouseEnter={() => setHover("view" + item.id)}
              onMouseLeave={() => setHover("")}
              onClick={() => window.open(item[elem.array[0]], "_blank")}
            >
              <ViewForWeb
                color={hover === "view" + item.id ? "#0D6FF0" : "#8C8C8C"}
              />
            </Box>
            <Box
              onMouseEnter={() => setHover("download" + item.id)}
              onMouseLeave={() => setHover("")}
              onClick={() => window.open(item[elem.array[1]], "_blank")}
            >
              <Download
                color={hover === "download" + item.id ? "#0D6FF0" : "#8C8C8C"}
              />
            </Box>
          </Box>
        );
    }
  };
  return (
    <>
      <TableContainer>
        <Table>
          <TableHead
            sx={{
              backgroundColor: "#FAFAFA",
            }}
          >
            <TableRow
              sx={{
                display: "grid",
                gridTemplateColumns: "3fr 3fr 11fr 1fr",
                alignItems: "center",
                borderBottom: "1px solid #F0F0F0",
              }}
            >
              {invoicesTable[0]?.label?.map((elem: string) => (
                <TableCell
                  key={elem}
                  sx={[
                    styles.lineTable,
                    {
                      fontWeight: 500,
                      fontSize: "16px",
                      color: "#262626",
                      textTransform: "capitalize",
                      borderBottom: "none",
                      position: "relative",
                    },
                  ]}
                >
                  {elem === "Date" ? (
                    <TableSortLabel
                      active={elem === "Date"}
                      direction={sortOrder}
                      onClick={() =>
                        setSortOrder(sortOrder === "asc" ? "desc" : "asc")
                      }
                      sx={{
                        "& .MuiTableSortLabel-icon": {
                          display: "none",
                        },
                      }}
                    >
                      {elem}
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          height="10px"
                          display="flex"
                          alignItems="center"
                          overflow="hidden"
                          onClick={() => setSortOrder("asc")}
                        >
                          <ArrowDropUpIcon
                            sx={{ height: "24px" }}
                            color={
                              sortOrder === "asc" && sortBy === elem
                                ? "primary"
                                : "disabled"
                            }
                          />
                        </Box>
                        <Box
                          height="10px"
                          display="flex"
                          alignItems="center"
                          overflow="hidden"
                          onClick={() => setSortOrder("desc")}
                        >
                          <ArrowDropDownIcon
                            sx={{ height: "24px" }}
                            color={
                              sortOrder === "desc" && sortBy === elem
                                ? "primary"
                                : "disabled"
                            }
                          />
                        </Box>
                      </Box>
                    </TableSortLabel>
                  ) : (
                    elem
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody sx={{ backgroundColor: "#FFF" }}>
            {allInvoice.slice(0, 12).map((invoice: Invoice) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={invoice.id}
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "3fr 3fr 11fr 1fr",
                  }}
                >
                  {invoicesTable[1]?.data?.map((elem, i) => {
                    return (
                      <TableCell
                        key={i}
                        sx={{
                          fontWeight: 400,
                          fontSize: "16px",
                          color: "#262626",
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {chooseType(elem, invoice)}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default InvoicesTable;
