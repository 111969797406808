import { Box, CardMedia, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DeleteForWeb } from "src/assets/icons";
import Toggle from "../Toggle";

const styles = {
  attachment: {
    borderRadius: "4px",
    objectFit: "contain",
    width: "100%",
    maxHeight: "300px",
    minHeight: "300px",
  },
  deleteIcon: {
    position: "absolute",
    right: "15px",
    top: "15px",
    backgroundColor: "#FF4D4F",
    borderRadius: "22px",
    display: "flex",
    columnGap: "10px",
    justifyContent: "space-between",
    alignItems: "center",
    p: "13px 24px",
    cursor: "pointer",
  },
  togleContainer: {
    marginTop: "22px",
    border: "1px solid #F0F0F0",
    borderRadius: "4px",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: "16px",
  },
  captionText: {
    padding: 0,
    marginTop: "10px",
    fontSize: "14px",
    color: "#000",
    "& fieldset": { border: "1px solid #F0F0F0" },
  },
};

interface Props {
  attachment: any;
  index: number;
  toggleReceipt: (index: number, elem: boolean) => void;
  handleCaption: (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => void;
  handleDelete: (id: string) => void;
}

const AddAttachmentItem: React.FC<Props> = ({
  attachment,
  index,
  toggleReceipt,
  handleCaption,
  handleDelete,
}) => {
  const [image, setImage] = useState();
  const convertBase64 = (file: any) => {
    new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    }).then((res: any) => setImage(res));
  };

  useEffect(() => {
    convertBase64(attachment.file);
  }, []);
  return (
    <>
      <Box width="23.5%">
        <Box position="relative">
          <CardMedia
            sx={styles.attachment}
            component="img"
            image={image}
            alt="attachment"
          />
          <Box
            sx={styles.deleteIcon}
            onClick={() => handleDelete(attachment.file.name)}
          >
            <DeleteForWeb color="#FFF" />
            <Typography fontSize="14px" fontWeight={500} color="#FFF">
              Delete
            </Typography>
          </Box>
        </Box>
        <Box sx={[styles.togleContainer]}>
          <Typography color="#262626">Receipt</Typography>
          <Toggle
            name="isReceipt"
            checked={attachment.isReceipt}
            onChange={() => toggleReceipt(index, attachment.isReceipt)}
          />
        </Box>
        <Typography mt="22px" color="#8C8C8C" fontSize="14px">
          Caption
        </Typography>
        <TextField
          multiline
          fullWidth
          sx={styles.captionText}
          minRows={1}
          maxRows={6}
          value={attachment.caption}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleCaption(e, index)
          }
          type="text"
          inputProps={{ maxLength: 500 }}
        />
      </Box>
    </>
  );
};

export default AddAttachmentItem;
