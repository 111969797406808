export const styles = {
  historyDay: {
    fontWeight: 700,
    fontSize: "20px",
    color: "#242424",
  },
  weekBox: {
    width: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  weekDates: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  weekContainer: {
    display: "flex",
    flexShrink: 0,
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #F0F0F0",
    backgroundColor: "#FFF",
    color: "#000",
    borderRadius: "4px",
    width: "calc(100vw / 12)",
    maxWidth: "44px",
    height: "calc(100vw / 12)",
    maxHeight: "44px",
    cursor: "pointer",
  },
  selectedWeekContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #0D6FF0",
    backgroundColor: "#0D6FF0",
    color: "#FFF",
    borderRadius: "4px",
    width: "calc(100vw / 12)",
    maxWidth: "44px",
    height: "calc(100vw / 12)",
    maxHeight: "44px",
    cursor: "pointer",
  },
};
