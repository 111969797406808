import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { styles } from "./attachments.module";
import { CircularProgress, Typography } from "@mui/material";
import PullToRefresh from "react-simple-pull-to-refresh";
import { Button } from "src/components";
import { Add } from "src/assets/icons";
import { useDispatch, useSelector } from "react-redux";
import { getAttachmentsSelector } from "src/store/attachments/selectors";
import { fetchAttachmentsRequest } from "src/store/attachments/actions";
import { AttachmentDay } from "src/types";
import { format, parseISO } from "date-fns";
import TabAttachmentsData from "src/components/TabAttachmentsData";
import DeviceWrapper from "../../DeviceWrapper";
import AddAttachmentModal from "src/components/ActionsModal/AddAttachmentModal";
import { parseTime } from "src/utils";

const Attachments = () => {
  const [size, setSize] = useState<number>(0);
  const pageSize: number = 1000;
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isToday, setIsToday] = useState<boolean>(false);
  const [attachmentDate, setAttachmentDate] = useState<string>("");
  const [modalActionType, setModalActionType] = useState({
    action: "",
    page: "an Action",
  });
  const dispatch = useDispatch();
  const attachmentState = useSelector(getAttachmentsSelector);

  const handleOpenModal = (type: string, elem?: string) => {
    setOpenModal(true);
    setAttachmentDate(elem);
    setModalActionType((prevState) => ({
      ...prevState,
      action: type,
    }));
  };

  const handleFetchAttachments = () => {
    dispatch(
      fetchAttachmentsRequest({
        currentPage: 1,
        pageSize: pageSize,
        sortOrder: "asc",
        fromDate: format(
          new Date(
            new Date().setDate(
              new Date().getDate() - new Date().getDay() - 7 * size
            )
          ),
          "y-MM-dd"
        ),
        toDate: format(
          new Date(
            new Date().setDate(new Date().getDate() - new Date().getDay() + 6)
          ),
          "y-MM-dd"
        ),
        sortBy: "attachmentDay",
      })
    );
  };

  const findIsTodayAttachments = () => {
    const isDayExist: boolean = attachmentState?.attachments?.days?.some(
      (elem) => elem.day === format(new Date(), "y-MM-dd")
    );
    if (isDayExist) setIsToday(false);
    else setIsToday(true);
  };

  useEffect(() => {
    handleFetchAttachments();
  }, [size]);

  useEffect(() => {
    findIsTodayAttachments();
  }, [attachmentState?.attachments]);

  const handleRefresh = async () => {
    handleFetchAttachments();
  };

  return (
    <>
      <DeviceWrapper>
        <PullToRefresh onRefresh={handleRefresh}>
          <Box>
            {!attachmentState.isLoading && isToday && (
              <Box sx={{ marginBottom: "30px" }}>
                <Typography sx={styles.attachmentsDay}>Today</Typography>
                <Box sx={styles.noData}>
                  <Typography sx={styles.noDataText}>
                    No attachments uploaded yet
                  </Typography>
                </Box>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    handleOpenModal("Choose", format(new Date(), "y-MM-dd"))
                  }
                >
                  <Box sx={styles.addButtonContainer}>
                    <Add />
                    <Typography sx={styles.addButtonText}>
                      Add Attachment
                    </Typography>
                  </Box>
                </Button>
              </Box>
            )}
            {attachmentState?.attachments?.days
              ?.sort((a: any, b: any) =>
                b.day > a.day ? 1 : a.day > b.day ? -1 : 0
              )
              ?.map((item: AttachmentDay) => {
                if (parseTime(item.day) <= new Date()) {
                  return (
                    <Box key={item.day} sx={styles.attachmentWrapper}>
                      <Typography sx={styles.attachmentsDay}>
                        {format(parseISO(item.day), "MMMM d, y") ===
                        format(new Date(), "MMMM d, y")
                          ? "Today"
                          : format(parseISO(item.day), "MMMM d, y")}
                      </Typography>
                      <TabAttachmentsData
                        attachmentsState={
                          attachmentState.attachments?.bucketUrlPrefix
                        }
                        attachmentDay={item.attachments}
                      />
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        mt="10px"
                        onClick={() => handleOpenModal("Choose", item.day)}
                      >
                        <Box sx={styles.addButtonContainer}>
                          <Add />
                          <Typography sx={styles.addButtonText}>
                            Add Attachment
                          </Typography>
                        </Box>
                      </Button>
                    </Box>
                  );
                }
              })}
            {!attachmentState.isLoading ? (
              <Button
                data-sqa-id="load-more-btn"
                variant="outlined"
                fullWidth
                style={{
                  border: "1px solid #0D6FF0",
                  boxShadow: "none",
                }}
                onClick={() => setSize((prevState) => prevState + 1)}
              >
                <Box sx={styles.addButtonContainer}>
                  <Typography
                    data-sqa-id="load-more-btn-text"
                    sx={styles.loadMore}
                  >
                    Load More
                  </Typography>
                </Box>
              </Button>
            ) : (
              <Box
                data-sqa-id="circular-progress-loader"
                display="flex"
                alignItems="center"
                justifyContent="center"
                mb="10px"
              >
                <CircularProgress />
              </Box>
            )}
          </Box>
        </PullToRefresh>
      </DeviceWrapper>
      {openModal && (
        <AddAttachmentModal
          onFetchSuccess={handleFetchAttachments}
          elementDate={attachmentDate}
          modalActionType={modalActionType}
          isOpen={openModal}
          closeModal={() => setOpenModal(false)}
        />
      )}
    </>
  );
};

export default Attachments;
