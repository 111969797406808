import {
  FETCH_COMMENTS_SUCCESS,
  FETCH_COMMENTS_FAILURE,
  // ADD_COMMENT_SUCCESS,
  ADD_COMMENT_FAILURE,
  DELETE_COMMENT_FAILURE,
  UPDATE_COMMENT_FAILURE,
  FETCH_DAILY_COMMENTS_FAILURE,
  FETCH_DAILY_COMMENTS_SUCCESS,
  FETCH_COMMENTS_REQUEST,
  FETCH_DAILY_COMMENTS_REQUEST,
  FETCH_COMMENTS_DAY_BY_USER_FAILURE,
  FETCH_COMMENTS_DAY_BY_USER_REQUEST,
  FETCH_COMMENTS_DAY_BY_USER_SUCCESS,
  // UPDATE_COMMENT_SUCCESS,
} from "./actionTypes";
import { AuthActions, CommentsState } from "./types";

const initialState: CommentsState = {
  isLoading: false,
  comments: {
    currentPage: 0,
    totalPages: 0,
    pageSize: 0,
    sortOrder: "",
    days: [],
  },
  error: null,
};

export default (state = initialState, action: AuthActions) => {
  switch (action.type) {
    case FETCH_COMMENTS_REQUEST:
    case FETCH_DAILY_COMMENTS_REQUEST:
    case FETCH_COMMENTS_DAY_BY_USER_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case FETCH_COMMENTS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };
    }
    case FETCH_COMMENTS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    case FETCH_COMMENTS_DAY_BY_USER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };
    }
    case FETCH_COMMENTS_DAY_BY_USER_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    case FETCH_DAILY_COMMENTS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };
    }
    case FETCH_DAILY_COMMENTS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    // case ADD_COMMENT_SUCCESS: {
    //   return {
    //     ...state,
    //     ...action.payload,
    //   };
    // }
    case ADD_COMMENT_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
      };
    }
    // case UPDATE_COMMENT_SUCCESS: {
    //   return {
    //     ...state,
    //     ...action.payload,
    //   };
    // }
    case UPDATE_COMMENT_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
      };
    }
    // case DELETE_COMMENT_SUCCESS: {
    //   return {
    //     ...state,
    //     ...action.payload,
    //   };
    // }
    case DELETE_COMMENT_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
      };
    }
    default:
      return state;
  }
};
