import { Box, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import PullToRefresh from "react-simple-pull-to-refresh";
import {
  AccountDetails,
  TabBillingAndInvoices,
  // TabConfiguration,
  TabSecurity,
} from "src/components";
import TabDataSection from "src/components/TabDataSection";
import TabPersonalDetails from "src/components/TabPersonalDetails";
import { AppRoutes, PageTitle } from "src/constants";
import DeviceWrapper from "src/pages/DeviceWrapper";
import {
  currentUserDataRequest,
  getOrganizationRequest,
} from "src/store/auth/actions";
import { getUserSelector } from "src/store/auth/selectors";
import {
  getInvoiceRequest,
  getSubscriptionRequest,
} from "src/store/subscription/actions";
import { styles } from "./settings.module";

const Settings = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const idState = useLocation().state as { id: string };
  const navigate = useNavigate();
  const [triggerState, setTriggerState] = useState(false);
  const user = useSelector(getUserSelector);
  const [currentTab, setCurrentTab] = useState(
    parseInt(sessionStorage.getItem("activeTab")) || 0
  );
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
    sessionStorage.setItem("activeTab", newValue.toString());
  };

  useEffect(() => {
    if (idState?.id) {
      setCurrentTab(2);
      navigate(AppRoutes.Settings, {
        state: { id: "" },
      });
      sessionStorage.setItem("activeTab", "2");
      setTriggerState(true);
    }
    return () => {
      sessionStorage.setItem("activeTab", "0");
    };
  }, [idState?.id]);

  const handleRefresh = async () => {
    switch (currentTab) {
      case 0: {
        dispatch(getOrganizationRequest());
        dispatch(currentUserDataRequest());
        break;
      }
      case 1: {
        dispatch(getOrganizationRequest());
        break;
      }
      case 2: {
        dispatch(getSubscriptionRequest());
        dispatch(getInvoiceRequest());
        break;
      }
    }
  };

  return (
    <DeviceWrapper>
      <PullToRefresh onRefresh={handleRefresh}>
        <>
          <Box sx={styles.topContainer}>
            <Typography sx={styles.pageTitle}>
              {user.isAdmin ? PageTitle[pathname] : "My Profile"}
            </Typography>
          </Box>
          <Box sx={styles.settings}>
            <Tabs
              value={currentTab}
              variant="scrollable"
              onChange={handleTabChange}
              sx={styles.tabs}
            >
              <Tab label="Personal Details" />
              {user.isAdmin && localStorage.getItem("roleMode") === "admin" && (
                <Tab label="Account Details" />
              )}
              {user.isAdmin && localStorage.getItem("roleMode") === "admin" && (
                <Tab label="Billing and Invoices" />
              )}
              <Tab label="Security" />
              {/* {user.isAdmin && localStorage.getItem("roleMode") === "admin" && (
                <Tab label="Configuration" />
              )} */}
            </Tabs>
            <TabDataSection mt="20px" border="none">
              {currentTab === 0 && <TabPersonalDetails />}
              {currentTab === 1 &&
                user.isAdmin &&
                localStorage.getItem("roleMode") === "admin" && (
                  <AccountDetails />
                )}
              {currentTab === 2 &&
                user.isAdmin &&
                localStorage.getItem("roleMode") === "admin" && (
                  <TabBillingAndInvoices
                    triggerClickPlan={triggerState}
                    closeTriggerState={() => setTriggerState(false)}
                  />
                )}
              {(currentTab === 3 ||
                (!user.isAdmin && currentTab === 1) ||
                (user.isAdmin &&
                  currentTab === 1 &&
                  localStorage.getItem("roleMode") === "user")) && (
                <TabSecurity />
              )}
              {/* {currentTab === 4 &&
                user.isAdmin &&
                localStorage.getItem("roleMode") === "admin" && (
                  <TabConfiguration />
                )} */}
            </TabDataSection>
          </Box>
        </>
      </PullToRefresh>
    </DeviceWrapper>
  );
};

export default Settings;
