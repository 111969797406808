import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  AppBar as MuiAppBar,
  Typography,
  Avatar,
  Theme,
} from "@mui/material";
import {
  LogoutIcon,
  ProjectIcon,
  ReportIcon,
  SettingsIcon,
  HelpCircle,
  TeamIcon,
  Home,
  Attachments,
  Comments,
  Calendar,
  Shuffle,
} from "src/assets/icons";
import TrialModal from "../ActionsModal/TrialModal";
import { useSelector } from "react-redux";
import {
  getOrganizationSelector,
  getUserSelector,
} from "src/store/auth/selectors";
import { useLocation, useNavigate } from "react-router-dom";
import { AppRoutes } from "src/constants";
import { currentUserDataRequest, logout } from "src/store/auth/actions";
import { useDispatch } from "react-redux";
import SupportModal from "../ActionsModal/SupportModal";
import { styles } from "./webContainer.module";
import { format } from "date-fns";
import { parseTime } from "src/utils";
import { clearFilter } from "src/store/report/actions";
import ChoosePlan from "../ActionsModal/ChoosePlan";
import { Capacitor } from "@capacitor/core";

interface Props {
  children: React.ReactElement;
}

const WebContainer: React.FC<Props> = ({ children }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ref = useRef<any>();
  const [open, setOpen] = useState<boolean>(false);
  const [roleMode, setRoleMode] = useState<string>(
    localStorage.getItem("roleMode")
  );
  const [openSupport, setOpenSupport] = useState<boolean>(false);
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const [activePage, setActivePage] = useState(
    pathname.slice(1).split("/").slice(0, 1).join("")
  );
  const [modalActionType, setModalActionType] = useState({
    action: "",
    page: "",
  });

  const [openChoosePlan, setOpenChoosePlan] = useState<boolean>(false);

  const user = useSelector(getUserSelector);
  const organization = useSelector(getOrganizationSelector);

  const openModal = (setState: (bol: boolean) => void, type: string) => {
    setState(true);
    if (type === "Trial") {
      setModalActionType((prevState) => ({
        ...prevState,
        action:
          new Date().getTime() < parseTime(organization?.trialExpires).getTime()
            ? "Trial"
            : "Trial expired",
      }));
    } else {
      setModalActionType((prevState) => ({
        ...prevState,
        action: type,
      }));
    }
  };

  const openChoosePlanModal = () => {
    setOpenChoosePlan(true);
    setModalActionType((prevState) => ({
      ...prevState,
      action: "Update Plan",
    }));
  };

  const redirectToPage = (pageRoute: string, params: string, tab?: number) => {
    if (typeof tab === "number") {
      sessionStorage.setItem("activeTab", tab.toString());
    }
    navigate(pageRoute);
    setActivePage(params);
  };

  function stringAvatar(name) {
    return {
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }

  useEffect(() => {
    const checkIfClickedOutside = (e: Event) => {
      if (openDropdown && ref.current && !ref.current.contains(e.target)) {
        setOpenDropdown(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [openDropdown]);

  const changeRoleMode = () => {
    if (localStorage.getItem("roleMode") === "admin") {
      localStorage.setItem("roleMode", "user");
      setRoleMode("user");
      window.location.href = "/";
    } else {
      localStorage.setItem("roleMode", "admin");
      setRoleMode("admin");
      window.location.href = "/";
    }
    sessionStorage.setItem("activeTab", "0");
  };

  useEffect(() => {
    dispatch(currentUserDataRequest());
  }, []);

  useEffect(() => {
    if (!pathname.slice(1).includes("report/")) {
      dispatch(clearFilter());
    }
  }, []);

  return (
    <>
      <MuiAppBar sx={styles.appBarWrapper}>
        <Box data-sqa-id="left-menu-box" sx={styles.sidebar}>
          <Typography
            data-sqa-id="left-menu-app-title"
            color="primary"
            textAlign="center"
            fontWeight="800"
            fontSize={{ xs: "24px" }}
          >
            BusyBucket
          </Typography>
          <Box px="25px" mt="33px">
            <Typography
              data-sqa-id="time-tracking-menu-separator"
              fontWeight="500"
              color="#8C8C8C"
              fontSize={{ xs: "12px" }}
            >
              Time Tracking
            </Typography>
            {(!user.isAdmin || (user.isAdmin && roleMode === "user")) && (
              <>
                <Box
                  sx={styles.boxContainer}
                  onClick={() => redirectToPage(AppRoutes.Dashboard, "")}
                >
                  <Home color="#262626" size="16" />
                  <Typography
                    id="dashboard-menu"
                    fontWeight="400"
                    color={activePage === "" ? "#0D6FF0" : "#262626"}
                    fontSize={{ xs: "16px" }}
                    ml="12px"
                  >
                    Dashboard
                  </Typography>
                </Box>
                <Box
                  sx={styles.boxContainer}
                  onClick={() =>
                    redirectToPage(
                      `${AppRoutes.HistoryDay}/${format(
                        new Date(),
                        "y-MM-dd"
                      )}`,
                      "history",
                      1
                    )
                  }
                >
                  <Attachments color="#262626" size="16" />
                  <Typography
                    id="attachement-menu"
                    fontWeight="400"
                    color={
                      activePage === "history" &&
                      sessionStorage.getItem("activeTab") === "1"
                        ? "#0D6FF0"
                        : "#262626"
                    }
                    fontSize={{ xs: "16px" }}
                    ml="12px"
                  >
                    Attachments
                  </Typography>
                </Box>
                <Box
                  sx={styles.boxContainer}
                  onClick={() =>
                    redirectToPage(
                      `${AppRoutes.HistoryDay}/${format(
                        new Date(),
                        "y-MM-dd"
                      )}`,
                      "history",
                      2
                    )
                  }
                >
                  <Comments color="#262626" size={16} />
                  <Typography
                    id="comments-menu"
                    fontWeight="400"
                    color={
                      activePage === "history" &&
                      sessionStorage.getItem("activeTab") === "2"
                        ? "#0D6FF0"
                        : "#262626"
                    }
                    fontSize={{ xs: "16px" }}
                    ml="12px"
                  >
                    Comments
                  </Typography>
                </Box>
                <Box
                  sx={styles.boxContainer}
                  onClick={() =>
                    redirectToPage(
                      `${AppRoutes.HistoryDay}/${format(
                        new Date(),
                        "y-MM-dd"
                      )}`,
                      "history",
                      0
                    )
                  }
                >
                  <Calendar color="#262626" size="16" />
                  <Typography
                    id="history-menu"
                    fontWeight="400"
                    color={
                      activePage === "history" &&
                      sessionStorage.getItem("activeTab") === "0"
                        ? "#0D6FF0"
                        : "#262626"
                    }
                    fontSize={{ xs: "16px" }}
                    ml="12px"
                  >
                    History
                  </Typography>
                </Box>
              </>
            )}
            {user.isAdmin && roleMode === "admin" && (
              <>
                <Box
                  sx={styles.boxContainer}
                  onClick={() => redirectToPage(AppRoutes.Report, "report")}
                >
                  <ReportIcon color="#262626" />
                  <Typography
                    data-sqa-id="report-menu-item"
                    fontWeight="400"
                    color={activePage === "report" ? "#0D6FF0" : "#262626"}
                    fontSize={{ xs: "16px" }}
                    ml="12px"
                  >
                    Report
                  </Typography>
                </Box>
                <Typography
                  data-sqa-id="manage-menu-separator"
                  fontWeight="500"
                  color="#8C8C8C"
                  fontSize={{ xs: "12px" }}
                >
                  Manage
                </Typography>
                <Box
                  sx={styles.boxContainer}
                  onClick={() => redirectToPage("/", "")}
                >
                  <TeamIcon color="#262626" />
                  <Typography
                    data-sqa-id="team-menu-item"
                    fontWeight="400"
                    color={activePage === "" ? "#0D6FF0" : "#262626"}
                    fontSize={{ xs: "16px" }}
                    ml="12px"
                  >
                    Team
                  </Typography>
                </Box>
                <Box
                  sx={styles.boxContainer}
                  onClick={() => redirectToPage(AppRoutes.Projects, "projects")}
                >
                  <ProjectIcon color="#262626" />
                  <Typography
                    data-sqa-id="projects-menu-item"
                    fontWeight="400"
                    color={
                      activePage === "projects" || activePage === "project"
                        ? "#0D6FF0"
                        : "#262626"
                    }
                    fontSize={{ xs: "16px" }}
                    ml="12px"
                  >
                    Projects
                  </Typography>
                </Box>
              </>
            )}
            {user.isAdmin && (
              <Box sx={styles.boxContainer} onClick={changeRoleMode}>
                <Shuffle size={16} color="#262626" />
                <Typography
                  data-sqa-id="switch-menu-item"
                  fontWeight="400"
                  color="#262626"
                  fontSize={{ xs: "16px" }}
                  ml="12px"
                >
                  Switch to
                  {roleMode === "admin" ? " User " : " Admin "}
                  Mode
                </Typography>
              </Box>
            )}
          </Box>
        </Box>

        <Box sx={styles.toolbar}>
          <Box sx={styles.navbar}>
            {organization?.trial && Capacitor.getPlatform() != "ios" && (
              <Box
                data-sqa-id="trial-top-button"
                sx={[
                  styles.licenseStatus,
                  {
                    backgroundColor:
                      new Date().getTime() <
                      parseTime(organization?.trialExpires).getTime()
                        ? (theme: Theme) => theme.palette.info.main
                        : "#FF4D4F",
                    color:
                      new Date().getTime() <
                      parseTime(organization?.trialExpires).getTime()
                        ? (theme: Theme) => theme.palette.info.contrastText
                        : "#FFF",
                  },
                ]}
                onClick={() => openModal(setOpen, "Trial")}
              >
                Trial
              </Box>
            )}
            <Box
              data-sqa-id="settings-button"
              sx={styles.settingsBox}
              onClick={() => {
                sessionStorage.setItem("activeTab", "0");
                navigate(AppRoutes.Settings);
              }}
            >
              <SettingsIcon
                data-sqa-id="settings-icon"
                size="25px"
                color="#262626"
              />
            </Box>
            <Box
              data-sqa-id="profile-menu"
              sx={styles.user}
              onClick={() => setOpenDropdown(!openDropdown)}
            >
              <Avatar
                {...stringAvatar(`${user.firstName} ${user.lastName}`)}
                sx={styles.userPhoto}
                src={user?.profilePictureUrl}
                data-sqa-id="user-avatar"
              />
              <Typography
                data-sqa-id="user-full-name"
                fontWeight="400"
                color="#262626"
                fontSize={{ xs: "16px" }}
              >
                {`${user.firstName} ${user.lastName}`}
              </Typography>
            </Box>
            {openDropdown && (
              <Box
                data-sqa-id="profile-menu-box"
                sx={styles.dropdown}
                ref={ref}
              >
                <Box
                  data-sqa-id="support-item-box"
                  sx={styles.boxWrapper}
                  mb="15px"
                  onClick={() => openModal(setOpenSupport, "Support")}
                >
                  <HelpCircle />
                  <Typography
                    data-sqa-id="support-item-label"
                    fontWeight="400"
                    color="#262626"
                    fontSize={{ xs: "16px" }}
                    ml="10px"
                  >
                    Support
                  </Typography>
                </Box>
                <Box
                  data-sqa-id="logout-item"
                  sx={styles.boxWrapper}
                  onClick={() => {
                    dispatch(logout());
                    navigate(AppRoutes.SignIn);
                  }}
                >
                  <LogoutIcon />
                  <Typography
                    data-sqa-id="logout-item-label"
                    fontWeight="400"
                    color="#262626"
                    fontSize={{ xs: "16px" }}
                    ml="10px"
                  >
                    Logout
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
          <Box sx={styles.pageContainer}>{children}</Box>
        </Box>
      </MuiAppBar>

      {open && (
        <TrialModal
          modalActionType={modalActionType}
          isOpen={open}
          closeModal={() => setOpen(false)}
          openChoosePlanModal={openChoosePlanModal}
        />
      )}

      {openSupport && (
        <SupportModal
          modalActionType={modalActionType}
          isOpen={openSupport}
          closeModal={() => setOpenSupport(false)}
        />
      )}

      {openChoosePlan && (
        <ChoosePlan
          isOpen={openChoosePlan}
          setModalActionType={setModalActionType}
          modalActionType={modalActionType}
          closeModal={() => setOpenChoosePlan(false)}
        />
      )}
    </>
  );
};

export default WebContainer;
