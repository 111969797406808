import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { UploadImage } from "src/assets/icons";
import Button from "../Button";
import AddAttachmentItem from "./AddAttachmentItem";
import {
  addAttachmentRequest,
  getPresignedUrlRequest,
} from "src/store/attachments/actions";
import ModalWrapper from "./ModalWrapper";
import { getAttachmentsSelector } from "src/store/attachments/selectors";
import axios from "axios";
import { clientTimezone } from "src/utils";

const styles = {
  boxContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "430px",
    position: "relative",
    border: "1px dashed #D9D9D9",
    borderRadius: "8px",
    overflow: "hidden",
  },
  attachmentBox: {
    height: "600px",
    display: "flex",
    flexWrap: "wrap",
    columnGap: "20px",
    rowGap: "20px",
    overflowY: "auto",
    overflowX: "hidden",
  },
  textStyle: {
    mt: "10px",
    fontSize: "18px",
    fontWeight: 400,
    color: "#0D6FF0",
    textDecoration: "underline",
  },
  uploadTextDesc: {
    position: "absolute",
    bottom: "20px",
    left: "50%",
    transform: "translateX(-50%)",
    fontSize: "16px",
    fontWeight: 400,
    color: "#8C8C8C",
  },
  toastStyle: {
    backgroundColor: "#FEE6B9",
    border: "1px solid #B87A00",
    width: "350px",
  },
};

interface ModalProps {
  isOpen: boolean;
  modalActionType: {
    action: string;
    page: string;
  };
  closeModal: () => void;
  handleFetch: () => void;
}

const AddAttachment: React.FC<ModalProps> = ({
  modalActionType,
  isOpen,
  closeModal,
  handleFetch,
}) => {
  const dispatch = useDispatch();
  const attachmentState = useSelector(getAttachmentsSelector);
  const { id } = useParams();
  const [attachmentItems, setAttachmentItem] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files: FileList | null = e.target.files;
    if (files.length > 20) {
      toast.warning(
        <>
          <Typography fontSize="16px" color="#262626">
            You can only upload at most 20 files!
          </Typography>
        </>,
        {
          style: styles.toastStyle,
        }
      );
      return;
    } else {
      const attachmentsArr = Array.from(files || []);
      attachmentsArr.forEach((elem) => {
        return setAttachmentItem((prev) => [
          ...prev,
          {
            caption: "",
            isReceipt: false,
            attachmentDay: id,
            s3FilePath: "",
            thumbnailS3FilePath: "",
            file: elem,
          },
        ]);
      });
    }
  };

  const handleDelete = (id: string) => {
    setAttachmentItem(attachmentItems.filter((el) => el.file.name !== id));
  };

  const toggleReceipt = (index: number, elem: boolean) => {
    let data: any = [...attachmentItems];
    data[index]["isReceipt"] = !elem;
    setAttachmentItem(data);
  };

  const handleCaption = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    let data: any = [...attachmentItems];
    if (data[index]["caption"].length > 501)
      return data[index]["caption"].substring(0, 500);
    else {
      data[index]["caption"] = e.target.value;
      setAttachmentItem(data);
    }
  };

  const handleSubmitAttachments = () => {
    setIsLoading(true);
    dispatch(
      getPresignedUrlRequest({
        s3ContentType: attachmentItems[0].file.type, //fix diff file type
        numberOfUrlsToGenerate: attachmentItems.length,
      })
    );
  };

  const uploadImageToS3 = async () => {
    if (attachmentItems[0]?.file) {
      let options = {
        headers: {
          "Content-Type": attachmentItems[0]?.file.type,
        },
      };

      const result = await Promise.allSettled(
        attachmentState?.f3images.map((elem, i) => {
          return axios.put(
            elem.presignedUrl,
            attachmentItems[i]?.file,
            options
          );
        })
      );

      if (result) {
        attachmentState?.f3images?.forEach((elem, i) => {
          let data: any = [...attachmentItems];
          data[i]["s3FilePath"] = elem?.s3FilePath;
          data[i]["thumbnailS3FilePath"] = elem?.s3FilePath;
          setAttachmentItem(data);
        });

        dispatch(
          addAttachmentRequest(
            {
              attachments: attachmentItems.map(
                ({
                  caption,
                  isReceipt,
                  attachmentDay,
                  s3FilePath,
                  thumbnailS3FilePath,
                }) => ({
                  caption: caption.trim(),
                  isReceipt,
                  attachmentDay,
                  s3FilePath,
                  thumbnailS3FilePath,
                })
              ),
              timezone: clientTimezone,
            },
            {
              onSuccess: fetchSuccess,
            }
          )
        );
      }
    }
  };

  useEffect(() => {
    uploadImageToS3();
  }, [attachmentState.f3images]);

  const fetchSuccess = () => {
    setIsLoading(false);
    closeModal();
    handleFetch();
  };

  return (
    <>
      <ModalWrapper
        closeModal={closeModal}
        isOpen={isOpen}
        maxWidth="70%"
        modalActionType={modalActionType}
      >
        <>
          {isLoading ? (
            <Box
              data-sqa-id="circular-progress-loader"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <CircularProgress />
            </Box>
          ) : attachmentItems.length === 0 ? (
            <Box sx={styles.boxContainer}>
              <input
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  opacity: 0,
                }}
                onChange={handleInputChange}
                type="file"
                multiple
                accept=".png, .jpg, .jpeg"
              />
              <UploadImage />
              <Typography
                mt="20px"
                fontSize="24px"
                fontWeight={500}
                color="#000"
              >
                Drag & Drop here
              </Typography>
              <Typography sx={styles.textStyle}>
                or click to select files
              </Typography>
              <Typography sx={styles.uploadTextDesc}>
                You can upload up to 20 pictures at once.
              </Typography>
            </Box>
          ) : (
            <>
              <Box sx={styles.attachmentBox}>
                {attachmentItems.map((attachment, index) => (
                  <AddAttachmentItem
                    key={attachment.file.name}
                    handleDelete={handleDelete}
                    toggleReceipt={toggleReceipt}
                    handleCaption={handleCaption}
                    attachment={attachment}
                    index={index}
                  />
                ))}
              </Box>
              <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                mt="30px"
                columnGap="20px"
              >
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  style={{ width: "8%" }}
                  onClick={closeModal}
                >
                  <Typography color="#262626" fontSize="14px">
                    Cancel
                  </Typography>
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  style={{ width: "8%" }}
                  onClick={handleSubmitAttachments}
                >
                  Save
                </Button>
              </Box>
            </>
          )}
        </>
      </ModalWrapper>
    </>
  );
};

export default AddAttachment;
