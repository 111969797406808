import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  IconButton,
  Toolbar,
  CircularProgress,
  Theme,
} from "@mui/material";
import { Back } from "src/assets/icons";
import { styles } from "./attachments.module";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAttachmentsSelector } from "src/store/attachments/selectors";
import { Button } from "src/components";
import {
  addAttachmentRequest,
  getPresignedUrlRequest,
} from "src/store/attachments/actions";
import axios from "axios";
import AddAttachmentItemMobile from "src/components/AddAttachmentItemMobile";
import { clientTimezone, parseTime } from "src/utils";
import { getOrganizationSelector } from "src/store/auth/selectors";
import TrialModal from "src/components/ActionsModal/TrialModal";
import { Capacitor } from "@capacitor/core";

const AddAttachment = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const organization = useSelector(getOrganizationSelector);
  const [arrayIsEmpty, setArrayIsEmpty] = useState(false);
  const attachmentData = useLocation().state as { photos: any[]; date: string };
  const attachmentState = useSelector(getAttachmentsSelector);
  const [attachmentItems, setAttachmentItem] = useState([]);
  const [modalActionType, setModalActionType] = useState({
    action: "",
    page: "",
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [open, setOpen] = useState(false);

  const handleDelete = (id: string) => {
    setAttachmentItem(attachmentItems.filter((el) => el?.file?.webPath !== id));
  };

  const toggleReceipt = (index: number, elem: boolean) => {
    let data: any = [...attachmentItems];
    data[index]["isReceipt"] = !elem;
    setAttachmentItem(data);
  };

  const handleCaption = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    let data: any = [...attachmentItems];
    if (e.target.value.length > 500) {
      data[index]["caption"] = e.target.value.substring(0, 500);
      setAttachmentItem(data);
    } else {
      data[index]["caption"] = e.target.value;
      setAttachmentItem(data);
    }
  };

  const handleSubmitAttachments = () => {
    setIsLoading(true);
    dispatch(
      getPresignedUrlRequest({
        s3ContentType: "image/" + attachmentItems[0]?.file?.format, //fix diff file type
        numberOfUrlsToGenerate: attachmentItems.length,
      })
    );
  };

  const fetchBlob = async (path: string) => {
    const response = await fetch(path);
    const blob = await response.blob();
    return blob;
  };

  const uploadImageToS3 = async () => {
    if (attachmentItems[0]?.file) {
      let options = {
        headers: {
          "Content-Type": "image/" + attachmentItems[0]?.file?.format,
        },
      };

      const result = await Promise.allSettled(
        attachmentState?.f3images.map((elem, i) =>
          fetchBlob(attachmentItems[i]?.file.webPath).then((imgBlob) => {
            return axios.put(
              elem.presignedUrl,
              new File([imgBlob], `attachment-${i}`),
              options
            );
          })
        )
      );

      if (result) {
        attachmentState?.f3images?.forEach((elem, i) => {
          let data: any = [...attachmentItems];
          data[i]["s3FilePath"] = elem?.s3FilePath;
          data[i]["thumbnailS3FilePath"] = elem?.s3FilePath;
          setAttachmentItem(data);
        });

        dispatch(
          addAttachmentRequest(
            {
              attachments: attachmentItems.map(
                ({
                  caption,
                  isReceipt,
                  attachmentDay,
                  s3FilePath,
                  thumbnailS3FilePath,
                }) => ({
                  caption: caption.trim(),
                  isReceipt,
                  attachmentDay,
                  s3FilePath,
                  thumbnailS3FilePath,
                })
              ),
              timezone: clientTimezone,
            },
            {
              onSuccess: () => {
                setIsLoading(false);
                navigate(-1);
                sessionStorage.setItem("activeTab", "1");
              },
            }
          )
        );
      }
    }
  };

  const handleOpenTrialModal = () => {
    setOpen(true);
    setModalActionType((prevState) => ({
      ...prevState,
      action:
        new Date().getTime() < parseTime(organization?.trialExpires).getTime()
          ? "Trial"
          : "Trial expired",
    }));
  };

  useEffect(() => {
    uploadImageToS3();
  }, [attachmentState.f3images]);

  useEffect(() => {
    attachmentData?.photos?.forEach((elem) => {
      return setAttachmentItem((prev) => [
        ...prev,
        {
          caption: "",
          isReceipt: false,
          attachmentDay: attachmentData?.date,
          s3FilePath: "",
          thumbnailS3FilePath: "",
          file: elem,
        },
      ]);
    });
    setArrayIsEmpty(true);
  }, []);

  useEffect(() => {
    if (attachmentItems.length === 0 && arrayIsEmpty) {
      navigate(-1);
      sessionStorage.setItem("activeTab", "1");
    }
  }, [attachmentItems]);

  if (attachmentState.isLoading)
    return (
      <Box data-sqa-id="circular-progress-loader" sx={styles.isLoading}>
        <CircularProgress />
      </Box>
    );

  return (
    <>
      <Box position="static" sx={styles.appBarWrapper}>
        <Toolbar sx={styles.toolbar}>
          <IconButton
            sx={styles.menuIcon}
            size="large"
            color="inherit"
            aria-label="menu"
            onClick={() => {
              navigate(-1);
              sessionStorage.setItem("activeTab", "1");
            }}
          >
            <Back width={26} height={18} color="#000" />
          </IconButton>
          <Typography sx={styles.pageTitle}>Review and Save</Typography>
          {organization?.trial && Capacitor.getPlatform() != "ios" && (
            <Box
              sx={[
                styles.licenseStatus,
                {
                  backgroundColor:
                    new Date().getTime() <
                    parseTime(organization?.trialExpires).getTime()
                      ? (theme: Theme) => theme.palette.info.main
                      : "#FF4D4F",
                  color:
                    new Date().getTime() <
                    parseTime(organization?.trialExpires).getTime()
                      ? (theme: Theme) => theme.palette.info.contrastText
                      : "#FFF",
                },
              ]}
              onClick={handleOpenTrialModal}
            >
              Trial
            </Box>
          )}
        </Toolbar>
      </Box>
      {isLoading ? (
        <Box data-sqa-id="circular-progress-loader" sx={styles.isLoading}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box mx="20px">
            {attachmentItems.map((attachment, index) => (
              <AddAttachmentItemMobile
                key={attachment?.file?.webPath}
                handleDelete={handleDelete}
                toggleReceipt={toggleReceipt}
                handleCaption={handleCaption}
                attachment={attachment}
                index={index}
              />
            ))}
          </Box>
          <Box sx={{ mb: "20px", px: "20px", flexGrow: 1, overflow: "auto" }}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleSubmitAttachments}
            >
              <Box sx={styles.addButtonContainer}>
                <Typography sx={styles.addButtonText}>Save</Typography>
              </Box>
            </Button>
          </Box>
        </>
      )}

      {open && (
        <TrialModal
          modalActionType={modalActionType}
          isOpen={open}
          closeModal={() => setOpen(false)}
        />
      )}
    </>
  );
};

export default AddAttachment;
