import axios from "../../utils/axios";
import {
  AddCommentProjectRequestPayload,
  AddProjectRequestPayload,
  DeleteCommentProjectRequestPayload,
  DeleteProjectRequestPayload,
  FetchCommentProjectRequestPayload,
  FetchProjectIDRequestPayload,
  FetchProjectRequestPayload,
  FetchSubProjectRequestPayload,
  SearchProjectRequestPayload,
  UpdateCommentProjectRequestPayload,
  UpdateProjectRequestPayload,
} from "./types";

export const fetchProject = (payload: FetchProjectRequestPayload) => {
  return axios.get(
    `/project?currentPage=${payload.currentPage}&pageSize=${payload.pageSize}&sortOrder=${payload.sortOrder}&sortBy=${payload.sortBy}`
  );
};

export const fetchProjectAll = () => {
  return axios.get(`/project/all`);
};

export const fetchSubProject = (payload: FetchSubProjectRequestPayload) => {
  return axios.get(
    `/project/parent/${payload.parentProjectId}?currentPage=${payload.currentPage}&pageSize=${payload.pageSize}&sortOrder=${payload.sortOrder}&sortBy=${payload.sortBy}`
  );
};

export const fetchProjectID = (payload: FetchProjectIDRequestPayload) => {
  return axios.get(`/project/${payload.id}`);
};

export const fetchCommentProject = (
  payload: FetchCommentProjectRequestPayload
) => {
  return axios.get(`/comment/project/${payload.id}`);
};

export const searchProject = (payload: SearchProjectRequestPayload) => {
  return axios.get(
    `/project/search?currentPage=${payload.currentPage}&pageSize=${payload.pageSize}&sortBy=${payload.sortBy}&sortOrder=${payload.sortOrder}&searchQuery=${payload.searchQuery}`
  );
};

export const addProject = (payload: AddProjectRequestPayload) => {
  return axios.post("/project", payload);
};

export const updateProject = (payload: UpdateProjectRequestPayload) => {
  return axios.put("/project", payload);
};

export const deleteProject = (payload: DeleteProjectRequestPayload) => {
  return axios.delete(`/project/${payload.id}`);
};

export const addCommentProject = (payload: AddCommentProjectRequestPayload) => {
  return axios.post("/comment/project", payload);
};

export const updateCommentProject = (
  payload: UpdateCommentProjectRequestPayload
) => {
  return axios.put(
    `/comment/${payload.id}/project/${payload.projectId}`,
    payload
  );
};

export const deleteCommentProject = (
  payload: DeleteCommentProjectRequestPayload
) => {
  return axios.delete(`/comment/${payload.id}/project/${payload.projectId}`);
};
